import { LightTheme } from '../../theme'
import { ButtonStyleProps } from './button.types'

export const buttonPresets = (theme: LightTheme) => ({
  borderButton: {
    padding: '2px 6px',
    borderRadius: 4,
    backgroundColor: theme.colors.transparent,
    border: `2px solid ${theme.colors.yellow}`,
    '&:hover': {
      borderColor: theme.colors.hover
    },
    '&:hover > span': {
      color: theme.colors.hover
    }
  },
  button2: {
    backgroundColor: ({ color = 'yellow' }: ButtonStyleProps) =>
      theme.colors[color],
    border: ({ color = 'yellow' }: ButtonStyleProps) =>
      `2px solid ${theme.colors[color]}`,
    borderRadius: 4,
    padding: '4px 8px',
    '&:hover': {
      background: theme.colors.hover,
      borderColor: theme.colors.hover
    }
  },
  button3: {
    backgroundColor: ({ color = 'yellow' }: ButtonStyleProps) =>
      theme.colors[color],
    border: ({ color = 'yellow' }: ButtonStyleProps) =>
      `2px solid ${theme.colors[color]}`,
    borderRadius: 4,
    padding: '12px 8px',
    '&:hover': {
      background: theme.colors.hover,
      borderColor: theme.colors.hover
    }
  },
  button4: {
    width: 96,
    height: 40,
    backgroundColor: ({ color = 'yellow' }: ButtonStyleProps) =>
      theme.colors[color],
    border: ({ color = 'yellow' }: ButtonStyleProps) =>
      `2px solid ${theme.colors[color]}`,
    borderRadius: 4,
    padding: '4px 8px',
    '&:hover': {
      background: theme.colors.hover,
      borderColor: theme.colors.hover
    }
  },
  button5: {
    width: 74,
    padding: '7px 0',
    border: `1px solid ${theme.colors.greyFon}`,
    backgroundColor: theme.colors.transparent,
    '&:hover': {
      backgroundColor: theme.colors.lightBlue
    }
  },
  primary: {
    padding: '16px 24px',
    width: '100%',
    backgroundColor: ({ color = 'yellow' }: ButtonStyleProps) =>
      theme.colors[color],
    border: ({ color = 'yellow' }: ButtonStyleProps) =>
      `2px solid ${theme.colors[color]}`,
    borderRadius: 4,
    '&:hover': {
      background: theme.colors.hover,
      borderColor: theme.colors.hover
    }
  },
  primarySecond: {
    padding: '16px 24px',
    width: '100%',
    height: 56,
    backgroundColor: ({ color = 'yellow' }: ButtonStyleProps) =>
      theme.colors[color],
    border: ({ color = 'yellow' }: ButtonStyleProps) =>
      `2px solid ${theme.colors[color]}`,
    borderRadius: 4,
    '&:hover': {
      background: theme.colors.hover,
      borderColor: theme.colors.hover
    }
  },
  secondary: {
    padding: '16px 24px',
    width: '100%',
    borderRadius: 4,
    backgroundColor: ({ color = 'yellow' }: ButtonStyleProps) =>
      theme.colors[color],
    border: ({ color = 'yellow' }: ButtonStyleProps) =>
      `2px solid ${theme.colors[color]}`,
    '&:hover': {
      background: theme.colors.hover,
      borderColor: theme.colors.hover
    }
  },
  secondaryBorder: {
    padding: '14px 10px',
    width: '100%',
    borderRadius: 4,
    backgroundColor: theme.colors.transparent,
    border: ({ color = 'yellow' }: ButtonStyleProps) =>
      `2px solid ${theme.colors[color]}`,
    '&:hover': {
      borderColor: theme.colors.hover
    },
    '&:hover > span': {
      color: theme.colors.hover
    }
  },
  secondaryPopup: {
    backgroundColor: ({ color = 'yellow' }: ButtonStyleProps) =>
      theme.colors[color],
    border: ({ color = 'yellow' }: ButtonStyleProps) =>
      `2px solid ${theme.colors[color]}`,
    borderRadius: 4,
    padding: '12px 8px',
    width: '100%',
    maxWidth: 168,
    '&:hover': {
      background: theme.colors.hover,
      borderColor: theme.colors.hover
    }
  },
  secondaryBorderMedium: {
    backgroundColor: theme.colors.transparent,
    border: ({ color = 'yellow' }: ButtonStyleProps) =>
      `2px solid ${theme.colors[color]}`,
    borderRadius: 4,
    padding: '12px 8px',
    width: '100%',
    maxWidth: 152,
    '&:hover': {
      borderColor: theme.colors.hover
    },
    '&:hover > span': {
      color: theme.colors.hover
    }
  },
  secondaryBorderHigh: {
    backgroundColor: theme.colors.transparent,
    border: ({ color = 'yellow' }: ButtonStyleProps) =>
      `2px solid ${theme.colors[color]}`,
    borderRadius: 4,
    padding: '10px 6px',
    width: '100%',
    maxWidth: 204,
    '&:hover': {
      borderColor: theme.colors.hover
    },
    '&:hover > span': {
      color: theme.colors.hover
    }
  },
  secondaryMedium: {
    backgroundColor: ({ color = 'yellow' }: ButtonStyleProps) =>
      theme.colors[color],
    border: ({ color = 'yellow' }: ButtonStyleProps) =>
      `2px solid ${theme.colors[color]}`,
    borderRadius: 4,
    padding: '12px 8px',
    width: '100%',
    maxWidth: 152,
    '&:hover': {
      background: theme.colors.hover,
      borderColor: theme.colors.hover
    }
  },
  secondaryBorderMini: {
    backgroundColor: theme.colors.transparent,
    border: ({ color = 'yellow' }: ButtonStyleProps) =>
      `2px solid ${theme.colors[color]}`,
    borderRadius: 4,
    padding: '10px 6px',
    width: '100%',
    maxWidth: 124,
    '&:hover': {
      borderColor: theme.colors.hover
    },
    '&:hover > span': {
      color: theme.colors.hover
    }
  },
  secondaryMini: {
    backgroundColor: ({ color = 'yellow' }: ButtonStyleProps) =>
      theme.colors[color],
    border: ({ color = 'yellow' }: ButtonStyleProps) =>
      `2px solid ${theme.colors[color]}`,
    borderRadius: 4,
    padding: '12px 0px',
    width: '100%',
    maxWidth: 124,
    '&:hover': {
      background: theme.colors.hover,
      borderColor: theme.colors.hover
    }
  },
  cards: {
    padding: 7,
    width: '100%',
    maxWidth: 160,
    backgroundColor: theme.colors.transparent,
    border: ({ color = 'shadow' }: ButtonStyleProps) =>
      `1px solid ${theme.colors[color]}`,
    '&:hover': {
      backgroundColor: theme.colors.lightBlue
    }
  }
})
