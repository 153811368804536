import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  headerContainer: {
    borderBottom: `1px solid ${theme.colors.shadow}`,
    padding: 16,
    width: 'calc(100% - 32px)'
  },
  index: {
    marginLeft: 6
  },
  close: {
    marginLeft: 16
  },
  contact: {
    padding: '10px 6px',
    width: 168
  },
  companyRow: {},
  company: {
    marginLeft: 72
  },
  companyName: {
    marginLeft: 8
  },

  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    company: {
      margin: 0
    },
    companyName: {
      marginLeft: 8
    }
  },
  '@media screen and (max-width: 480px)': {
    companyRow: {
      marginTop: 16,
      justifyContent: 'space-between',
      flexWrap: 'wrap'
    },
    company: {
      margin: 0
    },
    companyName: {
      marginLeft: 8
    }
  }
}))
