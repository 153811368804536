import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  forgotForm: {
    width: 'calc(100vw - 24px)'
  },
  input: {
    marginTop: 45
  },
  email: {},
  logIn: {
    marginTop: 385
  },
  forgotPassword: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 8,
    width: 'auto',
    marginLeft: 16,
    textDecoration: 'underline',
    textDecorationColor: theme.colors.error
  },
  forgotSecondaryText: {
    marginTop: 14,
    textAlign: 'center'
  },
  submit: {
    marginTop: 273
  },
  signUp: {
    marginLeft: 4
  },
  signUpContainer: {
    marginTop: 24
  },
  error: {
    position: 'absolute',
    backgroundColor: theme.colors.errorBackground,
    padding: '8px 0',
    marginTop: 94,
    borderRadius: 4,
    '& > a': {
      textDecoration: 'underline',
      textDecorationColor: theme.colors.error
    }
  },
  errorIcon: {
    marginLeft: 19
  },
  errorText: {
    marginLeft: 16
  },
  forgotPasswordOverlay: {
    position: 'fixed',
    backgroundColor: theme.colors.white,
    height: '100%',
    minHeight: '100vh',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 2
  },
  forgotPasswordHeader: {
    display: 'flex',
    alignItems: 'center',
    height: 57,
    borderBottom: `1px solid ${theme.colors.shadow}`,
    width: '100%'
  },
  forgotPasswordSvgContainer: {
    display: 'flex',
    margin: '0 12px'
  },
  loginText: {
    margin: '0 12px'
  },
  icon: {
    cursor: 'pointer',
    '&:hover > path': {
      fill: theme.colors.yellow
    }
  }
}))
