import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  loginForm: {
    padding: 16,
    width: 'calc(100% - 32px)'
  },
  error: {
    width: 'calc(100% - 32px)'
  }
})
