import React, { FC } from 'react'
import { Row, Text, Column, ColorDot, Button } from '../../../components'

import { getPriceToFixed } from '../../helper'

import { PriceSummaryProps } from './price-summary.types'
import { useStyle } from './price-summary.styles'

export const PriceSummary: FC<PriceSummaryProps> = ({
  className = '',
  salePriceByQuantity,
  priceByQuantity,
  materialTitle,
  materialPrice,
  colorCode,
  colorTitle,
  colorPrice,
  optionsValue,
  currency,
  quantity,
  saleTotalPrice,
  totalPrice,
  isSale,
  onClick
}) => {
  const classes = useStyle()

  return (
    <Column className={`${className} ${classes.summary}`}>
      <Column className={classes.summaryBlock} alignItems="flex-start">
        <Text text="Price Summary" tx="product.price.summary" preset="h3" />
        <Row
          className={classes.summryItem}
          fullWidth
          justifyContent="space-between"
        >
          <Text text="Subtotal" tx="product.subtotal" preset="body" />
          {isSale ? (
            <Text text={salePriceByQuantity} preset="h5" />
          ) : (
            <Text text={priceByQuantity} preset="h5" />
          )}
        </Row>
        <Row
          className={classes.summryItem}
          fullWidth
          justifyContent="space-between"
        >
          <Text text={materialTitle} preset="body" />
          <Text text={materialPrice} preset="h5" />
        </Row>
        <Row
          className={classes.summryItem}
          fullWidth
          justifyContent="space-between"
        >
          <Row>
            <ColorDot className={classes.color} color={colorCode} />
            <Text
              className={classes.colorTitle}
              text={colorTitle}
              preset="body"
            />
          </Row>
          <Text text={colorPrice} preset="h5" />
        </Row>
        {optionsValue.options.map((item, index) => {
          return (
            <Column key={`option_variants_${item.id}_${index}`} fullWidth>
              {item.variants &&
                item.variants.map((itemVariant) => {
                  const itemPriceQuantity = itemVariant
                    ? itemVariant.extraPrice * quantity
                    : 0
                  const itemPrice = `${currency}${getPriceToFixed(
                    itemPriceQuantity
                  )}`
                  return (
                    <Row
                      key={`option_variants_item_${itemVariant.id}`}
                      className={classes.summryItem}
                      fullWidth
                      justifyContent="space-between"
                    >
                      <Text text={itemVariant.title} preset="body" />
                      <Text text={itemPrice} preset="h5" />
                    </Row>
                  )
                })}
            </Column>
          )
        })}
      </Column>
      <Column className={classes.summaryBlock}>
        <Row fullWidth justifyContent="space-between">
          <Text text="Order Price:" tx="product.order.price" preset="h5" />
          {isSale ? (
            <Text text={saleTotalPrice} preset="h5" />
          ) : (
            <Text text={totalPrice} preset="h5" />
          )}
        </Row>
        <Row fullWidth>
          <Button
            className={classes.button}
            color="yellow"
            preset="primary"
            text="ADD TO BASKET"
            tx="product.addToBasket"
            textColor="white"
            textPreset="h5"
            onClick={onClick}
          />
        </Row>
      </Column>
    </Column>
  )
}
