import React, { FC } from 'react'

import {
  Column,
  getCurrencySymbol,
  Icon,
  ProductPrice,
  RatingStarsComponent,
  Row,
  Text
} from '../../../components'
import { getPriceToFixed } from '../../../pages'

import { ProductPreviewBasicProductInfoProps } from './product-preview-basic-product-info.types'
import { useStyle } from './product-preview-basic-product-info.styles'

export const ProductPreviewBasicProductInfo: FC<
  ProductPreviewBasicProductInfoProps
> = ({
  favouriteIcon,
  isSale,
  name,
  averageRating,
  currency,
  deliveryPrice,
  totalPrice,
  discountMaxPrice,
  onAddToWishlistClick
}) => {
  const classes = useStyle()
  const currencySymbol = getCurrencySymbol(currency)
  const productDeliveryPrice = `${currencySymbol}${getPriceToFixed(
    deliveryPrice
  )}`

  return (
    <Column
      fullWidth
      alignItems="flex-start"
      justifyContent="flex-start"
      className={classes.productInfo}
    >
      <Row
        fullWidth
        justifyContent="space-between"
        className={classes.titleContainer}
      >
        <Text className={classes.title} text={name} preset="h4" color="black" />
        <Row className={classes.favorite} onClick={onAddToWishlistClick}>
          <Icon src={favouriteIcon} />
        </Row>
      </Row>
      <RatingStarsComponent
        className={classes.stars}
        rating={averageRating}
        edit={false}
      />
      <Row fullWidth justifyContent="space-between">
        <ProductPrice
          mini
          isSale={isSale}
          currency={currency}
          totalPrice={totalPrice}
          discountMaxPrice={discountMaxPrice}
        />
      </Row>
      <Row>
        <Text
          text="Delivery Price:"
          tx="product.item.card.delivery.price"
          preset="h7"
          color="inactive"
        />

        <Text
          className={classes.price}
          text={productDeliveryPrice}
          preset="h7"
          color="inactive"
        />
      </Row>
    </Column>
  )
}
