import React, { createContext, FC, useContext } from 'react'
import { ApolloProvider } from '@apollo/client'

import { config } from '../../config'
import { api, Api } from '../../services'

import { AuthProvider } from '../auth'
import { BasketProvider } from '../basket'

const nextApi = api.create(config.BASE_URL || '/graphql/')

export const ApiContext = createContext<Api>(nextApi)

export const ApiProvider: FC = ({ children }) => {
  return (
    <ApiContext.Provider value={nextApi}>
      <ApolloProvider client={nextApi.api}>
        <AuthProvider>
          <BasketProvider>{children}</BasketProvider>
        </AuthProvider>
      </ApolloProvider>
    </ApiContext.Provider>
  )
}

export const useApi = () => useContext(ApiContext)
