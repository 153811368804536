import { createUseStyles } from 'react-jss'

import background from '../../../assets/images/emptySale.png'

export const useStyle = createUseStyles({
  image: {
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${background})`,
    zIndex: 1,
    top: 48,
    height: 300
  },
  saleText: {
    marginTop: 212,
    width: 300,
    textAlign: 'center'
  },
  '@media screen and (max-width: 900px)': {
    image: {
      top: 32
    }
  }
})
