import { useQuery } from '@apollo/client'
import {
  VENDORS,
  VENDORS_GLOBAL_SEARCH,
  VENDOR_BY_ID,
  WISHLIST_VENDORS
} from './vendor.graphql'

import {
  VendorsApi,
  Vendors,
  VendorsListVariables,
  VendorByIdVariables,
  GetVendorByIdRequest,
  GetVendor,
  VendorsListData,
  VendorsGlobal,
  VendorsGlobalData,
  WishlistVendors,
  WishlistVendorsData
} from './vendor.types'

export const vendorServise = (): VendorsApi => {
  const useVendorListData = (variables: VendorsListVariables): Vendors => {
    const { data, loading, error, refetch, fetchMore } = useQuery<
      VendorsListData,
      VendorsListVariables
    >(VENDORS, {
      variables
    })

    return { data, loading, error, refetch, fetchMore }
  }

  const useVendorWishList = (
    variables: VendorsListVariables
  ): WishlistVendors => {
    const { data, loading, error, refetch, fetchMore } = useQuery<
      WishlistVendorsData,
      VendorsListVariables
    >(WISHLIST_VENDORS, {
      variables
    })

    return { data, loading, error, refetch, fetchMore }
  }

  const useVendorGlobalSearch = (
    variables: VendorsListVariables
  ): VendorsGlobal => {
    const { data, loading, refetch, fetchMore } = useQuery<
      VendorsGlobalData,
      VendorsListVariables
    >(VENDORS_GLOBAL_SEARCH, { variables, fetchPolicy: 'network-only' })

    const handleOnRefetch = (
      nextVariables?: Partial<VendorsListVariables> | undefined
    ) => {
      return refetch(nextVariables)
    }

    return { data, loading, refetch: handleOnRefetch, fetchMore }
  }

  const useVendorById = (
    variables: VendorByIdVariables
  ): GetVendorByIdRequest => {
    const { data, loading, error, refetch } = useQuery<
      GetVendor,
      VendorByIdVariables
    >(VENDOR_BY_ID, { variables })

    if (error) {
      return { data: null, loading, refetch }
    }

    if (!data) {
      return { data: null, loading, refetch }
    }

    return { data, loading, refetch }
  }

  return {
    useVendorListData,
    useVendorGlobalSearch,
    useVendorById,
    useVendorWishList
  }
}
