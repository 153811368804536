import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState
} from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'

import {
  AddressInput,
  AccountInput,
  AccountAddressType,
  GetRegisterRequest,
  GetSetPassword,
  GetChangePassword,
  GetTokenCreateRequest,
  User,
  FeedbackCreateVariables,
  AccountDeleteVariables,
  Address
} from '../../services'
import {
  arrayHasElements,
  Column,
  getIncludesFromEnum,
  getListFromEdgesList,
  Loader,
  TextProps
} from '../../components'
import { AuthRoutes } from '../../routes'

import { useApi } from '../api-provider'

import { AuthValue, SessionState } from './auth.types'
import {
  AuthToken,
  getAссeptedOffersIds,
  setAuthorizationToken,
  setTokens
} from './helpers'
import {
  authErrorMessages,
  ErrorMessages,
  getFieldErrorByField
} from './messages'

const defaultValue = {
  loadingSession: true
}

export const AuthContext = createContext<AuthValue>(defaultValue)

export const AuthProvider: FC = ({ children }) => {
  const client = useApolloClient()
  const history = useHistory()
  const location = useLocation()
  const [user, changeUser] = useState<User>()
  const [unreadMessages, changeUnreadMessages] = useState(0)
  const [newOffers, changeNewOffers] = useState(0)
  const [initialBasketIds, changeInitialBasketIds] = useState<string[]>([])
  const [session, changeSession] = useState<SessionState>({
    isLoggedIn: false,
    loadingSession: true
  })
  const [error, changeError] = useState<TextProps>()
  const [account, changeDel] = useState<TextProps>()
  const [signature, changeSignature] = useState<string>()

  const { auth, setHeader } = useApi()
  const { data: responseMe, lazyFetch: refetchMe } = auth.useMe()
  const { data: responsePartMe, refetch: refetchPartMe } = auth.usePartMe()

  const { onSubmit: onSubmitRegister, response: register } = auth.useRegister()
  const { onSubmit: onSubmitLogin, response: login } = auth.useToken()
  const { onSubmit: onSubmitRefreshToken, response: refresh } =
    auth.useRefreshToken()

  const { onSubmit: onSubmitSetPassword, response: password } =
    auth.useSetPassword()

  const { onSubmit: onSubmitChangePassword, response: passwordChange } =
    auth.useChangePassword()

  const { onSubmit: onSubmitFeedback, response: feedback } = auth.useFeedback()

  const { onSubmit: onSubmitAccounDelete, response: accountDelete } =
    auth.useAccountDelete()

  const {
    onSubmit: onSubmitAccountAddressCreate,
    response: accountAddressCreate
  } = auth.useAccountAddressCreate()
  const { onSubmit: onSubmitAccountAddressEdit, response: accountAddressEdit } =
    auth.useAccountAddressEdit()
  const {
    onSubmit: onSubmitDefaultAccountAddress,
    response: defaultAccountAddress
  } = auth.useDefaultAccountAddress()
  const {
    onSubmit: onSubmitAccountProfileUpdate,
    response: accountProfileUpdate
  } = auth.useAccountProfileUpdate()

  const onClearSession = () => {
    auth.useIsLogout()
    changeUser(undefined)
    changeSession({ ...session, loadingSession: false, isLoggedIn: false })
    client.cache.reset()
  }

  const onLogout = () => {
    onClearSession()
  }

  const navigateOnLogin = () => {
    const isRedirect = getIncludesFromEnum(
      Object.values(AuthRoutes),
      location.pathname
    )

    if (isRedirect) {
      history.push('/')
    }
  }

  const onResyncPartUser = () => {
    refetchPartMe()
  }
  useEffect(() => {
    changeError(undefined)
    onResyncPartUser()
  }, [location.pathname])

  useEffect(() => {
    if (responsePartMe && responsePartMe.me !== null) {
      changeNewOffers(responsePartMe.me.offers.totalCount)
    }
  }, [responsePartMe])

  useEffect(() => {
    if (register.data) {
      const {
        user: nextUser,
        jwtToken,
        refreshToken,
        talkjsSignature,
        accountErrors = []
      } = register.data.accountRegister

      const hasErrors = arrayHasElements(accountErrors)

      if (hasErrors) {
        const nextError = authErrorMessages(
          ErrorMessages.REGISTER_ALLREADY_EXIST
        )
        changeError(nextError)
      }

      if (jwtToken) {
        navigateOnLogin()
        changeUser(nextUser)
        changeSignature(talkjsSignature || undefined)
        setTokens(jwtToken, refreshToken)
        setAuthorizationToken(jwtToken, setHeader)
        changeError(undefined)
      }

      changeSession({
        ...session,
        loadingSession: false,
        isLoggedIn: !hasErrors
      })
    }
  }, [register.data])

  useEffect(() => {
    if (login.data) {
      const {
        token,
        refreshToken,
        talkjsSignature,
        user: nextUser,
        accountErrors = []
      } = login.data.tokenCreate

      const hasErrors = arrayHasElements(accountErrors)

      if (hasErrors) {
        const nextError = authErrorMessages(ErrorMessages.INCORRECT_LOGIN)
        changeError(nextError)
      }

      if (token) {
        navigateOnLogin()
        changeUser(nextUser)
        const acceptedOffers = getListFromEdgesList(nextUser.offers)
        const acceptedOffersIds = getAссeptedOffersIds(acceptedOffers)
        changeInitialBasketIds(acceptedOffersIds)
        changeSignature(talkjsSignature || undefined)
        setTokens(token, refreshToken)
        setAuthorizationToken(token, setHeader)

        changeError(undefined)
      }

      changeSession({
        ...session,
        loadingSession: false,
        isLoggedIn: !hasErrors
      })
      onResyncPartUser()
    }
  }, [login.data])

  useEffect(() => {
    if (refresh.data) {
      const {
        user: nextUser,
        token,
        talkjsSignature,
        accountErrors = []
      } = refresh.data.tokenRefresh

      if (arrayHasElements(accountErrors)) {
        onClearSession()
      }

      if (token) {
        changeUser(nextUser)
        const acceptedOffers = getListFromEdgesList(nextUser.offers)
        const acceptedOffersIds = getAссeptedOffersIds(acceptedOffers)
        changeInitialBasketIds(acceptedOffersIds)
        changeUnreadMessages(refresh.data.tokenRefresh.user.unreadMessages)
        changeSignature(talkjsSignature || undefined)
        changeSession({ ...session, loadingSession: false, isLoggedIn: true })
        setTokens(token)
        setAuthorizationToken(token, setHeader)
      }
      onResyncPartUser()
    }
  }, [refresh.data])

  useEffect(() => {
    if (password.data) {
      const { accountErrors } = password.data.setPassword

      if (arrayHasElements(accountErrors)) {
        const nextError = authErrorMessages(ErrorMessages.MIN_LENGTH_PASSWORD)
        changeError(nextError)
      } else {
        history.push('/success-password-reset')
        changeError(undefined)
      }
    }
  }, [password.data])

  useEffect(() => {
    if (passwordChange.data) {
      const { accountErrors } = passwordChange.data.passwordChange

      if (arrayHasElements(accountErrors)) {
        const nextError = authErrorMessages(ErrorMessages.MIN_LENGTH_PASSWORD)
        changeError(nextError)
      } else {
        changeError(undefined)
      }
    }
  }, [passwordChange.data])

  useEffect(() => {
    if (feedback.data) {
      const { accountErrors } = feedback.data.feedback
      if (arrayHasElements(accountErrors)) {
        const nextError = authErrorMessages(ErrorMessages.FEEDBACK_FAILURE)
        changeError(nextError)
      } else {
        changeError(undefined)
      }
    }
  }, [feedback.data])

  useEffect(() => {
    if (accountDelete.data) {
      const { accountErrors } = accountDelete.data.accountDelete
      if (arrayHasElements(accountErrors)) {
        const nextError = authErrorMessages(ErrorMessages.ACCOUNT_DELETE)
        changeDel(nextError)
      } else {
        changeDel(undefined)
      }
    }
  }, [accountDelete.data])

  useEffect(() => {
    if (accountAddressCreate.data) {
      const {
        accountErrors,
        user: nextUser,
        address
      } = accountAddressCreate.data.accountAddressCreate

      if (arrayHasElements(accountErrors)) {
        const nextError = authErrorMessages(ErrorMessages.SAVE_DELIVERY_INFO)
        changeError(nextError)
      }

      if (nextUser && address) {
        onSubmitDefaultAccountAddress({
          id: address.id,
          type: AccountAddressType.SHIPPING
        })
        changeUser(nextUser)
      }
    }
  }, [accountAddressCreate.data])

  useEffect(() => {
    if (accountAddressEdit.data) {
      const {
        accountErrors,
        user: nextUser,
        address
      } = accountAddressEdit.data.accountAddressUpdate

      if (arrayHasElements(accountErrors)) {
        const nextError = authErrorMessages(ErrorMessages.SAVE_DELIVERY_INFO)
        changeError(nextError)
      }

      if (address && nextUser) {
        onSubmitDefaultAccountAddress({
          id: address.id,
          type: AccountAddressType.SHIPPING
        })
        changeUser(nextUser)
      }
    }
  }, [accountAddressEdit.data])

  useEffect(() => {
    if (accountProfileUpdate.data) {
      const { accountErrors, user: nextUser } =
        accountProfileUpdate.data.accountUpdate
      if (accountErrors.length > 0) {
        const nextError = authErrorMessages(ErrorMessages.SAVE_DELIVERY_INFO)
        changeError(nextError)
      } else {
        changeUser(nextUser)
      }
    }
  }, [accountProfileUpdate.data])

  useEffect(() => {
    if (defaultAccountAddress.data) {
      const { accountErrors, user: nextUser } =
        defaultAccountAddress.data.accountSetDefaultAddress

      if (arrayHasElements(accountErrors)) {
        const nextError = authErrorMessages(ErrorMessages.SAVE_DELIVERY_INFO)
        changeError(nextError)
      } else {
        changeUser(nextUser)
      }
    }
  }, [defaultAccountAddress.data])

  useEffect(() => {
    const accountErrors = passwordChange.data?.passwordChange.accountErrors
    const hasErrors = arrayHasElements(accountErrors)
    if (accountErrors && hasErrors) {
      const nextError = getFieldErrorByField(accountErrors)
      changeError(nextError)
    } else {
      changeError(undefined)
    }
  }, [passwordChange.data?.passwordChange.accountErrors.length])

  useEffect(() => {
    if (responseMe?.me) {
      changeUser(responseMe?.me)
    }
  }, [responseMe?.me])

  const onResyncUser = () => {
    refetchMe()
  }
  useEffect(() => {
    if (responsePartMe?.me) {
      changeUnreadMessages(responsePartMe.me.unreadMessages)
      changeNewOffers(responsePartMe.me.offers.totalCount)
      if (user) {
        const nextUser: User = {
          ...user,
          unreadMessages: responsePartMe.me.unreadMessages
        }
        changeUser(nextUser)
      }
    }
  }, [responsePartMe?.me])

  const onRefreshToken = () => {
    const refreshToken = localStorage.getItem(AuthToken.REFRESH_TOKEN)

    if (refreshToken) {
      onSubmitRefreshToken(refreshToken)
    } else {
      onClearSession()
    }
  }

  const onLoggedIn = (data: GetTokenCreateRequest) => {
    changeSession({ ...session, loadingSession: true })
    onSubmitLogin(data)
  }

  const onLoggedInModal = (data: GetTokenCreateRequest) => {
    changeSession({ ...session })
    onSubmitLogin(data)
  }

  const onChangeError = () => {
    changeError(undefined)
  }

  const onRegister = (data: GetRegisterRequest) => {
    onSubmitRegister(data)
    changeSession({ ...session, loadingSession: false })
  }

  const onSetPassword = (variables: GetSetPassword) => {
    onSubmitSetPassword(variables)
  }

  const onChangePassword = (variables: GetChangePassword) => {
    onSubmitChangePassword(variables)
  }

  const onFeedback = (variables: FeedbackCreateVariables) => {
    onSubmitFeedback(variables)
  }

  const onAccountDelete = (variables: AccountDeleteVariables) => {
    onSubmitAccounDelete(variables)
  }

  const onAccountAddressCreate = (input: AddressInput) => {
    onSubmitAccountAddressCreate({ input, type: AccountAddressType.SHIPPING })
  }

  const onAccountAddressEdit = (id: string, input: AddressInput) => {
    onSubmitAccountAddressEdit({ input, id })
  }

  const onAccountProfileUpdate = (input: AccountInput) => {
    onSubmitAccountProfileUpdate({ input })
  }

  const onUpdateUserShippingAddress = (shippingAddress?: Address | null) => {
    if (user && !user.defaultShippingAddress && shippingAddress) {
      changeUser({
        ...user,
        defaultShippingAddress: shippingAddress
      })
    }
  }

  const onUpdateOfferTotalCount = (diffCount: number) => {
    if (newOffers) {
      const nextNewOffers = newOffers + diffCount
      changeNewOffers(nextNewOffers)
    }
  }

  useEffect(() => {
    if (onRefreshToken) {
      onRefreshToken()
    }
  }, [])

  const context = {
    ...session,
    signature,
    error,
    user,
    unreadMessages,
    newOffers,
    initialBasketIds,
    account,
    onResyncUser,
    onResyncPartUser,
    onFeedback,
    onAccountDelete,
    onChangeError,
    onLoggedIn,
    onLoggedInModal,
    onRefreshToken,
    onRegister,
    onLogout,
    onSetPassword,
    onChangePassword,
    onAccountAddressCreate,
    onAccountAddressEdit,
    onAccountProfileUpdate,
    onUpdateOfferTotalCount,
    onUpdateUserShippingAddress
  }

  if (session.loadingSession) {
    const style = { height: '100vh' }
    return (
      <AuthContext.Provider value={context}>
        <Column fullWidth style={style}>
          <Column>
            <Loader />
          </Column>
        </Column>
      </AuthContext.Provider>
    )
  }

  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
}

export const useAuth = () => useContext(AuthContext)
