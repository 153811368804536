import { createUseStyles } from 'react-jss'
import { isMobile } from 'react-device-detect'

import { LightTheme } from '../../theme'

import { SignUpStyleProps } from './sign-up.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  loginForm: {
    marginTop: isMobile ? 24 : 64,
    width: 536
  },
  inputEmail: {
    marginTop: 24,
    '& > div': {
      border: ({ isErrorEmail }: SignUpStyleProps) =>
        isErrorEmail && `1px solid ${theme.colors.error}`
    }
  },
  inputPassword: {
    marginTop: 24,
    borderRadius: 4,
    border: `1px solid ${theme.colors.inactive} `,
    '&:focus': {
      border: 'none'
    },
    '&:focus-within': {
      border: ({ isErrorPassword }: SignUpStyleProps) =>
        isErrorPassword
          ? `1px solid ${theme.colors.error}`
          : `2px solid ${theme.colors.yellow}`,

      borderRadius: 4
    }
  },
  input: {
    marginTop: 24
  },
  submit: {
    marginTop: 96
  },
  signIn: {
    marginLeft: 4
  },
  signInContainer: {
    marginTop: 24
  },
  radio: {
    marginLeft: 60
  },
  radioContainer: {
    marginTop: 24
  },
  error: {
    position: 'absolute',
    backgroundColor: theme.colors.errorBackground,
    padding: '8px 0',
    marginTop: 336,
    borderRadius: 4
  },
  errorIcon: {
    marginLeft: 19
  },
  errorText: {
    marginLeft: 16
  },
  '@media screen and (max-width: 480px)': {
    loginForm: {
      width: 'calc(100% - 24px)',
      padding: '0 12px'
    }
  }
}))
