import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import {
  AccountProfileUpdate,
  ChangePassword,
  FeedbackCreateVariables,
  GetFeedbackResults
} from '.'
import { AuthToken } from '../../providers/auth/helpers'

import {
  ACCOUNT_ADDRESS_CREATE,
  ACCOUNT_ADDRESS_EDIT,
  ACCOUNT_PROFILE_UPDATE,
  ACCOUNT_DELETE,
  FEEDBACK_CREATE,
  REGISTER_USER,
  REQUEST_PASSWORD_RESET,
  SET_DEFAULT_ADDRESS,
  SET_PASSWORD,
  TOKEN_AUTH,
  TOKEN_REFRESH,
  CHANGE_PASSWORD,
  ME_QUERY,
  IS_USER_EXIST,
  PART_ME_QUERY
} from './auth.graphql'
import {
  AccountAddressCreate,
  AccountAddressCreateVariables,
  AccountAddressEdit,
  AccountAddressEditVariables,
  DefaultAccountAddress,
  DefaultAccountAddressVariables,
  GetAccountAddressCreateResults,
  GetAccountAddressEditResults,
  GetDefaultAccountAddressResults,
  GetAccountUpdateRequest,
  GetAccountUpdateResults,
  AuthApi,
  AuthApiOptions,
  GetRegisterRequest,
  GetRegisterResults,
  GetTokenCreateRequest,
  GetTokenCreateResults,
  GetTokenRefreshResults,
  TokenCreate,
  TokenRefresh,
  GetPasswordResetResults,
  PasswordReset,
  UserRegister,
  GetRequestPasswordReset,
  SetPassword,
  GetSetPassword,
  GetSetPasswordResults,
  GetChangePassword,
  GetChangePasswordResults,
  FeedbackMutation,
  AccountDeleteVariables,
  GetAccountDelete,
  AccountDelete,
  AccountMe,
  GetAccountMeData,
  IsUserExist,
  IsUserExistResults,
  IsUserExistVariables,
  GetPartAccountMeData,
  AccountPartMe
} from './auth.types'

// our "constructor"
export const authService = ({ setHeader }: AuthApiOptions): AuthApi => {
  const useToken = (): TokenCreate => {
    const [TokenAuth, response] = useMutation<
      GetTokenCreateResults,
      GetTokenCreateRequest
    >(TOKEN_AUTH)

    const handleOnSubmit = (variables: GetTokenCreateRequest) => {
      const options = {
        variables
      }

      TokenAuth(options)
    }

    return { onSubmit: handleOnSubmit, response }
  }

  /**
   * Login user request from service
   * @param userData - Data about user to login auth
   */
  const useRegister = (): UserRegister => {
    const [RegisterAccount, response] = useMutation<
      GetRegisterResults,
      GetRegisterRequest
    >(REGISTER_USER)

    const handleOnSubmit = (variables: GetRegisterRequest) => {
      // role: { 1: vendor, 2: customer}
      const options = {
        variables: {
          role: 2,
          redirectUrl: 'http://localhost:3000/test',
          ...variables
        }
      }

      RegisterAccount(options)
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useRefreshToken = (): TokenRefresh => {
    const [TokenRefreshSubmit, response] = useMutation<
      GetTokenRefreshResults,
      { token: string }
    >(TOKEN_REFRESH)

    const handleOnSubmit = (token: string) => {
      if (token) {
        const options = {
          variables: {
            token
          }
        }

        TokenRefreshSubmit(options)
      }
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useRequestResetPassword = (): PasswordReset => {
    const [RequestResetPasswordSubmit, response] = useMutation<
      GetPasswordResetResults,
      GetRequestPasswordReset
    >(REQUEST_PASSWORD_RESET)

    const handleOnSubmit = (email: string) => {
      if (email) {
        const options = {
          variables: {
            email
          }
        }

        RequestResetPasswordSubmit(options)
      }
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useSetPassword = (): SetPassword => {
    const [RequestSetPasswordSubmit, response] = useMutation<
      GetSetPasswordResults,
      GetSetPassword
    >(SET_PASSWORD)

    const handleOnSubmit = (variables: GetSetPassword) => {
      if (variables.email) {
        const options = {
          variables
        }

        RequestSetPasswordSubmit(options)
      }
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useChangePassword = (): ChangePassword => {
    const [RequestChangePasswordSubmit, response] = useMutation<
      GetChangePasswordResults,
      GetChangePassword
    >(CHANGE_PASSWORD)

    const handleOnSubmit = (variables: GetChangePassword) => {
      if (variables) {
        const options = {
          variables
        }

        RequestChangePasswordSubmit(options)
      }
    }

    return { onSubmit: handleOnSubmit, response }
  }
  const useFeedback = (): FeedbackMutation => {
    const [RquestFeedbackSubmit, response] = useMutation<
      GetFeedbackResults,
      FeedbackCreateVariables
    >(FEEDBACK_CREATE)

    const handleOnSubmit = (variables: FeedbackCreateVariables) => {
      if (variables) {
        const options = {
          variables
        }

        RquestFeedbackSubmit(options)
      }
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useAccountDelete = (): AccountDelete => {
    const [RquestAccountDeleteSubmit, response] = useMutation<
      GetAccountDelete,
      AccountDeleteVariables
    >(ACCOUNT_DELETE)
    const handleOnSubmit = (variables: AccountDeleteVariables) => {
      if (variables) {
        const options = {
          variables
        }

        RquestAccountDeleteSubmit(options)
      }
    }
    return { onSubmit: handleOnSubmit, response }
  }

  const useIsLoggedIn = () => {
    const token = localStorage.getItem(AuthToken.TOKEN)
    setHeader('Authorization', `JWT ${token}`)

    return Boolean(token)
  }

  const useIsLogout = () => {
    localStorage.removeItem(AuthToken.TOKEN)
    localStorage.removeItem(AuthToken.REFRESH_TOKEN)
    setHeader('Authorization', '')
  }

  const useDefaultAccountAddress = (): DefaultAccountAddress => {
    const [RequestDefaultAccountAddressSubmit, response] = useMutation<
      GetDefaultAccountAddressResults,
      DefaultAccountAddressVariables
    >(SET_DEFAULT_ADDRESS)

    const handleOnSubmit = (variables: DefaultAccountAddressVariables) => {
      const options = {
        variables
      }

      RequestDefaultAccountAddressSubmit(options)
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useAccountAddressCreate = (): AccountAddressCreate => {
    const [RequestAccountAddressCreateSubmit, response] = useMutation<
      GetAccountAddressCreateResults,
      AccountAddressCreateVariables
    >(ACCOUNT_ADDRESS_CREATE)

    const handleOnSubmit = (variables: AccountAddressCreateVariables) => {
      const options = {
        variables
      }
      RequestAccountAddressCreateSubmit(options)
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useAccountAddressEdit = (): AccountAddressEdit => {
    const [RequestAccountAddressCreateSubmit, response] = useMutation<
      GetAccountAddressEditResults,
      AccountAddressEditVariables
    >(ACCOUNT_ADDRESS_EDIT)

    const handleOnSubmit = (variables: AccountAddressEditVariables) => {
      const options = {
        variables
      }
      RequestAccountAddressCreateSubmit(options)
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useAccountProfileUpdate = (): AccountProfileUpdate => {
    const [RequestAccountProfileUpdateSubmit, response] = useMutation<
      GetAccountUpdateResults,
      GetAccountUpdateRequest
    >(ACCOUNT_PROFILE_UPDATE)

    const handleOnSubmit = (variables: GetAccountUpdateRequest) => {
      const options = {
        variables
      }
      RequestAccountProfileUpdateSubmit(options)
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useMe = (): AccountMe => {
    const [lazyFetch, { data, loading, refetch }] =
      useLazyQuery<GetAccountMeData>(ME_QUERY)

    return { data, loading, refetch, lazyFetch }
  }

  const usePartMe = (): AccountPartMe => {
    const { data, loading, refetch } =
      useQuery<GetPartAccountMeData>(PART_ME_QUERY)
    return { data, loading, refetch }
  }

  const useIsUserExist = (): IsUserExist => {
    const [onIsUserExist, response] = useMutation<
      IsUserExistResults,
      IsUserExistVariables
    >(IS_USER_EXIST)

    const handleOnSubmit = (variables: IsUserExistVariables) => {
      const options = {
        variables
      }

      onIsUserExist(options)
    }

    return { onSubmit: handleOnSubmit, response }
  }

  return {
    setHeader,
    useMe,
    usePartMe,
    useRegister,
    useAccountProfileUpdate,
    useChangePassword,
    useToken,
    useIsLoggedIn,
    useRefreshToken,
    useIsLogout,
    useRequestResetPassword,
    useSetPassword,
    useAccountAddressCreate,
    useAccountAddressEdit,
    useDefaultAccountAddress,
    useFeedback,
    useAccountDelete,
    useIsUserExist
  }
}
