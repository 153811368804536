import React, { FC, MouseEvent } from 'react'
import { isMobile } from 'react-device-detect'

import { Currency } from '../../services'
import { getPriceToFixed } from '../../pages'

import { Row } from '../row'
import { Column } from '../column'
import { Text } from '../text'
import { Checkbox } from '../checkbox'
import { getCurrencySymbol } from '../helpers'

import { useStyle } from './constructor-checkbox-item.styles'
import { ConstructorCheckboxItemProps } from './constructor-checkbox-item.types'

export const ConstructorCheckboxItem: FC<ConstructorCheckboxItemProps> = ({
  className,
  tx,
  id,
  extraPrice,
  extraProductionDays,
  title,
  checked,
  onChange,
  onClick
}) => {
  const classes = useStyle()
  const extraPriceStringified = `${getPriceToFixed(
    extraPrice
  )} ${getCurrencySymbol(Currency.USD)}`

  const extraProductionDayValue = { extraProductionDays }

  const Container = isMobile ? Column : Row
  const align = isMobile ? 'flex-start' : 'center'

  const handleOnChange =
    (nextId: string) =>
    (
      state: boolean,
      event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
    ) => {
      event.preventDefault()
      event.stopPropagation()

      if (onChange) {
        onChange(nextId, state)
      }
    }

  return (
    <Row
      className={className}
      onClick={onClick}
      fullWidth
      justifyContent="space-between"
    >
      <Row className={classes.container} justifyContent="flex-start">
        <Row
          className={classes.descriptionContainer}
          justifyContent="flex-start"
        >
          <Checkbox checked={checked} onChange={handleOnChange(id)} />
          {!isMobile && (
            <Text className={classes.title} text={title} tx={tx} preset="h5" />
          )}
        </Row>
        <Container
          className={classes.descriptionContainer}
          justifyContent="flex-start"
          alignItems={align}
        >
          {isMobile && (
            <Text className={classes.title} text={title} tx={tx} preset="h5" />
          )}
          {extraProductionDays && extraProductionDays <= 1 ? (
            <Text
              className={classes.description}
              text={`(+${extraProductionDays} day)`}
              tx="product.constructor.option.item.extra.production.day"
              preset="body"
              values={extraProductionDayValue}
            />
          ) : (
            <Text
              className={classes.description}
              text={`(+${extraProductionDays} days)`}
              tx="product.constructor.option.item.extra.production.days"
              preset="body"
              values={extraProductionDayValue}
            />
          )}
        </Container>
      </Row>
      <Text text={extraPriceStringified} preset="h5" />
    </Row>
  )
}
