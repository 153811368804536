import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  row: {
    width: '100%',
    padding: '32px 0',
    background: theme.colors.lightFonFilter,
    borderBottom: `1px solid ${theme.colors.shadow}`
  },
  reset: {
    height: 32,
    width: 86
  },
  resultsRow: {},
  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    row: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '24px 0'
    },
    resultsRow: {
      marginLeft: 16
    },
    reset: {
      marginLeft: 24
    }
  },
  '@media screen and (max-width: 480px)': {
    row: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '100%',
      padding: '16px 0px 24px'
    },
    resultsRow: {
      marginLeft: 4
    },
    reset: {
      marginLeft: 12
    }
  }
}))
