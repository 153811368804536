import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useHistory } from 'react-router'
import { arrayHasElements, getListFromEdgesList } from '../../components'
import { MainRoutes } from '../../routes'
import { AddressInput, Offer, OfferStatus, Order } from '../../services'

import { useApi } from '../api-provider'
import { useAuth } from '../auth'
import { AuthToken } from '../auth/helpers'
import { BasketByVendorType, BasketType, BasketValue } from './basket.types'

const defaultValue: BasketValue = {
  basket: null,
  basketIds: [],
  deliveryAddress: null,
  draftedOrders: [],
  draftedOrdersBulk: [],
  BasketByVendor: new Map()
}

export const BasketContext = createContext<BasketValue>(defaultValue)

export const BasketProvider: FC = ({ children }) => {
  const { offer: offerApi } = useApi()
  const {
    user: defaultUser,
    onUpdateUserShippingAddress,
    initialBasketIds
  } = useAuth()

  const {
    onSubmit: onSubmitDraftOrderCreate,
    response: responseDraftOrderCreate
  } = offerApi.useCreateOrderFromOffer()

  const {
    onSubmit: onSubmitDraftOrderBulkCreate,
    response: responseDraftOrderBulkCreate
  } = offerApi.useCreateOrderFromOfferList()

  const history = useHistory()
  const [draftedOrders, changeDraftedOrders] = useState<Order[]>([])
  const [draftedOrdersBulk, changeDraftedOrdersBulk] = useState<Order[]>([])
  const [basket, changeBasket] = useState<BasketType>([])
  const [basketIds, changeBasketIds] = useState<string[]>([])
  const [deliveryAddress, changeDeliveryAddress] =
    useState<AddressInput | null>(null)

  const offersByIdsVariables = {
    first: 10,
    filter: { ids: basketIds, status: [OfferStatus.ACCEPTED] }
  }
  const { data: offersById, refetch: refetchOffersById } =
    offerApi.useOffersById(offersByIdsVariables)

  useEffect(() => {
    if (initialBasketIds !== undefined) {
      changeBasketIds(initialBasketIds)
    }
  }, [initialBasketIds])

  useEffect(() => {
    if (refetchOffersById && arrayHasElements(basketIds)) {
      refetchOffersById(offersByIdsVariables)
    }
  }, [history.location.pathname === MainRoutes.SHOPPING_CART, basketIds])

  const Basket = useMemo(() => {
    if (arrayHasElements(basketIds) && offersById) {
      return getListFromEdgesList(offersById.useOffersById)
    }

    return []
  }, [offersById?.useOffersById.edges, basketIds])

  useEffect(() => {
    if (offersById) {
      const ids = Basket.map((offer) => offer.id)
      changeBasketIds(ids)
    }
  }, [offersById?.useOffersById.edges])

  useEffect(() => {
    if (responseDraftOrderCreate.data) {
      const newOrder = responseDraftOrderCreate.data.createOrderFromOffer.order

      const nextDraftedOrder = [...draftedOrders, newOrder]

      changeDraftedOrders(nextDraftedOrder)

      if (onUpdateUserShippingAddress) {
        onUpdateUserShippingAddress(newOrder.user.defaultShippingAddress)
      }
    }
  }, [responseDraftOrderCreate.data])

  useEffect(() => {
    if (responseDraftOrderBulkCreate.data) {
      const newOrders =
        responseDraftOrderBulkCreate.data.createOrderFromOfferList.orders

      const lastOrder =
        responseDraftOrderBulkCreate.data.createOrderFromOfferList.orders[0]

      changeDraftedOrdersBulk(newOrders)
      if (lastOrder && onUpdateUserShippingAddress) {
        onUpdateUserShippingAddress(lastOrder.user.defaultShippingAddress)
      }
    }
  }, [responseDraftOrderBulkCreate.data])

  useEffect(() => {
    if (defaultUser && defaultUser.defaultShippingAddress) {
      const { defaultShippingAddress: address } = defaultUser

      const { country: nextCountry } = address
      const nextAddress = {
        ...address,
        country: nextCountry?.code,
        isDefaultShippingAddress: undefined,
        isDefaultBillingAddress: undefined,
        __typename: undefined,
        id: undefined
      } as AddressInput

      changeDeliveryAddress(nextAddress)
    } else {
      changeDeliveryAddress(null)
    }
  }, [defaultUser?.defaultShippingAddress])

  const onClearBasket = () => {
    changeBasketIds([])
  }

  const getTotalFromBasket = () => {
    return Basket.reduce((acc, currentProduct) => {
      const offerPrice =
        currentProduct.productTotalPrice - currentProduct.discount
      const fullPrice = acc + offerPrice

      return fullPrice
    }, 0)
  }

  const getTotalWeightFromBasket = () => {
    return Basket.reduce((acc, currentOffer) => {
      const fullWeight = acc + currentOffer.totalWeight

      return fullWeight
    }, 0)
  }

  const getTotalDeliveryFromBasket = () => {
    return Basket.reduce((acc, currentProduct) => {
      if (currentProduct.product.deliveryPrice) {
        const fullPrice = acc + currentProduct.product.deliveryPrice.price

        return fullPrice
      }

      return 0
    }, 0)
  }

  const onAddOfferId = (offerId: string) => {
    const inBasket = basketIds.find((id) => {
      return id === offerId
    })

    if (!inBasket) {
      const nextBasketIds = [...basketIds, offerId]
      changeBasketIds(nextBasketIds)
      return nextBasketIds
    }
    return []
  }

  const onRemoveOfferId = (offerId: string) => {
    const nextBasketIds = basketIds.filter((id) => {
      const isCurrentProduct = id === offerId

      const active = isCurrentProduct

      return !active
    })

    changeBasketIds(nextBasketIds)

    return nextBasketIds
  }

  const onCreateDraftOrder = (currentDeliveryAddress: AddressInput) => {
    changeDraftedOrders([])
    const { id } = Basket[0]
    onSubmitDraftOrderCreate({
      offerId: id,
      shippingAddress: currentDeliveryAddress
    })
  }

  const onCreateBulkDraftOrder = (currentDeliveryAddress: AddressInput) => {
    const offerIds = Basket.map((item) => item.id)
    onSubmitDraftOrderBulkCreate({
      offerIds,
      shippingAddress: currentDeliveryAddress
    })

    onClearBasket()
  }

  // FIXME: not used in offer flow. Left not to get errors in components
  // which are used for product purchase flow which is currently not in use
  const setBasketToLocalStorage = (nextBasket: BasketType) => {
    localStorage.setItem(AuthToken.BASKET_TOKEN, JSON.stringify(nextBasket))
  }

  const onAddProduct = (offer: Offer) => {
    const newProduct: Offer = {
      ...offer,
      status: OfferStatus.ACCEPTED
    }

    const inBasket = basket.find((basketProduct) => {
      return basketProduct.id === newProduct.id
    })

    if (!inBasket) {
      const nextBasket = [...Basket, newProduct]
      changeBasket(nextBasket)
      setBasketToLocalStorage(nextBasket)

      return nextBasket
    }

    if (inBasket) {
      const nextBasket = basket.map((basketProduct) => {
        if (basketProduct.id === newProduct.id) {
          return {
            ...basketProduct
          }
        }
        return basketProduct
      })

      changeBasket(nextBasket)
      setBasketToLocalStorage(nextBasket)

      return nextBasket
    }

    return basket
  }

  const onRemoveProduct = (offerId: string) => {
    const nextBasket = Basket.filter((product) => {
      const isCurrentProduct = product.id === offerId

      const active = isCurrentProduct

      return !active
    })

    changeBasket(nextBasket)
    setBasketToLocalStorage(nextBasket)

    return nextBasket
  }

  const BasketByVendor = useMemo(() => {
    return basket.reduce((prevMap, product) => {
      const prevVendor = prevMap.get(product.vendor.id)
      const nextProducts = prevVendor
        ? [...prevVendor.products, product]
        : [product]
      const nextVendor: BasketByVendorType = {
        ...product.vendor,
        products: nextProducts
      }
      const nextBasketByVendor = prevMap.set(product.vendor.id, nextVendor)
      return nextBasketByVendor
    }, new Map<string, BasketByVendorType>())
  }, [basket])

  const onRemoveBasketByVendor = (vendorId: string) => {
    const nextBasket = basket.filter(
      (product) => product.vendor.id !== vendorId
    )

    changeBasket(nextBasket)

    return nextBasket
  }

  const context = useMemo(
    () => ({
      basket: Basket,
      basketIds,
      deliveryAddress,
      draftedOrders,
      draftedOrdersBulk,
      BasketByVendor,
      onRemoveBasketByVendor,
      getTotalFromBasket,
      getTotalDeliveryFromBasket,
      onAddProduct,
      onAddOfferId,
      onRemoveProduct,
      onRemoveOfferId,
      onCreateDraftOrder,
      onClearBasket,
      getTotalWeightFromBasket,
      onCreateBulkDraftOrder
    }),
    [Basket, basketIds, draftedOrders, draftedOrdersBulk]
  )

  return (
    <BasketContext.Provider value={context}>{children}</BasketContext.Provider>
  )
}

export const useBasket = () => useContext(BasketContext)
