import React, { FC } from 'react'

import { Row } from '../row'
import { Text } from '../text'
import { ItemInfoRowProps } from './item-info-row.types'
import { useStyle } from './item-info-row.styles'

export const ItemInfoRow: FC<ItemInfoRowProps> = ({
  price,
  pricePerItem,
  quantity
}) => {
  const classes = useStyle()

  return (
    <>
      <Row className={classes.itemText} justifyContent="space-between">
        {pricePerItem ? (
          <Text text={pricePerItem} preset="h5" color="black" />
        ) : (
          <Text text={price} preset="h5" color="black" />
        )}
      </Row>
      <Row className={classes.itemText} justifyContent="flex-start">
        <Text text={quantity} preset="h5" color="black" />
      </Row>
      <Row className={classes.total} justifyContent="flex-start">
        <Text text={price} preset="h5" color="black" />
      </Row>
    </>
  )
}
