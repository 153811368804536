import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {},
  content: {
    marginTop: 24
  },
  breadcrumbs: {
    marginTop: 24
  },
  offerView: {
    alignSelf: 'stretch',
    width: 476,
    border: `1px solid ${theme.colors.shadow}`
  },
  chatboxContainer: {
    border: `1px solid ${theme.colors.shadow}`,
    flex: 1,
    alignSelf: 'stretch'
  },
  chatbox: {
    flex: 1,
    alignSelf: 'stretch'
  },
  offerTitleContainer: {
    padding: '8px 24px',
    width: 'calc(100% - 48px)'
  },
  offerTitle: {
    marginLeft: 24
  },
  offerDescription: {
    marginTop: 8
  },
  offerProductContainer: {
    borderTop: `1px solid ${theme.colors.shadow}`,
    padding: 24,
    width: 'calc(100% - 48px)'
  },
  offerImg: {
    objectFit: 'cover',
    height: 428,
    width: 428
  },
  offerName: {
    marginTop: 16
  },
  offerObserve: {
    marginTop: 8
  },
  offerOptions: {
    marginTop: 16
  },
  offerButtonContainer: {
    marginTop: 36
  },
  offerAccept: {
    marginLeft: 24
  },
  '@media screen and (max-width: 480px)': {
    content: {
      flexDirection: 'column'
    },
    offerView: {
      width: '100%'
    },
    offerTitleContainer: {
      padding: '8px 16px',
      width: 'calc(100% - 32px)'
    },
    offerProductContainer: {
      padding: 16,
      width: 'calc(100% - 32px)'
    },
    offerImg: {
      objectFit: 'cover',
      height: 104,
      width: 104
    },
    chatbox: {
      height: 600,
      flex: 'auto'
    },
    offerName: {
      marginTop: 0,
      marginLeft: 16
    },
    offerObserve: {
      marginLeft: 16
    }
  }
}))
