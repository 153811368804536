import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  buttonRow: {
    width: '100%'
  },
  button: {
    marginTop: 40,
    width: '100%'
  }
})
