import React, { FC } from 'react'

import { Row } from '../../row'
import { Text } from '../../text'
import { Column } from '../../column'
import { ItemInfoRowProps } from '../item-info-row.types'
import { useStyle } from './item-info-row-mobile.styles'

export const ItemInfoRowMobile: FC<ItemInfoRowProps> = ({
  className,
  price,
  pricePerItem,
  quantity
}) => {
  const classes = useStyle()

  return (
    <Row justifyContent="space-between" className={className}>
      <Column className={classes.itemText} justifyContent="space-between">
        <Text
          text="Price"
          tx="order.preview.price"
          preset="body"
          color="inactive"
        />

        {pricePerItem ? (
          <Text
            text={pricePerItem}
            preset="h7"
            color="black"
            className={classes.textMobile}
          />
        ) : (
          <Text
            text={price}
            preset="h7"
            color="black"
            className={classes.textMobile}
          />
        )}
      </Column>
      <Column className={classes.itemText} justifyContent="flex-start">
        <Text
          text="Quantity"
          tx="order.preview.quantity"
          preset="body"
          color="inactive"
        />
        <Text
          text={quantity}
          preset="h7"
          color="black"
          className={classes.textMobile}
        />
      </Column>
      <Column className={classes.itemText} justifyContent="flex-start">
        <Text
          text="Total Price"
          tx="order.preview.total.price"
          preset="body"
          color="inactive"
        />
        <Text
          text={price}
          preset="h7"
          color="black"
          className={classes.textMobile}
        />
      </Column>
    </Row>
  )
}
