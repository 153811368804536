import React, { FC } from 'react'

import { BannerButtonType } from '../../services'

import { Button, ButtonPresets } from '../button'
import { Column } from '../column'
import { Text, TextPresets } from '../text'
import { Row } from '../row'

import { SecondaryBannerViewProps } from './secondary-banner.types'
import { useStyle } from './secondary-banner.styles'

export const SecondaryBannerView: FC<SecondaryBannerViewProps> = ({
  data,
  className
}) => {
  const classes = useStyle()

  const isBigButton = data.buttonType === BannerButtonType.BIG_BUTTON

  const buttonPreset: ButtonPresets = isBigButton ? 'primary' : 'button4'
  const buttonTextPreset: TextPresets = isBigButton ? 'h5' : 'maxButton'

  return (
    <Column fullWidth className={className}>
      {data.isNotificationActive && (
        <Row className={classes.topBarBanner} justifyContent="space-between">
          <Text
            className={classes.topBannerText}
            color="white"
            preset="title"
            text={data.notificationMessageText}
          />
          <a className={classes.anchor} href={data.notificationButtonLink}>
            <Button
              textColor="black"
              textPreset="maxButton"
              preset="button4"
              color="white"
              text={data.notificationButtonText}
            />
          </a>
        </Row>
      )}
      <Row fullWidth>
        <Column className={classes.bannerOverflow} alignItems="flex-start">
          <Text color="blue" preset="h4" text={data.mainHeading || ''} />
          <Text
            className={classes.text}
            color="black"
            preset="secondBanner"
            text={data.title || ''}
          />
          <Text
            className={classes.text}
            color="inactive"
            preset="subscriptionText"
            text={data.mainDescription || ''}
          />

          <Text
            className={classes.text}
            color="black"
            preset="h5"
            text={data.secondaryHeading || ''}
          />
          <Text
            className={classes.text}
            color="inactive"
            preset="h6"
            text={data.secondaryDescription || ''}
          />
          {data.isButtonActive && (
            <a
              className={`${classes.anchor} ${classes.linkContainer}`}
              href={data.buttonLink}
            >
              <Button
                className={classes.banner}
                textColor="white"
                textPreset={buttonTextPreset}
                preset={buttonPreset}
                text={data.buttonText}
              />
            </a>
          )}
        </Column>
        <img
          width="100%"
          height="480"
          src={data.image}
          className={classes.image}
        />
      </Row>
    </Column>
  )
}
