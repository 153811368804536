import { createUseStyles } from 'react-jss'
import { isMobileOnly } from 'react-device-detect'
import { LightTheme } from '../../../theme'

import { OrderStagingStyle } from './order-staging.types'

const getGradientProcent = (index: number, length: number) =>
  (index / length) * 100

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {},
  staging: {
    width: '100%'
  },
  progress: ({ left, right, active, length }: OrderStagingStyle) => {
    const width = isMobileOnly && 720
    const { yellow, shadow } = theme.colors
    const startIndex = active - 1
    const endIndex = startIndex + 2 === length ? startIndex + 3 : startIndex + 2
    const start = getGradientProcent(startIndex, length)
    const end = getGradientProcent(endIndex, length)
    const backgroundImage = `linear-gradient(90deg, ${yellow} 0%, ${yellow} ${start}%, ${shadow} ${end}%, ${shadow} 100%)`

    return {
      width,
      left,
      right,
      background: backgroundImage,
      position: 'absolute',
      height: 4,
      top: 18,
      transition: 'background-position 1s ease-in-out'
    }
  },
  time: ({ active, length }: OrderStagingStyle) => {
    const end = getGradientProcent(active, length)
    const left = end / 2

    return {
      cursor: 'default',
      top: 0,
      left: `${left}%`,
      position: 'absolute'
    }
  },
  itemFirst: {},
  item: {},
  hint: {
    width: 'auto'
  },
  hintText: {
    width: 304
  },
  '@media screen and (max-width: 480px)': {
    staging: {
      overflow: 'hidden'
    },
    itemFirst: {
      width: 104
    },
    item: {
      width: 104,
      marginLeft: 40
    }
  }
}))
