import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

import { ReturnsItemStyle } from './returns-item.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  ordersRow: {
    backgroundColor: ({ active }: ReturnsItemStyle) =>
      active && theme.colors.greyFon,
    width: 'calc(100% - 32px)',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.colors.lightBlue
    }
  },
  mainRow: {
    flex: 1,
    minHeight: 48
  },
  one: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'flex-end'
  },
  two: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'flex-end'
  },
  twoDate: {
    color: theme.colors.inactive,
    marginRight: 8
  },
  textItem: {
    lineHeight: '24px'
  },
  three: {
    flex: 3,
    justifyContent: 'flex-start',
    alignItems: 'flex-end'
  },
  threeVendor: {
    marginRight: 8
  },
  imagesRow: {
    flex: 1,
    marginRight: 20
  },
  arrow: {
    display: 'flex'
  },
  image: {
    width: 48,
    height: 48,
    marginRight: 8
  },
  orderImagesOrders: {
    marginRight: 16,
    minWidth: 48
  },
  '@media screen and (min-width: 700px) and (max-width: 1023px)': {
    mainRow: {
      flex: 4
    }
  }
}))
