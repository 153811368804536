import React, { FC, MouseEvent } from 'react'
import ReactDOM from 'react-dom'
import { isMobileOnly } from 'react-device-detect'

import { usePortal } from '../../hooks'

import { Column } from '../column'
import { Icon, ICONS } from '../icon'
import { Text } from '../text'
import { Row } from '../row'
import { onStopEvent } from '../helpers'
import { NavLink } from '../nav-link'

import { ModalProps } from './modal.types'
import { useStyle } from './modal.styles'

export const Modal: FC<ModalProps> = ({
  className = '',
  title,
  titleTx,
  children,
  linkTo,
  isLink,
  onClose
}) => {
  const classes = useStyle()
  const titlePreset = isMobileOnly ? 'h3Mobile' : 'h3'
  const target = usePortal('modal')

  const handleOnPropagationBackground = (
    event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    event.preventDefault()
    event.stopPropagation()
  }

  return ReactDOM.createPortal(
    <Column className={classes.container} onClick={onStopEvent}>
      <button className={classes.buttonContainer} onClick={onClose} />
      <Column
        className={`${className} ${classes.modal}`}
        justifyContent="flex-start"
        onClick={handleOnPropagationBackground}
      >
        <Row className={classes.title} justifyContent="space-between">
          {isLink ? (
            <Row fullWidth>
              <NavLink preset={titlePreset} text={title} to={linkTo} />
              <Icon
                src={ICONS.arrowRight}
                width={32}
                className={classes.arrowRight}
              />
            </Row>
          ) : (
            <Text preset={titlePreset} text={title} tx={titleTx} />
          )}
          <button className={classes.closeContainer} onClick={onClose}>
            <Icon src={ICONS.close} className={classes.close} />
          </button>
        </Row>
        <Column
          fullWidth
          className={classes.bottomContainer}
          justifyContent="flex-start"
        >
          {children}
        </Column>
      </Column>
    </Column>,
    target
  )
}
