import React, { FC, useMemo } from 'react'

import {
  getAvaliableToPayByPaymentStatus,
  QuestionModal,
  Text,
  TextTranslate
} from '../../components'
import { useStyle } from './decline-order-modal.styles'

import { DeclineModalProps } from './decline-order-modal.types'

export const DeclineOrderModal: FC<DeclineModalProps> = ({
  open,
  orderNumber,
  paymentStatus,
  totalPrice,
  onSubmit,
  onClose
}) => {
  const classes = useStyle()
  const isAvailableToPay = useMemo(
    () => getAvaliableToPayByPaymentStatus(paymentStatus),
    [paymentStatus]
  )

  if (!open) {
    return <></>
  }

  const descriptionFull = `This order (#${orderNumber}) will be canceled and payment refund will be made manually by Ovaflopick during 5 working days. Please contact us if you have any additional questions.`
  const descriptionPart = `This order (#${orderNumber}) will be canceled. Please contact us if you have any additional questions.`

  const Description = isAvailableToPay ? descriptionPart : descriptionFull
  const DescriptionTx: TextTranslate = isAvailableToPay
    ? 'declineOrder.modal.descriptionPart'
    : 'declineOrder.modal.description'

  const descriptionValues = {
    orderNumber
  }

  return (
    <QuestionModal
      title="Decline Order"
      titleTx="declineOrder.modal.title"
      successText="Confirm"
      successTx="declineOrder.modal.success"
      onClose={onClose}
      onSubmit={onSubmit}
    >
      <Text
        className={classes.title}
        color="darkBlue"
        preset="h4"
        text="Are you sure?"
        tx="declineOrder.modal.secondaryTitle"
      />
      <Text
        className={classes.description}
        color="inactive"
        preset="body"
        text={Description}
        tx={DescriptionTx}
        values={descriptionValues}
      />
      {!isAvailableToPay && (
        <>
          <Text
            className={classes.refundTitle}
            color="darkBlue"
            preset="h6"
            text="Full order’s price will be returned:"
            tx="declineOrder.modal.refund.title"
          />
          <Text
            className={classes.refundPrice}
            color="error"
            preset="h4"
            text={totalPrice}
          />
        </>
      )}
    </QuestionModal>
  )
}
