import { getProductWithOptionPrice } from '../../providers'
import { Offer, ProductWithOptions } from '../../services'

export const getTotalPriceByProducts = (products: ProductWithOptions[]) =>
  products
    .reduce(
      (acc, prev) =>
        acc +
        prev.count *
          (prev.defaultPrice +
            prev.orderColor.extraPrice +
            prev.orderMaterial.extraPrice),
      0
    )
    .toFixed(2)

export const getPriceWithOptions = (products: ProductWithOptions[]) => {
  return products.reduce((acc, currentProduct) => {
    const fullPrice =
      acc + getProductWithOptionPrice(currentProduct) * currentProduct.count

    return fullPrice
  }, 0)
}

export const getTotalPriceByVendor = (products: Offer[]) => {
  return products.reduce((acc, currentProduct) => {
    const fullPrice = acc + currentProduct.totalPrice

    return fullPrice
  }, 0)
}
