import { gql } from '@apollo/client'

import { AVATAR_FRAGMENT, VTMT_VENDOR_FRAGMENT } from '../fragments'

export const AVATARS_QUERY = gql`
  ${AVATAR_FRAGMENT}
  query Avatars($first: Int, $last: Int, $after: String, $before: String) {
    avatars(first: $first, last: $last, after: $after, before: $before) {
      edges {
        node {
          ...AvatarFragment
        }
      }
    }
  }
`

export const VTMT_VENDORS_QUERY = gql`
  ${VTMT_VENDOR_FRAGMENT}
  query VtmtVendors(
    $filter: VtmtVendorFilterInput
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    vtmtVendors(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
    ) {
      totalCount
      edges {
        cursor
        node {
          ...VtmtVendorFragment
        }
      }
    }
  }
`

export const SHARE_AVATAR_MUTATION = gql`
  mutation ShareAvatar($avatarId: ID!, $vendorEmails: [String]!) {
    shareAvatar(avatarId: $avatarId, vendorEmails: $vendorEmails) {
      status
      errors {
        field
        message
      }
    }
  }
`
