export enum HeaderAdditionalLinks {
  NEW_ARRIVALS = '/new',
  TOP_PRODUCTS = '/top-products',
  MAKERS_DESIGNERS = '/makers',
  SALES = '/sales',
  PRODUCT = '/product',
  PRODUCT_CONSTRUCTOR = '/product-constructor',
  BASKET = '/basket',
  VENDOR = '/vendor/:id'
}
