import { createUseStyles } from 'react-jss'
import { isTablet } from 'react-device-detect'

import { CategoryStyle } from './category.types'

export const useStyle = createUseStyles({
  categories: {
    marginTop: ({ mobile }: CategoryStyle) => (mobile ? 24 : 48),
    width: isTablet && 'calc(100% - 32px)',
    padding: isTablet && '0 16px'
  },
  products: {
    marginTop: 80
  },
  breadcrumbs: {
    marginTop: 16,
    width: '100%'
  }
})
