import {
  validateFirstName,
  validateLastName,
  validatePassword,
  validatePhone
  // TODO: uncomment if change email possibility is implemented
  // validateEmail,
} from '../helper'

export const ValidatorFirstName = (value: string) => {
  if (value) {
    const isValideFirstName = validateFirstName(value)
    return isValideFirstName
  }
  return undefined
}

export const ValidatorLastName = (value: string) => {
  if (value) {
    const isValideLastName = validateLastName(value)
    return isValideLastName
  }
  return undefined
}

export const ValidationNumberPhone = (value: string) => {
  if (value) {
    const isValidePhone = validatePhone(value)
    return isValidePhone
  }
  return undefined
}
// TODO: uncomment if change email possibility is implemented
// const ValidationEmail = (value: string) => {
//   if (value) {
//     const isValideEmail = validateEmail(value)
//     return isValideEmail
//   }
//   return undefined
// }

export const ValidatorPassword = (value: string) => {
  if (value) {
    const isValidePassword = validatePassword(value)

    return isValidePassword
  }
  return undefined
}
