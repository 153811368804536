import { gql } from '@apollo/client'

import {
  BASIC_CARD_PRODUCT_FRAGMENT,
  BASIC_PRODUCT_FRAGMENT,
  CATEGORY_FRAGMENT,
  COLLECTION_FRAGMENT,
  IMAGES_FRAGMENT,
  PAGE_INFO_FRAGMENT,
  PRODUCT_FRAGMENT_FOR_PREVIEW,
  PRODUCT_PRICING_FRAGMENT,
  USER_FRAGMENT,
  USER_FRAGMENT_FOR_PRODUCT_PREVIEW
} from '../fragments'
import { FEEDBACK_FRAGMENT } from '../fragments/feedback'

/**
 * TODO: Check need gross or net
 */

export const FEATURED_PRODUCTS_QUERY = gql`
  ${COLLECTION_FRAGMENT}
  ${BASIC_CARD_PRODUCT_FRAGMENT}
  ${PRODUCT_PRICING_FRAGMENT}
  query FeaturedProducts(
    $before: String
    $after: String
    $first: Int!
    $last: Int
    $filter: ProductFilterInput
  ) {
    shop {
      homepageCollection {
        id
        products(
          first: $first
          after: $after
          last: $last
          before: $before
          filter: $filter
        ) {
          totalCount
          edges {
            cursor
            node {
              ...BasicCardProductFields
              ...ProductPricingField
              collection {
                ...CollectionFragment
              }
              category {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`

export const TOP_PRODUCTS_QUERY = gql`
  ${BASIC_PRODUCT_FRAGMENT}
  ${PRODUCT_PRICING_FRAGMENT}
  query TopProducts(
    $before: String
    $after: String
    $first: Int!
    $last: Int
    $filter: ProductFilterInput
    $sortBy: ProductOrder
  ) {
    topProducts(
      first: $first
      after: $after
      last: $last
      before: $before
      filter: $filter
      sortBy: $sortBy
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
      edges {
        cursor
        node {
          ...BasicProductFields
          ...ProductPricingField
          category {
            id
            name
          }
        }
      }
      filters
    }
  }
`

export const TOP_PRODUCTS_QUERY_GLOBAL_SEARCH = gql`
  ${BASIC_CARD_PRODUCT_FRAGMENT}
  ${PRODUCT_PRICING_FRAGMENT}
  query topProducts(
    $before: String
    $after: String
    $first: Int!
    $last: Int
    $filter: ProductFilterInput
  ) {
    topProducts(
      first: $first
      after: $after
      last: $last
      before: $before
      filter: $filter
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
      edges {
        cursor
        node {
          ...BasicCardProductFields
          ...ProductPricingField
          category {
            id
            name
          }
        }
      }
      filters
    }
  }
`

export const PREVIOSLY_SEEN_PRODUCTS_QUERY = gql`
  ${BASIC_CARD_PRODUCT_FRAGMENT}
  ${PRODUCT_PRICING_FRAGMENT}
  query PreviouslySeenProducts(
    $before: String
    $after: String
    $first: Int!
    $last: Int
    $filter: ProductFilterInput
  ) {
    previouslySeenProducts(
      first: $first
      after: $after
      last: $last
      before: $before
      filter: $filter
    ) {
      totalCount
      edges {
        cursor
        node {
          ...BasicCardProductFields
          ...ProductPricingField
          category {
            id
            name
          }
        }
      }
    }
  }
`

export const PRODUCT_BY_CATEGORY_ID = gql`
  ${COLLECTION_FRAGMENT}
  ${BASIC_CARD_PRODUCT_FRAGMENT}
  ${PRODUCT_PRICING_FRAGMENT}
  query ProductsByCategoryId(
    $id: ID!
    $before: String
    $after: String
    $first: Int!
    $last: Int
    $filter: ProductFilterInput
    $sortBy: ProductOrder
  ) {
    category(id: $id) {
      id
      name
      products(
        first: $first
        after: $after
        last: $last
        before: $before
        filter: $filter
        sortBy: $sortBy
      ) {
        totalCount
        filters
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          cursor
          node {
            ...BasicCardProductFields
            ...ProductPricingField
            collection {
              ...CollectionFragment
            }
            category {
              id
              name
            }
          }
        }
        totalCount
        filters
      }
    }
  }
`

export const PRODUCT_BY_COLLECTION_ID = gql`
  ${BASIC_CARD_PRODUCT_FRAGMENT}
  ${PRODUCT_PRICING_FRAGMENT}
  query ProductsByCollectionId(
    $id: ID!
    $before: String
    $after: String
    $first: Int!
    $last: Int
    $filter: ProductFilterInput
    $sortBy: ProductOrder
  ) {
    collection(id: $id) {
      id
      name
      products(
        first: $first
        after: $after
        last: $last
        before: $before
        filter: $filter
        sortBy: $sortBy
      ) {
        totalCount
        filters
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          cursor
          node {
            ...BasicCardProductFields
            ...ProductPricingField
          }
        }
        totalCount
        filters
      }
    }
  }
`

export const VENDOR_CATEGORIES = gql`
  query Categories(
    $before: String
    $after: String
    $first: Int!
    $last: Int
    $filter: CategoryFilterInput
  ) {
    categories(
      first: $first
      after: $after
      last: $last
      before: $before
      filter: $filter
    ) {
      edges {
        node {
          id
          name
          backgroundImage {
            url
            alt
          }
          children(first: 6) {
            edges {
              cursor
              node {
                id
                name
                backgroundImage {
                  url
                  alt
                }
                description
                slug
                level
              }
            }
            totalCount
          }
          topProducts(first: 6) {
            totalCount
            edges {
              node {
                category {
                  id
                  name
                }
              }
            }
          }
        }
        cursor
      }
      totalCount
    }
  }
`

export const PRODUCT_BY_ID = gql`
  ${COLLECTION_FRAGMENT}
  ${BASIC_PRODUCT_FRAGMENT}
  ${IMAGES_FRAGMENT}
  ${PRODUCT_PRICING_FRAGMENT}
  ${USER_FRAGMENT}
  ${FEEDBACK_FRAGMENT}
  ${CATEGORY_FRAGMENT}
  ${BASIC_CARD_PRODUCT_FRAGMENT}
  query ProductById($id: ID!) {
    product(id: $id) {
      ...BasicProductFields
      ...ProductPricingField
      collection {
        ...CollectionFragment
      }
      category {
        ...CategoryFragment
        children(first: 1) {
          edges {
            node {
              ...CategoryFragment
            }
          }
        }
        parent {
          ...CategoryFragment
          children(first: 1) {
            edges {
              node {
                ...CategoryFragment
              }
            }
          }
          parent {
            ...CategoryFragment
            children(first: 1) {
              edges {
                node {
                  ...CategoryFragment
                }
              }
            }
          }
        }
      }
      recommendations {
        ...BasicCardProductFields
        ...ProductPricingField
      }
      feedbacks {
        ...FeedbackFragment
      }
      description
      publicationDate
      vendor {
        ...UserFragment
      }
      images {
        ...Images
      }
      productType {
        id
        name
      }
      size {
        id
        name
      }
      isAvailableForPurchase
    }
  }
`

export const PRODUCT_PREVIEW_BY_ID = gql`
  ${PRODUCT_FRAGMENT_FOR_PREVIEW}
  ${IMAGES_FRAGMENT}
  ${PRODUCT_PRICING_FRAGMENT}
  ${USER_FRAGMENT_FOR_PRODUCT_PREVIEW}
  ${FEEDBACK_FRAGMENT}
  query ProductById($id: ID!) {
    product(id: $id) {
      ...ProductFragmentForPreview
      ...ProductPricingField
      feedbacks {
        ...FeedbackFragment
      }
      description
      publicationDate
      vendor {
        ...UserFragmentForProductPreview
      }
      images {
        ...Images
      }
      productType {
        id
        name
      }
      isAvailableForPurchase
    }
  }
`

export const WISHLIST_ADD_PRODUCT = gql`
  mutation WishlistAddProduct($productId: ID!) {
    wishlistAddProduct(productId: $productId) {
      wishlistErrors {
        field
        message
      }
      wishlist {
        wishlist {
          id
          createdAt
          items(first: 20) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            totalCount
          }
        }
      }
    }
  }
`

export const WISHLIST_REMOVE_PRODUCT = gql`
  mutation WishlistRemoveProduct($productId: ID!) {
    wishlistRemoveProduct(productId: $productId) {
      wishlistErrors {
        field
        message
      }
      wishlist {
        wishlist {
          id
          createdAt
          items(first: 20) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            totalCount
          }
        }
      }
    }
  }
`

export const WISHLIST = gql`
  ${COLLECTION_FRAGMENT}
  ${BASIC_CARD_PRODUCT_FRAGMENT}
  ${PRODUCT_PRICING_FRAGMENT}
  ${PAGE_INFO_FRAGMENT}
  query GetWishlist(
    $first: Int!
    $last: Int
    $after: String
    $before: String
    $search: String
    $productCategoryId: String
    $productVendorId: ID
    $productIsSale: Boolean
  ) {
    me {
      wishlist(
        first: $first
        after: $after
        before: $before
        last: $last
        search: $search
        product_Category_Id: $productCategoryId
        product_Vendor_Id: $productVendorId
        product_IsSale: $productIsSale
      ) {
        totalCount
        pageInfo {
          ...PageInfoFragment
        }
        edges {
          cursor
          node {
            id
            product {
              ...BasicCardProductFields
              ...ProductPricingField
              collection {
                ...CollectionFragment
              }
              category {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`

export const NEW_ARRIVALS_PRODUCTS = gql`
  ${COLLECTION_FRAGMENT}
  ${BASIC_CARD_PRODUCT_FRAGMENT}
  ${PRODUCT_PRICING_FRAGMENT}
  query NewArrivalsProducts(
    $before: String
    $after: String
    $first: Int!
    $last: Int
    $filter: ProductFilterInput
    $sortBy: ProductOrder
  ) {
    newArrivals(
      first: $first
      after: $after
      last: $last
      before: $before
      filter: $filter
      sortBy: $sortBy
    ) {
      totalCount
      filters
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          ...BasicCardProductFields
          ...ProductPricingField
          collection {
            ...CollectionFragment
          }
          category {
            id
            name
          }
        }
      }
    }
  }
`

export const RECOMMENDATION_PRODUCTS = gql`
  ${COLLECTION_FRAGMENT}
  ${BASIC_CARD_PRODUCT_FRAGMENT}
  ${PRODUCT_PRICING_FRAGMENT}
  query RecommendationProducts(
    $before: String
    $after: String
    $first: Int!
    $last: Int
    $filter: ProductFilterInput
  ) {
    recommendations(
      first: $first
      after: $after
      last: $last
      before: $before
      filter: $filter
    ) {
      edges {
        cursor
        node {
          ...BasicCardProductFields
          ...ProductPricingField
          collection {
            ...CollectionFragment
          }
          category {
            id
            name
          }
        }
      }
      totalCount
    }
  }
`

export const YOU_MAY_LIKE = gql`
  ${COLLECTION_FRAGMENT}
  ${BASIC_CARD_PRODUCT_FRAGMENT}
  ${PRODUCT_PRICING_FRAGMENT}
  query YouMayLike(
    $before: String
    $after: String
    $first: Int!
    $last: Int
    $filter: ProductFilterInput
  ) {
    youMayLike(
      first: $first
      after: $after
      last: $last
      before: $before
      filter: $filter
    ) {
      edges {
        cursor
        node {
          ...BasicCardProductFields
          ...ProductPricingField
          collection {
            ...CollectionFragment
          }
          category {
            id
            name
          }
        }
      }
      totalCount
    }
  }
`

export const PRODUCTS_QUERY = gql`
  ${COLLECTION_FRAGMENT}
  ${BASIC_CARD_PRODUCT_FRAGMENT}
  ${PRODUCT_PRICING_FRAGMENT}
  query Products(
    $before: String
    $after: String
    $first: Int!
    $last: Int
    $filter: ProductFilterInput
    $sortBy: ProductOrder
  ) {
    products(
      first: $first
      after: $after
      last: $last
      before: $before
      filter: $filter
      sortBy: $sortBy
    ) {
      totalCount
      filters
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          ...BasicCardProductFields
          ...ProductPricingField
          collection {
            ...CollectionFragment
          }
          category {
            id
            name
          }
        }
      }
    }
  }
`
export const PRODUCTS_QUERY_GLOBAL_SEARCH = gql`
  ${COLLECTION_FRAGMENT}
  ${BASIC_CARD_PRODUCT_FRAGMENT}
  ${PRODUCT_PRICING_FRAGMENT}
  query ProductsGlobalSearch(
    $before: String
    $after: String
    $first: Int!
    $last: Int
    $filter: ProductFilterInput
    $sortBy: ProductOrder
  ) {
    productsGlobalSearch(
      first: $first
      after: $after
      last: $last
      before: $before
      filter: $filter
      sortBy: $sortBy
    ) {
      totalCount
      filters
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          ...BasicCardProductFields
          ...ProductPricingField
          collection {
            ...CollectionFragment
          }
          category {
            id
            name
          }
        }
      }
    }
  }
`

export const ADVERTISING_PRODUCTS_QUERY = gql`
  ${BASIC_CARD_PRODUCT_FRAGMENT}
  ${PRODUCT_PRICING_FRAGMENT}
  query AdvertisingProducts(
    $before: String
    $after: String
    $first: Int!
    $last: Int
    $filter: ProductFilterInput
    $sortBy: ProductOrder
  ) {
    advertisingProducts(
      first: $first
      after: $after
      last: $last
      before: $before
      filter: $filter
      sortBy: $sortBy
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
      edges {
        cursor
        node {
          ...BasicCardProductFields
          ...ProductPricingField
          category {
            id
            name
          }
        }
      }
      filters
    }
  }
`
