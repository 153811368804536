import React, { useState } from 'react'
import { useTheme } from 'react-jss'
import { useHistory } from 'react-router-dom'

import { LightTheme } from '../../theme'
import { BasketIcon } from '../../views'
import { useAuth, useSignInModal } from '../../providers'
import { CabinetNavigatorPath } from '../../pages'

import { Content } from '../content'
import { NavLink } from '../nav-link'
import { Icon, ICONS } from '../icon'
import { Row } from '../row'
import { Text } from '../text'
import { SearchComponent } from '../searchs'

import { HeaderNavMenu } from './nav-menu'
import { useStyle } from './header-bottom-bar.styles'

export const HeaderBottomBar = () => {
  const theme: LightTheme = useTheme()
  const classes = useStyle()
  const [open, openSearch] = useState(false)

  const history = useHistory()

  const { isLoggedIn, user } = useAuth()
  const { changeOpenModal } = useSignInModal()

  const handleOnNavigate = (path: string) => () => {
    history.push(path)
  }
  const handleOnClick = (state: boolean) => () => {
    openSearch(state)
  }

  const handleOnOpenWishList = () => {
    const navigate = handleOnNavigate(CabinetNavigatorPath.WISH_LIST)

    if (!isLoggedIn) {
      if (changeOpenModal) {
        changeOpenModal(true)
      }
    }

    if (isLoggedIn) {
      navigate()
    }
  }

  const handleOnOpenProfile = () => {
    const navigate = handleOnNavigate(CabinetNavigatorPath.USER_INFO)

    if (!isLoggedIn) {
      if (changeOpenModal) {
        changeOpenModal(true)
      }
    }

    if (isLoggedIn) {
      navigate()
    }
  }

  return (
    <Row className={classes.container}>
      <Content alignItems="space-between">
        <Row fullWidth justifyContent="space-between">
          <NavLink color="black" preset="h4" text="OVAFLOPICK" to="/" />
          <HeaderNavMenu />
          <Row>
            <Row>
              <Icon
                className={classes.icon}
                src={ICONS.search}
                onClick={handleOnClick(true)}
              />
              {open && (
                <SearchComponent open={open} onClose={handleOnClick(false)} />
              )}
              <Icon
                className={classes.icon}
                fill={theme.colors.black}
                src={ICONS.favorite}
                onClick={handleOnOpenWishList}
              />
              <BasketIcon className={classes.icon} />
            </Row>
            <Row className={classes.user} onClick={handleOnOpenProfile}>
              <Icon src={ICONS.person} />
              {isLoggedIn ? (
                <Text
                  color="black"
                  preset="h6"
                  text={`${user?.firstName} ${user?.lastName}`}
                />
              ) : (
                <Text color="black" preset="h6" text="MYOVAFLOPICK" />
              )}
            </Row>
          </Row>
        </Row>
      </Content>
    </Row>
  )
}
