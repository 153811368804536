import React, { FC, useMemo } from 'react'

import { Row } from '../../row'
import { Column } from '../../column'
import { Text } from '../../text'
import { Button } from '../../button'
import { getAvaliableToDelivery, getAvaliableToPay } from '../../helpers'
import { InfoRow } from '../../info-row'

import { OrderStaging } from '../order-staging'

import { useStyle } from './order-slider-header.styles'
import { OrderSliderHeaderProps } from './order-slider-header.types'

export const OrderSliderHeader: FC<OrderSliderHeaderProps> = ({
  number,
  created,
  price,
  mainStatus,
  paymentStatus,
  remainingTimeProductionStarted,
  onPayment,
  onOpenDelivery
}) => {
  const classes = useStyle()
  const orderIdText = `# ${number}`
  const isAvailableToPay = useMemo(
    () => getAvaliableToPay(paymentStatus, mainStatus),
    [paymentStatus, mainStatus]
  )

  const isAvailableToDelivery = useMemo(
    () => getAvaliableToDelivery(mainStatus),
    [mainStatus]
  )

  return (
    <Column fullWidth className={classes.container}>
      <Row fullWidth justifyContent="space-between">
        <Row justifyContent="flex-start">
          <Text className={classes.row} preset="h4" text={orderIdText} />
          <InfoRow
            className={classes.row}
            title="Date:"
            titleTx="order.item.date.title"
            value={created}
          />
          <InfoRow
            className={classes.row}
            title="Total Price:"
            titleTx="order.item.price.title"
            value={price}
          />
        </Row>
        <Row fullWidth justifyContent="flex-end">
          {isAvailableToPay && (
            <Button
              preset="secondaryMini"
              color="darkBlue"
              text="Payment"
              textColor="white"
              textPreset="maxButton"
              onClick={onPayment}
            />
          )}
          {isAvailableToDelivery && (
            <Button
              className={classes.deliveryButton}
              preset="secondaryMini"
              color="yellow"
              text="Delivery Tracking"
              textColor="white"
              textPreset="maxButton"
              onClick={onOpenDelivery}
            />
          )}
        </Row>
      </Row>

      <OrderStaging
        className={classes.staging}
        mainStatus={mainStatus}
        remainingTimeProductionStarted={remainingTimeProductionStarted}
      />
    </Column>
  )
}
