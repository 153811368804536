import React, { FC } from 'react'

import { Column } from '../../column'
import { Text } from '../../text'

import { useStyle } from './search-category.styles'
import { SearchCategoryProps } from './search-category.types'

export const SearchCategory: FC<SearchCategoryProps> = ({
  loading,
  children,
  label,
  labelTx
}) => {
  const classes = useStyle()

  if (loading) {
    return null
  }

  return (
    <Column
      className={classes.container}
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      <Text color="black" preset="h6" text={label} tx={labelTx} />
      {children}
    </Column>
  )
}
