import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  characteristics: {
    '& > :not(:first-child)': {
      marginTop: 12
    }
  },
  optionsRow: {
    '&:not(:first-child)': {
      marginTop: 4
    }
  },
  detailsColumn: {
    marginTop: 24
  },
  sliderContent: {
    marginLeft: 16
  },
  text: {
    textAlign: 'left'
  }
})
