import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    marginLeft: 28
  },
  child: {
    cursor: 'pointer',
    display: 'flex',
    marginTop: 24,
    '&:hover': {
      color: theme.colors.yellow
    }
  }
}))
