import { createUseStyles } from 'react-jss'
import { ProductsDataViewStyles } from './products-data.types'

export const useStyle = createUseStyles({
  product: {
    marginLeft: ({ mobile }: ProductsDataViewStyles) => !mobile && 20,
    marginBottom: ({ mobile }: ProductsDataViewStyles) => (mobile ? 12 : 20)
  },
  empty: {
    marginTop: 64
  },
  '@media screen and (max-width: 480px)': {
    empty: {
      marginTop: 24
    }
  }
})
