import { ProductOptionVariant } from '../../services'

export const getModificatorValue = <T extends ProductOptionVariant>(
  id?: string,
  data?: T[]
): T | undefined => {
  if (data) {
    const active = data.find((item) => item.id === id)
    const isDefault = data.find((item) => item.isDefault === true)

    return active || isDefault
  }

  return data
}
