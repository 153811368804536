import React from 'react'
import { isMobileOnly } from 'react-device-detect'

import { Content, OrderItemConfirm, Text } from '../../components'

import { useBasket } from '../../providers'

import { useStyle } from './order-confirm.styles'

export const OrderConfirmPage = () => {
  const { draftedOrdersBulk } = useBasket()
  const classes = useStyle()

  const h1Preset = isMobileOnly ? 'h1Mobile' : 'h1'

  return (
    <Content className={classes.container} justifyContent="flex-start">
      <Text
        className={classes.title}
        text="Confirm Payment"
        tx="order.confirm.confirm.payment"
        preset={h1Preset}
        color="black"
      />
      <Text
        className={classes.titleOwerview}
        text="Please carefully check the order(s) information below and choose way to pay."
        tx="order.confirm.payment.text"
        preset="h5"
        color="inactive"
      />
      {draftedOrdersBulk.map((order) => {
        return <OrderItemConfirm key={`orderConfirm_${order.id}`} {...order} />
      })}
    </Content>
  )
}
