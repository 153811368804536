import { OFFER_COLOR_FRAGMENT } from './color'
import { OFFER_MATERIAL_FRAGMENT } from './material'
import { OFFER_OPTION_FRAGMENT, OPTION_VARIANT_FRAGMENT } from './option'

export const OFFER_ITEM_FRAGMENT = `
${OFFER_MATERIAL_FRAGMENT}
${OFFER_COLOR_FRAGMENT}
${OFFER_OPTION_FRAGMENT}
${OPTION_VARIANT_FRAGMENT}
fragment OfferItemFragment on OfferItem {
  id
  quantity
  totalItemPrice
  unitIds
  productMaterials {
    ...OfferMaterialFragment
  }
  productColors {
    ...OfferColorFragment
  }
  productOptions {
    ...OfferOptionFragment
  }
  options {
    ...OptionVariantFragment
  }
}
`
