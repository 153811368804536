// a library to wrap and simplify api calls
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { relayStylePagination } from '@apollo/client/utilities'

import { config, NodeEnv } from '../config'

import { Api } from './api.types'
import { authService } from './auth'
import { avatarService } from './avatar'
import { categoryService } from './category'
import { collectionService } from './collection'
import { countryService } from './country'
import { menuService } from './menu'
import { newsService } from './news'
import { orderServise } from './order'
import { offerService } from './offer'
import { productService } from './product'
import { vendorServise } from './vendor'
import { feedbackService } from './feedback'
import { paymentService } from './payment'
import { adminService } from './admin'
import { deliveryService } from './delivery-price'
import { instructionServise } from './instruction'

export const defaultOptions: any = {
  fetchPolicy: 'no-cache'
}

const queryListPoliciesAggregator = (queryNames: string[]) =>
  queryNames.reduce(
    (acc, query) => ({
      ...acc,
      [query]: relayStylePagination()
    }),
    {}
  )

const queryFields = queryListPoliciesAggregator([
  'vendors',
  'topProducts',
  'newArrivals',
  'products',
  'advertisingProducts',
  'latestNewsList',
  'offers',
  'orders',
  'orderHistory',
  'productsGlobalSearch',
  'categoriesGlobalSearch',
  'vendorsGlobalSearch'
])

// our "constructor"
const create = (baseURL = '/graphql/'): Api => {
  let customHeaders = {}

  const httpLink = createHttpLink({
    uri: baseURL
  })

  const setHeader = (key: string, prop: string) => {
    customHeaders = { ...customHeaders, [key]: prop }
  }

  const headerLink = setContext((_, { headers: prevHeader }) => {
    return {
      headers: {
        ...prevHeader,
        ...customHeaders
      }
    }
  })

  const api = new ApolloClient({
    connectToDevTools: config.NODE_ENV !== NodeEnv.PRODUCTION,
    link: headerLink.concat(httpLink),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            ...queryFields
            // vendors: {
            //   // Don't cache separate results based on
            //   // any of this field's arguments.
            //   keyArgs: false,

            //   // Concatenate the incoming list items with
            //   // the existing list items.
            //   merge(existing = { edges: [] }, incoming) {
            //     console.log(existing.edges, incoming.edges)
            //     // eslint-disable-next-line no-debugger
            //     // debugger
            //     return {
            //       ...existing,
            //       ...incoming,
            //       edges: [...existing.edges, ...incoming.edges]
            //     }
            //   }
            // }
          }
        },
        Category: {
          fields: {
            products: relayStylePagination()
          }
        },
        Collection: {
          fields: {
            products: relayStylePagination()
          }
        },
        User: {
          fields: {
            wishlist: relayStylePagination(),
            orders: relayStylePagination(),
            ordersToReturn: {
              keyArgs: false,
              merge(existing = { edges: [] }, incoming) {
                return {
                  ...existing,
                  ...incoming,
                  edges: [...existing.edges, ...incoming.edges]
                }
              }
            }
          }
        },
        Shop: {
          fields: {
            products: relayStylePagination()
          }
        }
      }
    })
  })

  return {
    // a list of the API functions from step 2
    api,
    setHeader,
    admin: adminService(),
    auth: authService({ setHeader }),
    avatar: avatarService(),
    menu: menuService(),
    product: productService(),
    collection: collectionService(),
    category: categoryService(),
    country: countryService(),
    news: newsService(),
    order: orderServise(),
    offer: offerService(),
    vendor: vendorServise(),
    feedback: feedbackService(),
    payment: paymentService(),
    deliveryPrice: deliveryService(),
    instruction: instructionServise()
  }
}

// let's return back our create method as the default.
export const api = {
  create
}
