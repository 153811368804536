import React, { useMemo } from 'react'

import { useAuth, useBasket, useToastify } from '../../providers'
import {
  Column,
  NavLinkIconRow,
  Row,
  Text,
  Icon,
  ICONS,
  ResponsiveMediaView
} from '../../components'

import { useStyle } from './cabiner-navigator.styles'
import { CabinetNavigatorPath } from './cabinet-navigator.types'

export const CabinetNavigator = () => {
  const classes = useStyle()

  const { onLogout, unreadMessages, newOffers } = useAuth()
  const { onClearBasket } = useBasket()
  const { open } = useToastify()

  const OfferCount = useMemo(() => {
    if (newOffers) {
      return newOffers
    }
    return 0
  }, [newOffers])

  const MessagesCount = useMemo(() => {
    if (unreadMessages) {
      return unreadMessages
    }
    return 0
  }, [unreadMessages])

  const handleOnClick = (state: boolean) => () => {
    if (state) {
      open({
        text: 'You are now logged out',
        tx: 'header.logout.plate'
      })
    }
  }
  const handleOnLogout = () => {
    const openToastify = handleOnClick(true)
    if (onLogout && onClearBasket) {
      onClearBasket()
      onLogout()
      openToastify()
    }
  }
  const profileName = (
    <Row fullWidth justifyContent="flex-start">
      <Text
        color="black"
        preset="h4"
        text="My Profile"
        tx="cabinet.route.title"
      />
    </Row>
  )

  const logoutMobile = (
    <Row className={classes.logoutRow} onClick={handleOnLogout}>
      <Icon src={ICONS.logoutMobile} />
      <Text
        className={classes.logoutText}
        color="black"
        preset="h7"
        text="Logout"
        tx="header.mobile.logout"
      />
    </Row>
  )

  return (
    <Column
      fullWidth
      justifyContent="flex-start"
      alignItems="flex-start"
      className={classes.container}
    >
      <ResponsiveMediaView medium={profileName} bigScreen={profileName} />

      <NavLinkIconRow
        className={classes.navLink}
        icon={ICONS.permIdentity}
        text="User Info"
        to={CabinetNavigatorPath.USER_INFO}
        tx="cabinet.route.userInfo.title"
      />
      <NavLinkIconRow
        className={classes.navLink}
        icon={ICONS.favoriteBorder}
        text="Wish List"
        to={CabinetNavigatorPath.WISH_LIST}
        tx="cabinet.route.wishList.title"
      />
      <NavLinkIconRow
        className={classes.navLink}
        icon={ICONS.history}
        text="Purchase History"
        to={CabinetNavigatorPath.PURCHASE_HISTORY}
        tx="cabinet.route.purchaseHistory.title"
      />
      <Row fullWidth>
        <NavLinkIconRow
          className={classes.navLink}
          icon={ICONS.chat}
          text="Vendor Communication"
          to={CabinetNavigatorPath.VENDOR_COMMUNICATION}
          tx="cabinet.route.vendorCommunication.title"
        />
        {MessagesCount > 0 && (
          <Row className={classes.count}>
            <Text
              color="white"
              preset="secondTitle"
              text={String(MessagesCount)}
            />
          </Row>
        )}
      </Row>
      <NavLinkIconRow
        className={classes.navLink}
        icon={ICONS.workOutline}
        text="Orders Control"
        to={CabinetNavigatorPath.ORDERS_CONTROL}
        tx="cabinet.route.ordersControl.title"
      />
      <NavLinkIconRow
        className={classes.navLink}
        icon={ICONS.settings}
        text="Profile Settings"
        to={CabinetNavigatorPath.PROFILE_SETTINGS}
        tx="cabinet.route.profileSettings.title"
      />
      <NavLinkIconRow
        className={classes.navLink}
        icon={ICONS.accessibility}
        text="VTMT/Avatar"
        to={CabinetNavigatorPath.VTMT_AVATAR}
        tx="cabinet.route.vtmt.title"
      />
      <Row fullWidth>
        <NavLinkIconRow
          className={classes.navLink}
          icon={ICONS.list}
          text="Offers"
          to={CabinetNavigatorPath.OFFERS}
          tx="cabinet.route.offers.title"
        />
        {OfferCount > 0 && (
          <Row className={classes.count}>
            <Text
              color="white"
              preset="secondTitle"
              text={String(OfferCount)}
            />
          </Row>
        )}
      </Row>

      <ResponsiveMediaView mobile={logoutMobile} />
    </Column>
  )
}
