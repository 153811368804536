import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  extra: {
    padding: '5px 4px',
    background: theme.colors.vendorProfileFon
  }
}))
