import React, { FC } from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import {
  Button,
  Column,
  Loader,
  Row,
  ResponsiveMediaView
} from '../../components'

import { ProductsDataView } from '../products-data'

import { ProductsHeader } from './products-header'
import { useStyle } from './products.styles'
import { ProductsViewProps, LocationState } from './products.types'

export const ProductsView: FC<ProductsViewProps> = ({
  className,
  count = 0,
  children,
  data,
  loading,
  emptyTitle,
  emptyTitleTx,
  onLoadNext,
  onSort
}) => {
  const location = useLocation<LocationState>()
  const history = useHistory()
  const isWishlist = history.location.pathname.includes('/wish-list')
  const classes = useStyle({ wishlist: isWishlist })

  const FullHeader = (
    <ProductsHeader className={classes.header} count={count} onSort={onSort}>
      {children}
    </ProductsHeader>
  )

  const isShowButton = count > data.length

  return (
    <Column fullWidth className={className} justifyContent="flex-start">
      <Column fullWidth className={classes.container}>
        {!isWishlist && (
          <ResponsiveMediaView bigScreen={FullHeader} medium={FullHeader} />
        )}
        {children}

        <Row
          fullWidth
          alignItems="flex-start"
          className={classes.productsDataRow}
        >
          <ProductsDataView
            categoryBreadcrumb={location.state?.categoryBreadcrumb}
            count={count}
            className={classes.productContainer}
            data={data}
            emptyTitle={emptyTitle}
            emptyTitleTx={emptyTitleTx}
          />
        </Row>
        {loading && <Loader />}
        {isShowButton && (
          <Column fullWidth className={classes.load}>
            <Button
              className={classes.button}
              color="yellow"
              preset="button3"
              textColor="white"
              textPreset="h5"
              text="Load More"
              onClick={onLoadNext}
            />
          </Column>
        )}
      </Column>
    </Column>
  )
}
