import React, { FC } from 'react'

import { Column, Text } from '../../../components'

import { useStyle } from './empty-products.styles'
import { EmptyProductsProps } from './empty-products.types'

export const EmptyProducts: FC<EmptyProductsProps> = ({
  className,
  title,
  titleTx
}) => {
  const classes = useStyle()

  return (
    <Column
      className={`${className} ${classes.image}`}
      alignItems="center"
      justifyContent="center"
    >
      <Column>
        <Text
          className={classes.saleText}
          preset="h5"
          text={title}
          tx={titleTx}
        />
      </Column>
    </Column>
  )
}
