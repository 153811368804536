import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {},
  product: {
    '&:hover': {
      background: theme.colors.white,
      boxShadow: `4px 4px 16px ${theme.colors.shadow}`
    },
    transition: 'box-shadow 0.25s ease-in-out'
  }
}))
