import React, { FC } from 'react'

import { Column } from '../../column'

import { useStyle } from './carousel-dot.styles'
import { CarouselDotProps } from './carousel-dot.types'

export const CarouselDot: FC<CarouselDotProps> = ({
  active,
  className = '',
  onClick
}) => {
  const classes = useStyle({ active })

  return <Column className={`${className} ${classes.dot}`} onClick={onClick} />
}
