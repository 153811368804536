import React, { FC } from 'react'
import { isMobileOnly } from 'react-device-detect'

import { Button } from '../../button'
import { Column } from '../../column'
import { Text } from '../../text'
import { ResponsiveMediaView } from '../../responsive-media-view'

import { PromotionBannerCarouselItemProps } from './promotion-banner-carousel-item.types'
import { useStyle } from './promotion-banner-carousel-item.styles'

export const PromotionBannerCarouselItem: FC<
  PromotionBannerCarouselItemProps
> = ({ banner, className = '', imageClassName = '' }) => {
  const classes = useStyle()

  const textPreset = isMobileOnly ? 'h1' : 'mainBanner'

  const Banner = (
    <img
      className={imageClassName}
      height="60vh"
      width="100%"
      src={banner.image}
    />
  )

  const BannerMobile = (
    <img
      className={imageClassName}
      height="75vh"
      width="100%"
      src={banner.mobileImage}
    />
  )
  return (
    <Column
      fullWidth
      fullHeight
      className={`${className} ${classes.container}`}
    >
      <ResponsiveMediaView
        bigScreen={Banner}
        medium={Banner}
        tablet={Banner}
        mobile={BannerMobile}
      />
      <Column className={classes.bannerOverflow}>
        <Text
          className={classes.text}
          color="blue"
          preset="h3"
          text={banner.title || ''}
        />
        <Text
          className={classes.text}
          color="black"
          preset={textPreset}
          text={banner.subtitle || ''}
        />
        <a className={classes.anchor} href={banner.buttonLink}>
          <Button
            className={classes.button}
            text={banner.buttonText || ''}
            preset="primary"
            textColor="white"
            textPreset="h5"
          />
        </a>
      </Column>
    </Column>
  )
}
