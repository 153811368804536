import React from 'react'

import { Row } from '../../../components'

import { ProductPreviewCarousel } from '../product-preview-carousel'

import { ProductPreviewGalleryProps } from './product-preview-gallery.types'
import { useStyle } from './product-preview-gallery.styles'

export const ProductPreviewGallery = ({
  images
}: ProductPreviewGalleryProps) => {
  const classes = useStyle()

  return (
    <Row
      fullWidth
      className={classes.container}
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      <ProductPreviewCarousel className={classes.list} data={images} />
    </Row>
  )
}
