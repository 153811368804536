import React, { FC } from 'react'
import { isMobileOnly } from 'react-device-detect'

import { ObserveButton, getPriceWithSymbol } from '../../views'
import { useBasket } from '../../providers'

import { Text } from '../text'
import { Row } from '../row'
import { Column } from '../column'
import { Icon, ICONS } from '../icon'
import { ResponsiveMediaView } from '../responsive-media-view'

import { useStyle } from './order-preview-offer-item.styles'
import { OrderPreviewOfferItemProps } from './order-preview-offer-item.types'

export const OrderPreviewOfferItem: FC<OrderPreviewOfferItemProps> = ({
  className,
  id,
  image,
  name,
  offerQuantity,
  productTotalPrice,
  totalPrice,
  currency,
  material,
  colorName,
  colorCode,
  options,
  offerOptions
}) => {
  const { onRemoveProduct } = useBasket()
  const classes = useStyle()
  const Container = isMobileOnly ? Column : Row

  const handleOnRemove = () => {
    if (onRemoveProduct) {
      onRemoveProduct(id)
    }
  }
  const Delete = (
    <Column fullHeight justifyContent="flex-start">
      <Icon src={ICONS.deleteIcon} onClick={handleOnRemove} />
    </Column>
  )
  return (
    <Container className={`${className} ${classes.container}`}>
      <Row
        fullWidth
        className={classes.item}
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <img className={classes.img} src={image} />
        <Column className={classes.nameText} alignItems="flex-start">
          <Text text={name} preset="h5" />
          <Row fullWidth justifyContent="space-between">
            <ObserveButton
              className={classes.observeOptionsBtn}
              colorCode={colorCode}
              colorTitle={colorName}
              material={material}
              offerOptions={offerOptions}
              options={options}
              nameTx="order.preview.item.observe.options"
            />
            <ResponsiveMediaView mobile={Delete} />
          </Row>
        </Column>
      </Row>
      {!isMobileOnly ? (
        <>
          <Text
            className={classes.itemPrice}
            preset="h5"
            text={getPriceWithSymbol(productTotalPrice, currency)}
          />

          <Row className={classes.itemText} justifyContent="flex-start">
            <Text
              className={classes.itemPrice}
              preset="h5"
              text={String(offerQuantity)}
            />
          </Row>
          <Row className={classes.itemLastText} justifyContent="space-between">
            <Text text={getPriceWithSymbol(totalPrice, currency)} preset="h5" />
          </Row>
          <Column fullHeight justifyContent="flex-start">
            <Icon src={ICONS.deleteIcon} onClick={handleOnRemove} />
          </Column>
        </>
      ) : (
        <Row fullWidth justifyContent="space-between">
          <Row className={classes.itemText} justifyContent="flex-start">
            <Text
              className={classes.itemPrice}
              preset="h5"
              text={String(offerQuantity)}
            />
          </Row>
          <Row className={classes.itemLastText} justifyContent="flex-end">
            <Text text={getPriceWithSymbol(totalPrice, currency)} preset="h5" />
          </Row>
        </Row>
      )}
    </Container>
  )
}
