import * as React from 'react'

import { Icon } from '../icon'

import { useStyle } from './social-media-icon.styles'
import { SocialMediaIconProps } from './social-media-icon.types'

export const SocialMediaIcon: React.FC<SocialMediaIconProps> = ({
  className,
  medium,
  target = '_blank'
}) => {
  const classes = useStyle()
  return (
    <a
      className={className}
      href={medium.href}
      target={target}
      aria-label={medium.ariaLabel}
    >
      <Icon className={classes.socialIcon} src={medium.path} />
    </a>
  )
}
