import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  button: {
    height: 54,
    width: 204
  },
  submitButton: {
    width: 204,
    marginLeft: 24
  },
  buttonsRow: {},
  slider: {
    border: `1px solid ${theme.colors.shadow}`,
    borderRadius: 4
  },

  '@media screen and (max-width: 480px)': {
    button: {
      height: 58,
      width: '100%'
    },

    submitButton: {
      width: '100%',
      marginLeft: 0,
      padding: 14
    },
    buttonsRow: {
      columnGap: 24
    }
  }
}))
