import React, { FC } from 'react'
import { Icon, ICONS } from '../icon'
import { Row } from '../row'
import { Text } from '../text'
import { useStyle } from './filter-badge.styles'
import { FilterBadgeProps } from './filter-badge.types'

export const FilterBadge: FC<FilterBadgeProps> = ({
  className = '',
  name = '',
  children,
  onClick
}) => {
  const classes = useStyle()

  return (
    <Row className={`${className} ${classes.row} `} onClick={onClick}>
      {children}
      <Text
        className={classes.text}
        color="darkBlue"
        preset="body"
        text={String(name)}
      />
      <Row className={classes.icon}>
        <Icon src={ICONS.close} width={24} height={24} />
      </Row>
    </Row>
  )
}
