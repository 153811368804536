import React, { FC } from 'react'
import { isMobile } from 'react-device-detect'

import { CheckboxListByAlphabet } from '../checkbox-list-by-alphabet'
import { FilterTypeContainer } from '../filter-type-container'
import { MenuSlider } from '../menu-slider'
import { Text } from '../text'

import { useStyle } from './filter-list-by-alphabet.styles'
import { FilterListByAlphabetProps } from './filter-list-by-alphabet.types'

export const FilterListByAlphabet: FC<FilterListByAlphabetProps> = ({
  className = '',
  title,
  titleTx,
  ...checkboxProps
}) => {
  const classes = useStyle()

  const titlePreset = isMobile ? 'h5' : 'h4'
  const titleComponent = <Text text={title} tx={titleTx} preset={titlePreset} />

  if (checkboxProps.data.length > 6) {
    return (
      <MenuSlider
        className={`${className} ${classes.slider}`}
        contentClassName={classes.sliderContent}
        titleClassName={classes.sliderTitle}
        header={titleComponent}
      >
        <CheckboxListByAlphabet {...checkboxProps} />
      </MenuSlider>
    )
  }

  return (
    <FilterTypeContainer className={className} title={title} titleTx={titleTx}>
      <CheckboxListByAlphabet {...checkboxProps} />
    </FilterTypeContainer>
  )
}
