import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  listContainer: {},
  productAvatar: {
    objectFit: 'cover'
  },
  title: {
    marginLeft: 16,
    width: 300
  },
  line: {
    padding: '12px 0px',
    '&:not(:first-child)': {
      marginTop: 16
    }
  },
  observeOptionsBtn: {
    marginTop: 12,
    padding: '8px 6px'
  },
  column: {
    maxWidth: 260
  },
  infoRow: {},
  delivery: {
    wordBreak: 'break-word'
  },

  '@media screen and (max-width: 480px)': {
    listContainer: {
      width: 'calc(100% - 32px)',
      padding: 16
    },
    title: {
      width: '100%'
    },
    line: {
      padding: 0
    },
    infoRow: {
      marginTop: 24,
      justifyContent: 'space-around'
    }
  }
})
