import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../theme'

import { CategoryBreadcrumbs } from '../category-breadcrumbs'
import { Icon, ICONS } from '../icon'
import { Row } from '../row'
import { Text } from '../text'

import { useStyle } from './product-breadcrumbs.styles'
import { ProductBreadcrumbsProps } from './product-breadcrumbs.types'

export const ProductBreadcrumbs: FC<ProductBreadcrumbsProps> = ({
  className = '',
  product
}) => {
  const theme = useTheme<LightTheme>()
  const classes = useStyle()

  return (
    <Row wrap fullWidth justifyContent="flex-start" className={className}>
      <CategoryBreadcrumbs category={product.category} />
      <Row className={classes.icon}>
        <Icon fill={theme.colors.inactive} src={ICONS.arrowBreadcrumbs} />
      </Row>
      <Text
        className={classes.breadcrumbs}
        color="black"
        preset="h6"
        text={product.name}
      />
    </Row>
  )
}
