import React from 'react'
import { useHistory } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'

import { Column, Content, Text, GoBackContainer } from '../../components'

import { DeliveryInfoOrderView } from '../delivery-info-order-view'

import { useStyle } from './delivery-info.styles'

export const DeliveryInfoPage = () => {
  const history = useHistory()

  const classes = useStyle()

  const h1Preset = isMobileOnly ? 'h1Mobile' : 'h1'
  const textPreset = isMobileOnly ? 'h5' : 'h4'

  const handleGoBack = () => history.goBack()

  return (
    <Content justifyContent="flex-start" className={classes.container}>
      <GoBackContainer
        className={classes.breadcrumbs}
        text="Back to Shopping Cart"
        textTx="delivery.info.back.to.shopping.cart"
        onClick={handleGoBack}
      />
      <Content
        className={classes.content}
        preset="part"
        justifyContent="flex-start"
      >
        <Column fullWidth>
          <Text
            className={classes.text}
            text="Your Delivery Info"
            tx="delivery.info.title"
            preset={h1Preset}
          />
          <Text
            className={classes.text}
            text="Please confirm your delivery info  for order"
            tx="delivery.info.text"
            preset={textPreset}
            color="inactive"
          />
          <DeliveryInfoOrderView />
        </Column>
      </Content>
    </Content>
  )
}
