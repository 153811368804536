import {
  PRODUCT_FRAGMENT_FOR_OFFER,
  PRODUCT_PRICING_FRAGMENT_FOR_OFFER
} from './product'
import { OFFER_FRAGMENT_FOR_ORDER } from './offer'
import { OFFER_ITEM_FRAGMENT } from './items'

export const ORDER_ADDRESS_FRAGMENT = `
  fragment OrderAddressFragment on Address {
    id
    firstName
    lastName
    companyName
    streetAddress1
    streetAddress2
    city
    cityArea
    postalCode
    country {
      country
      code
    }
    countryArea
    phone
    isDefaultShippingAddress
    isDefaultBillingAddress
  }
`

export const ORDER_ERROR_FRAGMENT = `
  fragment OrderErrorFragment on OrderError {
    field
    message
    code
    warehouse
    orderLine
  }
`

export const COUNTRY_FRAGMENT = `
fragment CountryFragment on Address {
  country {
    country
    code
  }
  countryArea
}
`

export const ORDER_TOTAL_FRAGMENT = `
  fragment OrderTotalFragment on TaxedMoney {
    currency
    gross {
      currency
      amount
    }
    net {
      currency
      amount
    }
    tax {
      currency
      amount
    }
  }
`

export const ORDER_USER_FRAGMENT = `
  ${ORDER_ADDRESS_FRAGMENT}
  fragment OrderUserFragment on User {
    id
    email
    firstName
    lastName
    sex
    status
    activity
    averageResponseTime
    phone
    city
    companyName
    country
    state
    username
    defaultShippingAddress {
      ...OrderAddressFragment
    }
    avatar {
      url
      alt
    }
  }
`

export const ORDER_VENDOR_FRAGMENT = `
${COUNTRY_FRAGMENT}
  fragment OrderVendorFragment on User {
    id
    email
    firstName
    lastName
    sex
    status
    activity
    averageResponseTime
    phone
    city
    companyName
    country
    state
    username
    avatar {
      url
      alt
    }
    defaultBillingAddress {
      ...CountryFragment
    }
  }
`

export const ORDER_LINE_FRAGMENT = `
  ${PRODUCT_FRAGMENT_FOR_OFFER}
  ${PRODUCT_PRICING_FRAGMENT_FOR_OFFER}
  ${OFFER_ITEM_FRAGMENT}
  fragment OrderLineFragment on OrderLine {
    productName
    variantName
    productSku
    isShippingRequired
    quantity
    quantityFulfilled
    taxRate
    totalPrice
    product {
      ...ProductFragmentForOffer
      ...ProductPricingFieldForOffer
    }
    items {
      ...OfferItemFragment
    }
  }
`

export const ORDER_FRAGMENT = `
  ${ORDER_LINE_FRAGMENT}
  ${ORDER_TOTAL_FRAGMENT}
  ${OFFER_FRAGMENT_FOR_ORDER}
  ${ORDER_VENDOR_FRAGMENT}
  ${ORDER_USER_FRAGMENT}
  fragment OrderFragment on Order {
    id
    created
    startDate
    finishDate
    status
    mainStatus
    paymentStatus
    number
    totalPrice
    deliveryPrice
    deliveryTrackingLink
    lines {
      ...OrderLineFragment
    }
    total {
      ...OrderTotalFragment
    }
    vendor {
      ...OrderVendorFragment
    }
    user {
      ...OrderUserFragment
    }
    offer {
      ...OfferFragmentForOrder
    }
 
  }
`

export const FEEDBACK_ORDER_FRAGMENT = `
  ${ORDER_LINE_FRAGMENT}
  ${OFFER_FRAGMENT_FOR_ORDER}
  fragment FeedbackOrderFragment on Order {
    id
    created
    startDate
    finishDate
    status
    mainStatus
    paymentStatus
    lines {
      ...OrderLineFragment
    }
    offer {
      ...OfferFragmentForOrder
    }
    number
    totalPrice
  }
`
