import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  line: {
    borderTop: `1px solid ${theme.colors.shadow}`
  },
  column: {
    '&:not(:first-child)': {
      marginTop: 8
    }
  },
  itemRow: {
    flex: 1
  },
  row: {
    flex: 2.5
  },
  infoRow: {
    padding: '16px 0'
  },
  delivery: {
    wordBreak: 'break-word'
  }
}))
