import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  productInfo: {
    '& > *:not(:first-child)': {
      marginTop: 24
    },
    '& > *:last-child': {
      marginTop: 16
    }
  },
  title: {
    width: 'calc(100% - 64px)'
  },
  favorite: {
    background: theme.colors.white,
    borderRadius: 40,
    boxShadow: `1px 2px 8px ${theme.colors.shadow}`,
    height: 40,
    position: 'absolute',
    right: 16,
    top: 6,
    width: 40,
    cursor: 'pointer'
  },
  price: {
    marginLeft: 4
  }
}))
