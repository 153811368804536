import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  header: {
    backgroundColor: theme.colors.white
  },
  statusRow: {
    width: 160
  },
  statusColumn: {
    marginLeft: 24
  },
  status: {
    marginTop: 4
  },
  companyColumn: {
    marginLeft: 42
  },
  priceColumn: {
    marginTop: 8
  },
  button: {
    width: 120,
    marginLeft: 16
  },
  productAvatar: {
    objectFit: 'cover'
  },
  '@media screen and (max-width: 480px)': {
    header: {
      borderBottom: `1px solid ${theme.colors.shadow}`,
      padding: 16
    },
    companyColumn: {
      marginLeft: 0,
      marginTop: 16
    }
  }
}))
