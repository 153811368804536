import { isMobile } from 'react-device-detect'
import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colors.lightBlue
    }
  },
  image: {
    background: theme.colors.white,
    objectFit: 'cover'
  },
  details: {
    padding: !isMobile && '0 12px',
    marginLeft: isMobile && 12,
    maxWidth: isMobile ? 250 : 328
  },
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}))
