import React, { FC } from 'react'
import { isMobileOnly } from 'react-device-detect'

import { getPriceWithSymbol } from '../../views'

import { Button } from '../button'
import { Column } from '../column'
import { InfoRow } from '../info-row'
import { ItemLineMobile } from '../item-line'
import { Row } from '../row'

import { useStyle } from './shopping-item-line.styles'
import { ShoppingItemLineProps } from './shopping-item-line.types'

export const ShoppingItemLineMobile: FC<ShoppingItemLineProps> = ({
  className,
  productName,
  imgUrl,
  quantity,
  days,
  items,
  currency,
  noSuggestion,
  description,
  discount,
  productDefaultPrice,
  totalOfferPrice,
  onContactVendor
}) => {
  const classes = useStyle()

  return (
    <ItemLineMobile
      className={className}
      productName={productName}
      imgUrl={imgUrl}
      noSuggestion={noSuggestion}
      description={description}
      discount={discount}
      totalOfferPrice={totalOfferPrice}
      productDefaultPrice={productDefaultPrice}
      currency={currency}
      items={items}
    >
      <Column fullWidth className={classes.infoRows}>
        <InfoRow
          title="Production Days:"
          titleTx="shopping.cart.production.days"
          value={String(days)}
        />
        <InfoRow
          className={classes.row}
          title="Item Quantity:"
          titleTx="shopping.cart.units.quantity"
          value={String(quantity)}
        />
        <InfoRow
          className={classes.row}
          title="Order Price:"
          titleTx="shopping.cart.order.price"
          value={getPriceWithSymbol(totalOfferPrice, currency)}
        />
        {isMobileOnly && (
          <Row fullWidth>
            <Button
              className={classes.contact}
              color="yellow"
              preset="borderButton"
              textColor="yellow"
              textPreset="maxButton"
              text="Contact Maker"
              tx="shopping.cart.item.contact.maker"
              onClick={onContactVendor}
            />
          </Row>
        )}
      </Column>
    </ItemLineMobile>
  )
}
