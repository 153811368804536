import { createUseStyles } from 'react-jss'
import { isMobile } from 'react-device-detect'
import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  deliveryContainer: {
    maxWidth: 1128,
    padding: '24px 0'
  },
  required: {
    marginLeft: 16
  },
  dropdawnRow: {
    width: 536,
    marginTop: 24
  },
  input: {
    maxWidth: 536,
    marginTop: 24
  },
  inputError: {},
  dropdown: {
    top: 0,
    width: 'calc(100% - 16px)',
    borderRadius: 4,
    scrollbarWidth: 'none',
    border: `1px solid ${theme.colors.inactive}`,
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  button: {
    maxWidth: 536,
    marginTop: 24,
    border: 'none'
  },
  '@media screen and (max-width: 480px)': {
    deliveryContainer: {
      padding: '24px 0px 40px'
    },
    button: {
      width: !isMobile && 488
    }
  },
  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    deliveryContainer: {
      columnGap: 16
    },
    dropdawnRow: {
      width: 'calc(50% - 10px)',
      marginTop: 24
    },
    input: {
      maxWidth: 'calc(50% - 10px)',
      marginTop: 24
    },
    button: {
      width: 'calc(50% - 10px)',
      padding: '16px 0'
    }
  }
}))
