import React, { FC } from 'react'

import {
  arrayHasElements,
  Column,
  Modal,
  ResponsiveMediaView,
  Row,
  Text
} from '../../components'

import { ObserveOptionMaterial } from './observe-options-material'
import { ObserveOptionColor } from './observe-options-color'
import { ObserveOptionPrice } from './observe-options-price'
import { ObserveOptionDescription } from './observe-options-description'
import { useStyle } from './observe-options-offer.styles'
import { ObserveOptionOfferModalProps } from './observe-options-offer.types'
import { ObserveOptionVariant } from './observe-options-variant'
import { ObserveOptionOptions } from './observe-options-options'

export const ObserveOptionsOfferModal: FC<ObserveOptionOfferModalProps> = ({
  currency,
  options,
  noSuggestion,
  offerMaterials,
  offerOptions,
  offerColors,
  description,
  open,
  itemQuantity,
  totalItemPrice,
  unitIds,
  productDefaultPrice,
  onClose
}) => {
  const classes = useStyle()

  if (!open) {
    return <></>
  }

  const isUnitIds = unitIds && arrayHasElements(unitIds)

  const textTableDesctop = (
    <Text text="Extra Production Days" color="inactive" preset="title" />
  )
  const textTableMobile = (
    <Text
      text="Extra Days"
      tx="observe.options.modal.extra.days"
      color="inactive"
      preset="title"
    />
  )

  return (
    <Modal
      className={classes.container}
      title="View Customizations"
      titleTx="observe.options.modal.title.text"
      onClose={onClose}
    >
      <Column
        fullWidth
        alignItems="flex-start"
        justifyContent="flex-start"
        className={classes.details}
      >
        <Column fullWidth className={classes.detailsHeader}>
          <ObserveOptionPrice
            title="Quantity:"
            titleTx="order.line.product.quantity"
            price={itemQuantity}
          />
          <ObserveOptionPrice
            className={classes.detailsHeaderItem}
            title="Default Price:"
            titleTx="observe.options.modal.default.price.text"
            price={productDefaultPrice}
            currency={currency}
          />
        </Column>
        <Row fullWidth className={classes.header} justifyContent="flex-end">
          <Row className={classes.headerText} justifyContent="flex-end">
            <ResponsiveMediaView
              bigScreen={textTableDesctop}
              medium={textTableDesctop}
              mobile={textTableMobile}
            />
            <Text
              className={classes.text}
              text="Extra Price"
              color="inactive"
              preset="title"
            />
          </Row>
        </Row>
        <Column fullWidth className={classes.infoContainer}>
          <ObserveOptionMaterial
            title="Material:"
            titleTx="observe.options.modal.option.material.text"
            data={offerMaterials}
            currency={currency}
          />

          <ObserveOptionColor
            title="Color:"
            titleTx="observe.options.modal.option.color.text"
            data={offerColors}
            currency={currency}
          />
          {offerOptions.map((offerOption) => (
            <ObserveOptionOptions
              key={`option_${offerOption.id}`}
              title={offerOption.title}
              data={offerOption.variants}
              currency={currency}
            />
          ))}
          {arrayHasElements(options) && (
            <ObserveOptionVariant
              title="Additional Options:"
              titleTx="observe.options.modal.additional.options.text"
              data={options}
              currency={currency}
            />
          )}
        </Column>
        <ObserveOptionPrice
          className={classes.totalPrice}
          title="Item Variant Price"
          titleTx="observe.options.modal.total.price.text"
          price={totalItemPrice}
          currency={currency}
          preset="h5"
        />

        {noSuggestion && (
          <ObserveOptionDescription
            title="Describing and suggestion:"
            titleTx="observe.options.modal.describing.suggestions.text"
            description={description}
          />
        )}
        {isUnitIds && (
          <Column alignItems="flex-start" className={classes.idsContainer}>
            <Text
              text="Item numbers:"
              tx="observe.options.modal.item.numbers"
              preset="body"
              color="inactive"
            />
            <Row wrap className={classes.ids} justifyContent="flex-start">
              {unitIds.map((unitId) => (
                <Text
                  key={`id_${unitId}`}
                  className={classes.idsItem}
                  text={unitId}
                  preset="secondTitle"
                />
              ))}
            </Row>
          </Column>
        )}
      </Column>
    </Modal>
  )
}
