import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  row: {
    padding: 8,
    borderRadius: 4,
    background: theme.colors.greyFon
  },
  icon: {
    marginLeft: 12,
    width: 24,
    height: 24
  },
  text: {
    marginLeft: 8
  }
}))
