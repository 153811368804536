import { isMobile } from 'react-device-detect'
import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {
    alignSelf: 'stretch'
  },
  title: {
    marginRight: !isMobile && 8
  }
})
