import React, { FC } from 'react'
import SVG from 'react-inlinesvg'
import { useTheme } from 'react-jss'

import { Color, LightTheme } from '../../theme'

import { IconProps } from './icon.types'

export const Icon: FC<IconProps> = ({ src, color, ...props }) => {
  const theme: LightTheme = useTheme()

  const handlePreProcessor = (nextColor?: Color) => (code: string) => {
    if (!nextColor) {
      return code
    }

    const colorFill = theme.colors[nextColor]

    return code.replace(/fill=".*?"/g, `fill="${colorFill}"`)
  }

  return (
    <SVG
      {...props}
      uniquifyIDs
      cacheRequests
      key={`active_false_${src}`}
      preProcessor={handlePreProcessor(color)}
      src={src}
    />
  )
}

export * from './basket'
