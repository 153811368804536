import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  checkbox: {
    flexDirection: 'row-reverse',
    flex: 1,
    alignSelf: 'stretch',
    borderBottom: `1px solid ${theme.colors.shadow}`
  },

  title: {},
  form: {
    marginTop: 10,
    width: '100%',
    height: 40
  },
  input: {},
  buttons: {},
  column: {},
  row: {
    height: 60,
    padding: 24
  },
  content: {
    marginTop: 8
  },
  button: {
    width: 168,
    height: 16
  },
  buttonLeft: {
    height: 14
  },
  buttonRight: {
    marginLeft: 24
  }
}))
