import React, { useEffect, useMemo, useRef, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useHistory, useParams } from 'react-router-dom'

import {
  ButtonProps,
  Column,
  Content,
  GoBackContainer,
  Loader,
  Row,
  Text,
  getCurrencySymbol
} from '../../components'
import { useApi, useChat } from '../../providers'
import { Currency, OfferStatus } from '../../services'
import { ChatOverview, DeclineOrderModal } from '../../views'

import { getColorByMainStatus, getMessageByMainStatus } from '../helper'

import { useStyle } from './order-chat.styles'
import { OrderChatParams } from './order-chat.types'

export const OrderChat = () => {
  const history = useHistory()
  const { offerId, orderId } = useParams<OrderChatParams>()
  const chatboxRef = useRef<HTMLDivElement>(null)
  const { onMountChatboxConversation } = useChat()
  const { order: orderApi } = useApi()
  const { data, refetch } = orderApi.useOrderById({ id: orderId })
  const { onSubmit } = orderApi.useOrderUpdate()

  const [openDecline, changeOpenDecline] = useState(false)

  const classes = useStyle()
  const titlePreset = isMobileOnly ? 'h1Mobile' : 'h1'
  useEffect(() => {
    if (refetch) {
      refetch({ id: orderId })
    }
  }, [offerId])

  useEffect(() => {
    if (data?.order.offer && onMountChatboxConversation) {
      const { offer } = data.order
      onMountChatboxConversation(chatboxRef, offer.conversationId)
    }
  }, [data?.order.offer])

  const breadCrumbsName =
    history.location.state === 'fromPurchaseHistory'
      ? 'Back to Purchase history'
      : 'Back to Orders'
  const breadCrumbsNameTx =
    history.location.state === 'fromPurchaseHistory'
      ? 'order.chat.back.to.purchased.history'
      : 'order.preview.back.to.orders'

  const CurrencySybmol = useMemo(() => getCurrencySymbol(Currency.USD), [])

  const handleGoBack = () => {
    history.goBack()
  }

  const handleOnChangeDeclineModal = (state: boolean) => () => {
    changeOpenDecline(state)
  }

  const handleOnDecline: ButtonProps['onClick'] = () => {
    const close = handleOnChangeDeclineModal(false)

    close()

    if (data) {
      onSubmit({
        id: orderId,
        input: {
          isCanceled: true
        }
      })
    }

    handleGoBack()
  }

  if (!data?.order.offer) {
    return (
      <Content justifyContent="flex-start">
        <GoBackContainer
          text={breadCrumbsName}
          textTx={breadCrumbsNameTx}
          onClick={handleGoBack}
        />
        <Text preset="h1" text="Orders chat" />
        <Loader />
      </Content>
    )
  }

  const { offer, mainStatus, number, paymentStatus, totalPrice } = data.order
  const isDisabled =
    offer.status !== OfferStatus.NEW && offer.status !== OfferStatus.ACCEPTED

  const statusTextProps = getMessageByMainStatus(mainStatus)

  return (
    <Content className={classes.container} justifyContent="flex-start">
      <GoBackContainer
        className={classes.breadcrumbs}
        text={breadCrumbsName}
        textTx={breadCrumbsNameTx}
        onClick={handleGoBack}
      />
      <Text preset={titlePreset} text="Order chat" />
      <Row fullWidth className={classes.content}>
        <ChatOverview
          declineText="Decline Order"
          declineTx="declineOrder.modal.title"
          deleteText="Delete Offer"
          deleteTx="deleteOffer.modal.title"
          statusColor={getColorByMainStatus(mainStatus)}
          title={`Order # ${number}`}
          titleTx="order.number"
          titleValues={{ number }}
          orderTotalPrice={data?.order.totalPrice}
          orderQuantity={data?.order.lines[0].quantity}
          statusText={statusTextProps.text}
          statusTx={statusTextProps.tx}
          offer={offer}
          orderDelievryPrice={data?.order.deliveryPrice}
          disabled={isDisabled}
          disabledObserve={isDisabled}
          currencySymbol={CurrencySybmol}
          onDecline={handleOnChangeDeclineModal(true)}
        />
        <Column
          fullWidth
          alignItems="flex-start"
          justifyContent="flex-start"
          className={classes.chatboxContainer}
        >
          <Column
            fullWidth
            alignItems="flex-start"
            justifyContent="flex-start"
            className={classes.chatbox}
            ref={chatboxRef}
          />
        </Column>
      </Row>

      <DeclineOrderModal
        open={openDecline}
        orderNumber={number}
        paymentStatus={paymentStatus}
        totalPrice={`${totalPrice}${CurrencySybmol}`}
        onClose={handleOnChangeDeclineModal(false)}
        onSubmit={handleOnDecline}
      />
    </Content>
  )
}
