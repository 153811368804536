import React from 'react'

import paymentImg from '../../assets/images/payment-icons.png'

import { Row } from '../row'

import { Text } from '../text'
import { Content } from '../content'
import { ProductInfoBottomBar } from '../product-info-bottom-bar'

import { NavSocialBlock } from './nav-social'
import { useStyle } from './nav.styles'

export const NavChatTablet = () => {
  const classes = useStyle()

  return (
    <Content>
      <ProductInfoBottomBar>
        <Row fullWidth justifyContent="space-between">
          <NavSocialBlock classNameIcon={classes.socialIcon} />
          <Row>
            <Text
              color="shadow"
              preset="secondTitle"
              text="Copyright by"
              tx="footer.copyright"
            />
            <Text
              color="yellow"
              className={classes.brand}
              preset="secondTitle"
              text="Ovaflopick"
            />
            <Row className={classes.paymentImgChat}>
              <img src={paymentImg} alt="Available for pay" />
            </Row>
          </Row>
        </Row>
      </ProductInfoBottomBar>
    </Content>
  )
}
