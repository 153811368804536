import React from 'react'

import { Column, Text } from '../../components'
import { useStyle } from './empty-avatars.styles'

export const EmptyAvatarsPage = () => {
  const classes = useStyle()
  return (
    <Column
      fullWidth
      className={classes.container}
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Text
        className={classes.title}
        preset="h3"
        text="VTMT/Avatars"
        tx="emptyAvatars.title.text"
      />
      <Column
        className={classes.image}
        alignItems="center"
        justifyContent="center"
      >
        <Column className={classes.text}>
          <Text
            preset="h4"
            text="Oops! Your Avatars list is empty"
            tx="emptyAvatars.second.title"
          />
          <Text
            className={classes.subText}
            color="inactive"
            preset="body"
            text="Create your avatar in the mobile application OVAFLOPICK and it will automatically appear in this list."
            tx="emptyAvatars.text.about.problem"
          />
        </Column>
      </Column>
    </Column>
  )
}
