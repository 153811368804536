import React, { FC } from 'react'
import { useHistory } from 'react-router'

import { Column } from '../column'
import { Row } from '../row'
import { Text } from '../text'
import { Icon, ICONS } from '../icon'

import { LoginOverlayProps } from './login-overlay.types'
import { useStyle } from './login-overlay.styles'

export const LoginOverlay: FC<LoginOverlayProps> = ({ children }) => {
  const classes = useStyle()
  const history = useHistory()

  const handleOnClose = () => {
    history.push('/')
  }
  return (
    <Column justifyContent="start" className={classes.overlay}>
      <Column justifyContent="flex-start" className={classes.content}>
        <Row
          justifyContent="space-between"
          fullWidth
          className={classes.header}
        >
          <Text
            className={classes.text}
            color="black"
            preset="h5"
            text="MYOVAFLOPICK"
          />
          <Row className={classes.svgContainer}>
            <Icon
              className={classes.icon}
              src={ICONS.close}
              onClick={handleOnClose}
            />
          </Row>
        </Row>
        {children}
      </Column>
    </Column>
  )
}
