import React, { Suspense } from 'react'
import { Switch, Redirect } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

import {
  CabinetNavigatorPath,
  UserInfoPage,
  WishListPage,
  VTMTAvatarsPage,
  OrderControlPage,
  ProfileSettingsPage,
  PurchaseHistoryPage,
  InboxPage,
  OffersPage,
  OrderChat
} from '../pages'

import {
  Column,
  HeaderAdditionalLinks,
  FooterAdditionalLinks,
  Loader,
  ResponsiveMediaView,
  LazyRoute
} from '../components'
import { useApi, useAuth, ContainerProvider } from '../providers'
import { VendorFeedbackPage } from '../views'

import { CabinetRoute } from './cabinet'
import { useStyle } from './main.styles'
import { AuthRoutes, MainRoutes } from './main.types'
import { ScrollToTop } from './scroll-to-top'
import { lazyComponent } from './lazy-component'

const HomePage = lazyComponent(import('../pages'), 'HomePage')
const LoginPage = lazyComponent(import('../pages'), 'LoginPage')
const MakerDesignerPage = lazyComponent(import('../pages'), 'MakerDesignerPage')
const NewArrivalsPage = lazyComponent(import('../pages'), 'NewArrivalsPage')
const ProductPage = lazyComponent(import('../pages'), 'ProductPage')
const SignUpPage = lazyComponent(import('../pages'), 'SignUpPage')
const TopProductsPage = lazyComponent(import('../pages'), 'TopProductsPage')
const ForgotPasswordPage = lazyComponent(
  import('../pages'),
  'ForgotPasswordPage'
)
const ForgotPasswordMobilePage = lazyComponent(
  import('../pages'),
  'ForgotPasswordMobilePage'
)
const CategoryPage = lazyComponent(import('../pages'), 'CategoryPage')
const ProductsPage = lazyComponent(import('../pages'), 'ProductsPage')
const ProductsVendorPage = lazyComponent(
  import('../pages'),
  'ProductsVendorPage'
)
const ChangePasswordPage = lazyComponent(
  import('../pages'),
  'ChangePasswordPage'
)
const ChangePasswordMobilePage = lazyComponent(
  import('../pages'),
  'ChangePasswordMobilePage'
)
const ContactUsPage = lazyComponent(import('../pages'), 'ContactUsPage')
const NotFoundPage = lazyComponent(import('../pages'), 'NotFoundPage')
const CollectionProductsPage = lazyComponent(
  import('../pages'),
  'CollectionProductsPage'
)
const DeliveryInfoPage = lazyComponent(import('../pages'), 'DeliveryInfoPage')
const ShoppingCartPage = lazyComponent(import('../pages'), 'ShoppingCartPage')
const VendorPage = lazyComponent(import('../pages'), 'VendorPage')
const LatestNewsPage = lazyComponent(import('../pages'), 'LatestNewsPage')
const NewsPage = lazyComponent(import('../pages'), 'NewsPage')
const SearchResultsPage = lazyComponent(import('../pages'), 'SearchResultsPage')
const SuccesPasswordResetPage = lazyComponent(
  import('../pages'),
  'SuccesPasswordResetPage'
)
const SuccessPasswordResetMobilePage = lazyComponent(
  import('../pages'),
  'SuccessPasswordResetMobilePage'
)
const OrderPreviewPage = lazyComponent(import('../pages'), 'OrderPreviewPage')
const OrderPreviewOfferPage = lazyComponent(
  import('../pages'),
  'OrderPreviewOfferPage'
)
const OrderConfirmPage = lazyComponent(import('../pages'), 'OrderConfirmPage')
const PaymentPage = lazyComponent(import('../pages'), 'PaymentPage')
const CookiesPage = lazyComponent(import('../pages'), 'CookiesPage')
const PrivacyPolicyPage = lazyComponent(import('../pages'), 'PrivacyPolicyPage')
const TermsOfConditionsPage = lazyComponent(
  import('../pages'),
  'TermsOfConditionsPage'
)
const SubscriptionPage = lazyComponent(import('../pages'), 'SubscriptionPage')
const CataloguePage = lazyComponent(import('../pages'), 'CataloguePage')
const CatalogueMobilePage = lazyComponent(
  import('../pages'),
  'CatalogueMobilePage'
)
const CatalogueTabletPage = lazyComponent(
  import('../pages'),
  'CatalogueTabletPage'
)
const ProductConstructorPage = lazyComponent(
  import('../pages'),
  'ProductConstructorPage'
)
const FeedbackPage = lazyComponent(import('../pages'), 'FeedbackPage')
const ReturnPage = lazyComponent(import('../pages'), 'ReturnPage')
const SelectItems = lazyComponent(import('../pages'), 'SelectItems')
const BecomePartnerPage = lazyComponent(import('../pages'), 'BecomePartnerPage')
const ProceedPaymentPage = lazyComponent(
  import('../pages'),
  'ProceedPaymentPage'
)
const OfferChat = lazyComponent(import('../pages'), 'OfferChat')
const DeliveryInfoOfferPage = lazyComponent(
  import('../pages'),
  'DeliveryInfoOfferPage'
)
const OrderConfirmOfferPage = lazyComponent(
  import('../pages'),
  'OrderConfirmOfferPage'
)

// FIXME: Cabinet routes lazy loading
// const UserInfoPage = lazyComponent(import('../pages'), 'UserInfoPage')
// const WishListPage = lazyComponent(import('../pages'), 'WishListPage')
// const PurchaseHistoryPage = lazyComponent(
//   import('../pages'),
//   'PurchaseHistoryPage'
// )
// const VTMTAvatarsPage = lazyComponent(import('../pages'), 'VTMTAvatarsPage')
// const OrderControlPage = lazyComponent(import('../pages'), 'OrderControlPage')
// const ProfileSettingsPage = lazyComponent(
//   import('../pages'),
//   'ProfileSettingsPage'
// )
// const InboxPage = lazyComponent(import('../pages'), 'InboxPage')
// const OffersPage = lazyComponent(import('../pages'), 'OffersPage')

export const MainRouter = () => {
  const classes = useStyle()
  const api = useApi()
  const { isLoggedIn, loadingSession } = useAuth()

  if (!api || !HeaderAdditionalLinks) {
    return (
      <Column fullWidth className={classes.container}>
        <Loader />
      </Column>
    )
  }

  if (typeof loadingSession !== 'boolean' || loadingSession) {
    return (
      <Column
        fullWidth
        className={`.loading-container ${classes.container}`}
        justifyContent="space-between"
      >
        <ScrollToTop />
        <ContainerProvider>
          <Loader />
        </ContainerProvider>
      </Column>
    )
  }

  return (
    <Column
      fullWidth
      className={classes.container}
      justifyContent="space-between"
    >
      <ScrollToTop />

      <ContainerProvider>
        <Column
          fullWidth
          className={classes.content}
          justifyContent="flex-start"
        >
          <Suspense fallback={<Loader />}>
            <Switch>
              <LazyRoute exact path="/">
                <HomePage />
              </LazyRoute>
              <LazyRoute exact path={MainRoutes.SHOPPING_CART}>
                <ShoppingCartPage />
              </LazyRoute>
              <LazyRoute exact path={MainRoutes.ORDER_PREVIEW}>
                <OrderPreviewPage />
              </LazyRoute>
              <LazyRoute
                exact
                path={`${MainRoutes.ORDER_PREVIEW_OFFER}/:offerId`}
              >
                <OrderPreviewOfferPage />
              </LazyRoute>
              <LazyRoute exact path={MainRoutes.DELIVERY_INFO}>
                <DeliveryInfoPage />
              </LazyRoute>
              <LazyRoute
                exact
                path={`${MainRoutes.DELIVERY_INFO_OFFER}/:offerId`}
              >
                <DeliveryInfoOfferPage />
              </LazyRoute>
              <LazyRoute exact path="/category/:id/products">
                <ProductsPage />
              </LazyRoute>
              <LazyRoute exact path="/category/:id/:vendorId/products">
                <ProductsVendorPage />
              </LazyRoute>
              <LazyRoute exact path="/category/:id">
                <CategoryPage />
              </LazyRoute>
              <LazyRoute exact path="/catalogue">
                <ResponsiveMediaView
                  bigScreen={<CataloguePage />}
                  medium={<CataloguePage />}
                  tablet={<CatalogueTabletPage />}
                  mobile={<CatalogueMobilePage />}
                />
              </LazyRoute>
              <LazyRoute exact path={MainRoutes.CONTACT_US}>
                <ContactUsPage />
              </LazyRoute>
              <LazyRoute exact path="/cookies">
                <CookiesPage />
              </LazyRoute>
              <LazyRoute exact path="/search-results/:value">
                <SearchResultsPage />
              </LazyRoute>
              <LazyRoute exact path={HeaderAdditionalLinks.NEW_ARRIVALS}>
                <NewArrivalsPage />
              </LazyRoute>
              <LazyRoute exact path={HeaderAdditionalLinks.TOP_PRODUCTS}>
                <TopProductsPage />
              </LazyRoute>
              <LazyRoute exact path={HeaderAdditionalLinks.MAKERS_DESIGNERS}>
                <MakerDesignerPage />
              </LazyRoute>
              <LazyRoute exact path={`${HeaderAdditionalLinks.PRODUCT}/:id`}>
                <ProductPage />
              </LazyRoute>
              <LazyRoute
                exact
                path={`${HeaderAdditionalLinks.PRODUCT_CONSTRUCTOR}/:id`}
              >
                <ProductConstructorPage />
              </LazyRoute>
              <LazyRoute exact path={FooterAdditionalLinks.TERMS_OF_CONDITIONS}>
                <TermsOfConditionsPage />
              </LazyRoute>
              <LazyRoute exact path={FooterAdditionalLinks.PRIVACY_POLICY}>
                <PrivacyPolicyPage />
              </LazyRoute>
              <LazyRoute exact path={FooterAdditionalLinks.COOKIE_POLICY}>
                <CookiesPage />
              </LazyRoute>
              <LazyRoute exact path={FooterAdditionalLinks.PAYMENT_DELIVERY}>
                <PaymentPage />
              </LazyRoute>
              <LazyRoute exact path={MainRoutes.BECOME_PARTNER}>
                <BecomePartnerPage />
              </LazyRoute>
              <LazyRoute exact path="/collection/:id">
                <CollectionProductsPage />
              </LazyRoute>
              <LazyRoute exact path={HeaderAdditionalLinks.VENDOR}>
                <VendorPage key={uuid()} />
              </LazyRoute>
              <LazyRoute exact path={MainRoutes.LATEST_NEWS}>
                <LatestNewsPage />
              </LazyRoute>
              <LazyRoute exact path={`${MainRoutes.LATEST_NEWS}/:id`}>
                <NewsPage />
              </LazyRoute>
              <LazyRoute exact path={MainRoutes.ORDER_CONFIRM}>
                <OrderConfirmPage />
              </LazyRoute>
              <LazyRoute
                exact
                path={`${MainRoutes.ORDER_CONFIRM_OFFER}/:orderId`}
              >
                <OrderConfirmOfferPage />
              </LazyRoute>
              <LazyRoute exact path={MainRoutes.SUBSCRIPTION}>
                <SubscriptionPage />
              </LazyRoute>
              <LazyRoute exact path={MainRoutes.FEEDBACK}>
                <FeedbackPage />
              </LazyRoute>
              <LazyRoute exact path={MainRoutes.VENDOR_FEEDBACK}>
                <VendorFeedbackPage />
              </LazyRoute>
              {isLoggedIn ? (
                <Switch>
                  <CabinetRoute path={CabinetNavigatorPath.USER_INFO}>
                    <UserInfoPage />
                  </CabinetRoute>
                  <CabinetRoute path={CabinetNavigatorPath.WISH_LIST}>
                    <WishListPage />
                  </CabinetRoute>
                  <CabinetRoute path={CabinetNavigatorPath.VTMT_AVATAR}>
                    <VTMTAvatarsPage />
                  </CabinetRoute>
                  <CabinetRoute path={CabinetNavigatorPath.ORDERS_CONTROL}>
                    <OrderControlPage />
                  </CabinetRoute>
                  <CabinetRoute path={CabinetNavigatorPath.PROFILE_SETTINGS}>
                    <ProfileSettingsPage />
                  </CabinetRoute>
                  <CabinetRoute path={CabinetNavigatorPath.PURCHASE_HISTORY}>
                    <PurchaseHistoryPage />
                  </CabinetRoute>
                  <CabinetRoute
                    path={CabinetNavigatorPath.VENDOR_COMMUNICATION}
                  >
                    <InboxPage />
                  </CabinetRoute>
                  <CabinetRoute path={CabinetNavigatorPath.OFFERS}>
                    <OffersPage />
                  </CabinetRoute>
                  <LazyRoute
                    path={`${CabinetNavigatorPath.OFFER_CHAT}/:offerId`}
                  >
                    <OfferChat />
                  </LazyRoute>
                  <LazyRoute
                    path={`${CabinetNavigatorPath.ORDER_CHAT}/:orderId${CabinetNavigatorPath.OFFER}/:offerId`}
                  >
                    <OrderChat />
                  </LazyRoute>
                  <LazyRoute exact path={`${MainRoutes.PROCEED_PAYMENT}/:id`}>
                    <ProceedPaymentPage />
                  </LazyRoute>
                  <LazyRoute exact path={MainRoutes.SELECT}>
                    <SelectItems />
                  </LazyRoute>
                  <LazyRoute exact path={FooterAdditionalLinks.RETURNS}>
                    <ReturnPage />
                  </LazyRoute>
                  <LazyRoute exact path="*">
                    <NotFoundPage />
                  </LazyRoute>
                  <Redirect to="/" />
                </Switch>
              ) : (
                <Switch>
                  <LazyRoute exact path={AuthRoutes.SIGN_IN}>
                    <LoginPage />
                  </LazyRoute>
                  <LazyRoute exact path={AuthRoutes.SIGN_UP}>
                    <SignUpPage />
                  </LazyRoute>
                  <LazyRoute exact path={AuthRoutes.FORGOT_PASSWORD}>
                    <ResponsiveMediaView
                      bigScreen={<ForgotPasswordPage />}
                      medium={<ForgotPasswordPage />}
                      tablet={<ForgotPasswordPage />}
                      mobile={<ForgotPasswordMobilePage />}
                    />
                  </LazyRoute>
                  <LazyRoute
                    exact
                    path={`${AuthRoutes.CHANGE_PASSWORD}/:email/:token`}
                  >
                    <ResponsiveMediaView
                      bigScreen={<ChangePasswordPage />}
                      medium={<ChangePasswordPage />}
                      tablet={<ChangePasswordPage />}
                      mobile={<ChangePasswordMobilePage />}
                    />
                  </LazyRoute>
                  <LazyRoute exact path={AuthRoutes.SUCCESS_PASSWORD_RESET}>
                    <ResponsiveMediaView
                      bigScreen={<SuccesPasswordResetPage />}
                      medium={<SuccesPasswordResetPage />}
                      tablet={<SuccesPasswordResetPage />}
                      mobile={<SuccessPasswordResetMobilePage />}
                    />
                  </LazyRoute>
                  <LazyRoute exact path="*">
                    <LoginPage />
                  </LazyRoute>
                </Switch>
              )}
            </Switch>
          </Suspense>
        </Column>
      </ContainerProvider>
    </Column>
  )
}
