import React, { FC } from 'react'

import { MainRoutes } from '../../../../../routes'
import { NavLink } from '../../../../nav-link'
import { Column } from '../../../../column'
import { Row } from '../../../../row'

import { useStyle } from './submenu-mobile-item.styles'
import { HeaderNavSubMenuMobileItemProps } from './submenu-mobile-item.types'

export const HeaderSubMenuMobileItem: FC<HeaderNavSubMenuMobileItemProps> = ({
  item
}) => {
  const classes = useStyle()

  return (
    <Column className={classes.container} alignItems="flex-start">
      {item.children.map((child) => (
        <Row key={child.id}>
          <NavLink
            className={classes.child}
            color="black"
            text={child.name}
            preset="h7"
            to={`${MainRoutes.CATEGORY}/${child.category.id}`}
          />
        </Row>
      ))}
    </Column>
  )
}
