import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { isTablet } from 'react-device-detect'
import { useHistory } from 'react-router-dom'

import { useApi, useAuth } from '../../providers'
import {
  Column,
  DropdownItemData,
  ProductList,
  Row,
  Loader,
  getProductListFromEdgesList,
  arrayHasElements,
  ResponsiveMediaView,
  CabinetPageContainer,
  ProductCardType
} from '../../components'
import {
  EmptyWishlist,
  EmptyProducts,
  ProductsView,
  reduceProductsToProductList
} from '../../views'
import { WishlistVariables } from '../../services'

import { WishlistHeader } from './wishlist-header'
import { useStyle } from './wish-list.styles'
import { WishlistHeaderMobile } from './wishlist-header-mobile'

const FIRST = 8
const DEFAULT_DROPDOWN_VALUE = { id: '', name: 'All', value: '' }

export const WishListPage = () => {
  const { user, onResyncUser } = useAuth()
  const {
    product: { useWishlist, useYouMayLike },
    category: { useWishlistCategories },
    vendor: { useVendorWishList }
  } = useApi()

  const [defaultTotalCount, changeDefaultTotalCount] = useState(0)
  const [data, changeData] = useState<ProductCardType[]>([])
  const [variables, changeVariables] = useState<Partial<WishlistVariables>>({
    first: FIRST
  })

  const { data: response, loading, refetch, fetchMore } = useWishlist(variables)
  const { data: youMayLikeResponse, fetchMore: fetchMoreYouMayLike } =
    useYouMayLike({
      first: FIRST
    })
  const { data: categoryResponse, refetch: refetchCategories } =
    useWishlistCategories({ first: 100 })
  const { data: vendorResponse, refetch: refetchVendors } = useVendorWishList({
    first: 100
  })

  const classes = useStyle()
  const history = useHistory()

  useEffect(() => {
    if (response?.me?.wishlist) {
      const products = getProductListFromEdgesList(response.me.wishlist)
      const nextData = reduceProductsToProductList(products)
      changeData([...data, ...nextData])

      const { totalCount } = response.me.wishlist

      if (totalCount || totalCount === 0) {
        changeDefaultTotalCount(totalCount)
      }
    }
  }, [response])

  useEffect(() => {
    refetch(variables)
  }, [])

  useEffect(() => {
    if (onResyncUser) {
      onResyncUser()
    }

    refetchCategories()
    refetchVendors()
  }, [response])

  const Wishlist = useMemo(() => {
    const wishlistData = response?.me?.wishlist
    if (wishlistData) {
      const products = getProductListFromEdgesList(wishlistData)
      return reduceProductsToProductList(products)
    }

    return []
  }, [response])

  const Categories = useMemo(() => {
    if (categoryResponse) {
      const newCategoryResponse = [
        { node: { id: '', name: 'All', value: '' } },
        ...categoryResponse.wishlistCategories.edges
      ]
      return newCategoryResponse.map<DropdownItemData>(({ node }) => ({
        id: node.id,
        name: node.name,
        value: node.id
      }))
    }

    return []
  }, [categoryResponse])

  const Vendors = useMemo(() => {
    if (vendorResponse) {
      const newVendorResponse = [
        {
          node: {
            id: '',
            firstName: 'All',
            value: '',
            companyName: 'All'
          }
        },
        ...vendorResponse.wishlistVendors.edges
      ]
      return newVendorResponse.map<DropdownItemData>(({ node }) => ({
        id: node.id,
        name: `${node.companyName}`,
        value: node.id
      }))
    }

    return []
  }, [vendorResponse])

  const YouMayLike = useMemo(() => {
    if (youMayLikeResponse) {
      const products = youMayLikeResponse.youMayLike.edges.map(
        (item) => item.node
      )
      return reduceProductsToProductList(products)
    }

    return []
  }, [youMayLikeResponse])

  const Category = useMemo(() => {
    const active = Categories.find(
      (category) => category.id === variables.productCategoryId
    )

    return active || DEFAULT_DROPDOWN_VALUE
  }, [variables.productCategoryId])

  const Vendor = useMemo(() => {
    const active = Vendors.find(
      (vendor) => vendor.id === variables.productVendorId
    )

    return active || DEFAULT_DROPDOWN_VALUE
  }, [variables.productVendorId])

  const TotalCount = useMemo(() => {
    if (response && response.me && response.me.wishlist) {
      return response.me.wishlist.totalCount
    }

    return 0
  }, [response])

  const recommendationsPreset = isTablet ? 'h2' : 'h1'
  const isYouMayLike = arrayHasElements(YouMayLike)
  const isEmptyList = TotalCount === 0
  const hasElements = defaultTotalCount > 0
  const wishListTotalcount = user ? user.wishlist.totalCount : 0
  const isWishList = wishListTotalcount > 0
  const isEmptyResult =
    isWishList && isEmptyList && !loading && variables.search
  const isEmptyWishList = !loading && !hasElements && !isWishList

  const handleOnClickHome = () => {
    history.push('/')
  }

  const handleOnSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const { value: search } = event.target
    const nextState = { ...variables, search }

    changeVariables(nextState)
  }

  const handleOnChangeDropdown =
    (prop: keyof WishlistVariables) => (filter: DropdownItemData) => {
      const nextState = {
        ...variables,
        [prop]: filter.id
      }
      changeData([])

      changeVariables(nextState)
    }

  const handleOnSale = (state: boolean) => {
    const nextState = {
      ...variables,
      productIsSale: state
    }
    changeVariables(nextState)
  }

  const handleOnNext = () => {
    if (response) {
      const productEdges = response.me.wishlist.edges
      const lastIndex = productEdges.length - 1

      fetchMore({
        variables: { ...variables, after: productEdges[lastIndex].cursor }
      })
    }
  }

  const handleOnYouMayLikeLoadNext = () => {
    if (youMayLikeResponse) {
      const { edges } = youMayLikeResponse.youMayLike
      const { cursor } = edges[edges.length - 1]

      fetchMoreYouMayLike({ variables: { first: FIRST, after: cursor } })
    }
  }

  const header = (
    <WishlistHeader
      vendor={Vendor}
      vendors={Vendors}
      category={Category}
      categories={Categories}
      onChangeSearch={handleOnSearch}
      onSale={handleOnSale}
      onChangeDropdown={handleOnChangeDropdown}
    />
  )

  const headerMobile = (
    <WishlistHeaderMobile
      vendor={Vendor}
      vendors={Vendors}
      category={Category}
      categories={Categories}
      onChangeSearch={handleOnSearch}
      onChangeDropdown={handleOnChangeDropdown}
    />
  )

  return (
    <CabinetPageContainer
      label="Wish List"
      labelTx="cabinet.route.wishList.title"
      className={classes.container}
    >
      {isWishList && (
        <ResponsiveMediaView
          bigScreen={header}
          medium={header}
          tablet={header}
          mobile={headerMobile}
        />
      )}
      <ProductsView
        count={TotalCount}
        data={Wishlist}
        loading={loading}
        onLoadNext={handleOnNext}
      />
      {isEmptyResult && (
        <Row fullWidth justifyContent="center">
          <EmptyProducts
            title="Sorry, we couldn’t find any results."
            titleTx="empty.wishlist.description"
          />
        </Row>
      )}
      <Column fullWidth justifyContent="center" alignItems="center">
        {loading && isEmptyList && <Loader />}
      </Column>

      {isEmptyWishList && (
        <Column fullWidth>
          <EmptyWishlist
            title="Oops! Your wish list is empty"
            titleTx="cabinet.route.wishList.empty.message"
            text="Fill it up with goods."
            tx="cabinet.route.wishList.empty.sub.message"
            onGoHome={handleOnClickHome}
          />
        </Column>
      )}
      {isYouMayLike && !isEmptyList && (
        <Row fullWidth className={classes.youMayLikeContainer}>
          <ProductList
            carousel
            data={YouMayLike}
            title="You may like"
            titleTx="cabinet.route.wishList.you.may.like"
            titlePreset={recommendationsPreset}
            onLoadNext={handleOnYouMayLikeLoadNext}
          />
        </Row>
      )}
    </CabinetPageContainer>
  )
}
