import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { Row } from '../row'
import { Text } from '../text'

import { useStyle } from './login-tabs.styles'
import { LoginTabsProps } from './login-tabs.types'

export const LoginTabs: FC<LoginTabsProps> = ({
  activeLogin,
  activeSignUp
}) => {
  const history = useHistory()
  const classes = useStyle()

  const handleOnSignUp = () => {
    history.push('/sign-up')
  }

  const handleOnSignIn = () => {
    history.push('/sign-in')
  }

  const signInClasses = activeLogin ? classes.active : classes.inactive
  const signUpClasses = activeSignUp ? classes.active : classes.inactive
  const signInColor = activeLogin ? 'black' : 'inactive'
  const signUpColor = activeSignUp ? 'black' : 'inactive'

  return (
    <Row className={classes.loginTabs}>
      <Row className={signInClasses}>
        <Text
          color={signInColor}
          preset="h5"
          text="Sign In"
          tx="login.signIn"
          onClick={handleOnSignIn}
        />
      </Row>
      <Row className={signUpClasses}>
        <Text
          color={signUpColor}
          preset="h5"
          text="Sign Up"
          tx="login.signUp"
          onClick={handleOnSignUp}
        />
      </Row>
    </Row>
  )
}
