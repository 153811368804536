import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  productAvatar: {
    objectFit: 'cover'
  },
  title: {
    marginLeft: 16,
    maxWidth: 232
  },
  text: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  line: {
    width: 'calc(100% - 16px)',
    padding: '16px 8px',
    '&:not(:first-child)': {
      marginTop: 16
    }
  },
  observeOptionsBtn: {
    marginTop: 12,
    padding: '8px 6px'
  },
  column: {
    flex: 1
  },
  itemRow: {
    flex: 1
  },
  infoContainer: {
    flex: 3
  },
  infoRow: {
    flex: 2,
    marginLeft: 16,
    columnGap: 8
  },
  delivery: {
    wordBreak: 'break-word'
  },
  buttonsRow: {
    flex: 1,
    maxWidth: 260,
    marginLeft: 16
  },
  buttonDecline: {
    width: 120,
    padding: '12px 20px'
  },
  buttonContact: {
    width: 120,
    padding: '12px 14px',
    marginLeft: 20
  },
  emptyRow: {
    width: 56
  },
  '@media screen and (max-width: 1536px)': {
    title: {
      maxWidth: 160
    }
  },
  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    itemRow: {
      flex: 2
    }
  },
  '@media screen and (max-width: 480px)': {
    productAvatar: {
      width: 104
    },
    line: {
      width: '100%'
    },
    infoRow: {
      marginLeft: 0,
      marginTop: 24
    },
    buttonsRow: {
      justifyContent: 'space-between',
      maxWidth: 'none',
      width: '100%',
      marginLeft: 0,
      marginTop: 24,
      columnGap: 16
    },
    buttonDecline: {
      width: '50%',
      maxWidth: '50%'
    },
    buttonContact: {
      width: '50%',
      marginLeft: 0
    }
  }
})
