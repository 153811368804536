import React, { useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { Button, Column, Text, Row, Checkbox } from '../../components'
import { ConfirmationModal } from '../../views'
import { useStyle } from './subscription-styles'
import { SubscriptionItem } from './subscription-item'

export const SubscriptionPage = () => {
  const [openConfirm, changeOpenConfirm] = useState(false)
  const titlePreset = isMobileOnly ? 'h1Mobile' : 'h1'
  const h2Preset = isMobileOnly ? 'h2Mobile' : 'h2'

  const handleOnOpenConfirm = (state: boolean) => () => {
    changeOpenConfirm(state)
  }

  const handleOnToShare = () => {
    const openModal = handleOnOpenConfirm(true)

    openModal()
  }

  const text =
    'I want to learn about what’s new in the industry and receive tips on how to be better at my job. Please send me white papers, guides, ebooks, ets.'

  const classes = useStyle()

  return (
    <Column className={classes.container} justifyContent="flex-start">
      <Column>
        <Text
          preset={titlePreset}
          text="Email Subscriptions"
          tx="email.subscriptions.title"
        />
        <Row className={classes.mail} alignItems="baseline" wrap>
          <Text
            preset="h5"
            color="inactive"
            text="Email preferences for"
            tx="email.subscriptions.second.title.mobile"
          />
          <Text preset="h4" color="inactive" text="customer123@gmail.com" />
        </Row>

        <Text
          className={classes.description}
          preset="body"
          text="As someone looking to make an impact on the world, we know how important it is for you to be conntcted to the right information at the right time. Make sure your inbox is keeping you up to date on the most important things happening across global development."
          tx="email.subscriptions.text"
        />
      </Column>
      <Column fullWidth className={classes.subText} alignItems="flex-start">
        <Text
          preset={h2Preset}
          text="Subscription Preferences: "
          tx="email.subscriptions.subscription.text"
        />
      </Column>

      <Row fullWidth className={classes.items} justifyContent="flex-start" wrap>
        <SubscriptionItem title="Premium Content" description={text} />
        <SubscriptionItem title="Premium Content" description={text} />
        <SubscriptionItem
          className={classes.subText}
          title="Product Updates"
          description={text}
        />
        <SubscriptionItem
          className={classes.subText}
          title="Newsletter"
          description={text}
        />
      </Row>
      <Column fullWidth className={classes.border} alignItems="flex-start">
        <Row className={classes.row}>
          <Checkbox />
          <Text
            className={classes.under}
            text="I do not want to receive any emails from Ovaflopick."
            tx="email.subscriptions.under.text"
          />
        </Row>
      </Column>

      <Button
        className={classes.button}
        preset="primary"
        onClick={handleOnToShare}
        textColor="white"
        textPreset="h5"
        text="UPDATE SUBSCRIPTIONS"
        tx="email.subscriptions.button.text"
      />
      <ConfirmationModal
        title="All right!"
        txTitle="confirmation.second.title.text"
        text="Your email subscription has been successfully updated."
        tx="email.subscriptions.modal.title"
        open={openConfirm}
        onClose={handleOnOpenConfirm(false)}
      />
    </Column>
  )
}
