import { useMutation, useQuery } from '@apollo/client'

import {
  AVATARS_QUERY,
  SHARE_AVATAR_MUTATION,
  VTMT_VENDORS_QUERY
} from './avatar.graphql'

import {
  AvatarApi,
  Avatars,
  AvatarsData,
  AvatarsVariables,
  ShareAvatarData,
  ShareAvatarVariables,
  VtmtVendors,
  VtmtVendorsData,
  VtmtVendorsVariables
} from './avatar.types'

// our "constructor"
export const avatarService = (): AvatarApi => {
  const useAvatars = (variables: AvatarsVariables): Avatars => {
    const { data, loading, error, refetch, fetchMore } = useQuery<
      AvatarsData,
      AvatarsVariables
    >(AVATARS_QUERY, { variables })

    if (error) {
      return { data: null, loading, refetch, fetchMore }
    }

    if (!data) {
      return { data: null, loading, refetch, fetchMore }
    }

    return { data, loading, refetch, fetchMore }
  }

  const useVtmtVendors = (variables: VtmtVendorsVariables): VtmtVendors => {
    const { data, loading, error, refetch } = useQuery<
      VtmtVendorsData,
      VtmtVendorsVariables
    >(VTMT_VENDORS_QUERY, { variables })

    if (error) {
      return { data: null, loading, refetch }
    }

    if (!data) {
      return { data: null, loading, refetch }
    }

    return { data, loading, refetch }
  }

  const useShareAvatar = () => {
    const [ShareAvatarSubmit, response] = useMutation<
      ShareAvatarData,
      ShareAvatarVariables
    >(SHARE_AVATAR_MUTATION)

    const handleOnSubmit = (variables: ShareAvatarVariables) => {
      const options = {
        variables
      }
      ShareAvatarSubmit(options)
    }

    return { onSubmit: handleOnSubmit, response }
  }

  return {
    useAvatars,
    useVtmtVendors,
    useShareAvatar
  }
}
