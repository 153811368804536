import { createUseStyles } from 'react-jss'

import { ProductStyleProps } from './product.types'

export const useStyle = createUseStyles({
  breadcrumbs: {
    marginTop: 16
  },
  feedback: {
    marginTop: 80
  },
  productList: {
    marginTop: 80
  },
  button: {},
  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    productList: {
      '& > span': {
        fontFamily: 'Jost',
        fontWeight: 500,
        fontSize: 28,
        lineHeight: '38px',
        letterSpacing: '0.004em',
        textAlign: 'center'
      }
    }
  },

  bottomContainer: {},
  '@media screen and (max-width: 480px)': {
    productList: {
      '& > span': {
        fontFamily: 'Jost',
        fontWeight: 500,
        fontSize: 28,
        lineHeight: '38px',
        letterSpacing: '0.004em',
        textAlign: 'center'
      }
    },
    button: {
      marginTop: 0
    },
    bottomContainer: {
      bottom: ({ absolute }: ProductStyleProps) => (absolute ? -480 : 0)
    }
  }
})
