import React, { FC } from 'react'
import { BannerButtonType } from '../../services'
import { Text, TextPresets } from '../text'
import { Row } from '../row'
import { Column } from '../column'

import { Button, ButtonPresets } from '../button'

import { useStyle } from './banner.styles'
import { BannerProps } from './banner.types'

export const Banner: FC<BannerProps> = ({ data }) => {
  const isBigButton = data
    ? data.buttonType === BannerButtonType.BIG_BUTTON
    : false

  const classes = useStyle({ isBigButton })

  const buttonPreset: ButtonPresets = isBigButton ? 'primary' : 'button2'
  const buttonTextPreset: TextPresets = isBigButton ? 'h5' : 'maxButton'
  return (
    <>
      {data && (
        <Row fullWidth>
          <Column className={classes.bannerOverflow} alignItems="flex-start">
            <Text color="blue" preset="h4" text={data.mainHeading || ''} />
            <Text
              className={classes.text}
              color="black"
              preset="secondBanner"
              text={data.mainHeading || ''}
            />
            <Text
              className={classes.text}
              color="inactive"
              preset="subscriptionText"
              text={data.mainDescription || ''}
            />

            <Text
              className={classes.text}
              color="black"
              preset="h5"
              text={data.secondaryHeading || ''}
            />
            <Text
              className={classes.text}
              color="inactive"
              preset="h6"
              text={data.secondaryDescription || ''}
            />

            {data.isButtonActive && data.buttonText && data.buttonLink && (
              <a className={classes.anchor} href={data.buttonLink}>
                <Button
                  className={classes.button}
                  textColor="white"
                  textPreset={buttonTextPreset}
                  preset={buttonPreset}
                  text={data.buttonText}
                />
              </a>
            )}
          </Column>
          <img width="100%" height="480" src={data.image} />
        </Row>
      )}
    </>
  )
}
