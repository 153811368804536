import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    flexGrow: 1,
    flexBasis: 0
  },
  separator: {
    height: 4,
    backgroundColor: theme.colors.yellow
  },
  content: {
    padding: '12px 24px',
    width: 'calc(100% - 48px)'
  },
  productInfo: {
    padding: '12px 0'
  },
  text: {
    marginLeft: 24,
    wordBreak: 'break-all',
    width: '100%'
  },
  '@media screen and (max-width: 1300px)': {
    container: {
      minWidth: 500
    }
  }
}))
