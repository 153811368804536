import React, {
  ChangeEvent,
  FC,
  MouseEvent,
  useMemo,
  useRef,
  useState
} from 'react'
import ReactDOM from 'react-dom'
import { useHistory } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'

import { useOutsideAlert, usePortal } from '../../hooks'
import { useApi } from '../../providers'

import { Row } from '../row'
import { TextField } from '../text-field'
import { Icon, ICONS } from '../icon'
import { Column } from '../column'
import { Button } from '../button'
import { arrayHasElements } from '../helpers'

import { SearchCategory } from './search-category'
import { SearchItemProduct } from './search-item-product'
import { SearchItem } from './search-item'
import { SearchProps } from './searchs.types'
import { useStyle } from './searchs.styles'
import { LoadingContainer } from '../loading-container'
import { getPathByCategory } from '../../utils'

const SEARCH_ITEMS_COUNT = 10
const SHORT_SEARCH_COUNT = 3
const SEARCH_QUERY_MIN = 3

export const SearchComponent: FC<SearchProps> = ({
  className = '',
  children,
  open,
  onClose
}) => {
  const history = useHistory()
  const classes = useStyle({ mobile: isMobileOnly })

  const [value, changeValue] = useState<string>('')
  const { category, product, vendor } = useApi()
  const {
    data: products,
    refetch: refetchProducts,
    loading: loadingProducts
  } = product.useProductsGlobalSearch({
    first: SEARCH_ITEMS_COUNT
  })
  const {
    data: categories,
    loading: loadingCategory,
    refetch: refetchCategories
  } = category.useCategoriesGlobalSearch({ first: SEARCH_ITEMS_COUNT })
  const {
    data: vendors,
    loading: loadingVendors,
    refetch: refetchVendors
  } = vendor.useVendorGlobalSearch({ first: SEARCH_ITEMS_COUNT })

  const validSearchLength = useMemo(
    () => value.length >= SEARCH_QUERY_MIN,
    [value]
  )

  const target = usePortal('searchs')
  const ref = useRef<HTMLDivElement>(null)

  const Products = useMemo(() => {
    if (products && value) {
      return products.productsGlobalSearch.edges.slice(0, SHORT_SEARCH_COUNT)
    }

    return []
  }, [products])

  const Categories = useMemo(() => {
    if (categories && value) {
      return categories.categoriesGlobalSearch.edges.slice(
        0,
        SHORT_SEARCH_COUNT
      )
    }

    return []
  }, [categories])

  const Vendors = useMemo(() => {
    if (vendors && value) {
      return vendors.vendorsGlobalSearch.edges.slice(0, SHORT_SEARCH_COUNT)
    }

    return []
  }, [vendors])

  const handleOnPropagationBackground = (
    event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value: search } = event.target
    const filter = { search }

    if (search.length >= SEARCH_QUERY_MIN) {
      if (refetchProducts) {
        refetchProducts({ first: SEARCH_ITEMS_COUNT, filter })
      }

      if (refetchCategories) {
        refetchCategories({ first: SEARCH_ITEMS_COUNT, filter })
      }

      if (refetchVendors) {
        refetchVendors({ first: SEARCH_ITEMS_COUNT, filter })
      }
    }

    setTimeout(() => changeValue(search), 500)
  }

  const handleOnNavigate = () => {
    history.push(`/search-results/${value}`)
  }

  if (onClose) {
    useOutsideAlert(ref, open, onClose)
  }

  const hasProducts = arrayHasElements(Products)
  const hasCategories = arrayHasElements(Categories)
  const hasVendors = arrayHasElements(Vendors)

  const hasResults = hasProducts || hasCategories || hasVendors
  const loading = loadingProducts || loadingCategory || loadingVendors

  const inactiveClass =
    hasResults && validSearchLength
      ? classes.container
      : `${classes.inactiveContainer} ${classes.container}`

  return ReactDOM.createPortal(
    <Column
      alignItems="flex-end"
      justifyContent="flex-start"
      className={inactiveClass}
      ref={ref}
      onClick={onClose}
    >
      <Row
        fullWidth
        className={classes.row}
        onClick={handleOnPropagationBackground}
      >
        <TextField
          className={`${className} ${classes.text}`}
          leftIcon={ICONS.search}
          leftIconColor="yellow"
          label=""
          placeholder="Search"
          placeholderTx="sidebar.search.text"
          placeholderColor="black"
          preset="search"
          onChange={handleOnChange}
        />
        {children}
        <Icon className={classes.close} src={ICONS.close} onClick={onClose} />
      </Row>
      {validSearchLength && (
        <>
          <Column
            fullWidth
            fullHeight
            justifyContent="flex-start"
            className={classes.resultsContainer}
          >
            <LoadingContainer
              loading={Boolean(value && loading)}
              height={40}
              width={40}
            >
              {hasProducts && (
                <SearchCategory
                  label="Products"
                  labelTx="sidebar.products.title"
                >
                  {Products.map(({ node: item }) => (
                    <SearchItemProduct
                      key={`product_search_${item.id}`}
                      className={classes.item}
                      product={item}
                      title={item.name}
                      to={`/product/${item.id}`}
                    />
                  ))}
                </SearchCategory>
              )}
              {hasCategories && (
                <SearchCategory
                  label="Categories"
                  labelTx="sidebar.categories.title"
                >
                  {Categories.map(({ node }) => {
                    const pathname = getPathByCategory(
                      node.id,
                      node.children.edges
                    )

                    return (
                      <SearchItem
                        key={`category_search_${node.id}`}
                        className={classes.item}
                        image={node.backgroundImage.url}
                        title={node.name}
                        to={pathname}
                      />
                    )
                  })}
                </SearchCategory>
              )}
              {hasVendors && (
                <SearchCategory label="Vendors" labelTx="sidebar.vendors.title">
                  {Vendors.map(({ node: item }) => {
                    const to = `/vendor/${item.id}`
                    return (
                      <SearchItem
                        key={`category_search_${item.id}`}
                        className={classes.item}
                        image={item.avatar?.url}
                        userName={item.companyName}
                        title={item.companyName}
                        to={to}
                      />
                    )
                  })}
                </SearchCategory>
              )}
            </LoadingContainer>
          </Column>
          <Row className={classes.primary}>
            <Button
              preset="primary"
              textColor="white"
              text="SHOW ALL RESULTS"
              tx="sidebar.button.text"
              onClick={handleOnNavigate}
            />
          </Row>
        </>
      )}
    </Column>,
    target
  )
}
