import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  content: {
    padding: '0px 16px',
    width: 'calc(100% - 32px)'
  },
  radioItem: {
    borderBottom: `1px solid ${theme.colors.shadow}`,
    justifyContent: 'space-between',
    flexDirection: 'row-reverse',
    padding: '16px 0',
    '& > span': {
      marginLeft: 0
    }
  }
}))
