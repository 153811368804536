import React, { FC, useRef, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useHistory } from 'react-router-dom'

import { CabinetNavigatorPath } from '../../pages'

import { Column } from '../column'
import { HeaderAdditionalLinks } from '../header-bottom-bar'
import { Icon, ICONS } from '../icon'
import { Row } from '../row'

import { useStyle } from './menu-slider.styles'
import { MenuSliderProps } from './menu-slider.types'

export const MenuSlider: FC<MenuSliderProps> = ({
  defaultOpen = false,
  className = '',
  contentClassName = '',
  titleClassName = '',
  children,
  header: headerOpen,
  headerClose: headerCloseDefault
}) => {
  const history = useHistory()
  const itemsContainerRef = useRef<HTMLDivElement>(null)
  const itemsContainer = itemsContainerRef.current
  const height = itemsContainer ? itemsContainer.offsetHeight : 0
  const [open, changeOpen] = useState(defaultOpen)
  const isMobileAndOpen = isMobileOnly && open
  const widthTitleRow =
    history.location.pathname.includes(CabinetNavigatorPath.ORDERS_CONTROL) ||
    history.location.pathname.includes(CabinetNavigatorPath.PURCHASE_HISTORY) ||
    history.location.pathname.includes(
      HeaderAdditionalLinks.PRODUCT_CONSTRUCTOR
    )
  const iconMobile =
    history.location.pathname.includes(CabinetNavigatorPath.ORDERS_CONTROL) ||
    history.location.pathname.includes(CabinetNavigatorPath.PURCHASE_HISTORY)
  const isOrderControl =
    history.location.pathname.includes(CabinetNavigatorPath.ORDERS_CONTROL) &&
    !isMobileOnly

  const align = isOrderControl ? 'flex-start' : 'center'

  const classes = useStyle({
    open,
    height,
    mobileOpen: isMobileAndOpen,
    width: widthTitleRow,
    iconStyles: iconMobile,
    iconControl: isOrderControl
  })

  const handleOnOpen = () => {
    changeOpen(!open)
  }

  const headerClose = headerCloseDefault || headerOpen
  const header = open ? headerOpen : headerClose

  const iconOpen = open ? ICONS.arrowTop : ICONS.arrowBottom
  return (
    <Column fullWidth className={`${className} ${classes.slider}`}>
      <Row
        fullWidth
        className={`${titleClassName} ${classes.titleRow}`}
        alignItems="center"
        justifyContent="space-between"
        onClick={handleOnOpen}
      >
        <Row fullWidth justifyContent="flex-start">
          {header}
        </Row>
        <Row className={classes.icon} alignItems={align}>
          <Icon src={iconOpen} />
        </Row>
      </Row>
      <Column fullWidth className={`${contentClassName} ${classes.content} `}>
        <Column fullWidth ref={itemsContainerRef}>
          {children}
        </Column>
      </Column>
    </Column>
  )
}
