import React, { FC } from 'react'
import { Button } from '../button'

import { Column } from '../column'
import { Row } from '../row'
import { Text } from '../text'

import { useStyle } from './order-preview-table-header.styles'
import { OrderPreviewTableHeaderProps } from './order-preview-table-header.types'

export const OrderPreviewTableHeaderMobile: FC<
  OrderPreviewTableHeaderProps
> = ({ index, companyName, totalPrice, vendorId, onRemoveOrder }) => {
  const classes = useStyle()

  const handleOnRemoveOrder = () => {
    if (onRemoveOrder) {
      onRemoveOrder(vendorId)
    }
  }

  return (
    <Column className={classes.infoContainer}>
      <Row className={classes.itemOrderNum} justifyContent="space-betweeen">
        <Row fullWidth wrap justifyContent="flex-start">
          <Text text="Order" tx="order.preview.order" preset="h3Mobile" />
          <Text
            className={classes.orderNum}
            text={`#${String(index)}`}
            preset="h3Mobile"
          />
        </Row>
        <Button
          className={classes.deleteBtn}
          text="Delete Order"
          tx="order.preview.delete.button"
          textColor="yellow"
          textPreset="maxButton"
          onClick={handleOnRemoveOrder}
        />
      </Row>

      <Row
        fullWidth
        className={classes.itemOrderPrice}
        justifyContent="space-between"
      >
        <Text
          text="Order Price:"
          tx="order.preview.order.price"
          preset="body"
          color="inactive"
        />
        <Text
          className={classes.textMarginLeft}
          text={totalPrice}
          preset="h4"
        />
      </Row>
      <Row
        fullWidth
        className={classes.itemVendor}
        alignItems="center"
        justifyContent="space-between"
      >
        <Text
          text="Company:"
          tx="order.preview.vendor"
          preset="body"
          color="inactive"
        />
        <Text
          className={classes.textMarginLeft}
          text={companyName}
          preset="h4"
        />
      </Row>
    </Column>
  )
}
