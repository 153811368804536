import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.colors.lightBlue,
    padding: '12px 136px',
    width: 'calc(100% - 272px)',
    zIndex: 2
  },
  child: {
    cursor: 'pointer',
    marginLeft: 24,
    '&:hover': {
      color: theme.colors.yellow
    }
  }
}))
