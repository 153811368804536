import React, { FC } from 'react'

import { Button } from '../button'
import { Row } from '../row'
import { Text } from '../text'

import { useStyle } from './order-preview-table-header.styles'
import { OrderPreviewTableHeaderProps } from './order-preview-table-header.types'

export const OrderPreviewTableHeader: FC<OrderPreviewTableHeaderProps> = ({
  index,
  vendorId,
  companyName,
  totalPrice,
  onRemoveOrder
}) => {
  const classes = useStyle()

  const handleOnRemoveOrder = () => {
    if (onRemoveOrder) {
      onRemoveOrder(vendorId)
    }
  }

  return (
    <Row className={classes.infoContainer}>
      <Row
        className={classes.infoItemsLeft}
        alignItems="center"
        justifyContent="flex-start"
      >
        <Row className={classes.itemOrderNum} justifyContent="flex-start">
          <Row fullWidth wrap justifyContent="flex-start">
            <Text
              className={classes.orderNum}
              text="Order"
              tx="order.preview.order"
              preset="h3"
            />
            <Text
              className={classes.orderNum}
              text={`#${String(index)}`}
              preset="h3"
            />
          </Row>
        </Row>

        <Row
          className={classes.itemVendor}
          alignItems="center"
          justifyContent="flex-start"
        >
          <Text
            text="Company:"
            tx="order.preview.vendor"
            preset="body"
            color="inactive"
          />
          <Text
            className={classes.textMarginLeft}
            text={companyName}
            preset="h4"
          />
        </Row>
      </Row>
      <Row className={classes.infoItemsRight} justifyContent="flex-end">
        <Row
          className={classes.itemOrderPrice}
          alignItems="flex-end"
          justifyContent="flex-end"
        >
          <Text
            text="Order Price:"
            tx="order.preview.order.price"
            preset="body"
            color="inactive"
          />
          <Text
            className={classes.textMarginLeft}
            text={totalPrice}
            preset="h4"
          />
        </Row>

        <Row className={classes.itemButton} justifyContent="flex-end">
          <Button
            className={classes.deleteBtn}
            text="Delete Order"
            tx="order.preview.delete.button"
            textColor="yellow"
            textPreset="maxButton"
            onClick={handleOnRemoveOrder}
          />
        </Row>
      </Row>
    </Row>
  )
}
