import React, { FC } from 'react'

import { ObserveOfferButton, TooltipButton } from '../../views'

import { Text } from '../text'
import { Row } from '../row'
import { InfoRow } from '../info-row'
import { Column } from '../column'

import { useStyle } from './variant-column.styles'
import { VariantColumnProps } from './variant-column.types'

export const VariantColumn: FC<VariantColumnProps> = ({
  count,
  currency,
  productDefaultPrice,
  unitsIds,
  description,
  discount,
  noSuggestion,
  itemVariant,
  unitQuantity,
  totalUnitPrice,
  isTooltip
}) => {
  const classes = useStyle()

  return (
    <Column fullWidth className={classes.container} justifyContent="flex-start">
      <Row fullWidth alignItems="center" justifyContent="space-between">
        <Row
          fullWidth
          alignItems="baseline"
          justifyContent="flex-start"
          className={classes.variant}
        >
          <Text color="black" preset="h5" text={count} />
          <Text
            color="black"
            preset="title"
            text="Item"
            tx="shopping.cart.item.variant"
          />
        </Row>
        <ObserveOfferButton
          description={description}
          currency={currency}
          productDefaultPrice={productDefaultPrice}
          discount={discount}
          noSuggestion={noSuggestion}
          options={itemVariant.options}
          offerOptions={itemVariant.productOptions}
          offerColors={itemVariant.productColors}
          offerMaterials={itemVariant.productMaterials}
          itemQuantity={itemVariant.quantity}
          totalItemPrice={itemVariant.totalItemPrice}
          unitIds={unitsIds}
        />
      </Row>

      <InfoRow
        className={classes.row}
        title="Item Quantity:"
        titleTx="shopping.cart.units.quantity"
        value={String(unitQuantity)}
      >
        {isTooltip && <TooltipButton unitIds={unitsIds} />}
      </InfoRow>
      <InfoRow
        className={classes.row}
        title="Item Price:"
        titleTx="shopping.cart.units.price"
        value={totalUnitPrice}
      />
    </Column>
  )
}
