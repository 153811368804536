import { ApolloError, ApolloQueryResult, MutationResult } from '@apollo/client'

import {
  BackgroundImage,
  FetchMore,
  ListVariables,
  ListVariablesWithFilter,
  PaginationList,
  RangeInput
} from '../api.types'
import { Collection, CollectionByIdVariables } from '../collection'
import { Address, User } from '../auth'
import {
  CategoryByIdVariables,
  CategoryWithChildren,
  CategoryWithParent
} from '../category'
import {
  Currency,
  Money,
  TaxedMoney,
  TaxedMoneyRange,
  TaxRateType
} from '../tax'
import { LanguageCodeEnum } from '../language'
import { FeedbackWithCount } from '../feedback'
import { Order, OrderDirection } from '../order'
import { DeliveryPrice } from '../delivery-price'

export enum ProductOptionType {
  RADIOBUTTON = 'RADIOBUTTON',
  CHECKBOX = 'CHECKBOX'
}

export type FeaturePrice = {
  amount: number
  currency: Currency
}

export type FeaturePriceTypes = {
  gross: FeaturePrice
  net: FeaturePrice
}

export type FeaturePriceRange = {
  start: FeaturePriceTypes
  stop: FeaturePriceTypes
}

export type FeaturePricing = {
  onSale: boolean
  priceRangeUndiscounted: FeaturePriceRange
  priceRange: FeaturePriceRange
}

export type FeatureCategory = {
  id: string
  name: string
}

export type Color = {
  id: string
  name: string
  code: string
}

export interface ProductColor extends ProductOptionVariant {
  color: Color
}

export type AttributeType = {
  id: string
  type: string
  title: string
}

export type ProductAttribute = {
  id: string
  type: string
  attribute: AttributeType
}

export type ProductOptionVariant = {
  id: string
  title: string
  description: string
  extraPrice: number
  extraProductionDays: number
  isDefault: boolean
}

export type ProductOption = {
  id: string
  title: string
  type: ProductOptionType
  variant?: ProductOptionVariant
  variants: ProductOptionVariant[]
}

export interface ProductMaterial extends ProductOptionVariant {}

export type CoreItem = {
  id: string
  name: string
  count?: number
}
export enum ProductPurchaseSpecifics {
  ONLY_WITH_OFFER = 'ONLY_WITH_OFFER',
  DIRECT_PURCHASE = 'DIRECT_PURCHASE'
}

export type Product = {
  id: string
  name: string
  thumbnail: BackgroundImage
  thumbnail2x: BackgroundImage
  pricing: FeaturePricing
  category: FeatureCategory
  favorite?: boolean
  defaultPrice: number
  totalPrice: number
  images?: Image[]
  colors: ProductColor[]
  materials: ProductMaterial[]
  attributes?: ProductAttribute[]
  options: ProductOption[]
  size: ProductSize[]
  vendor: User
  description: string
  collections: Collection[]
  purchaseSpecifics: ProductPurchaseSpecifics
  productionDaysUpTo: number
  brandName: string
  countryOfOrigin: string
  materialCare: string
  materialComposition: string
  deliveryPrice: DeliveryPrice
  isFragile: boolean
  weight: Weight
  isFavorite: boolean
}

export type ProductCard = Pick<
  Product,
  | 'id'
  | 'name'
  | 'thumbnail2x'
  | 'pricing'
  | 'brandName'
  | 'productionDaysUpTo'
  | 'countryOfOrigin'
  | 'materialComposition'
  | 'defaultPrice'
  | 'totalPrice'
  | 'purchaseSpecifics'
  | 'options'
  | 'colors'
  | 'isFavorite'
>

export interface ProductOptionValue extends Omit<ProductOption, 'variants'> {
  variant?: ProductOptionVariant
  variants?: ProductOptionVariant[]
}

export type ProductOptions = {
  count: number
  orderColor: ProductColor
  orderMaterial: ProductMaterial
  orderOptions: ProductOptionValue[]
}

export type ProductFiltersParseJSON = {
  priceRange: CoreItem[]
  productionDaysRange: CoreItem[]
  materials: CoreItem[]
  colors: CoreItem[]
  companyNames: CoreItem[]
  genders: CoreItem[]
  productTypes: CoreItem[]
  sizes: CoreItem[]
  vendors: CoreItem[]
  rating: CoreItem[]
}

export interface ProductWithOptions extends Product, ProductOptions {
  maxAmount?: number
}

export type GetTopProducts = {
  topProducts: PaginationList<Product>
}

export type GetAdvertisingProducts = {
  advertisingProducts: PaginationList<Product>
}

export type GetPrevioslySeenProducts = {
  previouslySeenProducts: PaginationList<Product>
}

export type AttributeInput = {
  slug: string
  value: string
  values: string[]
}

export enum StockAvailability {
  IN_STOCK = 'IN_STOCK',
  OUT_OF_STOCK = 'OUT_OF_STOCK'
}

export type PriceRangeInput = RangeInput<number>
export type ProductionDaysRangeInput = RangeInput<number>

export type WarehouseIds = string[]

export type ProductStockFilterInput = {
  warehouseIds: string[]
  quantity?: PriceRangeInput
}

export type ProductFilter = {
  isPublished?: Boolean
  collections?: string[]
  categories?: string[]
  materials?: string[]
  vendors?: string[]
  colors?: string[]
  genders?: string[]
  rating?: string[]
  hasCategory?: Boolean
  companyNames?: string[]
  attributes?: AttributeInput[]
  options?: ProductOption[]
  stockAvailability?: StockAvailability
  productType?: string
  stocks?: ProductStockFilterInput
  search?: string
  price?: PriceRangeInput
  minimalPrice?: PriceRangeInput
  productTypes?: string[]
  isSale?: boolean
  productionDays?: ProductionDaysRangeInput
}

export enum ProductOrderField {
  PRICE = 'PRICE',
  DATE = 'DATE',
  ADVERTISING_CREATED = 'ADVERTISING_CREATED'
}

export type ProductOrderSort = {
  direction?: OrderDirection
  field?: ProductOrderField
}

export interface ProductsVariables
  extends ListVariablesWithFilter<ProductFilter> {
  sortBy?: ProductOrderSort
}

export interface GetTopProductsVariables extends ProductsVariables {}
export interface GetPrevioslyProductsVariables extends ProductsVariables {}
export interface GetAdvertisingProductsVariables extends ProductsVariables {}

export type GetFeaturedProducts = {
  shop: {
    homepageCollection: {
      id: string
      products: PaginationList<Product>
    }
  }
}

export type GetProduct = {
  product: FullProduct
}

export interface ProductsByCategoryIdVariables
  extends CategoryByIdVariables,
    ListVariables,
    ProductsVariables {}

export interface ProductsByCollectionIdVariables
  extends CollectionByIdVariables,
    ProductsVariables {}

export type CategoriesFilters = {
  vendor?: string
  search?: string
}
export interface CategoriesVatiables
  extends ListVariablesWithFilter<CategoriesFilters> {
  filter?: CategoriesFilters
}

export interface VendorCategoriesVariables extends CategoriesVatiables {}

export type GetProductsByCategoryId = {
  category: {
    id: string
    name: string
    products: PaginationList<Product>
  }
}

export type GetProductsByCollectionId = {
  collection: {
    id: string
    name: string
    products: PaginationList<Product>
  }
}

export type GetRecommendationProducts = {
  recommendations: PaginationList<Product>
}

export type GetYouMayLikeProducts = {
  youMayLike: PaginationList<Product>
}

export type GetNewArrivalsProducts = {
  newArrivals: PaginationList<Product>
}

export type GetProductsData = {
  products: PaginationList<Product>
}

export type GetProductsGlobalSearchData = {
  productsGlobalSearch: PaginationList<Product>
}

export type GetVendorCategories = {
  categories: PaginationList<CategoryWithChildren>
}

export enum WeightUnitsEnum {
  KG = 'KG',
  LB = 'LB',
  OZ = 'OZ',
  G = 'G'
}

export type Weight = {
  unit: WeightUnitsEnum
  value: number
}

export type MetadataItem = {
  key: string
  value: string
}

export type PageInfo = {
  hasNextPage: boolean
  hasPreviousPage: boolean
  startCursor: string
  endCursor: string
}

export type ReducedRate = {
  rate: number
  rateType: TaxRateType
}

export type VAT = {
  countryCode: string
  standardRate: number
  reducedRates: ReducedRate[]
}

export type CountryDisplay = {
  code: string
  country: string
  vat: VAT
}

export type MoneyRange = {
  start: Money
  stop: Money
}

export enum ShippingMethodTypeEnum {
  PRICE = 'PRICE',
  WEIGHT = 'WEIGHT'
}

export interface ShippingMethodTranslation extends Node {
  name: string
  language: LanguageDisplay
}

export interface ShippingMethod extends Node {
  name: string
  price: Money
  minimumOrderPrice: Money
  maximumOrderPrice: Money
  minimumOrderWeight: Weight
  maximumOrderWeight: Weight
  type: ShippingMethodTypeEnum
  translation: ShippingMethodTranslation
}

export interface ShippingZone extends Node {
  name: string
  default: boolean
  priceRange: MoneyRange
  countries: CountryDisplay[]
  shippingMethods: ShippingMethod[]
  warehouses: Warehouse[]
}

export type CountableConnectionT<T> = {
  pageInfo: PageInfo
  edges: T[]
  totalCount: number
}

export type ProductCountableEdge = {
  node: ProductType
  cursor: string
}

export type ProductTypeCountableEdge = {
  node: ProductType
  cursor: string
}

export type AttributeCountableEdge = {
  node: Attribute
  cursor: string
}

export type ShippingZoneCountableEdge = {
  node: ShippingZone
  cursor: string
}

export type WishlistItemCountableEdge = {
  node: WishlistItem
  cursor: string
}

export type OrderReturnItemCountableEdge = {
  node: Order
  cursor: string
}

export type ProductVariantCountableEdge = {
  node: ProductVariant
  cursor: string
}

export type ProductCountableConnection =
  CountableConnectionT<ProductCountableEdge>
export type ProductTypeCountableConnection =
  CountableConnectionT<ProductTypeCountableEdge>
export type AttributeCountableConnection =
  CountableConnectionT<AttributeCountableEdge>
export type ShippingZoneCountableConnection =
  CountableConnectionT<ShippingZoneCountableEdge>
export type WishlistItemCountableConnection =
  CountableConnectionT<WishlistItemCountableEdge>
export type ProductVariantCountableConnection =
  CountableConnectionT<ProductVariantCountableEdge>
export type OrderReturnItemCountableConnection =
  CountableConnectionT<OrderReturnItemCountableEdge>

export type TaxType = {
  description: string
  taxCode: string
}

export type ProductOptionVariantInput = {
  title: string
  description: string
  extraPrice: number
  extraProductionDays: number
  isDefault?: boolean
}

export type ProductCreateMaterialInput = ProductOptionVariantInput

export interface ProductCreateColorInput extends ProductOptionVariantInput {
  color: Color
}

export type ProductCreateOptionInput = {
  title: string
  type?: ProductOptionType
  variants?: ProductOptionVariantInput[]
}

export type AttributeInputTypeEnum = {}

export interface AttributeValueTranslation extends Node {
  name: string
  language: LanguageDisplay
}

export type AttributeValue = {
  id: string
  name: string
  slug: string
  translation: AttributeValueTranslation[]
  inputType: AttributeInputTypeEnum
}

export interface AttributeTranslation extends Node {
  id: string
  name: string
  language: LanguageDisplay
}

export type LanguageDisplay = {
  code: LanguageCodeEnum
  language: string
}

export type Attribute = {
  id: string
  productTypes: ProductTypeCountableConnection
  productVariantTypes: ProductTypeCountableConnection
  privateMetadata: MetadataItem
  metadata: MetadataItem
  inputType: AttributeInputTypeEnum
  name: string
  slug: string
  values: AttributeValue[]
  valueRequired: boolean
  visibleInStorefront: boolean
  filterableInStorefront: boolean
  filterableInDashboard: boolean
  availableInGrid: boolean
  translation: AttributeTranslation
  storefrontSearchPosition: number
}

export type ProductType = {
  id: string
  name: string
  slug: string
  hasVariants: boolean
  isShippingRequired: boolean
  isDigital: boolean
  weight: Weight
  privateMetadata: MetadataItem
  MediaMetadata: MetadataItem
  products: ProductCountableConnection
  taxRate: TaxRateType
  taxType: TaxType
  variantAttributes: Attribute[]
  productAttributes: Attribute[]
  availableAttributes: AttributeCountableConnection
}

export type VariantPricingInfo = {
  onSale: Boolean
  discount: TaxedMoney
  discountLocalCurrency: TaxedMoney
  price: TaxedMoney
  priceUndiscounted: TaxedMoney
  priceLocalCurrency: TaxedMoney
}

export type SelectedAttribute = {
  attribute: Attribute
  values: AttributeValue[]
}

export interface ProductImage extends Node {
  sortOrder: number
  alt: string
  url: string
}

export interface ProductVariantTranslation extends Node {
  name: string
  language: LanguageDisplay
}

export interface DigitalContentUrl extends Node {
  content: DigitalContent
  created: string
  downloadNum: number
  url: string
  token: string
}

export interface DigitalContent extends Node {
  useDefaultSettings: boolean
  automaticFulfillment: boolean
  productVariant: ProductVariant
  contentFile: string
  maxDownloads: number
  urlValidDays: number
  urls: DigitalContentUrl[]
  privateMetadata: MetadataItem
  metadata: MetadataItem
}

export interface Warehouse extends Node {
  name: string
  slug: string
  companyName: string
  shippingZones: ShippingZoneCountableConnection
  address: Address
  email: string
}

export interface Stock extends Node {
  warehouse: Warehouse
  productVariant: ProductVariant
  quantity: number
  quantityAllocated: number
}

export type ProductVariant = {
  id: string
  name: string
  sku: string
  product: Product
  trackInventory: Boolean
  weight: Weight
  privateMetadata: MetadataItem
  metadata: MetadataItem
  price: Money
  pricing: VariantPricingInfo
  attributes: SelectedAttribute[]
  costPrice: Money
  margin: number
  quantityOrdered: number
  revenue: TaxedMoney
  images: ProductImage[]
  translation: ProductVariantTranslation
  digitalContent: DigitalContent
  collections: Collection[]
  stocks: Stock[]
  quantityAvailable: number
}

export type Image = {
  id: string
  url: string
  alt: string
}

export type Margin = {
  start: number
  stop: number
}

export type ProductPricingInfo = {
  onSale: boolean
  discount: TaxedMoney
  discountLocalCurrency: TaxedMoney
  priceRange: TaxedMoneyRange
  priceRangeUndiscounted: TaxedMoneyRange
  priceRangeLocalCurrency: TaxedMoneyRange
}

export interface CollectionTranslation extends Node {
  seoTitle: string
  seoDescription: string
  name: string
  description: string
  descriptionJson: string
  language: LanguageDisplay
}

export type ProductTranslation = CollectionTranslation

/* META DATA types */
export type MetaItem = {
  key: string
  value: string
}

export type MetaClientStore = {
  name: string
  metadata: MetaItem[]
}

export type MetaStore = {
  namespace: string
  clients: MetaClientStore[]
}
export interface ObjectWithMetadata {
  privateMetadata: MetadataItem[]
  metadata: MetadataItem[]
  meta: MetaStore[]
}
/* eof META DATA types */

export interface ProductSize {
  id: string
  name: string
}

export interface FullProduct extends Product {
  id: string
  seoTitle: string
  seoDescription: string
  name: string
  description: string
  brandName: string
  productionDaysUpTo: number
  countryOfOrigin: string
  materialCare: string
  materialComposition: string
  descriptionJson: string
  publicationDate: string // date
  isPublished: boolean
  productType: ProductType
  availableForPurcahse: string // Date
  visibleInListings: boolean
  defaultVariant: ProductVariant
  privateMetadata: MetadataItem
  metadata: MetadataItem
  thumbnail: Image
  thumbnail2x: Image
  pricing: ProductPricingInfo
  isAvailable: boolean
  minimalVariantPrice: Money
  taxType: TaxType
  attributes: ProductAttribute[]
  purchaseCost: MoneyRange
  margin: Margin
  imageById: ProductImage
  variants: ProductVariant[]
  collections: Collection[]
  translation: ProductTranslation
  isAvailableForPurchase: boolean
  colors: ProductColor[]
  materials: ProductMaterial[]
  options: ProductOption[]
  size: ProductSize[]
  recommendations: Product[]
  isFavorite: boolean
  category: CategoryWithParent
  feedbacks: FeedbackWithCount[]
  weight: Weight
}

export interface Wishlist extends Node {
  createdAt: Date
  items: WishlistItemCountableConnection
}

export type WishlistError = {
  field: string
  message: string
}

export interface WishlistItem extends Node {
  wishlist: Wishlist
  product: Product
  variants: ProductVariantCountableConnection
}

export interface OrdersReturnItem extends Node {
  order: Order
  variants: OrderReturnItemCountableConnection
}

export type GetProductByIdRequest = {
  data: GetProduct | null
  loading: boolean
}

export type ProductByIdVariables = {
  id: string
}

export type GetWishlistAddResults = {
  wishlistAddProduct: {
    wishlist: Wishlist
    wishlistErrors: []
  }
}

export type GetWishlistRemoveResults = {
  wishlistRemoveProduct: {
    wishlist: Wishlist
    wishlistErrors: []
  }
}

export interface WishlistVariables extends ListVariables {
  search?: string
  productCategoryId?: string
  productVendorId?: string
  productIsSale?: boolean
}

export type GetWishlistResults = {
  me: {
    wishlist: PaginationList<WishlistItem>
  }
}

export type GetTopProductsRequest = {
  data: GetTopProducts | null
  loading: boolean
  refetch: (
    variables?: Partial<GetTopProductsVariables>
  ) => Promise<ApolloQueryResult<GetTopProducts>>
  fetchMore: FetchMore<GetTopProducts, GetTopProductsVariables>
}

export type GetAdvertisingProductsRequest = {
  data: GetAdvertisingProducts | null
  loading: boolean
  refetch: (
    variables?: Partial<GetAdvertisingProductsVariables>
  ) => Promise<ApolloQueryResult<GetAdvertisingProducts>>
  fetchMore: FetchMore<GetAdvertisingProducts, GetAdvertisingProductsVariables>
}

export type GetPrevioslyProductsRequest = {
  data: GetPrevioslySeenProducts | null
  loading: boolean
  refetch: (
    variables?: Partial<GetPrevioslyProductsVariables>
  ) => Promise<ApolloQueryResult<GetPrevioslySeenProducts>>
}

export type GetProductsRequest = {
  data: GetFeaturedProducts | null
  loading: boolean
  refetch: (
    variables?: Partial<ProductsVariables>
  ) => Promise<ApolloQueryResult<GetFeaturedProducts>>
}

export type GetFeaturedProductsRequest = {
  data?: GetFeaturedProducts | null
  error?: ApolloError
  loading: boolean
  refetch: (
    variables?: Partial<ProductsVariables>
  ) => Promise<ApolloQueryResult<GetFeaturedProducts>>
  fetchMore: FetchMore<GetFeaturedProducts, ProductsVariables>
}

export type GetNewArrivalsProductsRequest = {
  data: GetNewArrivalsProducts | null
  loading: boolean
  refetch: (
    variables?: Partial<ProductsVariables>
  ) => Promise<ApolloQueryResult<GetNewArrivalsProducts>>
  fetchMore: FetchMore<GetNewArrivalsProducts, ProductsVariables>
}

export type GetRecommendationProductsRequest = {
  data?: GetRecommendationProducts | null
  loading: boolean
  error?: ApolloError
  refetch: (
    variables?: Partial<ProductsVariables>
  ) => Promise<ApolloQueryResult<GetRecommendationProducts>>
  fetchMore: FetchMore<GetRecommendationProducts, ProductsVariables>
}

export type GetYouMayLikeProductsRequest = {
  data?: GetYouMayLikeProducts | null
  loading: boolean
  error?: ApolloError
  refetch: (
    variables?: Partial<ProductsVariables>
  ) => Promise<ApolloQueryResult<GetYouMayLikeProducts>>
  fetchMore: FetchMore<GetYouMayLikeProducts, ProductsVariables>
}

export type GetProducts = {
  data?: GetProductsData | null
  loading: boolean
  refetch?: (
    variables?: Partial<ProductsVariables>
  ) => Promise<ApolloQueryResult<GetProductsData>>
  fetchMore?: FetchMore<GetProductsData, ProductsVariables>
}

export type GetProductsGlobalSearch = {
  data?: GetProductsGlobalSearchData | null
  loading: boolean
  refetch?: (
    variables?: Partial<ProductsVariables>
  ) => Promise<ApolloQueryResult<GetProductsGlobalSearchData>>
  fetchMore?: FetchMore<GetProductsGlobalSearchData, ProductsVariables>
}

export type GetProductsByCategoryIdRequest = {
  data: GetProductsByCategoryId | null
  loading: boolean
  refetch: (
    variables?: Partial<ProductsByCategoryIdVariables>
  ) => Promise<ApolloQueryResult<GetProductsByCategoryId>>
  fetchMore?: FetchMore<GetProductsByCategoryId, ProductsByCategoryIdVariables>
}

export type GetProductsByCollectionIdRequest = {
  data: GetProductsByCollectionId | null
  loading: boolean
  refetch: (
    variables?: Partial<ProductsByCollectionIdVariables>
  ) => Promise<ApolloQueryResult<GetProductsByCollectionId>>
  fetchMore?: FetchMore<
    GetProductsByCollectionId,
    ProductsByCollectionIdVariables
  >
}

export type GetWishlistRequest = {
  data?: GetWishlistResults
  error?: ApolloError
  loading: boolean
  refetch: (variables: WishlistVariables) => void
  fetchMore: FetchMore<GetWishlistResults, WishlistVariables>
}

export type GetVendorCategoriesRequest = {
  data?: GetVendorCategories | null
  loading: boolean
  error?: ApolloError
  refetch: (variables: Partial<VendorCategoriesVariables>) => void
}

export type GetAddProductWishlistRequest = {
  onSubmit: () => void
  response: MutationResult<GetWishlistAddResults>
}

export type GetRemoveProductWishlistRequest = {
  onSubmit: () => void
  response: MutationResult<GetWishlistRemoveResults>
}

export type ProductApi = {
  useProducts: (variables: ProductsVariables) => GetProducts
  useProductsGlobalSearch: (
    variables: ProductsVariables
  ) => GetProductsGlobalSearch
  useFeaturedProducts: (
    variables: ProductsVariables
  ) => GetFeaturedProductsRequest
  useNewArrivalsProducts: (
    variables: ProductsVariables
  ) => GetNewArrivalsProductsRequest
  useRecommendationProducts: (
    variables: ProductsVariables
  ) => GetRecommendationProductsRequest
  useYouMayLike: (variables: ProductsVariables) => GetYouMayLikeProductsRequest
  useTopProducts: (variables: GetTopProductsVariables) => GetTopProductsRequest
  useAdvertisingProducts: (
    variables: GetAdvertisingProductsVariables
  ) => GetAdvertisingProductsRequest
  useTopProductsGlobalSearch: (
    variables: GetTopProductsVariables
  ) => GetTopProductsRequest
  usePrevioslySeenProducts: (
    variables: GetPrevioslyProductsVariables
  ) => GetPrevioslyProductsRequest
  useProductsByCategoryId: (
    variables: ProductsByCategoryIdVariables
  ) => GetProductsByCategoryIdRequest
  useProductsByCollectionId: (
    variables: ProductsByCollectionIdVariables
  ) => GetProductsByCollectionIdRequest
  useProductById: (variables: ProductByIdVariables) => GetProductByIdRequest
  useProductPreviewById: (
    variables: ProductByIdVariables
  ) => GetProductByIdRequest
  useAddProductToWishlist: (productId: string) => GetAddProductWishlistRequest
  useRemoveProductFromWishlist: (
    productId: string
  ) => GetRemoveProductWishlistRequest
  useWishlist: (variables: WishlistVariables) => GetWishlistRequest
  useVendorCategories: (
    variables: VendorCategoriesVariables
  ) => GetVendorCategoriesRequest
}
