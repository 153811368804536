import React from 'react'
import { useHistory } from 'react-router-dom'

import { Button, Column, Content, Text } from '../../components'

import { useStyle } from './empty-shopping-cart.styles'

import background from '../../assets/images/emptyShoppingCardBackground.png'

export const EmptyShoppingCart = () => {
  const classes = useStyle()

  const history = useHistory()

  const handleOnNavigate = (path: string) => () => {
    history.push(path)
  }

  return (
    <Content className={classes.content}>
      <Column>
        <Text
          className={classes.title}
          text="Your Shopping Cart"
          tx="empty.shopping.cart.title"
          preset="h1"
        />
        <Column>
          <img className={classes.image} src={background}></img>
        </Column>
        <Text
          className={classes.text}
          text="Oops! Your Shopping Cart is empty"
          tx="empty.shopping.cart.text"
          preset="h4"
        />
        <Text
          className={classes.secondaryText}
          text="But it’s not to late to correct it!"
          tx="empty.shopping.cart.secondary.text"
          preset="body"
        />
        <Button
          className={classes.button}
          preset="primary"
          text="HOME"
          textColor="white"
          textPreset="h5"
          tx="empty.shopping.cart.button"
          onClick={handleOnNavigate('/')}
        />
      </Column>
    </Content>
  )
}
