import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  bannerOverflow: {
    right: '55%',
    position: 'absolute',
    top: 0,
    bottom: 0,
    maxWidth: '40vw'
  },
  button: {
    marginTop: 24,
    width: 400
  },
  container: {},
  text: {
    marginTop: 8,
    textAlign: 'center'
  },
  anchor: {
    textDecoration: 'none'
  },
  '@media screen and (max-width: 480px)': {
    image: {
      height: '75vh'
    },
    bannerOverflow: {
      background: theme.colors.bannerOverflowMobile,
      backdropFilter: 'blur(10px)',
      width: '100vw',
      maxWidth: '100vw',
      height: '35%',
      right: 0,
      top: 'unset',
      justifyContent: 'flex-start'
    },
    anchor: {
      width: 'calc(100% - 24px)'
    },
    button: {
      marginTop: 20,
      padding: '16px 0',
      width: '100%'
    }
  }
}))
