import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {
    padding: 24
  },
  content: {
    padding: '24px 0',
    maxWidth: 1122
  },
  newsContainer: {
    padding: '0 16px',
    width: 'calc(100% - 32px)'
  },
  briefInfo: {
    marginTop: 48,
    '& > *': {
      flex: 1,
      width: '50%'
    }
  },
  image: {
    objectFit: 'cover',
    height: 375,
    width: '50%'
  },
  text: {
    padding: '24px 8px 42px'
  },
  details: {
    marginLeft: 16
  },
  createdAt: {
    marginTop: 24
  },

  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    container: {
      padding: 16
    },
    newsContainer: {
      width: '100%'
    }
  },
  '@media screen and (max-width: 480px)': {
    container: {
      padding: 12
    },
    newsContainer: {
      width: '100%',
      padding: 0
    },
    briefInfo: {
      '& > *': {
        width: '100%'
      }
    },
    image: {
      height: 230,
      width: '100%'
    },
    text: {
      padding: '24px 0 12px'
    },
    details: {
      marginLeft: 0,
      marginTop: 40
    },
    createdAt: {
      marginTop: 24
    }
  }
})
