import React, {
  createContext,
  FC,
  RefObject,
  useContext,
  useEffect,
  useState
} from 'react'
import { useIntl } from 'react-intl'
import Talk from 'talkjs'
import { v4 as uuid } from 'uuid'

import { config } from '../../config'
import { getMessageByMainStatus, getPriceToFixed } from '../../pages'
import { ChatUser, Order, Product } from '../../services'

import { useAuth } from '../auth'

import {
  ChatLocalStorageKey,
  ChatUserRole,
  ChatValue,
  GroupConversationOptions,
  SendMessageData
} from './chat.types'
import { getOrderLinesAutoMessage, getProductFullOriginLink } from './helpers'

const defaultUser = {
  id: 'Unknown',
  email: undefined,
  name: 'Unknown',
  photoUrl: undefined,
  role: ChatUserRole.VENDOR
}

const defaultValue: ChatValue = {
  ready: false,
  createVendor: () => defaultUser
}

export const ChatContext = createContext<ChatValue>(defaultValue)

export const ChatProvider: FC = ({ children }) => {
  const intl = useIntl()
  const { isLoggedIn, user, signature } = useAuth()
  const [conversation, changeConversation] =
    useState<Talk.ConversationBuilder>()
  const [me, changeMe] = useState<Talk.User>()
  const [session, changeSession] = useState<Talk.Session>()
  const [ready, changeReady] = useState(defaultValue.ready)

  useEffect(() => {
    // @ts-ignore
    if (conversation?.id) {
      // @ts-ignore
      localStorage.setItem(ChatLocalStorageKey.CONVERSATION, conversation.id)
    }
  }, [conversation])

  useEffect(() => {
    Talk.ready.then(() => {
      changeReady(true)
    })
  }, [])

  const handleOnOpenSession = () => {
    if (isLoggedIn && user) {
      const currentUser = new Talk.User({
        id: user.id,
        name: user.firstName || 'Unknown',
        email: user.email,
        photoUrl: user.avatar?.url,
        role: ChatUserRole.CUSTOMER
      })

      changeMe(currentUser)

      const nextSession = new Talk.Session({
        appId: config.TALKJS_APP_ID,
        me: currentUser,
        signature
      })

      changeSession(nextSession)
    }
  }

  const handleOnDestroySession = () => {
    if (!isLoggedIn && session) {
      session.destroy()
    }
  }

  useEffect(() => {
    handleOnOpenSession()

    return handleOnDestroySession
  }, [isLoggedIn, user])

  const handleOnSendMessage = ({
    conversation: nextConversation,
    message,
    messageTx,
    values,
    additionalMessage
  }: SendMessageData) => {
    if (nextConversation) {
      const formatedMessage = intl.formatMessage(
        {
          id: messageTx,
          defaultMessage: message
        },
        values
      )

      const nextMessage = messageTx ? formatedMessage : message
      const nextMessageWithAddition = additionalMessage
        ? `${nextMessage}\n ${additionalMessage}`
        : nextMessage

      nextConversation.sendMessage(nextMessageWithAddition)
    }
  }

  const handleOnSendProductMessage = (
    nextConversation: Talk.ConversationBuilder,
    product: Product
  ) => {
    const fullLinkOnProduct = getProductFullOriginLink(product.id)

    const message = `
      Product Name: ${product.name}
      Product Price: ${product.totalPrice}
      ${fullLinkOnProduct}
    `
    const values = {
      name: product.name,
      price: product.totalPrice,
      link: fullLinkOnProduct
    }

    handleOnSendMessage({
      values,
      message,
      messageTx: 'chat.product.message',
      conversation: nextConversation
    })
  }

  const handleOnSendOrderMessage = (
    nextConversation: Talk.ConversationBuilder,
    order: Order
  ) => {
    const { mainStatus, number, totalPrice, created = new Date() } = order
    const status = getMessageByMainStatus(mainStatus)
    const formatedStatus = intl.formatMessage({
      id: status.tx,
      defaultMessage: status.text || ''
    })

    const formatedTotal = `${getPriceToFixed(totalPrice)}$`

    const additionalMessage = getOrderLinesAutoMessage(intl, order.lines)
    const message = `
      Order Number: #${number} 
      Order Status: ${formatedStatus}
      Order Price: ${formatedTotal}
      Date: ${created}
      ${additionalMessage}
    `
    const values = {
      number,
      additionalMessage,
      status: formatedStatus,
      totalPrice: formatedTotal,
      created
    }

    handleOnSendMessage({
      values,
      message,
      messageTx: 'chat.order.message',
      conversation: nextConversation,
      additionalMessage
    })
  }

  const createVendor = (vendor?: ChatUser): Talk.User => {
    if (vendor) {
      const { id, firstName, email, avatar, companyName } = vendor
      const chatVendor: Talk.User = {
        id,
        email,
        name: companyName || firstName || 'Unknown',
        photoUrl: avatar?.url,
        role: ChatUserRole.VENDOR
      }

      return chatVendor
    }

    return defaultUser
  }

  const createAdmin = (admin?: ChatUser): Talk.User => {
    if (admin) {
      const { id, firstName, email, avatar } = admin
      const chatVendor: Talk.User = {
        id,
        email,
        name: firstName || 'Unknown',
        photoUrl: avatar?.url,
        role: ChatUserRole.STAFF
      }

      return chatVendor
    }

    return defaultUser
  }

  const createCustomer = (customer?: ChatUser): Talk.User => {
    if (customer) {
      const { id, firstName, email, avatar } = customer
      const chatVendor: Talk.User = {
        id,
        email,
        name: firstName || 'Unknown',
        photoUrl: avatar?.url,
        role: ChatUserRole.CUSTOMER
      }

      return chatVendor
    }

    return defaultUser
  }

  const createUser = (userData?: ChatUser, role?: ChatUserRole): Talk.User => {
    switch (role) {
      case ChatUserRole.CUSTOMER:
        return createCustomer(userData)
      case ChatUserRole.VENDOR:
        return createVendor(userData)
      case ChatUserRole.STAFF:
        return createAdmin(userData)
      default:
        return createCustomer(userData)
    }
  }

  const handleOnGetConversation = () => {
    if (session && me) {
      const nextConversation = session.getOrCreateConversation(
        Talk.oneOnOneId(me, me)
      )

      changeConversation(nextConversation)
    }
  }

  const handleOnCreateConversation = (
    otherUser: Talk.User
  ): Talk.ConversationBuilder | undefined => {
    const other = new Talk.User(otherUser)

    if (me && session) {
      const meWithWelcomeMessase = new Talk.User({
        id: me.id,
        name: me.name,
        email: me.email,
        photoUrl: me.photoUrl,
        role: me.role
      })

      const nextConversation = session.getOrCreateConversation(
        Talk.oneOnOneId(meWithWelcomeMessase, other)
      )

      nextConversation.setParticipant(me)
      nextConversation.setParticipant(other)

      changeConversation(nextConversation)
      return nextConversation
    }

    return undefined
  }

  const handleOnCreateGroupConversation = ({
    users,
    role,
    chatAttributes
  }: GroupConversationOptions) => {
    if (me && session) {
      const conversationId = uuid()
      const nextConversation = session.getOrCreateConversation(conversationId)

      users.forEach((userData) => {
        const chatUser = createUser(userData, role)
        nextConversation.setParticipant(chatUser)
      })

      nextConversation.setAttributes(chatAttributes)

      return conversationId
    }

    return undefined
  }

  const handleOnDestroyInboxes = () => {
    if (session) {
      const inboxes = session.getInboxes()

      inboxes.forEach((inboxItem) => inboxItem.destroy())
    }
  }

  const handleOnMountConversation = (ref: RefObject<HTMLDivElement>) => {
    if (session) {
      const inbox = session.createInbox()
      inbox.mount(ref.current)

      if (me && !conversation) {
        const localStorageId = localStorage.getItem(
          ChatLocalStorageKey.CONVERSATION
        )
        const oneOnOneId = Talk.oneOnOneId(me, me)
        const userId = user?.conversationId

        const nextConversation = session.getOrCreateConversation(
          localStorageId || userId || oneOnOneId
        )
        inbox.select(nextConversation)
      }

      if (me && conversation) {
        inbox.select(conversation)
      }
    }
  }

  const handleOnMountChatboxConversation = (
    ref: RefObject<HTMLDivElement>,
    conversationId: string
  ) => {
    if (session) {
      const inbox = session.createChatbox()
      inbox.mount(ref.current)

      if (me) {
        const nextConversation = session.getOrCreateConversation(conversationId)

        inbox.select(nextConversation)
      }
    }
  }

  const context = {
    ready,
    conversation,
    createVendor,
    session,
    onDestroyInboxes: handleOnDestroyInboxes,
    onCreateConversation: handleOnCreateConversation,
    onGetConversation: handleOnGetConversation,
    onMountConversation: handleOnMountConversation,
    onMountChatboxConversation: handleOnMountChatboxConversation,
    onSendMessage: handleOnSendMessage,
    onSendProductMessage: handleOnSendProductMessage,
    onSendOrderMessage: handleOnSendOrderMessage,
    onDestroySession: handleOnDestroySession,
    onOpenSession: handleOnOpenSession,
    onCreateGroupConversation: handleOnCreateGroupConversation
  }

  return <ChatContext.Provider value={context}>{children}</ChatContext.Provider>
}

export const useChat = () => useContext(ChatContext)
