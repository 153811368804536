import React, { useMemo, useState } from 'react'
import {
  Column,
  FilterSidebarData,
  getListFromEdgesList,
  Loader,
  parseFilterItems
} from '../../components'

import {
  OrderDirection,
  ProductFilter,
  ProductFiltersParseJSON,
  ProductOrderField,
  ProductOrderSort,
  ProductsVariables
} from '../../services'
import { useApi } from '../../providers'
import { ProductView, reduceProductsToProductList } from '../../views'

import { useStyle } from './new-arrivals.styles'

export const NewArrivalsPage = () => {
  const { product } = useApi()
  const first = 8

  const classes = useStyle()

  const [productVariables, changeProductVariables] =
    useState<ProductsVariables>({
      first,
      sortBy: { direction: OrderDirection.DESC, field: ProductOrderField.DATE }
    })

  const {
    data: response,
    refetch,
    fetchMore,
    loading
  } = product.useNewArrivalsProducts(productVariables)

  const Data = useMemo(() => {
    if (response) {
      const products = getListFromEdgesList(response.newArrivals)
      return reduceProductsToProductList(products)
    }

    return []
  }, [response])

  const TotalCount = useMemo(
    () => response?.newArrivals.totalCount || 0,
    [response?.newArrivals.totalCount]
  )

  const handleOnSort = (sortBy?: ProductOrderSort) => {
    refetch({ ...productVariables, first, sortBy })
    changeProductVariables({ ...productVariables, first, sortBy })
  }

  const handleOnLoadNext = (filter: ProductFilter) => {
    if (fetchMore && response) {
      const productEdges = response.newArrivals.edges
      const lastIndex = productEdges.length - 1
      fetchMore({
        variables: {
          first,
          filter,
          after: productEdges[lastIndex].cursor
        }
      })
    }
  }

  const handleOnChangeFilter = (value: ProductFilter) => {
    if (refetch) {
      refetch({ first, filter: value })
    }
  }

  const filtersData = response ? response.newArrivals.filters : undefined
  const Filters = useMemo(() => {
    if (filtersData) {
      const list = parseFilterItems<ProductFiltersParseJSON>(filtersData)

      if (list) {
        return list
      }
    }

    return {
      colors: [],
      materials: [],
      vendors: [],
      genders: [],
      priceRange: [],
      companyNames: [],
      productTypes: [],
      sizes: [],
      rating: [],
      productionDaysRange: []
    } as FilterSidebarData<ProductFiltersParseJSON>
  }, [filtersData])

  if (loading) {
    return (
      <Column className={classes.loadingContainer}>
        <Loader width={40} height={40} />
      </Column>
    )
  }

  return (
    <ProductView
      withCategories
      data={Data}
      title="New Arrivals"
      titleTx="header.newArrivals"
      totalCount={TotalCount}
      filtersData={Filters}
      onLoadNext={handleOnLoadNext}
      onChangeFilter={handleOnChangeFilter}
      onSort={handleOnSort}
    />
  )
}
