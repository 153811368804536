import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  header: {
    marginTop: 32,
    padding: '12px 16px',
    width: 'calc(100% - 32px)'
  },

  itemTitle: {
    flex: 9
  },
  secondaryTitle: {
    width: 168,
    flex: 2
  },
  thirdItem: {
    flex: 3
  },
  icon: {
    flex: 1
  },
  item: {
    '&:not(:first-child)': {
      marginTop: 16
    }
  },
  products: {
    marginTop: 80
  },
  '@media screen and (max-width: 480px) ': {
    content: {
      width: 'calc(100% - 24px)',
      padding: '24px 12px 32px'
    },
    breadcrumbs: {
      marginTop: 0
    },
    countTitle: {
      marginTop: 24
    },
    item: {
      marginTop: 24
    }
  }
})
