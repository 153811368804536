import React, { VFC } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { Button } from '../../button'
import { Flag } from '../../flag'
import { Row } from '../../row'
import { Text } from '../../text'

import { OrderRow } from '../order-row'

import { useStyle } from './order-contact-info.styles'
import { OrderContactInfoVendorProps } from './order-contact-info.types'

export const OrderContactInfoVendor: VFC<OrderContactInfoVendorProps> = ({
  title,
  titleTx,
  companyName,
  countryCode,
  countryFlag,
  buttonTitle,
  buttonTx,
  onButtonClick
}) => {
  const classes = useStyle()

  return (
    <>
      {!isMobileOnly ? (
        <OrderRow justifyContent="space-between" alignItems="center">
          <Row fullWidth justifyContent="flex-start">
            <Text color="darkBlue" preset="h6" text={title} tx={titleTx} />
            <Text
              className={classes.field}
              color="black"
              preset="h7"
              text={companyName}
            />
            <Row className={classes.field}>
              <Flag src={countryFlag} />
              <Text
                className={classes.text}
                color="black"
                preset="h7"
                text={countryCode}
              />
            </Row>
          </Row>

          <Button
            className={classes.button}
            preset="secondaryBorderMini"
            textColor="yellow"
            textPreset="maxButton"
            text={buttonTitle}
            tx={buttonTx}
            onClick={onButtonClick}
          />
        </OrderRow>
      ) : (
        <Button
          className={classes.button}
          preset="secondaryBorder"
          textColor="yellow"
          textPreset="maxButton"
          text={buttonTitle}
          tx={buttonTx}
          onClick={onButtonClick}
        />
      )}
    </>
  )
}
