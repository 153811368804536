import React, { useEffect, useMemo, useRef, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import {
  ButtonProps,
  Column,
  Content,
  GoBackContainer,
  Loader,
  Row,
  Text,
  getColorByOfferStatus,
  getCurrencySymbol
} from '../../components'
import {
  useApi,
  useAuth,
  useBasket,
  useChat,
  useToastify
} from '../../providers'
import { Currency, OfferStatus, OfferUpdateVariables } from '../../services'
import { DeclineOfferModal, ChatOverview, DeleteOfferModal } from '../../views'

import { CabinetNavigatorPath } from '../cabinet-navigator'
import { getMessageByOfferStatus, getBreadcrumbsTitle } from '../helper'

import { useStyle } from './offer-chat.styles'
import { OfferChatParams } from './offer-chat.types'

export const OfferChat = () => {
  const { open } = useToastify()
  const history = useHistory()
  const { offerId } = useParams<OfferChatParams>()
  const chatboxRef = useRef<HTMLDivElement>(null)
  const { onMountChatboxConversation } = useChat()
  const { offer: offerApi } = useApi()
  const { onUpdateOfferTotalCount } = useAuth()
  const { onAddOfferId, onRemoveOfferId } = useBasket()
  const { data, refetch } = offerApi.useOfferById({ id: offerId })
  const { onSubmit } = offerApi.useOfferUpdate()

  const [openDecline, changeOpenDecline] = useState(false)
  const [openDelete, changeOpenDelete] = useState(false)

  const classes = useStyle()
  const titlePreset = isMobileOnly ? 'h1Mobile' : 'h1'
  const isNew = data?.offer.status === OfferStatus.NEW
  const isAccepted = data?.offer.status === OfferStatus.ACCEPTED
  const isDisabled =
    data?.offer.status !== OfferStatus.NEW &&
    data?.offer.status !== OfferStatus.ACCEPTED_BACK_FROM_CART
  const isDeclinedByCutomerOrByVendor =
    data?.offer.status === OfferStatus.DECLINED_BY_CUSTOMER ||
    data?.offer.status === OfferStatus.DECLINED_BY_VENDOR

  const location = useLocation()

  useEffect(() => {
    if (refetch) {
      refetch({ id: offerId })
    }
  }, [offerId])

  useEffect(() => {
    if (data?.offer.conversationId && onMountChatboxConversation) {
      onMountChatboxConversation(chatboxRef, data?.offer.conversationId)
    }
  }, [data?.offer.conversationId])

  const CurrencySybmol = useMemo(() => getCurrencySymbol(Currency.USD), [])

  const handleGoBack = () => {
    history.goBack()
  }

  const handleOnAccept: ButtonProps['onClick'] = () => {
    if (data) {
      open({
        text: 'Success! the offer was accepted the Item was moved to the Cart',
        tx: 'offerUpdate.status.accepted.message'
      })
      const variables: OfferUpdateVariables = {
        id: data.offer.id,
        input: {
          status: OfferStatus.ACCEPTED
        }
      }

      if (onSubmit) {
        onSubmit(variables)
      }
      if (onUpdateOfferTotalCount && isNew) {
        onUpdateOfferTotalCount(-1)
      }

      if (onAddOfferId) {
        onAddOfferId(data.offer.id)
      }
    }
  }

  const handleOnChangeDeclineModal = (state: boolean) => () => {
    changeOpenDecline(state)
  }

  const handleOnChangeDeleteModal = (state: boolean) => () => {
    changeOpenDelete(state)
  }

  const handleOnDecline: ButtonProps['onClick'] = () => {
    const close = handleOnChangeDeclineModal(false)

    close()

    if (data) {
      onSubmit({
        id: data.offer.id,
        input: {
          status: String(OfferStatus.DECLINED_BY_CUSTOMER)
        }
      })
    }

    if (onUpdateOfferTotalCount && isNew) {
      onUpdateOfferTotalCount(-1)
    }

    if (onRemoveOfferId && isAccepted) {
      onRemoveOfferId(data.offer.id)
    }

    handleGoBack()
  }

  const handleOnDelete: ButtonProps['onClick'] = () => {
    const close = handleOnChangeDeclineModal(false)

    close()

    if (data) {
      const deleteStatus =
        data.offer.status === OfferStatus.DECLINED_BY_VENDOR
          ? OfferStatus.DECLINED
          : OfferStatus.DELETED_BY_CUSTOMER
      onSubmit({
        id: data.offer.id,
        input: {
          status: String(deleteStatus)
        }
      })
    }

    history.push(CabinetNavigatorPath.OFFERS)
  }

  const handleOnReactivate: ButtonProps['onClick'] = () => {
    if (data) {
      onSubmit({
        id: data.offer.id,
        input: {
          status: String(OfferStatus.NEW)
        }
      })
    }
    if (onUpdateOfferTotalCount) {
      onUpdateOfferTotalCount(+1)
    }

    handleGoBack()
  }

  const breadcrumbsTextProps =
    location.state && getBreadcrumbsTitle(location.state)

  if (!data?.offer) {
    return (
      <Content justifyContent="flex-start">
        <GoBackContainer
          text={breadcrumbsTextProps.text}
          textTx={breadcrumbsTextProps.tx}
          onClick={handleGoBack}
        />
        <Text preset="h1" text="Offer chat" />
        <Loader />
      </Content>
    )
  }

  const { offer } = data
  const statusTextProps = getMessageByOfferStatus(offer.status)

  return (
    <Content className={classes.container} justifyContent="flex-start">
      <GoBackContainer
        className={classes.breadcrumbs}
        text={breadcrumbsTextProps.text}
        textTx={breadcrumbsTextProps.tx}
        onClick={handleGoBack}
      />
      <Text preset={titlePreset} text="Offer chat" />
      <Row fullWidth className={classes.content}>
        <ChatOverview
          declineText="Decline"
          declineTx="offers.decline.offer"
          deleteText="Delete Offer"
          deleteTx="deleteOffer.modal.title"
          statusColor={getColorByOfferStatus(offer.status)}
          isDeclinedByCutomerOrByVendor={isDeclinedByCutomerOrByVendor}
          title={`Offer # ${offer.number}`}
          titleTx="offer.number"
          titleValues={{ number: offer.number }}
          statusText={statusTextProps.text}
          statusTx={statusTextProps.tx}
          offer={offer}
          disabled={isDisabled}
          disabledObserve={isDisabled}
          currencySymbol={CurrencySybmol}
          onAccept={handleOnAccept}
          onReactivate={handleOnReactivate}
          onDecline={handleOnChangeDeclineModal(true)}
          onDelete={handleOnChangeDeleteModal(true)}
        />
        <Column
          fullWidth
          alignItems="flex-start"
          justifyContent="flex-start"
          className={classes.chatboxContainer}
        >
          <Column
            fullWidth
            alignItems="flex-start"
            justifyContent="flex-start"
            className={classes.chatbox}
            ref={chatboxRef}
          />
        </Column>
      </Row>

      <DeclineOfferModal
        open={openDecline}
        description="Are you sure you want to decline this Offer?"
        descriptionTx="decline.offer.description"
        onClose={handleOnChangeDeclineModal(false)}
        onSubmit={handleOnDecline}
      />
      <DeleteOfferModal
        open={openDelete}
        description="Are you sure you want to delete this Offer?"
        descriptionTx="delete.offer.description"
        onClose={handleOnChangeDeleteModal(false)}
        onSubmit={handleOnDelete}
      />
    </Content>
  )
}
