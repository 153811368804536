import React, { FC, useEffect, useRef, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useSwipeable } from 'react-swipeable'

import { useApi } from '../../../providers'
import { getInstruction } from '../../../pages'

import { Row } from '../../row'
import { Text } from '../../text'
import { getListFromEdgesList } from '../../helpers'

import { mainStatusToIndex } from './helpers'
import { useStyle } from './order-staging.styles'
import { OrderStagingProps } from './order-staging.types'
import { OrderStagingItem } from './order-staging-item'
import { HintRow } from '../../hint-row'

export const OrderStaging: FC<OrderStagingProps> = ({
  className = '',
  mainStatus,
  remainingTimeProductionStarted
}) => {
  const { instruction: instructionApi } = useApi()
  const currentIndex = mainStatusToIndex(mainStatus)

  const [progressPosition, changeProgressPosition] = useState({
    left: 0,
    right: 0
  })

  const { data: instructions } = instructionApi.useInstructions({ first: 100 })

  const justify = isMobileOnly ? 'flex-start' : 'space-between'

  const firstRef = useRef<HTMLDivElement>(null)
  const lastRef = useRef<HTMLDivElement>(null)

  const scrollContainer = useRef<HTMLDivElement>(null)

  const instructionsList = getListFromEdgesList(instructions?.instructions)

  const orderControlProductionStartedInfo = getInstruction(
    instructionsList,
    'orderControlProductionStartedInfo'
  )
  const orderControlProductFinishedInfo = getInstruction(
    instructionsList,
    'orderControlProductFinishedInfo'
  )

  const handleOnClickLeft = () => {
    const scrollElement = scrollContainer.current
    if (scrollElement) {
      const scrollWidth = scrollElement.offsetWidth / 1.5
      const leftPosition = scrollElement.scrollLeft - scrollWidth
      scrollElement.scroll({ left: leftPosition, behavior: 'smooth' })
    }
  }

  const handleOnClickRight = () => {
    const scrollElement = scrollContainer.current
    if (scrollElement) {
      const scrollWidth = scrollElement.offsetWidth / 1.5
      const leftPosition = scrollElement.scrollLeft + scrollWidth
      scrollElement.scroll({ left: leftPosition, behavior: 'smooth' })
    }
  }

  const swipeableConfig = {
    delta: 20,
    preventDefaultTouchmoveEvent: false,
    trackTouch: isMobileOnly,
    trackMouse: true,
    rotationAngle: 0
  }

  const handlers = useSwipeable({
    onSwipedLeft: handleOnClickRight,
    onSwipedRight: handleOnClickLeft,
    ...swipeableConfig
  })
  const getIsActive = (index: number) => currentIndex >= index

  useEffect(() => {
    if (firstRef.current !== null && lastRef.current !== null) {
      const left = firstRef.current.offsetWidth / 2
      const right = lastRef.current.offsetWidth / 2

      changeProgressPosition({ left, right })
    } else {
      changeProgressPosition({ left: 0, right: 0 })
    }
  }, [firstRef, lastRef])

  const classes = useStyle({
    active: currentIndex,
    left: progressPosition.left,
    right: progressPosition.right,
    length: 6
  })

  return (
    <Row
      fullWidth
      {...handlers}
      justifyContent="space-between"
      className={`${className} ${classes.container}`}
    >
      <Row
        className={classes.staging}
        justifyContent={justify}
        ref={scrollContainer}
      >
        <div className={classes.progress} />
        {remainingTimeProductionStarted && (
          <Text
            className={classes.time}
            color="blue"
            preset="maxButton"
            text={remainingTimeProductionStarted}
          />
        )}

        <OrderStagingItem
          className={classes.itemFirst}
          disabled
          active={getIsActive(0)}
          ref={firstRef}
          title="Order paid"
          titleTx="order.item.staging.paid"
        />
        <OrderStagingItem
          className={classes.item}
          disabled
          active={getIsActive(1)}
          title="Order confirmed"
          titleTx="order.item.staging.confirmed"
        />
        <HintRow
          className={classes.hint}
          textClassName={classes.hintText}
          text={orderControlProductionStartedInfo}
          topIcon={40}
          top={48}
        >
          <OrderStagingItem
            className={classes.item}
            disabled
            active={getIsActive(2)}
            title="Production started"
            titleTx="order.item.staging.started"
          />
        </HintRow>
        <HintRow
          className={classes.hint}
          textClassName={classes.hintText}
          text={orderControlProductFinishedInfo}
          topIcon={40}
          top={48}
        >
          <OrderStagingItem
            className={classes.item}
            disabled
            active={getIsActive(3)}
            title="Product finished"
            titleTx="order.item.staging.finished"
          />
        </HintRow>
        <OrderStagingItem
          className={classes.item}
          disabled
          active={getIsActive(4)}
          title="Product shipped"
          titleTx="order.item.staging.shipped"
        />
        <OrderStagingItem
          className={classes.item}
          disabled
          active={getIsActive(5)}
          title="Parcel delivered"
          titleTx="order.item.staging.delivered"
          ref={lastRef}
        />
      </Row>
    </Row>
  )
}
