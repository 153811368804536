import React, { FC, useMemo } from 'react'

import { getAvaliableToDelivery, getAvaliableToPay } from '../../../helpers'
import { Row } from '../../../row'
import { Column } from '../../../column'
import { Text } from '../../../text'
import { Button } from '../../../button'
import { InfoRow } from '../../../info-row'

import { OrderStaging } from '../../order-staging'

import { useStyle } from './order-slider-header-close-mobile.styles'
import { OrderSliderHeaderCloseMobileProps } from './order-slider-header-close-mobile.types'

export const OrderSliderHeaderCloseMobile: FC<
  OrderSliderHeaderCloseMobileProps
> = ({
  number,
  created,
  price,
  vendor,
  mainStatus,
  paymentStatus,
  remainingTimeProductionStarted,
  onPayment,
  onOpenDelivery
}) => {
  const classes = useStyle()
  const orderIdText = `# ${number}`
  const isAvailableToPay = useMemo(
    () => getAvaliableToPay(paymentStatus, mainStatus),
    [paymentStatus, mainStatus]
  )
  const isAvailableToDelivery = useMemo(
    () => getAvaliableToDelivery(mainStatus),
    [mainStatus]
  )

  return (
    <Column fullWidth className={classes.container}>
      <Row wrap fullWidth justifyContent="space-between">
        <Row wrap justifyContent="flex-start">
          <Text className={classes.row} preset="h4" text={orderIdText} />
        </Row>
      </Row>

      <OrderStaging
        className={classes.staging}
        mainStatus={mainStatus}
        remainingTimeProductionStarted={remainingTimeProductionStarted}
      />
      <InfoRow
        className={classes.row}
        title="Date:"
        titleTx="order.item.date.title"
        value={created}
      />
      <InfoRow
        className={classes.row}
        title="Total Price:"
        titleTx="order.item.price.title"
        value={price}
      />
      <InfoRow
        className={classes.row}
        title="Vendor:"
        titleTx="order.item.vendor.title"
        value={vendor?.companyName}
      />

      <Row
        fullWidth
        className={classes.buttonRow}
        justifyContent="space-between"
      >
        {isAvailableToPay && (
          <Button
            className={classes.buttonBorder}
            preset="secondary"
            color="darkBlue"
            text="Payment"
            textColor="white"
            textPreset="maxButton"
            onClick={onPayment}
          />
        )}
        {isAvailableToDelivery && (
          <Button
            className={classes.deliveryButton}
            preset="secondary"
            color="yellow"
            text="Delivery Tracking"
            textColor="white"
            textPreset="maxButton"
            onClick={onOpenDelivery}
          />
        )}
      </Row>
    </Column>
  )
}
