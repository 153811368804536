import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  buttonsRow: {
    justifyContent: 'space-between',
    width: 'calc(100% - 32px)',
    columnGap: 16,
    padding: 16
  },
  buttonDecline: {
    width: '50%',
    maxWidth: '50%',
    padding: '12px 20px'
  },
  buttonContact: {
    width: '50%',
    padding: '12px 14px'
  }
})
