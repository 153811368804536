import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  cataloguePage: {
    paddingTop: '80px',
    paddingBottom: '76px'
  },
  categoryWrapper: {
    width: '100%',
    display: 'grid',
    columnGap: '20px',
    marginTop: '24px',
    '&:nth-child(even)': {
      gridTemplateColumns: '2fr 1fr'
    },
    '&:nth-child(odd)': {
      gridTemplateColumns: '1fr 2fr'
    }
  },
  link: {
    '& > span:hover': {
      color: theme.colors.yellow
    }
  },
  menuBlock: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    border: `1px solid ${theme.colors.shadow}`,
    height: '537px'
  },
  imageBlock: {
    border: `1px solid ${theme.colors.shadow}`,
    height: '537px'
  },
  header: {
    justifyContent: 'flex-start',
    width: 'calc(100% - 32px)',
    padding: '16px',
    borderBottom: `1px solid ${theme.colors.shadow}`
  },
  listWrapper: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    height: 'calc(100% - 110px)',
    rowGap: '32px',
    columnGap: '32px'
  },
  listGroup: {
    width: '360px',
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  },
  listItem: {
    paddingTop: '16px'
  },
  img: {
    objectFit: 'cover',
    height: '100%',
    width: '100%'
  }
}))
