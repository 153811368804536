import React, { ChangeEvent, FC, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import {
  Button,
  Column,
  Icon,
  ICONS,
  NavLink,
  Row,
  Text,
  TextField,
  TextProps
} from '../../../components'
import { useAuth } from '../../../providers'

import {
  ChangePasswordProps,
  RecoveryPasswordData,
  RecoveryPasswordErrorsProps,
  RecoveryPasswordIds,
  ChangePasswordParams
} from '../cahnge-password.types'
import { useStyle } from './change-password-mobile.styles'

export const ChangePasswordMobilePage: FC<ChangePasswordProps> = ({
  ...props
}) => {
  const classes = useStyle()
  const { token, email } = useParams<ChangePasswordParams>()
  const { onSetPassword, error } = useAuth()
  const [errorPassword, changeError] = useState<RecoveryPasswordErrorsProps>({
    password: undefined,
    repeatPassword: undefined
  })
  const [recoveryData, changeRecoveryData] = useState<RecoveryPasswordData>({
    password: '',
    repeatPassword: ''
  })
  const history = useHistory()

  const errorTextProps: TextProps = {
    text: 'At least 8 characters, one special, one letter and one number!',
    tx: 'change.password.error',
    color: 'error'
  }

  const isDisabled =
    recoveryData.password !== recoveryData.repeatPassword ||
    !recoveryData.password ||
    !recoveryData.repeatPassword

  const handleOnBlur = (prop: RecoveryPasswordIds) => () => {
    const regexp = new RegExp(
      '^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?=!@$%^&*-]).{8,}$'
    )

    const isPasswordCorrect = regexp.test(recoveryData[prop])

    if (!isPasswordCorrect) {
      changeError({
        ...errorPassword,
        [prop]: errorTextProps
      })
    } else {
      changeError({
        ...errorPassword,
        [prop]: ''
      })
    }
  }

  const handleOnChange =
    (prop: string) => (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target
      changeRecoveryData({
        ...recoveryData,
        [prop]: value
      })
    }

  const handleOnClick = () => {
    if (onSetPassword) {
      onSetPassword({
        token,
        email,
        password: recoveryData.password
      })
    }
  }

  const handleOnNavigate = (path: string) => () => {
    history.push(path)
  }

  return (
    <Column justifyContent="start" className={classes.changePasswordOverlay}>
      <Row
        justifyContent="space-between"
        fullWidth
        className={classes.changePasswordHeader}
      >
        <Text
          className={classes.changePasswordText}
          color="black"
          preset="h5"
          text="Reset Password"
        />
        <Row className={classes.changePasswordSvgContainer}>
          <Icon
            className={classes.icon}
            src={ICONS.close}
            onClick={handleOnNavigate('/')}
          />
        </Row>
      </Row>
      <Column>
        <Column {...props} fullWidth>
          <Column className={classes.changeForm}>
            <Text
              className={classes.changeSecondaryText}
              color="inactive"
              preset="h7"
              text="Your new password must be different from the previously used password."
              tx="change.password.title.text"
            />
            <TextField
              isSecure
              className={classes.input}
              label="New Password"
              labelTx="change.password.label.password"
              preset="border"
              type="text"
              onChange={handleOnChange('password')}
              onBlur={handleOnBlur('password')}
            />
            <TextField
              isSecure
              className={classes.input}
              label="Confirm New Password"
              labelTx="change.password.label.confirm.password"
              preset="border"
              type="text"
              onChange={handleOnChange('repeatPassword')}
              onBlur={handleOnBlur('repeatPassword')}
            />
            <Row fullWidth>
              {error && (
                <Row
                  className={classes.error}
                  fullWidth
                  justifyContent="flex-start"
                >
                  <Icon className={classes.errorIcon} src={ICONS.attention} />
                  <Text
                    className={classes.errorText}
                    text="At least 8 characters, one special, one letter and one number!"
                    tx="change.password.error"
                    color="error"
                    preset="body"
                  />
                </Row>
              )}
            </Row>
            <Button
              className={classes.submit}
              disabled={isDisabled}
              text="RESET PASSWORD"
              tx="change.password.reset.password"
              preset="primary"
              textColor="white"
              textPreset="h5"
              onClick={handleOnClick}
            />
            <Row className={classes.signUpContainer}>
              <Text
                color="black"
                preset="secondTitle"
                text="Don’t have an account?"
                tx="change.password.reset.no.account"
              />
              <NavLink
                className={classes.signUp}
                color="blue"
                preset="h6"
                text="Sign Up"
                tx="change.password.reset.signUp"
                to="/sign-up"
              />
            </Row>
          </Column>
        </Column>
      </Column>
    </Column>
  )
}
