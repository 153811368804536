import React, { FC, useState } from 'react'

import { Button, ButtonProps } from '../../components'

import { ObserveOptionsOfferModal } from '../observe-options-offer'

import { ObserveOfferButtonProps } from './observe-offer-button.types'

export const ObserveOfferButton: FC<ObserveOfferButtonProps> = ({
  className = '',
  defaultOpen = false,
  disabled,
  name = 'VIEW CUSTOMIZATIONS',
  nameTx = 'order.preview.item.observe.options',
  onClick,
  ...observeOfferModalProps
}) => {
  const [open, changeOpen] = useState(defaultOpen)

  const handleOnOpen =
    (state: boolean): ButtonProps['onClick'] =>
    (event) => {
      changeOpen(state)

      if (onClick) {
        onClick(event)
      }
    }

  return (
    <>
      <Button
        className={className}
        color="black"
        preset="cards"
        textColor="black"
        textPreset="maxButton"
        text={name}
        tx={nameTx}
        onClick={handleOnOpen(true)}
      />
      <ObserveOptionsOfferModal
        {...observeOfferModalProps}
        open={open}
        onClose={handleOnOpen(false)}
      />
    </>
  )
}
