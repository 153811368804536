import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'

import { AuthToken } from '../../providers/auth/helpers'
import { useAuth } from '../../providers'
import { Button, Column, Modal, Row, Text } from '../../components'

import { useStyle } from './delete-account.styles'
import { DeleteAccountProps } from './delete-account.types'

export const DeleteAccountModal: FC<DeleteAccountProps> = ({
  open,
  onClose,
  onSubmit
}) => {
  const token = localStorage.getItem(AuthToken.TOKEN) as string
  const classes = useStyle()
  const history = useHistory()

  const { onAccountDelete } = useAuth()

  const handleOnToRefreshClick = () => {
    window.location.reload()
  }
  const handleOnSubmit = () => {
    if (onAccountDelete) {
      onAccountDelete({
        token
      })
    }
  }
  const handleOnClick = () => {
    history.push('/')
    handleOnToRefreshClick()
    handleOnSubmit()
    onSubmit()
  }

  if (!open) {
    return <></>
  }

  return (
    <Modal
      className={classes.container}
      title="Delete Account"
      titleTx="delete.account.title.text"
      onClose={onClose}
    >
      <Column fullWidth justifyContent="space-between">
        <Column
          fullWidth
          className={classes.column}
          justifyContent="space-between"
        >
          <Column
            className={classes.image}
            alignItems="center"
            justifyContent="center"
          />
          <Column fullWidth>
            <Text
              text="Are you sure?"
              color="darkBlue"
              preset="h4"
              tx="delete.account.text"
            />
            <Text
              className={classes.subText}
              color="inactive"
              preset="body"
              text="Deleting your account will remove all of your information from our database. This cannot be undone."
              tx="delete.account.secondary.text"
            />
          </Column>

          <Row fullWidth className={classes.row} justifyContent="flex-end">
            <Button
              className={`${classes.buttonLeft} ${classes.button}`}
              color="white"
              preset="borderButton"
              textPreset="maxButton"
              text="Cancel"
              tx="choose.vendor.button.text"
              textColor="yellow"
              onClick={onClose}
            />
            <Button
              className={`${classes.buttonRight} ${classes.button}`}
              color="yellow"
              preset="primary"
              textPreset="maxButton"
              text="Delete account"
              textColor="white"
              onClick={handleOnClick}
            />
          </Row>
        </Column>
      </Column>
    </Modal>
  )
}
