import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  image: {},
  infoContainer: {},
  '@media screen and (max-width: 480px)': {
    image: {
      objectFit: 'cover',
      height: 64,
      width: 64
    },
    infoContainer: {
      marginLeft: 8
    }
  }
})
