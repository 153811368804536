import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  search: {
    maxWidth: '100%'
  },
  load: {
    padding: 16
  },
  button: {
    width: 536
  },
  emptyButton: {
    cursor: 'pointer',
    marginTop: 24,
    width: 312,
    padding: 16
  },
  imageContainer: {
    marginTop: '0px !important'
  },
  '@media screen and (max-width: 480px)': {
    load: {
      padding: 0,
      marginTop: 16
    },
    button: {
      width: '100%'
    }
  }
})
