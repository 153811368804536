import { createUseStyles } from 'react-jss'

import { ProductsViewStyles } from './products.types'

export const useStyle = createUseStyles({
  container: {
    padding: '16px 0px'
  },
  header: {
    marginTop: 8
  },
  productContainer: {
    marginTop: 24
  },
  load: {},
  button: {
    width: 536
  },
  productsDataRow: {
    minHeight: ({ wishlist }: ProductsViewStyles) => !wishlist && 500
  },
  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    container: {
      padding: 0
    }
  },
  '@media screen and (max-width: 480px)': {
    container: {
      padding: 0
    },
    header: {
      marginTop: 0
    },
    productContainer: {
      marginTop: 12
    },
    load: {
      padding: '16px 0 40px'
    },
    button: {
      width: 'calc(100% - 24px)'
    },
    productsDataRow: {
      minHeight: 'none'
    }
  }
})
