import React, { FC, ChangeEvent } from 'react'
import { isMobileOnly } from 'react-device-detect'

import { Button, Column, Modal, Row, Text, TextArea } from '../../components'

import { ReturnModalProps } from './return-modal.types'
import { useStyle } from './return-modal.styles'

export const ReturnModal: FC<ReturnModalProps> = ({
  vendor,
  orderNumber,
  className = '',
  open,
  message,
  handleMessage,
  onClose,
  onSubmit
}) => {
  if (!open) {
    return <></>
  }
  const classes = useStyle()

  const handleMessageTextArea = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.target
    handleMessage(value)
  }

  const isDisabled = !message
  const bodyToMaxButtonPreset = isMobileOnly ? 'maxButton' : 'body'
  const justify = isMobileOnly ? 'space-between' : 'flex-end'

  return (
    <Modal
      title="Return"
      titleTx="return.modal.title"
      onClose={onClose}
      className={className}
    >
      <Column fullWidth className={classes.container}>
        <Text
          className={classes.title}
          text="Briefly describe the reason for the return."
          tx="return.modal.subtitle"
          preset={bodyToMaxButtonPreset}
        />
        <Column
          fullWidth
          alignItems="flex-start"
          className={classes.summaryItem}
        >
          <Column fullWidth alignItems="flex-start">
            <Text
              text="Company"
              tx="return.modal.vendor"
              preset="subscriptionText"
              color="inactive"
            />
            <Text text={vendor} preset="body" color="inactive" />
          </Column>
        </Column>
        <Column
          fullWidth
          alignItems="flex-start"
          className={classes.summaryItem}
        >
          <Text
            text="Order number"
            tx="return.modal.order.number"
            preset="subscriptionText"
            color="inactive"
          />
          <Text text={orderNumber} preset="body" color="inactive" />
        </Column>
        <TextArea
          className={classes.area}
          label="Message"
          labelTx="return.modal.message"
          preset="main"
          value={message}
          onChange={handleMessageTextArea}
        />
      </Column>
      <Row fullWidth className={classes.row} justifyContent={justify}>
        <Button
          className={classes.buttonLeft}
          color="yellow"
          preset="secondaryBorderMedium"
          textPreset="maxButton"
          text="Cancel"
          tx="choose.vendor.button.text"
          textColor="yellow"
          onClick={onClose}
        />
        <Button
          disabled={isDisabled}
          className={classes.buttonRight}
          color="yellow"
          preset="secondaryMedium"
          textPreset="maxButton"
          text="Send"
          tx="forgot.password.button.sent"
          textColor="white"
          onClick={onSubmit}
        />
      </Row>
    </Modal>
  )
}
