import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  border: {
    width: 'calc(100% - 32px)',
    borderBottom: `1px solid ${theme.colors.shadow}`,
    padding: 16
  },
  container: {
    width: '25.6%',
    backgroundColor: theme.colors.white
  },
  column: {
    marginLeft: 16
  },
  col: {
    marginLeft: 12
  },
  columnOne: {
    marginTop: 16
  },
  colum: {},
  text: {
    backgroundColor: theme.colors.lightBlue
  },
  img: {
    width: 64,
    height: 64
  },
  span: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%'
  }
}))
