import { OrderLine } from '../../services'

export const ActionType = {
  EXCHANGE: 'Exchange',
  RETURN: 'Return'
}
export interface SelectItemsTypeParams {
  id: string
}

export interface OrderLineReturnExchange extends OrderLine {
  return?: boolean
  exchange?: boolean
}
