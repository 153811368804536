import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  countryName: {
    marginLeft: 16,
    '& > *:not(:first-child)': {
      marginTop: 12
    }
  },
  flag: {
    width: 32,
    height: 16
  },
  secondaryText: {
    marginLeft: 8
  },
  status: {
    '& > *': {
      whiteSpace: 'wrap'
    },
    '& > *:not(:first-child)': {
      marginLeft: 8
    },
    '&:not(:first-child)': {
      marginTop: 20
    }
  },
  '@media screen and (min-width: 481px)': {
    flag: {
      width: 48,
      height: 24
    }
  }
})
