import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  header: {
    padding: 16
  },
  statusRow: {},
  statusColumn: {
    marginLeft: 24
  },
  status: {
    marginTop: 4
  },
  companyColumn: {
    marginLeft: 0,
    marginTop: 16
  },
  priceColumn: {
    marginTop: 8
  },
  button: {
    width: 120,
    marginLeft: 16
  },
  productAvatar: {
    objectFit: 'cover'
  },
  text: {
    marginLeft: 8
  }
})
