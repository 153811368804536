import { createUseStyles } from 'react-jss'
import { isMobile } from 'react-device-detect'

export const useStyle = createUseStyles({
  container: {
    minHeight: '100vh',
    minWidth: '100vw',
    flex: 1,
    alignSelf: 'stretch'
  },
  content: {
    minHeight: !isMobile && 600,
    flex: 1,
    alignSelf: 'stretch'
  }
})
