import React, { FC } from 'react'
import { v4 as uuid } from 'uuid'
import {
  getPickedColorCode,
  getPickedColorTitle,
  getPickedMaterial
} from '../../pages'
import { getPriceWithSymbol } from '../../views'

import { Text } from '../text'
import { Row } from '../row'
import { Column } from '../column'
import { VariantColumn } from '../variant-column'
import { zeroPad } from '../helpers'

import { ItemLineProps } from './item-line.types'
import { useStyle } from './item-line.styles'

export const ItemLineMobile: FC<ItemLineProps> = ({
  className = '',
  productName,
  productDefaultPrice,
  imgUrl,
  children,
  items,
  currency,
  isTooltip,
  noSuggestion,
  description,
  discount
}) => {
  const classes = useStyle()

  return (
    <Column fullWidth className={className}>
      <Row fullWidth alignItems="flex-start" justifyContent="flex-start">
        <img className={classes.image} src={imgUrl} />

        <Column alignItems="flex-start" className={classes.infoContainer}>
          <Text color="black" preset="h5" text={productName} />
        </Column>
      </Row>

      {items &&
        items.map((item, index) => {
          const {
            id,
            quantity: unitQuantity,
            productColors,
            productMaterials,
            productOptions,
            options,
            totalItemPrice,
            unitIds
          } = item

          const initialIndex = index + 1
          const itemNumber = zeroPad(initialIndex, 2)
          const colorCode = getPickedColorCode(productColors)
          const colorTitle = getPickedColorTitle(productColors)
          const material = getPickedMaterial(productMaterials)

          return (
            <VariantColumn
              key={`${uuid()}${id}`}
              count={itemNumber}
              noSuggestion={noSuggestion}
              description={description}
              discount={discount}
              productDefaultPrice={productDefaultPrice}
              currency={currency}
              itemVariant={item}
              unitQuantity={unitQuantity}
              unitsIds={unitIds}
              isTooltip={isTooltip}
              totalUnitPrice={getPriceWithSymbol(totalItemPrice, currency)}
              material={material}
              colorCode={colorCode}
              colorTitle={colorTitle}
              offerOptions={options}
              productOptionsFromOffer={productOptions}
            />
          )
        })}
      {children}
    </Column>
  )
}
