import React, {
  FC,
  MouseEventHandler,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useHistory } from 'react-router'

import { Column, ICONS, LoadingContainer, Modal } from '../../components'
import {
  CabinetNavigatorPath,
  getFeedbackAmount,
  reduceProductItemToProduct
} from '../../pages'
import { useApi, useAuth, useSignInModal } from '../../providers'
import { OfferStatus } from '../../services'

import { ProductPreviewGeneralInfo } from './product-preview-general-info'
import { useStyle } from './product-preview.styles'
import { ProductPreviewProps } from './product-preview.types'

export const ProductPreview: FC<ProductPreviewProps> = ({
  open,
  id,
  onClose
}) => {
  const classes = useStyle()
  const history = useHistory()
  const {
    product: {
      useProductPreviewById,
      useAddProductToWishlist,
      useRemoveProductFromWishlist
    },
    offer: offerApi
  } = useApi()
  const { isLoggedIn, user } = useAuth()
  const { changeOpenModal } = useSignInModal()

  const { data } = useProductPreviewById({ id })
  const { onSubmit: onSubmitAddProduct } = useAddProductToWishlist(id)
  const { onSubmit: onSubmitRemoveProduct } = useRemoveProductFromWishlist(id)
  const { response: responseCreateOffer, onSubmit: onSubmitCreateOffer } =
    offerApi.useOfferCreate()

  const Product = useMemo(() => {
    if (data) {
      return reduceProductItemToProduct(data)
    }

    return null
  }, [JSON.stringify(data?.product)])

  const countryCode = Product?.vendor?.defaultBillingAddress?.country?.code

  const Feedbacks = data?.product.feedbacks
  const averageRating = Feedbacks && getFeedbackAmount(Feedbacks)
  const [isFavorite, changeIsFavorite] = useState(Product?.isFavorite)
  const favoriteIcon = isFavorite ? ICONS.favoriteFilled : ICONS.favorite

  useEffect(() => {
    if (
      typeof isFavorite === 'undefined' &&
      typeof Product?.isFavorite === 'boolean'
    ) {
      changeIsFavorite(Product?.isFavorite)
    }
  }, [Product?.isFavorite, isFavorite])

  useEffect(() => {
    const offerId = responseCreateOffer.data?.offerCreate.offer.id
    if (offerId) {
      history.push({
        pathname: `${CabinetNavigatorPath.OFFER_CHAT}/${offerId}`,
        state: 'fromProductsPage'
      })
    }
  }, [responseCreateOffer.data?.offerCreate.offer.id])

  const handleOnCreateOffer = () => {
    onSubmitCreateOffer({
      input: {
        customer: user?.id,
        product: id,
        quantity: 1,
        status: OfferStatus.DRAFT
      }
    })
  }

  const handleOnGetNewOffer: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault()
    event.stopPropagation()

    if (!isLoggedIn) {
      if (changeOpenModal) {
        changeOpenModal(true)
      }
    }

    if (isLoggedIn) {
      handleOnCreateOffer()
    }
  }

  const handleOnContactVendor = () => {
    if (!isLoggedIn) {
      if (changeOpenModal) {
        changeOpenModal(true)
      }
    }

    if (isLoggedIn) {
      history.push(CabinetNavigatorPath.VENDOR_COMMUNICATION)
    }
  }

  const handleOnAddToWishlistClick = () => {
    if (isLoggedIn) {
      changeIsFavorite(!isFavorite)

      if (!isFavorite) {
        onSubmitAddProduct()
      } else {
        onSubmitRemoveProduct()
      }
    }

    if (!isLoggedIn) {
      if (changeOpenModal) {
        changeOpenModal(true)
      }
    }
  }

  const linkToProduct = `/product/${id}`

  if (!open) {
    return <></>
  }

  return (
    <Modal
      isLink
      linkTo={linkToProduct}
      title="Show Product Card"
      titleTx="product.preview.title"
      className={classes.container}
      onClose={onClose}
    >
      <Column fullWidth className={classes.content} justifyContent="flex-start">
        <LoadingContainer className={classes.loader} loading={!Product}>
          {Product && (
            <ProductPreviewGeneralInfo
              product={Product}
              favouriteIcon={favoriteIcon}
              averageRating={averageRating}
              countryCode={countryCode}
              onAddToWishlistClick={handleOnAddToWishlistClick}
              onGetNewOffer={handleOnGetNewOffer}
              onContactVendor={handleOnContactVendor}
            />
          )}
        </LoadingContainer>
      </Column>
    </Modal>
  )
}
