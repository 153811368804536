import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  rowField: {
    marginLeft: 24
  },
  rowValue: {
    marginLeft: 8
  },
  date: {
    padding: '0px 0px 16px'
  }
})
