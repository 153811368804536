import React, { useState, FC, createContext, useContext } from 'react'

import { Modal } from '../../components'
import { SignInView } from '../../views'

import { useAuth } from '../auth'
import { useStyle } from './sign-in-modal.styles'

type SignInModal = {
  openModal: Boolean
  changeOpenModal?: (state: boolean) => void
}

const defaultValue = {
  openModal: false,
  changeOpenModal: undefined
}

export const SignInModalContext = createContext<SignInModal>(defaultValue)

export const SignInModalProvider: FC = ({ children }) => {
  const [openModal, changeOpenModal] = useState(false)
  const { isLoggedIn } = useAuth()
  const classes = useStyle()
  const context = {
    openModal,
    changeOpenModal
  }

  const handleOnOpenModal = (state: boolean) => () => {
    changeOpenModal(state)
  }

  return (
    <SignInModalContext.Provider value={context}>
      {children}
      {openModal && !isLoggedIn && (
        <Modal
          title="Sign In"
          titleTx="shopping.cart.signIn"
          onClose={handleOnOpenModal(false)}
        >
          <SignInView modal className={classes.loginForm} />
        </Modal>
      )}
    </SignInModalContext.Provider>
  )
}

export const useSignInModal = () => useContext(SignInModalContext)
