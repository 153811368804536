import { useQuery } from '@apollo/client'

import {
  CategoriesVariables,
  GetCategories,
  GetCategoriesData,
  GetCataloguesData,
  GetCatalogues,
  CategoryApi,
  GetCategoryById,
  GetCategoryByIdWithChildren,
  CategoryByIdVariables,
  GetCategoriesGlobalSearch,
  GetCategoriesGlobalSearchData,
  GetWishlistCategories,
  GetWishlistCategoriesData
} from './category.types'

import {
  CATEGORIES_QUERY,
  CATEGORY_BY_ID,
  CATALOGUES_QUERY,
  CATEGORIES_QUERY_GLOBAL_SEARCH,
  WISHLIST_CATEGORIES_QUERY
} from './category.graphql'

// our "constructor"
export const categoryService = (): CategoryApi => {
  const useCategoryById = (id: string): GetCategoryById => {
    const { data, loading, error, refetch } = useQuery<
      GetCategoryByIdWithChildren,
      CategoryByIdVariables
    >(CATEGORY_BY_ID, {
      variables: {
        id
      }
    })

    if (error) {
      return { data: null, loading, refetch }
    }

    if (!data) {
      return { data: null, loading, refetch }
    }

    return { data, loading, refetch }
  }

  const useCategories = (variables: CategoriesVariables): GetCategories => {
    const { data, loading, error, refetch } = useQuery<
      GetCategoriesData,
      CategoriesVariables
    >(CATEGORIES_QUERY, {
      variables
    })

    if (error) {
      return { data: null, loading, refetch }
    }

    if (!data) {
      return { data: null, loading, refetch }
    }

    return { data, loading, refetch }
  }

  const useCategoriesGlobalSearch = (
    variables: CategoriesVariables
  ): GetCategoriesGlobalSearch => {
    const { data, loading, error, refetch, fetchMore } = useQuery<
      GetCategoriesGlobalSearchData,
      CategoriesVariables
    >(CATEGORIES_QUERY_GLOBAL_SEARCH, {
      variables
    })
    if (error) {
      return { data: null, loading, refetch, fetchMore }
    }

    if (!data) {
      return { data: null, loading, refetch, fetchMore }
    }
    return { data, loading, refetch, fetchMore }
  }

  const useWishlistCategories = (
    variables: CategoriesVariables
  ): GetWishlistCategories => {
    const { data, loading, error, refetch } = useQuery<
      GetWishlistCategoriesData,
      CategoriesVariables
    >(WISHLIST_CATEGORIES_QUERY, {
      variables
    })

    if (error) {
      return { data: null, loading, refetch }
    }

    if (!data) {
      return { data: null, loading, refetch }
    }

    return { data, loading, refetch }
  }

  const useCatalogues = (): GetCatalogues => {
    const { data, loading, error } =
      useQuery<GetCataloguesData>(CATALOGUES_QUERY)

    if (error) {
      return { data: null, loading }
    }

    if (!data) {
      return { data: null, loading }
    }

    return { data, loading }
  }

  return {
    useCategoryById,
    useCategories,
    useCatalogues,
    useCategoriesGlobalSearch,
    useWishlistCategories
  }
}
