import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  changeForm: {
    width: 'calc(100vw - 24px)'
  },
  changePasswordOverlay: {
    position: 'fixed',
    backgroundColor: theme.colors.white,
    height: '100%',
    minHeight: '100vh',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 2
  },
  changePasswordHeader: {
    display: 'flex',
    alignItems: 'center',
    height: 57,
    borderBottom: `1px solid ${theme.colors.shadow}`,
    width: '100%'
  },
  changePasswordSvgContainer: {
    display: 'flex',
    margin: '0 12px'
  },
  changePasswordText: {
    margin: '0 12px'
  },
  changeSecondaryText: {
    marginTop: 24,
    textAlign: 'center'
  },
  input: {
    marginTop: 24
  },
  submit: {
    marginTop: 225
  },
  signUp: {
    marginLeft: 4
  },
  signUpContainer: {
    marginTop: 24
  },
  error: {
    position: 'absolute',
    backgroundColor: theme.colors.errorBackground,
    padding: '8px 0',
    marginTop: 154,
    borderRadius: 4,
    '& > a': {
      textDecoration: 'underline',
      textDecorationColor: theme.colors.error
    }
  },
  errorIcon: {
    marginLeft: 19
  },
  errorText: {
    marginLeft: 16
  },
  icon: {
    cursor: 'pointer',
    '&:hover > path': {
      fill: theme.colors.yellow
    }
  }
}))
