import React, { FC } from 'react'
import { isMobileOnly } from 'react-device-detect'

import { concatClassNames } from '../../utils'

import { Column } from '../column'
import { Row } from '../row'
import { Text } from '../text'

import { useStyle } from './info-column.styles'
import { InfoColumnProps } from './info-column.types'

export const InfoColumn: FC<InfoColumnProps> = ({
  className = '',
  children,
  title,
  titleTx,
  value,
  preset = isMobileOnly ? 'h5' : 'h7'
}) => {
  const classes = useStyle()

  return (
    <Column
      alignItems="flex-start"
      className={concatClassNames(classes.infoColumn, className)}
    >
      <Text
        className={classes.item}
        color="inactive"
        preset="body"
        text={title}
        tx={titleTx}
      />
      <Row className={classes.iconRow}>
        <Text color="black" preset={preset} text={value} />
        {children}
      </Row>
    </Column>
  )
}
