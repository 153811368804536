import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    padding: '16px 0',
    width: '100%',
    borderBottom: `1px solid ${theme.colors.shadow}`
  },
  variant: {
    columnGap: 8
  },
  observeOptionsBtn: {
    padding: '8px 6px'
  },
  row: {
    width: '100%',
    justifyContent: 'space-between',
    marginTop: 12
  }
}))
