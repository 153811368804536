import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  infoRows: {},
  row: {
    marginTop: 12
  },
  infoColumns: {
    width: '60%'
  },
  lastColumn: {
    minWidth: 140
  },

  contact: {},
  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    infoRows: {
      width: 'calc(100% - 72px)',
      margin: '24px 0 0 72px',
      '&> div': {
        justifyContent: 'space-between'
      }
    }
  },
  '@media screen and (max-width: 480px)': {
    infoRows: {
      width: '100%',
      marginTop: 16,
      '&> div': {
        justifyContent: 'space-between',
        width: '100%'
      }
    },
    contact: {
      marginTop: 16,
      padding: 12,
      width: '100%'
    }
  }
})
