import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'

import {
  Column,
  Content,
  FilterContainer,
  ResponsiveMediaView,
  Row
} from '../../components'

import { useStyle } from './cabinet.styles'
import { CabinetPageProps } from './cabinet.types'

export const CabinetPage: FC<CabinetPageProps> = ({ children, navigator }) => {
  const history = useHistory()
  const isChat = history.location.pathname.includes(
    '/cabinet/vendor-communication'
  )

  const classes = useStyle({ chat: isChat })

  const navigatorComponent = (
    <FilterContainer className={classes.navigator}>{navigator}</FilterContainer>
  )
  return (
    <Content className={classes.container} justifyContent="flex-start">
      <Row fullWidth className={classes.row} alignItems="flex-start">
        <ResponsiveMediaView
          bigScreen={navigatorComponent}
          medium={navigatorComponent}
        />

        <Column
          fullWidth
          className={classes.content}
          justifyContent="flex-start"
        >
          {children}
        </Column>
      </Row>
    </Content>
  )
}
