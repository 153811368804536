import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  link: {
    textDecoration: 'none'
  },
  textContainer: {
    backgroundColor: theme.colors.yellow,
    borderRadius: 16,
    height: 16,
    top: 0,
    right: 0,
    position: 'absolute',
    width: 16
  }
}))
