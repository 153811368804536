import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    backgroundColor: theme.colors.white
  },

  image: {
    width: 64,
    height: 64,
    objectFit: 'cover'
  },

  name: {
    marginLeft: 16
  },
  text: {
    marginTop: 4
  }
}))
