import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'

import { MainRoutes } from '../../routes'
import {
  EmptyBasket,
  PrevioslySeen,
  AttentionOfferModal,
  AttentionOfferModalProps,
  getPriceWithSymbol
} from '../../views'
import { OfferStatus, OfferUpdateVariables } from '../../services'

import {
  arrayHasElements,
  Breadcrumbs,
  Column,
  Content,
  getCurrencyByProduct,
  Loader,
  ProductInfoBottomBar,
  ProductInfoBottomBarMobile,
  ResponsiveMediaView,
  Row,
  Text
} from '../../components'
import { useApi, useAuth, useBasket, useSignInModal } from '../../providers'

import { CabinetNavigatorPath } from '../cabinet-navigator'
import { limit } from '../helper'

import { useStyle } from './shopping-cart.styles'
import { ShoppingCartTable } from './shopping-cart-table'
import { ShoppingCartCheckout } from './shopping-cart-checkout'

export const ShoppingCartPage = () => {
  const history = useHistory()

  const [scrollPosition, setScrollPosition] = useState(0)
  const [open, changeOpen] = useState(false)

  const isAbsolute = scrollPosition >= limit() - 400
  const classes = useStyle({ absolute: isAbsolute })
  const { isLoggedIn } = useAuth()
  const { offer: offerApi, deliveryPrice } = useApi()
  const { onSubmit } = offerApi.useOfferUpdate()
  const { changeOpenModal } = useSignInModal()

  const {
    basket,
    getTotalFromBasket,
    getTotalWeightFromBasket,
    onRemoveOfferId
  } = useBasket()

  if (!basket) {
    return <Loader />
  }

  const handleOnChangeWeightOfferModal =
    (state: boolean): AttentionOfferModalProps['onClose'] =>
    (event) => {
      event.preventDefault()
      event.stopPropagation()

      changeOpen(state)
    }

  const totalBasketDeliveryWeight =
    getTotalWeightFromBasket !== undefined ? getTotalWeightFromBasket() : 0

  const isTotalWeightOver = totalBasketDeliveryWeight >= 500

  const defaultTotalDeliveryVariables = {
    first: 100,
    filter: {
      weight: String(totalBasketDeliveryWeight)
    }
  }

  const { data: deliveryTotalResponse } = deliveryPrice.useTotalDeliveryPrice(
    defaultTotalDeliveryVariables
  )

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(() => {
    if (isTotalWeightOver) {
      changeOpen(true)
    }

    if (isMobileOnly) {
      window.addEventListener('scroll', handleScroll)
    }

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const currency =
    (arrayHasElements(basket) && getCurrencyByProduct(basket[0].product)) || '$'
  const count = basket.length
  const countValues = { count }
  const titlePreset = isMobileOnly ? 'h1Mobile' : 'h1'

  const totalDeliveryPrice = deliveryTotalResponse
    ? deliveryTotalResponse.totalDeliveryPrice.edges[0].node.price
    : 0
  const totalOfferPrice =
    getTotalFromBasket !== undefined ? getTotalFromBasket() : 0
  const totalBasketPrice = totalOfferPrice + totalDeliveryPrice
  const Container = isMobileOnly ? Column : Row

  const handleOnOpenModal = (state: boolean) => () => {
    if (changeOpenModal) {
      changeOpenModal(state)
    }
  }

  const handleOnCheckout = () => {
    const handleOpenModal = handleOnOpenModal(true)

    if (!isLoggedIn) {
      handleOpenModal()
    } else {
      history.push(MainRoutes.DELIVERY_INFO)
    }
  }

  const handleOnClickHome = () => {
    history.push('/')
  }

  const handleOnChangeStatus = (id: string) => {
    const variables: OfferUpdateVariables = {
      id,
      input: {
        status: OfferStatus.ACCEPTED_BACK_FROM_CART
      }
    }
    onSubmit(variables)
  }

  const handleOnContactVendorOffer = (offerId: string) => {
    if (!isLoggedIn) {
      if (changeOpenModal) {
        changeOpenModal(true)
      }
    }

    history.push({
      pathname: `${CabinetNavigatorPath.OFFER_CHAT}/${offerId}`,
      state: 'fromCart'
    })
  }

  if (!arrayHasElements(basket)) {
    return (
      <Column fullWidth justifyContent="flex-start" className={classes.content}>
        <Text
          text="Your Shopping Cart"
          tx="empty.shopping.cart.title"
          preset={titlePreset}
        />
        <Column fullWidth>
          <EmptyBasket
            title="Oops! Your Shopping Cart is empty"
            titleTx="empty.shopping.cart.text"
            text="But it’s not to late to correct it!"
            tx="empty.shopping.cart.secondary.text"
            onGoHome={handleOnClickHome}
          />
        </Column>
        <PrevioslySeen />
      </Column>
    )
  }

  const Checkout = (
    <ShoppingCartCheckout
      className={classes.info}
      currency={currency}
      totalOfferPrice={totalOfferPrice}
      totalBasketPrice={totalBasketPrice}
      totalDeliveryBasketPrice={totalDeliveryPrice}
      onCheckout={handleOnCheckout}
    />
  )

  return (
    <Content justifyContent="flex-start">
      <Breadcrumbs
        className={classes.breadcrumbs}
        title="Shopping Cart"
        titleTx="breadcrumbs.shopping.cart"
      />
      <Column fullWidth className={classes.content}>
        <Text
          className={classes.title}
          color="black"
          preset={titlePreset}
          text="Your Shopping Cart"
          tx="shopping.cart.title"
        />
        <Text
          className={classes.countTitle}
          color="inactive"
          preset="h4"
          text={`Accepted offers: ${String(count)}`}
          tx="shopping.cart.items"
          values={countValues}
        />
        <Container
          fullWidth
          alignItems="flex-start"
          className={classes.itemContainer}
        >
          <ResponsiveMediaView mobile={Checkout} />
          <Column className={classes.items}>
            <ShoppingCartTable
              basket={basket}
              onChangeStatus={handleOnChangeStatus}
              onContactVendorOffer={handleOnContactVendorOffer}
              onRemoveOffer={onRemoveOfferId}
            />
          </Column>
          <ResponsiveMediaView
            bigScreen={Checkout}
            medium={Checkout}
            tablet={Checkout}
          />
        </Container>
      </Column>
      <PrevioslySeen />
      <ResponsiveMediaView
        tablet={
          <ProductInfoBottomBar
            className={classes.bottomContainer}
            name="Total:"
            price={getPriceWithSymbol(totalBasketPrice, currency)}
            buttonTitle="PROCEED TO CHECKOUT"
            buttonTitleTx="shopping.cart.checkout"
            onClick={handleOnCheckout}
          />
        }
      />
      {scrollPosition > 400 && (
        <ResponsiveMediaView
          mobile={
            <ProductInfoBottomBarMobile
              absolute={isAbsolute}
              className={classes.bottomContainer}
              name="Total:"
              price={getPriceWithSymbol(totalBasketPrice, currency)}
              buttonTitle="PROCEED TO CHECKOUT"
              buttonTitleTx="shopping.cart.checkout"
              onClick={handleOnCheckout}
            />
          }
        />
      )}
      <AttentionOfferModal
        open={open}
        title="Total Weight"
        titleTx="offer.modal.total.weight.title"
        description="Your parcel exceeds 500kg, our Manager will contact you shortly to discuss special delivery conditions."
        descriptionTx="warning.about.the.weight.of.the.offer"
        onClose={handleOnChangeWeightOfferModal(false)}
        onSubmit={handleOnChangeWeightOfferModal(false)}
      />
    </Content>
  )
}
