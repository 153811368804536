import React, { FC } from 'react'

import { Text } from '../../text'
import { SearchItem } from '../search-item'

import { SearchItemProductProps } from './search-item-product.types'

export const SearchItemProduct: FC<SearchItemProductProps> = ({
  className,
  product,
  title,
  to
}) => {
  return (
    <SearchItem
      className={className}
      title={title}
      image={product.thumbnail2x?.url}
      to={to}
    >
      <Text color="inactive" preset="h5" text={product.category?.name} />
    </SearchItem>
  )
}
