import React, { FC } from 'react'

import { ObserveButton, getPriceWithSymbol } from '../../views'

import { Text } from '../text'
import { Row } from '../row'
import { Column } from '../column'

import { useStyle } from './order-preview-offer-item.styles'
import { OrderPreviewOfferItemProps } from './order-preview-offer-item.types'

export const OrderPreviewOfferItemMobile: FC<OrderPreviewOfferItemProps> = ({
  className,
  image,
  name,
  offerQuantity,
  totalPrice,
  currency,
  material,
  colorName,
  colorCode,
  options,
  offerOptions
}) => {
  const classes = useStyle()

  return (
    <Column className={`${className} ${classes.container}`}>
      <Row fullWidth justifyContent="flex-start" alignItems="flex-start">
        <img className={classes.img} src={image} />
        <Column className={classes.nameText} alignItems="flex-start">
          <Text text={name} preset="h5" />
          <Row fullWidth justifyContent="space-between">
            <ObserveButton
              className={classes.observeOptionsBtn}
              colorCode={colorCode}
              colorTitle={colorName}
              material={material}
              offerOptions={offerOptions}
              productOptionsFromOffer={options}
              nameTx="order.preview.item.observe.options"
            />
          </Row>
        </Column>
      </Row>

      <Row fullWidth justifyContent="space-between">
        <Row className={classes.itemText} justifyContent="flex-start">
          <Text
            className={classes.itemPrice}
            preset="h5"
            text={String(offerQuantity)}
          />
        </Row>
        <Row className={classes.itemLastText} justifyContent="flex-end">
          <Text text={getPriceWithSymbol(totalPrice, currency)} preset="h5" />
        </Row>
      </Row>
    </Column>
  )
}
