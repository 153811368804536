import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    padding: 24
  },
  content: {
    alignSelf: 'center'
  },
  text: {
    marginTop: 24,
    textAlign: 'center'
  },
  inputsContainer: {
    marginTop: 24,
    padding: '0 24px'
  },
  dropdawnRow: {
    width: 536,
    marginTop: 24
  },
  input: {
    width: 536,
    marginTop: 24
  },
  inputError: {},
  dropdown: {
    top: 0,
    width: 'calc(100% - 16px)',
    borderRadius: 4,
    scrollbarWidth: 'none',
    border: `1px solid ${theme.colors.inactive}`,
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  button: {
    width: 313,
    padding: '16px 24px',
    marginTop: 24
  },
  infoRow: {},

  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    container: {
      padding: '24px 16px'
    },
    content: {
      width: '100%',
      padding: '16px 0'
    },
    inputsContainer: {
      padding: 0
    },
    dropdawnRow: {
      width: 'calc(50% - 10px)'
    },
    input: {
      width: 'calc(50% - 10px)'
    },
    infoRow: {
      columnGap: 20
    }
  },
  '@media screen and (max-width: 480px)': {
    container: {
      padding: '24px 12px'
    },
    content: {
      width: '100%',
      padding: '16px 0'
    },
    inputsContainer: {
      width: '100%',
      marginTop: 0,
      padding: 0
    },
    dropdawnRow: {
      width: '100%'
    },
    input: {
      width: '100%'
    },
    button: {
      width: '100%',
      padding: '16px 0',
      marginTop: 40
    }
  }
}))
