import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {},
  buttonsRow: {},
  row: {
    width: 170,
    '&:not(:first-child)': {
      marginLeft: 24
    }
  },
  button: {
    marginLeft: 24,
    width: 148,
    padding: '12px 10px'
  },
  buttonBorder: {
    width: 148,
    padding: '10px 8px'
  },
  deliveryButton: {
    marginLeft: 16
  },
  staging: {
    marginTop: 24,
    marginLeft: 24
  },
  '@media screen and (min-width: 481px) and (max-width: 1023px)': {
    row: {
      width: 120,
      '&:not(:first-child)': {
        marginLeft: 12
      }
    }
  }
})
