import React from 'react'
import { isMobileOnly } from 'react-device-detect'

import { Content, Text, Column } from '../../components'

import { useStyle } from './terms-conditions.styles'

export const TermsOfConditionsPage = () => {
  const classes = useStyle()
  const titlePreset = isMobileOnly ? 'h1Mobile' : 'h1'
  const subTitlePreset = isMobileOnly ? 'h2Mobile' : 'h2'
  const textPreset = isMobileOnly ? 'h7' : 'h5'

  return (
    <Content className={classes.container} justifyContent="flex-start">
      <Text
        text="Terms of Conditions "
        preset={titlePreset}
        tx="terms.title.name"
      />
      <Text
        className={classes.name}
        color="inactive"
        preset={textPreset}
        text="Effective on October 1, 2020"
        tx="terms.create.date"
      />
      <Column className={classes.termsText} alignItems="flex-start">
        <Text
          className={classes.textName}
          preset="body"
          text="Welcome to Ovaflopick!"
          tx="terms.text"
        />
        <Text
          preset={subTitlePreset}
          className={classes.dataName}
          text="1. Application and Acceptance of the Terms"
          tx="terms.paragrph"
        />
        <Text
          className={classes.textName}
          preset="body"
          text="Ovaflopick legal age"
          tx="terms.legal"
        />
        <Text
          preset={subTitlePreset}
          className={classes.dataName}
          text="2. Provision of Services"
          tx="terms.provision"
        />
        <Text
          className={classes.textName}
          preset="body"
          text="Register Ovaflopick"
          tx="terms.register"
        />
      </Column>
    </Content>
  )
}
