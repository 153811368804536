import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {
    padding: 20,
    width: 'calc(100% - 40px)'
  },
  background: {
    position: 'absolute',
    top: 20,
    left: 20,
    right: 20,
    width: '100%'
  },

  anchor: {
    textDecoration: 'none'
  },
  banner: {
    marginTop: 16,
    minWidth: 80
  },
  bannerOverflow: {
    left: '10%',
    position: 'absolute',
    top: 0,
    bottom: 0,
    maxWidth: 600
  },
  text: {
    marginTop: 8
  },
  image: {
    width: '100%',
    maxWidth: 1128,
    height: 400
  },
  bannerContainer: {
    marginTop: 24
  },
  '@media screen and (max-width: 480px)': {
    container: {
      padding: 0,
      width: '100%'
    },

    bannerContainer: {
      marginTop: 0,
      paddingBottom: 40
    },
    background: {
      left: 0,
      right: 0
    }
  },
  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    container: {
      width: 'calc(100% - 32px)',
      padding: '24px 16px'
    },
    background: {
      left: 0,
      right: 0
    }
  }
})
