import React, { FC } from 'react'

import {
  arrayHasElements,
  Column,
  MenuSlider,
  Row,
  Text
} from '../../../components'

import { ProductPreviewAttributes } from '../product-preview-attributes'
import { ProductPreviewFeatures } from '../product-preview-features'
import { ProductPreviewOptions } from '../product-preview-options'

import { ProductPreviewDetailsProps } from './product-preview-details.types'
import { useStyle } from './product-preview-details.styles'

export const ProductPreviewDetails: FC<ProductPreviewDetailsProps> = ({
  id,
  product
}) => {
  const classes = useStyle()

  const {
    minCurrency,
    colors = [],
    materials = [],
    options = [],
    attributes = [],
    description = ''
  } = product

  const optionHeader = (
    <Row>
      <Text text="Available Options" preset="h5" />
    </Row>
  )

  const featuresHeader = (
    <Row>
      <Text text="Product features" preset="h5" />
    </Row>
  )

  const attributeHeader = (
    <Row>
      <Text text="Attributes" preset="h5" />
    </Row>
  )

  const descriptionHeader = (
    <Row>
      <Text text="Description" preset="h5" />
    </Row>
  )

  return (
    <Column
      fullWidth
      alignItems="flex-start"
      className={classes.characteristics}
    >
      <MenuSlider
        header={optionHeader}
        contentClassName={classes.sliderContent}
      >
        <ProductPreviewOptions
          currency={minCurrency}
          id={id}
          materials={materials}
          colors={colors}
          options={options}
        />
      </MenuSlider>
      <MenuSlider header={featuresHeader}>
        <ProductPreviewFeatures product={product} />
      </MenuSlider>
      {arrayHasElements(attributes) && (
        <MenuSlider header={attributeHeader}>
          <ProductPreviewAttributes id={id} attributes={attributes} />
        </MenuSlider>
      )}
      <MenuSlider header={descriptionHeader}>
        <Row fullWidth justifyContent="flex-start">
          <Text className={classes.text} text={description || '-'} />
        </Row>
      </MenuSlider>
    </Column>
  )
}
