export const NEWS = [
  {
    id: '24234234',
    firstName: 'Floral',
    lastName: 'Chained',
    status: 'Offline',
    email: 'test@test.com',
    activity: '23h ago',
    averageResponseTime: '6h',
    dateJoined: '25.08.2020',
    feedback: '94,2%',
    backgroundImage: {
      id: '433',
      url: 'http://marketplace-qa-s3.s3.eu-central-1.amazonaws.com/media/category-backgrounds/groceries_5xm5bJ6.jpg',
      alt: 'test'
    }
  },
  {
    id: '5656342',
    firstName: 'Floral2',
    lastName: 'Chained',
    status: 'Offline',
    email: 'test1@test.com',
    activity: '22h ago',
    averageResponseTime: '6h',
    dateJoined: '23.08.2020',
    feedback: '92,2%',
    backgroundImage: {
      id: '333',
      url: 'http://marketplace-qa-s3.s3.eu-central-1.amazonaws.com/media/category-backgrounds/groceries_5xm5bJ6.jpg',
      alt: 'test'
    }
  }
]
