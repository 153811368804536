import { createUseStyles } from 'react-jss'
import { isMobileOnly, isDesktop, isTablet } from 'react-device-detect'

export const useStyle = createUseStyles({
  container: {
    padding: isMobileOnly && '64px 0px 80px 0px',
    paddingTop: isTablet && 40,
    paddingBottom: !isMobileOnly && 104
  },
  link: {
    margin: '0 8px',
    textDecoration: 'none'
  },
  title: {
    marginTop: isDesktop && 88
  },
  text: {
    marginTop: 80
  },
  image: {
    marginTop: 24
  },
  list: {
    marginTop: 80
  },
  productsList: {
    marginTop: 24
  },
  product: {
    marginLeft: 20,
    '&:first-child': {
      marginLeft: 20
    }
  },
  categoryCarousel: {
    marginTop: '24px !important'
  }
})
