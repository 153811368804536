import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  topBarBanner: {
    marginTop: 48,
    background: theme.colors.blue,
    padding: '12px 80px',
    width: 'calc(100% - 160px)'
  },
  anchor: {
    textDecoration: 'none'
  },
  linkContainer: {
    width: '100%'
  },
  bannerOverflow: {
    left: '20%',
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: 350
  },
  text: {
    marginTop: 8
  },
  banner: {
    marginTop: 16
  },
  topBannerText: {},
  image: {},
  '@media screen and (max-width: 480px)': {
    text: {
      maxWidth: 280
    },
    topBarBanner: {
      marginTop: 90,
      padding: '16px 12px',
      width: 'calc(100vw - 24px)'
    },
    topBannerText: {
      width: '200px'
    },
    image: {
      objectFit: 'cover'
    },
    bannerOverflow: {
      width: 280
    }
  }
}))
