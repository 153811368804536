import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../theme'
import { CabinetPageStyle } from './cabinet.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    minHeight: 'calc(100vh - 368px)',
    flex: 1,
    width: '100%',
    alignSelf: 'center'
  },
  content: {
    flex: 1,
    alignSelf: 'stretch'
  },
  navigator: {
    width: 'calc(20% - 48px)'
  },
  row: {
    flex: 1
  },
  '@media screen and (max-width: 1223px)': {
    container: {
      width: '100%',
      padding: 0,
      minHeight: 'auto'
    }
  },
  '@media screen and (min-width: 1224px)': {
    content: {
      borderLeft: `1px solid ${theme.colors.shadow}`,
      marginLeft: ({ chat }: CabinetPageStyle) => (chat ? 0 : 12),
      padding: ({ chat }: CabinetPageStyle) => (chat ? 0 : '0 16px'),

      width: ({ chat }: CabinetPageStyle) =>
        chat ? '80%' : 'calc(80% - 53px)',
      minHeight: 569
    }
  },
  '@media screen and (max-width: 480px)': {
    container: {
      width: 'calc(100% - 24px)'
    }
  }
}))
