import React, { FC, useRef, useState } from 'react'

import { concatClassNames } from '../../../utils'
import { Row, Column, Text } from '../../../components'

import { ProductOptions } from '../product-options'
import { ProductAttributes } from '../product-attributes'
import { ProductFeatures } from '../product-features'

import {
  ProductDetailsFilterProps,
  SelectCategory
} from './product-details-filter.types'
import { useStyle } from './product-details-filter.styles'

export const ProductDetailsFilter: FC<ProductDetailsFilterProps> = ({
  product,
  onChange
}) => {
  const classes = useStyle()

  const scrollContainer = useRef<HTMLDivElement>(null)

  const [active, changeActive] = useState('Description')

  const {
    colors = [],
    materials = [],
    options = [],
    attributes = [],
    description = ''
  } = product

  const selectCategory: SelectCategory[] = [
    {
      name: 'Available Options',
      tx: 'product.options',
      content: (
        <ProductOptions
          className={classes.charactContent}
          materials={materials}
          colors={colors}
          options={options}
        />
      )
    },
    {
      name: 'Product features',
      tx: 'product.features',
      content: (
        <ProductFeatures className={classes.charactContent} product={product} />
      )
    },
    {
      name: 'Attributes',
      tx: 'product.attributes',
      content: (
        <ProductAttributes
          className={classes.charactContent}
          attributes={attributes}
        />
      )
    },
    {
      name: 'Description',
      tx: 'product.description',
      content: (
        <Row
          fullWidth
          justifyContent="flex-start"
          className={classes.charactContent}
        >
          <Text text={description || '-'} />
        </Row>
      )
    }
  ]

  const titleClassName = (item: SelectCategory) =>
    item.name === active ? classes.selected : classes.notSelected

  const activeItem = selectCategory?.find((item) => item.name === active)

  const handleOnCategoryChange = (name: string) => () => {
    const current = active === name

    if (current) {
      if (onChange) {
        onChange('')
      }
      changeActive('')
    } else {
      changeActive(name)
      if (onChange) {
        onChange(name)
      }
    }
  }

  return (
    <Column fullWidth>
      <Row
        className={classes.filterCategory}
        ref={scrollContainer}
        justifyContent="flex-start"
      >
        {selectCategory.map((item) => {
          return (
            <Text
              key={`${item.name}`}
              className={concatClassNames(classes.text, titleClassName(item))}
              color="inactive"
              preset="h6"
              text={item.name}
              onClick={handleOnCategoryChange(item.name)}
            />
          )
        })}
      </Row>
      {activeItem && activeItem.content}
    </Column>
  )
}
