import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    backgroundColor: theme.colors.vendorProfileFon,
    border: `1px solid ${theme.colors.shadow}`,
    borderRadius: 4
  },
  content: {
    borderBottom: `1px solid ${theme.colors.shadow}`,
    padding: 16,
    width: 'calc(100% - 32px)',
    '&:last-child': {
      borderBottom: 'none'
    }
  },
  status: {
    marginTop: 8
  },
  statusText: {
    marginLeft: 24
  },
  priceText: {
    marginLeft: 80
  },
  bottomText: {
    marginLeft: 8
  },
  number: {
    marginLeft: 6
  },
  mobileRow: {},
  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    container: {
      width: '100%'
    }
  },
  '@media screen and (max-width: 480px)': {
    container: {
      width: '100%'
    },
    priceText: {
      marginLeft: 0
    },
    bottomText: {
      marginLeft: 0
    },
    mobileRow: {
      width: '100%',
      justifyContent: 'space-between',
      '&:last-child': {
        marginTop: 8
      }
    }
  }
}))
