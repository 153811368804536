import React from 'react'

import {
  Content,
  Text,
  Row,
  Column,
  Loader,
  NavLink
} from '../../../components'
import { useApi } from '../../../providers'
import { MainRoutes } from '../../../routes'

import { useStyle } from './catalogue-tablet.styles'

export const CatalogueTabletPage = () => {
  const {
    category: { useCatalogues }
  } = useApi()
  const classes = useStyle()
  const { data, loading } = useCatalogues()

  if (!data && loading) {
    return <Loader />
  }

  return (
    <Content className={classes.cataloguePage} justifyContent="flex-start">
      <Row>
        <Text text="Catalogue" tx="catalogue.title" preset="h1" color="black" />
      </Row>
      {data &&
        data.categories.edges.map((category) => (
          <Row className={classes.categoryWrapper} key={category.node.id}>
            <Column
              fullWidth
              alignItems="flex-start"
              justifyContent="flex-start"
              className={classes.menuBlock}
            >
              <Row className={classes.header}>
                <NavLink
                  preset="h3"
                  text={category.node.name}
                  color="black"
                  to={`${MainRoutes.CATEGORY}/${category.node.id}`}
                />
              </Row>
              <Row
                className={classes.category}
                alignItems="flex-start"
                justifyContent="flex-start"
                wrap
              >
                {category.node.children.edges.map((subcategory) => (
                  <Column
                    className={classes.listGroup}
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    key={subcategory.node.id}
                  >
                    <NavLink
                      preset="h5"
                      color="black"
                      text={subcategory.node.name}
                      to={`${MainRoutes.CATEGORY}/${subcategory.node.id}`}
                    />
                    {subcategory.node.children.edges.map((listGroup) => (
                      <Row className={classes.listItem} key={listGroup.node.id}>
                        <NavLink
                          className={classes.link}
                          preset="body"
                          color="black"
                          text={listGroup.node.name}
                          to={`${MainRoutes.CATEGORY}/${listGroup.node.id}/products`}
                        />
                      </Row>
                    ))}
                  </Column>
                ))}
              </Row>
            </Column>
          </Row>
        ))}
    </Content>
  )
}
