import React, { FC, useState } from 'react'
import { useTheme } from 'react-jss'
import { useHistory } from 'react-router-dom'
import StarRatingComponent from 'react-star-rating-component'

import { MainRoutes } from '../../routes'
import { Color, LightTheme } from '../../theme'
import { useDefaultValue } from '../../hooks'

import { Icon, ICONS } from '../icon'
import { Row } from '../row'
import { HeaderAdditionalLinks } from '../header-bottom-bar'

import { RatingStarsProps } from './rating-stars.types'
import { useStyle } from './rating-stars.styles'

export const RatingStarsComponent: FC<RatingStarsProps> = ({
  className = '',
  rating,
  onChange,
  defaultRating = 0,
  onHover,
  onClick,
  edit
}) => {
  const history = useHistory()
  const isBigStar =
    history.location.pathname.includes(MainRoutes.VENDOR) ||
    history.location.pathname.includes(MainRoutes.VENDOR_FEEDBACK) ||
    history.location.pathname.includes(HeaderAdditionalLinks.PRODUCT)
  const classes = useStyle({ bigStar: isBigStar })
  const theme = useTheme<LightTheme>()

  const [ratingStar, changeRatingStar] = useState(defaultRating)

  const Value = useDefaultValue<number>({
    outerValue: rating,
    value: ratingStar
  })

  const handleOnHover = (nextValue: number) => {
    if (onHover) {
      onHover(nextValue)
    }
  }

  const handleOnRating = (nextValue: number) => {
    changeRatingStar(nextValue)

    if (onChange) {
      onChange(nextValue)
    }
  }

  const handleOnClickStar = (nextValue: number) => {
    const active = nextValue <= Value
    const color: Color = active ? 'yellow' : 'black'
    const src = active ? ICONS.starFilled : ICONS.starBorder
    return (
      <Icon className={classes.icon} fill={theme.colors[color]} src={src} />
    )
  }

  return (
    <Row className={className} onClick={onClick}>
      <StarRatingComponent
        name="rate1"
        starCount={5}
        value={Value}
        starColor="red"
        editing={edit}
        renderStarIcon={handleOnClickStar}
        onStarClick={handleOnRating}
        onStarHover={handleOnHover}
      />
    </Row>
  )
}
