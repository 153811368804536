import React, { FC } from 'react'

import { v4 as uuid } from 'uuid'

import { ShoppingListItem, getCurrencyByProduct } from '../../../components'

import { useStyle } from './shopping-cart-table.styles'
import { ShoppingCartTableProps } from './shopping-cart-table.types'

export const ShoppingCartTable: FC<ShoppingCartTableProps> = ({
  basket,
  onChangeStatus,
  onRemoveOffer,
  onContactVendorOffer
}) => {
  const classes = useStyle()

  return (
    <>
      {basket &&
        basket.map((offer, index) => {
          const { id, product } = offer
          const currency = getCurrencyByProduct(product)
          const nextIndex = index + 1

          return (
            <ShoppingListItem
              key={`${uuid()}${id}`}
              {...offer}
              className={classes.item}
              index={nextIndex}
              currency={currency}
              onRemove={onRemoveOffer}
              onChangeStatus={onChangeStatus}
              onContactVendorOffer={onContactVendorOffer}
            />
          )
        })}
    </>
  )
}
