import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    backgroundColor: theme.colors.white,
    borderBottom: `1px solid ${theme.colors.shadow}`,
    borderTop: `1px solid ${theme.colors.shadow}`,
    padding: '12px 16px',
    width: 'calc(100% - 32px)'
  },
  nav: {
    flex: 3
  },
  iconsDuo: {
    flex: 1,
    position: 'relative'
  },
  icon: {
    cursor: 'pointer',
    '&:hover > path': {
      fill: theme.colors.yellow
    }
  },
  text: {
    paddingLeft: 12
  }
}))
