import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import {
  ColorDot,
  getCurrencySymbol,
  OptionExtraRow,
  Row,
  Text
} from '../../../components'
import { LightTheme } from '../../../theme'

import { useStyle } from './product-preview-details-block-option.styles'
import { ProductPreviewDetailsBlockOptionProps } from './product-preview-details-block-option.types'

export const ProductPreviewDetailsBlockOption: FC<
  ProductPreviewDetailsBlockOptionProps
> = ({
  className = '',
  color,
  label,
  labelTx,
  text,
  tx,
  extraPrice,
  isOptionSection,
  currency
}) => {
  const theme = useTheme<LightTheme>()
  const classes = useStyle()
  const currencySymbol = getCurrencySymbol(currency)

  return (
    <Row fullWidth justifyContent="flex-start" className={className}>
      <ColorDot
        className={classes.dot}
        color={theme.colors.yellow}
        preset="mini"
      />
      {color && (
        <ColorDot
          className={classes.color}
          color={color}
          preset="productColor"
        />
      )}
      {label && (
        <Text
          color="inactive"
          className={classes.text}
          text="test"
          tx={labelTx}
          preset="body"
        />
      )}
      <Text className={classes.text} text={text} tx={tx} />
      {isOptionSection && (
        <OptionExtraRow
          className={classes.extraRow}
          extraPrice={extraPrice}
          currencySymbol={currencySymbol}
        />
      )}
    </Row>
  )
}
