import React, { forwardRef } from 'react'

import { Product } from '../product'
import { ItemsLine } from '../items-line'

import { ProductListProps } from './product-list.types'
import { useStyle } from './product-list.styles'

export const ProductList = forwardRef<HTMLDivElement, ProductListProps>(
  (
    {
      className = '',
      carousel,
      children,
      data,
      description,
      descriptionTx,
      mini,
      title,
      titlePreset,
      titleTx,
      to,
      onLoadNext
    },
    ref
  ) => {
    const classes = useStyle()

    return (
      <ItemsLine
        className={className}
        carousel={carousel}
        header={children}
        itemsLength={data.length}
        description={description}
        descriptionTx={descriptionTx}
        title={title}
        titlePreset={titlePreset}
        titleTx={titleTx}
        to={to}
        ref={ref}
        onLoadNext={onLoadNext}
      >
        {data.map(
          ({
            id,
            colors,
            image,
            minCurrency,
            maxCurrency,
            maxPrice,
            minPrice,
            totalPrice,
            isFavorite,
            name,
            isSale,
            productionDaysUpTo,
            countryOfOrigin,
            brandName,
            materialComposition,
            discountMinPrice,
            discountMaxPrice,
            options
          }) => (
            <Product
              key={`product_${id}`}
              id={id}
              className={classes.product}
              colors={colors}
              image={image}
              mini={mini}
              isFavorite={isFavorite}
              minCurrency={minCurrency}
              maxCurrency={maxCurrency}
              maxPrice={maxPrice}
              minPrice={minPrice}
              totalPrice={totalPrice}
              name={name}
              isSale={isSale}
              productionDaysUpTo={productionDaysUpTo}
              countryOfOrigin={countryOfOrigin}
              brandName={brandName}
              materialComposition={materialComposition}
              discountMinPrice={discountMinPrice}
              discountMaxPrice={discountMaxPrice}
              options={options}
            />
          )
        )}
      </ItemsLine>
    )
  }
)
