import React from 'react'

import { Column, Inbox, Loader } from '../../components'
import { useChat } from '../../providers'

import { useStyle } from './inbox.styles'

export const InboxPage = () => {
  const { session, ready } = useChat()
  const classes = useStyle()

  if (!ready || !session) {
    return (
      <Column className={classes.container} justifyContent="flex-start">
        <Loader />
      </Column>
    )
  }
  return (
    <Column fullWidth className={classes.container} justifyContent="flex-start">
      <Inbox />
    </Column>
  )
}
