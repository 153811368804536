import React, { FC } from 'react'
import { isMobile } from 'react-device-detect'

import { Button } from '../../button'
import { Column } from '../../column'
import { Icon, ICONS } from '../../icon'
import { ResponsiveMediaView } from '../../responsive-media-view'
import { Row } from '../../row'
import { Text } from '../../text'

import { useStyle } from './shopping-list-item-header.styles'
import { ShoppingListItemHeaderProps } from './shopping-list-item-header.types'

export const ShoppingListItemHeader: FC<ShoppingListItemHeaderProps> = ({
  index,
  companyName,
  onOpenRemoveOfferModal,
  onContactVendorOffer
}) => {
  const classes = useStyle()
  const Container = isMobile ? Column : Row

  const Company = (
    <Row fullWidth justifyContent="flex-start" className={classes.companyRow}>
      <Text
        className={classes.company}
        text="Company:"
        tx="shopping.cart.item.company"
        preset="body"
        color="inactive"
      />
      {companyName && (
        <Text
          className={classes.companyName}
          text={companyName}
          preset="h4"
          color="black"
        />
      )}
    </Row>
  )

  const ContactButton = (
    <Button
      className={classes.contact}
      color="yellow"
      preset="borderButton"
      textColor="yellow"
      textPreset="maxButton"
      text="Contact Maker"
      tx="shopping.cart.item.contact.maker"
      onClick={onContactVendorOffer}
    />
  )

  return (
    <Container
      wrap
      fullWidth
      justifyContent="space-between"
      className={classes.headerContainer}
    >
      <Row fullWidth justifyContent="space-between">
        <Row>
          <Text
            text="Offer"
            tx="shopping.cart.item.offer"
            preset="h3"
            color="black"
          />
          <Text
            className={classes.index}
            text={`#${String(index)}`}
            preset="h3"
            color="black"
          />
          <ResponsiveMediaView bigScreen={Company} medium={Company} />
        </Row>
        <Row>
          <ResponsiveMediaView
            bigScreen={ContactButton}
            medium={ContactButton}
            tablet={ContactButton}
          />
          <Row className={classes.close}>
            <Icon src={ICONS.close} onClick={onOpenRemoveOfferModal} />
          </Row>
        </Row>
      </Row>
      <ResponsiveMediaView tablet={Company} mobile={Company} />
    </Container>
  )
}
