import { getPriceToFixed } from '../../pages'
import {
  OfferColor,
  OfferMaterial,
  OfferOption,
  ProductOptionVariant
} from '../../services'

export const getMaterialExtraPrice = (materials: OfferMaterial[]) =>
  materials
    .filter((material) => material.isDefault === true)
    .map((material) => material.extraPrice)
    .reduce((acc: number, price: number) => {
      return acc + price
    }, 0)

export const getColorExtraPrice = (colors: OfferColor[]) =>
  colors
    .filter((color) => color.isDefault === true)
    .map((color) => color.extraPrice)
    .reduce((acc: number, price: number) => {
      return acc + price
    }, 0)

export const getOptionsExtraPrice = (options: ProductOptionVariant[]) =>
  options
    .map((option) => option.extraPrice)
    .reduce((acc: number, price: number) => {
      return acc + price
    }, 0)

export const getOptionsOfferExtraPrice = (offerOptions: OfferOption[]) =>
  offerOptions
    .map((offerOption) => offerOption.variants)
    .filter((option, index) => option[index].isDefault === true)
    .map((variable, index) => variable[index].extraPrice)
    .reduce((acc: number, price: number) => {
      return acc + price
    }, 0)

export const getPriceWithSymbol = (
  price?: number,
  currency?: string | null
) => {
  if (price) {
    return `${currency}${getPriceToFixed(price)}`
  }
  return '0'
}
