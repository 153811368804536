import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {
    padding: '40px 24px',
    marginTop: 32,
    maxWidth: 1250,
    alignSelf: 'center'
  },
  title: {
    textAlign: 'center'
  },
  titleOwerview: {
    marginTop: 24
  },
  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    container: {
      width: 'calc(100% - 32px)',
      padding: '70px 16px 40px'
    }
  },
  '@media screen and (max-width: 480px)': {
    container: {
      width: 'calc(100% - 24px)',
      padding: '24px 12px 40px 12px',
      marginTop: 0,
      justifyContent: 'flex-start'
    },
    title: {
      width: '100%'
    },
    titleOwerview: {
      textAlign: 'center'
    }
  }
})
