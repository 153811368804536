import { isMobile } from 'react-device-detect'
import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

import { ItemsListStyles } from './items-line.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {},
  product: {
    '&:hover': {
      background: theme.colors.white,
      boxShadow: `4px 4px 16px ${theme.colors.shadow}`
    },
    transition: 'box-shadow 0.25s ease-in-out'
  },
  row: {
    maxWidth: 1720,
    marginTop: 24
  },
  carouselContainer: {
    columnGap: 16,
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    padding: '0 0 12px',
    width: ({ carousel }: ItemsListStyles) =>
      carousel && !isMobile ? 'calc(100% - 80px)' : '100%',
    justifyContent: ({ arrowAvaliable }: ItemsListStyles) =>
      arrowAvaliable ? 'flex-start' : 'center'
  },
  arrowContainer: {
    background:
      'linear-gradient(90.33deg, #FFFFFF 24.62%, rgba(255, 255, 255, 0.76) 62.43%, rgba(255, 255, 255, 0) 99.72%)',
    height: '100%',
    position: 'absolute',
    left: -10,
    width: 216,
    zIndex: 2
  },
  right: {
    right: -80,
    left: 'auto',
    transform: 'matrix(-1, 0, 0, 1, 0, 0)'
  },
  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    row: {
      maxWidth: '100%'
    },
    arrowContainer: {
      left: 0,
      width: 96
    },
    right: {
      right: 0,
      left: 'auto'
    }
  },
  '@media screen and (max-width: 1023px)': {
    row: {
      maxWidth: '100%'
    },
    carouselContainer: {
      overflowX: 'auto',
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    }
  }
}))
