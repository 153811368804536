import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../theme'

import background from '../../assets/images/delete_account.png'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    width: 568
  },
  column: {
    height: 480
  },
  image: {
    marginTop: 0,
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top, center left 50px',
    backgroundImage: `url(${background})`,
    zIndex: 1,
    width: 520,
    height: 263
  },
  row: {
    backgroundColor: theme.colors.white,
    width: 'calc(100% - 48px)',
    height: 50,
    padding: 24,
    marginTop: 24,
    borderTop: `1px solid ${theme.colors.shadow}`
  },
  button: {
    width: 168
  },
  buttonLeft: {
    marginLeft: 24,
    padding: '16px 24px',
    border: `2px solid ${theme.colors.yellow}`
  },
  buttonRight: {
    marginLeft: 24
  },
  img: {
    width: 64,
    height: 64
  },
  title: {
    marginLeft: 8
  },
  text: {
    marginTop: 24
  },
  subText: {
    marginTop: 16,
    textAlign: 'center'
  },
  name: {
    border: `1px solid ${theme.colors.shadow}`,
    backgroundColor: theme.colors.lightBlue,
    borderRadius: 8,
    padding: 8,
    width: 217,
    marginTop: 16
  },
  '@media screen and (max-width: 480px)': {
    container: {
      width: 'calc(100% - 40px)'
    },
    image: {
      marginTop: 16,
      width: '100%'
    },
    row: {
      backgroundColor: theme.colors.white,
      width: 'calc(100% - 32px)',
      height: 40,
      padding: '24px 16px',
      justifyContent: 'space-between'
    },
    button: {
      width: 96
    },
    buttonLeft: {
      marginLeft: 0
    },
    buttonRight: {
      marginLeft: 0
    },
    text: {
      marginTop: 32
    },
    subText: {
      width: 'calc(100%-32px)',
      padding: '0 16px'
    }
  }
}))
