import React, { FC, MouseEvent, useState } from 'react'
import { useDefaultValue } from '../../hooks'

import { Icon, ICONS } from '../icon'
import { Row } from '../row'
import { useStyle } from './checkbox.styles'
import { CheckboxProps } from './checkbox.types'

export const Checkbox: FC<CheckboxProps> = ({
  className = '',
  checked,
  disabled,
  children,
  onChange
}) => {
  const [active, changeActive] = useState(checked)
  const Value = useDefaultValue({ outerValue: checked, value: active })

  const classes = useStyle({ active: Value })

  const handleOnClick = (
    event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    if (!disabled) {
      changeActive(!Value)
      if (onChange) {
        onChange(!Value, event)
      }
    }
  }

  return (
    <Row className={className} onClick={handleOnClick}>
      <Row className={` ${classes.container}`}>
        {Value ? (
          <Icon src={ICONS.checkBox} />
        ) : (
          <Row className={classes.innerContainer}>
            <div />
          </Row>
        )}
      </Row>
      {children}
    </Row>
  )
}
