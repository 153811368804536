import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    '& > :not(:first-child)': {
      marginLeft: 20
    }
  },
  imageContainer: {
    flex: 1,
    width: '50%',
    cursor: 'move'
  },
  image: {
    width: 600,
    objectFit: 'cover',
    overflow: 'hidden'
  },
  emptyImage: {
    minWidth: 778
  },
  color: {
    width: 21,
    height: 21,
    borderRadius: '50%',
    '&:not(:first-child)': {
      marginLeft: 8
    }
  },
  list: {
    width: 'auto'
  },
  zoom: {
    background: theme.colors.white,
    borderRadius: 40,
    bottom: -20,
    boxShadow: `1px 2px 8px ${theme.colors.shadow}`,
    height: 40,
    position: 'absolute',
    right: 16,
    width: 40
  },
  zoomContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: theme.colors.inactive,
    zIndex: 5
  },
  '@media screen and (max-width: 1200px)': {
    container: {
      flexWrap: 'wrap'
    },
    imageContainer: {
      width: '100%',
      order: 1
    },
    list: {
      width: '100%',
      order: 2
    },
    zoom: {
      top: 500,
      zIndex: 10
    }
  }
}))
