import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../theme'

import { Icon, ICONS } from '../icon'
import { Row } from '../row'
import { Text } from '../text'

import { useStyle } from './go-back-container.styles'
import { GoBackContainerProps } from './go-back-container.types'

export const GoBackContainer: FC<GoBackContainerProps> = ({
  className,
  text,
  textTx,
  onClick
}) => {
  const theme = useTheme<LightTheme>()
  const classes = useStyle({})
  return (
    <Row
      fullWidth
      justifyContent="flex-start"
      className={`${classes.backContainer} ${className}`}
      onClick={onClick}
    >
      <Icon
        height={24}
        width={24}
        fill={theme.colors.inactive}
        src={ICONS.leftArrow}
      />
      <Text
        className={classes.backTitle}
        text={text}
        tx={textTx}
        preset="h6"
        color="black"
      />
    </Row>
  )
}
