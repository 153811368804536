import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    borderBottom: `1px solid ${theme.colors.shadow}`
  },
  list: {
    padding: '16px 0px'
  }
}))
