import { useQuery } from '@apollo/client'

import { ListVariables } from '../api.types'
import { LATEST_NEWS_BY_ID, LATEST_NEWS_LIST } from './news.graphql'
import {
  LatestNewsByIdVariables,
  LatestNewsById,
  LatestNewsByIdData,
  LatestNewsDataList,
  LatestNewsList,
  NewsApi
} from './news.types'

// our "constructor"
export const newsService = (): NewsApi => {
  const useLatestNewsById = (
    variables: LatestNewsByIdVariables
  ): LatestNewsById => {
    const { data, loading, error } = useQuery<
      LatestNewsByIdData,
      LatestNewsByIdVariables
    >(LATEST_NEWS_BY_ID, { variables })

    if (error) {
      return { data: null, loading }
    }

    if (!data) {
      return { data: null, loading }
    }

    return { data, loading }
  }

  const useLatestNewsList = (variables: ListVariables): LatestNewsList => {
    const { data, loading, error, refetch, fetchMore } = useQuery<
      LatestNewsDataList,
      ListVariables
    >(LATEST_NEWS_LIST, { variables })

    return { data, error, loading, refetch, fetchMore }
  }

  return { useLatestNewsById, useLatestNewsList }
}
