import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  avatarAndName: {
    padding: '24px 0px 16px',
    columnGap: 20,
    width: '100%',
    alignItems: 'flex-end',
    justifyContent: 'flex-start'
  },
  sex: {
    marginTop: 0
  },
  indicators: {
    paddingBottom: 40,
    borderBottom: `1px solid ${theme.colors.shadow}`
  },
  indicator: {
    flex: 1
  }
}))
