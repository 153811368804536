import {
  LazyQueryExecFunction,
  MutationResult,
  OperationVariables
} from '@apollo/client'

import { CountryCode } from '../country'
import { TextProps } from '../../components'
import { Sex } from '../../pages'
import { PaginationList } from '../api.types'
import { FeedbackWithCount } from '../feedback'
import { Order } from '../order'
import { Offer } from '../offer'
import { Product, WishlistItem } from '../product'

export enum AccountErrorCode {
  ACTIVATE_OWN_ACCOUNT = 'ACTIVATE_OWN_ACCOUNT',
  ACTIVATE_SUPERUSER_ACCOUNT = 'ACTIVATE_SUPERUSER_ACCOUNT',
  DUPLICATED_INPUT_ITEM = 'DUPLICATED_INPUT_ITEM',
  DEACTIVATE_OWN_ACCOUNT = 'DEACTIVATE_OWN_ACCOUNT',
  DEACTIVATE_SUPERUSER_ACCOUNT = 'DEACTIVATE_SUPERUSER_ACCOUNT',
  DELETE_NON_STAFF_USER = 'DELETE_NON_STAFF_USER',
  DELETE_OWN_ACCOUNT = 'DELETE_OWN_ACCOUNT',
  DELETE_STAFF_ACCOUNT = 'DELETE_STAFF_ACCOUNT',
  DELETE_SUPERUSER_ACCOUNT = 'DELETE_SUPERUSER_ACCOUNT',
  GRAPHQL_ERROR = 'GRAPHQL_ERROR',
  INVALID = 'INVALID',
  INVALID_PASSWORD = 'INVALID_PASSWORD',
  LEFT_NOT_MANAGEABLE_PERMISSION = 'LEFT_NOT_MANAGEABLE_PERMISSION',
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  NOT_FOUND = 'NOT_FOUND',
  OUT_OF_SCOPE_SERVICE_ACCOUNT = 'OUT_OF_SCOPE_SERVICE_ACCOUNT',
  OUT_OF_SCOPE_USER = 'OUT_OF_SCOPE_USER',
  OUT_OF_SCOPE_GROUP = 'OUT_OF_SCOPE_GROUP',
  OUT_OF_SCOPE_PERMISSION = 'OUT_OF_SCOPE_PERMISSION',
  PASSWORD_ENTIRELY_NUMERIC = 'PASSWORD_ENTIRELY_NUMERIC',
  PASSWORD_TOO_COMMON = 'PASSWORD_TOO_COMMON',
  PASSWORD_TOO_SHORT = 'PASSWORD_TOO_SHORT',
  PASSWORD_TOO_SIMILAR = 'PASSWORD_TOO_SIMILAR',
  REQUIRED = 'REQUIRED',
  UNIQUE = 'UNIQUE',
  JWT_SIGNATURE_EXPIRED = 'JWT_SIGNATURE_EXPIRED',
  JWT_INVALID_TOKEN = 'JWT_INVALID_TOKEN',
  JWT_DECODE_ERROR = 'JWT_DECODE_ERROR',
  JWT_MISSING_TOKEN = 'JWT_MISSING_TOKEN',
  JWT_INVALID_CSRF_TOKEN = 'JWT_INVALID_CSRF_TOKEN'
}

export type GetRegisterRequest = {
  email: string
  password: string
  firstName?: string
  lastName?: string
  sex?: Sex
  role?: number
  redirectUrl?: string
}

export interface UserAvatar {
  __typename: 'Image'
  url: string
}

export type User = {
  id: string
  firstName: string
  lastName: string
  email: string
  phone: string
  city: string
  location?: string
  jwtToken: string
  sex: Sex
  defaultShippingAddress: Address | null
  defaultBillingAddress: Address | null
  status: string
  activity: string
  averageResponseTime: string
  ordersAmount: number
  sales: number
  companyName: string
  country: string
  state: string
  username: string
  positiveFeedbackPercentage?: string
  dateJoined?: string
  rating?: string
  bestSellingProducts?: Product[]
  avatar: UserAvatar | null
  feedbacks: FeedbackWithCount[]
  feedbackAmount: number
  wishlist: PaginationList<WishlistItem>
  orders: PaginationList<Order>
  offers: PaginationList<Offer>
  ordersToReturn?: PaginationList<Order>
  conversationId?: string
  unreadMessages: number
}

export type UserEdge = {
  node: User
  cursor: string
}

export type GetLoginResponse = {
  token: string
}

export type AccountErrors = {
  code: AccountErrorCode
  field: string
  message: string
}

export type GetRegisterResults = {
  accountRegister: {
    accountErrors: AccountErrors[]
    requiresConfirmation: boolean
    jwtToken: string
    refreshToken: string
    crfsToken: string
    talkjsSignature?: string
    user: User
  }
}

export type AccountError = {
  code: AccountErrorCode
  field: string
  message: string
}

export type GetAccountUpdateResults = {
  accountUpdate: {
    accountErrors: AccountError[]
    user: User
  }
}

export type GetAccountUpdateRequest = {
  input: Partial<AccountInput>
}

export type AccountInput = {
  firstName?: string
  lastName?: string
  phone?: string
  email?: string
  sex: Sex
  defaultBillingAddress?: AddressInput
  defaultShippingAddress?: AddressInput
  conversationId: string
}

export type GetTokenRefreshResults = {
  tokenRefresh: {
    accountErrors: AccountErrors[]
    user: User
    token: string
    talkjsSignature?: string
  }
}

export type GetTokenCreateResults = {
  tokenCreate: {
    token: string
    refreshToken: string
    csrfToken: string
    talkjsSignature?: string
    user: User
    accountErrors: AccountErrors[]
  }
}

export type GetPasswordResetResults = {
  requestPasswordReset: {
    accountErrors: AccountErrors[]
  }
}

export type GetSetPasswordResults = {
  setPassword: {
    token: string
    refreshToken: string
    csrfToken: string
    user: User
    accountErrors: AccountErrors[]
  }
}

export type GetChangePasswordResults = {
  passwordChange: {
    user: User
    accountErrors: AccountErrors[]
  }
}

export type GetFeedbackResults = {
  feedback: {
    accountErrors: AccountErrors[]
  }
}
export type GetAccountDelete = {
  accountDelete: {
    accountErrors: AccountErrors[]
    user: User
  }
}
export type GetTokenAccontDelete = {
  token: string
}

export type GetTokenContactUs = {
  name: string
  email: string
  message: string
  error?: TextProps | undefined
  disableButtonError?: boolean
}

export type GetTokenCreateRequest = {
  email: string
  password: string
  page?: string
  firstName?: string
  disabled?: boolean
  sex?: Sex
  error?: TextProps | undefined
}

export type GetTokenProfileSettingsRequest = {
  firstName?: string
  lastName?: string
  phoneNumber?: string
  sex?: Sex
  error?: TextProps | undefined
  currentEmail?: string
  newEmail: string
  disabled?: boolean
  currentNewPassword?: string
  currentPassword?: string
  newPassword?: string
}

export type GetRequestPasswordReset = {
  email: string
}

export type GetChangePassword = {
  newPassword?: string
  oldPassword?: string
}

export type GetSetPassword = {
  email: string
  password: string
  token: string
}

export type FeedbackCreateVariables = {
  email: string
  message: string
  name: string
}
export type AccountDeleteVariables = {
  token: string
}

export type Address = {
  id: string
  firstName: string
  lastName: string
  companyName: string
  streetAddress1: string
  streetAddress2: string
  city: string
  cityArea: string
  postalCode: string
  country?: {
    country: string
    code: CountryCode
  }
  countryArea: string
  phone: string
  isDefaultShippingAddress: boolean
  isDefaultBillingAddress: boolean
}

export enum AccountAddressType {
  BILLING = 'BILLING',
  SHIPPING = 'SHIPPING'
}

export type AddressInput = {
  firstName: string
  lastName: string
  companyName: string
  streetAddress1: string
  streetAddress2: string
  city: string
  cityArea: string
  postalCode: string
  country: CountryCode
  countryArea: string
  phone: string
}

export type GetAccountAddressCreateResults = {
  accountAddressCreate: {
    accountErrors: AccountErrors[]
    address: Address
    user: User
  }
}

export type GetAccountAddressEditResults = {
  accountAddressUpdate: {
    accountErrors: AccountErrors[]
    address: Address
    user: User
  }
}

export type GetAccountMeData = {
  me: User
}

export type GetPartAccountMeData = {
  me: User
}

export interface IsUserExistResults {
  isUserExists: {
    isUserExists: boolean
  }
}

export type GetDefaultAccountAddressResults = {
  accountSetDefaultAddress: {
    accountErrors: AccountErrors[]
    user: User
  }
}

export type AccountAddressCreateVariables = {
  input: AddressInput
  type: AccountAddressType
}

export type AccountAddressEditVariables = {
  input: AddressInput
  id: string
}

export type AccountUpdateVariables = {
  input: AccountInput
  id: string
}

export type DefaultAccountAddressVariables = {
  id: string
  type: AccountAddressType
}

export type IsUserExistVariables = {
  email: string
}

export type PasswordReset = {
  onSubmit: (email: string) => void
  response: MutationResult<GetPasswordResetResults>
}

export type SetPassword = {
  onSubmit: (variables: GetSetPassword) => void
  response: MutationResult<GetSetPasswordResults>
}
export type ChangePassword = {
  onSubmit: (variables: GetChangePassword) => void
  response: MutationResult<GetChangePasswordResults>
}

export type FeedbackMutation = {
  onSubmit: (variables: FeedbackCreateVariables) => void
  response: MutationResult<GetFeedbackResults>
}

export type TokenCreate = {
  onSubmit: (data: GetTokenCreateRequest) => void
  response: MutationResult<GetTokenCreateResults>
}

export type TokenRefresh = {
  onSubmit: (refreshToken: string) => void
  response: MutationResult<GetTokenRefreshResults>
}

export type UserRegister = {
  onSubmit: (data: GetRegisterRequest) => void
  response: MutationResult<GetRegisterResults>
}

export type AccountProfileUpdate = {
  onSubmit: (data: GetAccountUpdateRequest) => void
  response: MutationResult<GetAccountUpdateResults>
}

export type AccountEmailUpdate = {
  onSubmit: (data: GetAccountUpdateRequest) => void
  response: MutationResult<GetAccountUpdateResults>
}

export type AccountAddressCreate = {
  onSubmit: (variables: AccountAddressCreateVariables) => void
  response: MutationResult<GetAccountAddressCreateResults>
}

export type AccountAddressEdit = {
  onSubmit: (variables: AccountAddressEditVariables) => void
  response: MutationResult<GetAccountAddressEditResults>
}

export type AccountUpdate = {
  onSubmit: (variables: AccountUpdateVariables) => void
  response: MutationResult<GetAccountAddressEditResults>
}

export type AccountMe = {
  data?: GetAccountMeData
  lazyFetch: LazyQueryExecFunction<GetAccountMeData, OperationVariables>
  loading: boolean
  refetch: () => void
}

export type AccountPartMe = {
  data?: GetPartAccountMeData
  loading: boolean
  refetch: () => void
}

export type AccountDelete = {
  onSubmit: (variables: AccountDeleteVariables) => void
  response: MutationResult<GetAccountDelete>
}

export type DefaultAccountAddress = {
  onSubmit: (variables: DefaultAccountAddressVariables) => void
  response: MutationResult<GetDefaultAccountAddressResults>
}

export type AuthApiOptions = {
  setHeader: (key: string, prop: string) => void
}

export interface IsUserExist {
  onSubmit: (variables: IsUserExistVariables) => void
  response: MutationResult<IsUserExistResults>
}

export type AuthApi = {
  useToken: () => TokenCreate
  useRegister: () => UserRegister
  useAccountProfileUpdate: () => AccountProfileUpdate
  useChangePassword: () => ChangePassword
  useRefreshToken: () => TokenRefresh
  setHeader: (key: string, prop: string) => void
  useIsLoggedIn: () => boolean
  useIsLogout: () => void
  useRequestResetPassword: () => PasswordReset
  useSetPassword: () => SetPassword
  useAccountAddressCreate: () => AccountAddressCreate
  useAccountAddressEdit: () => AccountAddressEdit
  useDefaultAccountAddress: () => DefaultAccountAddress
  useFeedback: () => FeedbackMutation
  useAccountDelete: () => AccountDelete
  useMe: () => AccountMe
  usePartMe: () => AccountPartMe
  useIsUserExist: () => IsUserExist
}
