import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {},
  rightContainer: {},
  dateRow: {},
  reviewsRow: {
    marginTop: 8
  },
  review: {
    marginLeft: 4
  },
  feedbackContainer: {
    backgroundColor: theme.colors.vendorProfileFon,
    marginTop: 24,
    border: `1px solid ${theme.colors.shadow}`,
    borderRadius: 4
  },
  leftContainer: {
    padding: 24
  },
  date: {
    marginTop: 16
  },

  feedback: {
    paddingBottom: 24
  },
  text: {
    marginTop: 24
  },
  answerText: {
    marginLeft: 8
  },
  answer: {
    paddingTop: 24,
    borderTop: `1px solid ${theme.colors.shadow}`
  },
  submit: {
    width: '100%',
    padding: '16px 0',
    marginTop: 40
  },

  '@media screen and (max-width: 1223px)': {
    container: {
      width: '100%',
      padding: 0
    },
    submit: {
      width: '100%'
    },
    rightContainer: {
      borderTop: `1px solid ${theme.colors.shadow}`,
      width: 'calc(100% - 48px)',
      padding: 24
    },
    leftContainer: {
      width: 'calc(100% - 48px)'
    }
  },
  '@media screen and (min-width: 600px)': {
    submit: {
      width: 536
    }
  },
  '@media screen and (min-width: 1224px)': {
    container: {
      width: '100%'
    },
    feedbackContainer: {
      flexDirection: 'row'
    },
    rightContainer: {
      borderLeft: `1px solid ${theme.colors.shadow}`,
      flex: 4,
      width: 'calc(100% - 48px)',
      padding: '24px 24px 24px 64px',
      minHeight: 116
    },
    leftContainer: {
      flex: 1
    },
    dateRow: {
      flesDirection: 'column'
    }
  }
}))
