import React, { FC, useState, useEffect } from 'react'
import { isMobileOnly } from 'react-device-detect'

import { getFormatedOrderDate } from '../../views'
import { FeedbackWithCount } from '../../services'

import { getFullName } from '../helpers'
import { Column } from '../column'
import { Row } from '../row'
import { Text } from '../text'
import { Button } from '../button'
import { RatingStarsComponent } from '../rating-stars'

import { useStyle } from './feedbacks-list-vendor.styles'
import { FeedbackListVendorProps } from './feedbacks-list-vendor.types' //

export const FeedbacksListVendor: FC<FeedbackListVendorProps> = ({
  className = '',
  data
}) => {
  const classes = useStyle()

  const titlePreset = isMobileOnly ? 'h2Mobile' : 'h1'
  const [items, changeItems] = useState<FeedbackWithCount[]>([])
  const [visible, changeVisible] = useState(3)

  const isShowButton = data && data.length > 3 && visible <= data.length

  useEffect(() => {
    if (data) {
      changeItems(data)
    }
  }, [])

  const handleOnLoadNext = () => {
    changeVisible((prevState) => prevState + 3)
  }

  return (
    <Column className={className} fullWidth>
      <Column className={classes.container}>
        <Text text="Feedback" tx="feedback.list.title" preset={titlePreset} />
        <Row fullWidth className={classes.reviewsRow}>
          {data && (
            <Text text={data.length.toString()} preset="h7" color="inactive" />
          )}
          <Text
            text="reviews"
            preset="h7"
            color="inactive"
            className={classes.review}
          />
        </Row>
        {items &&
          items.slice(0, visible).map((feedback) => {
            const { id, user, rating, comment, answer, createdAt } = feedback
            const fullName = getFullName(user)
            const date = getFormatedOrderDate(new Date(createdAt))

            return (
              <Column
                key={`feedback_${id}`}
                fullWidth
                alignItems="flex-start"
                justifyContent="flex-start"
                className={classes.feedbackContainer}
              >
                <Column
                  alignItems="flex-start"
                  className={classes.leftContainer}
                >
                  <Row fullWidth justifyContent="flex-start">
                    <Text text={fullName} preset="h4" />
                  </Row>
                  <Row
                    fullWidth
                    className={classes.dateRow}
                    alignItems="baseLine"
                    justifyContent="space-between"
                  >
                    <Text
                      text={date}
                      preset="h7"
                      color="inactive"
                      className={classes.date}
                    />
                  </Row>
                </Column>
                <Column
                  fullWidth
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className={classes.rightContainer}
                >
                  <Column
                    fullWidth
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    className={classes.feedback}
                  >
                    {rating > 0 && (
                      <Row fullWidth justifyContent="flex-start">
                        <RatingStarsComponent rating={rating} edit={false} />
                      </Row>
                    )}
                    {comment && (
                      <Text
                        className={classes.text}
                        text={comment}
                        preset="body"
                      />
                    )}
                  </Column>
                  {answer && (
                    <Row
                      fullWidth
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      className={classes.answer}
                    >
                      <Row>
                        <Text text="Answer:" preset="body" color="inactive" />
                      </Row>
                      <Row>
                        <Text
                          className={classes.answerText}
                          text={answer}
                          preset="body"
                          color="black"
                        />
                      </Row>
                    </Row>
                  )}
                </Column>
              </Column>
            )
          })}
        {isShowButton && (
          <Button
            className={classes.submit}
            preset="primary"
            textColor="white"
            textPreset="h5"
            text="VIEW MORE"
            tx="feedbacks.button.text"
            onClick={handleOnLoadNext}
          />
        )}
      </Column>
    </Column>
  )
}
