import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    width: '100%',
    paddingBottom: 40
  },
  reviewsRow: {
    marginTop: 8
  },
  review: {
    marginLeft: 4
  },
  feedbackContainer: {
    backgroundColor: theme.colors.vendorProfileFon,
    marginTop: 24,
    border: `1px solid ${theme.colors.shadow}`,
    borderRadius: 4
  },
  leftContainer: {
    flex: 1,

    padding: 24
  },
  lastName: {
    marginLeft: 4
  },
  date: {
    marginTop: 16
  },
  rightContainer: {
    borderLeft: `1px solid ${theme.colors.shadow}`,
    flex: 4,
    padding: '24px 24px 24px 64px',
    minHeight: 116
  },

  observeOptionsBtn: {
    background: theme.colors.white,
    marginTop: 16,
    padding: '8px 6px'
  },
  feedback: {
    paddingBottom: 24
  },
  text: {
    marginTop: 24
  },
  answerText: {
    marginLeft: 8
  },
  answer: {
    paddingTop: 24,
    borderTop: `1px solid ${theme.colors.shadow}`
  },
  submit: {
    width: '100%',
    padding: '16px 0',
    marginTop: 40
  },
  '@media screen and (max-width: 1223px)': {
    leftContainer: {
      flex: 'none',
      width: 'calc(100% - 48px)'
    },
    rightContainer: {
      borderLeft: 'none',
      borderTop: `1px solid ${theme.colors.shadow}`,
      flex: 'none',
      width: 'calc(100% - 48px)',
      padding: 24,
      minHeight: 'none'
    }
  },
  '@media screen and (min-width: 481px)': {
    submit: {
      width: 536
    }
  }
}))
