import React, { FC } from 'react'

import { OrderContactInfoCustomer } from './order-contact-info-customer'
import { OrderContactInfoVendor } from './order-contact-info-vendor'

import { OrderContactInfoProps } from './order-contact-info.types'

export const OrderContactInfo: FC<OrderContactInfoProps> = (props) => {
  switch (props.type) {
    case 'customer':
      return <OrderContactInfoCustomer {...props} />
    case 'vendor':
      return <OrderContactInfoVendor {...props} />
    default:
      return <></>
  }
}
