import React, { FC, useState } from 'react'
import { useHistory } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import _ from 'lodash'

import { Order } from '../../services'

import { ReturnsItem } from '../returns-item'
import { Column } from '../column'
import { Row } from '../row'
import { Text } from '../text'
import { Loader } from '../loader'
import { Icon, ICONS } from '../icon'
import { Button } from '../button'

import { ChooseReturnOrderProps } from './choose-return-order.types'
import { useStyle } from './choose-return-order.styles'

export const ChooseReturnOrder: FC<ChooseReturnOrderProps> = ({
  orders,
  hasMore,
  onNext,
  onRefresh
}) => {
  const classes = useStyle()
  const history = useHistory()
  const [selectedItem, changeSelectedItem] = useState<Order>()
  const [open, changeOpen] = useState<boolean>(false)

  const count = orders.length || 0

  const handleOnClick = () => {
    if (!_.isEmpty(selectedItem) && selectedItem) {
      history.push(`/select-items/${selectedItem.id}`)
    }
  }

  const handleOpenDropdown = () => {
    changeOpen(!open)
  }

  const handleSelectOrder = (item: Order) => () => {
    changeSelectedItem(item)

    handleOpenDropdown()
  }
  const loader = (
    <Row fullWidth>
      <Loader />
    </Row>
  )

  return (
    <Column fullWidth>
      <Column fullWidth alignItems="flex-start">
        <Text
          className={classes.secondTitle}
          text="Choose Order"
          tx="returns.exchange.page.order.title"
          preset="h2"
        />
        {!_.isEmpty(orders) ? (
          <Row
            className={`${classes.row} ${open && classes.opened}`}
            onClick={handleOpenDropdown}
          >
            {selectedItem ? (
              <ReturnsItem
                order={selectedItem}
                onClick={handleSelectOrder(selectedItem)}
              />
            ) : (
              <Row className={classes.mainRow} justifyContent="flex-start">
                <Text text="Please select order" preset="body" />
              </Row>
            )}
            <Row>
              {open ? (
                <Icon className={classes.arrow} src={ICONS.arrowTop} />
              ) : (
                <Icon className={classes.arrow} src={ICONS.arrowBottom} />
              )}
            </Row>
          </Row>
        ) : (
          <Row className={classes.row}>
            <Row className={classes.mainRow} justifyContent="flex-start">
              <Text text="Your order list is empty" preset="body" />
            </Row>

            <Row>
              {open ? (
                <Icon className={classes.arrow} src={ICONS.arrowTop} />
              ) : (
                <Icon className={classes.arrow} src={ICONS.arrowBottom} />
              )}
            </Row>
          </Row>
        )}
        {open && (
          <Column
            fullWidth
            id="returnOrders"
            className={classes.ordersColumn}
            justifyContent="flex-start"
          >
            <InfiniteScroll
              className={classes.list}
              dataLength={count}
              next={onNext}
              hasMore={hasMore}
              loader={loader}
              refreshFunction={onRefresh}
              scrollableTarget="returnOrders"
            >
              {orders.map((item) => (
                <ReturnsItem
                  key={`return_item_${item.id}`}
                  className={classes.value}
                  active={item.id === selectedItem?.id}
                  order={item}
                  onClick={handleSelectOrder(item)}
                />
              ))}
            </InfiniteScroll>
          </Column>
        )}
      </Column>
      {!open && (
        <Column>
          <Button
            className={classes.button}
            textColor="white"
            text="GET STARTED"
            tx="returns.exchange.page.button.name"
            textPreset="h5"
            preset="primary"
            onClick={handleOnClick}
            disabled={_.isEmpty(selectedItem)}
          />
        </Column>
      )}
    </Column>
  )
}
