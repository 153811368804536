import React, { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Talk from 'talkjs'

import {
  Button,
  Column,
  Loader,
  PurchaseSlider,
  arrayHasElements,
  PurchaseSliderProps,
  CabinetPageContainer,
  getListFromEdgesList
} from '../../components'
import { useApi, useChat } from '../../providers'
import { Order, OrderHistoryVariables } from '../../services'
import { EmptyList } from '../../views'

import { CabinetNavigatorPath } from '../cabinet-navigator'

import { useStyle } from './purchase-history.styles'

export const PurchaseHistoryPage = () => {
  const first = 10
  const history = useHistory()
  const { order: orderApi } = useApi()
  const { createVendor, onCreateConversation, onSendOrderMessage } = useChat()

  const [variables, changeVariables] = useState<OrderHistoryVariables>({
    first
  })
  const {
    data: response,
    loading,
    fetchMore
  } = orderApi.useOrderHistory(variables)

  const classes = useStyle()

  const handleOnClickHome = () => {
    history.push('/')
  }

  const Orders = useMemo(() => {
    const ordersData = response?.orderHistory
    if (ordersData) {
      return getListFromEdgesList(ordersData)
    }

    return []
  }, [response])

  const isLoadMore = !loading && response?.orderHistory.pageInfo.hasNextPage

  const handleOnNext = () => {
    if (response && fetchMore) {
      const { endCursor, hasNextPage } = response.orderHistory.pageInfo

      if (hasNextPage) {
        const nextVariables = { ...variables, after: endCursor }

        changeVariables(nextVariables)
        fetchMore({
          variables: nextVariables
        })
      }
    }
  }

  const handleOnSendWelcomeMessage = (
    order: Order,
    conversation?: Talk.ConversationBuilder
  ) => {
    if (conversation && onSendOrderMessage) {
      onSendOrderMessage(conversation, order)
    }
  }

  const handleOnContactVendor =
    (order: Order): PurchaseSliderProps['onContactVendor'] =>
    (event) => {
      event.preventDefault()
      event.stopPropagation()

      if (order.offer) {
        history.push({
          pathname: `${CabinetNavigatorPath.ORDER_CHAT}/${order.id}${CabinetNavigatorPath.OFFER}/${order.offer.id}`,
          state: 'fromPurchaseHistory'
        })
      } else {
        const chatUser: Talk.User = createVendor(order.vendor)

        if (onCreateConversation) {
          const conversation = onCreateConversation(chatUser)
          handleOnSendWelcomeMessage(order, conversation)
        }

        history.push(CabinetNavigatorPath.VENDOR_COMMUNICATION)
      }
    }

  if (loading && !arrayHasElements(Orders)) {
    return <Loader />
  }

  return (
    <CabinetPageContainer
      label="Purchase history"
      labelTx="cabinet.route.purchaseHistory.title"
      classNameContent={classes.container}
    >
      {!arrayHasElements(Orders) && (
        <Column fullWidth>
          <EmptyList
            title="Oops! Your Purchase History is empty"
            titleTx="cabinet.route.purchaseHistory.empty.secondTitle"
            text="But it’s not to late to correct it!"
            tx="cabinet.route.purchaseHistory.empty.text"
          />
          <Button
            className={classes.emptyButton}
            color="yellow"
            preset="primary"
            textColor="white"
            textPreset="h5"
            text="HOME"
            tx="error.404.button.text"
            onClick={handleOnClickHome}
          />
        </Column>
      )}
      {Orders.map((order) => {
        return (
          <PurchaseSlider
            key={`order_${order.id}`}
            className={classes.order}
            order={order}
            onContactVendor={handleOnContactVendor(order)}
          />
        )
      })}
      <Column fullWidth className={classes.load}>
        {loading && <Loader />}
        {isLoadMore && (
          <Button
            className={classes.button}
            color="yellow"
            preset="button3"
            textColor="white"
            textPreset="h5"
            text="Load More"
            onClick={handleOnNext}
          />
        )}
      </Column>
    </CabinetPageContainer>
  )
}
