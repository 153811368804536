export enum NodeEnv {
  DEV = 'development',
  TEST = 'test',
  PRODUCTION = 'production'
}

const BASE_URL = process.env.REACT_APP_BASE_URL
const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID
const TALKJS_APP_ID = process.env.REACT_APP_TALKJS_APP_ID

if (process.env.REACT_APP_CONSOLE_ENV === 'true') {
  // eslint-disable-next-line no-console
  console.log(process.env)
}

if (!BASE_URL) {
  // eslint-disable-next-line no-console
  console.error('Not setting REACT_APP_BASE_URL to environment')
}

if (!PAYPAL_CLIENT_ID) {
  // eslint-disable-next-line no-console
  console.error('Not setting REACT_APP_PAYPAL_CLIENT_ID to environment')
}

if (!TALKJS_APP_ID) {
  // eslint-disable-next-line no-console
  console.error('Not setting REACT_APP_TALKJS_APP_ID to environment')
}

export const IS_DEV = process.env.NODE_ENV
  ? NodeEnv.DEV === process.env.NODE_ENV
  : Boolean(NodeEnv.DEV)

export const config = {
  BASE_URL: process.env.REACT_APP_BASE_URL || '/graphql/',
  NODE_ENV: process.env.NODE_ENV || NodeEnv.DEV,
  PAYPAL_CLIENT_ID:
    process.env.REACT_APP_PAYPAL_CLIENT_ID ||
    'ARi7-7yFKuW0VaN447pHi02hFKf_2DvDa3OYdd4qQnpPKEwlArcKk9FcDOAMBsJFLt8LV7LOcswx4ujM',
  TALKJS_APP_ID: process.env.REACT_APP_TALKJS_APP_ID || 'taWgU4Em'
}
