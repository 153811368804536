import React, { useEffect, useMemo } from 'react'
import { isMobile, isTablet } from 'react-device-detect'
import { useHistory } from 'react-router-dom'

import {
  Breadcrumbs,
  Button,
  Column,
  getListFromEdgesList,
  Loader
} from '../../components'
import { useApi } from '../../providers'
import { LatestNewsView } from '../../views'

import { useStyle } from './latest-news.styles'

export const LatestNewsPage = () => {
  const classes = useStyle()
  const history = useHistory()

  const { news } = useApi()
  const first = isTablet ? 16 : 12
  const { data, loading, refetch, fetchMore } = news.useLatestNewsList({
    first
  })

  const Data = useMemo(() => getListFromEdgesList(data?.latestNewsList), [data])

  const totalCount = data?.latestNewsList.totalCount || 0
  const count = data?.latestNewsList.edges.length || 0

  useEffect(() => {
    if (history.action === 'PUSH') {
      refetch({ first })
    }
  }, [])

  const handleOnNext = () => {
    if (!loading && data) {
      const newsEdges = data.latestNewsList.edges
      const nextEdge = newsEdges[newsEdges.length - 1]

      if (nextEdge && fetchMore) {
        fetchMore({
          variables: {
            first,
            after: nextEdge.cursor
          }
        })
      }
    }
  }

  const handleOnRefresh = () => {
    refetch({ first })
  }

  if (!data) {
    return <Loader />
  }

  return (
    <Column className={classes.column}>
      <Breadcrumbs
        className={classes.breadcrumbs}
        title="Latest News"
        titleTx="breadcrumbs.latest.news"
      />
      <LatestNewsView
        isWrap={true}
        totalCount={totalCount}
        count={count}
        data={Data}
        onLoadNext={handleOnNext}
        onRefresh={handleOnRefresh}
      />
      {totalCount > count && !isMobile && (
        <Button
          className={classes.button}
          color="yellow"
          preset="primary"
          textColor="white"
          textPreset="h5"
          text="View More"
          tx="vendor.button.text"
          onClick={handleOnNext}
        />
      )}
    </Column>
  )
}
