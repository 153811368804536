import React, { FC } from 'react'

import { getFormatedOrderDate } from '../../pages'
import { getPriceWithSymbol } from '../../views'

import { MenuSlider } from '../menu-slider'
import { OrderLine } from '../order-line'
import { getCurrencySymbol } from '../helpers'
import { ResponsiveMediaView } from '../responsive-media-view'

import { PurchaseSliderHeaderOpen } from './purchase-slider-header-open'
import { PurchaseSliderHeaderClose } from './purchase-slider-header-close'
import { PurchaseSliderHeaderMobileOpen } from './purchase-slider-header-mobile-open'
import { PurchaseSliderHeaderMobileClose } from './purchase-slider-header-mobile-close'
import { PurchaseSliderProps } from './purchase-slider.types'

import { OrderInfoRow } from './order-info-row'

export const PurchaseSlider: FC<PurchaseSliderProps> = ({
  className = '',
  order,
  onContactVendor
}) => {
  const {
    id,
    created,
    number,
    lines,
    offer,
    vendor,
    total,
    totalPrice,
    finishDate,
    startDate,
    mainStatus,
    deliveryPrice
  } = order

  const { currency } = total
  const currencySymbol = getCurrencySymbol(currency)

  const { companyName } = vendor
  const country = vendor.defaultBillingAddress?.country?.code

  const createdDate = getFormatedOrderDate(startDate)
  const finishedDate = getFormatedOrderDate(finishDate)

  const headerOpen = (
    <PurchaseSliderHeaderOpen
      id={id}
      number={number}
      status={mainStatus}
      lines={lines}
      totalPrice={getPriceWithSymbol(totalPrice, currencySymbol)}
      companyName={companyName}
      country={country}
      created={created}
      finishDate={finishDate}
      startDate={startDate}
      onContactVendor={onContactVendor}
    />
  )

  const headerClose = (
    <PurchaseSliderHeaderClose
      id={id}
      number={number}
      status={mainStatus}
      totalPrice={getPriceWithSymbol(totalPrice, currencySymbol)}
      lines={lines}
      companyName={companyName}
      country={country}
      created={created}
    />
  )

  const headerMobileOpen = (
    <PurchaseSliderHeaderMobileOpen
      id={id}
      number={number}
      status={mainStatus}
      lines={lines}
      totalPrice={getPriceWithSymbol(totalPrice, currencySymbol)}
      companyName={companyName}
      country={country}
      created={created}
      finishDate={finishDate}
      startDate={startDate}
    />
  )

  const headerMobileClose = (
    <PurchaseSliderHeaderMobileClose
      id={id}
      number={number}
      status={mainStatus}
      totalPrice={getPriceWithSymbol(totalPrice, currencySymbol)}
      lines={lines}
      companyName={companyName}
      country={country}
      created={created}
    />
  )

  const InfoRow = (
    <OrderInfoRow
      createdDate={createdDate}
      finishedDate={finishedDate}
      companyName={companyName}
      country={country}
    />
  )

  return (
    <MenuSlider
      className={className}
      header={
        <ResponsiveMediaView
          bigScreen={headerOpen}
          medium={headerOpen}
          tablet={headerOpen}
          mobile={headerMobileOpen}
        />
      }
      headerClose={
        <ResponsiveMediaView
          bigScreen={headerClose}
          medium={headerClose}
          tablet={headerClose}
          mobile={headerMobileClose}
        />
      }
    >
      <ResponsiveMediaView
        bigScreen={InfoRow}
        medium={InfoRow}
        tablet={InfoRow}
      />

      {offer &&
        lines.map((orderLine) => {
          if (!orderLine.product) {
            return null
          }

          return (
            <OrderLine
              {...orderLine}
              {...offer}
              currency={currencySymbol}
              key={`order_${id}_product_${orderLine.product.id}`}
              deliveryPrice={deliveryPrice}
              totalOrderPrice={Number(totalPrice)}
            />
          )
        })}
    </MenuSlider>
  )
}
