import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  breadcrumbs: {
    cursor: 'default'
  },
  icon: {
    marginLeft: 8
  }
})
