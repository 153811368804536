import React, { FC } from 'react'

import { getPriceToFixed } from '../../pages'

import { Row } from '../row'
import { Text } from '../text'
import { getCurrencySymbol } from '../helpers'

import { ProductPriceProps } from './product-price.types'
import { useStyle } from './product-price.styles'

export const ProductPrice: FC<ProductPriceProps> = ({
  mini,
  currency,
  totalPrice = 0,
  discountMaxPrice,
  isSale
}) => {
  const classes = useStyle()
  const currencySymbol = getCurrencySymbol(currency)
  const totalPriceFormated = `${currencySymbol}${getPriceToFixed(totalPrice)}`
  const salesPrice = `${currencySymbol}${getPriceToFixed(discountMaxPrice)}`
  const price = `${currencySymbol}${getPriceToFixed(totalPrice)}`

  const dashedPreset = mini ? 'h6' : 'h5'
  const mainPricePreset = mini ? 'h5' : 'h4'

  return (
    <>
      {isSale ? (
        <Row>
          <Text
            className={`${classes.price} ${classes.discount}`}
            color="inactive"
            preset={dashedPreset}
            text={totalPriceFormated}
          />
          <Text
            className={`${classes.price} ${classes.discountPrice}`}
            color="yellow"
            preset={mainPricePreset}
            text={salesPrice}
          />
        </Row>
      ) : (
        <Row>
          <Text
            text="from"
            tx="product.item.card.price.from"
            preset={mainPricePreset}
            color="inactive"
          />
          <Text
            className={classes.minPrice}
            text={price}
            preset={mainPricePreset}
            color="inactive"
          />
        </Row>
      )}
    </>
  )
}
