import React, { FC } from 'react'
import { isMobileOnly } from 'react-device-detect'

import { ObserveOfferButton } from '../../../views'

import { getCurrencyByProduct } from '../../helpers'
import { Column } from '../../column'
import { Text } from '../../text'
import { Row } from '../../row'

import { OfferListInfoRow } from '../offers-list-info-row'

import { useStyle } from './offers-list-item-variant-line.styles'
import { OfferListItemVariantLineProps } from './offers-list-item-variant-line.types'

export const OfferListItemVariantLine: FC<OfferListItemVariantLineProps> = ({
  count,
  item,
  offer,
  className = ''
}) => {
  const classes = useStyle()

  const { product, description, discount, noSuggestion } = offer

  const currency = getCurrencyByProduct(product)
  const itemQuantity = item ? item.quantity : 0
  const itemTotalPrice = item ? item.totalItemPrice : 0

  const Container = isMobileOnly ? Column : Row

  return (
    <Column fullWidth className={className}>
      <Container
        fullWidth
        className={classes.line}
        justifyContent="space-between"
      >
        <Row
          fullWidth
          justifyContent="space-between"
          className={classes.itemRow}
        >
          <Row className={classes.itemNumber}>
            <Text text={count} preset="h5" />
            <Text
              className={classes.itemTitle}
              color="black"
              preset="title"
              text="Item"
              tx="offer.line.item.variant"
            />
          </Row>
          <Row alignItems="flex-end" justifyContent="space-between">
            <ObserveOfferButton
              currency={currency}
              className={classes.observeOptionsBtn}
              description={description}
              productDefaultPrice={product.defaultPrice}
              options={item.options}
              offerOptions={item.productOptions}
              offerColors={item.productColors}
              offerMaterials={item.productMaterials}
              totalItemPrice={item.totalItemPrice}
              discount={discount}
              itemQuantity={item.quantity}
              noSuggestion={noSuggestion}
            />
          </Row>
        </Row>
        <Column
          fullWidth
          justifyContent="flex-start"
          className={classes.infoRow}
        >
          <OfferListInfoRow
            className={classes.column}
            title="Item Quantity:"
            titleTx="offer.line.units.quantity"
            price={itemQuantity}
          />
          <OfferListInfoRow
            className={classes.column}
            title="Offer Price:"
            titleTx="offer.line.offer.price"
            price={itemTotalPrice}
            currency={currency}
          />
        </Column>
      </Container>
    </Column>
  )
}
