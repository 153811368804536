import { Offer } from '../../services'

export enum AuthToken {
  TOKEN = 'token',
  REFRESH_TOKEN = 'refreshToken',
  CRFS_TOKEN = 'crfsToken',
  BASKET_TOKEN = 'basket'
}

export const setTokens = (
  token: string,
  refreshToken?: string,
  crfsToken?: string
) => {
  if (token) {
    localStorage.setItem(AuthToken.TOKEN, token)
  }

  if (refreshToken) {
    localStorage.setItem(AuthToken.REFRESH_TOKEN, refreshToken)
  }

  if (crfsToken) {
    localStorage.setItem(AuthToken.CRFS_TOKEN, crfsToken)
  }
}

export const setAuthorizationToken = (
  token: string,
  setHeader: (key: string, prop: string) => void
) => {
  setHeader('Authorization', `JWT ${token}`)
}

export const getAссeptedOffersIds = (offers: Offer[]) => {
  const acceptedOffersIds = offers.map((offer) => offer.id)
  return acceptedOffersIds
}
