import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'
import { TabletProductsHeaderStyle } from './tablet-products-header.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  results: {
    marginLeft: 4
  },
  count: {
    lineHeight: '18px'
  },
  sort: {
    marginLeft: 48,
    minWidth: 316
  },
  sortText: {
    minWidth: 64
  },
  sortDropdown: {
    width: 'calc(100% - 16px)',
    borderRadius: 4,
    scrollbarWidth: 'none',
    border: '1px solid black',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  filter: {
    position: 'relative',
    display: 'flex',
    backgroundColor: theme.colors.white,
    padding: '16px 0',
    width: '100%'
  },
  title: {
    marginLeft: 42
  },
  filterOverlay: {
    position: 'fixed',
    backgroundColor: theme.colors.white,
    height: '100%',
    minHeight: '100vh',
    maxWidth: 454,
    top: ({ scroll }: TabletProductsHeaderStyle) => (scroll ? 0 : 115),
    left: 0,
    zIndex: 2
  },
  filterHeader: {
    display: 'flex',
    alignItems: 'center',
    width: 430,
    padding: '24px 0 24px 24px',
    height: 57
  },
  filterSvgContainer: {
    display: 'flex',
    margin: '0 12px'
  },

  icon: {
    cursor: 'pointer',
    '&:hover > path': {
      fill: theme.colors.yellow
    }
  },
  infoBottom: {
    width: 424,
    padding: '24px 16px',
    background: theme.colors.white,
    boxShadow: '4px 8px 24px rgba(0, 0, 0, 0.16)',
    position: 'fixed',
    bottom: 0,
    zIndex: 24
  }
}))
