import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  policy: {
    cursor: 'pointer',
    textDecoration: 'underline',
    textDecorationColor: `${theme.colors.blue}`
  }
}))
