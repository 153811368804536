import React, { ChangeEvent, useEffect, useState } from 'react'

import {
  Button,
  CabinetPageContainer,
  Column,
  ICONS,
  RadioItem,
  ResponsiveMediaView,
  Row,
  Text,
  TextField,
  TextFieldErrorProps
} from '../../components'
import { DeleteAccountModal } from '../../views'
import { AccountInput, GetTokenProfileSettingsRequest } from '../../services'
import { useAuth, useToastify } from '../../providers'

import {
  ValidatorFirstName,
  ValidatorLastName,
  ValidationNumberPhone,
  ValidatorPassword
} from './validation'
import { Sex } from './profile-settings.types'
import { useStyle } from './profile-settings.styles'

const defaultUserState: GetTokenProfileSettingsRequest = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  currentNewPassword: '',
  currentEmail: '',
  newEmail: '',
  currentPassword: '',
  newPassword: '',
  sex: Sex.MALE
}

export const ProfileSettingsPage = () => {
  const classes = useStyle()
  const {
    onChangeError,
    onAccountProfileUpdate,
    error,
    onChangePassword,
    user: userInfo
  } = useAuth()
  const defaultUser = {
    ...defaultUserState,
    currentEmail: userInfo?.email,
    firstName: userInfo?.firstName,
    lastName: userInfo?.lastName,
    phoneNumber: userInfo?.phone,
    email: userInfo?.email,
    sex: userInfo?.sex
  }
  const [user, changeUser] =
    useState<GetTokenProfileSettingsRequest>(defaultUser)
  const [openDelete, changeOpenDelete] = useState(false)

  const { open } = useToastify()

  useEffect(() => {
    if (error) {
      open({
        ...error,
        type: 'ERROR'
      })
    }
  }, [error])

  const handleOnClick = (state: boolean) => () => {
    if (state) {
      open({
        text: 'Action successful',
        tx: 'profile.settings.toastify.text'
      })
    }
  }

  const handleOnFocus = () => {
    if (onChangeError) {
      onChangeError()
    }
  }

  const handleOnOpenDeleteAccount = (state: boolean) => () => {
    changeOpenDelete(state)
  }

  const handleOnDeleteAccount = () => {
    const closeModalDelete = handleOnOpenDeleteAccount(false)

    closeModalDelete()
  }
  const handleOnToShareClick = () => {
    const openModal = handleOnOpenDeleteAccount(true)

    openModal()
  }

  const handleOnChange =
    (prop: keyof GetTokenProfileSettingsRequest) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target

      changeUser({
        ...user,
        [prop]: value,
        error: undefined
      })
      if (onChangeError && user.currentPassword !== value) {
        onChangeError()
      }
    }

  const handleOnChangeSex = (value: Sex) => () => {
    changeUser({
      ...user,
      sex: value,
      error: undefined
    })
  }

  const isDisabled =
    !user.firstName ||
    !ValidatorFirstName(user.firstName) ||
    !user.lastName ||
    !ValidatorLastName(user.lastName) ||
    !user.phoneNumber ||
    !ValidationNumberPhone(user.phoneNumber)

  // TODO: uncomment if change email possibility is implemented
  // const isDisabledSecond =
  //   !user.currentEmail || !user.newEmail || !ValidationEmail()

  const matchPassword = () => {
    if (user.newPassword === user.currentNewPassword) {
      return true
    }
    return undefined
  }
  const isDisabledThurd =
    !user.currentPassword ||
    !user.newPassword ||
    !ValidatorPassword(user.newPassword) ||
    !user.currentNewPassword ||
    !matchPassword() ||
    !ValidatorPassword(user.currentNewPassword)

  const phoneError: TextFieldErrorProps =
    user.phoneNumber && !ValidationNumberPhone(user.phoneNumber)
      ? {
          error: 'Please enter a valid phone number.',
          errorTx: 'error.phone.number'
        }
      : {}
  // TODO: uncomment if change email possibility is implemented
  // const emailError: TextFieldErrorProps =
  //   !ValidationEmail() && user.newEmail
  //     ? {
  //         error: 'Please enter a valid e-mail address.',
  //         errorTx: 'error.email'
  //       }
  //     : {}
  const currentPasswordError: TextFieldErrorProps =
    user.currentPassword && !ValidatorPassword(user.currentPassword)
      ? {
          error:
            'Password must contain from 5 to 30 characters, upper and lower case letters.',
          errorTx: 'profile.settings.password.error'
        }
      : {}
  const newPasswordError: TextFieldErrorProps =
    user.newPassword && !ValidatorPassword(user.newPassword)
      ? {
          error:
            'Password must contain from 5 to 30 characters, upper and lower case letters.',
          errorTx: 'profile.settings.password.error'
        }
      : {}

  const currentNewPasswordError: TextFieldErrorProps =
    user.currentNewPassword && !ValidatorPassword(user.currentNewPassword)
      ? {
          error:
            'Password must contain from 5 to 30 characters, upper and lower case letters.',
          errorTx: 'profile.settings.password.error'
        }
      : {}

  const handleOnSubmit = () => {
    const {
      firstName,
      lastName,
      phoneNumber,
      sex,
      currentPassword,
      newPassword
    } = user
    const openToastify = handleOnClick(true)
    const changeGeneralInfo = firstName && lastName && phoneNumber && sex
    const changePassword = currentPassword && newPassword

    if (changeGeneralInfo) {
      const nextInput = {
        firstName,
        lastName,
        phone: phoneNumber,
        sex
      } as AccountInput

      if (!error && onAccountProfileUpdate) {
        onAccountProfileUpdate(nextInput)
        openToastify()
      }
    }
    if (!error && changePassword && onChangePassword) {
      onChangePassword({
        newPassword,
        oldPassword: currentPassword
      })
      openToastify()
    }
  }

  const PhoneInput = (
    <TextField
      withError
      {...phoneError}
      className={classes.input}
      label="Phone Number"
      labelTx="profile.settings.number.phone.user"
      preset="border"
      type="text"
      maxLength={30}
      onChange={handleOnChange('phoneNumber')}
      defaultValue={user.phoneNumber}
    />
  )

  return (
    <Column
      fullHeight
      fullWidth
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <CabinetPageContainer
        className={classes.containerPage}
        classNameContent={classes.containerContent}
        label="General Info"
        labelTx="profile.settings.general.title.text"
      >
        <Row
          className={classes.row}
          alignItems="flex-start"
          justifyContent="space-between"
          wrap
        >
          <TextField
            className={classes.input}
            label="First Name"
            labelTx="profile.settings.firstName.text"
            preset="border"
            type="text"
            maxLength={30}
            onChange={handleOnChange('firstName')}
            value={user.firstName}
          />
          <ResponsiveMediaView
            bigScreen={PhoneInput}
            medium={PhoneInput}
            tablet={PhoneInput}
          />

          <TextField
            className={classes.input}
            label="Last Name"
            labelTx="profile.settings.last.text"
            preset="border"
            type="text"
            maxLength={30}
            onChange={handleOnChange('lastName')}
            value={user.lastName}
          />
          <ResponsiveMediaView mobile={PhoneInput} />
          <Row className={classes.radio} justifyContent="flex-start">
            <RadioItem
              active={Sex.FEMALE === user.sex}
              src={ICONS.female}
              name="sex"
              text="Female"
              tx="register.female"
              onClick={handleOnChangeSex(Sex.FEMALE)}
            />
            <RadioItem
              active={Sex.MALE === user.sex}
              className={classes.item}
              src={ICONS.male}
              name="sex"
              text="Male"
              tx="register.male"
              onClick={handleOnChangeSex(Sex.MALE)}
            />
          </Row>
          <Row fullWidth justifyContent="flex-end">
            <Button
              className={classes.button}
              disabled={isDisabled}
              text="SAVE CHANGES"
              tx="profile.settings.button.text"
              textColor="white"
              textPreset="h5"
              preset="primarySecond"
              onClick={handleOnSubmit}
            />
          </Row>
        </Row>
      </CabinetPageContainer>
      <CabinetPageContainer
        className={classes.containerPage}
        classNameContent={classes.containerContent}
        label="E-mail address"
        labelTx="profile.settings.email.address"
      >
        <Row wrap className={classes.row} justifyContent="space-between">
          <TextField
            className={classes.input}
            inputClassName={classes.inputText}
            disabled={true}
            label="Current E-mail"
            labelTx="profile.settings.current.email.address"
            preset="border"
            type="text"
            maxLength={30}
            //  TODO: uncomment if change email possibility is implemented
            // onChange={handleOnChange('currentEmail')}
            value={user.currentEmail}
          />
          {/*
          TODO: possible further implementation of change email function
          <TextField
            withError
            {...emailError}
            className={classes.input}
            label="New E-mail"
            labelTx="profile.settings.new.email.text"
            preset="border"
            type="text"
            defaultValue={user.newEmail}
            maxLength={30}
            onChange={handleOnChange('newEmail')}
          />
          <Row fullWidth justifyContent="flex-end">
            <Button
              className={classes.button}
              disabled={isDisabledSecond}
              text="SAVE CHANGES"
              tx="profile.settings.button.text"
              textColor="white"
              textPreset="h5"
              preset="primary"
              onClick={handleOnOpenWrong(true)}
            />
          </Row> */}
        </Row>
      </CabinetPageContainer>
      <CabinetPageContainer
        className={classes.containerPage}
        classNameContent={classes.containerContent}
        label="Change your Password"
        labelTx="profile.settings.text.change.password"
      >
        <Row
          wrap
          className={classes.row}
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <TextField
            isSecure
            withError
            {...currentPasswordError}
            className={classes.input}
            label="Current Password"
            labelTx="profile.settings.current.password"
            preset="border"
            type="text"
            maxLength={30}
            onChange={handleOnChange('currentPassword')}
            value={user.currentPassword}
            onFocus={handleOnFocus}
          />

          <TextField
            isSecure
            withError
            {...currentNewPasswordError}
            className={classes.input}
            label="Confirm New Password"
            labelTx="profile.settings.confirm.new.password"
            preset="border"
            type="text"
            defaultValue={user.currentNewPassword}
            maxLength={30}
            onChange={handleOnChange('currentNewPassword')}
          />
          <TextField
            isSecure
            withError
            {...newPasswordError}
            className={classes.input}
            label="New Password"
            labelTx="profile.settings.new.password"
            preset="border"
            type="text"
            defaultValue={user.newPassword}
            maxLength={30}
            onChange={handleOnChange('newPassword')}
          />
          <Button
            className={classes.button}
            disabled={isDisabledThurd}
            text="SAVE CHANGES"
            tx="profile.settings.button.text"
            textColor="white"
            textPreset="h5"
            preset="primarySecond"
            onClick={handleOnSubmit}
          />
        </Row>
      </CabinetPageContainer>
      <Column>
        <Text
          className={classes.link}
          color="blue"
          preset="h5"
          text="Delete Account?"
          tx="profile.settings.link.text"
          onClick={handleOnToShareClick}
        />
        <DeleteAccountModal
          open={openDelete}
          onClose={handleOnOpenDeleteAccount(false)}
          onSubmit={handleOnDeleteAccount}
        />
      </Column>
    </Column>
  )
}
