import React, { Suspense } from 'react'
import { Route } from 'react-router-dom'

import { Loader } from '../loader'

import { LazyRouteProps } from './lazy-route.types'

export const LazyRoute = <T extends {} = {}, Path extends string = string>(
  props: LazyRouteProps<T, Path>
) => {
  const fallback = <Loader />
  return (
    <Suspense fallback={fallback}>
      <Route {...props} />
    </Suspense>
  )
}
