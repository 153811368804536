import React, { FC } from 'react'

import { Column } from '../column'

import { LoaderProps } from './loader.types'
import { useStyle } from './loader.styles'
import { concatClassNames } from '../../utils'

export const Loader: FC<LoaderProps> = ({ className = '', height, width }) => {
  const classes = useStyle({ height, width })
  return (
    <Column className={concatClassNames(classes.loader, className)}>
      <Column className={classes.point} />
    </Column>
  )
}
