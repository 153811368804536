import React, { FC } from 'react'

import { Row } from '../../row'
import { Text } from '../../text'

import { FilterStarRowProps } from './filter-star-row.types'

import { useStyle } from './filter-star-row.styles'
import { Icon, ICONS } from '../../icon'

export const FilterStarRow: FC<FilterStarRowProps> = ({
  className = '',
  name,
  onClick
}) => {
  const classes = useStyle()

  const reviewStars = [
    {
      rating: 4,
      stars: [true, true, true, true, false]
    },
    {
      rating: 3,
      stars: [true, true, true, false, false]
    },
    {
      rating: 2,
      stars: [true, true, false, false, false]
    },
    {
      rating: 1,
      stars: [true, false, false, false, false]
    }
  ]

  const rowToRender = reviewStars.find(
    (reviewRow) => reviewRow.rating === Number(name)
  )

  return (
    <Row className={`${className} ${classes.ratingRow}`} onClick={onClick}>
      {rowToRender &&
        rowToRender.stars.map((isFilled, i) => {
          const icon = isFilled ? ICONS.starFilled : ICONS.starBorder

          return (
            <Icon
              key={`icon_${i}`}
              src={icon}
              className={classes.reviewStar}
              height={24}
              width={24}
            />
          )
        })}
      <Text color="inactive" preset="h5" text="& Up" />
    </Row>
  )
}
