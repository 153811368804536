import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  content: {
    padding: '24px 0'
  },
  title: {},
  image: {
    marginTop: 24,
    width: '100%'
  },
  text: {
    marginTop: 24
  },
  secondaryText: {
    marginTop: 16
  },
  button: {
    cursor: 'pointer',
    marginTop: 32,
    width: 312,
    padding: 16
  }
})
