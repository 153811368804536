import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { useTheme } from 'react-jss'
import { isMobileOnly } from 'react-device-detect'

import { LightTheme } from '../../theme'

import { Row } from '../row'
import { Icon, ICONS } from '../icon'
import { Text } from '../text'

import { BreadcrumbsProps } from './breadcrumbs.types'
import { useStyle } from './breadcrumbs.styles'

export const Breadcrumbs: FC<BreadcrumbsProps> = ({
  active,
  title,
  titleTx,
  subTitle,
  onNavigate,
  className = ''
}) => {
  const classes = useStyle({ active })
  const theme = useTheme<LightTheme>()
  const history = useHistory()
  const textPreset = isMobileOnly ? 'h7' : 'h6'
  const textColor = subTitle ? 'inactive' : 'black'
  const isSubtitleClassName = subTitle ? classes.breadcrumbs : ''

  const handleOnNavigateHome = () => {
    history.push('/')
  }

  return (
    <Row fullWidth className={className} justifyContent="flex-start">
      <>
        <Text
          className={classes.breadcrumbs}
          color="inactive"
          preset={textPreset}
          text="Home"
          tx="breadcrumbs.home"
          onClick={handleOnNavigateHome}
        />
        <Row className={classes.icon}>
          <Icon fill={theme.colors.inactive} src={ICONS.arrowBreadcrumbs} />
        </Row>
      </>

      <Text
        className={isSubtitleClassName}
        color={textColor}
        preset={textPreset}
        text={title}
        tx={titleTx}
        onClick={onNavigate}
      />
      {subTitle && (
        <>
          <Row className={classes.icon}>
            <Icon fill={theme.colors.inactive} src={ICONS.arrowBreadcrumbs} />
          </Row>
          <Text color="black" preset={textPreset} text={subTitle} />
        </>
      )}
    </Row>
  )
}
