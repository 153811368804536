import React, { useMemo } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useApi } from '../../providers'
import { Loader, ProductList } from '../../components'
import { reduceProductEdgesToProductList } from '../helpers'

import { useStyle } from './recommendations.styles'

export const Recommendations = () => {
  const { product } = useApi()
  const { data } = product.useRecommendationProducts({ first: 10 })

  const classes = useStyle({ mobile: isMobileOnly })
  const h2Preset = isMobileOnly ? 'h2Mobile' : 'h2'

  const Products = useMemo(() => {
    if (data) {
      return reduceProductEdgesToProductList(data.recommendations.edges)
    }

    return []
  }, [data])

  if (!data) {
    return <Loader />
  }

  return (
    <ProductList
      carousel
      mini={isMobileOnly}
      className={classes.productList}
      titlePreset={h2Preset}
      title="Recommendations for You"
      titleTx="recommendation.title"
      data={Products}
    />
  )
}
