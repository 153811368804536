import React, { FC } from 'react'
import { useTheme } from 'react-jss'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { Icon } from '../icon'
import { Row } from '../row'
import { Text } from '../text'

import { Color, LightTheme } from '../../theme'
import { NavLinkIconRowProps } from './nav-link-icon-row.types'
import { useStyle } from './nav-link-icon-row.styles'

export const NavLinkIconRow: FC<NavLinkIconRowProps> = ({
  className,
  icon,
  text,
  to,
  tx
}) => {
  const { path } = useRouteMatch()
  const history = useHistory()

  const theme = useTheme<LightTheme>()
  const active = path === to
  const activeColor: Color = active ? 'black' : 'inactive'
  const activeFill = theme.colors[activeColor]
  const classes = useStyle()

  const handleOnNavigate = () => {
    history.push(to)
  }

  return (
    <Row
      fullWidth
      className={`${className} ${classes.container}`}
      justifyContent="flex-start"
      onClick={handleOnNavigate}
    >
      <Icon
        fill={activeFill}
        height={24}
        src={icon}
        width={24}
        className={classes.mobile}
      />
      <Text
        className={classes.title}
        color={activeColor}
        preset="h7"
        text={text}
        tx={tx}
      />
    </Row>
  )
}
