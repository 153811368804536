import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    width: '100%',
    marginTop: 40,
    border: `1px solid ${theme.colors.shadow}`,
    borderRadius: 4
  },
  infoContainer: {
    width: 'calc(100% - 48px)',
    padding: '16px 24px',
    borderBottom: `1px solid ${theme.colors.shadow}`
  },
  checkbox: {
    width: 48
  },
  header: {
    width: 'calc(100% - 96px)',
    padding: '12px 24px 12px 72px'
  },
  firstItem: {
    flex: 7
  },
  item: {
    flex: 2
  },
  total: {
    flex: 1
  },
  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    firstItem: {
      flex: 5
    }
  },
  '@media screen and (max-width: 480px)': {
    infoContainer: {
      width: 'calc(100% - 40px)',
      padding: '16px 20px',
      borderBottom: `1px solid ${theme.colors.shadow}`
    }
  }
}))
