import React, { FC } from 'react'

import { getPriceWithSymbol } from '../../../views'

import { Column } from '../../column'
import { Text } from '../../text'

import { OfferListInfoColumnProps } from './offers-list-info-column.types'

export const OfferListInfoColumn: FC<OfferListInfoColumnProps> = ({
  className,
  title,
  currency,
  titleTx,
  price
}) => {
  const text =
    currency && price ? getPriceWithSymbol(price, currency) : String(price)

  return (
    <Column alignItems="flex-start" className={className}>
      <Text color="inactive" preset="body" text={title} tx={titleTx} />
      <Text color="black" preset="h7" text={text} />
    </Column>
  )
}
