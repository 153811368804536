import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

import { TextAreaStyleProps } from './text-area.types'

export const presetsContainer = (theme: LightTheme) => ({
  border: {
    '&:focus-within': {
      border: `2px solid ${theme.colors.yellow}`,
      padding: '3px 22px',
      width: 'calc(100% - 48px)'
    },
    border: `1px solid ${theme.colors.inactive}`,
    borderRadius: 4,
    cursor: 'text',
    padding: '4px 23px',
    width: 'calc(100% - 48px)'
  },
  'bottom-border': {
    '&:focus-within': {
      borderBottom: `2px solid ${theme.colors.yellow}`,
      padding: '0px 4px',
      width: 'calc(100% - 8px)'
    },
    borderBottom: `2px solid ${theme.colors.black}`,
    cursor: 'text',
    padding: '0px 4px',
    width: 'calc(100% - 8px)'
  },
  main: {
    borderRadius: 4,
    cursor: 'text',
    padding: '4px 16px',
    width: 'calc(100% - 32px)'
  }
})

const presetsInput = {
  border: {},
  'bottom-border': {},
  main: {}
}

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: ({ preset = 'main' }: TextAreaStyleProps) => ({
    background: theme.colors.white,
    ...presetsContainer(theme)[preset]
  }),
  icon: {
    cursor: 'pointer',
    marginLeft: 8
  },
  input: ({
    textAreaInputPreset = 'body',
    preset = 'main',
    password
  }: TextAreaStyleProps) => ({
    ...theme.typography[textAreaInputPreset],
    ...presetsInput[preset],
    '&:-internal-autofill-selected': {
      '-webkit-box-shadow': 'none',
      backgroundColor: 'none'
    },
    '&::placeholder': {
      color: theme.colors.inactive
    },
    '&:focus': {
      outline: 'none'
    },
    background: theme.colors.transparent,
    border: 'none',
    color: theme.colors.black,
    fontFamily: 'inherit',
    letterSpacing: password ? '8px' : 'normal',
    marginTop: 4,
    minHeight: 125,
    padding: 0,
    width: '100%',
    resize: 'none'
  }),
  label: {
    transform: ({ focus, value }: TextAreaStyleProps) =>
      !focus && !value && 'translate(0px, 12px) scale(1.25)',
    transformOrigin: ({ focus, value }: TextAreaStyleProps) =>
      !focus && !value && '0%',
    transition:
      'transform-origin 0.25s ease-in-out, transform 0.25s ease-in-out'
  }
}))
