import { createUseStyles } from 'react-jss'
import { isMobileOnly } from 'react-device-detect'

export const useStyle = createUseStyles({
  text: {
    marginLeft: 8
  },
  field: {
    marginLeft: 40
  },
  button: {
    width: isMobileOnly && 128,
    padding: isMobileOnly && '12px 10px',
    marginLeft: !isMobileOnly && 24
  }
})
