import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    border: `1px solid ${theme.colors.shadow}`,
    borderRadius: 4
  },
  listContainer: {
    width: 'calc(100% - 32px)',
    padding: '0 16px'
  },
  '@media screen and (max-width: 480px)': {
    listContainer: {
      padding: 16
    }
  }
}))
