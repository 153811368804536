import React, { FC } from 'react'

import { Column, Row, Text, Icon, ICONS } from '../../../components'

import { useStyle } from './basic-info-mobile.styles'
import { BasicInfoMobileProps } from './basic-info-mobile.types'

export const BasicInfoMobile: FC<BasicInfoMobileProps> = ({ data }) => {
  const classes = useStyle()

  const userGender = data?.sex === 1
  const totalCountFeedbacks = `${data?.feedbacks.length || 0}`
  const valueString = `${data?.wishlist.totalCount || 0}`

  return (
    <Column fullWidth justifyContent="flex-start" alignItems="flex-start">
      <Row className={classes.avatarAndName}>
        {userGender ? (
          <Icon
            className={classes.sex}
            height="80"
            src={ICONS.male}
            width="80"
          />
        ) : (
          <Icon
            className={classes.sex}
            height="80"
            src={ICONS.female}
            width="80"
          />
        )}
        <Column alignItems="flex-start">
          <Text preset="h3Mobile" text={`${data.firstName} ${data.lastName}`} />
          {data.defaultShippingAddress && (
            <Text color="inactive" text={data.defaultShippingAddress.city} />
          )}
        </Column>
      </Row>
      <Row
        fullWidth
        className={classes.indicators}
        justifyContent="space-evenly"
      >
        <Column className={classes.indicator}>
          <Text color="yellow" preset="h3" text={valueString} />
          <Text
            color="inactive"
            text="Favorites"
            tx="cabinet.route.userInfo.favorites.title"
          />
        </Column>
        <Column className={classes.indicator}>
          <Text color="yellow" preset="h3" text={totalCountFeedbacks} />
          <Text
            color="inactive"
            text="Reviews"
            tx="cabinet.route.userInfo.reviews.title"
          />
        </Column>
      </Row>
    </Column>
  )
}
