import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  infoNum: {},
  infoItem: {
    marginLeft: 88
  },
  infoLastItem: {
    flex: 2
  },
  value: {
    marginLeft: 8
  },
  '@media screen and (max-width: 480px)': {
    infoNum: {
      width: '100%'
    },
    infoItem: {
      marginLeft: 0,
      width: '100%',
      justifyContent: 'space-between',
      flexWrap: 'wrap'
    }
  }
})
