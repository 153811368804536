import React, { FC } from 'react'
import { isMobileOnly } from 'react-device-detect'

import { Row, Text, Column, Button } from '../../../components'
import { getPriceWithSymbol } from '../../../views'

import { useStyle } from './shopping-cart-checkout.styles'
import { ShoppingCartCheckoutProps } from './shopping-cart-ckeckout.types'

export const ShoppingCartCheckout: FC<ShoppingCartCheckoutProps> = ({
  className = '',
  currency,
  totalOfferPrice,
  totalBasketPrice,
  totalDeliveryBasketPrice,
  onCheckout
}) => {
  const classes = useStyle()

  const titlePreset = isMobileOnly ? 'h1Mobile' : 'h1'
  const h3Preset = isMobileOnly ? 'h3Mobile' : 'h3'

  return (
    <Row fullWidth justifyContent="flex-end" className={className}>
      <Column fullWidth className={classes.totalPrice}>
        <Row fullWidth justifyContent="flex-start" className={classes.title}>
          <Text
            text="Summary"
            tx="shopping.cart.item.summary"
            color="black"
            preset={h3Preset}
          />
        </Row>
        <Column className={classes.title}>
          <Row fullWidth justifyContent="space-between">
            <Text
              color="inactive"
              preset="body"
              text="Offers Price:"
              tx="shopping.cart.item.offers.price"
            />
            <Text
              color="black"
              preset="h4"
              text={getPriceWithSymbol(totalOfferPrice, currency)}
            />
          </Row>
          <Row
            fullWidth
            justifyContent="space-between"
            className={classes.deliveryPrice}
          >
            <Text
              color="inactive"
              preset="body"
              text="Delivery Price:"
              tx="shopping.cart.item.delivery.price"
            />
            <Text
              color="black"
              preset="h4"
              text={getPriceWithSymbol(totalDeliveryBasketPrice, currency)}
            />
          </Row>
        </Column>
        <Row fullWidth justifyContent="space-between" className={classes.title}>
          <Text
            color="black"
            preset={h3Preset}
            text="Total:"
            tx="shopping.cart.total"
          />
          <Text
            color="black"
            preset={titlePreset}
            text={getPriceWithSymbol(totalBasketPrice, currency)}
          />
        </Row>
        <Row fullWidth className={classes.button}>
          <Button
            preset="primary"
            textColor="white"
            textPreset="h5"
            text="PROCEED TO CHECKOUT"
            tx="shopping.cart.checkout"
            onClick={onCheckout}
          />
        </Row>
      </Column>
    </Row>
  )
}
