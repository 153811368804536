import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    border: `1px solid ${theme.colors.shadow}`,
    borderRadius: 4,
    width: 1204,
    maxWidth: 'calc(100% - 48px)',
    marginTop: 24
  },
  info: {
    backgroundColor: theme.colors.blue,
    width: 'calc(100% - 32px)',
    borderRadius: '4px 4px 0 0',
    padding: 16
  },

  header: {
    width: 'calc(100% - 32px)',
    padding: '12px 16px'
  },
  headerTitle: {
    flex: 2
  },
  headerLastTitle: {
    flex: 2
  },
  item: {
    flex: 6
  },
  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    container: {
      width: '100%',
      maxWidth: '100%'
    }
  },
  '@media screen and (max-width: 480px)': {
    container: {
      width: '100%',
      maxWidth: '100%'
    }
  }
}))
