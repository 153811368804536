import React, { FC } from 'react'

import { getPriceWithSymbol } from '../../views'

import { Column } from '../column'
import { Row } from '../row'
import { Text } from '../text'
import { OrderPreviewOfferItemMobile } from '../order-preview-offer-item'
import { getCurrencyByProduct } from '../helpers'

import { useStyle } from './order-preview-offer-table.styles'
import { OrderPreviewOfferTableProps } from './order-preview-offer-table.types'

export const OrderPreviewOfferTableMobile: FC<OrderPreviewOfferTableProps> = ({
  id,
  number,
  offerQuantity,
  companyName,
  productTotalPrice,
  totalPrice,
  material,
  product,
  options,
  colorName,
  colorCode,
  offerOptions,
  onRemoveOffer
}) => {
  const classes = useStyle()

  const currency = product && getCurrencyByProduct(product)

  return (
    <Column className={classes.container}>
      <Column className={classes.infoContainer}>
        <Row className={classes.itemOrderNum} justifyContent="flex-start">
          <Row fullWidth wrap justifyContent="flex-start">
            <Text text="Order" tx="order.preview.order" preset="h3Mobile" />
            <Text
              className={classes.orderNum}
              text={`#${String(number)}`}
              preset="h3Mobile"
            />
          </Row>
        </Row>

        <Row
          fullWidth
          className={classes.itemOrderPrice}
          justifyContent="space-between"
        >
          <Text
            text="Order Price:"
            tx="order.preview.order.price"
            preset="body"
            color="inactive"
          />
          <Text
            className={classes.textMarginLeft}
            text={getPriceWithSymbol(totalPrice, currency)}
            preset="h4"
          />
        </Row>
        <Row
          fullWidth
          className={classes.itemVendor}
          alignItems="center"
          justifyContent="space-between"
        >
          <Text
            text="Company:"
            tx="order.preview.vendor"
            preset="body"
            color="inactive"
          />
          <Text
            className={classes.textMarginLeft}
            text={companyName}
            preset="h4"
          />
        </Row>
      </Column>

      {product && (
        <OrderPreviewOfferItemMobile
          id={id}
          colorName={colorName}
          colorCode={colorCode}
          name={product.name}
          currency={currency}
          productTotalPrice={productTotalPrice}
          material={material}
          options={options}
          offerOptions={offerOptions}
          defaultQuantity={offerQuantity}
          image={product.thumbnail2x?.url}
          offerQuantity={offerQuantity}
          totalPrice={totalPrice}
          onRemoveOffer={onRemoveOffer}
        />
      )}
    </Column>
  )
}
