import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  totalPrice: {
    backgroundColor: theme.colors.vendorProfileFon,
    marginLeft: 24,
    border: `1px solid ${theme.colors.shadow}`,
    borderRadius: 4
  },
  title: {
    width: 'calc(100% - 32px)',
    padding: 16,
    borderBottom: `1px solid ${theme.colors.shadow}`
  },
  deliveryPrice: {
    marginTop: 24
  },
  button: {
    width: 'calc(100% - 32px)',
    padding: '24px 16px'
  },
  '@media screen and (max-width: 480px) ': {
    totalPrice: {
      flex: 1,
      marginTop: 0,
      marginLeft: 0
    }
  }
}))
