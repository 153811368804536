import React, { FC, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Column, Row, Text, Modal } from '../../components'
import { useStyle } from './choose-order-modal.styles'
import { ChooseOrderProps } from './choose-order-modal.types'

export const ChooseOrderModal: FC<ChooseOrderProps> = ({
  open,
  onClose,
  orders
}) => {
  const history = useHistory()
  const classes = useStyle()
  const [selectedId, changeSelectedId] = useState('')

  if (!open) {
    return <></>
  }

  const handleSelectOrder = (item: any) => () => {
    changeSelectedId(item.id)
    if (selectedId) {
      history.push(`/select-items/${selectedId}`)
    }
  }

  return (
    <Modal
      className={classes.modal}
      title="Choose Order"
      titleTx="returns.exchange.page.order.title"
      onClose={onClose}
    >
      {orders &&
        orders.map((item) => (
          <Column
            key={item.id}
            fullWidth
            className={classes.container}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Column className={classes.order} onClick={handleSelectOrder(item)}>
              <Row fullWidth justifyContent="flex-start">
                <Text text={`№ ${item.number}`} preset="h5" />
              </Row>

              <Row
                fullWidth
                justifyContent="flex-start"
                className={classes.imgRow}
              >
                <Row className={classes.imgContainer}>
                  {item.lines
                    .filter((line) => line.product !== null)
                    .map((line) => (
                      <img
                        key={line.product.id}
                        className={classes.image}
                        src={line.product.thumbnail.url}
                        alt={line.product.thumbnail.alt}
                        width={48}
                        height={48}
                      />
                    ))}
                </Row>
              </Row>
            </Column>
          </Column>
        ))}
    </Modal>
  )
}
