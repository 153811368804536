import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  sex: {
    marginTop: 40
  },
  indicators: {
    width: '60%'
  },
  indicator: {
    flex: 1
  }
})
