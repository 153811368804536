import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  dropdown: {
    top: 0,
    width: 'calc(100% - 16px)',
    borderRadius: 4,
    scrollbarWidth: 'none',
    border: '1px solid rgba(102, 102, 102, 0.87)',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  sliderContainer: {
    marginLeft: 20
  },
  slider: {
    marginLeft: 8
  },
  search: {
    flex: 1.5
  },
  input: {
    flex: 1,
    '&:not(:first-child)': {
      marginLeft: 20
    }
  }
})
