import React, {
  ChangeEvent,
  FC,
  FocusEvent,
  useEffect,
  useRef,
  useState
} from 'react'
import { Color } from '../../theme'

import { Column } from '../column'
import { Icon, ICONS } from '../icon'
import { Row } from '../row'
import { Text } from '../text'
import { useStyle } from './text-area.styles'
import { TextAreaProps } from './text-area.types'

export const TextArea: FC<TextAreaProps> = ({
  preset = 'main',
  className = '',
  inputClassName = '',
  isSecure,
  label,
  labelTx,
  defaultValue,
  value: outerValue,
  textAreaInputPreset,
  onChange,
  onFocus,
  onBlur,
  ...props
}) => {
  const inputRef = useRef<HTMLTextAreaElement>(null)
  const [value, changeValue] = useState<string | number | readonly string[]>(
    defaultValue || ''
  )
  const [visible, changeVisible] = useState(!isSecure)
  const [focus, changeFocus] = useState(Boolean(defaultValue))
  const classes = useStyle({
    focus,
    password: isSecure && !visible,
    preset,
    value
  })

  useEffect(() => {
    if (typeof outerValue !== 'undefined' && value !== outerValue) {
      changeValue(outerValue)
    }
  }, [value, outerValue])

  const activeLabelColor: Color = !focus && !value ? 'inactive' : 'yellow'
  const secureIcon = visible ? ICONS.removeRedEye : ICONS.visibilityOff

  const handleOnVisible = () => {
    changeVisible(!visible)
  }

  const handleOnChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value: nextValue } = event.target
    changeValue(nextValue)

    if (nextValue) {
      changeFocus(true)
    }

    if (onChange) {
      onChange(event)
    }
  }

  const handleOnFocus = (event: FocusEvent<HTMLTextAreaElement>) => {
    changeFocus(true)

    if (onFocus) {
      onFocus(event)
    }
  }

  const handleOnBlur = (event: FocusEvent<HTMLTextAreaElement>) => {
    if (!value) {
      changeFocus(false)
    }

    if (onBlur) {
      onBlur(event)
    }
  }

  const handleOnClickContainer = () => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  return (
    <Row
      className={`${className} ${classes.container}`}
      justifyContent="space-between"
      onClick={handleOnClickContainer}
    >
      <Column fullWidth alignItems="flex-start" justifyContent="flex-start">
        <Text
          className={classes.label}
          color={activeLabelColor}
          preset="subscriptionText"
          text={label}
          tx={labelTx}
        />
        <textarea
          {...props}
          value={value}
          ref={inputRef}
          className={`${inputClassName} ${classes.input}`}
          onChange={handleOnChange}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
        />
      </Column>
      {isSecure && value && (
        <Icon
          className={classes.icon}
          color="black"
          src={secureIcon}
          onClick={handleOnVisible}
        />
      )}
    </Row>
  )
}
