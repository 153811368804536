import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  item: {
    width: '100%',
    flex: 1
  },
  itemText: {
    flex: 'none'
  },
  textMobile: {
    marginTop: 4
  }
})
