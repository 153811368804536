import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {
    display: 'flex',
    width: '100%'
  },
  icon: {
    marginLeft: 8
  },
  text: {
    marginLeft: 8
  },
  input: {
    display: 'none',
    '&:focused + div': {
      border: '1px solid red'
    }
  }
})
