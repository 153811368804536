import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    marginTop: 0,
    '&:hover': {
      background: theme.colors.white,
      boxShadow: `4px 4px 16px ${theme.colors.shadow}`
    },
    transition: 'box-shadow 0.25s ease-in-out',
    width: 268
  },
  imgContainer: {
    overflow: 'hidden'
  },
  imgLabel: {
    background: theme.colors.white,
    border: `1px solid ${theme.colors.shadow}`,
    padding: 8,
    position: 'absolute',
    right: 0,
    top: 0
  },
  img: {
    objectFit: 'cover',
    overflow: 'hidden',
    height: 180,
    width: 268
  },
  details: {
    height: 56,
    padding: '24px 8px',
    width: 'calc(100% - 16px)'
  },
  text: {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden'
  },
  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    container: {
      width: 234
    },
    imgContainer: {
      width: '100%'
    },
    img: {
      width: '100%'
    }
  },
  '@media screen and (max-width: 480px)': {
    container: {
      marginTop: 0,
      background: theme.colors.white,
      boxShadow: `4px 4px 16px ${theme.colors.shadow}`
    }
  }
}))
