import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  vendor: {
    textDecoration: 'none',
    padding: 8
  },
  '@media screen and (max-width: 480px)': {
    vendor: {
      textDecoration: 'none',
      padding: 0,
      '&': {
        marginLeft: 0
      }
    }
  }
})
