import React, { useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'
import { useSwipeable } from 'react-swipeable'

import { Column, Content, Loader, Row } from '../../components'
import { useApi } from '../../providers'
import { CollectionsGalleryItem } from './collection-gallery-item'
import { useStyle } from './collections-gallery.styles'

export const CollectionsGallery = () => {
  const history = useHistory()
  const { collection } = useApi()
  const collections = collection.useHomeCollections({})
  const scrollContainer = useRef<HTMLDivElement>(null)

  const isEvenLengthOfItems =
    collections.data && collections.data.collections.edges.length % 2 === 0

  const classes = useStyle()

  const handleOnClickLeft = () => {
    const scrollElement = scrollContainer.current
    if (scrollElement) {
      const scrollWidth = scrollElement.offsetWidth / 1.5
      const leftPosition = scrollElement.scrollLeft - scrollWidth
      scrollElement.scroll({ left: leftPosition, behavior: 'smooth' })
    }
  }

  const handleOnClickRight = () => {
    const scrollElement = scrollContainer.current

    if (scrollElement) {
      const scrollWidth = scrollElement.offsetWidth / 1.5
      const leftPosition = scrollElement.scrollLeft + scrollWidth
      scrollElement.scroll({ left: leftPosition, behavior: 'smooth' })
    }
  }

  const swipeableConfig = {
    delta: 20,
    preventDefaultTouchmoveEvent: false,
    trackTouch: isMobileOnly,
    trackMouse: true,
    rotationAngle: 0
  }

  const handlers = useSwipeable({
    onSwipedLeft: handleOnClickRight,
    onSwipedRight: handleOnClickLeft,
    ...swipeableConfig
  })

  const handleOnNavigate = (id: string) => () => {
    history.push(`/collection/${id}`)
  }

  if (!collections.data) {
    return <Loader />
  }

  return (
    <Content className={classes.container}>
      <Column fullWidth {...handlers}>
        <Row
          fullWidth
          justifyContent="flex-start"
          className={classes.row}
          ref={scrollContainer}
        >
          {collections.data.collections.edges.map((menuItem) => {
            return (
              <CollectionsGalleryItem
                key={menuItem.node.id}
                isEven={isEvenLengthOfItems}
                imageUrl={menuItem.node.backgroundImage?.url}
                alt={menuItem.node.backgroundImage?.alt}
                onClick={handleOnNavigate(menuItem.node.id)}
              />
            )
          })}
        </Row>
      </Column>
    </Content>
  )
}
