import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  content: {
    width: 'calc(100% - 32px)',
    padding: '40px 16px',
    rowGap: 16,
    textAlign: 'center'
  },
  bottom: {
    width: 'calc(100% - 32px)',
    padding: '24px 16px',
    borderTop: `1px solid ${theme.colors.shadow}`
  }
}))
