import React, { FC, useState } from 'react'

import { Column } from '../column'
import { Row } from '../row'
import { Text } from '../text'
import { Icon, ICONS } from '../icon'

import { NumberSwitcherProps } from './number-switcher.types'
import { useStyle } from './number-switcher.styles'

export const NumberSwitcher: FC<NumberSwitcherProps> = ({
  className = '',
  label,
  labelTx,
  preset,
  defaultValue = 1,
  maxAmount = 10,
  onChange
}) => {
  const classes = useStyle()
  const [value, changeValue] = useState<number>(defaultValue)

  const handleOnCaretUpClick = () => {
    if (value < maxAmount) {
      changeValue(value + 1)

      if (onChange) {
        onChange(value + 1)
      }
    }
  }

  const handleOnCaretDownClick = () => {
    if (value > 1) {
      changeValue(value - 1)

      if (onChange) {
        onChange(value - 1)
      }
    }
  }

  return (
    <Column className={className}>
      <Row
        className={classes.container}
        fullWidth
        justifyContent="space-between"
      >
        <Column alignItems="flex-start">
          {label && (
            <Text
              className={classes.label}
              text={label}
              tx={labelTx}
              color="darkBlue"
              preset="subscriptionText"
            />
          )}
          {value && <Text text={String(value)} preset={preset} />}
        </Column>
        <Column>
          <Icon
            className={classes.caretUp}
            src={ICONS.filledArrow}
            color="black"
            onClick={handleOnCaretUpClick}
          />
          <Row onClick={handleOnCaretDownClick}>
            <Icon
              className={classes.caretDown}
              src={ICONS.filledArrow}
              color="black"
            />
          </Row>
        </Column>
      </Row>
    </Column>
  )
}
