import React, { FC } from 'react'
import { isMobile } from 'react-device-detect'

import { Currency } from '../../services'
import { getPriceToFixed } from '../../pages'

import { Row } from '../row'
import { Column } from '../column'
import { Text } from '../text'
import { getCurrencySymbol } from '../helpers'

import { Dot } from './dot'
import { useStyle } from './constructor-radio-item.styles'
import { ConstructorRadioItemProps } from './constructor-radio-item.types'

export const ConstructorRadioItem: FC<ConstructorRadioItemProps> = ({
  active,
  className,
  tx,
  extraPrice,
  extraProductionDays,
  title,
  onClick
}) => {
  const classes = useStyle()
  const extraPriceStringified = `${getPriceToFixed(
    extraPrice
  )} ${getCurrencySymbol(Currency.USD)}`

  const extraProductionDayValue = { extraProductionDays }

  const Container = isMobile ? Column : Row
  const align = isMobile ? 'flex-start' : 'center'

  return (
    <Row
      className={className}
      onClick={onClick}
      fullWidth
      justifyContent="space-between"
    >
      <Row className={classes.container} justifyContent="flex-start">
        <Row
          className={classes.descriptionContainer}
          justifyContent="flex-start"
        >
          <Dot active={active} />
          {!isMobile && (
            <Text className={classes.title} text={title} tx={tx} preset="h5" />
          )}
        </Row>
        <Container
          className={classes.descriptionContainer}
          justifyContent="flex-start"
          alignItems={align}
        >
          {isMobile && (
            <Text className={classes.title} text={title} tx={tx} preset="h5" />
          )}
          {extraProductionDays && extraProductionDays <= 1 ? (
            <Text
              className={classes.description}
              text={`(+${extraProductionDays} day)`}
              tx="product.constructor.option.item.extra.production.day"
              preset="body"
              values={extraProductionDayValue}
            />
          ) : (
            <Text
              className={classes.description}
              text={`(+${extraProductionDays} days)`}
              tx="product.constructor.option.item.extra.production.days"
              preset="body"
              values={extraProductionDayValue}
            />
          )}
        </Container>
      </Row>
      <Text text={extraPriceStringified} preset="h5" />
    </Row>
  )
}
