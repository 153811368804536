import { createUseStyles } from 'react-jss'

import { BannerStyle } from './banner.types'

export const useStyle = createUseStyles({
  anchor: {
    textDecoration: 'none'
  },
  bannerOverflow: {
    left: '20%',
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: 550
  },
  text: {
    marginTop: 8
  },
  button: ({ isBigButton }: BannerStyle) => ({
    marginTop: 24,
    width: isBigButton ? 400 : 96
  }),

  '@media screen and (max-width: 1223px)': {
    bannerOverflow: {
      width: 200
    }
  }
})
