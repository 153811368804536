import React, { FC } from 'react'

import { Dropdown, ICONS, Row, Column, TextField } from '../../../components'
import { WishlistHeaderProps } from '../wishlist-header/wishlist-header.types'

import { useStyle } from './wishlist-header-mobile.styles'

export const WishlistHeaderMobile: FC<WishlistHeaderProps> = ({
  className = '',
  vendor,
  vendors,
  category,
  categories,
  onChangeSearch,
  onChangeDropdown
}) => {
  const classes = useStyle()

  return (
    <Column fullWidth className={className}>
      <TextField
        preset="border"
        leftIcon={ICONS.search}
        label="Search"
        labelTx="wishlist.filter.search"
        onChange={onChangeSearch}
      />
      <Row className={classes.input}>
        <Dropdown
          className={classes.dropdown}
          label="Category"
          labelTx="wishlist.filter.category"
          defaultValue={category}
          data={categories}
          preset="body"
          onChange={onChangeDropdown('productCategoryId')}
        />
      </Row>
      <Row className={classes.input}>
        <Dropdown
          className={classes.dropdown}
          label="Company"
          labelTx="wishlist.filter.company"
          defaultValue={vendor}
          data={vendors}
          preset="body"
          onChange={onChangeDropdown('productVendorId')}
        />
      </Row>
    </Column>
  )
}
