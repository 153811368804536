import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.shadow}`,
    borderRadius: 4
  },
  content: {
    width: 'calc(100% - 32px)',
    padding: 16,
    borderBottom: `1px solid ${theme.colors.shadow}`,
    justifyContent: 'space-between',
    '&:last-child': {
      borderBottom: 'none'
    }
  },
  image: {
    width: 64,
    height: 64
  },
  name: {
    marginLeft: 16
  },
  nameAndFlag: {},
  flagImg: {
    backgroundColor: 'grey',
    width: 32,
    height: 16
  },
  flagName: {
    marginLeft: 8
  },
  rate: {
    marginTop: 16
  },
  ratingText: {},
  textArea: {
    marginTop: 16
  },
  icon: {
    '&:first-child': {
      marginRight: 16
    }
  },

  '@media screen and (max-width: 480px)': {
    container: {
      width: '100%'
    },
    content: {
      justifyContent: 'flex-start',
      columnGap: 16
    },
    nameAndFlag: {
      alignItems: 'flex-start'
    },
    rate: {
      marginTop: 0
    },
    name: {
      marginLeft: 0,
      display: 'inline-block',
      width: 180,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    ratingText: {
      marginTop: 2
    }
  }
}))
