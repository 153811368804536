import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useHistory } from 'react-router-dom'

import { Content, Text, Column, GoBackContainer } from '../../components'

import { useStyle } from './payment-delivery.styles'

export const PaymentPage = () => {
  const classes = useStyle()
  const titlePreset = isMobileOnly ? 'h1Mobile' : 'h1'
  const subTitlePreset = isMobileOnly ? 'h2Mobile' : 'h2'
  const textPreset = isMobileOnly ? 'h7' : 'h5'
  const history = useHistory()

  const isFromItemCard =
    history.location.state !== undefined &&
    history.location.state.prevPath.includes('/product')

  const handleGoBack = () => history.goBack()

  return (
    <Content className={classes.container} justifyContent="flex-start">
      {isFromItemCard && (
        <GoBackContainer
          text="Back to Product Page"
          textTx="payment.delivery.back.to.product.page"
          onClick={handleGoBack}
        />
      )}
      <Content
        className={classes.content}
        preset="part"
        justifyContent="flex-start"
      >
        <Text
          preset={titlePreset}
          text="Payment & Delivery"
          tx="payment.title"
        />
        <Text
          className={classes.createdDate}
          color="inactive"
          preset={textPreset}
          text="Effective on October 1, 2020"
          tx="payment.created.date"
        />
        <Column className={classes.column} alignItems="flex-start">
          <Text
            className={classes.secondaryTitle}
            preset={subTitlePreset}
            text="1. Packaging and Delivery"
            tx="payment.paragraph.title"
          />
          <Text
            className={classes.secondaryTitle}
            preset="body"
            text="Payment orders"
            tx="payment.text"
          />
          <Text
            className={classes.secondaryTitle}
            preset={subTitlePreset}
            text="2. Delivery Charges"
            tx="delivery.title"
          />
          <Text
            className={classes.secondaryTitle}
            preset="body"
            text="Delivery page"
            tx="delivery.text.page"
          />
        </Column>
      </Content>
    </Content>
  )
}
