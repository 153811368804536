import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {},
  category: {
    textDecoration: 'none'
  },
  description: {
    marginTop: 8,
    maxWidth: 380,
    textAlign: 'center'
  },
  carouselContainer: {
    marginTop: 12,
    maxWidth: 1434
  },
  listRow: {
    columnGap: 16,
    rowGap: 16
  },
  '@media screen and (min-width: 1224px) and (max-width: 1465px)': {
    carouselContainer: {
      maxWidth: 1144
    }
  },
  '@media screen and (min-width: 1080px) and (max-width: 1223px)': {
    carouselContainer: {
      maxWidth: 1048
    }
  },
  '@media screen and (min-width: 814px) and (max-width: 1079px)': {
    carouselContainer: {
      maxWidth: 782
    }
  },
  '@media screen and (min-width: 556px) and (max-width: 813px)': {
    carouselContainer: {
      maxWidth: 516
    }
  },
  '@media screen and (max-width: 480px)': {
    listRow: {
      justifyContent: 'center'
    }
  }
})
