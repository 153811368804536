import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  secondTitle: {
    marginTop: 24
  },
  row: {
    border: `1px solid ${theme.colors.shadow}`,
    padding: 16,
    marginTop: 16,
    width: 'calc(100% - 34px)',
    borderRadius: 4,
    cursor: 'pointer'
  },
  opened: {
    border: `1px solid ${theme.colors.yellow}`
  },
  mainRow: {
    flex: 1
  },
  arrow: {
    display: 'flex'
  },
  value: {
    padding: 16
  },
  ordersColumn: {
    top: 0,
    border: `1px solid ${theme.colors.shadow}`,
    borderRadius: 4,
    maxHeight: 300,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 8
    },
    '&::-webkit-scrollbar-thumb': {
      background: `${theme.colors.blue}`,
      height: 120
    }
  },
  list: {
    width: '100%'
  },
  button: {
    marginTop: 24,
    width: 362
  }
}))
