import React from 'react'
import { TextComponentProps } from './text.types'

export const spanComponent = ({ children, ...props }: TextComponentProps) => (
  <span {...props}>{children}</span>
)

export const presetComponents = {
  mainBanner: spanComponent,
  secondBanner: spanComponent,
  h1: spanComponent,
  h2: spanComponent,
  h3: spanComponent,
  h4: spanComponent,
  h5: spanComponent,
  h6: spanComponent,
  h7: spanComponent,
  h1Mobile: spanComponent,
  h2Mobile: spanComponent,
  h3Mobile: spanComponent,
  title: spanComponent,
  subscriptionText: spanComponent,
  maxButton: spanComponent,
  cardText: spanComponent,
  body: spanComponent,
  secondTitle: spanComponent,
  button: spanComponent,
  rightButton: spanComponent,
  notFound: spanComponent
}
