import React, { FC } from 'react'

import { OrderUpdateVariables } from '../../services'
import { getPriceWithSymbol } from '../../views'

import { MenuSlider } from '../menu-slider'
import { OrderLine } from '../order-line'
import { getCurrencySymbol } from '../helpers'

import { OrderSliderHeader } from './order-slider-header'
import { OrderSliderDescription } from './order-slider-description'
import { useStyle } from './order-slider.styles'
import { OrderSliderProps } from './order-slider.types'

export const OrderSlider: FC<OrderSliderProps> = ({
  className = '',
  order,
  onChange,
  onPayment,
  onContactVendor,
  onOpenDelivery
}) => {
  const classes = useStyle()

  const {
    id,
    created,
    startDate,
    finishDate,
    number,
    lines,
    vendor,
    mainStatus,
    paymentStatus,
    total,
    totalPrice,
    deliveryPrice,
    offer,
    remainingTimeProductionStarted
  } = order

  const currencySymbol = getCurrencySymbol(total.currency)

  const handleOnChange = (variables: OrderUpdateVariables) => {
    if (onChange) {
      onChange(variables)
    }
  }

  const header = (
    <OrderSliderHeader
      number={number}
      mainStatus={mainStatus}
      paymentStatus={paymentStatus}
      created={created}
      price={getPriceWithSymbol(totalPrice, currencySymbol)}
      onPayment={onPayment}
      onOpenDelivery={onOpenDelivery}
    />
  )
  return (
    <MenuSlider
      className={`${className} ${classes.container}`}
      contentClassName={classes.content}
      header={header}
    >
      <OrderSliderDescription
        id={id}
        mainStatus={mainStatus}
        paymentStatus={paymentStatus}
        remainingTimeProductionStarted={remainingTimeProductionStarted}
        startDate={startDate}
        finishDate={finishDate}
        vendor={vendor}
        price={getPriceWithSymbol(totalPrice, currencySymbol)}
        number={number}
        onChangeStatus={handleOnChange}
        onContactVendor={onContactVendor}
      />
      {offer &&
        lines.map((orderLine) => {
          if (!orderLine.product) {
            return null
          }

          return (
            <OrderLine
              className={classes.line}
              {...orderLine}
              {...offer}
              currency={currencySymbol}
              key={`order_${id}_orderLine_${orderLine.id}_product_${orderLine.product.id}`}
              deliveryPrice={deliveryPrice}
              totalOrderPrice={totalPrice}
            />
          )
        })}
    </MenuSlider>
  )
}
