import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    padding: '16px 0',
    width: '100%',
    borderBottom: `1px solid ${theme.colors.shadow}`
  },
  variantContainer: {
    width: '40%'
  },
  number: {
    width: 64,
    textAlign: 'center'
  },
  infoContainer: {
    marginLeft: 8,
    columnGap: 16
  },
  observeOptionsBtn: {
    padding: '8px 6px'
  },
  variant: {
    minWidth: 104
  },
  infoColumns: {
    width: '60%'
  },
  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    variant: {
      minWidth: 48
    }
  }
}))
