import { gql } from '@apollo/client'

import {
  BANNER_FRAGMENT,
  MAIN_MENU_SUB_ITEM_FRAGMENT,
  PROMOTION_BANNER_FRAGMENT,
  SECONDARY_MENU_SUB_ITEM_FRAGMENT
} from '../fragments'

export const SECONDARY_MENU = gql`
  ${SECONDARY_MENU_SUB_ITEM_FRAGMENT}
  query SecondaryMenu {
    shop {
      navigation {
        secondary {
          items {
            ...SecondaryMenuSubItemFragment
            children {
              ...SecondaryMenuSubItemFragment
            }
          }
        }
      }
    }
  }
`

export const MAIN_MENU = gql`
  ${MAIN_MENU_SUB_ITEM_FRAGMENT}
  query SecondaryMenu {
    shop {
      navigation {
        main {
          items {
            ...MainMenuSubItemFragment
            children {
              ...MainMenuSubItemFragment
            }
          }
        }
      }
    }
  }
`

export const BANNERS = gql`
  ${BANNER_FRAGMENT}
  query Banners(
    $first: Int!
    $last: Int
    $after: String
    $before: String
    $filter: BannerFilterInput
  ) {
    allBanners(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
    ) {
      edges {
        cursor
        node {
          ...BannerFragment
        }
      }
    }
  }
`

export const PROMOTION_BANNERS = gql`
  ${PROMOTION_BANNER_FRAGMENT}
  query PromotionBanners(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: PromotionBannerFilterInput
  ) {
    promotionBanners(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
    ) {
      edges {
        cursor
        node {
          ...PromotionBammerFragment
        }
      }
      totalCount
      activeBannersCount
    }
  }
`

export const ADVERTISING_BANNERS = gql`
  ${PROMOTION_BANNER_FRAGMENT}
  query AdvertisingBanners(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: PromotionBannerFilterInput
  ) {
    advertisingBanners(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
    ) {
      edges {
        cursor
        node {
          ...PromotionBammerFragment
        }
      }
      totalCount
      activeBannersCount
    }
  }
`
