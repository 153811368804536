import { isMobile } from 'react-device-detect'
import { createUseStyles } from 'react-jss'

import { ProductViewStyles } from './products-view.types'

export const useStyle = createUseStyles({
  breadcrumbs: {
    marginTop: 16,
    width: '100%'
  },
  title: {
    marginTop: ({ subcategoryId }: ProductViewStyles) =>
      !isMobile && !subcategoryId ? 48 : 24
  },
  productsContainer: {
    position: 'relative',
    width: '100%',
    padding: '16px 0px'
  },
  tabletHeader: {
    marginTop: 16
  },
  scroll: {
    overflow: 'auto',
    maxHeight: '100vh'
  },
  hiddenScroll: {
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  '@media screen and (max-width: 480px)': {
    breadcrumbs: {
      marginLeft: 0
    }
  },
  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    productsContainer: {
      padding: 0
    },
    tabletHeader: {
      marginTop: 16
    }
  }
})
