import React, { FC } from 'react'

import { useLocation } from 'react-router'
import { ObserveOfferButton } from '../../views'

import { Text } from '../text'
import { Row } from '../row'
import { InfoColumn } from '../info-column'
import { Tooltip } from '../tooltip'

import { useStyle } from './variant-line.styles'
import { VariantLineProps } from './variant-line.types'
import { MainRoutes } from '../../routes'
import { CabinetNavigatorPath } from '../../pages'

export const VariantLine: FC<VariantLineProps> = ({
  count,
  currency,
  lastColumnClassName,
  productDefaultPrice,
  unitsIds,
  description,
  discount,
  noSuggestion,
  itemVariant,
  unitQuantity,
  totalUnitPrice,
  isTooltip
}) => {
  const classes = useStyle()
  const location = useLocation()
  const isProceedOrConfirm =
    location.pathname.includes(MainRoutes.PROCEED_PAYMENT) ||
    location.pathname.includes(MainRoutes.ORDER_CONFIRM)
  const isControlOrHistory =
    location.pathname === CabinetNavigatorPath.ORDERS_CONTROL ||
    location.pathname === CabinetNavigatorPath.PURCHASE_HISTORY

  return (
    <Row className={classes.container} justifyContent="space-between">
      <Row
        fullWidth
        className={classes.variantContainer}
        alignItems="center"
        justifyContent="flex-start"
      >
        <Text
          className={classes.number}
          color="black"
          preset="h2"
          text={count}
        />
        <Row alignItems="center" className={classes.infoContainer}>
          <Text
            className={classes.variant}
            color="black"
            preset="h5"
            text="Item"
            tx="shopping.cart.item.variant"
          />
          <ObserveOfferButton
            description={description}
            currency={currency}
            productDefaultPrice={productDefaultPrice}
            discount={discount}
            noSuggestion={noSuggestion}
            options={itemVariant.options}
            offerOptions={itemVariant.productOptions}
            offerColors={itemVariant.productColors}
            offerMaterials={itemVariant.productMaterials}
            itemQuantity={itemVariant.quantity}
            totalItemPrice={itemVariant.totalItemPrice}
            unitIds={unitsIds}
          />
        </Row>
      </Row>
      <Row className={classes.infoColumns} justifyContent="space-between">
        <InfoColumn />
        {isProceedOrConfirm && <InfoColumn />}
        <InfoColumn
          title="Item Quantity:"
          titleTx="shopping.cart.units.quantity"
          value={String(unitQuantity)}
        >
          {isTooltip && <Tooltip unitIds={unitsIds} />}
        </InfoColumn>
        <InfoColumn
          className={lastColumnClassName}
          title="Item Price:"
          titleTx="shopping.cart.units.price"
          value={totalUnitPrice}
        />
        {isControlOrHistory && <InfoColumn />}
      </Row>
    </Row>
  )
}
