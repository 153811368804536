import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {},
  details: {
    marginLeft: 24
  },
  status: {
    marginTop: 4
  }
})
