import React, { FC } from 'react'

import { getPriceToFixed } from '../../../pages'

import { Text } from '../../text'
import { Column } from '../../column'
import { ColorDot } from '../../color-dot'
import { Row } from '../../row'
import { getCurrencySymbol } from '../../helpers'

import { useStyle } from './feedback-product-slider-header.styles'
import { FeedbackSliderHeaderProps } from './feedback-product-slider-header.types'

export const FeedbackSliderHeader: FC<FeedbackSliderHeaderProps> = ({
  className = '',
  name,
  color,
  productImage,
  quantity,
  currency,
  defaultPrice
}) => {
  const classes = useStyle()
  const symbolCurrency = getCurrencySymbol(currency)
  const pricePerUnit = `${symbolCurrency}${getPriceToFixed(defaultPrice)}`

  return (
    <Column className={`${classes.container} ${className}`} fullWidth>
      <Row className={classes.content} fullWidth alignItems="flex-start">
        <Row
          className={classes.col}
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <img className={classes.image} src={productImage} />
          <Column className={classes.name} alignItems="flex-start">
            <Text text={name} preset="h5" />
            <ColorDot className={classes.text} preset="default" color={color} />
          </Column>
        </Row>

        <Column className={classes.secondaryCol} alignItems="flex-start">
          <Text
            text="Price for unit:"
            tx="give.feedback.about.product.price.for.unit"
            preset="body"
            color="inactive"
          />
          <Text className={classes.text} text={pricePerUnit} preset="h7" />
        </Column>
        <Column className={classes.secondaryCol} alignItems="flex-start">
          <Text
            text="Quantity:"
            tx="order.item.product.quantity"
            preset="body"
            color="inactive"
          />
          <Text className={classes.text} text={String(quantity)} preset="h7" />
        </Column>
      </Row>
    </Column>
  )
}
