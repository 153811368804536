import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  cataloguePage: {
    paddingTop: '40px',
    paddingBottom: '48px'
  },
  categoryWrapper: {
    width: 'calc(100% - 32px)',
    padding: '0 16px',
    marginTop: '24px'
  },
  link: {
    '& > span:hover': {
      color: theme.colors.yellow
    }
  },
  menuBlock: {
    border: `1px solid ${theme.colors.shadow}`
  },

  header: {
    justifyContent: 'flex-start',
    width: 'calc(100% - 32px)',
    padding: '16px',
    borderBottom: `1px solid ${theme.colors.shadow}`
  },

  category: {
    width: 'calc(100% - 32px)',
    padding: '16px',
    columnGap: '56px'
  },

  listGroup: {
    minWidth: 250
  },
  listItem: {
    paddingTop: '16px'
  }
}))
