import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { getMessageByOfferStatus } from '../../../pages'
import { OfferStatus } from '../../../services'
import { LightTheme } from '../../../theme'
import { getPriceWithSymbol } from '../../../views'

import { Button } from '../../button'
import { ColorDot } from '../../color-dot'
import { Column } from '../../column'
import { onStopEvent } from '../../helpers'
import { Row } from '../../row'
import { Text } from '../../text'

import { getColorByOfferStatus } from '../helpers'

import { useStyle } from './offer-list-item-header.styles'
import { OfferListItemHeaderProps } from './offer-list-item-header.types'

export const OfferListItemHeader: FC<OfferListItemHeaderProps> = ({
  status,
  totalPrice,
  currency,
  imgUrl,
  isDeclined,
  number,
  vendor,
  onAcceptOffer,
  onReactivate
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle()
  const buttonText =
    status === OfferStatus.ACCEPTED_BACK_FROM_CART ? 'Add To Cart' : 'Accept'
  const buttonTx =
    status === OfferStatus.ACCEPTED_BACK_FROM_CART
      ? 'btn.add.to.cart'
      : 'btn.accept'
  const isAccepted = status === OfferStatus.ACCEPTED
  const isDeletedByCustomer = status === OfferStatus.DELETED_BY_CUSTOMER
  const acceptDisabled = isAccepted || isDeletedByCustomer

  return (
    <Row className={classes.header} fullWidth justifyContent="space-between">
      <Row justifyContent="flex-start">
        <Row
          className={classes.statusRow}
          alignItems="center"
          justifyContent="flex-start"
        >
          <ColorDot
            preset="big"
            color={theme.colors[getColorByOfferStatus(status)]}
          />
          <Column alignItems="flex-start" className={classes.statusColumn}>
            <Text text={`# ${String(number)}`} color="inactive" preset="body" />
            <Text
              className={classes.status}
              {...getMessageByOfferStatus(status)}
              color="black"
              preset="h7"
            />
          </Column>
        </Row>
        <Column alignItems="flex-start" className={classes.companyColumn}>
          <Text text="Company:" color="inactive" preset="body" />
          <Text
            className={classes.status}
            text={vendor.companyName}
            color="black"
            preset="h7"
          />
        </Column>
        <Column alignItems="flex-start" className={classes.companyColumn}>
          <Text
            text="Total Price:"
            tx="offer.total.price"
            color="inactive"
            preset="body"
          />
          <Text
            className={classes.status}
            text={getPriceWithSymbol(totalPrice, currency)}
            color="black"
            preset="h7"
          />
        </Column>
      </Row>

      <Row onClick={onStopEvent}>
        {imgUrl && (
          <img
            className={classes.productAvatar}
            height="48px"
            width="48px"
            src={imgUrl}
          />
        )}
        {!isDeclined ? (
          <Button
            disabled={acceptDisabled}
            className={classes.button}
            text={buttonText}
            tx={buttonTx}
            color="yellow"
            preset="button3"
            textColor="white"
            textPreset="maxButton"
            onClick={onAcceptOffer}
          />
        ) : (
          <Button
            className={classes.button}
            text="Reactivate"
            tx="btn.reactivate"
            color="yellow"
            preset="button3"
            textColor="white"
            textPreset="maxButton"
            onClick={onReactivate}
          />
        )}
      </Row>
    </Row>
  )
}
