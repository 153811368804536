import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  info: {},
  vendorInfo: {
    marginTop: 16,
    '& > *:not(:first-child)': {
      marginTop: 16
    }
  },
  vendorStatuses: {
    '& > *': {
      whiteSpace: 'nowrap'
    },
    '& > *:not(:first-child)': {
      marginLeft: 8
    }
  },
  vendorActions: {
    marginTop: 52,
    '& > *:not(:first-child)': {
      marginLeft: 16
    }
  },
  '@media screen and (max-width: 480px)': {
    info: {
      justifyContent: 'flex-start'
    },
    vendorInfo: {
      flex: 1,
      marginTop: 16,

      '&:not(:first-child)': {
        marginTop: 20
      }
    },
    vendorStatuses: {
      '& > *': {
        whiteSpace: 'nowrap'
      },
      '& > *:not(:first-child)': {
        marginLeft: 8
      },
      '&:not(:first-child)': {
        marginTop: 20
      }
    },
    vendorActions: {
      marginTop: 24
    }
  }
})
