import React, { FC, useState } from 'react'

import {
  Icon,
  ICONS,
  Row,
  Column,
  Text,
  FilterSidebar,
  Button,
  MobilePickerMenu,
  MobilePickerMenuProps
} from '../../../components'
import { getSortByValue } from '../../../pages'

import { FilterResultRow } from '../../products-view'

import { sortByData } from '../products-header'

import { MobileProductsHeaderProps } from './mobile-products-header.types'
import { useStyle } from './mobile-products-header.styles'

export const MobileProductsHeader: FC<MobileProductsHeaderProps> = ({
  count,
  isShowTitle,
  title,
  titleTx,
  reset,
  filters,
  filtersResults,
  onChangeFilters,
  onChangeFilterItems,
  onResetFilters,
  onRemoveItem,
  onSort
}) => {
  const classes = useStyle()
  const [open, changeOpen] = useState(false)
  const [openSort, changeOpenSort] = useState(false)

  const handlOnOpenFilter = () => {
    changeOpen(!open)
  }

  const handleOnClick = () => {
    changeOpen(!open)
  }

  const handleOnOpenSort = (state: boolean) => () => {
    changeOpenSort(state)
  }

  const handleOnChange: MobilePickerMenuProps['onChange'] = (value) => {
    const input = getSortByValue(value.id)

    if (onSort) {
      onSort(input)
    }
  }

  return (
    <>
      {isShowTitle && (
        <Text
          text={title}
          tx={titleTx}
          preset="h3Mobile"
          className={classes.title}
        />
      )}

      <Row fullWidth className={classes.filter} justifyContent="space-between">
        <Row>
          <Icon src={ICONS.filterList} onClick={handlOnOpenFilter} />
        </Row>
        <Row alignItems="baseline">
          <Text
            className={classes.count}
            color="black"
            preset="h6"
            text={String(count)}
          />
          <Text
            className={classes.results}
            color="inactive"
            preset="secondTitle"
            text="results"
          />
        </Row>
        <Row onClick={handleOnOpenSort(true)}>
          <Icon src={ICONS.sortArrows} />
        </Row>
        <MobilePickerMenu
          open={openSort}
          data={sortByData}
          defaultValue={sortByData[0].id}
          name="sort"
          title="Sort by"
          onClose={handleOnOpenSort(false)}
          onChange={handleOnChange}
        />
      </Row>

      {open && (
        <Column justifyContent="start" className={classes.filterOverlay}>
          <Row
            justifyContent="space-between"
            fullWidth
            className={classes.filterHeader}
          >
            <Text
              className={classes.filterText}
              color="black"
              preset="h5"
              text="FILTERS"
            />
            <Row className={classes.filterSvgContainer}>
              <Icon
                className={classes.icon}
                src={ICONS.close}
                onClick={handlOnOpenFilter}
              />
            </Row>
          </Row>
          {filtersResults.size > 0 && (
            <FilterResultRow
              data={filtersResults}
              onResetFilters={onResetFilters}
              onRemoveItem={onRemoveItem}
            />
          )}
          <FilterSidebar
            reset={reset}
            filters={filters}
            filtersResults={filtersResults}
            onChangeFilters={onChangeFilters}
            onChangeFilterItems={onChangeFilterItems}
          />
          <Row
            fullWidth
            className={classes.infoBottom}
            justifyContent="space-between"
          >
            <Button
              color="yellow"
              preset="primary"
              text={`Show ${count} Results`}
              textColor="white"
              textPreset="h5"
              onClick={handleOnClick}
            />
          </Row>
        </Column>
      )}
    </>
  )
}
