import React, { FC, MouseEvent, useMemo, useState } from 'react'

import { CoreItem } from '../../services'

import { Column } from '../column'

import { FilterStarReviewProps } from './filter-star-review.types'
import { FilterStarRow } from './filter-star-row'

export const FilterStarReview: FC<FilterStarReviewProps> = ({
  className = '',
  data,
  value,
  defaultValue = [],
  onChange,
  onChangeItem
}) => {
  const [activeIds, changeActiveIds] = useState<string[]>(defaultValue)
  const MainValue = useMemo(() => value || activeIds, [value, activeIds])

  const handleOnChange = (
    nextValue: string[],
    event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    changeActiveIds(nextValue)

    if (onChange) {
      onChange(nextValue, event)
    }

    if (onChangeItem) {
      const nextValueItems = data.filter((item) => {
        const isActiveRating = nextValue.includes(item.id)

        return isActiveRating
      })
      onChangeItem(nextValueItems, event)
    }
  }

  const handleOnClick =
    (rating: CoreItem) =>
    (event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
      const currentActive = MainValue.includes(rating.id)

      if (currentActive) {
        const nextValue = MainValue.filter((ratingId) => ratingId !== rating.id)

        handleOnChange(nextValue, event)
      } else {
        const nextValue = [...MainValue, rating.id]

        handleOnChange(nextValue, event)
      }
    }

  return (
    <Column className={className}>
      {data &&
        data
          .filter((item) => Number(item.id) !== 5)
          .reverse()
          .map((item) => {
            return (
              <FilterStarRow
                key={`rating_${item.name}`}
                textColor="inactive"
                textPreset="h5"
                name={item.name}
                onClick={handleOnClick(item)}
              />
            )
          })}
    </Column>
  )
}
