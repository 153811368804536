import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'

import { Text } from '../../text'
import { Column } from '../../column'
import { Row } from '../../row'
import { Avatar } from '../../avatar'

import { useStyle } from './search-item.styles'
import { SearchItemProps } from './search-item.types'

export const SearchItem: FC<SearchItemProps> = ({
  className = '',
  children,
  image,
  userName,
  title,
  to
}) => {
  const history = useHistory()
  const classes = useStyle()

  const handleOnNavigate = () => {
    history.push(to)
  }

  return (
    <Row
      fullWidth
      className={`${className} ${classes.container}`}
      justifyContent="flex-start"
      onClick={handleOnNavigate}
    >
      <Avatar src={image} name={userName} radius={0} />
      <Column fullWidth alignItems="space-beetween" className={classes.details}>
        <Text className={classes.text} color="black" preset="h5" text={title} />
        {children}
      </Column>
    </Row>
  )
}
