import React, { FC } from 'react'

import { concatClassNames } from '../../utils'

import { Button } from '../button'
import { Column } from '../column'
import { onStopEvent } from '../helpers'
import { Row } from '../row'

import { useStyle } from './features-details-block.styles'
import { FeaturesDetailsBlockProps } from './features-details-block.types'

export const FeaturesDetailsBlock: FC<FeaturesDetailsBlockProps> = ({
  className = '',
  onOpenPreview
}) => {
  const classes = useStyle()

  const handleOnOpenPreview = () => {
    if (onOpenPreview) {
      onOpenPreview()
    }
  }

  return (
    <Column
      fullWidth
      alignItems="flex-start"
      className={concatClassNames(classes.container, className)}
    >
      <Row onClick={onStopEvent}>
        <Button
          className={classes.button}
          preset="button3"
          textPreset="maxButton"
          textColor="white"
          text="QUICK VIEW"
          tx="product.quickView"
          onClick={handleOnOpenPreview}
        />
      </Row>
    </Column>
  )
}
