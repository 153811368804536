import React, { FC } from 'react'
import { isMobile } from 'react-device-detect'

import { Column } from '../../column'
import { Text } from '../../text'

import { useStyle } from './constructor-slider-header.styles'
import { СonstructorSliderHeaderProps } from './constructor-slider-header.types'

export const СonstructorSliderHeader: FC<СonstructorSliderHeaderProps> = ({
  optionName,
  tx
}) => {
  const classes = useStyle()
  const titlePreset = isMobile ? 'h3Mobile' : 'h3'
  return (
    <Column className={classes.container}>
      <Column alignItems="flex-start">
        <Text
          className={classes.status}
          color="black"
          preset={titlePreset}
          text={optionName}
          tx={tx}
        />
      </Column>
    </Column>
  )
}
