import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {
    width: 'calc(100% - 24px)'
  },
  category: {
    textDecoration: 'none',
    margin: 8
  },
  description: {
    marginTop: 24,
    maxWidth: 'calc(100% - 24px)',
    textAlign: 'center'
  },
  carouselContainer: {
    marginTop: 24,
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  }
})
