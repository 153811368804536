import React, { FC } from 'react'

import { Row } from '../../row'

import { useStyle } from './dot.styles'
import { DotProps } from './dot.types'

export const Dot: FC<DotProps> = ({ active }) => {
  const classes = useStyle({ active })
  return (
    <Row className={classes.container}>
      <div className={classes.active} />
    </Row>
  )
}
