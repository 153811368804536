import React, { FC } from 'react'

import { Column } from '../../../components'

import { ProductPreviewFeaturesDetailsBlockOption } from '../features-details-block-option'

import { ProductPreviewFeaturesProps } from './product-preview-features.types'
import { useStyle } from './product-preview-features.styles'

export const ProductPreviewFeatures: FC<ProductPreviewFeaturesProps> = ({
  className = '',
  product
}) => {
  const classes = useStyle()

  const {
    brandName,
    productionDaysUpTo,
    countryOfOrigin,
    materialCare,
    materialComposition
  } = product

  return (
    <Column
      alignItems="flex-start"
      justifyContent="flex-start"
      fullWidth
      className={className}
    >
      <ProductPreviewFeaturesDetailsBlockOption
        className={classes.optionsRow}
        label="Production days up to:"
        labelTx="product.features.details.production.days"
        text={String(productionDaysUpTo) || '-'}
      />
      <ProductPreviewFeaturesDetailsBlockOption
        className={classes.optionsRow}
        label="Brand name:"
        labelTx="product.features.details.brand.name"
        text={brandName || '-'}
      />
      <ProductPreviewFeaturesDetailsBlockOption
        className={classes.optionsRow}
        label="Country of Origin:"
        text={countryOfOrigin || '-'}
      />
      <ProductPreviewFeaturesDetailsBlockOption
        className={classes.optionsRow}
        label="Material composition:"
        labelTx="product.features.details.material.composition"
        text={materialComposition || '-'}
      />
      <ProductPreviewFeaturesDetailsBlockOption
        className={classes.optionsRow}
        label="Material care:"
        labelTx="product.features.details.material.care"
        text={materialCare || '-'}
      />
    </Column>
  )
}
