import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {
    alignItems: 'center'
  },
  order: {
    marginTop: 16
  },
  load: {
    padding: '16px 0'
  },
  button: {
    width: 537
  },
  emptyButton: {
    cursor: 'pointer',
    marginTop: 24,
    width: 312,
    padding: 16
  },
  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    container: {
      width: 'calc(100% - 32px)',
      padding: '0px 16px'
    }
  },
  '@media screen and (max-width: 480px)': {
    container: {
      width: '100%'
    },
    order: {
      marginTop: 24
    },
    load: {
      padding: 0,
      marginTop: 16
    },
    column: {
      marginTop: 0
    },
    button: {
      width: '100%',
      padding: '12px 0'
    },
    emptyButton: {
      marginTop: 24
    }
  }
})
