import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    height: '100%'
  },
  navLink: {
    marginTop: 16
  },
  logoutRow: {
    position: 'fixed',
    bottom: 0,
    height: 48
  },
  logoutText: {
    marginLeft: 16
  },
  count: {
    minWidth: 24,
    height: 24,
    borderRadius: '50%',
    marginTop: 18,
    background: theme.colors.yellow
  },
  '@media screen and (max-width: 480px)': {
    container: {
      width: 'calc(100% - 24px)',
      padding: '0 12px'
    }
  },
  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    container: {
      width: 'calc(100% - 32px)',
      padding: '0px 16px'
    }
  }
}))
