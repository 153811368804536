import React, { FC } from 'react'

import { Row } from '../row'
import { Text } from '../text'

import { VendorDetailsItemProps } from './vendor-details-item.types'

export const VendorDetailsItem: FC<VendorDetailsItemProps> = ({
  className = '',
  title,
  titleTx,
  value
}) => {
  return (
    <Row className={className}>
      <Text text={title} tx={titleTx} preset="body" color="inactive" />
      <Text text={value || '-'} preset="h5" color="black" />
    </Row>
  )
}
