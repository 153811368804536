import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  details: {
    flex: 2,
    marginLeft: 8,
    overflow: 'hidden'
  },

  statusColumn: {
    flex: 2,
    marginLeft: 24,
    minWidth: 168
  },
  detailsRow: {
    flex: 6
  },
  statusRow: {
    flex: 2.4
  },
  status: {
    marginTop: 4,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  imgRow: {
    flex: 2
  }
})
