import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {},
  banner: {},
  dot: {
    '&:not(:first-child)': {
      marginLeft: 8
    }
  },
  carouselContainer: {
    overflowX: 'hidden',
    maxWidth: '100vw',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  image: {
    height: '60vh',
    objectFit: 'cover',
    width: '100vw'
  },
  dotContainer: {
    marginTop: 8
  },
  '@media screen and (max-width: 480px)': {
    image: {
      height: '75vh',
      objectFit: 'cover',
      width: '100vw'
    },
    carouselContainer: {
      overflowX: 'auto'
    }
  }
})
