import React, { FC } from 'react'
import { isMobileOnly } from 'react-device-detect'

import { Column } from '../column'
import { Row } from '../row'
import { Text } from '../text'

import { useStyle } from './order-confirm-header.styles'
import { OrderConfirmHeaderProps } from './order-confirm-header.types'

export const OrderConfirmHeader: FC<OrderConfirmHeaderProps> = ({
  className,
  orderNumber,
  created,
  companyName,
  price
}) => {
  const classes = useStyle()
  const Container = isMobileOnly ? Column : Row
  const textPreset = isMobileOnly ? 'h5' : 'h4'

  return (
    <Container fullWidth className={className} justifyContent="flex-start">
      <Row className={classes.infoNum} justifyContent="flex-start">
        <Row>
          <Text
            text="Order #"
            tx="order.confirm.number"
            preset={textPreset}
            color="white"
          />
          <Text text={orderNumber} preset={textPreset} color="white" />
        </Row>
      </Row>
      <Row
        className={classes.infoItem}
        justifyContent="flex-start"
        alignItems="flex-end"
      >
        <Text
          text="Date:"
          tx="order.confirm.date"
          preset="body"
          color="white"
        />
        <Text
          className={classes.value}
          text={created}
          preset={textPreset}
          color="white"
        />
      </Row>
      <Row
        className={classes.infoItem}
        justifyContent="flex-start"
        alignItems="flex-end"
      >
        <Text
          text="Company:"
          tx="order.confirm.vendor"
          preset="body"
          color="white"
        />
        <Text
          className={classes.value}
          text={companyName}
          preset={textPreset}
          color="white"
        />
      </Row>

      {price && (
        <Row
          className={classes.infoLastItem}
          justifyContent="flex-start"
          alignItems="flex-end"
        >
          <Text
            text="Order Price:"
            tx="order.confirm.order.price"
            preset="body"
            color="white"
          />
          <Text
            className={classes.value}
            text={price}
            preset={textPreset}
            color="white"
          />
        </Row>
      )}
    </Container>
  )
}
