import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    background: `${theme.colors.inactive}`,
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100vh',
    width: '100vw',
    zIndex: 100
  },
  buttonContainer: {
    background: theme.colors.transparent,
    position: 'absolute',
    inset: '0 0 0 0',
    border: 'none',
    outline: 'none',
    zIndex: 1
  },
  modal: {
    margin: 'auto',
    backgroundColor: theme.colors.white,
    borderRadius: 4,
    position: 'relative',
    width: 568,
    maxHeight: 'calc(100vh - 80px)',
    boxShadow: '4px 8px 24px rgba(0, 0, 0, 0.16)',
    zIndex: 2,
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  title: {
    padding: 16,
    width: 'calc(100% - 32px)',
    borderBottom: `1px solid ${theme.colors.shadow}`
  },
  closeContainer: {
    border: 'none',
    outline: 'none',
    background: 'transparent'
  },
  close: {
    cursor: 'pointer'
  },
  bottomContainer: {
    alignSelf: 'stretch',
    flex: 1
  },
  illustration: {
    opacity: 0.7
  },
  arrowRight: {
    marginLeft: 16
  }
}))
