import React, { FC, useEffect, useMemo, useState } from 'react'

import {
  Icon,
  ICONS,
  Row,
  Column,
  Text,
  FilterSidebar,
  Button,
  Dropdown,
  DropdownItemData
} from '../../../components'
import { getSortByValue } from '../../../pages'

import { FilterResultRow } from '../../products-view'

import { sortData } from '../products-header'

import { TabletProductsHeaderProps } from './tablet-products-header.types'
import { useStyle } from './tablet-products-header.styles'

export const TabletProductsHeader: FC<TabletProductsHeaderProps> = ({
  className = '',
  count,
  isShowTitle,
  title,
  titleTx,
  reset,
  filters,
  filtersResults,
  onChangeFilters,
  onChangeFilterItems,
  onResetFilters,
  onRemoveItem,
  onSort
}) => {
  const [openFilter, changeOpenFilter] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0)

  const isScrollPosition = useMemo(() => {
    if (scrollPosition) {
      return scrollPosition > 10
    }
    return false
  }, [scrollPosition])

  const classes = useStyle({ scroll: isScrollPosition })

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleOnSort = (value: DropdownItemData) => {
    if (onSort) {
      const sortBy = getSortByValue(value.id)

      onSort(sortBy)
    }
  }

  const handlOnOpenFilter = () => {
    changeOpenFilter(!openFilter)
  }

  const handleOnClick = () => {
    changeOpenFilter(!openFilter)
  }
  return (
    <Row fullWidth className={className}>
      <Row fullWidth className={classes.filter} justifyContent="space-between">
        <Row>
          <Icon src={ICONS.filterList} onClick={handlOnOpenFilter} />
          {isShowTitle && (
            <Text
              text={title}
              tx={titleTx}
              preset="h3"
              className={classes.title}
            />
          )}
        </Row>
        <Row fullWidth justifyContent="flex-end">
          <Row alignItems="baseline">
            <Text
              className={classes.count}
              color="black"
              preset="h6"
              text={String(count)}
            />
            <Text
              className={classes.results}
              color="inactive"
              preset="secondTitle"
              text="results"
            />
          </Row>
          <Row className={classes.sort}>
            <Text
              className={classes.sortText}
              color="inactive"
              preset="h6"
              text="Sort by:"
            />
            <Dropdown
              className={classes.sortDropdown}
              label="Clear"
              labelTx="product.sort.clear"
              preset="body"
              withSearch={false}
              data={sortData}
              onChange={handleOnSort}
            />
          </Row>
        </Row>
      </Row>

      {openFilter && (
        <Column justifyContent="flex-start" className={classes.filterOverlay}>
          <Row
            justifyContent="space-between"
            fullWidth
            className={classes.filterHeader}
          >
            <Text color="black" preset="h4" text="FILTERS" />
            <Row className={classes.filterSvgContainer}>
              <Icon
                className={classes.icon}
                src={ICONS.close}
                onClick={handlOnOpenFilter}
              />
            </Row>
          </Row>
          {filtersResults.size > 0 && (
            <FilterResultRow
              data={filtersResults}
              onResetFilters={onResetFilters}
              onRemoveItem={onRemoveItem}
            />
          )}

          <FilterSidebar
            reset={reset}
            filters={filters}
            filtersResults={filtersResults}
            onChangeFilters={onChangeFilters}
            onChangeFilterItems={onChangeFilterItems}
          />
          <Row
            fullWidth
            className={classes.infoBottom}
            justifyContent="space-between"
          >
            <Button
              color="yellow"
              preset="primary"
              text={`Show ${count} Results`}
              textColor="white"
              textPreset="h5"
              onClick={handleOnClick}
            />
          </Row>
        </Column>
      )}
    </Row>
  )
}
