export enum Sex {
  MALE = 1,
  FEMALE = 2
}

export type SignUpStyleProps = {
  background?: string
  isErrorEmail?: boolean
  isErrorPassword?: boolean
}
