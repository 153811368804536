import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { getColorByMainStatus, getMessageByMainStatus } from '../../../pages'
import { LightTheme } from '../../../theme'

import { Row } from '../../row'
import { Column } from '../../column'
import { Text } from '../../text'
import { ColorDot } from '../../color-dot'
import { ResponsiveMediaView } from '../../responsive-media-view'

import { useStyle } from './purchase-slider-header-close.styles'
import { PurchaseSliderHeaderCloseProps } from './purchase-slider-header-close.types'

export const PurchaseSliderHeaderClose: FC<PurchaseSliderHeaderCloseProps> = ({
  number,
  country,
  created,
  status,
  totalPrice,
  lines,
  companyName
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle()
  const orderIdText = `# ${number}`

  const VendorAndCountry = (
    <>
      <Column className={classes.details} alignItems="flex-start">
        <Text color="inactive" preset="body" text="Company:" />
        <Row fullWidth justifyContent="flex-start">
          <Text
            className={classes.status}
            color="black"
            preset="h7"
            text={companyName}
          />
        </Row>
      </Column>
      <Column className={classes.details} alignItems="flex-start">
        <Text color="inactive" preset="body" text="Country" />
        <Text
          className={classes.status}
          color="black"
          preset="h7"
          text={country}
        />
      </Column>
    </>
  )

  return (
    <Row fullWidth justifyContent="space-between">
      <Row
        fullWidth
        justifyContent="space-between"
        alignItems="flex-start"
        className={classes.detailsRow}
      >
        <Row className={classes.statusRow}>
          <ColorDot
            preset="big"
            color={theme.colors[getColorByMainStatus(status)]}
          />
          <Column className={classes.statusColumn} alignItems="flex-start">
            <Text color="inactive" preset="body" text={orderIdText} />
            <Text
              {...getMessageByMainStatus(status)}
              className={classes.status}
              color="black"
              preset="h7"
            />
          </Column>
        </Row>
        <Column className={classes.details} alignItems="flex-start">
          <Text
            color="inactive"
            preset="body"
            text="Total Price"
            tx="order.item.price.title"
          />
          <Text
            className={classes.status}
            color="black"
            preset="h7"
            text={String(totalPrice)}
          />
        </Column>
        <ResponsiveMediaView
          bigScreen={VendorAndCountry}
          medium={VendorAndCountry}
        />

        <Column className={classes.details} alignItems="flex-start">
          <Text color="inactive" preset="body" text="Date of creation" />
          <Text
            className={classes.status}
            color="black"
            preset="h7"
            text={created}
          />
        </Column>
      </Row>

      <Row className={classes.imgRow} justifyContent="flex-end">
        {lines.map((orderLine) => {
          if (!orderLine.product) {
            return null
          }

          return (
            <img
              key={Math.random()}
              height="48px"
              width="48px"
              src={orderLine.product.thumbnail2x?.url}
            />
          )
        })}
      </Row>
    </Row>
  )
}
