import React, { FC } from 'react'

import { arrayHasElements, Column } from '../../../components'

import { DetailsBlockSubitem } from '../details-block-subitem'
import { DetailsBlockOption } from '../details-block-option'

import { ProductOptionsProps } from './product-options.types'
import { useStyle } from './product-options.styles'

export const ProductOptions: FC<ProductOptionsProps> = ({
  className = '',
  id,
  colors,
  materials,
  options,
  currency
}) => {
  const classes = useStyle()

  return (
    <Column fullWidth className={className} alignItems="flex-start">
      <DetailsBlockSubitem text="Material:" tx="product.material.options">
        {materials &&
          materials.map((material) => (
            <DetailsBlockOption
              isOptionSection
              key={`product_${id}_material_${material.id}`}
              className={classes.optionsRow}
              text={material.title || '-'}
              extraDays={material.extraProductionDays}
              extraPrice={material.extraPrice}
              currency={currency}
            />
          ))}
      </DetailsBlockSubitem>
      <DetailsBlockSubitem
        className={classes.detailsColumn}
        text="Color:"
        tx="product.color.options"
      >
        {colors &&
          colors.map((color) => (
            <DetailsBlockOption
              isOptionSection
              key={`product_${id}_color_${color.id}`}
              className={classes.optionsRow}
              color={color.color.code}
              text={color.color.name}
              extraDays={color.extraProductionDays}
              extraPrice={color.extraPrice}
              currency={currency}
            />
          ))}
      </DetailsBlockSubitem>
      {options &&
        options.map((option) => {
          const isVariants = arrayHasElements(option.variants)

          if (!isVariants) {
            return null
          }

          return (
            <DetailsBlockSubitem
              key={`product_${id}_option_${option.id}_${option.title}`}
              className={classes.detailsColumn}
              text={option.title}
            >
              {option.variants.map((variant) => (
                <DetailsBlockOption
                  isOptionSection
                  key={`option_${option.id}_variant_${variant.id}`}
                  className={classes.optionsRow}
                  text={variant.title || '-'}
                  extraDays={variant.extraProductionDays}
                  extraPrice={variant.extraPrice}
                  currency={currency}
                />
              ))}
            </DetailsBlockSubitem>
          )
        })}
    </Column>
  )
}
