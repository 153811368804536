import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'
import { TextFieldStyleProps } from './text-field.types'

export const presetsContainer = (theme: LightTheme, error?: string) => ({
  border: {
    '&:focus-within': {
      border: `2px solid ${theme.colors.yellow} !important`,
      padding: '3px 22px',
      width: 'calc(100% - 48px)'
    },
    border: `1px solid ${theme.colors[error ? 'error' : 'inactive']}`,
    borderRadius: 4,
    cursor: 'text',
    padding: '4px 23px',
    width: 'calc(100% - 48px)'
  },
  'bottom-border': {
    '&:focus-within': {
      borderBottom: `2px solid ${theme.colors.yellow}`,
      padding: '0px 4px',
      width: 'calc(100% - 8px)'
    },
    borderBottom: `2px solid ${theme.colors.black}`,
    cursor: 'text',
    padding: '0px 4px',
    width: 'calc(100% - 8px)'
  },
  main: {
    borderRadius: 4,
    cursor: 'text',
    padding: '4px 24px',
    width: 'calc(100% - 48px)'
  },
  search: {
    borderRadius: 4,
    cursor: 'text',
    paddingTop: 16,
    background: theme.colors.lightBlue,
    width: 'calc(100% - 48px)'
  }
})

const presetsInput = {
  border: {},
  'bottom-border': {},
  main: {},
  search: {}
}

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: ({ preset = 'main', error }: TextFieldStyleProps) => ({
    background: theme.colors.white,
    ...presetsContainer(theme, error)[preset]
  }),
  icon: {
    cursor: 'pointer',
    marginLeft: 8
  },
  input: ({
    textInputPreset = 'body',
    preset = 'main',
    password,
    placeholderColor = 'inactive'
  }: TextFieldStyleProps) => ({
    ...theme.typography[textInputPreset],
    ...presetsInput[preset],
    '&:-internal-autofill-selected': {
      '-webkit-box-shadow': 'none',
      backgroundColor: 'none'
    },
    '&::placeholder': {
      color: theme.colors[placeholderColor]
    },
    '&:focus': {
      outline: 'none'
    },
    background: theme.colors.transparent,
    border: 'none',
    color: theme.colors.black,
    letterSpacing: password ? '8px' : 'normal',
    marginTop: 4,
    padding: 0,
    width: '100%'
  }),
  inputLabel: {
    '&::placeholder': {
      opacity: 0
    },
    '&:focus::placeholder': {
      opacity: 0.7
    }
  },
  inputContainer: {
    marginLeft: ({ leftIcon }: TextFieldStyleProps) => leftIcon && 8
  },
  label: {
    transform: ({ focus, value }: TextFieldStyleProps) =>
      !focus && !value && 'translate(0px, 66%) scale(1.25)',
    transformOrigin: ({ focus, value }: TextFieldStyleProps) =>
      !focus && !value && '0%',
    transition:
      'transform-origin 0.25s ease-in-out, transform 0.25s ease-in-out'
  },
  leftIcon: {
    minHeight: 24,
    minWidth: 24
  },
  errorText: {
    width: 'calc(100% - 48px)',
    height: 14
  }
}))
