import React, { useEffect, useMemo } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useApi } from '../../providers'

import {
  Content,
  HeaderAdditionalLinks,
  Loader,
  ProductList,
  ProductsCategoryFilter,
  Row
} from '../../components'

import { reduceProductEdgesToProductList } from '../helpers'
import {
  OrderDirection,
  ProductFilter,
  ProductOrderField
} from '../../services'

import { useStyle } from './feature-products.styles'

const first = 10

export const FeatureProducts = () => {
  const { product, menu } = useApi()

  const categories = menu.useMainMenu()
  const { data, refetch, fetchMore } = product.useNewArrivalsProducts({
    first,
    sortBy: { direction: OrderDirection.DESC, field: ProductOrderField.DATE }
  })

  const classes = useStyle()
  const h1Preset = isMobileOnly ? 'h1Mobile' : 'h1'

  useEffect(() => {
    refetch({ first })
  }, [])

  const Products = useMemo(() => {
    if (data) {
      return reduceProductEdgesToProductList(data.newArrivals.edges)
    }

    return []
  }, [data])

  const Categories = useMemo(() => {
    if (categories && categories.data) {
      return categories.data.shop.navigation.main.items
    }

    return []
  }, [categories])

  const handleOnChangeFilter = (value: ProductFilter) => {
    if (refetch) {
      refetch({ first, filter: value })
    }
  }

  const handleOnChangeCategory = (id: string) => {
    if (id) {
      const nextState = {
        categories: [id]
      }
      handleOnChangeFilter(nextState)
    } else {
      const nextState = {
        categories: undefined
      }
      handleOnChangeFilter(nextState)
    }
  }

  const handleOnLoadNext = () => {
    if (data?.newArrivals.pageInfo.hasNextPage) {
      const { edges } = data.newArrivals
      const { cursor } = edges[edges.length - 1]

      fetchMore({ variables: { first, after: cursor } })
    }
  }

  if (!data) {
    return <Loader />
  }

  return (
    <Content>
      <ProductList
        carousel
        titlePreset={h1Preset}
        className={classes.productList}
        title="New Arrivals"
        titleTx="header.newArrivals"
        to={HeaderAdditionalLinks.NEW_ARRIVALS}
        data={Products}
        onLoadNext={handleOnLoadNext}
      >
        <ProductsCategoryFilter
          data={Categories}
          onChange={handleOnChangeCategory}
        />
        <Row />
      </ProductList>
    </Content>
  )
}
