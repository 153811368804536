import React, {
  FC,
  ChangeEvent,
  MouseEvent,
  useState,
  useCallback,
  useEffect,
  useRef,
  KeyboardEventHandler
} from 'react'

import { useDefaultValue } from '../../hooks'

import { Column } from '../column'
import { Row } from '../row'
import { Text } from '../text'
import { TextField } from '../text-field'
import {
  DropdownItemData,
  DropdownItems,
  SearchableDropdownItems,
  PositionDropdown
} from '../dropdown-items'

import { RightCaret } from './right-caret'
import { DropdownProps } from './dropdown.types'
import { useStyle } from './dropdown.styles'

export const Dropdown: FC<DropdownProps> = ({
  className = '',
  containerClassName = '',
  custom,
  label,
  labelTx,
  active,
  preset,
  rightIconName,
  data,
  withSearch = true,
  defaultValue,
  disabled = false,
  value: outerValue,
  onChange
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [value, changeValue] = useState<DropdownItemData | undefined>(
    defaultValue
  )
  const [open, changeOpen] = useState<boolean>(false)
  const [controlled, changeControlled] = useState(true)
  const [list, changeList] = useState<DropdownItemData[]>(data)

  useEffect(() => {
    if (typeof outerValue !== 'undefined') {
      changeControlled(false)
      changeValue(undefined)
    }
  }, [outerValue])

  useEffect(() => {
    changeList(data)
  }, [data])

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [open])

  const Value = useDefaultValue<DropdownItemData | undefined>({
    outerValue,
    value
  })

  const classes = useStyle({
    open,
    custom,
    disabled,
    value: Value
  })

  const disabledInputClass = disabled ? classes.disabled : ''
  const disabledLabelClass = disabled ? classes.disabledLabel : ''
  const labelPreset = withSearch ? 'subscriptionText' : 'h5'

  const handleOnPropagationBackground = (
    event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    event.preventDefault()
  }

  const handleOnChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { value: inputText } = event.target

    if (inputText) {
      const filteredList = data.filter((item) => {
        const formatedString = inputText.toLowerCase()
        return item.name.toString().toLowerCase().startsWith(formatedString)
      })

      changeList(filteredList)
    }
    if (!inputText) {
      changeList(data)
    }
  }

  const handleOnClickInput = useCallback(() => {
    changeList(data)
  }, [data])

  const handleOnChange = (item: DropdownItemData) => {
    if (controlled) {
      changeValue(item)
    }

    if (onChange) {
      onChange(item)
    }
  }

  const handleOnOpen = useCallback(
    (state: boolean) => {
      if (!disabled) {
        changeOpen(state)
      }
    },
    [disabled]
  )

  const handleOnCaretClick = () => {
    handleOnOpen(false)
  }

  const handleOnKeyPress: KeyboardEventHandler<HTMLDivElement> = (event) => {
    const { code } = event
    if (inputRef.current && code !== 'Enter' && code !== 'Tab') {
      inputRef.current.focus()
    }
  }

  return (
    <Column fullWidth onKeyDown={handleOnKeyPress}>
      {!open || !withSearch ? (
        <DropdownItems
          isScrollable
          className={`${disabledInputClass} ${className} ${classes.dropdownItems}`}
          containerClassName={containerClassName}
          disabled={!disabled}
          active={active}
          data={data}
          position={PositionDropdown.BOTTOM}
          onOpen={handleOnOpen}
          onChange={handleOnChange}
        >
          <Row
            className={classes.container}
            fullWidth
            justifyContent="space-between"
          >
            <Column alignItems="flex-start">
              {label && (
                <Text
                  className={`${disabledLabelClass} ${classes.label}`}
                  text={label}
                  tx={labelTx}
                  color="darkBlue"
                  preset={labelPreset}
                />
              )}
              {Value && (
                <Text
                  className={classes.text}
                  text={Value.name}
                  tx={Value.nameTx}
                  preset={preset}
                />
              )}
            </Column>
            <RightCaret
              open={open}
              custom={custom}
              iconName={rightIconName}
              onClick={handleOnCaretClick}
            />
          </Row>
        </DropdownItems>
      ) : (
        <SearchableDropdownItems
          isScrollable
          open
          active={active}
          className={`${disabledInputClass} ${className} ${classes.dropdownItems}`}
          containerClassName={containerClassName}
          disabled={!disabled}
          data={list}
          position={PositionDropdown.BOTTOM}
          onOpen={handleOnOpen}
          onChange={handleOnChange}
        >
          <Row fullWidth onClick={handleOnPropagationBackground}>
            {label && (
              <TextField
                className={classes.input}
                label={label}
                defaultValue={Value?.name || ''}
                preset="border"
                type="text"
                ref={inputRef}
                onChange={handleOnChangeInput}
                onClick={handleOnClickInput}
              />
            )}
          </Row>
        </SearchableDropdownItems>
      )}
    </Column>
  )
}
