import React, { FC } from 'react'

import { getPriceWithSymbol } from '../../../views'

import { Row } from '../../row'
import { Text } from '../../text'

import { OfferListInfoRowProps } from './offers-list-info-row.types'

export const OfferListInfoRow: FC<OfferListInfoRowProps> = ({
  className,
  title,
  currency,
  titleTx,
  price
}) => {
  const text = currency ? getPriceWithSymbol(price, currency) : String(price)

  return (
    <Row
      fullWidth
      justifyContent="space-between"
      alignItems="flex-start"
      className={className}
    >
      <Text color="inactive" preset="body" text={title} tx={titleTx} />
      <Text color="black" preset="h7" text={text} />
    </Row>
  )
}
