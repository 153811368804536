import React, { FC } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'

import { Content, Text, TextPresets } from '../../components'
import { MainRoutes } from '../../routes'

import { NewsList } from './news-list'
import { useStyle } from './latest-news.styles'
import { LatestNewsViewProps } from './latest-news.types'

export const LatestNewsView: FC<LatestNewsViewProps> = ({
  children,
  data,
  totalCount,
  isWrap,
  count,
  onLoadNext,
  onRefresh
}) => {
  const history = useHistory()
  const isPage = history.location.pathname === '/latest-news'
  const classes = useStyle({ page: isPage })

  const titlePreset: TextPresets = isMobileOnly ? 'h1Mobile' : 'h1'

  return (
    <Content className={classes.container} justifyContent="flex-start">
      <Link className={classes.title} to={MainRoutes.LATEST_NEWS}>
        <Text
          className={classes.text}
          text="Latest News"
          tx="header.latestNews"
          preset={titlePreset}
        />
      </Link>
      <Text
        className={classes.description}
        color="inactive"
        preset="h7"
        text="A new collection shot in the warmth of soft evening light. Discover elegant silhouettes, refined details."
        tx="latestNews.description"
      />
      <NewsList
        className={classes.news}
        isWrap={isWrap}
        count={count}
        data={data}
        totalCount={totalCount}
        onLoadNext={onLoadNext}
        onRefresh={onRefresh}
      />
      {children}
    </Content>
  )
}
