import { DropdownItemData, PickerItem } from '../../../components'
import { ProductOrderField, OrderDirection } from '../../../services'

export const sortData: DropdownItemData[] = [
  {
    id: '',
    value: '',
    name: 'Clear',
    nameTx: 'product.sort.clear'
  },
  {
    id: `${ProductOrderField.PRICE},${OrderDirection.ASC}`,
    value: `${ProductOrderField.PRICE},${OrderDirection.ASC}`,
    name: 'Price Low-High',
    nameTx: 'product.sort.price.lowHigh'
  },
  {
    id: `${ProductOrderField.PRICE},${OrderDirection.DESC}`,
    value: `${ProductOrderField.PRICE},${OrderDirection.DESC}`,
    name: 'Price High-Low',
    nameTx: 'product.sort.price.highLow'
  },
  {
    id: `${ProductOrderField.DATE},${OrderDirection.ASC}`,
    value: `${ProductOrderField.DATE},${OrderDirection.ASC}`,
    name: 'Last updated Ascending',
    nameTx: 'product.sort.date.ascending'
  },
  {
    id: `${ProductOrderField.DATE},${OrderDirection.DESC}`,
    value: `${ProductOrderField.DATE},${OrderDirection.DESC}`,
    name: 'Last updated Descending',
    nameTx: 'product.sort.date.descending'
  }
]

export const sortByData: PickerItem[] = [
  {
    id: '',
    text: 'Clear'
  },
  {
    id: `${ProductOrderField.PRICE},${OrderDirection.ASC}`,
    text: 'Price Low-High'
  },
  {
    id: `${ProductOrderField.PRICE},${OrderDirection.DESC}`,
    text: 'Price High-Low'
  },
  {
    id: `${ProductOrderField.DATE},${OrderDirection.ASC}`,
    text: 'Last updated Ascending'
  },
  {
    id: `${ProductOrderField.DATE},${OrderDirection.DESC}`,
    text: 'Last updated Descending'
  }
]
