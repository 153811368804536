import React, { FC } from 'react'

import { useTheme } from 'react-jss'

import { Row } from '../../row'
import { Column } from '../../column'
import { Text } from '../../text'

import { ColorDot } from '../../color-dot'

import { getFormatedOrderDate } from '../../../views'
import { getColorByMainStatus, getMessageByMainStatus } from '../../../pages'
import { LightTheme } from '../../../theme'

import { PurchaseSliderHeaderOpenProps } from '../purchase-slider-header-open'

import { useStyle } from './purchase-slider-header-mobile-open.styles'

export const PurchaseSliderHeaderMobileOpen: FC<
  PurchaseSliderHeaderOpenProps
> = ({
  number,
  country,
  created,
  status,
  totalPrice,
  companyName,
  startDate,
  finishDate
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle()

  const orderIdText = `# ${number}`
  const createdDate = getFormatedOrderDate(startDate)
  const finishedDate = getFormatedOrderDate(finishDate)

  return (
    <Column
      fullWidth
      justifyContent="space-between"
      className={classes.container}
    >
      <Column fullWidth justifyContent="space-between" alignItems="flex-start">
        <Row className={classes.statusRow}>
          <ColorDot
            preset="big"
            color={theme.colors[getColorByMainStatus(status)]}
          />
          <Column className={classes.statusColumn} alignItems="flex-start">
            <Text color="inactive" preset="body" text={orderIdText} />
            <Text
              {...getMessageByMainStatus(status)}
              className={classes.status}
              color="black"
              preset="h7"
            />
          </Column>
        </Row>
        <Row
          className={classes.details}
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Text
            color="inactive"
            preset="body"
            text="Total Price"
            tx="order.item.price.title"
          />
          <Text
            className={classes.status}
            color="black"
            preset="h7"
            text={String(totalPrice)}
          />
        </Row>
        <Row
          className={classes.details}
          alignItems="center"
          justifyContent="space-between"
        >
          <Text color="inactive" preset="body" text="Vendor" />
          <Text
            className={classes.status}
            color="black"
            preset="h7"
            text={companyName}
          />
        </Row>
        {country && (
          <Row
            className={classes.details}
            alignItems="center"
            justifyContent="space-between"
          >
            <Text color="inactive" preset="body" text="Country" />
            <Text
              className={classes.status}
              color="black"
              preset="h7"
              text={country}
            />
          </Row>
        )}
        <Row
          className={classes.details}
          alignItems="center"
          justifyContent="space-between"
        >
          <Text color="inactive" preset="body" text="Date of creation" />
          <Text
            className={classes.status}
            color="black"
            preset="h7"
            text={created}
          />
        </Row>
      </Column>

      <Row fullWidth alignItems="flex-start" justifyContent="space-between">
        <Text
          className={classes.productionText}
          color="inactive"
          preset="body"
          text="Start date of production"
          tx="order.item.product.startDateProd"
        />
        <Text
          className={classes.productionText}
          color="black"
          preset="h7"
          text={createdDate}
        />
      </Row>
      <Row fullWidth alignItems="flex-start" justifyContent="space-between">
        <Text
          className={classes.productionText}
          color="inactive"
          preset="body"
          text="End date of profuction"
          tx="order.item.product.endDateProd"
        />
        <Text
          className={classes.productionText}
          preset="h7"
          text={finishedDate}
        />
      </Row>
    </Column>
  )
}
