import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react'
import { isMobile } from 'react-device-detect'

import {
  Button,
  Column,
  Dropdown,
  DropdownItemData,
  Loader,
  Row,
  Text,
  TextField,
  TextFieldErrorProps
} from '../../../components'
import { useApi, useAuth, useToastify } from '../../../providers'
import { AddressInput, Address, CountryCode } from '../../../services'
import { useValidateDeliveryInfo } from '../../../hooks'

import {
  getDefaultStateShippingAddress,
  ValidateUserPhone,
  ValidateUserZipCode
} from '../../helper'

import { useStyle } from './delivery-info.styles'
import { DeliveryInfoViewProps } from './delivery-info.types'

export const DeliveryInfoView: FC<DeliveryInfoViewProps> = ({
  user: defaultUser
}) => {
  const classes = useStyle()
  const { country } = useApi()
  const [user, changeUser] = useState(defaultUser)
  const { onAccountAddressCreate, onAccountAddressEdit } = useAuth()
  const { open } = useToastify()
  const titlePreset = isMobile ? 'h3Mobile' : 'h3'
  const { data: countriesData } = country.useCountries()
  const { data: countryStatesData, refetch: refetchCountryStates } =
    country.useCountryStatesById({
      filter: {
        country: ''
      }
    })

  const UserCountryCode = useMemo(() => {
    if (user.defaultShippingAddress?.country) {
      const countryCode = user.defaultShippingAddress.country.code
      return countryCode
    }

    return undefined
  }, [user.defaultShippingAddress?.country])

  const handleOnClick = (stateToastify: boolean) => () => {
    if (stateToastify) {
      open({
        text: 'Action is successful.',
        tx: 'toastify.context.text.successful'
      })
    }
  }

  const isDisabled = useValidateDeliveryInfo(user)

  const Countries = useMemo(() => {
    if (countriesData) {
      return countriesData.countries.edges.map((countryItem) => ({
        ...countryItem.node,
        value: countryItem.node.code
      }))
    }

    return []
  }, [countriesData?.countries])

  const UserCountry = useMemo(() => {
    return Countries.find((countryItem) => countryItem.code === UserCountryCode)
  }, [Countries, UserCountryCode])

  useEffect(() => {
    if (UserCountry && UserCountry.id) {
      refetchCountryStates({ filter: { country: UserCountry.id } })
    }
  }, [UserCountry])

  const CountryStates = useMemo(() => {
    if (countryStatesData) {
      return countryStatesData.countryStates.edges.map((countryStateItem) => ({
        ...countryStateItem.node,
        value: countryStateItem.node.id
      }))
    }

    return []
  }, [countryStatesData?.countryStates])

  const UserCountryState = useMemo(() => {
    return CountryStates.find(
      (countryStateItem) =>
        countryStateItem.name === user.defaultShippingAddress?.countryArea
    )
  }, [CountryStates, user.defaultShippingAddress?.countryArea])

  const handleOnChangeCountry = (value: DropdownItemData) => {
    changeUser({
      ...user,
      defaultShippingAddress: {
        ...getDefaultStateShippingAddress,
        ...user.defaultShippingAddress,
        country: {
          country: value.name,
          code: value.value as CountryCode
        },
        countryArea: ''
      }
    })
  }

  const handleOnChangeState = (value: DropdownItemData) => {
    changeUser({
      ...user,
      defaultShippingAddress: {
        ...getDefaultStateShippingAddress,
        ...user.defaultShippingAddress,
        countryArea: String(value.name)
      }
    })
  }

  const handleOnChange =
    (prop: keyof Address) => (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target

      changeUser({
        ...user,
        defaultShippingAddress: {
          ...getDefaultStateShippingAddress,
          ...user.defaultShippingAddress,
          [prop]: value
        }
      })
    }

  const handleOnSubmit = () => {
    const { defaultShippingAddress: defaultAddress } = defaultUser
    const { defaultShippingAddress: address } = user
    const openToastify = handleOnClick(true)
    if (address) {
      const { id, country: nextCountry } = address
      const nextAddress = {
        ...address,
        country: nextCountry?.code,
        isDefaultShippingAddress: undefined,
        isDefaultBillingAddress: undefined,
        __typename: undefined,
        id: undefined
      } as AddressInput

      if (!defaultAddress) {
        if (onAccountAddressCreate) {
          onAccountAddressCreate(nextAddress)
        }
      } else if (onAccountAddressEdit) {
        onAccountAddressEdit(id, nextAddress)
      }
    }
    openToastify()
  }

  const CountriesIsLoaded = Boolean(Countries.length)
  const CountryStatesIsLoaded = Boolean(CountryStates.length)

  const postalCodeError = useMemo<TextFieldErrorProps>(() => {
    const postalCode = user.defaultShippingAddress?.postalCode
    if (!ValidateUserZipCode(user) && postalCode) {
      return {
        error: 'This code is unknown. Please enter a valid zip code.',
        errorTx: 'error.postal.code'
      }
    }

    return {}
  }, [user.defaultShippingAddress?.postalCode])

  const phoneError = useMemo<TextFieldErrorProps>(() => {
    const phone = user.defaultShippingAddress?.phone
    if (!ValidateUserPhone(user) && phone) {
      return {
        error: 'Please enter a valid phone number.',
        errorTx: 'error.phone.number'
      }
    }

    return {}
  }, [user.defaultShippingAddress?.phone])

  if (!user) {
    return (
      <Column fullWidth fullHeight className={classes.deliveryContainer}>
        <Loader />
      </Column>
    )
  }

  return (
    <Column
      fullWidth
      className={classes.deliveryContainer}
      alignItems="flex-start"
    >
      <Row alignItems="baseline">
        <Text text="Delivery Info" tx="delivery.info" preset={titlePreset} />
        <Text
          className={classes.required}
          text="All fields are required!"
          tx="delivery.info.required.fileds"
          preset="h6"
          color="blue"
        />
      </Row>

      <Row
        wrap
        fullWidth
        justifyContent="space-between"
        alignItems="flex-start"
      >
        {CountriesIsLoaded && (
          <Row className={classes.dropdawnRow}>
            <Dropdown
              className={classes.dropdown}
              value={UserCountry}
              label="Country or Region"
              labelTx="delivery.info.country"
              data={Countries}
              preset="body"
              onChange={handleOnChangeCountry}
            />
          </Row>
        )}
        <TextField
          disabled
          className={classes.input}
          label="Country Code"
          labelTx="delivery.info.country.code"
          value={UserCountryCode}
          preset="border"
          type="text"
        />
        {CountryStatesIsLoaded && (
          <Row className={classes.dropdawnRow}>
            <Dropdown
              className={classes.dropdown}
              value={UserCountryState}
              label="State"
              labelTx="delivery.info.state"
              data={CountryStates}
              preset="body"
              onChange={handleOnChangeState}
            />
          </Row>
        )}
        <TextField
          className={classes.input}
          defaultValue={user.defaultShippingAddress?.city}
          label="City"
          labelTx="delivery.info.city"
          preset="border"
          type="text"
          maxLength={30}
          onChange={handleOnChange('city')}
        />
        <TextField
          className={classes.input}
          defaultValue={user.defaultShippingAddress?.streetAddress1}
          label="Address Line"
          labelTx="delivery.info.street"
          preset="border"
          type="text"
          maxLength={50}
          onChange={handleOnChange('streetAddress1')}
        />
        <TextField
          withError
          {...postalCodeError}
          className={`${classes.input} ${classes.inputError}`}
          defaultValue={user.defaultShippingAddress?.postalCode}
          label="Zip Code"
          labelTx="delivery.info.zip.code"
          preset="border"
          type="text"
          maxLength={10}
          onChange={handleOnChange('postalCode')}
        />
        <TextField
          className={classes.input}
          label="First Name"
          labelTx="delivery.info.first.name"
          defaultValue={user.defaultShippingAddress?.firstName}
          preset="border"
          type="text"
          maxLength={30}
          onChange={handleOnChange('firstName')}
        />
        <TextField
          className={classes.input}
          label="Last Name"
          labelTx="delivery.info.last.name"
          defaultValue={user.defaultShippingAddress?.lastName}
          preset="border"
          type="text"
          maxLength={30}
          onChange={handleOnChange('lastName')}
        />
        <TextField
          withError
          isHidePlaceholder
          {...phoneError}
          className={`${classes.input} ${classes.inputError}`}
          label="Phone number"
          labelTx="delivery.info.phone"
          placeholder="+country code XXXXXXXXXX"
          placeholderTx="delivery.info.placeholder.phone"
          defaultValue={user.defaultShippingAddress?.phone}
          preset="border"
          type="text"
          maxLength={20}
          onChange={handleOnChange('phone')}
        />
        <Button
          className={classes.button}
          preset="primary"
          text="SAVE CHANGES"
          textColor="white"
          textPreset="h5"
          tx="delivery.info.profile.button"
          onClick={handleOnSubmit}
          disabled={isDisabled}
        />
      </Row>
    </Column>
  )
}
