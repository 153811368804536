import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  avatars: {
    width: 'calc(100% - 24px)',
    marginTop: 24,
    border: `1px solid ${theme.colors.shadow}`,
    borderRadius: 4
  },
  th: {
    padding: '12px 16px',
    width: 'calc(100% - 32px)',
    '& > *:not(:first-child)': {
      marginLeft: 8
    }
  },
  title: {
    width: 'calc(100% - 24px)'
  },
  row: {
    padding: '12px 24px',
    width: 'calc(100% - 48px)',
    '& > *:not(:first-child):not(:last-child)': {
      marginLeft: 8
    }
  },
  border: {
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.colors.shadow}`
    }
  },
  img: {
    width: 64,
    height: 64,
    objectFit: 'cover'
  },
  nameTh: {
    display: 'flex',
    flex: 4,
    marginLeft: 72
  },
  name: {
    display: 'flex',
    flex: 3
  },
  id: {
    display: 'flex',
    flex: 2
  },
  date: {
    display: 'flex',
    flex: 2
  },
  dateTh: {
    display: 'flex',
    flex: 2
  },
  btnContainer: {
    marginLeft: 8,
    width: 216
  },
  load: {
    padding: 16
  },
  button: {
    width: 537
  },
  icons: {
    maxWidth: 158,
    maxHeight: 56
  },
  play: {
    maxWidth: 158,
    maxHeight: 54,
    marginLeft: 26
  },
  rows: {
    marginTop: 20,
    padding: '0 0 24px 0'
  },
  icon: {
    cursor: 'pointer'
  },
  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    title: {
      width: '100%'
    },
    avatars: {
      width: '100%'
    },
    id: {
      flex: 1.2
    }
  },
  '@media screen and (max-width: 480px)': {
    avatars: {
      width: '100%',
      marginTop: 20
    },
    title: {
      width: '100%'
    },
    row: {
      padding: 16
    },
    button: {
      width: '100%',
      padding: '12px 0'
    },
    load: {
      padding: '16px 0'
    }
  }
}))
