import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  successResetOverlay: {
    position: 'fixed',
    backgroundColor: theme.colors.white,
    height: '100%',
    minHeight: '100vh',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 2
  },
  successResetForm: {
    width: 'calc(100vw - 24px)'
  },

  successResetHeader: {
    display: 'flex',
    alignItems: 'center',
    height: 57,
    borderBottom: `1px solid ${theme.colors.shadow}`,
    width: '100%'
  },
  successResetSvgContainer: {
    display: 'flex',
    margin: '0 12px'
  },
  successResetText: {
    margin: '0 12px'
  },
  successResetSecondaryText: {
    marginTop: '24px',
    textAlign: 'center'
  },
  mainText: {
    fontWeight: 600,
    marginTop: 24,
    textAlign: 'center'
  },
  submit: {
    marginTop: 253
  },
  icon: {
    cursor: 'pointer',
    '&:hover > path': {
      fill: theme.colors.yellow
    }
  },
  container: {
    backgroundSize: '100vw',
    backgroundRepeat: 'space',
    paddingBottom: 64,
    zIndex: 1
  },
  signUp: {
    marginLeft: 4
  },
  signUpContainer: {
    marginTop: 24
  }
}))
