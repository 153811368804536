import { MouseEvent } from 'react'
import { DropdownItemData } from './dropdown-item/dropdown-item.types'

export enum PositionDropdown {
  LEFT = 'left',
  RIGHT = 'right',
  BOTTOM = 'bottom'
}

export type DropdownItemsProps = {
  active?: string | number
  className?: string
  containerClassName?: string
  disabled?: boolean
  position?: PositionDropdown
  data: DropdownItemData[]
  inputValue?: string
  isScrollable?: boolean
  open?: boolean
  onInputValueChange?: (value: React.SetStateAction<string>) => void
  onChange?: (value: DropdownItemData) => void
  onOpen?: (state: boolean) => void
  onClick?: (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => void
}

export interface DropdownItemsStyleProps extends Partial<DropdownItemsProps> {
  width?: number
  height?: number
  listHeight?: number
}

export interface GetListHeightProps {
  data: DropdownItemData[]
}
