import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    maxWidth: 536
  },

  title: {
    marginTop: 24
  },
  name: {
    border: `1px solid ${theme.colors.shadow}`,
    marginTop: 24,
    maxWidth: 536,
    maxHeight: 56
  },
  area: {
    border: `1px solid ${theme.colors.shadow}`,
    width: 'calc(100% - 48px)',
    padding: '4px 24px',
    marginTop: 24
  },
  summaryItem: {
    marginTop: 24,
    background: theme.colors.white,
    width: 'calc(100% - 48px)',
    maxWidth: 488,
    maxHeight: 56,
    border: `1px solid ${theme.colors.shadow}`,
    borderRadius: 4,
    padding: '4px 24px'
  },
  buttonLeft: {
    marginLeft: 24
  },
  buttonRight: {
    marginLeft: 24
  },
  row: {
    maxWidth: 536,
    marginTop: 54,
    backgroundColor: theme.colors.white,
    padding: '24px 16px',
    borderTop: `1px solid ${theme.colors.shadow}`
  },
  '@media screen and (max-width: 480px)': {
    container: {
      width: 'calc(100% - 32px)',
      padding: '32px 16px 36px 16px'
    },
    title: {
      marginTop: 0,
      textAlign: 'center'
    },
    buttonLeft: {
      marginLeft: 0
    },
    row: {
      marginTop: 0,
      width: 'calc(100% - 32px)',
      padding: 16
    }
  }
}))
