import React, { ChangeEvent, FC, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'

import { FeedbackCreateInput } from '../../services/feedback'

import { Column } from '../column'
import { Row } from '../row'
import { Text } from '../text'
import { TextArea } from '../text-area'
import { RatingStarsComponent } from '../rating-stars'
import { ResponsiveMediaView } from '../responsive-media-view'
import { Avatar } from '../avatar'

import { FeedbackVendorItemProps } from './feedback-vendor-item.types'
import { useStyle } from './feedback-vendor-item.styles'

const DEFAULT_FEEDBACK_STATE: FeedbackCreateInput = {
  vendor: '',
  rating: 0,
  comment: ''
}

export const FeedbackVendorItem: FC<FeedbackVendorItemProps> = ({
  className = '',
  companyName,
  countryCode,
  avatar,
  vendorId,
  onChange
}) => {
  const classes = useStyle()
  const titlePreset = isMobileOnly ? 'h3Mobile' : 'h3'
  const Container = isMobileOnly ? Column : Row
  const [feedbackData, changeData] = useState<FeedbackCreateInput>(
    DEFAULT_FEEDBACK_STATE
  )

  const handleOnChange =
    (prop: keyof FeedbackCreateInput) =>
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = event.target
      const nextFeedbackData = {
        ...feedbackData,
        [prop]: value
      }
      changeData(nextFeedbackData)
      if (onChange && vendorId && prop === 'comment' && value) {
        onChange(nextFeedbackData)
      }
    }

  feedbackData.vendor = vendorId

  const handleOnRating = (nextValue: number) => {
    const nextFeedbackData = {
      ...feedbackData,
      rating: nextValue
    }
    changeData(nextFeedbackData)
    if (onChange && vendorId && nextValue) {
      onChange(nextFeedbackData)
    }
  }
  const CompanyName = (
    <Text className={classes.name} text={companyName} preset="h4" />
  )

  const RatingComponentMobile = (
    <Row fullWidth justifyContent="space-between">
      <Text text="Overal Rating" tx="give.feedback.overal.rating" preset="h7" />
      <Row className={classes.rate}>
        <RatingStarsComponent
          defaultRating={feedbackData.rating}
          onChange={handleOnRating}
        />
      </Row>
    </Row>
  )

  const RatingComponent = (
    <>
      <Text text="Overal Rating" tx="give.feedback.overal.rating" preset="h7" />
      <Row className={classes.rate}>
        <RatingStarsComponent
          defaultRating={feedbackData.rating}
          onChange={handleOnRating}
        />
      </Row>
    </>
  )

  return (
    <Column className={`${classes.container} ${className}`}>
      <Row className={classes.content} justifyContent="flex-start">
        <Text
          text="Feedback about the Vendor"
          tx="give.feedback.about.vendor"
          preset={titlePreset}
        />
      </Row>
      <Row className={classes.content}>
        <Row>
          <Row className={classes.image}>
            <Avatar
              alt="user"
              src={avatar}
              name={companyName}
              presetTextPlaceholder="h4"
              radius={64}
            />
          </Row>
          <ResponsiveMediaView
            bigScreen={CompanyName}
            medium={CompanyName}
            tablet={CompanyName}
          />
        </Row>
        <Container className={classes.nameAndFlag}>
          <ResponsiveMediaView mobile={CompanyName} />
          <Row>
            {/* TODO:add flag after deciding how flags will be implemented */}
            {/* <img className={classes.flagImg} src={flag} /> */}
            <Text className={classes.flagName} text={countryCode} preset="h7" />
          </Row>
        </Container>
      </Row>
      <Column className={classes.content} alignItems="flex-start">
        <ResponsiveMediaView mobile={RatingComponentMobile} />
        <ResponsiveMediaView
          bigScreen={RatingComponent}
          medium={RatingComponent}
          tablet={RatingComponent}
        />

        <TextArea
          className={classes.textArea}
          label="Comments"
          labelTx="give.feedback.comments"
          preset="border"
          textAreaInputPreset="body"
          maxLength={500}
          onChange={handleOnChange('comment')}
        />
      </Column>
    </Column>
  )
}
