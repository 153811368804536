import React, { FC } from 'react'

import { Column, ICONS, Icon, Row, Text, Button } from '../../components'
import { useStyle } from './empty-wishlist.styles'
import { EmptyWishlistProps } from './empty-wishlist.types'

export const EmptyWishlist: FC<EmptyWishlistProps> = ({
  headerText,
  headerTx,
  title,
  titleTx,
  text,
  tx,
  onRefresh,
  onGoHome
}) => {
  const classes = useStyle()

  return (
    <Row fullWidth className={classes.container} justifyContent="flex-start">
      {onRefresh ? (
        <Row className={classes.titleIcon} justifyContent="space-between">
          <Text
            className={classes.title}
            text={headerText}
            tx={headerTx}
            preset="h3"
          />
          <Row className={classes.icon}>
            <Icon src={ICONS.refresh} onClick={onRefresh} />
          </Row>
        </Row>
      ) : (
        <Text
          className={classes.title}
          preset="h3"
          text={headerText}
          tx={headerTx}
        />
      )}
      <Column
        className={classes.image}
        alignItems="center"
        justifyContent="center"
      />

      <Column className={classes.text}>
        <Text preset="h4" text={title} tx={titleTx} />
        <Text
          className={classes.subText}
          color="inactive"
          preset="body"
          text={text}
          tx={tx}
        />
        <Button
          className={classes.emptyButton}
          color="yellow"
          preset="primary"
          textColor="white"
          textPreset="h5"
          text="HOME"
          tx="error.404.button.text"
          onClick={onGoHome}
        />
      </Column>
    </Row>
  )
}
