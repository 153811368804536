import React, { FC } from 'react'
import { isMobileOnly } from 'react-device-detect'

import {
  Column,
  NavLink,
  Row,
  Text,
  VendorDetailsItem
} from '../../../components'

import { ProductVendorInfoProps } from './product-vendor-info.types'
import { useStyle } from './product-vendor-info.styles'
import { MainRoutes } from '../../../routes'

export const ProductVendorInfo: FC<ProductVendorInfoProps> = ({
  className = '',
  countryCode,
  id,
  name = '',
  activity,
  averageResponseTime
}) => {
  const classes = useStyle()
  const titlePreset = isMobileOnly ? 'h3Mobile' : 'h3'
  const countryTextPreset = isMobileOnly ? 'h7' : 'h3'

  return (
    <Column
      fullHeight
      fullWidth
      alignItems="flex-start"
      justifyContent="flex-start"
      className={className}
    >
      <Text
        text="Company:"
        tx="vendor.listItem.title"
        preset="h5"
        color="inactive"
      />
      <Row fullWidth justifyContent="space-between">
        <NavLink
          preset={titlePreset}
          text={name}
          to={`${MainRoutes.VENDOR}/${id}`}
        />
        {countryCode && <Text text={countryCode} preset={countryTextPreset} />}
      </Row>
      {/* TODO: use when status change will be implemented */}
      {/* <VendorDetailsItem
        className={classes.status}
        title="Status:"
        titleTx="vendor.listItem.status"
        value={status}
      /> */}
      <VendorDetailsItem
        className={classes.status}
        title="Activity on the site:"
        titleTx="vendor.listItem.activity"
        value={activity}
      />
      <VendorDetailsItem
        className={classes.status}
        title="Average response time:"
        titleTx="vendor.listItem.averageResponseTime"
        value={averageResponseTime}
      />
    </Column>
  )
}
