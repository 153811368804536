import React, { FC, useState, MouseEvent } from 'react'
import { NavLink } from 'react-router-dom'

import { useApi, useAuth, useSignInModal, useToastify } from '../../providers'
import { ProductPreview } from '../../views'

import { Column } from '../column'
import { Icon, ICONS } from '../icon'
import { Row } from '../row'
import { Text } from '../text'
import { ProductPrice } from '../product-price'
import { ColorDot } from '../color-dot'
import { FeaturesDetailsBlock } from '../features-details-block'

import { ProductProps } from './product.types'
import { useStyle } from './product.styles'

export const Product: FC<ProductProps> = ({
  id,
  className = '',
  colors,
  isFavorite: favorite,
  image,
  mini,
  minCurrency,
  totalPrice,
  name,
  isSale,
  productionDaysUpTo,
  brandName,
  countryOfOrigin,
  materialComposition,
  discountMaxPrice,
  categoryBreadcrumb,
  onFavoriteChange
}) => {
  const {
    product: { useAddProductToWishlist, useRemoveProductFromWishlist }
  } = useApi()
  const { onSubmit: onSubmitAddProduct } = useAddProductToWishlist(id)
  const { onSubmit: onSubmitRemoveProduct } = useRemoveProductFromWishlist(id)
  const [isFavorite, changeIsFavorite] = useState(favorite)
  const { isLoggedIn } = useAuth()
  const favoriteIcon = isFavorite ? ICONS.favoriteFilled : ICONS.favorite
  const { changeOpenModal } = useSignInModal()
  const [openProductPreview, changeOpenProductPreview] = useState(false)

  const handleOnOpenProductPreview = (state: boolean) => () => {
    changeOpenProductPreview(state)
  }

  const classes = useStyle({ mini })

  const { open } = useToastify()

  const handleOnClick = (state: boolean) => () => {
    if (state) {
      open({
        text: 'Action is successful.',
        tx: 'toastify.context.text.successful'
      })
    }
  }

  const handleOnFavoriteClick = (event: MouseEvent<HTMLDivElement>) => {
    const openToastify = handleOnClick(true)
    event.preventDefault()
    event.stopPropagation()

    if (!isLoggedIn) {
      if (changeOpenModal) {
        changeOpenModal(true)
      }
    }

    if (isLoggedIn) {
      changeIsFavorite(!isFavorite)

      if (!isFavorite) {
        onSubmitAddProduct()
      } else {
        onSubmitRemoveProduct()
      }

      if (onFavoriteChange) {
        onFavoriteChange(!isFavorite)
      }
    }

    openToastify()
  }

  const to = {
    pathname: `/product/${id}`,
    state: { categoryBreadcrumb }
  }

  return (
    <NavLink className={classes.link} to={to}>
      <Column
        alignItems="flex-start"
        className={`${className} ${classes.container}`}
        justifyContent="flex-start"
      >
        <Column>
          {isSale && (
            <Row className={classes.salePlate}>
              <Text
                text="SALE"
                tx="product.item.card.sale"
                preset="h5"
                color="white"
              />
            </Row>
          )}
          <Row className={classes.imageContainer}>
            <img className={classes.image} src={image} />
          </Row>
          <Column
            className={classes.hoverContainer}
            justifyContent="space-between"
          >
            <div />
            <FeaturesDetailsBlock
              className={classes.hoverDetails}
              productionDaysUpTo={String(productionDaysUpTo)}
              brandName={brandName}
              countryOfOrigin={countryOfOrigin}
              materialComposition={materialComposition}
              onOpenPreview={handleOnOpenProductPreview(true)}
            />
          </Column>
          <Row className={classes.favorite} onClick={handleOnFavoriteClick}>
            <Icon src={favoriteIcon} />
          </Row>
        </Column>
        <Column fullWidth alignItems="flex-start" className={classes.details}>
          <Text
            className={classes.title}
            color="black"
            preset="h6"
            text={name}
          />
          <ProductPrice
            mini={mini}
            isSale={isSale}
            currency={minCurrency}
            totalPrice={totalPrice}
            discountMaxPrice={discountMaxPrice}
          />
          <Row className={classes.colors}>
            {colors.map((color) => (
              <ColorDot
                key={`product_${id}_color_${color.id}`}
                className={classes.color}
                color={color.color.name}
              />
            ))}
          </Row>
        </Column>
        {openProductPreview && (
          <ProductPreview
            id={id}
            open={openProductPreview}
            onClose={handleOnOpenProductPreview(false)}
          />
        )}
      </Column>
    </NavLink>
  )
}
