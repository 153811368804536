import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    width: 568
  },
  column: {
    height: 393
  },
  row: {
    backgroundColor: theme.colors.white,
    width: 'calc(100% - 16px)',
    height: 50,
    padding: 24,
    marginTop: 24,
    borderTop: `1px solid ${theme.colors.shadow}`
  },
  button: {
    width: 120,
    height: 8
  },
  buttonLeft: {
    marginLeft: 24
  },
  buttonRight: {
    marginLeft: 24
  },
  img: {
    width: 64,
    height: 64
  },
  title: {
    marginLeft: 8
  },
  text: {
    marginTop: 24
  },
  itemContainer: {
    border: `1px solid ${theme.colors.shadow}`,
    borderRadius: 8,
    padding: 8,
    width: 'calc(50% - 48px)',
    margin: 8,

    '&:hover': {
      backgroundColor: theme.colors.lightBlue
    }
  },
  item: {
    borderBottom: 'none',
    overflow: 'hidden',
    width: 'calc(100% - 48px)'
  },
  icon: {
    width: 32,
    height: 32
  },
  '@media screen and (max-width: 480px)': {
    container: {
      width: '100%'
    },
    itemContainer: {
      width: '100%',
      margin: 0,
      marginTop: 8
    }
  }
}))
