import {
  DropdownItemData,
  ProductCardType,
  ProductType
} from '../../components'
import {
  FeedbackWithCount,
  GetProduct,
  GetWishlistResults,
  ProductSize,
  User
} from '../../services'
import { getProductTypeFromProduct } from '../../views'
import {
  CurrentDropdownProps,
  GetActiveItemProps,
  GetIsItemWishedProps,
  GetValuesProps
} from './product.types'

export const getCurrentDropdown = ({
  dropdowns,
  value
}: CurrentDropdownProps): DropdownItemData | undefined => {
  if (dropdowns && dropdowns.length) {
    if (value) {
      const current = dropdowns.find(
        (item: DropdownItemData) => item.id === value
      )
      return current
    }
    return dropdowns[0]
  }
  return undefined
}

export const getVendorInfo = (vendor?: User): Partial<User> => {
  const emptyValues = {
    id: '',
    firstName: '-',
    lastName: '-',
    companyName: '-',
    status: '-',
    email: '',
    activity: '-',
    averageResponseTime: '-'
  }

  if (vendor) {
    const {
      id,
      firstName,
      lastName,
      companyName,
      email,
      status,
      activity,
      averageResponseTime
    } = vendor
    const statusFirstUpper = status.charAt(0).toUpperCase()
    const statusSlice = status.slice(1)
    const alignedStatus = `${statusFirstUpper}${statusSlice}`

    return {
      ...emptyValues,
      id,
      firstName,
      lastName,
      companyName,
      status: alignedStatus,
      email,
      activity,
      averageResponseTime
    }
  }

  return emptyValues as User
}

export const getActiveItem = ({ id, order, data }: GetActiveItemProps) => {
  const isOrderCreated = Object.keys(order).length
  const isOrderItem = order[id]

  if (isOrderCreated && isOrderItem) {
    return isOrderItem ? order[id].id : ''
  }

  if (data && data.length) {
    return data[0].id
  }

  return ''
}

export const getIsItemWished = ({
  id,
  wishlist,
  callback
}: GetIsItemWishedProps) => {
  const wishProduct =
    wishlist && wishlist?.find((item: ProductCardType) => item.id === id)
  const isItemWished = Boolean(wishProduct)
  callback(isItemWished)

  return isItemWished
}

export const reduceProductItemToProduct = (data: GetProduct): ProductType => {
  const { product } = data
  return getProductTypeFromProduct(product)
}

export const reduceWishlistToProductWishlist = (
  data: GetWishlistResults
): ProductType[] => {
  return data.me.wishlist.edges.map(({ node }) => {
    const { product } = node

    return getProductTypeFromProduct(product)
  })
}

export const parseSizes = (sizes: ProductSize[]): DropdownItemData[] | [] => {
  if (sizes.length) {
    return sizes.map(({ id, name }: ProductSize) => ({
      id,
      value: id,
      name
    }))
  }

  return []
}

export const getValues = ({ size = [], order }: GetValuesProps) => {
  const sizes = parseSizes(size)
  const activeItem = getActiveItem({ id: 'size', order, data: sizes })
  const isProductSize = Boolean(size?.length)

  return {
    sizes,
    activeItem,
    isProductSize
  }
}
export const getFeedbackAmount = (feedbacks: FeedbackWithCount[]) => {
  const amount = feedbacks?.length
  const feedbacksSum = feedbacks.reduce((acc, { rating }) => {
    return acc + rating
  }, 0)

  return Math.round(feedbacksSum / amount)
}
