import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {
    width: 'calc(100% - 32px)',
    padding: '0 16px 16px 16px'
  },

  row: {
    width: '100%',
    justifyContent: 'space-between',
    marginTop: 12
  },

  buttonBorder: {
    width: 128,
    padding: '12px 10px'
  },
  deliveryButton: {
    marginTop: 16,
    width: '100%',
    padding: '14px 0px'
  },

  staging: {
    marginTop: 24,
    marginLeft: 24
  },

  '@media screen and (max-width: 480px)': {
    container: {
      width: 'calc(100% - 32px)',
      padding: '16px'
    }
  }
})
