import { MutationResult } from '@apollo/client'

import { Product } from '../product'
import { Order } from '../order'
import { User } from '../auth'

import { ListVariables, PaginationList } from '../api.types'

export enum FeedbackErrorCode {
  ALREADY_EXISTS = 'ALREADY_EXISTS',
  GRAPHQL_ERROR = 'GRAPHQL_ERROR',
  INVALID = 'INVALID',
  NOT_FOUND = 'NOT_FOUND',
  REQUIRED = 'REQUIRED',
  UNIQUE = 'UNIQUE'
}

export type FeedbackError = {
  field?: string
  message?: string
  code?: FeedbackErrorCode
}

export type Feedback = {
  id: string
  vendor?: User
  product?: Product
  comment?: string
  rating: number
  answer?: string
}

export type FeedbackCreateInput = {
  vendor?: string
  product?: string
  order?: string
  rating: number
  comment?: string
}

export type FeedbackCreateInputVariables = {
  input: FeedbackCreateInput
}

export type FeedbackFilterInput = {
  isWithComment?: boolean
  isWithoutComment?: boolean
  isWithAnswer?: boolean
  isWithoutAnswer?: boolean
  search?: string
}

export type FeedbackCreateData = {
  feedbackCreate: {
    feedbackErrors: FeedbackError[]
    feedback: Feedback
  }
}

export interface FeedbackVariables extends ListVariables {
  filter?: FeedbackFilterInput
}

export type BasicFeedback = {
  id: string
  createdAt: Date
  comment: string
  answer: string
  rating: number
  type: string
}

export type FullFeedback = {
  id: string
  createdAt: Date
  vendor: User
  user: User
  product: Product
  order: Order
  comment: string
  answer: string
  rating: number
  type: string
}

export interface FeedbackWithCount extends FullFeedback {}

export interface FeedbacksData extends PaginationList<FeedbackWithCount> {
  filters: string
}

export type GetFeedbacksData = {
  feedbacks: FeedbacksData
}

export type GetFeedbacks = {
  data: GetFeedbacksData | null
  loading: boolean
  refetch: (variables?: Partial<FeedbackVariables>) => void
}

export type FeedbackCreate = {
  onSubmit: (variables: FeedbackCreateInputVariables) => void
  response: MutationResult<FeedbackCreateData>
}

export type FeedbackApi = {
  useFeedbacks: (variables: ListVariables) => GetFeedbacks
  useCreateFeedback: () => FeedbackCreate
}
