import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'

import {
  Button,
  Column,
  NavLink,
  Row,
  Text,
  Icon,
  ICONS
} from '../../../components'

import { SuccessPasswordResetProps } from '../success-password-reset.types'
import { useStyle } from './success-password-reset.style'

export const SuccessPasswordResetMobilePage: FC<SuccessPasswordResetProps> = ({
  className = '',
  ...props
}) => {
  const classes = useStyle()
  const history = useHistory()

  const handleOnNavigate = (path: string) => () => {
    history.push(path)
  }

  return (
    <Column justifyContent="start" className={classes.successResetOverlay}>
      <Row
        justifyContent="space-between"
        fullWidth
        className={classes.successResetHeader}
      >
        <Text
          className={classes.successResetText}
          color="black"
          preset="h5"
          text="Reset Password"
        />
        <Row className={classes.successResetSvgContainer}>
          <Icon
            className={classes.icon}
            src={ICONS.close}
            onClick={handleOnNavigate('/')}
          />
        </Row>
      </Row>
      <Column
        {...props}
        className={`${className} ${classes.container}`}
        fullWidth
        justifyContent="center"
      >
        <Column className={classes.successResetForm}>
          <Text
            className={classes.mainText}
            preset="h3"
            text="Successful password reset!"
            tx="password.reset.success.title"
          />
          <Text
            className={classes.successResetSecondaryText}
            color="inactive"
            preset="h7"
            text="You can now use your new password to log in to your account!"
            tx="password.reset.success.text"
          />
          <Button
            className={classes.submit}
            text="LOG IN"
            tx="password.reset.login"
            preset="primary"
            textColor="white"
            textPreset="h5"
            onClick={handleOnNavigate('/sign-in')}
          />
          <Row className={classes.signUpContainer}>
            <Text
              color="black"
              preset="secondTitle"
              text="Don’t have an account?"
            />
            <NavLink
              className={classes.signUp}
              color="blue"
              preset="h6"
              text="Sign Up"
              to="/sign-up"
            />
          </Row>
        </Column>
      </Column>
    </Column>
  )
}
