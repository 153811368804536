import React, { FC, MouseEventHandler } from 'react'

import {
  ColorDot,
  FilterBadge,
  FilterStarRow,
  getCurrencySymbol
} from '../../../components'
import { capitalizedName, priceFilterValue } from '../../helpers'

import {
  CoreItemWithType,
  FilterResultsItemProps
} from './filter-result-item.types'
import { useStyle } from './filter-result-item.styles'

export const FilterResultItem: FC<FilterResultsItemProps> = ({
  id,
  type,
  name,
  currency: defaultCurrency,
  onClick,
  ...props
}) => {
  const currency = getCurrencySymbol(defaultCurrency)
  const classes = useStyle()

  const handleOnClick: MouseEventHandler<HTMLDivElement> = (event) => {
    const coreItemWithType: CoreItemWithType = {
      id,
      type,
      name
    }

    if (onClick) {
      onClick(coreItemWithType, event)
    }
  }

  switch (type) {
    case 'rating':
      return (
        <FilterBadge className={classes.item} onClick={handleOnClick}>
          <FilterStarRow name={name} textColor="darkBlue" textPreset="body" />
        </FilterBadge>
      )
    case 'colors':
      return (
        <FilterBadge
          className={classes.item}
          name={name}
          onClick={handleOnClick}
        >
          <ColorDot color={capitalizedName(name)} preset="productColor" />
        </FilterBadge>
      )
    case 'companyNames':
      return (
        <FilterBadge
          className={classes.item}
          name={name}
          onClick={handleOnClick}
        />
      )
    case 'genders':
      return (
        <FilterBadge
          className={classes.item}
          name={name}
          onClick={handleOnClick}
        />
      )
    case 'materials':
      return (
        <FilterBadge
          className={classes.item}
          name={name}
          onClick={handleOnClick}
        />
      )
    case 'price':
      return (
        <FilterBadge
          className={classes.item}
          name={priceFilterValue(name, currency)}
          onClick={handleOnClick}
        />
      )
    case 'productionDays':
      return (
        <FilterBadge
          className={classes.item}
          name={priceFilterValue(name)}
          onClick={handleOnClick}
        />
      )

    default:
      return <div {...props}></div>
  }
}
