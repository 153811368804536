import React, { VFC } from 'react'

import { Button } from '../../button'
import { Flag } from '../../flag'
import { Row } from '../../row'
import { Text } from '../../text'

import { OrderRow } from '../order-row'

import { useStyle } from './order-contact-info.styles'
import { OrderContactInfoCustomerProps } from './order-contact-info.types'

export const OrderContactInfoCustomer: VFC<OrderContactInfoCustomerProps> = ({
  title,
  titleTx,
  fullAddress,
  fullName,
  country,
  countryFlag,
  phoneNumber,
  email,
  buttonTitle,
  buttonTx,
  onButtonClick
}) => {
  const classes = useStyle()

  return (
    <OrderRow alignItems="center" justifyContent="space-between">
      <Row fullWidth justifyContent="space-between">
        <Text color="darkBlue" preset="h6" text={title} tx={titleTx} />
        <Text
          className={classes.field}
          color="black"
          preset="h7"
          text={fullName}
        />
        <Row className={classes.field}>
          <Flag src={countryFlag} />
          <Text
            className={classes.text}
            color="black"
            preset="h7"
            text={country}
          />
        </Row>
        <Text
          className={classes.field}
          color="black"
          preset="h7"
          text={fullAddress}
        />
        <Text
          className={classes.field}
          color="black"
          preset="h7"
          text={phoneNumber}
        />
        {email && (
          <Text
            className={classes.field}
            color="black"
            preset="h7"
            text={email}
          />
        )}
      </Row>
      <Button
        className={classes.button}
        preset="secondaryBorderMini"
        textColor="yellow"
        textPreset="maxButton"
        text={buttonTitle}
        tx={buttonTx}
        onClick={onButtonClick}
      />
    </OrderRow>
  )
}
