import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  cataloguePage: {
    width: 'calc(100% - 24px)',
    padding: '24px 12px 62px 12px'
  },
  categoryWrapper: {
    width: '100%'
  },
  menuBlock: {
    marginTop: 24,
    border: `1px solid ${theme.colors.shadow}`
  },
  imageBlock: {
    marginTop: 24
  },
  img: {
    objectFit: 'cover',
    height: 351,
    width: 351
  },
  header: {
    width: 'calc(100% - 32px)',
    padding: '16px',
    borderBottom: `1px solid ${theme.colors.shadow}`
  },
  listWrapper: {
    width: 'calc(100% - 32px)'
  }
}))
