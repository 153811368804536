import React, { FC } from 'react'
import { useMediaQuery } from 'react-responsive'

import { ResponsiveMediaViewProps } from './responsive-media-view.types'

export const ResponsiveMediaView: FC<ResponsiveMediaViewProps> = ({
  bigScreen,
  medium,
  tablet,
  mobile
}) => {
  const isMediumScreen = useMediaQuery({
    minWidth: 1224,
    maxWidth: 1719
  })
  const isBigScreen = useMediaQuery({ minWidth: 1720 })
  const isMobile = useMediaQuery({ maxDeviceWidth: 480 })
  const isTablet = useMediaQuery({ minDeviceWidth: 481, maxWidth: 1223 })
  return (
    <>
      {isMediumScreen && (medium || bigScreen)}
      {isBigScreen && bigScreen}
      {isMobile && mobile}
      {isTablet && tablet}
    </>
  )
}
