import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../theme'
import { LatestNewsStyles } from './latest-news.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    marginTop: 42
  },
  description: {
    textAlign: 'center',
    width: 380,
    marginTop: 8
  },
  title: {
    cursor: 'pointer',
    textDecoration: 'none'
  },
  text: {
    '&:hover': {
      color: theme.colors.yellow
    }
  },
  news: {
    display: 'flex',
    justifyContent: 'flex-start',
    overflowX: 'hidden',
    marginTop: 0,
    padding: '24px 0',
    columnGap: 16,
    rowGap: 16
  },
  '@media screen and (min-width: 1224px) and (max-width: 2560px)': {
    container: {
      width: '100%',
      padding: 0
    },
    news: {
      maxWidth: 1132,
      padding: '16px 0',
      marginTop: 16
    }
  },

  '@media screen and (min-width: 1016px) and (max-width: 1223px)': {
    container: {
      width: '100%',
      padding: 0
    },
    news: {
      maxWidth: 984,
      padding: '16px 0',
      marginTop: 16
    }
  },
  '@media screen and (min-width: 768px) and (max-width: 1015px)': {
    container: {
      width: '100%',
      padding: 0
    },
    news: {
      maxWidth: 734,
      padding: '16px 0',
      marginTop: 16
    }
  },
  '@media screen and (max-width: 480px)': {
    container: {
      width: 'calc(100% - 24px)',
      paddingBottom: 80
    },
    description: {
      textAlign: 'center',
      width: '100%',
      marginTop: 24
    },
    news: {
      flexDirection: ({ page }: LatestNewsStyles) => page && 'column',
      marginTop: 24,
      flexWrap: 'nowrap',
      display: 'flex',
      justifyContent: 'flex-start',
      overflowX: 'auto',
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    }
  }
}))
