import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  infoColumn: {
    width: 124,
    rowGap: 4
  },
  item: {
    width: 'fit-content'
  },

  iconRow: {
    columnGap: 8
  },
  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    infoColumn: {
      width: 104
    }
  }
})
