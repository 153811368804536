import { createUseStyles } from 'react-jss'
import { isMobile } from 'react-device-detect'
import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    borderBottom: `1px solid ${theme.colors.shadow}`,
    padding: isMobile ? '16px 12px' : 16,
    width: isMobile ? 'calc(100% - 24px)' : 'calc(100% - 32px)'
  }
}))
