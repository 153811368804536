import { gql } from '@apollo/client'

import {
  VENDOR_USER_SMALL_FRAGMENT,
  PAGE_INFO_FRAGMENT,
  USER_FRAGMENT
} from '../fragments'

export const VENDORS = gql`
  ${PAGE_INFO_FRAGMENT}
  ${VENDOR_USER_SMALL_FRAGMENT}
  query Vendors(
    $filter: VendorFilterInput
    $before: String
    $after: String
    $first: Int!
    $last: Int
  ) {
    vendors(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
    ) {
      edges {
        cursor
        node {
          ...VendorUserSmallFragment
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
      totalCount
    }
  }
`

export const WISHLIST_VENDORS = gql`
  ${USER_FRAGMENT}
  query WishlistVendors(
    $filter: VendorFilterInput
    $before: String
    $after: String
    $first: Int!
    $last: Int
  ) {
    wishlistVendors(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
    ) {
      edges {
        cursor
        node {
          ...UserFragment
        }
      }
      totalCount
    }
  }
`

export const VENDORS_GLOBAL_SEARCH = gql`
  ${PAGE_INFO_FRAGMENT}
  ${VENDOR_USER_SMALL_FRAGMENT}
  query VendorsGlobalSearch(
    $filter: VendorFilterInput
    $before: String
    $after: String
    $first: Int!
    $last: Int
  ) {
    vendorsGlobalSearch(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
    ) {
      edges {
        cursor
        node {
          ...VendorUserSmallFragment
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
      totalCount
    }
  }
`

export const VENDOR_BY_ID = gql`
  ${USER_FRAGMENT}
  query Vendor($id: ID!) {
    vendor(id: $id) {
      ...UserFragment
    }
  }
`
