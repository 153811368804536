import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    background: theme.colors.darkBlue,
    padding: '16px 24px',
    width: 'calc(100% - 48px)'
  },
  text: {
    marginLeft: 4
  },
  auth: {
    cursor: 'pointer',
    marginLeft: 24
  },
  button: {
    cursor: 'pointer',
    marginLeft: 48
  },
  flagTitle: {
    marginLeft: 8
  },
  rightContainer: {
    background: theme.colors.darkBlue,
    width: 'calc(100vw - 61px)',
    height: 48,
    position: 'fixed',
    bottom: 0
  },
  errorIcon: {
    marginLeft: 16
  }
}))
