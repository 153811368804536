import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    padding: '16px 0',
    width: '100%',
    borderBottom: `1px solid ${theme.colors.shadow}`
  },
  imageContainer: {
    width: '40%'
  },
  image: {
    objectFit: 'cover',
    height: 64,
    width: 64
  },
  name: {
    marginLeft: 8,
    overflow: 'hidden'
  },
  text: {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden'
  }
}))
