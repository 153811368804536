import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    border: `1px solid ${theme.colors.shadow}`,
    backgroundColor: theme.colors.vendorProfileFon
  },
  line: {
    marginTop: 16,
    width: 'calc(100% - 32px)',
    padding: 16
  },
  content: {
    borderTop: `1px solid ${theme.colors.shadow}`
  }
}))
