import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {
    marginTop: 80
  },
  productList: {
    flex: 1,
    width: '50%',
    marginLeft: 24,
    marginTop: 0
  },
  imageContainer: {
    flex: 1,
    width: '50%'
  },
  image: {
    objectFit: 'cover',
    display: 'block',
    width: '100%',
    height: 532
  },
  description: {
    width: 380,
    marginTop: 16,
    textAlign: 'center'
  },
  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    imageContainer: {
      width: '45%'
    },
    productList: {
      width: '55%'
    }
  },
  '@media screen and (max-width: 480px)': {
    container: {
      flexDirection: 'column'
    },
    productList: {
      flex: 1,
      width: '100%',
      marginLeft: 0,
      marginTop: 50
    },
    imageContainer: {
      width: '100%'
    },
    image: {
      height: 215
    },
    description: {
      width: '100%',
      marginTop: 24
    }
  }
})
