import React, { FC, useMemo, useState } from 'react'

import { Text } from '../text'
import { Checkbox } from '../checkbox'
import { Column } from '../column'
import { Row } from '../row'

import { CheckboxListProps } from './checkbox-list.types'
import { useStyle } from './checkbox-list.styles'

export const CheckboxList: FC<CheckboxListProps> = ({
  name,
  value,
  defaultValue = [],
  data,
  onChange,
  onChangeItem
}) => {
  const classes = useStyle()
  const [activeIds, changeActiveIds] = useState<string[]>(defaultValue)

  const MainValue = useMemo(() => value || activeIds, [value, activeIds])

  const getChecked = (id: string) =>
    Boolean(MainValue.find((activeId) => activeId === id))

  const handleOnChange = (nextValue: string[]) => {
    changeActiveIds(nextValue)

    if (onChange) {
      onChange(nextValue)
    }

    if (onChangeItem) {
      const nextValueItems = data.filter((item) => {
        const isActiveColor = nextValue.includes(item.id)

        return isActiveColor
      })
      onChangeItem(nextValueItems)
    }
  }

  const handleOnClick = (id: string) => (state: boolean) => {
    const filteredActiveIds = MainValue.filter((item) => item !== id)

    if (state) {
      const nextValue = [...filteredActiveIds, id]
      handleOnChange(nextValue)
    } else {
      handleOnChange(filteredActiveIds)
    }
  }

  return (
    <Column
      fullWidth
      className={classes.container}
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      {data.map((item) => {
        const checked = getChecked(item.id)
        return (
          <Row
            key={`checkbox_${name}_${item.id}`}
            className={classes.item}
            justifyContent="flex-start"
          >
            <Checkbox checked={checked} onChange={handleOnClick(item.id)}>
              <Text
                className={classes.text}
                preset="body"
                text={item.name}
                tx={item.nameTx}
              />
            </Checkbox>
          </Row>
        )
      })}
    </Column>
  )
}
