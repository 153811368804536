import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    backgroundColor: theme.colors.white
  },
  content: {
    width: 'calc(100% - 32px)'
  },
  title: {},
  image: {
    width: 64,
    height: 64,
    objectFit: 'cover'
  },
  col: {
    flex: 3
  },
  secondaryCol: {
    flex: 2
  },
  name: {
    marginLeft: 16
  },
  text: {
    marginTop: 4
  },
  '@media screen and (max-width: 480px)': {
    secondaryCol: {
      width: 'calc(100% - 32px)'
    }
  }
}))
