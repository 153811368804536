import { gql } from '@apollo/client'

import { OFFER_FRAGMENT, ORDER_FRAGMENT } from '../fragments'

export const OFFERS_QUERY = gql`
  ${OFFER_FRAGMENT}
  query Offers(
    $first: Int!
    $last: Int
    $after: String
    $before: String
    $filter: OfferFilterInput
    $sortBy: OfferOrder
  ) {
    offers(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
      sortBy: $sortBy
    ) {
      totalCount
      filters
      edges {
        cursor
        node {
          ...OfferFragment
        }
      }
    }
  }
`

export const OFFERS_BY_ID = gql`
  ${OFFER_FRAGMENT}
  query useOffersById(
    $first: Int!
    $last: Int
    $after: String
    $before: String
    $filter: OfferFilterInput
    $sortBy: OfferOrder
  ) {
    useOffersById(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
      sortBy: $sortBy
    ) {
      totalCount
      filters
      edges {
        cursor
        node {
          ...OfferFragment
        }
      }
    }
  }
`

export const OFFER_UPDATE_MUTATION = gql`
  ${OFFER_FRAGMENT}
  mutation OfferUpdate($id: ID!, $input: OfferCreateInput!) {
    offerUpdate(id: $id, input: $input) {
      offerErrors {
        code
        field
        message
      }
      offer {
        ...OfferFragment
      }
    }
  }
`

export const OFFER_CREATE_MUTATION = gql`
  ${OFFER_FRAGMENT}
  mutation OfferCreate($input: OfferCreateInput!) {
    offerCreate(input: $input) {
      offerErrors {
        code
        field
        message
      }
      offer {
        ...OfferFragment
      }
    }
  }
`

export const OFFER_BY_ID = gql`
  ${OFFER_FRAGMENT}
  query OfferById($id: ID!) {
    offer(id: $id) {
      ...OfferFragment
    }
  }
`

export const CREATE_ORDER_FROM_OFFER_MUTATION = gql`
  ${ORDER_FRAGMENT}
  mutation createOrderFromOffer(
    $offerId: ID!
    $shippingAddress: AddressInput!
  ) {
    createOrderFromOffer(offerId: $offerId, shippingAddress: $shippingAddress) {
      orderErrors {
        code
        field
        message
      }
      order {
        ...OrderFragment
      }
    }
  }
`

export const CREATE_ORDER_FROM_OFFER_LIST_MUTATION = gql`
  ${ORDER_FRAGMENT}
  mutation createOrderFromOfferList(
    $offerIds: [ID]!
    $shippingAddress: AddressInput!
  ) {
    createOrderFromOfferList(
      offerIds: $offerIds
      shippingAddress: $shippingAddress
    ) {
      orderErrors {
        code
        field
        message
      }
      orders {
        ...OrderFragment
      }
    }
  }
`
