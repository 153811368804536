import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    maxWidth: 1124,
    width: 'calc(100% - 48px)',
    padding: '16px 24px',
    borderBottom: `1px solid ${theme.colors.shadow}`,
    '&:last-child': {
      borderBottom: 'none'
    }
  },
  item: {
    flex: 7
  },
  checkbox: {
    width: 48
  },
  img: {
    width: 64,
    height: 64,
    objectFit: 'cover',
    marginRight: 8
  },
  nameText: {
    marginLeft: 8
  },
  observeOptionsBtn: {
    marginTop: 6,
    padding: '8px 6px'
  },
  infoRow: {
    marginTop: 24,
    paddingLeft: 48,
    width: 'calc(100% - 48px)'
  },
  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    item: {
      flex: 5
    }
  },
  '@media screen and (max-width: 480px)': {
    container: {
      width: 'calc(100% - 40px)',
      padding: '16px 20px'
    },
    checkbox: {
      width: 42
    },
    img: {
      width: 104,
      height: 104,
      marginRight: 0
    }
  }
}))
