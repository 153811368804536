export const MAIN_MENU_SUB_ITEM_FRAGMENT = `
  fragment MainMenuSubItemFragment on MenuItem {
    id
    name
    category {
      id
      name
      description
      backgroundImage {
        url
        alt
      }
    }
    url
    collection {
      id
      name
    }
    page {
      slug
    }
  }
`

export const SECONDARY_MENU_SUB_ITEM_FRAGMENT = `
  fragment SecondaryMenuSubItemFragment on MenuItem {
    id
    name
    category {
      id
      name
    }
    url
    collection {
      id
      name
    }
    page {
      slug
    }
  }
`

export const BANNER_FRAGMENT = `
  fragment BannerFragment on Banner {
    id
    image
    mobileImage
    kind
    isButtonActive
    isNotificationActive
    buttonType
    buttonText
    buttonLink
    mainHeading
    secondaryHeading
    title
    description
    mainDescription
    secondaryDescription
    startDate
    endDate
    isVisible
    notificationMessageText
    notificationButtonText
    notificationButtonLink
  }
`

export const PROMOTION_BANNER_FRAGMENT = `
fragment PromotionBammerFragment on PromotionBanner {
  id
  name
  title
  subtitle
  buttonText
  buttonLink
  startDate
  endDate
  isVisible
  image
  mobileImage
}
`
