import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    padding: '24px 24px 120px 24px',
    width: 407,
    minWidth: 407,
    overflowY: 'scroll'
  },
  filterType: {
    marginTop: 16
  },
  input: {
    flex: 1
  },
  slider: {
    border: 'none',
    borderBottom: `1px solid ${theme.colors.shadow}`
  },
  sliderContent: {
    border: 'none',
    width: '100%'
  },
  sliderTitle: {
    width: '100%',
    padding: '16px 0',
    marginTop: 16
  },
  starRow: {
    '& > div:not(:first-child)': {
      marginTop: 16
    }
  },
  '@media screen and (max-width: 480px)': {
    container: {
      width: 'calc(100% - 24px)',
      minWidth: 'calc(100% - 24px)',
      padding: '12px 12px 120px 12px'
    }
  }
}))
