import React, { FC } from 'react'
import { isMobile, isMobileOnly } from 'react-device-detect'

import { OfferStatus } from '../../../services'

import { Button } from '../../button'
import { Column } from '../../column'
import { Text } from '../../text'
import { Row } from '../../row'

import { OfferListInfoColumn } from '../offers-list-info-column'

import { useStyle } from './offers-list-item-line.styles'
import { OfferListItemLineProps } from './offers-list-item-line.types'

export const OfferListItemLine: FC<OfferListItemLineProps> = ({
  className = '',
  currency,
  productTotalPrice,
  deliveryPrice,
  productionDays,
  discount,
  product,
  quantity,
  status,
  isDeclined,
  onOpenDeclineModal,
  onOpenDeleteModal,
  onContactVendor
}) => {
  const classes = useStyle()
  const Container = isMobileOnly ? Column : Row

  const deliveryTx = isMobile
    ? 'order.line.delivery.price.mobile'
    : 'order.line.delivery.price'
  const isDeletedByCustomer = status === OfferStatus.DELETED_BY_CUSTOMER

  const offerPrice = productTotalPrice - discount
  const offerProductionDays = productionDays !== null ? productionDays : 0
  const deliveryProductPrice = deliveryPrice !== null ? deliveryPrice : 0
  const imgUrl = product?.thumbnail2x.url

  const handleOnOpenDeclineModal = () => {
    if (onOpenDeclineModal) {
      onOpenDeclineModal()
    }
  }

  const handleOnOpenDeleteModal = () => {
    if (onOpenDeleteModal) {
      onOpenDeleteModal()
    }
  }

  return (
    <Column fullWidth className={className}>
      <Container
        fullWidth
        className={classes.line}
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Row className={classes.itemRow} alignItems="flex-start">
          <img
            className={classes.productAvatar}
            height="64px"
            width="64px"
            src={imgUrl}
          />
          <Row fullWidth alignItems="flex-start" justifyContent="space-between">
            <Column alignItems="normal" className={classes.title}>
              <Text
                className={classes.text}
                color="black"
                preset="h5"
                text={product.name}
              />
            </Column>
          </Row>
        </Row>
        <Row className={classes.infoContainer}>
          <Row
            fullWidth
            justifyContent="space-between"
            alignItems="flex-start"
            className={classes.infoRow}
          >
            <OfferListInfoColumn
              className={classes.column}
              title="Production Days:"
              titleTx="offer.line.production.days"
              price={offerProductionDays}
            />
            <OfferListInfoColumn
              className={classes.column}
              title="Item Quantity:"
              titleTx="offer.line.units.quantity"
              price={quantity}
            />
            <OfferListInfoColumn
              className={classes.column}
              title="Offer Price:"
              titleTx="offer.line.offer.price"
              price={offerPrice}
              currency={currency}
            />
            <OfferListInfoColumn
              className={classes.column}
              title="Delivery Price"
              titleTx={deliveryTx}
              price={deliveryProductPrice}
              currency={currency}
            />
          </Row>
          <Row className={classes.buttonsRow} justifyContent="flex-end">
            {isDeclined ? (
              <Button
                className={classes.buttonDecline}
                color="darkBlue"
                textColor="darkBlue"
                textPreset="maxButton"
                preset="secondaryBorderMini"
                text="Delete Offer"
                tx="offers.delete.offer"
                onClick={handleOnOpenDeleteModal}
              />
            ) : (
              <Button
                disabled={isDeletedByCustomer}
                className={classes.buttonDecline}
                color="darkBlue"
                textColor="darkBlue"
                textPreset="maxButton"
                preset="secondaryBorderMini"
                text="Decline Offer"
                tx="offers.decline.offer"
                onClick={handleOnOpenDeclineModal}
              />
            )}
            <Button
              className={classes.buttonContact}
              textColor="yellow"
              textPreset="maxButton"
              preset="borderButton"
              text="Contact Maker"
              tx="offers.contact.maker"
              onClick={onContactVendor}
            />
          </Row>
          <Row className={classes.emptyRow} />
        </Row>
      </Container>
    </Column>
  )
}
