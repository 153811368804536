import React, { FC, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { isMobile } from 'react-device-detect'

import { Icon } from '../icon'
import { Text } from '../text'
import { Dot } from './dot'

import { useStyle } from './radio-item.styles'
import { RadioItemProps } from './radio-item.types'
import { Row } from '../row'

export const RadioItem: FC<RadioItemProps> = ({
  active,
  className = '',
  name,
  src,
  text,
  tx,
  onClick
}) => {
  const classes = useStyle()
  const history = useHistory()

  const textPreset = useMemo(() => {
    if (history.location.pathname.includes('/select-items')) {
      return isMobile ? 'secondTitle' : 'h6'
    }
    return 'body'
  }, [history.location.pathname])

  return (
    <label className={classes.container} onClick={onClick}>
      <input
        className={classes.input}
        defaultChecked={active}
        value={text}
        name={name}
        type="radio"
      />
      <Row fullWidth className={className}>
        <Dot active={active} />
        {src && <Icon className={classes.icon} src={src} />}
        <Text
          className={classes.text}
          color="black"
          preset={textPreset}
          text={text}
          tx={tx}
        />
      </Row>
    </label>
  )
}
