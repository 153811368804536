import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  svgContainer: {
    width: 'auto'
  },
  icon: {
    cursor: 'pointer',
    width: 24,
    height: 24
  },
  listGroup: {
    padding: 16,
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.colors.shadow}`
    }
  },
  listItem: {
    paddingTop: '12px'
  }
}))
