import { createUseStyles } from 'react-jss'
import { isMobile } from 'react-device-detect'

import background from '../../assets/images/background-pattern.png'

export const useStyle = createUseStyles({
  container: {
    backgroundSize: '100vw',
    backgroundRepeat: 'space',
    backgroundImage: `url(${background})`,
    paddingBottom: 24,
    zIndex: 1
  },
  loginForm: {
    marginTop: isMobile ? 24 : 64,
    width: 536
  },
  error: {},
  '@media screen and (max-width: 480px)': {
    loginForm: {
      padding: '0 12px',
      width: 'calc(100% - 24px)'
    },
    error: {
      position: 'relative',
      marginTop: 12
    }
  }
})
