import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

import { PromotionCarouselDotStyle } from './promotion-carousel-dot.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  dot: {
    borderRadius: 8,
    backgroundColor: ({ active }: PromotionCarouselDotStyle) =>
      active ? theme.colors.yellow : theme.colors.shadow,
    height: 8,
    width: 8
  }
}))
