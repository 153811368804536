import React, { FC, useRef, useState } from 'react'

import { useOutsideAlert } from '../../hooks'
import { Column } from '../column'
import { Row } from '../row'

import { ItemsList } from './items-list'
import { getListHeight } from './helpers'
import { DropdownItemsProps, PositionDropdown } from './dropdown-items.types'
import { useStyle } from './dropdown-items.styles'

export const DropdownItems: FC<DropdownItemsProps> = ({
  className,
  containerClassName,
  position = PositionDropdown.BOTTOM,
  data,
  active,
  disabled,
  children,
  isScrollable = false,
  onChange,
  onOpen
}) => {
  const [open, changeOpen] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const itemsRef = useRef<HTMLButtonElement>(null)
  const itemsScrollRef = useRef<HTMLDivElement>(null)

  const width = ref.current ? ref.current.offsetWidth : 0
  const height = ref.current ? ref.current.offsetHeight : 0

  const listHeight = getListHeight({ data })
  const classes = useStyle({
    disabled,
    width,
    height,
    listHeight
  })

  const handleOnOpen = (state?: boolean) => () => {
    if (!disabled) {
      return
    }

    if (typeof state === 'boolean') {
      changeOpen(state)
      if (onOpen) {
        onOpen(state)
      }
    } else {
      changeOpen(!open)
      if (onOpen) {
        onOpen(!open)
      }
    }
  }

  useOutsideAlert(itemsRef, open, handleOnOpen(false))
  const scrollableRef = isScrollable ? itemsScrollRef : null

  return (
    <button
      className={classes.container}
      ref={itemsRef}
      onClick={handleOnOpen()}
    >
      <Row fullWidth ref={ref}>
        {children}
      </Row>
      {open &&
        (!isScrollable ? (
          <ItemsList
            className={className}
            open={open}
            active={active}
            data={data}
            width={width}
            height={height}
            position={position}
            isScrollable={isScrollable}
            onChange={onChange}
            onOpen={handleOnOpen}
          />
        ) : (
          <Column
            fullWidth
            justifyContent="flex-start"
            ref={scrollableRef}
            className={`${containerClassName} ${classes.listContainer}`}
          >
            <ItemsList
              className={className}
              open={open}
              active={active}
              data={data}
              width={width}
              height={height}
              position={position}
              isScrollable={isScrollable}
              onChange={onChange}
              onOpen={handleOnOpen}
            />
          </Column>
        ))}
    </button>
  )
}
