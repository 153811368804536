import { gql } from '@apollo/client'

import {
  ORDER_ERROR_FRAGMENT,
  ORDER_FRAGMENT,
  PAGE_INFO_FRAGMENT
} from '../fragments'

export const DRAFT_ORDER_CREATE = gql`
  ${ORDER_FRAGMENT}
  mutation DraftOrderCreate(
    $lines: [OrderLineCreateInput]
    $shippingAddress: AddressInput
  ) {
    draftOrderCreate(
      input: { lines: $lines, shippingAddress: $shippingAddress }
    ) {
      orderErrors {
        field
        message
        code
        orderLine
      }
      order {
        ...OrderFragment
      }
    }
  }
`

export const ORDER_BY_ID_QUERY = gql`
  ${ORDER_FRAGMENT}
  query OrderById($id: ID!) {
    order(id: $id) {
      ...OrderFragment
    }
  }
`
export const ORDERS_QUERY = gql`
  ${ORDER_FRAGMENT}
  query Orders(
    $first: Int!
    $last: Int
    $after: String
    $before: String
    $filter: OrderFilterInput
  ) {
    orders(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
    ) {
      totalCount
      edges {
        cursor
        node {
          ...OrderFragment
        }
      }
    }
  }
`

export const ORDER_HISTORY_QUERY = gql`
  ${ORDER_FRAGMENT}
  ${PAGE_INFO_FRAGMENT}
  query OrderHistory(
    $first: Int!
    $last: Int
    $after: String
    $before: String
    $sortBy: OrderSortingInput
    $filter: OrderDraftFilterInput
    $created: ReportingPeriod
  ) {
    orderHistory(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
      sortBy: $sortBy
      created: $created
    ) {
      totalCount
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        cursor
        node {
          ...OrderFragment
        }
      }
    }
  }
`

export const BILLING_QUERY = gql`
  ${ORDER_FRAGMENT}
  query Billing($first: Int!) {
    billing(first: $first) {
      totalCount
      edges {
        cursor
        node {
          ...OrderFragment
        }
      }
    }
  }
`

export const ORDER_UPDATE_MUTATION = gql`
  ${ORDER_FRAGMENT}
  ${ORDER_ERROR_FRAGMENT}
  mutation OrderUpdate($id: ID!, $input: OrderUpdateInput!) {
    orderUpdate(id: $id, input: $input) {
      orderErrors {
        ...OrderErrorFragment
      }
      order {
        ...OrderFragment
      }
    }
  }
`

export const RETURN_EXCHANGE_MUTATION = gql`
  mutation returnExchange(
    $orderId: ID!
    $message: String!
    $products: [ProductReturnExchangeType]!
  ) {
    returnExchange(orderId: $orderId, message: $message, products: $products) {
      status
    }
  }
`

export const DRAFT_ORDER_BULK_CREATE_MUTATION = gql`
  ${ORDER_FRAGMENT}
  mutation draftOrderBulkCreate($input: [DraftOrderCreateInput]!) {
    draftOrderBulkCreate(input: $input) {
      orders {
        ...OrderFragment
      }
      orderErrors {
        field
        message
        code
        orderLine
      }
    }
  }
`
export const ORDERS_RETURN_QUERY = gql`
  ${ORDER_FRAGMENT}
  query OrdersToReturn(
    $first: Int!
    $last: Int
    $after: String
    $before: String
  ) {
    me {
      ordersToReturn(
        first: $first
        last: $last
        after: $after
        before: $before
      ) {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          cursor
          node {
            ...OrderFragment
          }
        }
      }
    }
  }
`
