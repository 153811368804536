import React, { useState, useEffect, useMemo } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useParams, useHistory } from 'react-router-dom'

import { Order } from '../../services'
import {
  Button,
  Column,
  Text,
  GoBackContainer,
  FooterAdditionalLinks,
  arrayHasElements
} from '../../components'
import { ConfirmationModal, ReturnModal } from '../../views'
import { useApi } from '../../providers'

import { SelectItemTable } from './select-item-table'
import { useStyle } from './select-items.styles'
import {
  SelectItemsTypeParams,
  OrderLineReturnExchange,
  ActionType
} from './select-items.types'

export const SelectItems = () => {
  const { id } = useParams<SelectItemsTypeParams>()
  const { order } = useApi()
  const history = useHistory()
  const { data: orderData } = order.useOrderById({ id })
  const { onSubmit } = order.useReturnExchange()
  const [openReturn, changeOpenReturn] = useState(false)
  const [openConfirm, changeOpenConfirm] = useState(false)
  const [data, changeData] = useState<Order>()
  const [lines, changeLines] = useState<OrderLineReturnExchange[]>([])
  const [message, changeMessage] = useState<string>('')
  const [checkedIds, changeCheckedIds] = useState<string[]>([])

  const classes = useStyle()
  const h1Preset = isMobileOnly ? 'h1Mobile' : 'h1'

  useEffect(() => {
    if (orderData) {
      const nextData = orderData.order
      changeData({ ...nextData })
      const nextLines = nextData.lines.map((obj) => ({
        ...obj,
        return: false,
        exchange: false
      }))
      changeLines(nextLines)
    }
  }, [orderData])

  const handleOnBack = () => history.goBack()
  const handleOnOpen = (state: boolean) => () => {
    changeOpenReturn(state)
  }
  const handleOnOpenConfirm = (state: boolean) => () => {
    changeOpenConfirm(state)
  }

  const handleOnToShare = () => {
    const openModal = handleOnOpen(true)
    openModal()
  }
  const handleOnSuccess = () => {
    const closeReturn = handleOnOpen(false)
    const openModalConfirm = handleOnOpenConfirm(true)

    const products = lines.map((item) => {
      return {
        productId: item.product.id,
        action: item.exchange ? ActionType.EXCHANGE : ActionType.RETURN
      }
    })

    const newReturnExchangeRequest = {
      orderId: id,
      message,
      products
    }
    onSubmit(newReturnExchangeRequest)
    closeReturn()
    openModalConfirm()
    setTimeout(() => {
      history.push(FooterAdditionalLinks.RETURNS)
    }, 3000)
  }

  const handleReturnItem = (item: string, state: boolean) => {
    const filteredIds = checkedIds.filter((removeId) => removeId !== item)
    if (state) {
      changeCheckedIds([...filteredIds, item])
    } else {
      changeCheckedIds(filteredIds)
    }
  }

  const handleReturnAll = (state: boolean) => {
    if (state) {
      const nextLines = lines?.map((obj) => obj.product.id)
      changeCheckedIds(nextLines)
    } else {
      changeCheckedIds([])
    }
  }

  const CheckedAll = useMemo(() => {
    return checkedIds.length === lines.length
  }, [checkedIds, lines])

  return (
    <Column className={classes.selectPage}>
      <GoBackContainer
        text="Back to Return"
        textTx="select.items.back.to.return.exchange"
        onClick={handleOnBack}
      />
      <Column fullWidth className={classes.container}>
        <Column>
          <Text
            className={classes.title}
            text="Select Items"
            tx="select.items.page.title"
            preset={h1Preset}
          />
          <Text
            className={classes.text}
            preset="h5"
            text="What item would you like to return?"
            tx="select.items.page.second.title"
          />
        </Column>
        {data && (
          <Column fullWidth className={classes.table}>
            <SelectItemTable
              data={data}
              lines={lines}
              returnAllState={CheckedAll}
              onReturnAll={handleReturnAll}
              onReturnItem={handleReturnItem}
              checkedIds={checkedIds}
            />
          </Column>
        )}
        <Button
          className={classes.button}
          text="Confirm"
          textPreset="h5"
          preset="primary"
          textColor="white"
          onClick={handleOnToShare}
          tx="select.items.page.button"
          disabled={!arrayHasElements(checkedIds)}
        />
        <ReturnModal
          orderNumber={orderData?.order.number ?? ''}
          vendor={orderData?.order.vendor.companyName ?? ''}
          open={openReturn}
          message={message}
          handleMessage={changeMessage}
          onClose={handleOnOpen(false)}
          onSubmit={handleOnSuccess}
        />
        <ConfirmationModal
          title="All right!"
          txTitle="confirmation.second.title.text"
          text="We sent you confirmation message. Check your mailbox."
          tx="confirmation.second.text"
          open={openConfirm}
          onClose={handleOnOpenConfirm(false)}
        />
      </Column>
    </Column>
  )
}
