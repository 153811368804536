import { gql } from '@apollo/client'

import { NEWS_FRAGMENT } from '../fragments'

export const LATEST_NEWS_LIST = gql`
  ${NEWS_FRAGMENT}
  query LatestNewsList(
    $before: String
    $after: String
    $first: Int!
    $last: Int
  ) {
    latestNewsList(
      first: $first
      last: $last
      after: $after
      before: $before
      sortBy: { direction: DESC, field: NAME }
    ) {
      edges {
        cursor
        node {
          ...NewsFragment
        }
      }
      totalCount
    }
  }
`

export const LATEST_NEWS_BY_ID = gql`
  ${NEWS_FRAGMENT}
  query LatestNews($id: ID!) {
    latestNews(id: $id) {
      ...NewsFragment
    }
  }
`
