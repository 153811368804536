import * as React from 'react'
import { useHistory } from 'react-router-dom'

import { Row } from '../row'
import { ResponsiveMediaView } from '../responsive-media-view'

import { useStyle } from './footer.styles'
import { Nav } from './nav'
import { NavChatTablet } from './nav-chat-tablet'

export const Footer = React.forwardRef<HTMLDivElement>((_, ref) => {
  const classes = useStyle()
  const history = useHistory()

  const isChat = history.location.pathname.includes('/vendor-communication')
  return (
    <Row fullWidth className={classes.footer} id="footer" ref={ref}>
      <ResponsiveMediaView
        bigScreen={<Nav />}
        medium={<Nav />}
        tablet={isChat ? <NavChatTablet /> : <Nav />}
      />
    </Row>
  )
})
