import React, { FC, useState } from 'react'

import { OfferStatus, OfferUpdateVariables } from '../../services'
import { useAuth, useBasket, useToastify } from '../../providers'
import {
  DeclineOfferModal,
  DeclineOfferModalProps,
  DeleteOfferModal,
  DeleteOfferModalProps,
  getPriceWithSymbol
} from '../../views'

import { Column } from '../column'
import { Row } from '../row'

import { useStyle } from './offers-list-item-mobile.styles'
import { OfferListItemMobileProps } from './offers-list-item.types'
import {
  OfferListItemHeader,
  OfferListItemMainHeader
} from './offers-list-item-header'
import { OfferListItemLine } from './offers-list-item-line'
import { getCurrencyByProduct, zeroPad } from '../helpers'
import { MenuSlider } from '../menu-slider'
import { OfferListItemVariantLine } from './offers-list-item-variant-line'
import { OfferListButtonRow } from './offers-list-button-row'

export const OfferListItemMobile: FC<OfferListItemMobileProps> = ({
  id,
  offer,
  onChangeStatus,
  onContactVendor
}) => {
  const classes = useStyle()
  const { open } = useToastify()
  const { onAddOfferId, onRemoveOfferId } = useBasket()
  const { onUpdateOfferTotalCount } = useAuth()
  const [openDecline, changeOpenDecline] = useState(false)
  const [openDelete, changeOpenDelete] = useState(false)

  const isNew = offer.status === OfferStatus.NEW
  const isAccepted = offer.status === OfferStatus.ACCEPTED

  const currency = getCurrencyByProduct(offer.product)
  const totalOfferPrice = currency
    ? getPriceWithSymbol(offer.totalPrice, currency)
    : String(offer.totalPrice)

  const isDeclined =
    offer.status === OfferStatus.DECLINED_BY_CUSTOMER ||
    offer.status === OfferStatus.DECLINED_BY_VENDOR

  const deliveryProductPrice =
    offer.product.deliveryPrice !== null ? offer.product.deliveryPrice.price : 0

  const handleOnChangeDeclineModal =
    (state: boolean): DeclineOfferModalProps['onClose'] =>
    (event) => {
      event.preventDefault()
      event.stopPropagation()

      changeOpenDecline(state)
    }

  const handleOnChangeDeleteModal =
    (state: boolean): DeleteOfferModalProps['onClose'] =>
    (event) => {
      event.preventDefault()
      event.stopPropagation()

      changeOpenDelete(state)
    }

  const handleOnOpenDeclineModal = () => {
    changeOpenDecline(true)
  }
  const handleOnOpenDeleteModal = () => {
    changeOpenDelete(true)
  }

  const handleOnAcceptOffer = () => {
    open({
      text: 'Success! the offer was accepted the Item was moved to the Cart',
      tx: 'offerUpdate.status.accepted.message'
    })

    const variables: OfferUpdateVariables = {
      id,
      input: {
        status: OfferStatus.ACCEPTED
      }
    }
    if (onChangeStatus) {
      onChangeStatus(variables)
    }
    if (onUpdateOfferTotalCount && isNew) {
      onUpdateOfferTotalCount(-1)
    }

    if (onAddOfferId) {
      onAddOfferId(offer.id)
    }
  }

  const handleOnReactivate = () => {
    const variables: OfferUpdateVariables = {
      id,
      input: {
        status: OfferStatus.NEW
      }
    }
    if (onUpdateOfferTotalCount) {
      onUpdateOfferTotalCount(+1)
    }

    if (onChangeStatus) {
      onChangeStatus(variables)
    }
  }

  const handleOnDecline = () => {
    const variables: OfferUpdateVariables = {
      id,
      input: {
        status: OfferStatus.DECLINED_BY_CUSTOMER
      }
    }
    if (onUpdateOfferTotalCount && isNew) {
      onUpdateOfferTotalCount(-1)
    }

    if (onChangeStatus) {
      onChangeStatus(variables)
    }
    if (onRemoveOfferId && isAccepted) {
      onRemoveOfferId(offer.id)
    }

    changeOpenDecline(false)
  }

  const handleOnDelete = () => {
    const deleteStatus =
      offer.status === OfferStatus.DECLINED_BY_VENDOR
        ? OfferStatus.DECLINED
        : OfferStatus.DELETED_BY_CUSTOMER
    const variables: OfferUpdateVariables = {
      id,
      input: {
        status: deleteStatus
      }
    }

    if (onChangeStatus) {
      onChangeStatus(variables)
    }

    changeOpenDelete(false)
  }

  const Header = (
    <OfferListItemHeader
      status={offer.status}
      totalPrice={totalOfferPrice}
      offerImage={offer.product.thumbnail2x.url}
      isDeclined={isDeclined}
      orderNumber={offer.number}
      companyName={offer.vendor.companyName}
      productName={offer.product.name}
      onAcceptOffer={handleOnAcceptOffer}
      onReactivate={handleOnReactivate}
    />
  )
  const MainHeader = (
    <OfferListItemMainHeader
      status={offer.status}
      isDeclined={isDeclined}
      orderNumber={offer.number}
      companyName={offer.vendor.companyName}
      totalPrice={totalOfferPrice}
      onAcceptOffer={handleOnAcceptOffer}
      onReactivate={handleOnReactivate}
    />
  )

  return (
    <Column fullWidth className={classes.container}>
      <Row fullWidth className={classes.mainHeader}>
        {MainHeader}
      </Row>
      <MenuSlider
        className={classes.containerSlider}
        titleClassName={classes.titleSlider}
        contentClassName={classes.contentSlider}
        header={Header}
      >
        {offer.items.map((item, index) => {
          const initialIndex = index + 1
          const itemNumber = zeroPad(initialIndex, 2)

          return (
            <OfferListItemVariantLine
              key={`offerLine_${item.id}_${index}`}
              id={id}
              offer={offer}
              count={itemNumber}
              item={item}
              deliveryPrice={deliveryProductPrice}
              isDeclined={isDeclined}
              onOpenDeclineModal={handleOnOpenDeclineModal}
              onOpenDeleteModal={handleOnOpenDeleteModal}
              onContactVendor={onContactVendor}
            />
          )
        })}
        <OfferListItemLine
          id={id}
          offer={offer}
          deliveryPrice={deliveryProductPrice}
          isDeclined={isDeclined}
          onOpenDeclineModal={handleOnOpenDeclineModal}
          onOpenDeleteModal={handleOnOpenDeleteModal}
          onContactVendor={onContactVendor}
        />
        <DeclineOfferModal
          open={openDecline}
          description="Are you sure you want to decline this Offer?"
          descriptionTx="decline.offer.description"
          onClose={handleOnChangeDeclineModal(false)}
          onSubmit={handleOnDecline}
        />
        <DeleteOfferModal
          open={openDelete}
          description="Are you sure you want to delete this Offer?"
          descriptionTx="delete.offer.description"
          onClose={handleOnChangeDeleteModal(false)}
          onSubmit={handleOnDelete}
        />
      </MenuSlider>
      <OfferListButtonRow
        id={id}
        offer={offer}
        isDeclined={isDeclined}
        onOpenDeclineModal={handleOnOpenDeclineModal}
        onOpenDeleteModal={handleOnOpenDeleteModal}
        onContactVendor={onContactVendor}
      />
    </Column>
  )
}
