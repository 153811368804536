import React, { FC, useState } from 'react'

import { MainRoutes } from '../../../../routes'

import { Column } from '../../../column'
import { NavLink } from '../../../nav-link'

import { HeaderSubMenuItem } from '../submenu-item'

import { useStyle } from './submenu.styles'
import { HeaderNavSubMenuProps } from './submenu.types'

export const HeaderNavSubMenu: FC<HeaderNavSubMenuProps> = ({
  className = '',
  item
}) => {
  const classes = useStyle()
  const [over, changeOver] = useState(false)

  const handleOnMouseOver = (state: boolean) => () => {
    changeOver(state)
  }

  return (
    <Column
      className={`${className} ${classes.item}`}
      alignItems="flex-start"
      onMouseOver={handleOnMouseOver(true)}
    >
      <NavLink
        className={classes.item}
        color="inactive"
        preset="h6"
        text={item.name}
        to={`${MainRoutes.CATEGORY}/${item.category.id}`}
      />
      {over && item.children.length > 0 && (
        <HeaderSubMenuItem item={item} onMouseOut={handleOnMouseOver(false)} />
      )}
    </Column>
  )
}
