import React, { FC } from 'react'

import { Row } from '../row'
import { Text } from '../text'

import { ItemHeaderProps } from './item-header.types'

export const ItemHeader: FC<ItemHeaderProps> = ({
  className,
  classNameItem,
  classNamePrice,
  classNameQuantity,
  classNameTotal,
  classNameNumberItem,
  classNameDeliveryItem,
  children
}) => {
  return (
    <Row className={className} justifyContent="flex-start" fullWidth>
      {classNameNumberItem && (
        <Row className={classNameNumberItem} justifyContent="flex-start">
          <Text
            text="No. of order"
            tx="order.preview.number"
            preset="body"
            color="inactive"
          />
        </Row>
      )}
      {classNameItem && (
        <Row className={classNameItem} justifyContent="flex-start">
          <Text
            text="Item"
            tx="order.preview.item"
            preset="body"
            color="inactive"
          />
        </Row>
      )}

      {classNameDeliveryItem && (
        <Row className={classNameDeliveryItem} justifyContent="flex-start">
          <Text
            text="Delivery Price"
            tx="order.preview.delivery.price"
            preset="body"
            color="inactive"
          />
        </Row>
      )}
      <Row justifyContent="flex-start" className={classNamePrice}>
        <Text
          text="Price"
          tx="order.preview.price"
          preset="body"
          color="inactive"
        />
      </Row>
      <Row justifyContent="flex-start" className={classNameQuantity}>
        <Text
          text="Quantity"
          tx="order.preview.quantity"
          preset="body"
          color="inactive"
        />
      </Row>
      <Row justifyContent="flex-start" className={classNameTotal}>
        <Text
          text="Total Price"
          tx="order.preview.total.price"
          preset="body"
          color="inactive"
        />
      </Row>
      {children}
    </Row>
  )
}
