import React, { FC } from 'react'

import {
  OrderControlItemLine,
  OrderControlItemLineMobile
} from '../order-control-item-line'
import { ResponsiveMediaView } from '../responsive-media-view'

import { OrderLineProps } from './order-line.types'
import { useStyle } from './order-line.styles'

export const OrderLine: FC<OrderLineProps> = ({
  product,
  quantity,
  items,
  currency,
  noSuggestion,
  description,
  discount,
  productionDays,
  deliveryPrice,
  totalOrderPrice
}) => {
  const classes = useStyle()

  if (!product) {
    return <></>
  }

  const orderDeliveryPrice = deliveryPrice || 0

  const totalPriceWithoutDelivery = totalOrderPrice - orderDeliveryPrice || 0

  const InfoLine = (
    <OrderControlItemLine
      productName={product.name}
      imgUrl={product?.thumbnail2x.url}
      noSuggestion={noSuggestion}
      description={description}
      discount={discount}
      deliveryPrice={orderDeliveryPrice}
      days={productionDays}
      quantity={quantity}
      productDefaultPrice={product.defaultPrice}
      totalOfferPrice={totalPriceWithoutDelivery}
      totalOrderPrice={totalOrderPrice}
      items={items}
      currency={currency}
      isTooltip={true}
    />
  )

  const InfoLineMobile = (
    <OrderControlItemLineMobile
      className={classes.listContainer}
      productName={product.name}
      imgUrl={product?.thumbnail2x.url}
      noSuggestion={noSuggestion}
      description={description}
      discount={discount}
      deliveryPrice={orderDeliveryPrice}
      quantity={quantity}
      days={productionDays}
      productDefaultPrice={product.defaultPrice}
      totalOfferPrice={totalPriceWithoutDelivery}
      totalOrderPrice={totalOrderPrice}
      items={items}
      currency={currency}
      isTooltip={true}
    />
  )

  return (
    <ResponsiveMediaView
      mobile={InfoLineMobile}
      tablet={InfoLine}
      medium={InfoLine}
      bigScreen={InfoLine}
    />
  )
}
