import React, { createContext, FC, useContext, useRef } from 'react'
import {
  Footer,
  FooterMobile,
  Header,
  HeaderMobile,
  HeaderTablet,
  ResponsiveMediaView
} from '../../components'

import { ContainerValue, ContainerProviderProps } from './container.types'

export const defaultValue = {
  headerRef: { current: null },
  footerRef: { current: null }
}
export const ContainerContext = createContext<ContainerValue>(defaultValue)

export const useContainer = () => useContext(ContainerContext)

export const ContainerProvider: FC<ContainerProviderProps> = ({ children }) => {
  const headerRef = useRef<HTMLDivElement>(null)
  const footerRef = useRef<HTMLDivElement>(null)

  const value = {
    headerRef,
    footerRef
  }

  return (
    <ContainerContext.Provider value={value}>
      <ResponsiveMediaView
        bigScreen={<Header ref={headerRef} />}
        medium={<Header ref={headerRef} />}
        tablet={<HeaderTablet ref={headerRef} />}
        mobile={<HeaderMobile ref={headerRef} />}
      />
      {children}
      <ResponsiveMediaView
        bigScreen={<Footer ref={footerRef} />}
        medium={<Footer ref={footerRef} />}
        tablet={<Footer ref={footerRef} />}
        mobile={<FooterMobile ref={footerRef} />}
      />
    </ContainerContext.Provider>
  )
}
