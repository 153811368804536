import { createUseStyles } from 'react-jss'
import { isMobile } from 'react-device-detect'
import { LightTheme } from '../../theme'

import background from '../../assets/images/empty-avatars.png'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    backgroundColor: theme.colors.white,
    padding: '24px 0'
  },
  image: {
    backgroundSize: !isMobile && '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top, center left 50px',
    backgroundImage: `url(${background})`,
    zIndex: 1,
    width: '100%',
    marginTop: !isMobile && 94,
    height: isMobile ? 282 : 255
  },
  button: {
    marginTop: 24,
    width: isMobile ? '100%' : 264,
    padding: isMobile && 12,
    backgroundColor: theme.colors.yellow
  },
  text: {
    width: isMobile && 'calc(100% - 24px)',
    textAlign: isMobile && 'center'
  },
  title: {
    padding: '8px 0'
  },
  subText: {
    marginTop: 16
  },
  header: {},
  titleIcon: {
    width: 'calc(100% - 24px)'
  },
  icon: {
    cursor: 'pointer'
  },
  row: {
    marginTop: 26
  },
  badgeRow: {
    marginTop: 24
  }
}))
