import { gql } from '@apollo/client'

import {
  BASIC_PRODUCT_FRAGMENT,
  FEEDBACK_FRAGMENT,
  FEEDBACK_USER_FRAGMENT
} from '../fragments'

export const FEEDBACK_CREATE_MUTATION = gql`
  ${FEEDBACK_USER_FRAGMENT}
  ${BASIC_PRODUCT_FRAGMENT}
  mutation FeedbackCreate($input: FeedbackCreateInput!) {
    feedbackCreate(input: $input) {
      feedback {
        id
        rating
        comment
        vendor {
          ...FeedbackUserFragment
        }
        product {
          ...BasicProductFields
        }
      }
      feedbackErrors {
        field
        code
        message
      }
      errors {
        message
        field
      }
    }
  }
`

export const FEEDBACKS_QUERY = gql`
  ${FEEDBACK_FRAGMENT}
  query Feedbacks(
    $before: String
    $after: String
    $first: Int!
    $last: Int
    $filter: FeedbackFilterInput
  ) {
    feedbacks(
      before: $before
      after: $after
      first: $first
      last: $last
      filter: $filter
    ) {
      filters
      totalCount
      edges {
        cursor
        node {
          ...FeedbackFragment
        }
      }
    }
  }
`
