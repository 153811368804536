import React, { FC } from 'react'

import { Row, Text } from '../../../components'

import { ObserveOfferButton } from '../../observe-offer-button'

import { useStyle } from './offer-chat-item.styles'
import { OfferChatItemProps } from './offer-chat-item.types'

export const OfferChatItem: FC<OfferChatItemProps> = ({
  count,
  disabled,
  currency,
  description,
  productDefaultPrice,
  options,
  discount,
  noSuggestion,
  offerOptions,
  offerColors,
  offerMaterials,
  isStatusDraft,
  itemQuantity,
  totalItemPrice
}) => {
  const classes = useStyle()

  if (isStatusDraft) {
    return <></>
  }

  return (
    <Row
      fullWidth
      className={classes.itemContainer}
      justifyContent="space-between"
    >
      <Row>
        <Text text={count} preset="h5" />
        <Text
          className={classes.itemTitle}
          text="Item"
          tx="offer.item.variant"
          preset="title"
        />
      </Row>
      <ObserveOfferButton
        currency={currency}
        disabled={disabled}
        description={description}
        productDefaultPrice={productDefaultPrice}
        options={options}
        discount={discount}
        noSuggestion={noSuggestion}
        offerOptions={offerOptions}
        offerColors={offerColors}
        offerMaterials={offerMaterials}
        itemQuantity={itemQuantity}
        totalItemPrice={totalItemPrice}
      />
    </Row>
  )
}
