import React, { useState } from 'react'
import ReactImageZoom from 'react-image-zoom'

import { Row } from '../../../components'

import { Carousel, Image } from '../carousel'

import { GalleryProps } from './gallery.types'
import { useStyle } from './gallery.styles'

export const Gallery = ({ images }: GalleryProps) => {
  const classes = useStyle()
  const [mainImage, changeMainImage] = useState(images[0] || null)

  const props = {
    width: 600,
    zoomWidth: 600,
    zoomPosition: 'original',
    img: mainImage?.url
  }

  const handleOnItemSelect = (image: Image) => {
    changeMainImage(image)
  }

  return (
    <Row fullWidth className={classes.container} alignItems="flex-start">
      {!mainImage ? (
        <Row className={classes.emptyImage} alignItems="flex-start" />
      ) : (
        <>
          <Carousel
            className={classes.list}
            data={images}
            onClick={handleOnItemSelect}
          />
          <Row className={classes.imageContainer} alignItems="flex-start">
            <ReactImageZoom {...props} />
          </Row>
        </>
      )}
    </Row>
  )
}
