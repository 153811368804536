import { createUseStyles } from 'react-jss'
import { isMobileOnly } from 'react-device-detect'

export const useStyle = createUseStyles({
  title: {
    marginTop: 16,
    width: isMobileOnly && 104,
    textAlign: isMobileOnly && 'center'
  }
})
