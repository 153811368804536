import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {
    marginTop: 80,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start'
  },
  row: {
    overflowX: 'hidden'
  },

  '@media screen and (min-width: 768px) and (max-width: 1015px)': {
    row: {
      flexWrap: 'wrap',
      overflowX: 'visible',
      columnGap: 16,
      rowGap: 16,
      maxWidth: 734
    }
  },

  '@media screen and (min-width: 1016px) and (max-width: 1223px)': {
    row: {
      flexWrap: 'wrap',
      overflowX: 'visible',
      columnGap: 16,
      rowGap: 16,
      maxWidth: 984
    }
  },
  '@media screen and (max-width: 480px)': {
    row: {
      overflowX: 'auto',
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    }
  }
})
