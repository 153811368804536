import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {
    alignSelf: 'center'
  },
  description: {
    marginTop: 24,
    textAlign: 'center'
  },
  title: {
    marginTop: 24
  },
  backContainer: {
    marginTop: 24,
    cursor: 'pointer'
  },
  backTitle: {
    marginLeft: 8
  }
})
