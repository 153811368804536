import React, { FC } from 'react'

import { Text } from '../text'

import { useStyle } from './button.style'
import { ButtonProps } from './button.types'

export const Button: FC<ButtonProps> = ({
  color,
  border,
  className = '',
  disabled,
  preset = 'borderButton',
  textColor,
  textPreset,
  text,
  tx,
  onClick
}) => {
  const classes = useStyle({ color, border, disabled })
  const presetClass = classes[preset]

  return (
    <button
      className={`${className} ${classes.container} ${presetClass}`}
      onClick={onClick}
    >
      <Text
        className={classes.text}
        color={textColor}
        preset={textPreset}
        text={text}
        tx={tx}
      />
    </button>
  )
}
