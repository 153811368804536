import React, { FC } from 'react'
import { isMobileOnly } from 'react-device-detect'

import { Text } from '../text'
import { Row } from '../row'
import { Column } from '../column'
import { Button } from '../button'
import { ResponsiveMediaView } from '../responsive-media-view'
import { getFormatedOrderDate } from '../../views'

import { AvatarInfoMobile } from './avatar-info-mobile'
import { useStyle } from './avatar-item.styles'
import { AvatarProps } from './avatar-item.types'

export const AvatarItem: FC<AvatarProps> = ({
  externalId,
  previewImage,
  username,
  createdAt,
  onToShareClick
}) => {
  const classes = useStyle()
  const Container = isMobileOnly ? Column : Row

  const createdAtFormat = getFormatedOrderDate(new Date(createdAt))

  const InfoRow = (
    <>
      <img className={classes.img} src={previewImage} alt={username} />
      <Text className={classes.name} text={username} preset="h5" />
      <Text className={classes.id} text={externalId} preset="h5" />
      <Text className={classes.date} text={createdAtFormat} preset="h5" />
    </>
  )

  return (
    <Container
      fullWidth
      className={`${classes.row} ${classes.border}`}
      justifyContent="flex-start"
    >
      <ResponsiveMediaView
        bigScreen={InfoRow}
        medium={InfoRow}
        tablet={InfoRow}
      />
      <ResponsiveMediaView
        mobile={
          <AvatarInfoMobile
            id={externalId}
            img={previewImage}
            name={username}
            date={createdAtFormat}
          />
        }
      />

      <Row className={classes.btnContainer}>
        <Button
          text="TO SHARE"
          tx="btn.toShare"
          color="yellow"
          preset="primary"
          textColor="white"
          textPreset="h5"
          onClick={onToShareClick}
        />
      </Row>
    </Container>
  )
}
