import React, { FC } from 'react'

import {
  arrayHasElements,
  ColorDot,
  Column,
  Modal,
  Row,
  Text
} from '../../components'
import {
  getIsPickedMultiOption,
  getMultiOptionsFromOffer,
  getPickedOptions,
  getSingleOptionsFromOffer
} from '../../pages'

import { useStyle } from './observe-options.styles'
import { ObserveOptionModalProps } from './observe-options.types'

export const ObserveOptionModal: FC<ObserveOptionModalProps> = ({
  material = 'Material',
  colorCode = '#000',
  colorTitle = 'Black',
  options,
  productOptionsFromOffer,
  offerOptions,
  open,
  onClose
}) => {
  const classes = useStyle()

  if (!open) {
    return <></>
  }

  const singleOptionsOffer = getSingleOptionsFromOffer(productOptionsFromOffer)
  const pickedSingleOptions = getPickedOptions(singleOptionsOffer)
  const multiOptionsOffer = getMultiOptionsFromOffer(productOptionsFromOffer)

  return (
    <Modal
      className={classes.container}
      title="Options"
      titleTx="observe.options.modal.title.text"
      onClose={onClose}
    >
      <Column fullWidth alignItems="flex-start" className={classes.details}>
        <Row fullWidth className={classes.row} justifyContent="space-between">
          <Text
            color="inactive"
            text="Material Options:"
            tx="observe.options.modal.option.material.text"
            preset="body"
          />
          <Text text={material} preset="body" />
        </Row>
        <Row fullWidth className={classes.row} justifyContent="space-between">
          <Text
            color="inactive"
            text="Color Options:"
            tx="observe.options.modal.option.color.text"
            preset="body"
          />
          <Row>
            <ColorDot color={colorCode} preset="productColor" />
            <Text className={classes.text} text={colorTitle} preset="body" />
          </Row>
        </Row>
        {options &&
          options
            .filter((orderOption) => arrayHasElements(orderOption.variants))
            .map((orderOption) => (
              <Row fullWidth key={`checkedOptions_${orderOption.id}`}>
                <Row
                  fullWidth
                  className={classes.row}
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Text
                    color="inactive"
                    text={orderOption.title}
                    preset="body"
                  />
                  <Column alignItems="flex-end">
                    {orderOption &&
                      orderOption.variants?.map((variant) => (
                        <Text
                          key={`variant_${variant.id}`}
                          text={variant.title || '-'}
                          preset="body"
                        />
                      ))}
                  </Column>
                </Row>
              </Row>
            ))}
        {singleOptionsOffer &&
          arrayHasElements(singleOptionsOffer) &&
          singleOptionsOffer.map((option, index) => (
            <Row fullWidth key={`singleOptionsOffer${option.id}`}>
              <Row
                fullWidth
                className={classes.row}
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Text color="inactive" text={option.title} preset="body" />
                <Column alignItems="flex-end">
                  {pickedSingleOptions &&
                    arrayHasElements(pickedSingleOptions) && (
                      <Text
                        key={`variant_${pickedSingleOptions[index].id}`}
                        text={pickedSingleOptions[index].title || '-'}
                        preset="body"
                      />
                    )}
                </Column>
              </Row>
            </Row>
          ))}
        {multiOptionsOffer &&
          arrayHasElements(multiOptionsOffer) &&
          getIsPickedMultiOption(multiOptionsOffer) &&
          multiOptionsOffer.map((multiOption) => (
            <Row fullWidth key={`productOptionsFromOffer${multiOption.id}`}>
              <Row
                fullWidth
                className={classes.row}
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Text color="inactive" text={multiOption.title} preset="body" />
                <Column alignItems="flex-end">
                  {multiOption &&
                    multiOption.variants
                      ?.filter((variant) => variant.isDefault === true)
                      .map((variant) => (
                        <Text
                          key={`variant_${variant.id}`}
                          text={variant.title || '-'}
                          preset="body"
                        />
                      ))}
                </Column>
              </Row>
            </Row>
          ))}
        {offerOptions && arrayHasElements(offerOptions) && (
          <Row
            fullWidth
            className={classes.row}
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Text
              color="inactive"
              text="Additional Options:"
              tx="observe.options.modal.additional.options.text"
              preset="body"
            />
            <Column alignItems="flex-end">
              {offerOptions.map((offerOption) => (
                <Column
                  key={`offerOption${offerOption.id}`}
                  alignItems="flex-end"
                >
                  <Text text={offerOption.title} preset="body" />
                </Column>
              ))}
            </Column>
          </Row>
        )}
      </Column>
    </Modal>
  )
}
