import React, { FC } from 'react'

import { Button, Row } from '../../../components'

import { useStyle } from './vendor-feedback-buttons.styles'
import { VendorFeedbackButtonsType } from './vendor-feedback-buttons.types'

export const VendorFeedbackButtons: FC<VendorFeedbackButtonsType> = ({
  isDisabled,
  onGoBack,
  onSubmit
}) => {
  const classes = useStyle()

  return (
    <Row className={classes.buttonsRow} fullWidth justifyContent="flex-end">
      <Button
        className={classes.button}
        color="yellow"
        preset="secondaryBorderHigh"
        textColor="yellow"
        textPreset="h5"
        text="CANCEL"
        tx="give.feedback.cancel.btn"
        onClick={onGoBack}
      />
      <Button
        className={classes.submitButton}
        color="yellow"
        disabled={isDisabled}
        preset="primary"
        textColor="white"
        textPreset="h5"
        text="SEND"
        tx="give.feedback.send.btn"
        onClick={onSubmit}
      />
    </Row>
  )
}
