import { RefObject } from 'react'
import Talk from 'talkjs'

import { TextTranslate } from '../../components'
import { ChatUser, Order, Product, User } from '../../services'

export type SendMessageData = {
  conversation: Talk.ConversationBuilder
  message: string
  messageTx?: TextTranslate
  values?: any
  additionalMessage?: string
}

export enum ChatUserRole {
  STAFF = 'Staff',
  VENDOR = 'Vendor',
  CUSTOMER = 'Customer'
}

export enum ChatLocalStorageKey {
  CONVERSATION = 'conversation'
}

export type GroupConversationOptions = {
  users: ChatUser[]
  role: ChatUserRole
  chatAttributes: Talk.ConversationAttributes
}

export type ChatValue = {
  ready: boolean
  conversation?: Talk.ConversationBuilder
  session?: Talk.Session
  onCreateConversation?: (
    user: Talk.User
  ) => Talk.ConversationBuilder | undefined
  onGetConversation?: () => void
  onDestroyInboxes?: () => void
  onMountConversation?: (ref: RefObject<HTMLDivElement>) => void
  onMountChatboxConversation?: (
    ref: RefObject<HTMLDivElement>,
    conversationId: string
  ) => void
  onSendMessage?: (data: SendMessageData) => void
  onSendProductMessage?: (
    conversation: Talk.ConversationBuilder,
    product: Product
  ) => void
  onSendOrderMessage?: (
    conversation: Talk.ConversationBuilder,
    order: Order
  ) => void
  createVendor: (vendor?: ChatUser) => Talk.User
  onDestroySession?: () => void
  onOpenSession?: () => void
  onCreateGroupConversation?: (
    options: GroupConversationOptions
  ) => string | undefined
}
