import React from 'react'

import { useApi } from '../../../../providers'
import { NavLink } from '../../../nav-link'

import { Icon, ICONS } from '../../../icon'
import { Row } from '../../../row'
import { Text } from '../../../text'
import { HeaderAdditionalLinks } from '../../../header-bottom-bar'
import { Loader } from '../../../loader'

import { useStyle } from './nav-menu-mobile.styles'
import { HeaderNavMobileSubMenu } from './submenu-mobile'

export const HeaderNavMobileMenu = () => {
  const { menu } = useApi()
  const mainMenu = menu.useMainMenu()
  const classes = useStyle()
  if (!mainMenu.data || !HeaderAdditionalLinks) {
    return <Loader />
  }

  return (
    <Row justifyContent="start" className={classes.container}>
      {mainMenu.data.shop.navigation.main.items.map((item) => (
        <HeaderNavMobileSubMenu
          key={item.id}
          className={classes.item}
          item={item}
        />
      ))}
      <NavLink
        className={classes.item}
        color="inactive"
        text="New Arrivals"
        preset="h6"
        to={HeaderAdditionalLinks.NEW_ARRIVALS}
        tx="header.newArrivals"
      />
      <NavLink
        className={classes.item}
        color="inactive"
        text="Top Products"
        preset="h6"
        to={HeaderAdditionalLinks.TOP_PRODUCTS}
        tx="header.topProducts"
      />
      <NavLink
        className={classes.item}
        color="inactive"
        text="Maker/Designer"
        preset="h6"
        to={HeaderAdditionalLinks.MAKERS_DESIGNERS}
        tx="header.maker"
      />
      <Row className={classes.rightContainer} justifyContent="space-between">
        <Row className={classes.button}>
          <Icon src={ICONS.uk} />
          <Text
            className={classes.flagTitle}
            color="white"
            preset="secondTitle"
            text="ENGLISH"
          />
        </Row>
        <Row className={classes.button}>
          <Text color="white" preset="secondTitle" text="USD" />
          <Icon src={ICONS.arrowRight} className={classes.arrowRight} />
        </Row>
      </Row>
    </Row>
  )
}
