import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

import { AvatarStyleProps } from './avatar.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  avatar: ({
    radius = 48,
    backgroundColor = 'blue',
    size = 48
  }: AvatarStyleProps) => ({
    backgroundColor: theme.colors[backgroundColor],
    borderRadius: radius,
    height: radius || size,
    width: radius || size
  }),
  image: ({ radius = 48, size = 48 }: AvatarStyleProps) => ({
    borderRadius: radius,
    height: radius || size,
    width: radius || size,
    objectFit: 'cover'
  })
}))
