import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {
    padding: 24
  },
  content: {
    alignSelf: 'center'
  },
  createdDate: {
    marginTop: 24
  },
  column: {
    maxWidth: 1124,
    marginTop: 24
  },
  secondaryTitle: {
    marginTop: 24
  },
  '@media screen and (max-width: 480px)': {
    container: {
      padding: '24px 0 40px',
      width: '100%'
    },
    content: {
      padding: '18px 0'
    },
    column: {
      marginTop: 0
    }
  },
  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    container: {
      width: '100%',
      padding: '40px 0'
    },
    content: {
      padding: '18px 0'
    }
  }
})
