import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  selectPage: {
    width: 'calc(100% - 48px)',
    padding: 24,
    maxWidth: 1720
  },
  container: {
    maxWidth: 1124,
    padding: '24px 0 94px'
  },

  backTitle: {
    marginLeft: 8
  },
  button: {
    marginTop: 40,
    width: 360
  },
  table: {
    marginTop: 40
  },
  title: {
    marginTop: 24
  },
  text: {
    marginTop: 24
  },
  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    selectPage: {
      width: 'calc(100% - 32px)',
      padding: '24px 16px'
    },
    container: {
      width: '100%',
      padding: '24px 0px 94px'
    }
  },
  '@media screen and (max-width: 480px)': {
    selectPage: {
      width: 'calc(100% - 24px)',
      padding: '16px 12px'
    },
    container: {
      width: '100%',
      padding: '18px 0px 30px'
    },
    backContainer: {
      marginTop: 0
    },
    button: {
      width: '100%',
      padding: '16px 0'
    },
    table: {
      marginTop: 0
    },
    text: {
      textAlign: 'center',
      color: theme.colors.inactive
    }
  }
}))
