import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'
import { DropdownItemsStyleProps } from '..'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  itemsContainer: {
    borderRadius: 4,
    padding: 7,
    position: 'absolute',
    minHeight: 46,
    zIndex: 12,
    overflow: 'hidden',
    backgroundColor: theme.colors.white
  },
  left: {
    top: 10,
    right: ({ width }: DropdownItemsStyleProps) => width
  },
  right: {
    top: 10,
    left: ({ width }: DropdownItemsStyleProps) => width
  },
  bottom: {
    top: 10
  }
}))
