import React, { FC, useMemo } from 'react'

import { PaypalButtonsView } from '../../views'

import { Column } from '../column'
import { getAvaliableToPay, getCurrencySymbol } from '../helpers'
import { OrderConfirmLine } from '../order-confirm-line'
import { OrderConfirmHeader } from '../order-confirm-header'

import { useStyle } from './order-confirm-item.styles'
import { OrderItemConfirmProps } from './order-confirm-item.types'

export const OrderItemConfirm: FC<OrderItemConfirmProps> = ({
  id: orderId,
  number,
  created,
  total,
  totalPrice,
  vendor,
  lines,
  offer,
  paymentStatus,
  deliveryPrice,
  mainStatus
}) => {
  const classes = useStyle()

  const isAvailableToPay = useMemo(
    () => getAvaliableToPay(paymentStatus, mainStatus),
    [paymentStatus, mainStatus]
  )

  const currencySymbol = getCurrencySymbol(total.gross.currency)

  return (
    <Column
      fullWidth
      key={`orderConfirm_${orderId}`}
      className={classes.container}
    >
      <OrderConfirmHeader
        className={classes.info}
        orderNumber={number}
        created={created}
        companyName={vendor.companyName}
      />

      {offer &&
        lines.map((line, index) => {
          return (
            <OrderConfirmLine
              key={`orderControl_${orderId}_line_${line.product.id}${index}`}
              {...offer}
              {...line}
              currency={currencySymbol}
              totalOrderPrice={totalPrice}
              deliveryPrice={deliveryPrice}
            />
          )
        })}
      {isAvailableToPay && (
        <PaypalButtonsView
          amount={String(totalPrice)}
          currency={total.gross.currency}
          orderId={orderId}
          orderNumber={number}
        />
      )}
    </Column>
  )
}
