import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    alignSelf: 'stretch',
    width: 476,
    border: `1px solid ${theme.colors.shadow}`
  },
  productContainer: {
    padding: '16px 24px',
    width: 'calc(100% - 48px)'
  },
  avatar: {
    objectFit: 'cover',
    height: 428,
    width: 428
  },
  offerName: {
    marginTop: 16
  },
  observe: {
    marginTop: 8
  },
  options: {
    borderBottom: `1px solid ${theme.colors.shadow}`,
    padding: '16px 0'
  },
  buttonContainer: {
    marginTop: 16
  },
  accept: {
    marginLeft: 24
  },
  '@media screen and (max-width: 480px)': {
    content: {
      flexDirection: 'column'
    },
    container: {
      alignSelf: 'stretch',
      width: '100%',
      border: `1px solid ${theme.colors.shadow}`
    },
    offerView: {
      width: '100%'
    },
    offerTitleContainer: {
      padding: '8px 16px',
      width: 'calc(100% - 32px)'
    },
    offerProductContainer: {
      padding: 16,
      width: 'calc(100% - 32px)'
    },
    offerImg: {
      objectFit: 'cover',
      height: 104,
      width: 104
    },
    avatar: {
      objectFit: 'cover',
      height: 104,
      width: 104
    },
    chatbox: {
      height: 600,
      flex: 'auto'
    },
    offerName: {
      marginTop: 0,
      marginLeft: 16
    },
    offerObserve: {
      marginLeft: 16
    },
    accept: {
      padding: '14px 22px'
    }
  }
}))
