import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {},
  image: {
    objectFit: 'cover',
    border: '4px solid transparent',
    '&:hover': {
      background: theme.colors.white,
      boxShadow: `4px 4px 16px ${theme.colors.shadow}`
    },
    '&:not(:first-child)': {
      marginTop: 16
    }
  },
  carouselContainer: {
    height: 800,
    overflowY: 'hidden',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  arrowContainer: {
    background:
      'linear-gradient(180deg, #FFFFFF 24.62%, rgba(255, 255, 255, 0.76) 62.43%, rgba(255, 255, 255, 0) 99.72%)',
    width: '100%',
    position: 'absolute',
    top: 0,
    zIndex: 2,
    cursor: 'pointer'
  },
  bottom: {
    top: '100%',
    transformOrigin: 'center',
    left: 'auto',
    transform: 'rotate(180deg)'
  },
  top: {
    top: '0%',
    left: 'auto'
  },
  selected: {
    border: `4px solid ${theme.colors.yellow}`
  },
  color: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    '&:not(:first-child)': {
      marginTop: 8
    }
  },
  imgColumn: {
    marginLeft: 8
  },
  '@media screen and (max-width: 1200px)': {
    carouselContainer: {
      marginBottom: 12
    },
    color: {
      position: 'absolute',
      left: 280,
      top: 100,
      zIndex: 9
    }
  }
}))
