import React, { FC } from 'react'

import { ItemHeader } from '../item-header'

import { Column } from '../column'
import { Row } from '../row'
import { Text } from '../text'
import { OrderPreviewOfferItem } from '../order-preview-offer-item'
import { getCurrencyByProduct } from '../helpers'
import { getPriceWithSymbol } from '../../views'

import { useStyle } from './order-preview-offer-table.styles'
import { OrderPreviewOfferTableProps } from './order-preview-offer-table.types'

export const OrderPreviewOfferTable: FC<OrderPreviewOfferTableProps> = ({
  id,
  number,
  offerQuantity,
  companyName,
  productTotalPrice,
  totalPrice,
  material,
  product,
  options,
  colorName,
  colorCode,
  offerOptions,
  onRemoveOffer
}) => {
  const classes = useStyle()

  const currency = product && getCurrencyByProduct(product)

  return (
    <Column className={classes.container}>
      <Row className={classes.infoContainer}>
        <Row
          className={classes.infoItemsLeft}
          alignItems="center"
          justifyContent="flex-start"
        >
          <Row className={classes.itemOrderNum} justifyContent="flex-start">
            <Row fullWidth wrap justifyContent="flex-start">
              <Text
                className={classes.orderNum}
                text="Order"
                tx="order.preview.order"
                preset="h3"
              />
              <Text
                className={classes.orderNum}
                text={`#${String(number)}`}
                preset="h3"
              />
            </Row>
          </Row>

          <Row
            className={classes.itemVendor}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Text
              text="Company:"
              tx="order.preview.vendor"
              preset="body"
              color="inactive"
            />
            <Text
              className={classes.textMarginLeft}
              text={companyName}
              preset="h4"
            />
          </Row>
        </Row>
        <Row className={classes.infoItemsRight} justifyContent="flex-end">
          <Row
            className={classes.itemOrderPrice}
            alignItems="flex-end"
            justifyContent="flex-end"
          >
            <Text
              text="Order Price:"
              tx="order.preview.order.price"
              preset="body"
              color="inactive"
            />
            <Text
              className={classes.textMarginLeft}
              text={getPriceWithSymbol(totalPrice, currency)}
              preset="h4"
            />
          </Row>
        </Row>
      </Row>
      <ItemHeader
        className={classes.header}
        classNameItem={classes.item}
        classNamePrice={classes.headerPrice}
        classNameQuantity={classes.headerText}
        classNameTotal={classes.headerLastText}
      />
      {product && (
        <OrderPreviewOfferItem
          id={id}
          colorName={colorName}
          colorCode={colorCode}
          name={product.name}
          currency={currency}
          productTotalPrice={productTotalPrice}
          material={material}
          options={options}
          offerOptions={offerOptions}
          defaultQuantity={offerQuantity}
          image={product.thumbnail2x?.url}
          offerQuantity={offerQuantity}
          totalPrice={totalPrice}
          onRemoveOffer={onRemoveOffer}
        />
      )}
    </Column>
  )
}
