import React, { FC } from 'react'
import { Route } from 'react-router-dom'

import { CabinetNavigator, CabinetPage } from '../../pages'

export type CabinetRouteProps = {
  path: string
}

export const CabinetRoute: FC<CabinetRouteProps> = ({ children, path }) => {
  const navigator = <CabinetNavigator />
  return (
    <Route exact path={path}>
      <CabinetPage navigator={navigator}>{children}</CabinetPage>
    </Route>
  )
}
