import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../theme'

import image from '../../assets/images/contactUsImage.png'
import background from '../../assets/images/contactUsBackground.png'
import backgroundImage from '../../assets/images/contactUsBackgroundEllipse.png'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  contactUsMainContainer: {
    backgroundPositionX: 'right',
    backgroundPositionY: '60px, center',
    backgroundSize: '60%, 65%',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${background}), url(${backgroundImage})`,
    minHeight: 648,
    width: '100%',
    zIndex: 1
  },
  contactUsContainer: {
    width: '100%'
  },
  contactUsContent: {
    width: '33%'
  },
  contactUsSecondaryText: {
    marginTop: 8
  },
  input: {
    marginTop: 24
  },
  submit: {
    marginTop: 88
  },
  icon: {},
  contentItem: {
    marginLeft: 16
  },
  contentItemText: {
    marginTop: 8
  },
  contactUsContentItem: {
    marginTop: 88,
    '&:first-child': {
      marginTop: 0
    }
  },

  contactUsContentImage: {
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${image})`,
    height: 343
  },
  contactUsMessage: {
    color: theme.colors.white
  },
  error: {
    position: 'absolute',
    backgroundColor: theme.colors.errorBackground,
    padding: '8px 0',
    marginTop: 365,
    borderRadius: 4
  },
  errorIcon: {
    marginLeft: 19
  },
  errorText: {
    marginLeft: 16
  },
  '@media screen and (max-width: 480px)': {
    contactUsMainContainer: {
      backgroundPositionX: '-220px, -220px',
      backgroundPositionY: '594px, 474px',
      backgroundSize: '200%, 216%',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url(${background}), url(${backgroundImage})`,
      minHeight: 830
    },
    contactUsContainer: {
      flexDirection: 'column'
    },
    contactUsContent: {
      width: 'calc(100% - 24px)',
      marginTop: 24
    },
    contactUsContentItem: {
      marginTop: 88,
      '&:first-child': {
        marginTop: 88
      }
    },
    contactUsContentImage: {
      display: 'none'
    },
    error: {
      opacity: 0.8
    }
  },
  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    contactUsMainContainer: {
      backgroundPositionY: 0,
      backgroundPositionX: 530,
      backgroundSize: '60%, 80%'
    },
    contactUsContentImage: {
      display: 'none'
    },
    contactUsContainer: {
      justifyContent: 'flex-start'
    },
    contactUsContent: {
      width: 'calc(60% - 32px)',
      padding: '0 16px'
    }
  }
}))
