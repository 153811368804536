import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  innerContainer: {
    width: 'calc(100vw - 24px)',
    padding: 12
  },
  socialIcon: {
    margin: '12px 0',
    '&:not(:first-child)': {
      marginLeft: 24
    }
  },
  svgContainer: {
    position: 'absolute',
    left: '120px',
    width: 'auto',
    height: '100%'
  },
  icon: {
    cursor: 'pointer'
  },
  category: {
    width: '100%',
    marginBottom: 14
  },
  social: {
    width: '100vw',
    borderBottom: `1px solid ${theme.colors.shadow}`,
    borderTop: `1px solid ${theme.colors.shadow}`
  },
  subscribeBtn: {
    height: 16,
    width: 67,
    padding: '8px 4px'
  },
  border: {
    width: '100vw',
    borderTop: `1px solid ${theme.colors.shadow}`
  },
  paymentBlock: {
    width: 'calc(100vw - 24px)',
    marginTop: 14
  },
  brandBlock: {
    marginTop: 14
  },
  brand: {
    marginLeft: 4
  },
  newsletter: {
    marginTop: 16
  }
}))
