import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    width: 'calc(100% - 48px)',
    padding: '72px 24px',
    maxWidth: 1124
  },
  button: {
    maxWidth: 400,
    marginTop: 40
  },
  border: {
    marginTop: 40,
    borderTop: `1px solid ${theme.colors.shadow}`
  },
  secondTitle: {
    marginTop: 24
  },
  text: {
    marginTop: 24
  },
  subTitle: {
    marginTop: 22
  },
  secondText: {
    marginTop: 20
  },
  thirdText: {
    marginTop: 16
  },
  row: {
    width: '100%',
    marginTop: 40,
    '& > a': {
      marginLeft: 8
    }
  },
  '@media screen and (max-width: 480px)': {
    container: {
      padding: '24px 12px 40px 12px',
      width: 'calc(100% - 24px)'
    },
    border: {
      marginTop: 24
    },
    secondText: {
      marginTop: 16
    },
    button: {
      maxWidth: '100%'
    },
    row: {
      width: '100%',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginTop: 24,
      '& > a': {
        marginLeft: 0
      }
    }
  },
  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    container: {
      width: 'calc(100% - 32px)',
      padding: '40px 16px'
    },
    row: {
      marginTop: 24
    }
  }
}))
