import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  ProductFilter,
  ProductFiltersParseJSON,
  ProductOrderSort,
  ProductsByCollectionIdVariables
} from '../../services'
import {
  Column,
  FilterSidebarData,
  getListFromEdgesList,
  parseFilterItems
} from '../../components'
import { useApi } from '../../providers'
import { ProductView, reduceProductsToProductList } from '../../views'

import { CollectionProductsPageParams } from './collection-products.types'

export const CollectionProductsPage = () => {
  const { id } = useParams<CollectionProductsPageParams>()
  const {
    product: { useProductsByCollectionId }
  } = useApi()

  const first = 8

  const [productVariables, changeProductVariables] =
    useState<ProductsByCollectionIdVariables>({
      id,
      first
    })

  const {
    data: response,
    refetch,
    fetchMore
  } = useProductsByCollectionId(productVariables)

  const Data = useMemo(() => {
    if (response) {
      const products = getListFromEdgesList(response.collection.products)
      return reduceProductsToProductList(products)
    }

    return []
  }, [response])

  const TotalCount = useMemo(
    () => response?.collection.products.totalCount || 0,
    [response?.collection.products.totalCount]
  )

  const handleOnSort = (sortBy?: ProductOrderSort) => {
    refetch({ ...productVariables, first, sortBy })
    changeProductVariables({ ...productVariables, first, sortBy })
  }

  const handleOnLoadNext = (filter: ProductFilter) => {
    if (fetchMore && response) {
      const productEdges = response.collection.products.edges
      const lastIndex = productEdges.length - 1
      fetchMore({
        variables: {
          id,
          first,
          filter,
          after: productEdges[lastIndex].cursor
        }
      })
    }
  }

  const handleOnChangeFilter = (value: ProductFilter) => {
    if (refetch) {
      refetch({ first, filter: value })
    }
  }

  const Filters = useMemo(() => {
    if (response?.collection.products.filters) {
      const list = parseFilterItems<ProductFiltersParseJSON>(
        response?.collection.products.filters
      )

      if (list) {
        return list
      }
    }

    return {
      colors: [],
      materials: [],
      vendors: [],
      genders: [],
      priceRange: [],
      companyNames: [],
      productTypes: [],
      sizes: [],
      rating: [],
      productionDaysRange: []
    } as FilterSidebarData<ProductFiltersParseJSON>
  }, [response?.collection.products.filters])

  return (
    <Column fullWidth>
      {response && (
        <ProductView
          withCategories
          data={Data}
          title={response?.collection.name}
          totalCount={TotalCount}
          filtersData={Filters}
          onLoadNext={handleOnLoadNext}
          onSort={handleOnSort}
          onChangeFilter={handleOnChangeFilter}
        />
      )}
    </Column>
  )
}
