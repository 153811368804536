import React, { forwardRef, useEffect, useRef, useState } from 'react'

import { Row } from '../row'
import { Column } from '../column'

import { BannerListProps } from './banner-carousel.types'
import { useStyle } from './banner-carousel.styles'
import { BannerCarouselItem } from './banner-carousel-item'
import { CarouselDot } from './carousel-dot'

export const BannerCarousel = forwardRef<HTMLDivElement, BannerListProps>(
  ({ className = '', data, timeout = 0 }, ref) => {
    const [currentIndex, changeCurrentIndex] = useState(0)
    const scrollContainer = useRef<HTMLDivElement>(null)

    const classes = useStyle()

    const handleOnClickIndex = (index: number) => () => {
      const scrollElement = scrollContainer.current
      if (scrollElement) {
        const leftPosition = scrollElement.offsetWidth * index
        changeCurrentIndex(index)
        scrollElement.scroll({ left: leftPosition, behavior: 'smooth' })
      }
    }

    useEffect(() => {
      if (timeout) {
        const timer = setInterval(() => {
          const nextIndex = currentIndex + 1
          if (data.length > nextIndex) {
            const onChangeIndex = handleOnClickIndex(nextIndex)
            onChangeIndex()
          } else {
            const onChangeIndex = handleOnClickIndex(0)
            onChangeIndex()
          }
        }, timeout)

        return () => {
          clearInterval(timer)
        }
      }

      return undefined
    }, [timeout, currentIndex])

    return (
      <Column fullWidth className={className} ref={ref}>
        <Row
          fullWidth
          className={classes.carouselContainer}
          justifyContent="flex-start"
          ref={scrollContainer}
        >
          {data.map((banner) => (
            <BannerCarouselItem
              key={`banner_${banner.id}`}
              banner={banner}
              imageClassName={classes.image}
            />
          ))}
        </Row>
        <Row className={classes.dotContainer}>
          {data.map((banner, index) => {
            const active = currentIndex === index

            return (
              <CarouselDot
                key={`dot_${banner.id}`}
                active={active}
                className={classes.dot}
                onClick={handleOnClickIndex(index)}
              />
            )
          })}
        </Row>
      </Column>
    )
  }
)
