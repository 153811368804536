import React, { FC } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'

import { useSignInModal, useAuth } from '../../../providers'
import { Column, Row, Text, Button, Avatar } from '../../../components'

import { VendorSummaryHeader } from './vendor-summary-header'
import { VendorSummaryProps, VendorSummaryParams } from './vendor-summary.types'
import { useStyle } from './vendor-summary.styles'
import { VendorSummaryInfo } from './vendor-info'

export const VendorSummary: FC<VendorSummaryProps> = ({
  className = '',
  data,
  averageRating,
  countryCode,
  positiveFeedback
}) => {
  const history = useHistory()

  const { id } = useParams<VendorSummaryParams>()
  const { isLoggedIn } = useAuth()

  const { changeOpenModal } = useSignInModal()
  const companyName = data?.companyName ? data.companyName : '-'
  const radius = isMobileOnly ? 64 : 120
  const textPreset = isMobileOnly ? 'h4' : 'h1'
  const countryTextPreset = isMobileOnly ? 'h7' : 'h3'

  const classes = useStyle()

  const handleOnNavigatePath = (path: string) => () => {
    history.push(path)
  }

  const handleOnGiveFeedback = () => {
    const navigate = handleOnNavigatePath(`/vendor-feedback/${id}`)

    if (!isLoggedIn) {
      if (changeOpenModal) {
        changeOpenModal(true)
      }
    }

    if (isLoggedIn) {
      navigate()
    }
  }

  return (
    <Column className={className}>
      <VendorSummaryHeader className={classes.header} rating={averageRating} />
      {data && (
        <Column fullWidth className={classes.vendor}>
          <Row fullWidth justifyContent="flex-start" alignItems="flex-start">
            <Row className={classes.vendorImage}>
              {data && (
                <Avatar
                  alt="user"
                  src={data.avatar?.url}
                  name={companyName}
                  presetTextPlaceholder={textPreset}
                  radius={radius}
                />
              )}
            </Row>
            <Column
              className={classes.countryName}
              fullWidth
              alignItems="flex-start"
            >
              <Text text={companyName} preset={textPreset} color="black" />
              <Row>
                {/* TODO:add flag after deciding how flags will be implemented */}
                {/* <img className={classes.flag} src={countryFlag} /> */}
                {countryCode && (
                  <Text
                    className={classes.secondaryText}
                    text={countryCode}
                    preset={countryTextPreset}
                  />
                )}
              </Row>
            </Column>
          </Row>
          <Row className={classes.separator} fullWidth />
          <VendorSummaryInfo
            status={data.status}
            activity={data.activity}
            averageResponseTime={data.averageResponseTime}
            positiveFeedback={positiveFeedback}
            dateJoined={data.dateJoined}
          />

          <Row fullWidth justifyContent="center" className={classes.buttonsRow}>
            <Button
              className={classes.btnFeedback}
              color="white"
              preset="secondaryBorder"
              text="GIVE FEEDBACK"
              tx="vendor.giveFeedback"
              textColor="yellow"
              textPreset="h5"
              onClick={handleOnGiveFeedback}
            />
          </Row>
        </Column>
      )}
    </Column>
  )
}
