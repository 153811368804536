import React, { FC } from 'react'

import { useTheme } from 'react-jss'

import { Row } from '../../row'
import { Column } from '../../column'
import { Text } from '../../text'

import { ColorDot } from '../../color-dot'

import { getColorByMainStatus, getMessageByMainStatus } from '../../../pages'
import { LightTheme } from '../../../theme'

import { PurchaseSliderHeaderCloseProps } from '../purchase-slider-header-close'

import { useStyle } from './purchase-slider-header-mobile-close.styles'

export const PurchaseSliderHeaderMobileClose: FC<
  PurchaseSliderHeaderCloseProps
> = ({ number, country, created, status, totalPrice, lines, companyName }) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle()
  const orderIdText = `# ${number}`

  return (
    <Column fullWidth justifyContent="space-between">
      <Column fullWidth justifyContent="space-between" alignItems="flex-start">
        <Row className={classes.statusRow}>
          <ColorDot
            preset="big"
            color={theme.colors[getColorByMainStatus(status)]}
          />
          <Column className={classes.statusColumn} alignItems="flex-start">
            <Text color="inactive" preset="body" text={orderIdText} />
            <Text
              {...getMessageByMainStatus(status)}
              className={classes.status}
              color="black"
              preset="h7"
            />
          </Column>
        </Row>
        <Row
          className={classes.price}
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Text
            color="inactive"
            preset="body"
            text="Total Price"
            tx="order.item.price.title"
          />
          <Text
            className={classes.status}
            color="black"
            preset="h7"
            text={String(totalPrice)}
          />
        </Row>
        <Row
          className={classes.details}
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Text color="inactive" preset="body" text="Vendor" />
          <Text
            className={classes.status}
            color="black"
            preset="h7"
            text={companyName}
          />
        </Row>
        {country && (
          <Row
            className={classes.details}
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Text color="inactive" preset="body" text="Country" />
            <Text
              className={classes.status}
              color="black"
              preset="h7"
              text={country}
            />
          </Row>
        )}
        <Row
          className={classes.details}
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Text color="inactive" preset="body" text="Date of creation" />
          <Text
            className={classes.status}
            color="black"
            preset="h7"
            text={created}
          />
        </Row>
      </Column>

      <Row justifyContent="flex-start" className={classes.imgRow}>
        {lines.map((orderLine) => (
          <img
            key={Math.random()}
            height="48px"
            width="48px"
            src={orderLine?.product?.thumbnail2x?.url}
          />
        ))}
      </Row>
    </Column>
  )
}
