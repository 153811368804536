import React, { ChangeEvent, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'

import {
  Button,
  Checkbox,
  Column,
  Icon,
  ICONS,
  NavLink,
  RadioItem,
  Row,
  Text,
  TextField,
  TextProps
} from '../../components'
import { GetTokenCreateRequest } from '../../services'
import { useAuth } from '../../providers'
import { validatePassword, validateEmail } from '../../pages'

import { useStyle } from './sign-up.styles'
import { Sex } from './sign-up.types'

const defaultUserState: GetTokenCreateRequest = {
  email: '',
  password: '',
  firstName: '',
  sex: Sex.MALE
}

export const SignUpView = () => {
  const [user, changeUser] = useState<GetTokenCreateRequest>(defaultUserState)
  const { onRegister, error, onChangeError } = useAuth()
  const [checked, setChecked] = useState(false)

  const handleOnCheck = () => setChecked(!checked)

  const ValidatorPassword = () => {
    if (user.password) {
      const isValidePassword = validatePassword(user.password)

      return isValidePassword
    }
    return undefined
  }
  const errorPassword = user.password.length > 0 && !ValidatorPassword()
  const classes = useStyle({
    isErrorEmail: error !== undefined,
    isErrorPassword: errorPassword
  })

  const isDisabled =
    !user.email ||
    !user.password ||
    !ValidatorPassword() ||
    !user.firstName ||
    typeof user.sex === 'undefined' ||
    !checked

  const handleOnChange =
    (prop: keyof GetTokenCreateRequest) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target
      changeUser({
        ...user,
        [prop]: value,
        error: undefined
      })
    }

  const handleOnChangeSex = (value: Sex) => () => {
    changeUser({
      ...user,
      sex: value,
      error: undefined
    })
  }

  const handleOnClick = () => {
    const isEmail = validateEmail(user.email)
    if (isEmail && onRegister) {
      onRegister(user)
    } else {
      const emailError: TextProps = {
        text: 'Incorrect email! Try again.',
        color: 'error',
        tx: 'contact.us.error.email',
        preset: 'body'
      }
      changeUser({ ...user, error: emailError })
    }
  }

  return (
    <>
      <Column className={classes.loginForm}>
        {!isMobileOnly && (
          <Text preset="h1" text="Sign Up" tx="register.signUp.title" />
        )}
        <Text
          color="inactive"
          preset="h7"
          text="Please sign up to continue."
          tx="register.continue"
        />

        <TextField
          required
          className={classes.inputEmail}
          defaultValue={user.email}
          label="E-mail"
          labelTx="register.email"
          preset="border"
          type="text"
          onFocus={onChangeError}
          onChange={handleOnChange('email')}
        />
        {error && (
          <Row fullWidth justifyContent="flex-start">
            <Text
              className={classes.errorText}
              text="An account with that email already exists! Please Sign In."
              tx="sign.up.error"
              color="error"
              preset="secondTitle"
            />
          </Row>
        )}
        <TextField
          isSecure
          className={classes.inputPassword}
          defaultValue={user.password || ''}
          value={user.password}
          label="Password"
          labelTx="register.password"
          maxLength={30}
          preset="main"
          type="text"
          onChange={handleOnChange('password')}
        />
        {errorPassword && (
          <Row fullWidth justifyContent="flex-start">
            <Text
              className={classes.errorText}
              text="Password must contain from 5 to 30 characters, upper and lower case letters."
              tx="sign.up.password.error"
              color="error"
              preset="secondTitle"
            />
          </Row>
        )}
        <Row className={classes.radioContainer}>
          <RadioItem
            active={Sex.FEMALE === user.sex}
            src={ICONS.female}
            name="sex"
            text="Female"
            tx="register.female"
            onClick={handleOnChangeSex(Sex.FEMALE)}
          />
          <RadioItem
            className={classes.radio}
            active={Sex.MALE === user.sex}
            src={ICONS.male}
            name="sex"
            text="Male"
            tx="register.male"
            onClick={handleOnChangeSex(Sex.MALE)}
          />
        </Row>
        <TextField
          className={classes.input}
          defaultValue={user.firstName}
          label="Name"
          labelTx="register.name"
          preset="border"
          type="text"
          onChange={handleOnChange('firstName')}
        />
        <Row
          className={classes.signInContainer}
          fullWidth
          wrap
          justifyContent="flex-start"
        >
          <Checkbox checked={checked} onChange={handleOnCheck} />
          <Text
            className={classes.signIn}
            color="darkBlue"
            preset="secondTitle"
            text="I have read and agreed to your"
            tx="register.read"
          />
          <NavLink
            className={classes.signIn}
            color="blue"
            preset="h6"
            text="Privacy Policy"
            to="/privacy-policy"
            tx="register.privacy.text"
          />
          <Text
            className={classes.signIn}
            color="darkBlue"
            preset="secondTitle"
            text="and"
            tx="register.first.text.read"
          />
          <NavLink
            className={classes.signIn}
            color="blue"
            preset="h6"
            text="Terms of conditions"
            to="/Terms"
            tx="register.secondary.text"
          />
        </Row>

        {user.error && (
          <Row className={classes.error} fullWidth justifyContent="flex-start">
            <Icon className={classes.errorIcon} src={ICONS.attention} />
            <Text className={classes.errorText} {...user.error} />
          </Row>
        )}

        <Button
          className={classes.submit}
          disabled={isDisabled}
          preset="primary"
          text="SIGN UP"
          textColor="white"
          textPreset="h5"
          tx="register.signUp"
          onClick={handleOnClick}
        />
        <Row className={classes.signInContainer}>
          <Text
            color="black"
            preset="secondTitle"
            text="You already have an account?"
            tx="register.haveAccount"
          />
          <NavLink
            className={classes.signIn}
            color="blue"
            preset="h6"
            text="Sign In"
            to="/sign-in"
            tx="register.signIn"
          />
        </Row>
      </Column>
    </>
  )
}
