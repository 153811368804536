import { PRODUCT_ATTRIBUTE_FRAGMENT } from './attribute'
import { PRODUCT_COLOR_FRAGMENT } from './color'
import { DELIVERY_PRICE_FRAGMENT } from './delivery-price'
import { PRODUCT_MATERIAL_FRAGMENT } from './material'
import { PRODUCT_OPTION_FRAGMENT } from './option'

export const MONEY_FRAGMENT = `
fragment MoneyFragment on Money {
  currency
  amount
}
`

export const WEIGHT_FRAGMENT = `
fragment WeightFragment on Weight {
  unit
  value
}
`

export const PRICE_FRAGMENT = `
${MONEY_FRAGMENT}
fragment Price on TaxedMoney {
  gross {
    ...MoneyFragment
  }
  net {
    ...MoneyFragment
  }
}
`

export const PRODUCT_USER_FRAGMENT = `
  fragment ProductUserFragment on User {
    id
    firstName
    lastName
    companyName
    country
  }
`

export const BASIC_CARD_PRODUCT_FRAGMENT = `
  ${PRODUCT_COLOR_FRAGMENT}
  ${PRODUCT_OPTION_FRAGMENT}
  fragment BasicCardProductFields on Product {
    id
    name
    brandName
    productionDaysUpTo
    countryOfOrigin
    materialComposition
    slug
    defaultPrice
    totalPrice
    purchaseSpecifics
    isFavorite
    options {
      ...ProductOptionFragment
    }
    colors {
      ...ProductColorFragment
    }
    thumbnail2x: thumbnail(size: 510) {
      url
      alt
    }
  }
`

export const BASIC_PRODUCT_FRAGMENT = `
  ${PRODUCT_MATERIAL_FRAGMENT}
  ${PRODUCT_COLOR_FRAGMENT}
  ${PRODUCT_ATTRIBUTE_FRAGMENT}
  ${PRODUCT_OPTION_FRAGMENT}
  ${PRODUCT_USER_FRAGMENT}
  ${DELIVERY_PRICE_FRAGMENT}
  ${WEIGHT_FRAGMENT}
  fragment BasicProductFields on Product {
    id
    name
    description
    brandName
    productionDaysUpTo
    countryOfOrigin
    materialCare
    materialComposition
    slug
    defaultPrice
    totalPrice
    purchaseSpecifics
    isFragile
    isFavorite
    weight {
      ...WeightFragment
    }
    deliveryPrice {
      ...DeliveryPriceFragment
    }
    vendor {
      ...ProductUserFragment
    }
    materials {
      ...ProductMaterialFragment
    }
    colors {
      ...ProductColorFragment
    }
    attributes {
      ...ProductAttributeFragment
    }
    options {
      ...ProductOptionFragment
    }
    thumbnail {
      url
      alt
    }
    thumbnail2x: thumbnail(size: 510) {
      url
      alt
    }
  }
`

export const PRODUCT_FRAGMENT_FOR_PREVIEW = `
  ${PRODUCT_MATERIAL_FRAGMENT}
  ${PRODUCT_COLOR_FRAGMENT}
  ${PRODUCT_ATTRIBUTE_FRAGMENT}
  ${PRODUCT_OPTION_FRAGMENT}
  ${DELIVERY_PRICE_FRAGMENT}
  fragment ProductFragmentForPreview on Product {
    id
    name
    description
    brandName
    productionDaysUpTo
    countryOfOrigin
    materialCare
    materialComposition
    slug
    defaultPrice
    totalPrice
    isFavorite
    deliveryPrice {
      ...DeliveryPriceFragment
    }
    materials {
      ...ProductMaterialFragment
    }
    colors {
      ...ProductColorFragment
    }
    attributes {
      ...ProductAttributeFragment
    }
    options {
      ...ProductOptionFragment
    }
  }
`

export const PRODUCT_FRAGMENT_FOR_OFFER = `
  ${DELIVERY_PRICE_FRAGMENT}
  fragment ProductFragmentForOffer on Product {
    id
    name
    defaultPrice
    deliveryPrice {
      ...DeliveryPriceFragment
    }
    thumbnail {
      url
      alt
    }
    thumbnail2x: thumbnail(size: 510) {
      url
      alt
    }
  }
`

export const IMAGES_FRAGMENT = `
fragment Images on ProductImage {
  id
  sortOrder
  alt
  url
}
`

export const PRODUCT_PRICING_FRAGMENT = `
${PRICE_FRAGMENT}
fragment ProductPricingField on Product {
  pricing {
    onSale
    priceRangeUndiscounted {
      start {
        ...Price
      }
      stop {
        ...Price
      }
    }
    priceRange {
      start {
        ...Price
      }
      stop {
        ...Price
      }
    }
    discount {
      currency
      gross {
        ...MoneyFragment
      }
      net {
        ...MoneyFragment
      }
      tax {
        ...MoneyFragment
      }
    }
  }
}
`
export const PRODUCT_PRICING_FRAGMENT_FOR_OFFER = `
${PRICE_FRAGMENT}
fragment ProductPricingFieldForOffer on Product {
  pricing {
    priceRange {
      stop {
        ...Price
      }
    }
  }
}
`
