import { ICONS } from '../components'

export const STORES_LINKS = {
  appStore: {
    ariaLabel: 'appStore',
    href: 'https://apps.apple.com/ua/app/ovtmt/id1545586316?l=ru',
    icon: ICONS.appStore
  },
  googlePlay: {
    ariaLabel: 'googlePlay',
    href: 'https://play.google.com/store/apps/details?id=com.ovafloinc.com&hl=ru',
    icon: ICONS.googlePlay
  }
}
