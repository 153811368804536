import React, { FC } from 'react'
import { isMobile } from 'react-device-detect'

import {
  Row,
  Column,
  Text,
  Dropdown,
  DropdownItemData
} from '../../../components'
import { getSortByValue } from '../../../pages'

import { sortData } from './helpers'
import { useStyle } from './products-header.styles'
import { ProductsHeaderProps } from './products-header.types'

export const ProductsHeader: FC<ProductsHeaderProps> = ({
  className = '',
  children,
  count,
  onSort
}) => {
  const classes = useStyle()
  const Container = isMobile ? Column : Row

  const handleOnSort = (value: DropdownItemData) => {
    if (onSort) {
      const sortBy = getSortByValue(value.id)

      onSort(sortBy)
    }
  }

  return (
    <Container
      wrap
      fullWidth
      className={className}
      justifyContent="space-between"
    >
      {children || (
        <Row fullWidth justifyContent="flex-end">
          <Row alignItems="flex-end">
            <Text
              className={classes.count}
              color="black"
              preset="h6"
              text={String(count)}
            />
            <Text
              className={classes.results}
              color="inactive"
              preset="secondTitle"
              text="results"
            />
          </Row>
          <Row className={classes.sort}>
            <Text
              className={classes.sortText}
              color="inactive"
              preset="h6"
              text="Sort by:"
            />
            <Dropdown
              className={classes.sortDropdown}
              label="Clear"
              labelTx="product.sort.clear"
              preset="body"
              withSearch={false}
              data={sortData}
              onChange={handleOnSort}
            />
          </Row>
        </Row>
      )}
    </Container>
  )
}
