import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    width: '100%',
    marginTop: 40,
    border: `1px solid ${theme.colors.shadow}`,
    borderRadius: 4
  },
  infoContainer: {
    width: 'calc(100% - 32px)',
    padding: 16,
    borderBottom: `1px solid ${theme.colors.shadow}`
  },
  infoItemsLeft: {
    width: '60%'
  },
  itemOrderNum: {
    flex: 1
  },
  orderNum: {
    lineHeight: '42px'
  },
  itemVendor: {
    flex: 2
  },
  textMarginLeft: {
    marginLeft: 8
  },

  infoItemsRight: {
    width: '40%'
  },
  itemOrderPrice: {
    flex: 2
  },
  itemButton: {
    flex: 1.5
  },
  deleteBtn: {
    width: 148,
    height: 28
  },
  header: {
    width: 'calc(100% - 32px)',
    padding: '12px 16px'
  },
  item: {
    flex: 8
  },
  headerText: {
    flex: 4
  },
  headerPrice: {
    flex: 4
  },

  headerLastText: {
    flex: 2.5
  },

  '@media screen and (min-width: 481px) and (max-width: 900px)': {
    infoItemsLeft: {
      width: '45%'
    },
    infoItemsRight: {
      width: '55%'
    },
    itemOrderNum: {
      flex: 1.5,
      maxWidth: 126
    },
    itemOrderPrice: {
      minWidth: 86
    },
    itemButton: {
      marginLeft: 12
    },
    itemVendor: {
      flex: 1.5,
      justifyContent: 'center'
    }
  },
  '@media screen and (min-width: 901px) and (max-width: 1223px)': {
    itemOrderPrice: {
      justifyContent: 'flex-start'
    },
    headerPrice: {
      flex: 3
    }
  }
}))
