import React from 'react'
import { isMobileOnly } from 'react-device-detect'

import { Button, Column, NavLink, Row, Text } from '../../components'

import { useStyle } from './become-partner.styles'

export const BecomePartnerPage = () => {
  const classes = useStyle()
  const titlePreset = isMobileOnly ? 'h1Mobile' : 'h1'
  const h2Preset = isMobileOnly ? 'h2Mobile' : 'h2'

  return (
    <Column className={classes.container} justifyContent="flex-start">
      <Column>
        <Text
          preset={titlePreset}
          text="Become a Partner"
          tx="become.partner.title.text"
        />
        <Text
          className={classes.secondTitle}
          preset="h5"
          color="inactive"
          text="Effective on October 1, 2020"
          tx="become.partner.second.title"
        />
        <Text
          className={classes.text}
          preset="body"
          text="Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet."
          tx="become.partner.text"
        />
      </Column>
      <Column fullWidth alignItems="flex-start">
        <Text
          className={classes.subTitle}
          preset={h2Preset}
          text="Want to become a seller on our marketplace?"
          tx="become.partner.sub.title.text"
        />
        <Text
          className={classes.secondText}
          preset="body"
          text="Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt."
        />
        <Text
          className={classes.thirdText}
          preset="body"
          text="Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet."
        />
      </Column>
      <Column fullWidth>
        <Button
          className={classes.button}
          preset="primary"
          textColor="white"
          textPreset="h5"
          text="GET A QUESTIONNAIRE"
          tx="become.partner.button.text"
        />
      </Column>
      <Column fullWidth className={classes.border} alignItems="flex-start">
        <Row className={classes.row} justifyContent="flex-start">
          <Text
            text="Send your suggestions to us by e-mail: "
            tx="become.partner.text.send.suggestions"
          />
          <NavLink
            color="blue"
            preset="h6"
            text="ovaflopick@gmail.com"
            to="/"
          />
        </Row>
      </Column>
    </Column>
  )
}
