import React, { FC } from 'react'

import {
  getPickedColorCode,
  getPickedColorTitle,
  getPickedMaterial
} from '../../pages'

import { getCurrencyByProduct } from '../helpers'
import {
  OrderPreviewTableHeader,
  OrderPreviewTableHeaderMobile
} from '../order-preview-table-header'
import { ResponsiveMediaView } from '../responsive-media-view'
import { ItemHeader } from '../item-header'
import { Column } from '../column'
import { Loader } from '../loader'
import { OrderPreviewOfferItem } from '../order-preview-offer-item'

import { useStyle } from './order-preview-table.styles'
import { OrderPreviewTableProps } from './order-preview-table.types'

export const OrderPreviewTable: FC<OrderPreviewTableProps> = ({
  index,
  companyName,
  totalPrice,
  products,
  vendorId,
  onRemoveOrder
}) => {
  const classes = useStyle()

  if (!products) {
    return <Loader />
  }

  const Header = (
    <OrderPreviewTableHeader
      vendorId={vendorId}
      index={index}
      companyName={companyName}
      totalPrice={totalPrice}
      onRemoveOrder={onRemoveOrder}
    />
  )

  const HeaderMobile = (
    <OrderPreviewTableHeaderMobile
      vendorId={vendorId}
      index={index}
      companyName={companyName}
      totalPrice={totalPrice}
      onRemoveOrder={onRemoveOrder}
    />
  )
  const HeaderItem = (
    <ItemHeader
      className={classes.header}
      classNameItem={classes.item}
      classNamePrice={classes.headerPrice}
      classNameQuantity={classes.headerText}
      classNameTotal={classes.headerLastText}
    />
  )

  return (
    <Column className={classes.container}>
      <ResponsiveMediaView
        bigScreen={Header}
        medium={Header}
        tablet={Header}
        mobile={HeaderMobile}
      />
      <ResponsiveMediaView
        bigScreen={HeaderItem}
        medium={HeaderItem}
        tablet={HeaderItem}
      />

      {products &&
        products.map((product, productIndex) => {
          const {
            id,
            productColors,
            quantity,
            productTotalPrice,
            productMaterials,
            productOptions,
            options
          } = product
          const currency = getCurrencyByProduct(product.product)

          const colorName = getPickedColorTitle(productColors)
          const colorCode = getPickedColorCode(productColors)

          return (
            <OrderPreviewOfferItem
              key={`${productIndex}`}
              id={String(id)}
              colorName={colorName}
              colorCode={colorCode}
              name={product.product.name}
              currency={currency}
              productTotalPrice={productTotalPrice}
              material={getPickedMaterial(productMaterials)}
              options={productOptions}
              offerOptions={options}
              defaultQuantity={quantity}
              image={product.product.thumbnail2x?.url}
              offerQuantity={quantity}
              totalPrice={product.totalPrice}
            />
          )
        })}
    </Column>
  )
}
