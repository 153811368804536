import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

import { HintRowStyleProps } from './hint-row.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    cursor: 'pointer',
    opsition: 'relative',
    '&:hover > span': {
      '-webkit-animation': '$hide 5s ease-out',
      animation: '$hide 5s ease-out'
    },
    '&:hover > svg': {
      '-webkit-animation': '$hide 5s ease-out',
      animation: '$hide 5s ease-out'
    }
  },
  text: {
    backgroundColor: theme.colors.vendorProfileFon,
    border: `1px solid ${theme.colors.shadow}`,
    borderRadius: 4,
    visibility: 'hidden',
    position: 'absolute',
    padding: 8,
    zIndex: 5,
    textAlign: 'left',
    boxShadow: `4px 8px 16px ${theme.colors.shadow}`,
    top: ({ top }: HintRowStyleProps) => (top ? [top] : 24),
    left: ({ left }: HintRowStyleProps) => (left ? [left] : 'auto')
  },
  icon: {
    position: 'absolute',
    visibility: 'hidden',
    bottom: -12,
    left: 'calc(50% - 6px)',
    top: ({ topIcon }: HintRowStyleProps) => (topIcon ? [topIcon] : 'auto')
  },
  '@keyframes hide': {
    from: { visibility: 'visible' },
    to: { visibility: 'hidden' }
  }
}))
