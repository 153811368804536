import { lazy } from 'react'

export const getComponent = (name: string) => (mod: any) => ({
  default: mod[name]
})

export const lazyComponent = <T>(
  componentImport: Promise<T>,
  component: string
) => lazy(() => componentImport.then(getComponent(component)))
