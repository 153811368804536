import React, { FC, useMemo } from 'react'
import { isMobileOnly } from 'react-device-detect'

import {
  Button,
  Column,
  getCurrencyByProduct,
  Row,
  Text,
  zeroPad
} from '../../components'
import { OfferStatus } from '../../services'

import { OfferChatItem } from './chat-item-overview'
import { useStyle } from './chat-overview.styles'
import { ChatOverviewProps } from './chat-overview.types'
import { OfferPriceChat } from './chat-price-overview'
import { OrderChatStatus } from './chat-status-overview'

export const ChatOverview: FC<ChatOverviewProps> = ({
  statusColor,
  title,
  titleTx,
  titleValues,
  statusText,
  statusTx,
  disabled,
  disabledObserve,
  isDeclinedByCutomerOrByVendor,
  offer,
  orderDelievryPrice,
  currencySymbol,
  orderTotalPrice,
  orderQuantity,
  declineText,
  declineTx,
  deleteText,
  deleteTx,
  onDecline,
  onAccept,
  onDelete,
  onReactivate
}) => {
  const classes = useStyle()
  const buttonTextPreset = isMobileOnly ? 'maxButton' : 'h5'
  const Container = isMobileOnly ? Row : Column
  const currency = getCurrencyByProduct(offer.product)

  const isStatusDraft =
    offer.status === OfferStatus.DRAFT ||
    offer.status === OfferStatus.START_OFFER

  const DeliveryPrice = useMemo(() => {
    if (orderDelievryPrice) {
      return orderDelievryPrice
    }
    if (offer.deliveryPrice && offer.deliveryPrice !== null) {
      return offer.deliveryPrice
    }
    return 0
  }, [offer?.deliveryPrice, orderDelievryPrice])

  const totalPrice =
    typeof orderTotalPrice !== 'undefined' ? orderTotalPrice : offer.totalPrice
  const totalOfferPrice = offer.productTotalPrice - offer.discount
  const quantity =
    typeof orderQuantity !== 'undefined' ? orderQuantity : offer.quantity

  const buttonText =
    offer.status === OfferStatus.ACCEPTED_BACK_FROM_CART
      ? 'Add To Cart'
      : 'Accept'
  const buttonTx =
    offer.status === OfferStatus.ACCEPTED_BACK_FROM_CART
      ? 'btn.add.to.cart'
      : 'btn.accept'

  return (
    <Column className={classes.container}>
      <OrderChatStatus
        isStatusDraft={isStatusDraft}
        color={statusColor}
        title={title}
        titleTx={titleTx}
        titleValues={titleValues}
        statusText={statusText}
        statusTx={statusTx}
      />
      <Column
        fullWidth
        alignItems="flex-start"
        justifyContent="flex-start"
        className={classes.productContainer}
      >
        <Container
          fullWidth
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <img className={classes.avatar} src={offer.product.thumbnail2x.url} />
          <Column fullWidth alignItems="flex-start">
            <Text
              className={classes.offerName}
              preset="h5"
              text={offer.product.name}
            />
            {offer.items &&
              offer.items.map((optionItem, index) => {
                const initialIndex = index + 1
                const itemNumber = zeroPad(initialIndex, 2)

                return (
                  <OfferChatItem
                    key={`option_${optionItem}_${index}`}
                    currency={currency}
                    count={itemNumber}
                    disabled={disabledObserve}
                    description={offer.description}
                    productDefaultPrice={offer.product.defaultPrice}
                    options={optionItem.options}
                    discount={offer.discount}
                    noSuggestion={offer.noSuggestion}
                    offerOptions={optionItem.productOptions}
                    offerColors={optionItem.productColors}
                    offerMaterials={optionItem.productMaterials}
                    isStatusDraft={isStatusDraft}
                    itemQuantity={optionItem.quantity}
                    totalItemPrice={optionItem.totalItemPrice}
                  />
                )
              })}
          </Column>
        </Container>
        <OfferPriceChat
          className={classes.options}
          currencySymbol={currencySymbol}
          price={totalOfferPrice}
          deliveryPrice={DeliveryPrice}
          totalPrice={totalPrice}
          quantity={quantity}
        />
        <Row fullWidth className={classes.buttonContainer}>
          {onDecline && !isDeclinedByCutomerOrByVendor && (
            <Button
              color="darkBlue"
              textColor="darkBlue"
              textPreset={buttonTextPreset}
              preset="secondaryBorder"
              text={declineText}
              tx={declineTx}
              onClick={onDecline}
            />
          )}
          {onDelete && isDeclinedByCutomerOrByVendor && (
            <Button
              color="darkBlue"
              textColor="darkBlue"
              textPreset={buttonTextPreset}
              preset="secondaryBorder"
              text={deleteText}
              tx={deleteTx}
              onClick={onDelete}
            />
          )}
          {onAccept && !isDeclinedByCutomerOrByVendor && !isStatusDraft && (
            <Button
              className={classes.accept}
              disabled={disabled}
              textColor="white"
              textPreset={buttonTextPreset}
              preset="primary"
              text={buttonText}
              tx={buttonTx}
              onClick={onAccept}
            />
          )}
          {onReactivate && isDeclinedByCutomerOrByVendor && (
            <Button
              className={classes.accept}
              textColor="white"
              textPreset={buttonTextPreset}
              preset="primary"
              text="Reactivate"
              tx="btn.reactivate"
              onClick={onReactivate}
            />
          )}
        </Row>
      </Column>
    </Column>
  )
}
