import React, { FC, useRef, useState } from 'react'
import { useTheme } from 'react-jss'

import { Column, Row, Icon, ColorDot, ICONS } from '../../../components'
import { LightTheme } from '../../../theme'

import { CarouselProps, ScrollDirectionVertical } from './carousel.types'
import { useStyle } from './carousel.styles'

export const Carousel: FC<CarouselProps> = ({
  className = '',
  data,
  onClick
}) => {
  const scrollContainer = useRef<HTMLDivElement>(null)
  const theme: LightTheme = useTheme()
  const classes = useStyle() // carousel
  const [active, changeActive] = useState(0)
  const carousel = data.length > 4

  const handleOnClick = (direction: ScrollDirectionVertical) => () => {
    const dividerScroll = 1.5

    const scrollElement = scrollContainer.current
    if (scrollElement) {
      const { offsetHeight, scrollTop } = scrollElement
      const scrollHeight = offsetHeight / dividerScroll
      const topPosition =
        direction === ScrollDirectionVertical.TOP
          ? scrollTop - scrollHeight
          : scrollTop + scrollHeight

      scrollElement.scroll({ top: topPosition, behavior: 'smooth' })
    }
  }

  const handleOnClickTop = handleOnClick(ScrollDirectionVertical.TOP)
  const handleOnClickBottom = handleOnClick(ScrollDirectionVertical.BOTTOM)

  const handleOnClickImage = (id: number) => () => {
    changeActive(id)
    onClick(data[id])
  }

  const colors = Array.from({ length: data.length }, () => theme.colors.shadow)
  const visibilityColorDot = data.length > 1

  return (
    <Row fullWidth className={className}>
      <Column>
        {visibilityColorDot &&
          colors.map((color: string, idx: number) => {
            const isActive = idx === active
            const dotColor = isActive ? theme.colors.yellow : color

            return (
              <ColorDot
                key={`image_color_${idx}`}
                className={classes.color}
                color={dotColor}
                onClick={handleOnClickImage(idx)}
              />
            )
          })}
      </Column>
      <Column className={classes.imgColumn} fullWidth alignItems="flex-start">
        {carousel && (
          <Row
            className={`${classes.top} ${classes.arrowContainer}`}
            onClick={handleOnClickTop}
          >
            <Icon src={ICONS.arrowTop} />
          </Row>
        )}
        <Column
          className={classes.carouselContainer}
          justifyContent="flex-start"
          ref={scrollContainer}
        >
          {data.map(({ url, alt }, idx) => {
            const isSelected = Number(idx) === active
            const selectedClass = isSelected ? `${classes.selected}` : ''

            return (
              <img
                key={`image_${url}`}
                alt={alt}
                className={`${classes.image} ${selectedClass}`}
                src={url}
                width={200}
                height={180}
                onClick={handleOnClickImage(idx)}
              />
            )
          })}
        </Column>
        {carousel && (
          <Row
            className={`${classes.bottom} ${classes.arrowContainer}`}
            onClick={handleOnClickBottom}
          >
            <Icon src={ICONS.arrowTop} />
          </Row>
        )}
      </Column>
    </Row>
  )
}
