import { createUseStyles } from 'react-jss'
import { isMobileOnly } from 'react-device-detect'
import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    width: isMobileOnly ? 'calc(100% - 32px)' : '100%',
    padding: isMobileOnly ? '16px 16px 8px 16px' : '0 0 24px',
    alignSelf: 'center',
    zIndex: 2,
    marginTop: 16
  },
  paypal: {
    display: 'flex',
    justifyContent: 'center',
    width: 400
  },
  title: {
    marginTop: isMobileOnly && 16,
    width: isMobileOnly && '100%',
    textAlign: 'center'
  },

  bottomText: {
    marginTop: isMobileOnly && 16,
    textAlign: isMobileOnly && 'center',
    marginLeft: !isMobileOnly && 16
  },
  error: {
    marginBottom: !isMobileOnly && 16
  },
  errorText: {
    marginTop: isMobileOnly && 16,
    marginBottom: isMobileOnly && 16,
    marginLeft: !isMobileOnly && 16,
    textAlign: isMobileOnly && 'center'
  },

  link: {
    padding: '0 8px',
    '&:hover': {
      textDecoration: 'underline',
      color: theme.colors.blue
    }
  },
  track: {
    alignItems: !isMobileOnly && 'baseline'
  }
}))
