import React, { FC } from 'react'

import { arrayHasElements, Row, Text } from '../../../components'

import { DetailsBlock } from '../details-block'
import { ProductAttributes } from '../product-attributes'
import { ProductFeatures } from '../product-features'
import { ProductOptions } from '../product-options'

import { ProductDetailsProps } from './product-details.types'
import { useStyle } from './product-details.styles'

export const ProductDetails: FC<ProductDetailsProps> = ({ id, product }) => {
  const classes = useStyle()

  const {
    minCurrency,
    colors = [],
    materials = [],
    options = [],
    attributes = [],
    description = ''
  } = product

  return (
    <Row fullWidth alignItems="flex-start" className={classes.characteristics}>
      <DetailsBlock title="Available Options" titleTx="product.options">
        <ProductOptions
          currency={minCurrency}
          id={id}
          materials={materials}
          colors={colors}
          options={options}
        />
      </DetailsBlock>
      <DetailsBlock title="Product features" titleTx="product.features">
        <ProductFeatures product={product} />
      </DetailsBlock>
      {arrayHasElements(attributes) && (
        <DetailsBlock title="Attributes" titleTx="product.attributes">
          <ProductAttributes id={id} attributes={attributes} />
        </DetailsBlock>
      )}
      <DetailsBlock title="Description" titleTx="product.description">
        <Text text={description || '-'} />
      </DetailsBlock>
    </Row>
  )
}
