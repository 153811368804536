import React, { FC } from 'react'

import { Text } from '../text'
import { Row } from '../row'
import { Column } from '../column'

import { useStyle } from './general-item-line.styles'
import { GeneralItemLineProps } from './general-item-line.types'

export const GeneralItemLine: FC<GeneralItemLineProps> = ({
  productName,
  img,
  children
}) => {
  const classes = useStyle()

  return (
    <Row className={classes.container} justifyContent="space-between">
      <Row
        fullWidth
        className={classes.imageContainer}
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <img className={classes.image} src={img} />

        <Column alignItems="normal" className={classes.name}>
          <Text
            className={classes.text}
            color="black"
            preset="h5"
            text={productName}
          />
        </Column>
      </Row>
      {children}
    </Row>
  )
}
