import React, {
  forwardRef,
  ReactNode,
  UIEventHandler,
  useMemo,
  useRef
} from 'react'
import { useSwipeable } from 'react-swipeable'
import { isMobileOnly } from 'react-device-detect'

import { useDebounce } from '../../hooks'

import { Icon, ICONS } from '../icon'
import { Row } from '../row'
import { Text } from '../text'
import { Column } from '../column'
import { ResponsiveMediaView } from '../responsive-media-view'
import { NavLink } from '../nav-link'

import { ItemsLineProps, ScrollDirection } from './items-line.types'
import { useStyle } from './items-line.styles'

export const ItemsLine = forwardRef<HTMLDivElement, ItemsLineProps>(
  (
    {
      className = '',
      carousel,
      children,
      itemsLength,
      description,
      descriptionTx,
      header,
      title,
      titlePreset,
      titleTx,
      treshold = 0.5,
      to,
      onLoadNext
    },
    ref
  ) => {
    const scrollContainer = useRef<HTMLDivElement>(null)
    const prevScrollLeft = useRef<number>(0)

    const width = window.innerWidth

    const isShowArrow = useMemo(() => {
      if (width < 480) {
        return 10
      }

      if (width > 700 && width < 1024) {
        return 2
      }

      if (width >= 1024 && width < 1224) {
        return 3
      }

      if (width >= 1224 && width < 1720) {
        return 4
      }

      return 5
    }, [width])

    const arrowAvaliable = itemsLength > isShowArrow
    const classes = useStyle({ carousel, arrowAvaliable })
    const handleOnLoadNext = (offset: number, left: number) => {
      let procentScrollToLoad = 0.5

      if (treshold > 0 && treshold < 1) {
        procentScrollToLoad = treshold
      }

      if (offset * procentScrollToLoad < left && onLoadNext) {
        onLoadNext()
      }
    }

    const handleOnClick = (direction: ScrollDirection) => () => {
      const dividerScroll = isMobileOnly ? 1.5 : 3

      const scrollElement = scrollContainer.current
      if (scrollElement) {
        const {
          offsetWidth,
          scrollLeft,
          scrollWidth: currentScroll
        } = scrollElement
        const scrollWidth = offsetWidth / dividerScroll
        const leftPosition =
          direction === ScrollDirection.LEFT
            ? scrollLeft - scrollWidth
            : scrollLeft + scrollWidth

        handleOnLoadNext(currentScroll, leftPosition)

        prevScrollLeft.current = scrollLeft
        scrollElement.scroll({ left: leftPosition, behavior: 'smooth' })
      }
    }

    const handleOnClickLeft = handleOnClick(ScrollDirection.LEFT)
    const handleOnClickRight = handleOnClick(ScrollDirection.RIGHT)

    const swipeableConfig = {
      delta: 20,
      preventDefaultTouchmoveEvent: false,
      trackTouch: isMobileOnly,
      trackMouse: true,
      rotationAngle: 0
    }

    const handlers = useSwipeable({
      onSwipedLeft: handleOnClickRight,
      onSwipedRight: handleOnClickLeft,
      ...swipeableConfig
    })

    const leftCarouselButton = (
      <Row className={classes.arrowContainer} onClick={handleOnClickLeft}>
        <Icon src={ICONS.arrowLeft} />
      </Row>
    )

    const rightCarouselButton = (
      <Row
        className={`${classes.right} ${classes.arrowContainer}`}
        onClick={handleOnClickRight}
      >
        <Icon src={ICONS.arrowLeft} />
      </Row>
    )

    const renderArrow = (arrow: ReactNode) => {
      if (!carousel) {
        return null
      }

      if (!arrowAvaliable) {
        return null
      }

      return (
        <ResponsiveMediaView bigScreen={arrow} medium={arrow} tablet={arrow} />
      )
    }

    const handleOnScrollEnd: UIEventHandler<HTMLDivElement> = (event) => {
      const { scrollLeft, scrollWidth } = event.target as HTMLDivElement

      if (prevScrollLeft.current > scrollLeft) {
        handleOnLoadNext(scrollWidth, scrollLeft)
      }

      prevScrollLeft.current = scrollLeft
    }

    const handleOnScroll = useDebounce(handleOnScrollEnd, 500)

    return (
      <Column fullWidth className={className} ref={ref}>
        <NavLink
          color="black"
          preset={titlePreset}
          text={title}
          tx={titleTx}
          to={to}
        />
        {description && (
          <Text
            color="inactive"
            preset="h7"
            text={description}
            tx={descriptionTx}
          />
        )}
        {header}

        <Row fullWidth className={classes.row} {...handlers}>
          {renderArrow(leftCarouselButton)}

          <Row
            fullWidth
            className={classes.carouselContainer}
            alignItems="flex-start"
            ref={scrollContainer}
            onScroll={handleOnScroll}
          >
            {children}
          </Row>

          {renderArrow(rightCarouselButton)}
        </Row>
      </Column>
    )
  }
)
