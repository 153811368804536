import React, { useState, ChangeEvent, useMemo, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import {
  Button,
  Column,
  Icon,
  ICONS,
  NavLink,
  Row,
  Text,
  TextField,
  TextProps
} from '../../components'
import { useApi } from '../../providers'
import { GetTokenCreateRequest } from '../../services'

import { validateEmail } from '../helper'

import { useStyle } from './forgot-password.styles'

const DEFAULT_USER_STATE: GetTokenCreateRequest = {
  email: '',
  password: '',
  error: undefined
}

export const ForgotPasswordPage = () => {
  const history = useHistory()
  const handleOnNavigate = (path: string) => () => {
    history.push(path)
  }
  const [user, changeUserData] =
    useState<GetTokenCreateRequest>(DEFAULT_USER_STATE)
  const [isSent, changeIsSent] = useState<boolean>(false)
  const { auth } = useApi()

  const { response, onSubmit } = auth.useIsUserExist()
  const { onSubmit: onPasswordReset } = auth.useRequestResetPassword()

  const classes = useStyle()

  const isDisabled = !user.email

  const IsUserExists = useMemo(() => {
    if (response.data) {
      return response.data.isUserExists.isUserExists
    }

    return undefined
  }, [response.data])

  useEffect(() => {
    if (user.email) {
      onSubmit({ email: user.email })
    }
  }, [user.email])

  const handleOnChange =
    (prop: keyof GetTokenCreateRequest) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target
      changeUserData({
        ...user,
        [prop]: value,
        error: undefined
      })
    }

  const handleOnClick = () => {
    onSubmit({ email: user.email })
    const isEmail = validateEmail(user.email)

    if (isEmail && IsUserExists) {
      changeIsSent(true)
      onPasswordReset(user.email)
    } else {
      const userError: TextProps = {
        text: "Email does't exist or incorrect! Try again!",
        color: 'error',
        tx: 'forgot.password.error.message',
        preset: 'body'
      }
      changeUserData({ ...user, error: userError })
      changeIsSent(false)
    }
  }

  return (
    <Column fullWidth className={classes.container}>
      {isSent ? (
        <>
          <Column className={classes.forgotForm}>
            <Text
              preset="h1"
              text="Forgot Password?"
              tx="forgot.password.title"
            />
            <Text
              className={classes.forgotSecondaryText}
              color="inactive"
              preset="h7"
              text="An email with instructions to reset your password has been sent to"
              tx="forgot.password.text.is.sent"
            />
            <Text
              className={classes.email}
              color="blue"
              preset="h6"
              text={user.email}
            />
            <Button
              className={classes.logIn}
              text="LOG IN"
              tx="forgot.password.button.log.in"
              preset="primary"
              textColor="white"
              textPreset="h5"
              onClick={handleOnNavigate('/sign-in')}
            />
            <Row className={classes.signUpContainer}>
              <Text
                color="black"
                preset="secondTitle"
                text="Don’t have an account?"
              />
              <NavLink
                className={classes.signUp}
                color="blue"
                preset="h6"
                text="Sign Up"
                to="/sign-up"
              />
            </Row>
          </Column>
        </>
      ) : (
        <>
          <Column className={classes.forgotForm}>
            <Text
              preset="h1"
              text="Forgot Password?"
              tx="forgot.password.title"
            />
            <Text
              className={classes.forgotSecondaryText}
              color="inactive"
              preset="h7"
              text="Enter the email associated with your account, and we'll send an email with instructions to reset your password."
              tx="forgot.password.text"
            />
            <TextField
              className={classes.input}
              label="E-mail"
              labelTx="forgot.password.email"
              preset="border"
              type="text"
              textInputPreset="h7"
              onChange={handleOnChange('email')}
            />
            {user.error && (
              <Row
                className={classes.error}
                fullWidth
                justifyContent="flex-start"
              >
                <Icon className={classes.errorIcon} src={ICONS.attention} />
                <Text className={classes.errorText} {...user.error} />
              </Row>
            )}
            <Button
              className={classes.submit}
              disabled={isDisabled}
              text="SEND"
              tx="forgot.password.button.sent"
              preset="primary"
              textColor="white"
              textPreset="h5"
              onClick={handleOnClick}
            />
            <Row className={classes.signUpContainer}>
              <Text
                color="black"
                preset="secondTitle"
                text="Don’t have an account?"
                tx="forgot.password.no.account"
              />
              <NavLink
                className={classes.signUp}
                color="blue"
                preset="h6"
                text="Sign Up"
                tx="forgot.password.button.signUp"
                to="/sign-up"
              />
            </Row>
          </Column>
        </>
      )}
    </Column>
  )
}
