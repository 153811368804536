import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  characteristics: {
    width: 'calc(100% - 32px)',
    marginTop: 48,
    padding: '0 16px',
    '& > :not(:first-child)': {
      marginLeft: 20
    }
  },
  optionsRow: {
    '&:not(:first-child)': {
      marginTop: 4
    }
  },
  detailsColumn: {
    marginTop: 24
  },

  '@media screen and (max-width: 1440px) and (max-width: 90%)': {
    characteristics: {
      flexWrap: 'wrap',
      '& > :not(:first-child)': {
        marginLeft: 0
      }
    }
  }
})
