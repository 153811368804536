import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

import background from '../../assets/images/wishListBackground.png'
import backgroundImg from '../../assets/images/wishListBackgroundImage.png'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    backgroundColor: theme.colors.white,
    backgroundImage: `url(${background})`,
    minHeight: 600
  },
  image: {
    backgroundSize: '70%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center left 200px',
    backgroundImage: `url(${backgroundImg})`,
    zIndex: 1,
    width: 724,

    height: 410
  },
  button: {
    marginTop: 24,
    width: 264,
    padding: 12,
    backgroundColor: theme.colors.yellow
  },
  text: {},
  title: {
    padding: '8px 0'
  },
  subText: {
    marginTop: 16
  },
  header: {},
  titleIcon: {
    width: 'calc(100% - 24px)'
  },
  icon: {
    cursor: 'pointer'
  },
  row: {
    marginTop: 26
  },
  badgeRow: {
    marginTop: 24
  },
  emptyButton: {
    cursor: 'pointer',
    marginTop: 32,
    width: 312,
    padding: 16
  },
  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    image: {
      backgroundSize: '70%',
      backgroundPosition: 'center left 170px',
      height: 400
    }
  },
  '@media screen and (max-width: 480px)': {
    container: {
      minHeight: 400
    },
    image: {
      backgroundSize: '100%',
      backgroundPosition: 'center left 50px',
      width: '100vw',
      height: 300
    },
    text: {
      textAlign: 'center'
    },
    emptyButton: {
      padding: '16px 26px'
    }
  }
}))
