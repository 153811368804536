import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import { getPathByCategory } from '../../utils'

import { CategoryCard } from '../category-card'
import { ItemsLine } from '../items-line'

import { useStyle } from './category-line.styles'
import { CategoryLineProps } from './category-line.types'

export const CategoryLine: FC<CategoryLineProps> = ({
  carousel,
  className = '',
  data,
  title,
  titleTx,
  titlePreset,
  onLoadNext
}) => {
  const classes = useStyle()

  return (
    <ItemsLine
      carousel={carousel}
      className={className}
      itemsLength={data.length}
      title={title}
      titleTx={titleTx}
      titlePreset={titlePreset}
      onLoadNext={onLoadNext}
    >
      {data.map(({ id, name, backgroundImage, children: categoryChildren }) => {
        const pathname = getPathByCategory(id, categoryChildren.edges)

        const to = { pathname }

        return (
          <Link key={`category${id}`} className={classes.category} to={to}>
            <CategoryCard
              id={id}
              name={name}
              backgroundImage={backgroundImage}
            />
          </Link>
        )
      })}
    </ItemsLine>
  )
}
