import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  line: {
    borderTop: `1px solid ${theme.colors.shadow}`,
    padding: '8px 0',
    '&:not(:first-child)': {
      marginTop: 16
    }
  },
  itemNumber: {},
  itemTitle: {
    marginLeft: 8,
    minWidth: 104
  },
  observeOptionsBtn: {
    padding: '8px 6px'
  },
  column: {
    marginTop: 8
  },
  itemRow: {},
  infoRow: {}
}))
