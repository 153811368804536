import React, { FC } from 'react'

import { useTheme } from 'react-jss'
import { useHistory } from 'react-router-dom'

import { getColorByMainStatus, getMessageByMainStatus } from '../../../pages'
import { LightTheme } from '../../../theme'

import { Row } from '../../row'
import { Column } from '../../column'
import { Text } from '../../text'
import { Button } from '../../button'
import { ColorDot } from '../../color-dot'
import { ResponsiveMediaView } from '../../responsive-media-view'

import { useStyle } from './purchase-slider-header-open.styles'
import { PurchaseSliderHeaderOpenProps } from './purchase-slider-header-open.types'

export const PurchaseSliderHeaderOpen: FC<PurchaseSliderHeaderOpenProps> = ({
  id,
  number,
  country,
  created,
  status,
  totalPrice,
  companyName,
  onContactVendor
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle()
  const history = useHistory()
  const orderIdText = `# ${number}`

  const handleOnNavigate = () => {
    history.push(`/feedback/${id}`)
  }

  const VendorAndCountry = (
    <>
      <Column className={classes.details} alignItems="flex-start">
        <Text color="inactive" preset="body" text="Company:" />
        <Row fullWidth justifyContent="flex-start">
          <Text
            className={classes.status}
            color="black"
            preset="h7"
            text={companyName}
          />
        </Row>
      </Column>
      <Column className={classes.details} alignItems="flex-start">
        <Text color="inactive" preset="body" text="Country" />
        <Text
          className={classes.status}
          color="black"
          preset="h7"
          text={country}
        />
      </Column>
    </>
  )

  return (
    <Row fullWidth justifyContent="space-between">
      <Row
        fullWidth
        justifyContent="space-between"
        alignItems="flex-start"
        className={classes.detailsRow}
      >
        <Row className={classes.statusRow}>
          <ColorDot
            preset="big"
            color={theme.colors[getColorByMainStatus(status)]}
          />
          <Column className={classes.statusColumn} alignItems="flex-start">
            <Text color="inactive" preset="body" text={orderIdText} />
            <Text
              {...getMessageByMainStatus(status)}
              className={classes.status}
              color="black"
              preset="h7"
            />
          </Column>
        </Row>
        <Column className={classes.details} alignItems="flex-start">
          <Text
            color="inactive"
            preset="body"
            text="Total Price"
            tx="order.item.price.title"
          />
          <Text
            className={classes.status}
            color="black"
            preset="h7"
            text={String(totalPrice)}
          />
        </Column>
        <ResponsiveMediaView
          bigScreen={VendorAndCountry}
          medium={VendorAndCountry}
        />

        <Column className={classes.details} alignItems="flex-start">
          <Text color="inactive" preset="body" text="Date of creation" />
          <Text
            className={classes.status}
            color="black"
            preset="h7"
            text={created}
          />
        </Column>
      </Row>

      <Row className={classes.buttonsRow} justifyContent="flex-end">
        <Button
          className={classes.buttonBorder}
          textColor="yellow"
          textPreset="maxButton"
          preset="borderButton"
          text="Contact Maker"
          tx="purchase.item.contact.maker"
          onClick={onContactVendor}
        />
        <Button
          className={classes.button}
          color="yellow"
          textColor="white"
          textPreset="maxButton"
          preset="button3"
          text="Give Feedback"
          tx="purchase.item.feedback"
          onClick={handleOnNavigate}
        />
      </Row>
    </Row>
  )
}
