import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'
import { v4 as uuid } from 'uuid'

import {
  Content,
  Row,
  Column,
  Loader,
  ConstructorSlider,
  getCurrencySymbol,
  ProductBreadcrumbs,
  ProductInfoBottomBar,
  ResponsiveMediaView
} from '../../components'
import {
  getProductWithOptionPrice,
  getProductWithOptionPriceByQuantity,
  useApi,
  useBasket,
  useToastify
} from '../../providers'
import { Offer, ProductOptionType, ProductOptionValue } from '../../services'

import {
  reduceProductItemToProduct,
  OrderProps,
  ProductProps
} from '../product'
import { getPriceToFixed } from '../helper'

import { PriceSummary } from './price-summary'
import { ProductInfo } from './product-info'
import { useStyle } from './product-constructor.styles'
import { getModificatorValue } from './helper'
import { OptionsState } from './product-constructor.types'
import { OptionSlider } from './options-slider'

export const ProductConstructorPage = () => {
  const classes = useStyle()
  const {
    product: { useProductById }
  } = useApi()

  const { id } = useParams<ProductProps>()
  const { data } = useProductById({ id })
  const [option, changeOption] = useState<OptionsState>({
    material: '',
    color: '',
    options: []
  })
  const [order, changeOrder] = useState<OrderProps>({ quantity: 1 })
  const { onAddProduct } = useBasket()
  const { open } = useToastify()

  useEffect(() => {
    if (data?.product.options) {
      const nextOptions = data.product.options.map((optionsItem) => ({
        ...optionsItem,
        variants:
          optionsItem.type === ProductOptionType.RADIOBUTTON
            ? optionsItem.variants.filter((variant) => variant.isDefault)
            : []
      }))
      changeOption({ ...option, options: nextOptions })
    }
  }, [data?.product.options])

  const Product = useMemo(() => {
    if (data) {
      return reduceProductItemToProduct(data)
    }

    return null
  }, [data])

  const Options = useMemo(() => {
    if (Product?.options) {
      return Product?.options.map((optionItem) => {
        const active = option.options.find((item) => item.id === optionItem.id)

        return {
          ...optionItem,
          variant: getModificatorValue(active?.variant?.id, optionItem.variants)
        }
      })
    }

    return []
  }, [Product?.options, option.options])

  const Material = useMemo(
    () => getModificatorValue(option.material, Product?.materials),
    [Product?.materials, option.material]
  )

  const Color = useMemo(
    () => getModificatorValue(option.color, Product?.colors),
    [Product?.colors, option.color]
  )

  const handleOnClick = (stateToastify: boolean) => () => {
    if (stateToastify) {
      open({
        text: 'Action is successful.',
        tx: 'toastify.context.text.successful'
      })
    }
  }

  if (!data || !Product) {
    return <Loader />
  }
  const titlePreset = isMobileOnly ? 'h2Mobile' : 'h2'
  const {
    colors = [],
    materials = [],
    options = [],
    defaultPrice,
    minCurrency,
    name,
    thumbnail2x,
    isSale,
    discountMaxPrice
  } = Product
  const minCurrencySymbol = getCurrencySymbol(minCurrency)

  const getPriceByQuantity = (price: number = 0, quantity: number) =>
    getPriceToFixed(Number(price * quantity))

  const getFullPrice = (price: number, quantity: number, currency?: string) =>
    `${getPriceByQuantity(price, quantity)} ${currency}`

  const getDefaultPriceByQuantity = (quantity: number) =>
    getFullPrice(defaultPrice, quantity, minCurrencySymbol)

  const getSalePriceByQuantity = (quantity: number) =>
    getFullPrice(discountMaxPrice, quantity, minCurrencySymbol)

  const materialExtraPrice = getPriceByQuantity(Material?.extraPrice, 1)

  const getMaterialPrice = (quantity: number) =>
    getFullPrice(Number(materialExtraPrice), quantity, minCurrencySymbol)

  const colorExtraPrice = getPriceByQuantity(Color?.extraPrice, 1)

  const getColorPrice = (quantity: number) =>
    getFullPrice(Number(colorExtraPrice), quantity, minCurrencySymbol)

  const optionCheckedPrice = option.options.map((optionItem) => {
    const optionItemByType = optionItem.type === ProductOptionType.CHECKBOX

    if (optionItemByType && optionItem.variants) {
      const extraOptionPrice = optionItem.variants.map((itemVariant) => {
        const exstraPriceCheckedOption = itemVariant.extraPrice
        return exstraPriceCheckedOption
      })

      const extraPrice = extraOptionPrice?.reduce((acc, checkedItem) => {
        return acc + checkedItem
      }, 0)

      return extraPrice
    }

    return 0
  })

  const getTotalPrice = (price: number, quantity: number) => {
    const optionsPrice = Options.reduce((acc, optionItem) => {
      const extraPrice = optionItem.variant?.extraPrice || 0

      return acc + extraPrice
    }, 0)

    const allCheckedOptionsPrice = optionCheckedPrice.reduce((acc, item) => {
      const allOptionExtraPrice = item

      return acc + allOptionExtraPrice
    }, 0)

    const fullNumberPrice =
      price +
      Number(Material?.extraPrice || 0) +
      Number(Color?.extraPrice || 0) +
      optionsPrice +
      allCheckedOptionsPrice

    return getFullPrice(fullNumberPrice, quantity, minCurrencySymbol)
  }

  const getDefaultPrice = (quantity: number) => {
    if (onAddProduct && data && Color && Material) {
      return getProductWithOptionPriceByQuantity(
        {
          ...data.product,
          count: order.quantity,
          orderColor: Color,
          orderMaterial: Material,
          orderOptions: option.options
        },
        quantity
      ).toFixed(2)
    }

    return '0.00'
  }

  const getProductTotalPrice = () => {
    if (data && Color && Material) {
      return getProductWithOptionPrice({
        ...data.product,
        count: order.quantity,
        orderColor: Color,
        orderMaterial: Material,
        orderOptions: option.options
      }).toFixed(2)
    }

    return '0.00'
  }

  const handleOnAddToBasket = () => {
    const openToastify = handleOnClick(true)
    if (onAddProduct && data && Color && Material) {
      const offer = {
        id: uuid(),
        product: data.product,
        vendor: data.product.vendor,
        quantity: order.quantity,
        productMaterials: data.product.materials,
        productColors: data.product.colors,
        productOptions: data.product.options,
        options: [],
        productTotalPrice: Number(getProductTotalPrice()),
        totalPrice: Number(getDefaultPrice(order.quantity)),
        __typename: 'Offer'
      } as unknown as Offer

      if (onAddProduct) {
        onAddProduct(offer)
      }
      openToastify()
    }
  }
  const getSaleTotalPrice = (quantity: number) =>
    getTotalPrice(discountMaxPrice, quantity)

  const handleOnSwitcherChange = (value: number) => {
    changeOrder({
      ...order,
      quantity: value
    })
  }

  const handleOnChangeSliderRadio =
    (type: 'color' | 'material') => (activeId: string) => {
      changeOption({
        ...option,
        [type]: activeId
      })
    }

  const handleOnChangeOption = (value: ProductOptionValue) => {
    const filterOptions = option.options.filter((item) => item.id !== value.id)
    const nextValue = {
      ...option,
      options: [...filterOptions, value]
    }

    changeOption(nextValue)
  }

  return (
    <>
      <Content
        className={classes.mainContainer}
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <ProductBreadcrumbs
          className={classes.breadcrumbs}
          product={data.product}
        />
        <Row className={classes.container} fullWidth alignItems="flex-start">
          <Column
            className={classes.options}
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <ProductInfo
              className={classes.productInfo}
              image={thumbnail2x}
              title={name}
              titlePreset={titlePreset}
              salePriceByQuantity={getSalePriceByQuantity(order.quantity)}
              priceByQuantity={getDefaultPriceByQuantity(order.quantity)}
              isSale={isSale}
              onChange={handleOnSwitcherChange}
            />
            <ConstructorSlider
              className={classes.table}
              title="Material Option"
              tx="product.material.options"
              data={materials}
              onChangeRadio={handleOnChangeSliderRadio('material')}
            />
            <ConstructorSlider
              className={classes.table}
              title="Color Options"
              tx="product.color.options"
              data={colors}
              onChangeRadio={handleOnChangeSliderRadio('color')}
            />
            {options.map((optionItem) => (
              <OptionSlider
                key={`option_${name}_${optionItem.title}`}
                className={classes.table}
                option={optionItem}
                onChange={handleOnChangeOption}
              />
            ))}
          </Column>

          <PriceSummary
            salePriceByQuantity={getSalePriceByQuantity(order.quantity)}
            priceByQuantity={getDefaultPriceByQuantity(order.quantity)}
            materialTitle={Material?.title}
            materialPrice={getMaterialPrice(order.quantity)}
            colorCode={Color?.color.code}
            colorTitle={Color?.title}
            colorPrice={getColorPrice(order.quantity)}
            options={Options}
            optionsValue={option}
            checkboxOptions={option.options}
            currency={minCurrencySymbol}
            quantity={order.quantity}
            saleTotalPrice={getSaleTotalPrice(order.quantity)}
            totalPrice={getDefaultPrice(order.quantity)}
            isSale={isSale}
            onClick={handleOnAddToBasket}
          />
        </Row>
      </Content>
      <ResponsiveMediaView
        tablet={
          <ProductInfoBottomBar
            name={name}
            price={getDefaultPrice(order.quantity)}
            buttonTitle="ADD TO BASKET"
            buttonTitleTx="product.addToBasket"
            onClick={handleOnAddToBasket}
          />
        }
      />
    </>
  )
}
