import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'react-jss' // App.js
import { PayPalScriptProvider } from '@paypal/react-paypal-js'

import {
  ApiProvider,
  ChatProvider,
  LocaleProvider,
  SignInModalProvider,
  ToastifyProvider
} from './providers'
import { MainRouter } from './routes'
import { lightTheme } from './theme'
import { config } from './config'

const App = () => {
  const paypalOptions = {
    'client-id': config.PAYPAL_CLIENT_ID,
    currency: 'USD',
    components: 'buttons'
  }

  useEffect(() => {
    const iframes = document.getElementsByTagName('iframe')
    const firstIframe = iframes[0]

    if (firstIframe) {
      // @ts-ignore
      firstIframe.remove()
    }
  }, [])

  return (
    <BrowserRouter>
      <PayPalScriptProvider options={paypalOptions}>
        <ThemeProvider theme={lightTheme}>
          <ApiProvider>
            <LocaleProvider>
              <ChatProvider>
                <SignInModalProvider>
                  <div id="modal" />
                  <div id="choose-modal" />
                  <div id="searchs" />
                  <div id="logout" />
                  <ToastifyProvider>
                    <MainRouter />
                  </ToastifyProvider>
                </SignInModalProvider>
              </ChatProvider>
            </LocaleProvider>
          </ApiProvider>
        </ThemeProvider>
      </PayPalScriptProvider>
    </BrowserRouter>
  )
}

export default App
