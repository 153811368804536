import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  column: {
    padding: '0 16px 40px'
  },
  breadcrumbs: {
    marginTop: 16
  },

  button: {
    maxWidth: 536
  }
})
