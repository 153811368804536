import { BASIC_PRODUCT_FRAGMENT } from './product'
import { FEEDBACK_ORDER_FRAGMENT } from './order'

export const FEEDBACK_USER_FRAGMENT = `
  fragment FeedbackUserFragment on User {
    id
    email
    firstName
    lastName
    sex
    status
    activity
    averageResponseTime
    phone
    city
    companyName
    country
    state
    username
  }
`

export const FEEDBACK_FRAGMENT = `
  ${FEEDBACK_ORDER_FRAGMENT}
  ${BASIC_PRODUCT_FRAGMENT}
  ${FEEDBACK_USER_FRAGMENT}
  fragment FeedbackFragment on Feedback {
    id
    createdAt
    vendor {
        ...FeedbackUserFragment
    }
    user {
        ...FeedbackUserFragment
    }
    product {
        ...BasicProductFields
    }
    order {
        ...FeedbackOrderFragment
    }
    comment
    answer
    rating
    type
  }
`
