import React, { FC } from 'react'
import { isMobile } from 'react-device-detect'

import {
  Column,
  getCurrencySymbol,
  Icon,
  ProductPrice,
  RatingStarsComponent,
  ResponsiveMediaView,
  Row,
  Text
} from '../../../components'

import { getPriceToFixed } from '../../helper'

import { BasicProductInfoProps } from './basic-product-info.types'
import { useStyle } from './basic-product-info.styles'

export const BasicProductInfo: FC<BasicProductInfoProps> = ({
  favouriteIcon,
  isSale,
  name,
  averageRating,
  currency,
  deliveryPrice,
  totalPrice,
  discountMaxPrice,
  onAddToWishlistClick
}) => {
  const classes = useStyle()
  const namePreset = isMobile ? 'h1Mobile' : 'h1'
  const currencySymbol = getCurrencySymbol(currency)
  const productDeliveryPrice = `${currencySymbol}${getPriceToFixed(
    deliveryPrice
  )}`

  const FavouriteIcon = (
    <Row className={classes.favorite} onClick={onAddToWishlistClick}>
      <Icon src={favouriteIcon} />
    </Row>
  )

  return (
    <Column
      alignItems="flex-start"
      justifyContent="flex-start"
      fullHeight
      fullWidth
      className={classes.productInfo}
    >
      <Row fullWidth justifyContent="space-between">
        <Text
          className={classes.title}
          text={name}
          preset={namePreset}
          color="black"
        />
        <ResponsiveMediaView
          bigScreen={FavouriteIcon}
          medium={FavouriteIcon}
          tablet={FavouriteIcon}
        />
      </Row>
      <RatingStarsComponent rating={averageRating} edit={false} />
      <Row fullWidth justifyContent="space-between">
        <ProductPrice
          isSale={isSale}
          currency={currency}
          totalPrice={totalPrice}
          discountMaxPrice={discountMaxPrice}
        />
        <ResponsiveMediaView mobile={FavouriteIcon} />
      </Row>
      <Row>
        <Text
          text="Delivery Price:"
          tx="product.item.card.delivery.price"
          preset="h5"
          color="inactive"
        />

        <Text
          className={classes.price}
          text={productDeliveryPrice}
          preset="h5"
          color="inactive"
        />
      </Row>
    </Column>
  )
}
