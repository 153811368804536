import React, { FC, useState } from 'react'

import { Column } from '../column'
import { ConstructorRadioItem } from '../constructor-radio-item'
import { useStyle } from './constructor-radio-box.styles'
import { ConstructorRadioBoxProps } from './constructor-radio-box.types'

export const ConstructorRadioBox: FC<ConstructorRadioBoxProps> = ({
  active: defaultActive,
  className = '',
  data,
  itemClassName = '',
  onChange
}) => {
  const classes = useStyle()
  const [active, changeActive] = useState(defaultActive)

  const getActive = (currentValue: string) => currentValue === active

  const handleOnChange = (id: string) => () => {
    changeActive(id)
    if (onChange) {
      onChange(id)
    }
  }

  return (
    <Column fullWidth alignItems="flex-start" className={className}>
      {data &&
        data.map((item) => (
          <ConstructorRadioItem
            {...item}
            key={`radioBox_${item.id}`}
            active={getActive(item.id)}
            className={`${itemClassName} ${classes.item}`}
            onClick={handleOnChange(item.id)}
          />
        ))}
    </Column>
  )
}
