import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

import { DotProps } from './dot.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    borderRadius: 16,
    border: `2px solid ${theme.colors.black}`,
    minWidth: 16,
    minHeight: 16
  },
  active: {
    display: ({ active }: DotProps) => (active ? 'block' : 'none'),
    width: 10,
    height: 10,
    borderRadius: 10,
    background: theme.colors.yellow
  }
}))
