import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  item: {
    cursor: 'pointer',
    '& > span:hover': {
      color: theme.colors.yellow
    },
    '@media screen and (max-width: 480px)': {
      '& > span': {
        color: theme.colors.black
      }
    }
  }
}))
