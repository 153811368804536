import React, { forwardRef } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { Color } from '../../../../theme'

import { Column } from '../../../column'
import { Text } from '../../../text'

import { OrderStagingDot } from '../../order-staging-dot'

import { OrderStagingItemProps } from './order-staging-item.types'
import { useStyle } from './order-staging-item.styles'

export const OrderStagingItem = forwardRef<
  HTMLDivElement,
  OrderStagingItemProps
>(({ className, active, disabled, title, titleTx, onClick }, ref) => {
  const classes = useStyle()
  const textPreset = isMobileOnly ? 'maxButton' : 'h6'
  const getActiveTextColor: Color = active ? 'black' : 'inactiveSecondary'

  const disabledOnClick = !disabled ? onClick : undefined

  return (
    <Column className={className} ref={ref} onClick={disabledOnClick}>
      <OrderStagingDot active={active} disabled={disabled} />
      <Text
        className={classes.title}
        color={getActiveTextColor}
        preset={textPreset}
        text={title}
        tx={titleTx}
      />
    </Column>
  )
})
