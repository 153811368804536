import { arrayHasElements, TextProps } from '../../components'
import { AccountError } from '../../services'

export enum ErrorMessages {
  REGISTER_ALLREADY_EXIST = 'REGISTER_ALLREADY_EXIST',
  INCORRECT_LOGIN = 'INCORRECT_LOGIN',
  MIN_LENGTH_PASSWORD = 'MIN_LENGTH_PASSWORD',
  FEEDBACK_FAILURE = 'FEEDBACK_FAILURE',
  SAVE_DELIVERY_INFO = 'SAVE_DELIVERY_INFO',
  CHANGE_OLD_PASSWORD_ERROR = 'CHANGE_OLD_PASSWORD_ERROR',
  ACCOUNT_DELETE = 'ACCOUNT_DELETE'
}

export const authErrorMessages = (key: ErrorMessages): TextProps => {
  switch (key) {
    case ErrorMessages.REGISTER_ALLREADY_EXIST:
      return {
        text: 'An account with that email already exists! Please Sign In.',
        tx: 'sign.up.error'
      }
    case ErrorMessages.INCORRECT_LOGIN:
      return {
        text: 'Incorrect email or password!',
        tx: 'login.error'
      }
    case ErrorMessages.MIN_LENGTH_PASSWORD:
      return {
        text: 'At least 8 characters, one special, one letter and one number!',
        tx: 'change.password.error'
      }
    case ErrorMessages.FEEDBACK_FAILURE:
      return {
        text: 'After send contact us data something went wrong!',
        tx: 'contact.us.send.error'
      }
    case ErrorMessages.SAVE_DELIVERY_INFO:
      return {
        text: 'After save delivery address something went wrong!',
        tx: 'delivery.change.error'
      }
    case ErrorMessages.CHANGE_OLD_PASSWORD_ERROR:
      return {
        text: "Old password isn't valid",
        tx: 'profile.settings.text.change.old.error'
      }
    default:
      return {
        text: 'Something went wrong!',
        tx: 'profile.settings.toastify.text.wrong'
      }
  }
}

export const getChangePasswordErrorByField = (field: string): TextProps => {
  switch (field) {
    case 'oldPassword':
      return authErrorMessages(ErrorMessages.CHANGE_OLD_PASSWORD_ERROR)
    case 'newPassword':
      return authErrorMessages(ErrorMessages.MIN_LENGTH_PASSWORD)
    default:
      return {
        text: 'Something went wrong!',
        tx: 'profile.settings.toastify.text.wrong'
      }
  }
}

export const getFieldErrorByField = (errors: AccountError[]): TextProps => {
  if (arrayHasElements(errors)) {
    const [error] = errors

    return getChangePasswordErrorByField(error.field)
  }

  return {
    text: 'Something went wrong!',
    tx: 'profile.settings.toastify.text.wrong'
  }
}
