import React, { ChangeEvent, useState } from 'react'
import { useTheme } from 'react-jss'
import { isMobileOnly } from 'react-device-detect'

import { LightTheme } from '../../theme'
import { useStyle } from './contact-us.styles'

import {
  Button,
  Column,
  Content,
  Icon,
  ICONS,
  Row,
  Text,
  TextArea,
  TextField,
  TextProps
} from '../../components'

import { useAuth } from '../../providers'
import { GetTokenContactUs } from '../../services'
import { validateEmail } from '../helper'
import { ConfirmationModal } from '../../views'

const DEFAULT_CONTACT_US_STATE: GetTokenContactUs = {
  name: '',
  email: '',
  message: '',
  error: undefined,
  disableButtonError: false
}

export const ContactUsPage = () => {
  const theme: LightTheme = useTheme()
  const classes = useStyle()
  const titlePreset = isMobileOnly ? 'h1Mobile' : 'h1'
  const [contactData, changeData] = useState<GetTokenContactUs>(
    DEFAULT_CONTACT_US_STATE
  )
  const [openConfirm, changeOpenConfirm] = useState(false)
  const { onFeedback } = useAuth()

  const isSentDisabled =
    !contactData.name || !contactData.email || !contactData.message

  const handleOnOpenConfirm = (state: boolean) => () => {
    changeOpenConfirm(state)
  }

  const handleOnOpenModal = () => {
    const openModal = handleOnOpenConfirm(true)

    openModal()
  }

  const handleOnSubmit = () => {
    const isEmail = validateEmail(contactData.email)
    if (isEmail && onFeedback) {
      onFeedback({
        email: contactData.email,
        message: contactData.message,
        name: contactData.name
      })
      handleOnOpenModal()
    } else {
      const emailError: TextProps = {
        text: 'Incorrect email! Try again.',
        color: 'error',
        tx: 'contact.us.error.email',
        preset: 'body'
      }
      changeData({ ...contactData, error: emailError })
    }
  }

  const handleOnChange =
    (prop: keyof GetTokenContactUs) =>
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = event.target
      changeData({
        ...contactData,
        [prop]: value,
        error: undefined
      })
    }

  return (
    <Content className={classes.contactUsMainContainer}>
      <Row className={classes.contactUsContainer} justifyContent="space-around">
        <Column
          className={`${classes.contactUsContent} ${classes.contactUsMessage}`}
          alignItems="flex-start"
        >
          <Text
            text="Contact Us"
            preset={titlePreset}
            tx="contact.us.main.text"
          />
          <Text
            className={classes.contactUsSecondaryText}
            text="Send us a message and we’ll respond as soon as possible"
            tx="contact.us.secondary.text"
            preset="h7"
            color="inactive"
          />
          <TextField
            className={classes.input}
            label="Name"
            labelTx="contact.us.name"
            preset="border"
            type="text"
            textInputPreset="h7"
            maxLength={100}
            onChange={handleOnChange('name')}
          />
          <TextField
            className={classes.input}
            label="E-mail"
            labelTx="contact.us.email"
            preset="border"
            type="text"
            textInputPreset="h7"
            maxLength={100}
            onChange={handleOnChange('email')}
          />
          <TextArea
            className={classes.input}
            label="Message"
            labelTx="contact.us.message"
            preset="border"
            textAreaInputPreset="h7"
            maxLength={2000}
            onChange={handleOnChange('message')}
          />
          {contactData.error && (
            <Row
              className={classes.error}
              fullWidth
              justifyContent="flex-start"
            >
              <Icon className={classes.errorIcon} src={ICONS.attention} />
              <Text className={classes.errorText} {...contactData.error} />
            </Row>
          )}
          <Button
            className={classes.submit}
            disabled={isSentDisabled}
            text="SUBMIT"
            tx="contact.us.submit"
            preset="primary"
            textColor="white"
            textPreset="h5"
            onClick={handleOnSubmit}
          />
        </Column>
        <Column className={classes.contactUsContent}>
          <Column className={classes.contactUsContentItem}>
            <Row>
              <Icon
                className={classes.icon}
                fill={theme.colors.yellow}
                src={ICONS.location}
              />
              <Text
                className={classes.contentItem}
                text="Address"
                tx="contact.us.address"
                preset="h7"
                color="inactive"
              />
            </Row>
            <Text
              className={classes.contentItemText}
              text="PO Box 16122 Collins Street West"
              preset="h7"
            />
          </Column>
          <Column className={classes.contactUsContentItem}>
            <Row>
              <Icon
                className={classes.icon}
                fill={theme.colors.yellow}
                src={ICONS.localPost}
              />
              <Text
                className={classes.contentItem}
                text="Email"
                tx="contact.us.email"
                preset="h7"
                color="inactive"
              />
            </Row>
            <Text
              className={classes.contentItemText}
              text="ovaflopick@marketplace.com"
              preset="h7"
            />
          </Column>
          <Column className={classes.contactUsContentItem}>
            <Row>
              <Icon
                className={classes.icon}
                fill={theme.colors.yellow}
                src={ICONS.localPhone}
              />
              <Text
                className={classes.contentItem}
                text="Phone"
                tx="contact.us.phone"
                preset="h7"
                color="inactive"
              />
            </Row>
            <Text
              className={classes.contentItemText}
              text="+61 3 8376 6284"
              preset="h7"
            />
          </Column>
        </Column>
        <Column
          className={`${classes.contactUsContentImage} ${classes.contactUsContent}`}
        ></Column>
        <ConfirmationModal
          title="All right!"
          txTitle="confirmation.second.title.text"
          text="Message sent successfully. We will contact you as soon as we can!"
          tx="contact.us.modal.text"
          open={openConfirm}
          onClose={handleOnOpenConfirm(false)}
        />
      </Row>
    </Content>
  )
}
