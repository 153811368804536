import { ApolloError, MutationResult } from '@apollo/client'

import { TaxedMoney } from '../tax'
import { AddressInput, User } from '../auth'
import { Image, Product, ProductColor, ProductMaterial } from '../product'
import {
  FetchMore,
  ListVariables,
  PaginationList,
  RangeInput
} from '../api.types'
import { Offer, OfferItem } from '../offer'

export enum ReportingPeriod {
  TODAY = 'TODAY',
  THIS_MONTH = 'THIS_MONTH'
}

export enum OrderDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum OrderSortField {
  NUMBER = 'NUMBER',
  CREATION_DATE = 'CREATION_DATE',
  CUSTOMER = 'CUSTOMER',
  PAYMENT = 'PAYMENT',
  FULFILLMENT_STATUS = 'FULFILLMENT_STATUS',
  TOTAL = 'TOTAL',
  TOTAL_PRICE = 'TOTAL_PRICE'
}

export enum PurchaseStatus {
  COMPLETED = 'COMPLETED',
  DECLINE = 'DECLINE'
}

export enum OrderErrorCode {
  BILLING_ADDRESS_NOT_SET = 'BILLING_ADDRESS_NOT_SET',
  CANNOT_CANCEL_FULFILLMENT = 'CANNOT_CANCEL_FULFILLMENT',
  CANNOT_CANCEL_ORDER = 'CANNOT_CANCEL_ORDER',
  CANNOT_DELETE = 'CANNOT_DELETE',
  CANNOT_REFUND = 'CANNOT_REFUND',
  CAPTURE_INACTIVE_PAYMENT = 'CAPTURE_INACTIVE_PAYMENT',
  NOT_EDITABLE = 'NOT_EDITABLE',
  FULFILL_ORDER_LINE = 'FULFILL_ORDER_LINE',
  GRAPHQL_ERROR = 'GRAPHQL_ERROR',
  INVALID = 'INVALID',
  PRODUCT_NOT_PUBLISHED = 'PRODUCT_NOT_PUBLISHED',
  PRODUCT_UNAVAILABLE_FOR_PURCHASE = 'PRODUCT_UNAVAILABLE_FOR_PURCHASE',
  NOT_FOUND = 'NOT_FOUND',
  ORDER_NO_SHIPPING_ADDRESS = 'ORDER_NO_SHIPPING_ADDRESS',
  PAYMENT_ERROR = 'PAYMENT_ERROR',
  PAYMENT_MISSING = 'PAYMENT_MISSING',
  REQUIRED = 'REQUIRED',
  SHIPPING_METHOD_NOT_APPLICABLE = 'SHIPPING_METHOD_NOT_APPLICABLE',
  SHIPPING_METHOD_REQUIRED = 'SHIPPING_METHOD_REQUIRED',
  TAX_ERROR = 'TAX_ERROR',
  UNIQUE = 'UNIQUE',
  VOID_INACTIVE_PAYMENT = 'VOID_INACTIVE_PAYMENT',
  ZERO_QUANTITY = 'ZERO_QUANTITY',
  INSUFFICIENT_STOCK = 'INSUFFICIENT_STOCK',
  DUPLICATED_INPUT_ITEM = 'DUPLICATED_INPUT_ITEM'
}

export type OrderError = {
  field: string
  message: string
  code: OrderErrorCode
  orderLine: string
}

export enum OrderPaymentStatus {
  COMPLETED = 'COMPLETED',
  DECLINED = 'DECLINED',
  PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED',
  PENDING = 'PENDING',
  REFUNDED = 'REFUNDED',
  FAILED = 'FAILED',
  PROCESS = 'PROCESS',
  BLOCKED = 'BLOCKED',
  DENIED = 'DENIED',
  HELD = 'HELD',
  RETURNED = 'RETURNED',
  SUCCEEDED = 'SUCCEEDED',
  UNCLAIMED = 'UNCLAIMED'
}

export enum OrderStatus {
  DRAFT = 'DRAFT',
  UNFULFILLED = 'UNFULFILLED',
  PARTIALLY_FULFILLED = 'PARTIALLY_FULFILLED',
  FULFILLED = 'FULFILLED',
  CANCELED = 'CANCELED',
  SHIPPED = 'SHIPPED',
  DELIVERIED = 'DELIVERIED'
}

export enum OrderMainStatus {
  NOT_CONFIRMED = 'NOT_CONFIRMED',
  CONFIRMED = 'CONFIRMED',
  PRODUCTION_STARTED = 'PRODUCTION_STARTED',
  PRODUCTION_FINISHED = 'PRODUCTION_FINISHED',
  PRODUCT_SHIPPED = 'PRODUCT_SHIPPED',
  PARCEL_DELIVERED = 'PARCEL_DELIVERED',
  CANCELED_BY_CUSTOMER = 'CANCELED_BY_CUSTOMER',
  CANCELED_BY_VENDOR = 'CANCELED_BY_VENDOR',
  CANCELED_WITHOUT_REFUND = 'CANCELED_WITHOUT_REFUND',
  PAID = 'PAID',
  REFUNDED = 'REFUNDED',
  PARTLY_REFUNDED = 'PARTLY_REFUNDED',
  OPENED_STATE = 'OPENED_STATE',
  CLOSED_STATE = 'CLOSED_STATE'
}

export type OrderFilterInput = {
  mainStatus?: OrderMainStatus[]
  search?: string
}

export type OrderLine = {
  id: string
  color: ProductColor
  material: ProductMaterial
  productName: string
  variantName: string
  productSku: string
  isShippingRequired: boolean
  quantity: number
  quantityFulfilled: number
  taxRate: number
  thumbnail: Image
  unitPrice: TaxedMoney
  totalPrice: number
  defaultPrice: number
  product: Product
  translatedProductName: string
  translatedVariantName: string
  items: OfferItem[]
}

export interface OrderUser
  extends Pick<
    User,
    | 'email'
    | 'city'
    | 'firstName'
    | 'lastName'
    | 'country'
    | 'phone'
    | 'id'
    | 'avatar'
    | 'sex'
    | 'defaultShippingAddress'
  > {}

export interface OrderVendor
  extends OrderUser,
    Pick<User, 'companyName' | 'defaultBillingAddress'> {}

export type Order = {
  id: string
  created: string
  startDate: Date
  finishDate: Date
  status: OrderStatus
  mainStatus: OrderMainStatus
  totalPrice: number
  languageCode: string
  trackingClientId: string

  remainingTimeProductionStarted?: string
  lines: OrderLine[]
  offer?: Offer
  number: string
  event?: string
  paymentStatus: OrderPaymentStatus
  paymentStatusDisplay: string
  total: TaxedMoney
  vendor: OrderVendor
  user: OrderUser
  deliveryTrackingLink: string
  deliveryPrice: number
}

export type OrderUpdateInput = {
  billingAddress?: AddressInput
  userEmail?: string
  shippingAddress?: AddressInput
  isConfirmed?: boolean
  isProductionStarted?: boolean
  isProductionFinished?: boolean
  isProductionShipped?: boolean
  isCanceled?: boolean
  trackingNumber?: string
}
export type OrderLineCreateOptions = {
  optionId: string
  variantId: string[]
}

export type OrderLineCreateInput = {
  quantity: number
  productId: string
  colorId: string
  materialId: string
  options: OrderLineCreateOptions[]
}

export type OrderUpdateVariables = {
  id: string
  input: OrderUpdateInput
}

export type ReturnExchangeVariables = {
  orderId: string
  message: string
  products: ProductReturnExchangeType[]
}

export type DraftOrderCreateInput = {
  shippingAddress?: AddressInput
  lines: OrderLineCreateInput[]
}

export type DraftOrderBulkCreateInput = {
  input: DraftOrderCreateInput[]
}

export type OrderSortingInput = {
  direction: OrderDirection
  field: OrderSortField
}

export type OrderDraftFilterInput = {
  customer: string
  created: RangeInput<string>
  search: string
}

export type DraftOrderCreateData = {
  draftOrderCreate: {
    order: Order
    orderErrors: OrderError[]
  }
}

export type DraftOrderBulkCreateData = {
  draftOrderBulkCreate: {
    orders: Order[]
    orderErrors: OrderError[]
  }
}

export type OrderUpdateData = {
  orderUpdate: {
    order: Order
    orderErrors: OrderError[]
  }
}

export type ReturnExchangeData = {
  returnExchange: {
    status: string
  }
}

export type GetOrdersData = {
  orders: PaginationList<Order>
}

export type GetOrdersReturnData = {
  me: {
    ordersToReturn: PaginationList<Order>
  } | null
}

export type OrderHistoryData = {
  orderHistory: PaginationList<Order>
}

export type GetBillingData = {
  billing: PaginationList<Order>
}

export type ProductReturnExchangeType = {
  productId: string
  action: string
}

export type GetOrderData = {
  order: Order
}

export type OrderByIdVariables = {
  id: string
}

export interface OrdersVariables extends ListVariables {
  filter?: OrderFilterInput
}

export interface OrdersToReturnVariables extends ListVariables {}

export interface OrderHistoryVariables extends ListVariables {
  filter?: OrderDraftFilterInput
  created?: ReportingPeriod
  sortBy?: OrderSortingInput
}

export type GetOrderByIdRequest = {
  data: GetOrderData | null
  loading: boolean
  refetch: (variables: OrderByIdVariables) => void
}

export type DraftOrderCreate = {
  onSubmit: (variables: DraftOrderCreateInput) => void
  response: MutationResult<DraftOrderCreateData>
}

export type DraftOrederBulkCreate = {
  onSubmit: (variables: DraftOrderBulkCreateInput) => void
  response: MutationResult<DraftOrderBulkCreateData>
}

export type GetOrders = {
  data?: GetOrdersData
  error?: ApolloError
  loading: boolean
  refetch: (variables: OrdersVariables) => void
  fetchMore?: FetchMore<GetOrdersData, OrdersVariables>
}

export type GetOrdersReturn = {
  data?: GetOrdersReturnData
  error?: ApolloError
  loading: boolean
  refetch: (variables: OrdersToReturnVariables) => void
  fetchMore?: FetchMore<GetOrdersReturnData, OrdersToReturnVariables>
}

export type OrderHistory = {
  data?: OrderHistoryData
  error?: ApolloError
  loading: boolean
  refetch: (variables: OrderHistoryVariables) => void
  fetchMore?: FetchMore<OrderHistoryData, OrderHistoryVariables>
}

export type GetBilling = {
  data: GetBillingData | null
  loading: boolean
  refetch: (variables: ListVariables) => void
}

export type OrderUpdate = {
  onSubmit: (variables: OrderUpdateVariables) => void
  response: MutationResult<OrderUpdateData>
}

export type ReturnExchange = {
  onSubmit: (variables: ReturnExchangeVariables) => void
  response: MutationResult<ReturnExchangeData>
}

export type OrderApi = {
  useDraftOrderCreate: () => DraftOrderCreate
  useOrders: (variables: OrdersVariables) => GetOrders
  useOrderHistory: (variables: OrderHistoryVariables) => OrderHistory
  useOrderUpdate: () => OrderUpdate
  useOrderById: (variables: OrderByIdVariables) => GetOrderByIdRequest
  useBilling: (variables: ListVariables) => GetBilling
  useReturnExchange: () => ReturnExchange
  useDraftOrderBulkCreate: () => DraftOrederBulkCreate
  useOrdersToReturn: (variables: OrdersToReturnVariables) => GetOrdersReturn
}
