import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  line: {
    width: 16,
    height: 2,
    background: theme.colors.shadow
  }
}))
