import React, { FC } from 'react'
import { isMobileOnly } from 'react-device-detect'

import { ObserveOfferButton } from '../../../views'

import { Column } from '../../column'
import { Text } from '../../text'
import { Row } from '../../row'

import { OfferListInfoColumn } from '../offers-list-info-column'

import { useStyle } from './offers-list-item-variant-line.styles'
import { OfferListItemVariantLineProps } from './offers-list-item-variant-line.types'

export const OfferListItemVariantLine: FC<OfferListItemVariantLineProps> = ({
  className = '',
  currency,
  product,
  description,
  discount,
  noSuggestion,
  count,
  item
}) => {
  const classes = useStyle()

  const Container = isMobileOnly ? Column : Row
  const itemQuantity = item ? item.quantity : 0
  const itemTotalPrice = item ? item.totalItemPrice : 0

  return (
    <Column fullWidth className={className}>
      <Container
        fullWidth
        className={classes.line}
        justifyContent="space-between"
      >
        <Row className={classes.itemRow}>
          <Row className={classes.itemNumber}>
            <Text text={count} preset="h2" />
          </Row>
          <Row fullWidth alignItems="flex-start" justifyContent="space-between">
            <Row className={classes.title}>
              <Text
                className={classes.itemTitle}
                color="black"
                preset="h5"
                text="Item"
                tx="offer.line.item.variant"
              />
            </Row>
          </Row>
        </Row>
        <Row className={classes.infoContainer}>
          <Row
            fullWidth
            justifyContent="flex-start"
            className={classes.infoRow}
          >
            <Row className={classes.column} />
            <OfferListInfoColumn
              className={classes.column}
              title="Item Quantity:"
              titleTx="offer.line.units.quantity"
              price={itemQuantity}
            />
            <OfferListInfoColumn
              className={classes.column}
              title="Item Price:"
              titleTx="offer.item.variant.unit.price"
              price={itemTotalPrice}
              currency={currency}
            />
            <Row className={classes.column} />
          </Row>
          {item && (
            <Row
              className={classes.observeOptionsContainer}
              justifyContent="flex-end"
            >
              <ObserveOfferButton
                className={classes.observeOptionsBtn}
                currency={currency}
                description={description}
                productDefaultPrice={product.defaultPrice}
                options={item.options}
                offerOptions={item.productOptions}
                offerColors={item.productColors}
                offerMaterials={item.productMaterials}
                discount={discount}
                noSuggestion={noSuggestion}
                totalItemPrice={item.totalItemPrice}
                itemQuantity={item.quantity}
              />
            </Row>
          )}
          <Row className={classes.emptyRow} />
        </Row>
      </Container>
    </Column>
  )
}
