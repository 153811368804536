import { useMutation, useQuery } from '@apollo/client'

import {
  FeedbackCreate,
  FeedbackApi,
  FeedbackCreateInputVariables,
  FeedbackCreateData,
  FeedbackVariables,
  GetFeedbacks,
  GetFeedbacksData
} from './feedback.types'

import { FEEDBACKS_QUERY, FEEDBACK_CREATE_MUTATION } from './feedback.graphql'

export const feedbackService = (): FeedbackApi => {
  const useCreateFeedback = (): FeedbackCreate => {
    const [RequestCreateFeedbackSubmit, response] = useMutation<
      FeedbackCreateData,
      FeedbackCreateInputVariables
    >(FEEDBACK_CREATE_MUTATION)

    const handleOnSubmit = (variables: FeedbackCreateInputVariables) => {
      if (variables) {
        const options = {
          variables
        }

        RequestCreateFeedbackSubmit(options)
      }
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useFeedbacks = (variables: FeedbackVariables): GetFeedbacks => {
    const { data, loading, error, refetch } = useQuery<
      GetFeedbacksData,
      FeedbackVariables
    >(FEEDBACKS_QUERY, { variables })

    if (error) {
      return { data: null, loading, refetch }
    }

    if (!data) {
      return { data: null, loading, refetch }
    }

    return { data, loading, refetch }
  }

  return {
    useCreateFeedback,
    useFeedbacks
  }
}
