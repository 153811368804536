import { useMutation } from '@apollo/client'

import {
  PaymentApi,
  CreatePaypalOrder,
  CreatePaypalOrderVariables,
  CreatePaypalOrderData
} from './payment.types'
import { CREATE_PAYPAL_ORDER_MUTATION } from './payment.graphql'

export const paymentService = (): PaymentApi => {
  const useCreatePaypalOrder = (): CreatePaypalOrder => {
    const [OrderUpdateSubmit, response] = useMutation<
      CreatePaypalOrderData,
      CreatePaypalOrderVariables
    >(CREATE_PAYPAL_ORDER_MUTATION)

    const handleOnSubmit = (variables: CreatePaypalOrderVariables) => {
      const options = {
        variables
      }
      OrderUpdateSubmit(options)
    }

    return { onSubmit: handleOnSubmit, response }
  }

  return {
    useCreatePaypalOrder
  }
}
