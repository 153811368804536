import React, { FC } from 'react'

import { getPriceWithSymbol } from '../../views'

import { Column } from '../column'
import { InfoRow } from '../info-row'
import { ItemLineMobile } from '../item-line'

import { useStyle } from './order-confirm-item-line.styles'
import { OrderConfirmItemLineProps } from './order-confirm-item-line.types'

export const OrderConfirmItemLineMobile: FC<OrderConfirmItemLineProps> = ({
  className,
  productName,
  currency,
  imgUrl,
  quantity,
  deliveryPrice,
  items,
  noSuggestion,
  description,
  discount,
  totalOrderPrice,
  totalOfferPrice,
  productDefaultPrice,
  isTooltip
}) => {
  const classes = useStyle()

  return (
    <ItemLineMobile
      className={className}
      lastColumnClassName={classes.lastColumn}
      productName={productName}
      imgUrl={imgUrl}
      noSuggestion={noSuggestion}
      description={description}
      discount={discount}
      totalOfferPrice={totalOfferPrice}
      items={items}
      deliveryPrice={deliveryPrice}
      productDefaultPrice={productDefaultPrice}
      currency={currency}
      isTooltip={isTooltip}
    >
      <Column fullWidth className={classes.infoRows}>
        <InfoRow
          preset="h5"
          title="Order Price:"
          titleTx="shopping.cart.order.price"
          value={getPriceWithSymbol(totalOfferPrice, currency)}
        />
        <InfoRow
          preset="h5"
          className={classes.row}
          title="Delivery Price:"
          titleTx="shopping.cart.item.delivery.price"
          value={getPriceWithSymbol(deliveryPrice, currency)}
        />
        <InfoRow
          preset="h5"
          className={classes.row}
          title="Item Quantity:"
          titleTx="shopping.cart.units.quantity"
          value={String(quantity)}
        />
        <InfoRow
          preset="h4"
          className={classes.row}
          title="Total Price:"
          titleTx="order.confirm.total.price"
          value={getPriceWithSymbol(totalOrderPrice, currency)}
        />
      </Column>
    </ItemLineMobile>
  )
}
