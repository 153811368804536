import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  optionsRow: {
    columnGap: 8,
    '&:not(:first-child)': {
      marginTop: 6
    }
  },
  detailsColumn: {
    marginTop: 24
  }
})
