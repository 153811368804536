import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  categories: {
    marginTop: 48
  },

  summary: {
    width: '50%'
  },
  basicInfo: {
    marginTop: 48
  },

  productList: {
    width: '100%',
    flex: 1,
    justifyContent: 'center',
    marginLeft: 8,
    '& > div > div': {
      justifyContent: 'center'
    }
  },
  description: {
    width: 380,
    marginTop: 16,
    textAlign: 'center'
  },
  allItemsTitle: {
    marginTop: 80
  },
  allItems: {
    marginTop: 24,
    gap: 20
  },

  load: {
    padding: '16px 0',
    width: 536
  },
  allItemsContainer: {},
  '@media screen and (min-width: 1224px)': {
    basicInfo: {
      flexDirection: 'row'
    },
    productList: {
      width: '50%',
      flex: 1
    }
  },
  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    summary: {
      width: '100%'
    }
  },
  '@media screen and (max-width: 480px)': {
    productList: {
      justifyContent: 'flex-start',
      marginLeft: 0,
      '& > div > div': {
        justifyContent: 'flex-start'
      }
    },
    categories: {
      marginTop: 70
    },
    description: {
      width: '100%'
    },
    load: {
      marginTop: 40,
      padding: 0,
      width: '100%'
    },
    allItemsContainer: {
      paddingBottom: 40
    }
  }
})
