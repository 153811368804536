import React from 'react'
import { useHistory } from 'react-router-dom'
import { isMobileOnly, isMobile } from 'react-device-detect'
import { Content, Column, Text, Button, Row } from '../../components'
import { useStyle } from './not-found.styles'

export const NotFoundPage = () => {
  const classes = useStyle()
  const textPreset = isMobileOnly ? 'h6' : 'h2'
  const notFoundPreset = isMobileOnly ? 'h1Mobile' : 'notFound'
  const titlePreset = isMobileOnly ? 'h1Mobile' : 'h1'
  const h3Preset = isMobile ? 'h3Mobile' : 'h3'
  const history = useHistory()
  const handleOnClickHome = () => {
    history.push('/')
  }

  return (
    <Content className={classes.container} alignItems="flex-start">
      <Row fullWidth className={classes.row} alignItems="flex-start">
        <Column
          className={classes.firstColumn}
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Text
            preset={textPreset}
            text="Ooops..."
            color="blue"
            tx="error.404.left.first.text"
          />
          <Text
            className={classes.errorNumber}
            preset={notFoundPreset}
            text="404"
          />
          <Text
            className={classes.page}
            preset={textPreset}
            text="Page Not Found"
            color="blue"
            tx="error.404.left.found.page"
          />
        </Column>

        <Column className={classes.secondColumn} alignItems="flex-start">
          <Text
            className={classes.title}
            preset={titlePreset}
            text="Something went wrong here"
            tx="error.404.title.text"
          />
          <Text
            className={classes.textError}
            preset={h3Preset}
            text="The page you are looking for dosen’t exist, you can return to the home."
            tx="error.404.second.title.text"
          />
          <Button
            className={classes.button}
            color="yellow"
            preset="borderButton"
            textColor="white"
            text="HOME"
            tx="error.404.button.text"
            onClick={handleOnClickHome}
          />
        </Column>
      </Row>
    </Content>
  )
}
