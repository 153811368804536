import React, { FC } from 'react'

import { OrderUpdateVariables } from '../../../services'

import { MenuSlider } from '../../menu-slider'
import { OrderLine } from '../../order-line'
import { getCurrencySymbol } from '../../helpers'

import { OrderSliderHeaderOpenMobile } from './order-slider-header-open-mobile'
import { OrderSliderHeaderCloseMobile } from './order-slider-header-close-mobile'
import { OrderSliderButtons } from './order-slider-buttons'
import { useStyle } from './order-slider-mobile.styles'
import { OrderSliderMobileProps } from './order-slider-mobile.types'
import { getPriceWithSymbol } from '../../../views'

export const OrderSliderMobile: FC<OrderSliderMobileProps> = ({
  className = '',
  order,
  onChange,
  onPayment,
  onContactVendor,
  onOpenDelivery
}) => {
  const classes = useStyle()
  const {
    id,
    created,
    startDate,
    finishDate,
    number,
    lines,
    vendor,
    mainStatus,
    paymentStatus,
    total,
    totalPrice,
    offer,
    remainingTimeProductionStarted,
    deliveryPrice
  } = order

  const currencySymbol = getCurrencySymbol(total.currency)

  const handleOnChange = (variables: OrderUpdateVariables) => {
    if (onChange) {
      onChange(variables)
    }
  }

  const headerOpenMobile = (
    <OrderSliderHeaderOpenMobile
      number={number}
      mainStatus={mainStatus}
      paymentStatus={paymentStatus}
      created={created}
      price={getPriceWithSymbol(totalPrice, currencySymbol)}
      vendor={vendor}
      startDate={startDate}
      finishDate={finishDate}
      onPayment={onPayment}
    />
  )
  const headerCloseMobile = (
    <OrderSliderHeaderCloseMobile
      number={number}
      mainStatus={mainStatus}
      paymentStatus={paymentStatus}
      created={created}
      price={getPriceWithSymbol(totalPrice, currencySymbol)}
      vendor={vendor}
      onPayment={onPayment}
      onOpenDelivery={onOpenDelivery}
    />
  )
  return (
    <MenuSlider
      className={`${className} ${classes.container}`}
      contentClassName={classes.content}
      header={headerOpenMobile}
      headerClose={headerCloseMobile}
    >
      {offer &&
        lines.map((orderLine) => {
          if (!orderLine.product) {
            return null
          }

          return (
            <OrderLine
              className={classes.line}
              {...orderLine}
              {...offer}
              currency={currencySymbol}
              key={`order_${id}_orderLine_${orderLine.id}_product_${orderLine.product.id}`}
              deliveryPrice={deliveryPrice}
              totalOrderPrice={totalPrice}
              // productionDays={days}
            />
          )
        })}

      <OrderSliderButtons
        id={id}
        mainStatus={mainStatus}
        paymentStatus={paymentStatus}
        remainingTimeProductionStarted={remainingTimeProductionStarted}
        startDate={startDate}
        finishDate={finishDate}
        vendor={vendor}
        price={getPriceWithSymbol(totalPrice, currencySymbol)}
        number={number}
        onChangeStatus={handleOnChange}
        onContactVendor={onContactVendor}
        onOpenDelivery={onOpenDelivery}
      />
    </MenuSlider>
  )
}
