import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  ratingRow: {
    cursor: 'pointer',
    '&:hover': {
      '& > span': {
        color: theme.colors.yellow
      },
      '& > svg': {
        fill: theme.colors.yellow
      }
    }
  },
  textRow: {
    paddingBottom: 4
  },
  reviewStar: {
    marginRight: 16
  }
}))
