import React, { ChangeEvent, FC } from 'react'
import { Range } from 'rc-slider'
import 'rc-slider/assets/index.css'

import { CoreItem } from '../../services'

import { getCurrencySymbol } from '../helpers'
import { Column } from '../column'
import { Row } from '../row'
import { TextField } from '../text-field'

import { useStyle } from './range-input.styles'
import { RangeInputProps } from './range-input.types'
import { Icon, ICONS } from '../icon'

export const RangeInput: FC<RangeInputProps> = ({
  currency,
  start = 0,
  end = 100,
  valueStart,
  valueEnd,
  onChange,
  onChangeItem
}) => {
  const classes = useStyle()
  const currencySymbol = currency ? getCurrencySymbol(currency) : undefined

  const startState = valueStart || 0
  const endState = valueEnd || 0

  const state = [startState, endState]
  const handleOnChange =
    (min: boolean) => (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target

      if (onChange) {
        if (min) {
          onChange([Number(value), endState])
        } else {
          onChange([startState, Number(value)])
        }
      }

      if (onChangeItem) {
        if (min) {
          const nextValueItems: CoreItem[] = [
            {
              id: 'price',
              name: String([Number(value), endState])
            }
          ]

          onChangeItem(nextValueItems)
        } else {
          const nextValueItems: CoreItem[] = [
            {
              id: 'price',
              name: String([startState, Number(value)])
            }
          ]

          onChangeItem(nextValueItems)
        }
      }
    }
  const handleOnChangeRangeInput = (value: number[]) => {
    if (onChange) {
      onChange(value)
    }

    if (onChangeItem) {
      const nextValueItems: CoreItem[] = [
        {
          id: 'price',
          name: String(value)
        }
      ]

      onChangeItem(nextValueItems)
    }
  }

  return (
    <Column fullWidth alignItems="flex-start" justifyContent="flex-start">
      <Range
        className={classes.rangeStyle}
        min={start}
        max={end}
        value={state}
        onChange={handleOnChangeRangeInput}
      />

      <Row className={classes.rangeInput}>
        <TextField
          preset="border"
          label=""
          rightElement={currencySymbol}
          type="number"
          value={state[0]}
          onChange={handleOnChange(true)}
        />
        <Icon src={ICONS.maximize} width="40px" color="inactive" />
        <TextField
          preset="border"
          label=""
          type="number"
          rightElement={currencySymbol}
          value={state[1]}
          onChange={handleOnChange(false)}
        />
      </Row>
    </Column>
  )
}
