import { arrayHasElements } from '../../components'
import { MainRoutes } from '../../routes'
import { Category, Edge } from '../../services'

const getNextIsProductPage = (categoryChildren: Edge<Category>[]) => {
  const nextIsProductPage = arrayHasElements(categoryChildren)

  if (nextIsProductPage) {
    return ''
  }

  return '/products'
}

export const getPathByCategory = <T extends Edge<Category>[]>(
  categoryId: string,
  categoryChildren: T,
  vendorId?: string
) => {
  const path = getNextIsProductPage(categoryChildren)

  if (vendorId) {
    return `${MainRoutes.CATEGORY}/${categoryId}/${vendorId}${path}`
  }

  return `${MainRoutes.CATEGORY}/${categoryId}${path}`
}
