import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  slider: {
    border: 'none',
    borderRadius: '0 0 4px 4px',
    borderBottom: `1px solid ${theme.colors.shadow}`,
    '&:nth-child(n) > div': {
      '&:last-child': {
        backgroundColor: theme.colors.vendorProfileFon,
        margin: 0,
        paddingRight: 16,
        paddingLeft: 16,
        borderTop: 'none'
      }
    },
    '&:last-child': {
      borderBottom: 'none',
      borderRadius: '4px'
    }
  },
  rate: {
    marginTop: 16
  },
  textArea: {
    marginTop: 16
  },
  quantityRow: {
    marginTop: 8
  },
  ratingRow: {
    marginTop: 0
  },
  '@media screen and (max-width: 480px)': {
    slider: {
      width: 'calc(100% - 32px)'
    },
    rate: {
      marginTop: 0
    },
    ratingRow: {
      marginTop: 18
    }
  }
}))
