import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {
    padding: 12
  },
  button: {
    marginTop: 12
  }
})
