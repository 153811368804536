import React, { FC } from 'react'

import { Column, Row, Text } from '../../../components'

import { MakerDesignerCardProps } from './maker-designer-card.types'
import { useStyle } from './maker-designer-card.styles'

export const MakerDesignerCard: FC<MakerDesignerCardProps> = ({
  className,
  avatar,
  background,
  name
}) => {
  const firstSymbol = () => {
    if (name !== null) {
      return name.charAt(0).toUpperCase()
    }
    return undefined
  }

  const classes = useStyle({ background })
  return (
    <Column className={`${className} ${classes.container}`}>
      <Row className={classes.imgContainer}>
        <Row className={classes.imgWrapper}>
          {avatar ? (
            <img className={classes.img} src={avatar} />
          ) : (
            <Row className={`${classes.img} ${classes.placeholderImg}`}>
              <Text color="white" preset="h1" text={firstSymbol()} />
            </Row>
          )}
        </Row>
      </Row>
      <Column className={classes.details}>
        {name && <Text color="black" preset="h5" text={name} />}
      </Column>
    </Column>
  )
}
