import React, { FC } from 'react'

import { Column, Row, Text, Icon, ICONS } from '../../../components'

import { useStyle } from './basic-info.styles'
import { BasicInfoProps } from './basic-info.types'

export const BasicInfo: FC<BasicInfoProps> = ({ data }) => {
  const classes = useStyle()
  const userGender = data?.sex === 1
  const totalCountFeedbacks = `${data?.feedbackAmount || 0}`
  const valueString = `${data?.wishlist.totalCount || 0}`

  return (
    <Row fullWidth justifyContent="flex-start" alignItems="flex-end">
      {userGender ? (
        <Icon
          className={classes.sex}
          height="160"
          src={ICONS.male}
          width="160"
        />
      ) : (
        <Icon
          className={classes.sex}
          height="160"
          src={ICONS.female}
          width="160"
        />
      )}

      <Column>
        <Text preset="h3" text={`${data.firstName} ${data.lastName}`} />
        {data.defaultShippingAddress && (
          <Text color="inactive" text={data.defaultShippingAddress.city} />
        )}
      </Column>

      <Row className={classes.indicators} justifyContent="space-evenly">
        <Column className={classes.indicator}>
          <Text color="yellow" preset="h3" text={valueString} />
          <Text
            color="inactive"
            text="Favorites"
            tx="cabinet.route.userInfo.favorites.title"
          />
        </Column>
        <Column className={classes.indicator}>
          <Text color="yellow" preset="h3" text={totalCountFeedbacks} />
          <Text
            color="inactive"
            text="Reviews"
            tx="cabinet.route.userInfo.reviews.title"
          />
        </Column>
      </Row>
    </Row>
  )
}
