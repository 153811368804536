import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  modal: {
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: 4
    },
    '&::-webkit-scrollbar-thumb': {
      background: `${theme.colors.blue}`,
      height: 120
    }
  },
  container: {
    width: 'calc(100% - 32px)',
    padding: '0 16px',
    marginTop: 16,
    maxHeight: 554,
    backgroundColor: theme.colors.white,
    zIndex: 2
  },
  order: {
    width: 'calc(100% - 32px)',
    padding: 16,
    border: `1px solid ${theme.colors.shadow}`,
    borderRadius: 4,
    cursor: 'pointer',
    '&:not(:first-child)': {
      marginTop: 16
    }
  },
  imgRow: {
    marginTop: 16
  },
  imgContainer: {
    '&:not(:first-child)': {
      marginLeft: 8
    }
  },
  name: {
    padding: '8px 0'
  },
  image: {}
}))
