import camelcaseKeys from 'camelcase-keys'
import { MouseEvent } from 'react'

import { CoreItemByAlphabet } from '../pages'
import {
  CoreItem,
  Currency,
  FilterItem,
  OrderMainStatus,
  OrderPaymentStatus,
  PaginationList,
  Product,
  ProductWithOptions,
  WishlistItem
} from '../services'

const CURRENCY_SYMBOL = {
  USD: '$',
  EUR: '€'
}

export const getCurrencySymbol = (
  currency: Currency | undefined = Currency.USD
) => {
  return CURRENCY_SYMBOL[currency]
}

export const getCurrencyByProduct = (product: ProductWithOptions | Product) => {
  if (product?.pricing?.priceRange?.stop?.gross?.currency) {
    getCurrencySymbol(product.pricing.priceRange.stop.gross.currency)
  }

  return getCurrencySymbol(Currency.USD)
}

export const arrayHasElements = <T>(data?: T[]) => {
  if (data) {
    return data.length > 0
  }

  return false
}

export const getListFromEdgesList = <T>(
  data?: PaginationList<T> | null
): T[] => {
  if (data) {
    return data.edges.map((item) => item.node)
  }

  return []
}

export const getProductListFromEdgesList = (
  data?: PaginationList<WishlistItem> | null
): Product[] => {
  if (data) {
    return data.edges.map((item) => item.node.product)
  }

  return []
}

export type GetFullNameObject = {
  firstName: string
  lastName: string
}

export const getFullName = <T extends GetFullNameObject>(object: T | null) => {
  if (object) {
    const { firstName = '', lastName = '' } = object

    return `${firstName} ${lastName}`
  }

  return ''
}

export const getCoreItemsByAlphabet = (
  data: CoreItem[]
): CoreItemByAlphabet => {
  return data.reduce<CoreItemByAlphabet>((acc, countryItem) => {
    const firstChar = countryItem.name[0].toUpperCase()
    const currentDataByChar = acc[firstChar] || []
    return {
      ...acc,
      [firstChar]: [...currentDataByChar, countryItem]
    }
  }, {})
}

export const getAvaliableToPayByMainStatus = (mainStatus: OrderMainStatus) =>
  mainStatus !== OrderMainStatus.CANCELED_BY_CUSTOMER &&
  mainStatus !== OrderMainStatus.CANCELED_BY_VENDOR

export const getAvaliableToPayByPaymentStatus = (status: OrderPaymentStatus) =>
  status === OrderPaymentStatus.FAILED ||
  status === OrderPaymentStatus.DECLINED ||
  !status

export const getAvaliableToPay = (
  status: OrderPaymentStatus,
  mainStatus: OrderMainStatus
) =>
  getAvaliableToPayByMainStatus(mainStatus) &&
  getAvaliableToPayByPaymentStatus(status)

export const getAvaliableToDelivery = (mainStatus: OrderMainStatus) =>
  mainStatus === OrderMainStatus.PRODUCT_SHIPPED ||
  mainStatus === OrderMainStatus.PARCEL_DELIVERED

export const getAvaliableToDecline = (mainStatus: OrderMainStatus) =>
  mainStatus !== OrderMainStatus.CANCELED_BY_CUSTOMER &&
  mainStatus !== OrderMainStatus.CANCELED_BY_VENDOR &&
  mainStatus !== OrderMainStatus.CANCELED_WITHOUT_REFUND &&
  mainStatus !== OrderMainStatus.REFUNDED &&
  mainStatus !== OrderMainStatus.PARTLY_REFUNDED

export type FilterItemTypes = FilterItem | string | number

export type FilterSidebarData<T extends Record<string, FilterItemTypes[]>> =
  Record<keyof T, CoreItem[]>

export const getFilterItemsJSONToCoreItemFilters = <
  T extends Record<string, FilterItemTypes[]>
>(
  list: T
) =>
  Object.entries(list).reduce<FilterSidebarData<T>>(
    (acc, item) => ({
      ...acc,
      [item[0]]: item[1].map((filterItem) => {
        if (typeof filterItem === 'object') {
          const { id = '', name = '' } = filterItem
          return {
            id,
            name
          }
        }
        if (typeof filterItem === 'string') {
          return {
            id: filterItem,
            name: filterItem
          }
        }

        if (typeof filterItem === 'number') {
          return {
            id: String(filterItem),
            name: String(filterItem)
          }
        }

        return {
          id: '',
          name: ''
        }
      })
    }),
    {} as Record<keyof T, FilterItem[]>
  )

export const parseFilterItems = <T extends Record<string, FilterItemTypes[]>>(
  dataJSON: string
): FilterSidebarData<T> | undefined => {
  if (dataJSON) {
    const list = camelcaseKeys<T>(JSON.parse(dataJSON) as T, {
      deep: true
    }) as T

    if (list) {
      return getFilterItemsJSONToCoreItemFilters(list)
    }
  }

  return undefined
}

export const getIncludesFromEnum = (enumValues: string[], value: string) => {
  const current = enumValues.find((enumValue) => enumValue.includes(value))

  return Boolean(current)
}

export const zeroPad = (num: number, places: number) =>
  String(num).padStart(places, '0')

export const onStopEvent = (
  event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
) => {
  event.preventDefault()
  event.stopPropagation()
}
