import React, { useMemo, useState } from 'react'

import {
  GetAdvertisingProductsVariables,
  OrderDirection,
  ProductFilter,
  ProductFiltersParseJSON,
  ProductOrderField,
  ProductOrderSort
} from '../../services'
import {
  Column,
  FilterSidebarData,
  getListFromEdgesList,
  Loader,
  parseFilterItems
} from '../../components'
import { useApi } from '../../providers'
import { reduceProductsToProductList, ProductView } from '../../views'
import { useStyle } from './top-products.styles'

export const TopProductsPage = () => {
  const { product } = useApi()
  const first = 8

  const classes = useStyle()

  const [productVariables, changeProductVariables] =
    useState<GetAdvertisingProductsVariables>({
      first,
      sortBy: {
        direction: OrderDirection.DESC,
        field: ProductOrderField.ADVERTISING_CREATED
      }
    })

  const {
    loading,
    data: response,
    refetch,
    fetchMore
  } = product.useAdvertisingProducts(productVariables)

  const Data = useMemo(() => {
    if (response) {
      const products = getListFromEdgesList(response.advertisingProducts)
      return reduceProductsToProductList(products)
    }

    return []
  }, [response])

  const TotalCount = response?.advertisingProducts.totalCount || 0

  const handleOnSort = (sortBy?: ProductOrderSort) => {
    refetch({ ...productVariables, first, sortBy })
    changeProductVariables({ ...productVariables, first, sortBy })
  }

  const handleOnLoadNext = (filter: ProductFilter) => {
    if (fetchMore && response) {
      const productEdges = response.advertisingProducts.edges
      const lastIndex = productEdges.length - 1
      fetchMore({
        variables: {
          first,
          filter,
          after: productEdges[lastIndex].cursor
        }
      })
    }
  }

  const handleOnChangeFilter = (value: ProductFilter) => {
    if (refetch) {
      refetch({ first, filter: value })
    }
  }

  const filtersData = response
    ? response.advertisingProducts.filters
    : undefined
  const Filters = useMemo(() => {
    if (filtersData) {
      const list = parseFilterItems<ProductFiltersParseJSON>(filtersData)

      if (list) {
        return list
      }
    }

    return {
      colors: [],
      materials: [],
      vendors: [],
      genders: [],
      priceRange: [],
      companyNames: [],
      productTypes: [],
      sizes: [],
      rating: [],
      productionDaysRange: []
    } as FilterSidebarData<ProductFiltersParseJSON>
  }, [filtersData])

  if (loading) {
    return (
      <Column className={classes.loadingContainer}>
        <Loader width={40} height={40} />
      </Column>
    )
  }

  return (
    <ProductView
      withCategories
      loading={loading}
      data={Data}
      title="Top Products"
      titleTx="header.topProducts"
      totalCount={TotalCount}
      filtersData={Filters}
      onLoadNext={handleOnLoadNext}
      onChangeFilter={handleOnChangeFilter}
      onSort={handleOnSort}
    />
  )
}
