import React, { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTheme } from 'react-jss'

import { useBasket } from '../../providers'
import { Color, LightTheme } from '../../theme'
import { MainRoutes } from '../../routes'
import { Row, Basket, Text } from '../../components'

import { BasketIconProps } from './basket-icon.types'
import { useStyle } from './basket-icon.styles'

export const BasketIcon: FC<BasketIconProps> = ({ className = '' }) => {
  const { basketIds } = useBasket()
  const [hover, changeHover] = useState(false)
  const theme: LightTheme = useTheme()
  const classes = useStyle()
  const stringValue = String(basketIds.length || 0)

  const activeIconColor: Color = hover ? 'yellow' : 'black'

  const handleOnMouseOver = (state: boolean) => () => {
    changeHover(state)
  }

  return (
    <Link
      className={`${className} ${classes.link}`}
      to={MainRoutes.SHOPPING_CART}
      onMouseOver={handleOnMouseOver(true)}
      onMouseOut={handleOnMouseOver(false)}
    >
      <Row>
        <Basket fill={theme.colors[activeIconColor]} height={32} width={32} />
        <Row className={classes.textContainer}>
          <Text color="white" preset="secondTitle" text={stringValue} />
        </Row>
      </Row>
    </Link>
  )
}
