import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {},
  buttonsRow: {},
  row: {
    width: '100%',
    justifyContent: 'space-between',
    marginTop: 12
  },
  button: {
    marginLeft: 24,
    width: 148,
    padding: '12px 10px'
  },
  buttonBorder: {
    width: 148,
    padding: '10px 8px'
  },
  staging: {
    marginTop: 24,
    marginLeft: 24
  }
})
