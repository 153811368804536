import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {},
  color: {
    cursor: 'pointer',
    border: `1px solid ${theme.colors.greyFon}`,
    margin: '5px 11px'
  },
  colorSelected: {
    cursor: 'pointer',
    border: `4px solid ${theme.colors.yellow}`,
    margin: '2px 8px'
  }
}))
