import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  btnRow: {
    columnGap: 16,
    marginTop: 40
  },
  addToCartBtn: {
    flex: 1
  },
  contactBtn: {
    flex: 1,
    padding: '14px 22px'
  },
  offerContactBtn: {},
  communication: {
    padding: '16px 22px',
    marginTop: 24
  },

  btnRowOffer: {
    justifyContent: 'flex-start',
    marginTop: 40
  },
  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    btnRow: {
      columnGap: 16
    },
    addToCartBtn: {
      flex: 1
    },
    offerContactBtn: {
      maxWidth: '100%'
    },
    contactBtn: {
      flex: 1,
      marginTop: 0
    },
    btnRowOffer: {
      justifyContent: 'center'
    },
    marginTop: 0
  },
  '@media screen and (max-width: 480px)': {
    btnRow: {
      marginTop: 24
    },
    addToCartBtn: {
      width: '100%'
    },
    contactBtn: {
      width: '100%',
      padding: '16px 12px',
      marginTop: 24
    },
    offerContactBtn: {
      width: '100%',
      maxWidth: '100%',
      marginTop: 24
    },
    btnRowOffer: {
      justifyContent: 'center',
      marginTop: 0
    }
  }
})
