import React, { FC } from 'react'

import { Icon } from '../icon'
import { Row } from '../row'
import { Text } from '../text'

import { OuterLinkProps } from './outer-link.types'
import { useStyle } from './outer-link.style'

export const OuterLink: FC<OuterLinkProps> = ({
  className = '',
  linkClassName = '',
  color,
  link,
  text,
  typeLink,
  copyLink,
  iconSrc
}) => {
  const nextLink = typeLink ? `${typeLink}${link}` : link

  const classes = useStyle({ copyLink })

  return (
    <Row
      className={`${className} ${classes.container}`}
      justifyContent="space-between"
    >
      <a
        className={`${linkClassName} ${classes.link}`}
        href={nextLink}
        target="_blank"
        rel="noreferrer"
      >
        {text && (
          <Text
            className={classes.text}
            color={color || 'inactive'}
            text={text}
          />
        )}
        {iconSrc && <Icon className={classes.text} src={iconSrc} />}
      </a>
    </Row>
  )
}
