import React, { FC, useMemo, useState } from 'react'

import { getFormatedOrderDate } from '../../../pages'
import { OrderUpdateVariables } from '../../../services'
import { DeclineOrderModal } from '../../../views'

import { Button, ButtonProps } from '../../button'
import { Row } from '../../row'
import { Text } from '../../text'
import { Column } from '../../column'
import { getAvaliableToDecline, getFullName } from '../../helpers'

import { OrderRow } from '../order-row'
import { OrderContactInfo } from '../order-contact-info'

import { OrderSliderDescriptionProps } from './order-slider-description.types'
import { useStyle } from './order-slider-description.styles'

export const OrderSliderDescription: FC<OrderSliderDescriptionProps> = ({
  id,
  mainStatus,
  paymentStatus,
  startDate,
  finishDate,
  vendor,
  number,
  price,
  onContactVendor,
  onChangeStatus
}) => {
  const [openDeclineModal, changeOpenDeclineModal] = useState(false)

  const classes = useStyle()
  const createdDate = getFormatedOrderDate(startDate)
  const finishedDate = getFormatedOrderDate(finishDate)

  const declineIsEnabled = getAvaliableToDecline(mainStatus)

  const VendorFullName = useMemo(() => getFullName(vendor), [vendor])
  const countryCode = vendor?.defaultBillingAddress?.country?.code

  const handleOnChangeDeclineModal =
    (state: boolean): ButtonProps['onClick'] =>
    (event) => {
      event.preventDefault()
      event.stopPropagation()

      changeOpenDeclineModal(state)
    }

  const handleOnSubmit = () => {
    const variables: OrderUpdateVariables = {
      id,
      input: {
        isCanceled: true
      }
    }

    if (onChangeStatus) {
      onChangeStatus(variables)
    }

    changeOpenDeclineModal(false)
  }

  return (
    <Column fullWidth>
      <OrderRow
        className={classes.date}
        alignItems="center"
        justifyContent="space-between"
      >
        <Row>
          <Row>
            <Text
              color="inactive"
              preset="body"
              text="Start Date"
              tx="order.item.startDate"
            />
            <Text
              className={classes.rowValue}
              color="black"
              preset="h7"
              text={createdDate}
            />
          </Row>
          <Row className={classes.rowField}>
            <Text
              color="inactive"
              preset="body"
              text="Finish Date"
              tx="order.item.finishDate"
            />
            <Text
              className={classes.rowValue}
              color="black"
              preset="h7"
              text={finishedDate}
            />
          </Row>
        </Row>
        {declineIsEnabled && (
          <Button
            color="darkBlue"
            className={classes.rowField}
            preset="secondaryBorderMini"
            textColor="darkBlue"
            textPreset="maxButton"
            text="Decline Order"
            tx="order.item.declineOrder"
            onClick={handleOnChangeDeclineModal(true)}
          />
        )}
      </OrderRow>
      {vendor && (
        <OrderContactInfo
          type="vendor"
          title="Vendor Info:"
          titleTx="order.item.vendorInfo"
          countryCode={countryCode}
          country={vendor.country}
          companyName={vendor.companyName}
          email={vendor.email}
          city={vendor.city}
          fullName={VendorFullName}
          phoneNumber={vendor.phone}
          buttonTitle="Contact Maker"
          buttonTx="order.item.contact.maker"
          onButtonClick={onContactVendor}
        />
      )}

      <DeclineOrderModal
        open={openDeclineModal}
        orderNumber={number}
        totalPrice={price}
        paymentStatus={paymentStatus}
        onClose={handleOnChangeDeclineModal(false)}
        onSubmit={handleOnSubmit}
      />
    </Column>
  )
}
