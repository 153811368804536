import React, { FC } from 'react'
import { isMobile, isMobileOnly } from 'react-device-detect'
import { useLocation } from 'react-router-dom'

import {
  Column,
  FooterAdditionalLinks,
  ItemCardLink,
  ResponsiveMediaView,
  Row
} from '../../../components'

import { Gallery } from '../gallery'

import { GalleryMobile } from '../gallery-mobile'
import { BasicProductInfo } from '../basic-product-info'
import { ProductVendorInfo } from '../product-vendor-info'
import { getVendorInfo } from '../helpers'
import { ButtonsRow } from '../buttons-row'

import { GeneralProductInfoProps } from './general-product-info.types'
import { useStyle } from './general-product-info.styles'

export const GeneralProductInfo: FC<GeneralProductInfoProps> = ({
  product,
  countryCode,
  favouriteIcon,
  averageRating,
  onAddToWishlistClick,
  onAddToBasket,
  onGetNewOffer,
  onContactVendor
}) => {
  const classes = useStyle()
  const Container = isMobileOnly ? Column : Row
  const ContainerReverse = isMobile ? Row : Column
  const location = useLocation()
  const {
    images = [],
    minCurrency,
    totalPrice,
    name,
    vendor,
    purchaseSpecifics,
    isSale,
    discountMaxPrice,
    deliveryPrice
  } = product

  const {
    id: vendorId,
    companyName,
    activity,
    averageResponseTime
  } = getVendorInfo(vendor)

  const productDeliveryPrice = deliveryPrice !== null ? deliveryPrice?.price : 0
  const isOffer = purchaseSpecifics === 'ONLY_WITH_OFFER'

  const deliveryLink = {
    pathname: `${FooterAdditionalLinks.PAYMENT_DELIVERY}`,
    state: { prevPath: location.pathname }
  }

  const returnsLink = {
    pathname: `${FooterAdditionalLinks.RETURNS}`,
    state: { prevPath: location.pathname }
  }

  const VendorInfo = (
    <ProductVendorInfo
      id={vendorId}
      className={classes.vendorInfo}
      name={companyName}
      countryCode={countryCode}
      activity={activity}
      averageResponseTime={averageResponseTime}
    />
  )

  return (
    <Container className={classes.container} alignItems="flex-start">
      <Row className={classes.imageContainer}>
        <ResponsiveMediaView
          bigScreen={<Gallery images={images} />}
          medium={<Gallery images={images} />}
          tablet={
            <GalleryMobile className={classes.imageContainer} images={images} />
          }
          mobile={
            <GalleryMobile className={classes.imageContainer} images={images} />
          }
        />
      </Row>
      <Column
        className={classes.actions}
        alignItems="flex-start"
        justifyContent="flex-start"
        fullHeight
      >
        <BasicProductInfo
          favouriteIcon={favouriteIcon}
          name={name}
          isSale={isSale}
          currency={minCurrency}
          deliveryPrice={productDeliveryPrice}
          totalPrice={totalPrice}
          averageRating={averageRating}
          discountMaxPrice={discountMaxPrice}
          onAddToWishlistClick={onAddToWishlistClick}
        />
        <ResponsiveMediaView bigScreen={VendorInfo} medium={VendorInfo} />
        <ButtonsRow
          isOffer={isOffer}
          onAddToBasket={onAddToBasket}
          onContactVendor={onContactVendor}
          onGetNewOffer={onGetNewOffer}
        />
        <ContainerReverse
          fullWidth
          alignItems="flex-start"
          justifyContent="space-evenly"
          className={classes.policyRow}
        >
          <ItemCardLink
            to={deliveryLink}
            text="Delivery Policy"
            textTx="product.delivery.policy"
          />
          <ItemCardLink
            to={returnsLink}
            text="Return Policy"
            textTx="product.return.policy"
          />
        </ContainerReverse>
        <ResponsiveMediaView tablet={VendorInfo} mobile={VendorInfo} />
      </Column>
    </Container>
  )
}
