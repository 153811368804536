import { useQuery } from '@apollo/client'

import { COUNTRIES, COUNTRY_STATES_BY_ID } from './country.graphql'
import {
  Countries,
  CountriesData,
  CountryApi,
  CountryStatesById,
  CountryStatesData,
  CountryStatesVariables
} from './country.types'

// our "constructor"
export const countryService = (): CountryApi => {
  const useCountries = (): Countries => {
    const { data, loading, error } = useQuery<CountriesData, any>(COUNTRIES)

    if (error) {
      return { data: null, loading }
    }

    if (!data) {
      return { data: null, loading }
    }

    return { data, loading }
  }

  const useCountryStatesById = (
    variables: CountryStatesVariables
  ): CountryStatesById => {
    const { data, loading, error, refetch } = useQuery<
      CountryStatesData,
      CountryStatesVariables
    >(COUNTRY_STATES_BY_ID, { variables })

    if (error) {
      return { data: null, loading, refetch }
    }

    if (!data) {
      return { data: null, loading, refetch }
    }

    return { data, loading, refetch }
  }

  return {
    useCountries,
    useCountryStatesById
  }
}
