import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  dropdown: {
    top: 0,
    width: 'calc(100% - 16px)',
    borderRadius: 4,
    scrollbarWidth: 'none',
    border: '1px solid rgba(102, 102, 102, 0.87)',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },

  input: {
    width: '100%',
    flex: 1,
    '&:not(:first-child)': {
      marginTop: 16
    },
    '&:first-child': {
      marginTop: 24
    }
  }
})
