import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  listContainer: {
    width: 'calc(100% - 32px)',
    padding: '0 16px'
  },
  items: {
    borderBottom: `1px solid ${theme.colors.shadow}`
  },
  img: {
    width: 64,
    height: 64,
    objectFit: 'cover'
  },
  nameText: {
    marginLeft: 8,
    minWidth: 152
  },
  observeOptionsBtn: {
    marginTop: 6
  },
  item: {
    flex: 6
  },
  '@media screen and (max-width: 480px)': {
    listContainer: {
      padding: 16
    },
    img: {
      width: 104,
      height: 104,
      objectFit: 'cover'
    },
    item: {
      width: '100%'
    }
  }
}))
