import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'
import { CategoryCardStyle } from './category-card.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    '&:hover': {
      background: theme.colors.white,
      boxShadow: `4px 4px 16px ${theme.colors.shadow}`
    },
    transition: 'box-shadow 0.25s ease-in-out'
  },
  imgContainer: {},
  imgLabel: {
    background: theme.colors.white,
    border: `1px solid ${theme.colors.shadow}`,
    padding: 8,
    position: 'absolute',
    right: 0,
    top: 0
  },
  img: {
    objectFit: 'cover',
    width: ({ mini }: CategoryCardStyle) => (mini ? 170 : 274),
    height: ({ mini }: CategoryCardStyle) => (mini ? 112 : 180)
  },
  details: {
    height: ({ mini }: CategoryCardStyle) => (mini ? 46 : 52),
    padding: ({ mini }: CategoryCardStyle) => (mini ? '8px 8px' : '24px 8px'),
    alignItems: ({ mini }: CategoryCardStyle) =>
      mini ? 'flex-start' : 'center',

    width: 'calc(100% - 16px)',
    maxWidth: ({ mini }: CategoryCardStyle) => mini && 155
  },
  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    img: {
      objectFit: 'cover',
      width: ({ mini }: CategoryCardStyle) => (mini ? 170 : 250),
      height: ({ mini }: CategoryCardStyle) => (mini ? 112 : 180)
    }
  }
}))
