import React, { useMemo } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useParams } from 'react-router-dom'

import { useApi } from '../../providers'

import { Recommendations, reduceProductEdgesToProductList } from '../../views'
import {
  CategoryList,
  Content,
  Loader,
  ProductList,
  CategoryBreadcrumbs,
  getListFromEdgesList
} from '../../components'

import { CategoryEmpty } from './category-empty'
import { useStyle } from './category.styles'
import { CategoryPageParams } from './category.types'

export const CategoryPage = () => {
  const { id } = useParams<CategoryPageParams>()
  const {
    category: { useCategoryById }
  } = useApi()
  const { data } = useCategoryById(id)
  const classes = useStyle({ mobile: isMobileOnly })
  const h1Preset = isMobileOnly ? 'h1Mobile' : 'h1'
  const titlePreset = isMobileOnly ? 'h2Mobile' : 'h2'
  const TopProducts = useMemo(() => {
    if (data) {
      return reduceProductEdgesToProductList(data.category.topProducts.edges)
    }

    return []
  }, [data])

  const Parent = useMemo(() => {
    if (data) {
      return data.category
    }

    return null
  }, [id, data])

  const Categories = useMemo(
    () => getListFromEdgesList(Parent?.children),
    [Parent]
  )

  if (!data || !Parent) {
    return <Loader />
  }

  const emptyCategory = Categories.length === 0

  const defaultDescription =
    'A new collection shot in the warmth of soft evening light. Discover elegant silhouettes, refined details.'
  const categoryBreadcrumb = { id: Parent.id, name: Parent.name }

  return (
    <Content justifyContent="flex-start">
      <CategoryBreadcrumbs
        active
        category={Parent}
        className={classes.breadcrumbs}
      />
      {!emptyCategory ? (
        <>
          <CategoryList
            className={classes.categories}
            titlePreset={h1Preset}
            description={Parent.description || defaultDescription}
            data={Categories}
            title={Parent.name}
            categoryBreadcrumb={categoryBreadcrumb}
          />
          {TopProducts.length > 0 && (
            <ProductList
              carousel
              className={classes.products}
              title="TOP Products"
              titlePreset={titlePreset}
              titleTx="topProducts.title"
              data={TopProducts}
            />
          )}
        </>
      ) : (
        <CategoryEmpty parent={Parent} />
      )}
      <Recommendations />
    </Content>
  )
}
