import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    backgroundColor: theme.colors.white,
    padding: '24px 0px'
  },
  content: {
    marginTop: 16
  },
  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    container: {
      width: 'calc(100% - 32px)',
      padding: '24px 16px'
    }
  },
  '@media screen and (max-width: 480px)': {
    container: {
      padding: '24px 0px 40px'
    }
  }
}))
