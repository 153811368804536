import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  footer: {
    borderTop: `1px solid ${theme.colors.shadow}`,
    background: theme.colors.white,
    padding: '56px 0'
  },
  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    footer: {
      width: 'calc(100% - 192px)',
      padding: '56px 96px'
    }
  }
}))
