import React, { useMemo } from 'react'
import { isMobileOnly } from 'react-device-detect'

import {
  Column,
  Text,
  Row,
  Content,
  getListFromEdgesList,
  Button
} from '../../components'

import { useApi } from '../../providers'

import { MakerDesignerList } from './maker-designer-list'
import { useStyle } from './maker-designer.styles'

export const MakerDesignerPage = () => {
  const classes = useStyle()
  const titlePreset = isMobileOnly ? 'h1Mobile' : 'h1'

  const {
    vendor: { useVendorListData }
  } = useApi()
  const first = isMobileOnly ? 4 : 10

  const { data: response, fetchMore } = useVendorListData({
    first
  })

  const Data = useMemo(
    () => getListFromEdgesList(response?.vendors),
    [response?.vendors.edges.length]
  )

  const totalCount = response?.vendors.totalCount || 0
  const count = response?.vendors.edges.length || 0

  const handleOnNext = () => {
    if (response) {
      const { hasNextPage, endCursor } = response.vendors.pageInfo
      if (hasNextPage && fetchMore) {
        fetchMore({
          variables: {
            after: endCursor
          }
        })
      }
    }
  }

  return (
    <Content
      className={classes.container}
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Column fullWidth justifyContent="flex-start">
        <Text text="Maker/Designer" tx="header.maker" preset={titlePreset} />
        <Text
          className={classes.description}
          color="inactive"
          preset="h7"
          text="A new collection shot in the warmth of soft evening light. Discover elegant silhouettes, refined details."
          tx="bestSellingProducts.description"
        />

        <Row fullWidth wrap className={classes.vendors}>
          <MakerDesignerList
            totalCount={totalCount}
            count={count}
            data={Data}
          />
        </Row>
        {totalCount > count && (
          <Column fullWidth className={classes.load}>
            <Button
              color="yellow"
              preset="primary"
              textColor="white"
              textPreset="h5"
              text="View More"
              tx="vendor.button.text"
              onClick={handleOnNext}
            />
          </Column>
        )}
      </Column>
    </Content>
  )
}
