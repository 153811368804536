import React, { FC } from 'react'

import { arrayHasElements, Column, Modal, Row, Text } from '../../components'

import { useStyle } from './units-list-modal.styles'
import { UnitsListModalProps } from './units-list-modal.types'

export const UnitsListModal: FC<UnitsListModalProps> = ({
  unitIds,
  open,
  onClose
}) => {
  const classes = useStyle()

  if (!open) {
    return <></>
  }

  return (
    <Modal
      className={classes.container}
      title="Item numbers:"
      titleTx="tooltip.title"
      onClose={onClose}
    >
      <Column fullWidth alignItems="flex-start" className={classes.details}>
        {unitIds &&
          arrayHasElements(unitIds) &&
          unitIds.map((unitId) => (
            <Row
              key={`id_${unitId}`}
              fullWidth
              justifyContent="flex-start"
              className={classes.row}
            >
              <Text text={unitId} />
            </Row>
          ))}
      </Column>
    </Modal>
  )
}
