import React, { FC } from 'react'

import { Column, Text, Row, Checkbox } from '../../../components'

import { SubscriptionItemProps } from './subscription-item.types'
import { useStyle } from './subscription-item-styles'

export const SubscriptionItem: FC<SubscriptionItemProps> = ({
  className = '',
  title,
  titleTx,
  description,
  descriptionTx
}) => {
  const classes = useStyle()

  return (
    <Row alignItems="flex-start" className={className}>
      <Checkbox />
      <Column className={classes.column} alignItems="flex-start">
        <Text preset="h5" text={title} tx={titleTx} />
        <Text
          className={classes.text}
          preset="body"
          text={description}
          tx={descriptionTx}
        />
      </Column>
    </Row>
  )
}
