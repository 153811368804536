import React, { FC } from 'react'
import { isMobileOnly } from 'react-device-detect'

import { Column } from '../column'
import { Row } from '../row'
import { Text } from '../text'

import { useStyle } from './cabinet-page-container.styles'
import { CabinetPageContainerProps } from './cabinet-page-container.types'

export const CabinetPageContainer: FC<CabinetPageContainerProps> = ({
  className = '',
  classNameContent = '',
  children,
  label,
  labelTx,
  rightHeaderContent
}) => {
  const classes = useStyle()

  const titlePreset = isMobileOnly ? 'h3Mobile' : 'h3'

  return (
    <Column
      fullWidth
      className={`${className} ${classes.container}`}
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Row fullWidth justifyContent="space-between">
        <Text preset={titlePreset} text={label} tx={labelTx} />
        <Row>{rightHeaderContent}</Row>
      </Row>
      <Column
        fullWidth
        className={`${classNameContent} ${classes.content}`}
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        {children}
      </Column>
    </Column>
  )
}
