import { useMutation, useQuery } from '@apollo/client'

import { ListVariables } from '../api.types'
import { ME_QUERY } from '../auth/auth.graphql'
import { GetAccountMeData } from '../auth'

import {
  DraftOrderCreateInput,
  DraftOrderCreateData,
  DraftOrderCreate,
  GetOrders,
  GetOrdersData,
  OrderApi,
  OrderUpdate,
  OrderUpdateData,
  OrderUpdateVariables,
  OrderByIdVariables,
  GetOrderByIdRequest,
  GetOrderData,
  OrderHistory,
  OrderHistoryVariables,
  OrderHistoryData,
  GetBilling,
  GetBillingData,
  ReturnExchange,
  ReturnExchangeData,
  ReturnExchangeVariables,
  DraftOrederBulkCreate,
  DraftOrderBulkCreateData,
  DraftOrderBulkCreateInput,
  OrdersVariables,
  GetOrdersReturnData,
  GetOrdersReturn,
  OrdersToReturnVariables
} from './order.types'
import {
  DRAFT_ORDER_CREATE,
  ORDERS_QUERY,
  ORDER_UPDATE_MUTATION,
  ORDER_BY_ID_QUERY,
  ORDER_HISTORY_QUERY,
  BILLING_QUERY,
  RETURN_EXCHANGE_MUTATION,
  DRAFT_ORDER_BULK_CREATE_MUTATION,
  ORDERS_RETURN_QUERY
} from './order.graphql'

export const orderServise = (): OrderApi => {
  const useDraftOrderCreate = (): DraftOrderCreate => {
    const [RequestAccountAddressCreateSubmit, response] = useMutation<
      DraftOrderCreateData,
      DraftOrderCreateInput
    >(DRAFT_ORDER_CREATE)

    const handleOnSubmit = (variables: DraftOrderCreateInput) => {
      const options = {
        variables
      }
      RequestAccountAddressCreateSubmit(options)
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useDraftOrderBulkCreate = (): DraftOrederBulkCreate => {
    const [onDraftOrderBuldCreateSubmit, response] = useMutation<
      DraftOrderBulkCreateData,
      DraftOrderBulkCreateInput
    >(DRAFT_ORDER_BULK_CREATE_MUTATION)

    const handleOnSubmit = (variables: DraftOrderBulkCreateInput) => {
      const options = {
        variables
      }
      onDraftOrderBuldCreateSubmit({
        ...options,
        update(cache, results) {
          const order = results.data?.draftOrderBulkCreate.orders[0]
          if (order?.user.defaultShippingAddress) {
            const queryResult = cache.readQuery<GetAccountMeData>({
              query: ME_QUERY
            })

            if (queryResult) {
              cache.writeQuery<GetAccountMeData>({
                query: ME_QUERY,
                data: {
                  ...queryResult,
                  me: {
                    ...queryResult.me,
                    defaultShippingAddress: order.user.defaultShippingAddress
                  }
                }
              })
            }
          }
        }
      })
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useOrderUpdate = (): OrderUpdate => {
    const [OrderUpdateSubmit, response] = useMutation<
      OrderUpdateData,
      OrderUpdateVariables
    >(ORDER_UPDATE_MUTATION)

    const handleOnSubmit = (variables: OrderUpdateVariables) => {
      const options = {
        variables
      }
      OrderUpdateSubmit({
        ...options,
        update(cache, result) {
          const queryResult = cache.readQuery<GetOrdersData>({
            query: ORDERS_QUERY
          })
          if (queryResult) {
            cache.writeQuery<GetOrdersData>({
              query: ORDERS_QUERY,
              data: {
                ...queryResult,

                orders: {
                  ...queryResult.orders,
                  edges: queryResult.orders.edges.map((item) => {
                    if (item.node.id === result.data?.orderUpdate.order.id) {
                      return {
                        ...item,
                        node: {
                          ...item.node,
                          ...result.data.orderUpdate.order
                        }
                      }
                    }

                    return item
                  })
                }
              }
            })
          }
        }
      })
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useReturnExchange = (): ReturnExchange => {
    const [ReturnExchangeSubmit, response] = useMutation<
      ReturnExchangeData,
      ReturnExchangeVariables
    >(RETURN_EXCHANGE_MUTATION)

    const handleOnSubmit = (variables: ReturnExchangeVariables) => {
      const options = {
        variables
      }
      ReturnExchangeSubmit(options)
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useOrders = (variables: OrdersVariables): GetOrders => {
    const { data, loading, error, refetch, fetchMore } = useQuery<
      GetOrdersData,
      OrdersVariables
    >(ORDERS_QUERY, { variables })

    return { data, error, loading, refetch, fetchMore }
  }

  const useOrderHistory = (variables: OrderHistoryVariables): OrderHistory => {
    const { data, loading, error, refetch, fetchMore } = useQuery<
      OrderHistoryData,
      OrderHistoryVariables
    >(ORDER_HISTORY_QUERY, {
      variables,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first'
    })

    return { data, loading, error, refetch, fetchMore }
  }

  const useBilling = (variables: ListVariables): GetBilling => {
    const { data, loading, error, refetch } = useQuery<
      GetBillingData,
      ListVariables
    >(BILLING_QUERY, { variables })

    if (error) {
      return { data: null, loading, refetch }
    }

    if (!data) {
      return { data: null, loading, refetch }
    }

    return { data, loading, refetch }
  }

  const useOrdersToReturn = (
    variables: OrdersToReturnVariables
  ): GetOrdersReturn => {
    const { data, loading, error, refetch, fetchMore } = useQuery<
      GetOrdersReturnData,
      OrdersToReturnVariables
    >(ORDERS_RETURN_QUERY, {
      variables,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first'
    })

    return { data, error, loading, refetch, fetchMore }
  }

  const useOrderById = (variables: OrderByIdVariables): GetOrderByIdRequest => {
    const { data, loading, error, refetch } = useQuery<
      GetOrderData,
      OrderByIdVariables
    >(ORDER_BY_ID_QUERY, { variables })

    if (error) {
      return { data: null, loading, refetch }
    }

    if (!data) {
      return { data: null, loading, refetch }
    }

    return { data, loading, refetch }
  }

  return {
    useDraftOrderCreate,
    useOrders,
    useOrderHistory,
    useOrderUpdate,
    useOrderById,
    useOrdersToReturn,
    useBilling,
    useReturnExchange,
    useDraftOrderBulkCreate
  }
}
