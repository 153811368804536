import { arrayHasElements } from '../../components'
import {
  OfferColor,
  OfferMaterial,
  OfferOption,
  ProductOptionType,
  ProductWithOptions
} from '../../services'

export const getTotalPriceByProducts = (products: ProductWithOptions[]) =>
  products
    .reduce(
      (acc, prev) =>
        acc +
        prev.count *
          (prev.defaultPrice +
            prev.orderColor.extraPrice +
            prev.orderMaterial.extraPrice),
      0
    )
    .toFixed(2)

export const getPickedMaterial = (materials: OfferMaterial[]) =>
  materials
    .filter((material) => material.isDefault === true)
    .map((item) => item.title)
    .toString()

export const getPickedColorTitle = (colors: OfferColor[]) =>
  colors
    .filter((color) => color.isDefault === true)
    .map((item) => item.title)
    .toString()

export const getPickedColorCode = (colors: OfferColor[]) =>
  colors
    .filter((color) => color.isDefault === true)
    .map((item) => item.color.code)
    .toString()

export const getPickedOptions = (options?: OfferOption[]) =>
  options
    ?.map((offerOption) => offerOption.variants)
    .flatMap((option) => option)
    .filter((variant) => variant.isDefault === true)

export const getSingleOptionsFromOffer = (options?: OfferOption[]) =>
  options?.filter((option) => option.type === ProductOptionType.RADIOBUTTON)

export const getMultiOptionsFromOffer = (options?: OfferOption[]) =>
  options?.filter((option) => option.type === ProductOptionType.CHECKBOX)

export const getIsPickedMultiOption = (options?: OfferOption[]) =>
  arrayHasElements(
    options
      ?.flatMap((option) => option.variants)
      ?.filter((variant) => variant.isDefault === true)
  )
