import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  tooltipContainer: {
    position: 'absolute',
    zIndex: 2,
    left: 32,
    bottom: 8,
    minWidth: 120,
    maxHeight: 288,
    backgroundColor: theme.colors.vendorProfileFon,
    border: `1px solid ${theme.colors.shadow}`,
    borderRadius: '16px 16px 16px 0'
  },
  title: {
    display: 'inline-block',
    textAlign: 'center',
    padding: 12,
    borderBottom: `1px solid ${theme.colors.shadow}`
  },
  ids: {
    padding: 12,
    rowGap: 8,
    width: 'calc(100% - 24px)',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: 4,
      height: 40
    }
  }
}))
