import React from 'react'
import { useHistory } from 'react-router-dom'

import { useApi } from '../../../providers'
import payPalIcon from '../../../assets/images/pay-pal-mob.png'
import MasterCardIcon from '../../../assets/images/masterCard-mob.png'
import visaIcon from '../../../assets/images/visa-mob.png'
import dinerClubIcon from '../../../assets/images/diner-club-mob.png'
import americanExpressIcon from '../../../assets/images/american-express-mob.png'

import { Row } from '../../row'
import { Column } from '../../column'
import { Text } from '../../text'
import { Content } from '../../content'
import { Loader } from '../../loader'
import { Button } from '../../button'

import { NavSocialBlock } from './nav-social-mobile'
import { useStyle } from './nav-mobile.styles'

import { NavCategoryMobile } from './nav-category-mobile'

export const NavMobile = () => {
  const { menu } = useApi()
  const history = useHistory()
  const { data, loading } = menu.useSecondaryMenu()
  const classes = useStyle()

  if (!data || loading) {
    return <Loader />
  }

  const categories = data.shop.navigation.secondary.items

  const handleOnNavigate = (path: string) => () => {
    history.push(path)
  }

  return (
    <Content>
      <Row>
        <NavSocialBlock
          className={classes.social}
          classNameIcon={classes.socialIcon}
        />
      </Row>
      <Column
        className={classes.innerContainer}
        alignItems="flex-start"
        justifyContent="space-between"
      >
        {categories.map((item) => {
          return (
            <NavCategoryMobile
              className={classes.category}
              key={item.id}
              item={item}
            />
          )
        })}
        <Row
          fullWidth
          justifyContent="space-between"
          className={classes.newsletter}
        >
          <Text
            color="black"
            preset="h6"
            text="Newsletter"
            tx="footer.newsletter"
          />
          <Button
            className={classes.subscribeBtn}
            text="SUBSCRIBE"
            tx="footer.subscribe"
            textColor="black"
            textPreset="maxButton"
            preset="button5"
            onClick={handleOnNavigate('/subscription')}
          />
        </Row>
      </Column>
      <Column className={classes.border}>
        <Column
          className={classes.innerContainer}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Row justifyContent="space-between" className={classes.paymentBlock}>
            <Row>
              <img src={payPalIcon} alt="Available for pay" />
            </Row>
            <Row>
              <img src={MasterCardIcon} alt="Available for pay" />
              <img src={visaIcon} alt="Available for pay" />
              <img src={dinerClubIcon} alt="Available for pay" />
              <img src={americanExpressIcon} alt="Available for pay" />
            </Row>
          </Row>
          <Row className={classes.brandBlock}>
            <Text
              color="shadow"
              preset="secondTitle"
              text="Copyright by"
              tx="footer.copyright"
            />
            <Text
              color="yellow"
              className={classes.brand}
              preset="secondTitle"
              text="Ovaflopick"
            />
          </Row>
        </Column>
      </Column>
    </Content>
  )
}
