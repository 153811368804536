import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  itemContainer: {
    borderBottom: `1px solid ${theme.colors.shadow}`,
    padding: '8px 0'
  },
  itemTitle: {
    marginLeft: 8
  }
}))
