import React, { useMemo, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useHistory, useParams } from 'react-router-dom'

import { useApi } from '../../providers'
import { ConfirmationModal } from '../confirmation'
import { FeedbackCreateInput } from '../../services'
import {
  Column,
  Content,
  FeedbackVendorItem,
  FeedbacksListVendor,
  Row,
  Text,
  GoBackContainer,
  ResponsiveMediaView,
  ProductInfoBottomBarMobile
} from '../../components'

import { useStyle } from './vendor-feedback.styles'
import { FeedbackTypeParams } from './vendor-feedback.types'
import { VendorFeedbackButtons } from './vendor-feedback-buttons'

export const VendorFeedbackPage = () => {
  const { id } = useParams<FeedbackTypeParams>()

  const classes = useStyle()
  const history = useHistory()
  const {
    feedback: { useCreateFeedback }
  } = useApi()
  const {
    vendor: { useVendorById }
  } = useApi()

  const { onSubmit } = useCreateFeedback()

  const { data: vendorData } = useVendorById({ id })
  const [openConfirm, changeOpenConfirm] = useState(false)
  const [feedbackData, changeData] = useState<FeedbackCreateInput[]>([])

  const Vendor = useMemo(() => {
    if (vendorData) {
      return vendorData
    }

    return undefined
  }, [vendorData])

  const hiddenFeedbacks = useMemo(() => {
    if (vendorData?.vendor.feedbacks.length === 0) {
      return null
    }

    return vendorData
  }, [vendorData])

  const handleOnChangeVendorFeedback = (data: FeedbackCreateInput) => {
    let nextFeedbackData = feedbackData.filter(
      (feedback) => feedback.vendor !== data.vendor
    )
    nextFeedbackData = [...nextFeedbackData, data]
    changeData(nextFeedbackData)
  }

  const companyName = Vendor && Vendor.vendor.companyName

  const countryCode = Vendor?.vendor?.defaultBillingAddress?.country?.code
  const titlePreset = isMobileOnly ? 'h1Mobile' : 'h1'
  const Container = isMobileOnly ? Column : Row

  const handleGoBack = () => history.goBack()

  const isDisabled = !feedbackData.length

  const handleOnOpenConfirm = (state: boolean) => () => {
    if (state === false) {
      history.goBack()
    }
    changeOpenConfirm(state)
  }

  const handleOnOpenModal = () => {
    const openModal = handleOnOpenConfirm(true)

    openModal()
  }

  const handleOnSubmit = () => {
    feedbackData.forEach((item) => {
      const newFeedback = { input: item }
      onSubmit(newFeedback)
    })
    handleOnOpenModal()
  }

  const handleOnGoBack = () => {
    history.goBack()
  }

  const Buttons = (
    <VendorFeedbackButtons
      isDisabled={isDisabled}
      onGoBack={handleOnGoBack}
      onSubmit={handleOnSubmit}
    />
  )

  return (
    <>
      <Content>
        <Column
          fullHeight
          className={classes.container}
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <GoBackContainer
            text="Back to the Seller's page"
            textTx="vendor.feedback.back.to.sellers.page"
            onClick={handleGoBack}
          />
          <Column className={classes.title} fullWidth>
            <Text
              text="Give Feedback"
              tx="give.feedback.header.title"
              preset={titlePreset}
            />
            <Text
              text="Your comments will help other buyers! Please tell us more:"
              tx="give.feedback.secondary.title"
              preset="h5"
              color="inactive"
            />
          </Column>
          <Container className={classes.content} fullWidth>
            {Vendor && (
              <FeedbackVendorItem
                className={classes.item}
                companyName={companyName}
                avatar={Vendor.vendor.avatar?.url}
                vendorId={id}
                country={Vendor.vendor.country}
                countryCode={countryCode}
                onChange={handleOnChangeVendorFeedback}
              />
            )}
          </Container>
          {Vendor && hiddenFeedbacks && (
            <FeedbacksListVendor
              className={classes.feedback}
              data={Vendor?.vendor.feedbacks}
            />
          )}
        </Column>
        <Row fullWidth className={classes.submitContainer}>
          <ResponsiveMediaView
            bigScreen={Buttons}
            medium={Buttons}
            tablet={Buttons}
          />
        </Row>
        <ConfirmationModal
          title="Thank you!"
          txTitle="confirmation.title.text"
          text="Thanks for sharing your thoughts. We appriciate your feedback."
          tx="give.feedback.thanks.modal.message"
          open={openConfirm}
          onClose={handleOnOpenConfirm(false)}
        />
      </Content>
      <ResponsiveMediaView
        mobile={
          <ProductInfoBottomBarMobile className={classes.bottomContainer}>
            {Buttons}
          </ProductInfoBottomBarMobile>
        }
      />
    </>
  )
}
