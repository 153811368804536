import { createUseStyles } from 'react-jss'
import { isMobile } from 'react-device-detect'

import background from '../../assets/images/background-pattern.png'

export const useStyle = createUseStyles({
  container: {
    backgroundSize: '100vw',
    backgroundRepeat: 'space',
    backgroundImage: `url(${background})`,
    paddingBottom: 64,
    zIndex: 1
  },
  changeForm: {
    marginTop: isMobile ? 24 : 96,
    width: 592
  },
  changeSecondaryText: {
    marginTop: 8
  },
  logIn: {
    marginTop: 256,
    width: 488
  },
  signUp: {
    marginLeft: 4
  },
  signUpContainer: {
    marginTop: 24
  }
})
