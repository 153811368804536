import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

import { DropdownItemProps } from './dropdown-item.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: 12,
    minWidth: ({ isScrollable }: Partial<DropdownItemProps>) =>
      isScrollable ? 'auto' : 200,
    border: 'none',
    width: 'calc(100% - 24px)',
    backgroundColor: theme.colors.white,
    color: theme.colors.black,
    cursor: 'pointer',
    '& > *': {
      cursor: 'pointer'
    },
    '&:hover': {
      backgroundColor: theme.colors.lightBlue,
      '& > *': {
        color: theme.colors.black
      }
    },
    '&:focus': {
      backgroundColor: theme.colors.lightBlue,
      border: 'none',
      outline: 'none',
      '& > *': {
        color: theme.colors.black
      }
    },
    '&:focus-within': {
      backgroundColor: theme.colors.lightBlue,
      border: 'none',
      outline: 'none',
      '& > *': {
        color: theme.colors.black
      }
    }
  },
  disabled: {
    cursor: 'default',
    backgroundColor: theme.colors.greyFon,
    '& > *': {
      cursor: 'default',
      color: theme.colors.black
    },
    '&:hover': {
      backgroundColor: theme.colors.greyFon,
      '& > *': {
        color: `${theme.colors.black}!important`
      }
    }
  },
  text: {
    textAlign: 'start'
  }
}))
