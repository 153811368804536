import moment from 'moment'
import { MobileView } from 'react-device-detect'
import {
  postcodeValidator,
  postcodeValidatorExistsForCountry
} from 'postcode-validator'

import { Column, TextProps } from '../components'
import { config, NodeEnv } from '../config'
import {
  Address,
  CoreItem,
  Instruction,
  OfferStatus,
  OrderDirection,
  OrderMainStatus,
  OrderPaymentStatus,
  ProductOrderField,
  ProductOrderSort,
  User
} from '../services'
import { Color } from '../theme'

export const REGEX_EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const validateEmail = (email: string) => {
  const testEmail = REGEX_EMAIL.test(email)

  return testEmail
}

export const REGEX_PHONE = /^\+(?=(?:\s?\d){7,17}$)\d+(?:\s?\d+){0,3}$/

export const validatePhone = (phone: string) => {
  const testPhone = REGEX_PHONE.test(phone)

  return testPhone
}

export const REGEX_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{5,30}$/

export const validatePassword = (password: string) => {
  const testPassword = REGEX_PASSWORD.test(password)

  return testPassword
}
export const REGEX_FIRSNAME =
  /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*.{0,30}$/

export const validateFirstName = (firstName: string) => {
  const testFirstName = REGEX_FIRSNAME.test(firstName)

  return testFirstName
}
export const REGEX_LASTNAME =
  /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*.{0,30}$/

export const validateLastName = (lastName: string) => {
  const testLastName = REGEX_LASTNAME.test(lastName)

  return testLastName
}

export const getDefaultStateShippingAddress: Address = {
  id: '',
  country: undefined,
  firstName: '',
  lastName: '',
  companyName: '',
  streetAddress1: '',
  streetAddress2: '',
  city: '',
  cityArea: '',
  postalCode: '',
  countryArea: '',
  phone: '',
  isDefaultShippingAddress: false,
  isDefaultBillingAddress: false
}

export const getViewByEnv = () => {
  if (config.NODE_ENV === NodeEnv.DEV) {
    return Column
  }

  return MobileView
}

export const filterWithZeroCounter = <T extends CoreItem>(
  data?: T[],
  active?: T[]
) => {
  if (data) {
    return data.filter((item) => {
      if (active) {
        const current = active.find((activeItem) => activeItem.id === item.id)
        return current || Number(item.count) !== 0
      }

      return Number(item.count) !== 0
    })
  }

  return []
}

export type CoreItemByAlphabet = Record<string, CoreItem[]>

export const getCoreItemsByAlphabet = (
  data: CoreItem[]
): CoreItemByAlphabet => {
  return data.reduce<CoreItemByAlphabet>((acc, countryItem) => {
    const firstChar = countryItem.name[0].toUpperCase()
    const currentDataByChar = acc[firstChar] || []
    return {
      ...acc,
      [firstChar]: [...currentDataByChar, countryItem]
    }
  }, {})
}

export const getFormatedOrderDate = (date?: Date) => {
  if (date) {
    return moment(date).format('MM.DD.YYYY')
  }

  return '__.__.____'
}

export const getColorByMainStatus = (status: OrderMainStatus): Color => {
  switch (status) {
    case OrderMainStatus.NOT_CONFIRMED:
      return 'notConfirmed'
    case OrderMainStatus.CONFIRMED:
      return 'blue'
    case OrderMainStatus.PRODUCTION_STARTED:
      return 'darkBlue'
    case OrderMainStatus.PRODUCTION_FINISHED:
      return 'yellow'
    case OrderMainStatus.PRODUCT_SHIPPED:
      return 'purple'
    case OrderMainStatus.PARCEL_DELIVERED:
      return 'green'
    case OrderMainStatus.CANCELED_BY_CUSTOMER:
    case OrderMainStatus.CANCELED_BY_VENDOR:
      return 'canceled'
    case OrderMainStatus.CANCELED_WITHOUT_REFUND:
      return 'canceledWithoutRefund'
    case OrderMainStatus.PAID:
      return 'green'
    case OrderMainStatus.REFUNDED:
      return 'refunded'
    case OrderMainStatus.PARTLY_REFUNDED:
      return 'partlyRefunded'
    default:
      return 'inactiveSecondary'
  }
}

export const getColorByOrderPaymentStatus = (
  status: OrderPaymentStatus
): Color => {
  switch (status) {
    case OrderPaymentStatus.COMPLETED:
      return 'green'
    case OrderPaymentStatus.REFUNDED:
      return 'active'
    case OrderPaymentStatus.PARTIALLY_REFUNDED:
      return 'yellow'
    case OrderPaymentStatus.PENDING:
      return 'purple'
    case OrderPaymentStatus.FAILED:
      return 'canceled'
    case OrderPaymentStatus.DECLINED:
      return 'error'
    default:
      return 'error'
  }
}

export const getMessageByPaymentStatus = (
  status: OrderPaymentStatus
): TextProps => {
  switch (status) {
    case OrderPaymentStatus.DECLINED:
      return {
        text: 'Declined',
        tx: 'paymentStatus.declined'
      }
    case OrderPaymentStatus.COMPLETED:
      return {
        text: 'Completed',
        tx: 'paymentStatus.completed'
      }
    case OrderPaymentStatus.PARTIALLY_REFUNDED:
      return {
        text: 'Partially Refunded',
        tx: 'paymentStatus.partiallyRefunded'
      }
    case OrderPaymentStatus.PENDING:
      return {
        text: 'Pending',
        tx: 'paymentStatus.pending'
      }
    case OrderPaymentStatus.REFUNDED:
      return {
        text: 'Refunded',
        tx: 'paymentStatus.refunded'
      }
    case OrderPaymentStatus.FAILED:
      return {
        text: 'Failed',
        tx: 'paymentStatus.failed'
      }
    case OrderPaymentStatus.PROCESS:
      return {
        text: 'Process',
        tx: 'paymentStatus.process'
      }
    case OrderPaymentStatus.BLOCKED:
      return {
        text: 'Blocked',
        tx: 'paymentStatus.blocked'
      }
    case OrderPaymentStatus.DENIED:
      return {
        text: 'Denied',
        tx: 'paymentStatus.denied'
      }
    case OrderPaymentStatus.HELD:
      return {
        text: 'Held',
        tx: 'paymentStatus.held'
      }
    case OrderPaymentStatus.RETURNED:
      return {
        text: 'Returned',
        tx: 'paymentStatus.returned'
      }
    case OrderPaymentStatus.SUCCEEDED:
      return {
        text: 'Succeeded',
        tx: 'paymentStatus.succeeded'
      }
    case OrderPaymentStatus.UNCLAIMED:
      return {
        text: 'Unclaimed',
        tx: 'paymentStatus.unclaimed'
      }
    default:
      return {
        text: 'Not Charged',
        tx: 'paymentStatus.notCharged'
      }
  }
}

export const getMessageByMainStatus = (status: OrderMainStatus): TextProps => {
  switch (status) {
    case OrderMainStatus.CONFIRMED:
      return { text: 'Confirmed', tx: 'mainStatus.confirmed' }
    case OrderMainStatus.PRODUCTION_STARTED:
      return { text: 'Production Started', tx: 'mainStatus.productionStarted' }
    case OrderMainStatus.PRODUCTION_FINISHED:
      return {
        text: 'Production Finished',
        tx: 'mainStatus.productionFinished'
      }
    case OrderMainStatus.PRODUCT_SHIPPED:
      return { text: 'Product Shipped', tx: 'mainStatus.productShipped' }
    case OrderMainStatus.PARCEL_DELIVERED:
      return { text: 'Parcel Delivered', tx: 'mainStatus.parcelDelivered' }
    case OrderMainStatus.CANCELED_BY_CUSTOMER:
      return { text: 'Canceled By Customer', tx: 'mainStatus.canceledCustomer' }
    case OrderMainStatus.CANCELED_BY_VENDOR:
      return { text: 'Canceled By Vendor', tx: 'mainStatus.canceledVendor' }
    case OrderMainStatus.CANCELED_WITHOUT_REFUND:
      return {
        text: 'Canceled without Refund',
        tx: 'mainStatus.canceledWithoutRefund'
      }
    case OrderMainStatus.PAID:
      return { text: 'Paid', tx: 'mainStatus.paid' }
    case OrderMainStatus.REFUNDED:
      return { text: 'Refunded', tx: 'mainStatus.refunded' }
    case OrderMainStatus.PARTLY_REFUNDED:
      return { text: 'Partly Refunded', tx: 'mainStatus.partlyRefunded' }
    default:
      return { text: 'Not Confirmed', tx: 'mainStatus.notConfirmed' }
  }
}

export const getMessageByOfferStatus = (status: OfferStatus): TextProps => {
  switch (status) {
    case OfferStatus.DRAFT:
      return { text: 'Start offer', tx: 'offerStatus.under.discussion' }
    case OfferStatus.NEW:
      return { text: 'Sent', tx: 'offerStatus.new' }
    case OfferStatus.ACCEPTED:
      return {
        text: 'Accepted',
        tx: 'offerStatus.accepted'
      }
    case OfferStatus.ACCEPTED_BACK_FROM_CART:
      return {
        text: 'Accepted',
        tx: 'offerStatus.accepted'
      }
    case OfferStatus.DECLINED:
      return {
        text: 'Declined',
        tx: 'offerStatus.declined'
      }
    case OfferStatus.DELETED_BY_CUSTOMER:
      return {
        text: 'Deleted By Customer',
        tx: 'offerStatus.deleted.by.customer'
      }
    case OfferStatus.DECLINED_BY_CUSTOMER:
      return {
        text: 'Declined',
        tx: 'offerStatus.declined.by.customer'
      }
    case OfferStatus.DECLINED_BY_VENDOR:
      return {
        text: 'Declined',
        tx: 'offerStatus.declined.by.vendor'
      }
    case OfferStatus.START_OFFER:
      return {
        text: 'Start offer',
        tx: 'offerStatus.under.discussion'
      }

    default:
      return { text: 'Unknown', tx: 'offerStatus.unknown' }
  }
}

export const getBreadcrumbsTitle = (path: string): TextProps => {
  switch (path) {
    case 'fromOffersPage':
      return {
        text: 'Back to Offers',
        tx: 'order.preview.back.to.offers'
      }
    case 'fromProductsPage':
      return {
        text: 'Back to Product Page',
        tx: 'offer.chat.back.to.product.page'
      }
    case 'fromCart':
      return {
        text: 'Back to Shopping Cart',
        tx: 'order.preview.back.to.shopping.cart'
      }
    default:
      return {
        text: 'Back to Product Page',
        tx: 'offer.chat.back.to.product.page'
      }
  }
}

export const ValidateUserZipCode = (user: User) => {
  if (user.defaultShippingAddress?.country) {
    const { country, postalCode } = user.defaultShippingAddress
    const isAvaliable = postcodeValidatorExistsForCountry(country?.code)
    if (isAvaliable) {
      const isValidePostalCode = postcodeValidator(postalCode, country?.code)
      return isValidePostalCode
    }

    // eslint-disable-next-line no-console
    console.error('Current country does not exist to validation postal code')

    return true
  }
  return false
}

export const ValidateUserPhone = (user: User) => {
  if (user.defaultShippingAddress) {
    const isValidePhone = validatePhone(user.defaultShippingAddress.phone)
    return isValidePhone
  }
  return false
}

export const getSortByValue = (value: string): ProductOrderSort | undefined => {
  if (value) {
    const values = value.split(',')

    return {
      field: values[0] as ProductOrderField,
      direction: values[1] as OrderDirection
    }
  }

  return undefined
}

export const getPriceToFixed = (price: number | undefined) => {
  if (price) {
    return price.toFixed(2)
  }
  return '0'
}

export const getPriceByQuantity = (
  price?: number | null,
  quantity?: number
) => {
  if (price && quantity) {
    return price * quantity
  }
  return 0
}

export const limit = () =>
  Math.max(
    document.body.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.clientHeight,
    document.documentElement.scrollHeight,
    document.documentElement.offsetHeight
  ) - window.innerHeight

export const stopDefAction = (event: any) => {
  event.preventDefault()
}

export const getInstruction = (data: Instruction[], name: string) => {
  const instruction = data.find((item) => item.name === name)

  if (instruction) {
    return instruction.text
  }

  return '-'
}
