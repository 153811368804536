import {
  BackgroundImage,
  DateRangeInput,
  FetchMore,
  ListVariables,
  ListVariablesWithFilter,
  PaginationList
} from '../api.types'

export enum BannerKind {
  PROMOTION_BANNER = '1',
  PREMIUM_USER_BANNER = '2',
  TOP_PRODUCT_BANNER = '3',
  PROMOTED_STORE_BANNER = '4'
}

export enum BannerButtonType {
  BIG_BUTTON = '1',
  LITTLE_BUTTON = '2'
}

export enum TypeName {
  MENU = 'Menu',
  MENU_ITEM = 'MenuItem'
}

export type MainMenuCategory = {
  id: string
  name: string
  description: string
  slug: string | null
  level: number
  backgroundImage: BackgroundImage
}

export type MainMenuItem = {
  id: string
  name: string
  category: MainMenuCategory
  url: null
  page: null
  parent: null
  children: MainMenuItem[]
}

export type SecondaryMenuShop = {
  shop: {
    navigation: {
      secondary: {
        __typename: TypeName
        id: string
        items: MainMenuItem[]
      }
    }
  }
}

export type MainMenuShop = {
  shop: {
    navigation: {
      main: {
        __typename: TypeName
        id: string
        items: MainMenuItem[]
      }
    }
  }
}

export type Banner = {
  id: string
  image: string
  mobileImage: string
  kind: BannerKind
  isButtonActive: boolean
  isNotificationActive?: boolean
  buttonType: BannerButtonType
  buttonText: string
  buttonLink: string
  mainHeading: string
  secondaryHeading: string
  title: string
  description: string
  mainDescription: string
  secondaryDescription: string
  startDate: string
  endDate: string
  isVisible: boolean
  notificationMessageText: string
  notificationButtonText: string
  notificationButtonLink: string
}

export interface PromotionBanner {
  id: string
  name: string
  title: string
  subtitle: string
  buttonText: string
  buttonLink?: string
  startDate: string
  endDate: string
  isVisible: boolean
  image: string
  mobileImage: string
}

export type BannerFilterInput = {
  isAvailableToday?: boolean
  isVisible?: boolean
  kind?: BannerKind[]
  startDate?: DateRangeInput
  endDate?: DateRangeInput
}

export type PromotionBannerFilter = {
  isVisible?: boolean
  startDate?: DateRangeInput
  endDate?: DateRangeInput
  search?: string
  vendor?: string
}

export interface PagginationBannersVariables extends ListVariables {
  filter?: BannerFilterInput
}

export interface PromotionBannersVariables
  extends ListVariablesWithFilter<PromotionBannerFilter> {}

export interface HomeBanners {
  allBanners: PaginationList<Banner>
}

export interface PromotionBanners {
  promotionBanners: PaginationList<PromotionBanner>
}

export interface AdvertisingBanners {
  advertisingBanners: PaginationList<PromotionBanner>
}

export type SecondaryMenu = {
  data: SecondaryMenuShop | null
  loading: boolean
}

export type MainMenu = {
  data: MainMenuShop | null
  loading: boolean
  refetch: () => void
}

export type GetHomeBanners = {
  data: HomeBanners | null
  loading: boolean
}

export type GetPromotionBanners = {
  data: PromotionBanners | null
  loading: boolean
  refetch?: (variables: Partial<PromotionBannersVariables>) => void
  fetchMore?: FetchMore<PromotionBanners, PromotionBannersVariables>
}

export type GetAdvertisingBanners = {
  data: AdvertisingBanners | null
  loading: boolean
  refetch?: (variables: Partial<PromotionBannersVariables>) => void
  fetchMore?: FetchMore<AdvertisingBanners, PromotionBannersVariables>
}

export type MenuApi = {
  useSecondaryMenu: () => SecondaryMenu
  useMainMenu: () => MainMenu
  useBanners: (variables: PagginationBannersVariables) => GetHomeBanners
  usePromotionBanners: (
    variables: PromotionBannersVariables
  ) => GetPromotionBanners
  useAdvertisingBanners: (
    variables: PromotionBannersVariables
  ) => GetAdvertisingBanners
}
