export const lightTheme = {
  colors: {
    active: '#A6E8D7',
    black: '#000000dd',
    blue: '#75B4F2',
    darkBlue: '#293A56',
    error: '#DD0000',
    green: '#219653',
    purple: '#9B51E0',
    errorBackground: '#FCE6E6',
    greyFon: '#EEEEEE',
    lightFonFilter: '#FEFEFE',
    hover: '#FF9E00',
    inactive: '#666666DE',
    lightBlue: '#DFFAF9',
    lightFon: '#FAFDFD',
    inactiveSecondary: '#666666B3',
    shadow: '#E0E0E0',
    white: '#FFFFFF',
    yellow: '#FEB53E',
    canceled: '#EB5757',
    notConfirmed: '#CACACA',
    canceledWithoutRefund: '#F256E2',
    refunded: '#6FCF97',
    partlyRefunded: '#F2994A',
    vendorProfileFon: '#F8FAFA',
    transparent: 'transparent',
    backdrop: 'rgba(102, 102, 102, 0.5)',
    bannerOverflowMobile: 'rgba(255, 255, 255, 0.7)',
    overlay: 'rgba(0, 0, 0, 0.4)'
  },
  fonts: {
    main: ['Jost', 'sans-serif'],
    defaultSize: 16
  },
  typography: {
    mainBanner: {
      fontWeight: 600,
      fontSize: 92,
      lineHeight: '123px',
      letterSpacing: '0.0002em',
      margin: 0,
      fontFamily: 'Jost'
    },
    secondBanner: {
      fontWeight: 600,
      fontSize: 64,
      lineHeight: '70px',
      margin: 0,
      fontFamily: 'Jost'
    },
    h1: {
      fontWeight: 600,
      fontSize: 48,
      lineHeight: '62px',
      letterSpacing: '0.006em',
      margin: 0,
      fontFamily: 'Jost'
    },
    h2: {
      fontWeight: 400,
      fontSize: 45,
      lineHeight: '62px',
      letterSpacing: '0.004em',
      margin: 0,
      fontFamily: 'Jost'
    },
    h3: {
      fontWeight: 400,
      fontSize: 34,
      lineHeight: '46px',
      letterSpacing: '0.003em',
      margin: 0,
      fontFamily: 'Jost'
    },
    h4: {
      fontWeight: 700,
      fontSize: 23,
      lineHeight: '31px',
      letterSpacing: '0.002em',
      margin: 0,
      fontFamily: 'Jost'
    },
    h5: {
      fontWeight: 600,
      fontSize: 18,
      lineHeight: '26px',
      letterSpacing: '0.025em',
      margin: 0,
      fontFamily: 'Jost'
    },
    h6: {
      fontWeight: 600,
      fontSize: 15,
      lineHeight: '21px',
      letterSpacing: '0.006em',
      margin: 0,
      fontFamily: 'Jost'
    },
    h7: {
      fontWeight: 500,
      fontSize: 15,
      lineHeight: '21px',
      letterSpacing: '-0.005em',
      margin: 0,
      fontFamily: 'Jost'
    },
    h1Mobile: {
      fontWeight: 600,
      fontSize: 32,
      lineHeight: '46px',
      letterSpacing: '0.006em',
      margin: 0,
      fontFamily: 'Jost'
    },
    h2Mobile: {
      fontWeight: 500,
      fontSize: 28,
      lineHeight: '39px',
      letterSpacing: '0.004em',
      margin: 0,
      fontFamily: 'Jost'
    },
    h3Mobile: {
      fontWeight: 400,
      fontSize: 24,
      lineHeight: '32px',
      letterSpacing: '0.003em',
      margin: 0,
      fontFamily: 'Jost'
    },
    title: {
      fontWeight: 400,
      fontSize: 15,
      lineHeight: '21px',
      letterSpacing: '-0.003em',
      margin: 0,
      fontFamily: 'Jost'
    },
    subscriptionText: {
      fontWeight: 500,
      fontSize: 13,
      lineHeight: '18px',
      letterSpacing: '-0.009em',
      margin: 0,
      fontFamily: 'Jost'
    },
    maxButton: {
      fontWeight: 600,
      fontSize: 12,
      lineHeight: '15px',
      letterSpacing: '0.01em',
      margin: 0,
      fontFamily: 'Jost'
    },
    cardText: {
      fontWeight: 600,
      fontSize: 11,
      lineHeight: '15px',
      letterSpacing: '0.017em',
      margin: 0,
      fontFamily: 'Jost'
    },
    body: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '24px',
      margin: 0,
      fontFamily: 'Roboto'
    },
    secondTitle: {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '14px',
      margin: 0,
      fontFamily: 'Roboto'
    },
    rightButton: {
      fontWeight: 400,
      fontSize: 10,
      lineHeight: '40px',
      margin: 0,
      fontFamily: 'Jost'
    },
    notFound: {
      fontWeight: 600,
      fontSize: 144,
      lineHeight: '184.61px'
    },
    button: {
      fontWeight: 400,
      fontSize: 8,
      lineHeight: '9px',
      fontFamily: 'Jost'
    }
  },
  spacing: {
    unit: 16
  },
  boxShadow:
    '0 1px 5px 0 rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.12)',
  link: {
    textDecoration: 'none',
    textTransform: 'capitalize',
    padding: '.25em 1em'
  },
  mediumScreen: '992px',
  largeScreen: '1438px'
}

export type Color = keyof typeof lightTheme.colors

type LightType = typeof lightTheme

export interface LightTheme extends LightType {}
