import { createUseStyles } from 'react-jss'
import { isMobileOnly } from 'react-device-detect'
import { LightTheme } from '../../theme'
import { SignInStyleProps } from './sign-in.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  loginForm: {
    padding: 16,
    width: 'calc(100% - 32px)'
  },
  input: {
    marginTop: 24
  },
  forgotPassword: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: isMobileOnly ? '100%' : 536,
    marginTop: 8
  },
  submit: {
    width: '100%',
    marginTop: 96
  },
  signUp: {
    marginLeft: 4
  },
  signUpContainer: {
    marginTop: 24
  },
  error: {
    position: 'absolute',
    width: ({ modal }: SignInStyleProps) =>
      modal ? 'calc(100% - 32px)' : '100%',
    backgroundColor: theme.colors.errorBackground,
    padding: '8px 0',
    marginTop: 94,
    borderRadius: 4
  },
  errorIcon: {
    marginLeft: 19
  },
  errorText: {
    marginLeft: 16
  }
}))
