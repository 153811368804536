import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  previewPage: {
    width: 'calc(100% - 48px)',
    padding: 24,
    maxWidth: 1720
  },
  container: {
    maxWidth: 1252,
    width: '100%',
    padding: '72px 0 24px 0'
  },
  emptyButton: {
    cursor: 'pointer',
    marginTop: 32,
    minWidth: 300,
    width: 312,
    padding: 16
  },
  text: {
    marginTop: 24
  },
  submit: {
    width: 314,
    marginTop: 24
  },
  buttonRow: {},
  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    previewPage: {
      width: 'calc(100% - 32px)',
      padding: 16,
      maxWidth: 1720
    },
    container: {
      padding: '24px 0px'
    }
  },
  '@media screen and (max-width: 480px)': {
    previewPage: {
      width: 'calc(100% - 24px)',
      padding: '16px 12px'
    },
    container: {
      padding: '24px 0px 12px'
    },
    emptyButton: {
      cursor: 'pointer',
      marginTop: 24
    },
    buttonRow: {
      width: '100%'
    },
    submit: {
      width: '100%'
    }
  }
})
