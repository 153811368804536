import { MutationResult } from '@apollo/client'

export enum PaymentChargeStatus {
  NOT_CHARGED = 'NOT_CHARGED',
  PENDING = 'PENDING',
  PARTIALLY_CHARGED = 'PARTIALLY_CHARGED',
  FULLY_CHARGED = 'FULLY_CHARGED',
  PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED',
  FULLY_REFUNDED = 'FULLY_REFUNDED',
  REFUSED = 'REFUSED',
  CANCELLED = 'CANCELLED'
}

export enum PaymentError {
  BILLING_ADDRESS_NOT_SET = 'BILLING_ADDRESS_NOT_SET',
  GRAPHQL_ERROR = 'GRAPHQL_ERROR',
  INVALID = 'INVALID',
  NOT_FOUND = 'NOT_FOUND',
  REQUIRED = 'REQUIRED',
  UNIQUE = 'UNIQUE',
  PARTIAL_PAYMENT_NOT_ALLOWED = 'PARTIAL_PAYMENT_NOT_ALLOWED',
  SHIPPING_ADDRESS_NOT_SET = 'SHIPPING_ADDRESS_NOT_SET',
  INVALID_SHIPPING_METHOD = 'INVALID_SHIPPING_METHOD',
  SHIPPING_METHOD_NOT_SET = 'SHIPPING_METHOD_NOT_SET',
  PAYMENT_ERROR = 'PAYMENT_ERROR',
  NOT_SUPPORTED_GATEWAY = 'NOT_SUPPORTED_GATEWAY'
}

export type CreatePaypalOrderVariables = {
  orderId: string
  paypalOrderId: string
}

export type CreatePaypalOrderData = {
  createPaypalOrder: {
    paymentErrors: PaymentError[]
  }
}

export type CreatePaypalOrder = {
  onSubmit: (variables: CreatePaypalOrderVariables) => void
  response: MutationResult<CreatePaypalOrderData>
}

export type PaymentApi = {
  useCreatePaypalOrder: () => CreatePaypalOrder
}
