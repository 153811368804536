import React, { FC } from 'react'
import { getPriceWithSymbol } from '../../views'

import { InfoColumn } from '../info-column'
import { ItemLine } from '../item-line'
import { Row } from '../row'

import { useStyle } from './order-confirm-item-line.styles'
import { OrderConfirmItemLineProps } from './order-confirm-item-line.types'

export const OrderConfirmItemLine: FC<OrderConfirmItemLineProps> = ({
  className,
  productName,
  currency,
  imgUrl,
  quantity,
  deliveryPrice,
  items,
  noSuggestion,
  description,
  discount,
  totalOrderPrice,
  totalOfferPrice,
  productDefaultPrice,
  isTooltip
}) => {
  const classes = useStyle()

  return (
    <ItemLine
      className={className}
      productName={productName}
      imgUrl={imgUrl}
      noSuggestion={noSuggestion}
      description={description}
      discount={discount}
      lastColumnClassName={classes.lastColumn}
      totalOfferPrice={totalOfferPrice}
      items={items}
      deliveryPrice={deliveryPrice}
      productDefaultPrice={productDefaultPrice}
      currency={currency}
      isTooltip={isTooltip}
    >
      <Row justifyContent="space-between" className={classes.infoColumns}>
        <InfoColumn
          preset="h5"
          title="Order Price:"
          titleTx="shopping.cart.order.price"
          value={getPriceWithSymbol(totalOfferPrice, currency)}
        />

        <InfoColumn
          preset="h5"
          title="Delivery Price:"
          titleTx="shopping.cart.item.delivery.price"
          value={getPriceWithSymbol(deliveryPrice, currency)}
        />

        <InfoColumn
          preset="h5"
          title="Item Quantity:"
          titleTx="shopping.cart.units.quantity"
          value={String(quantity)}
        />

        <InfoColumn
          className={classes.lastColumn}
          preset="h4"
          title="Total Price:"
          titleTx="order.confirm.total.price"
          value={getPriceWithSymbol(totalOrderPrice, currency)}
        />
      </Row>
    </ItemLine>
  )
}
