import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    cursor: 'pointer',
    padding: '4px 0px',
    '&:hover > svg': {
      fill: theme.colors.yellow
    },
    '&:hover > span': {
      color: theme.colors.yellow
    }
  },
  title: {
    marginLeft: 16,
    '@media screen and (max-width: 1223px)': {
      color: theme.colors.black
    }
  },
  mobile: {
    '@media screen and (max-width: 1223px)': {
      fill: theme.colors.black
    }
  }
}))
