import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    width: '100%',
    borderRadius: 4,
    marginTop: 16
  },
  containerSlider: {
    border: `1px solid ${theme.colors.shadow}`
  },
  mobileHeader: {},
  titleSlider: {},
  '@media screen and (max-width: 480px)': {
    container: {
      border: `1px solid ${theme.colors.shadow}`
    },
    containerSlider: {
      border: 'none'
    },
    titleSlider: {
      padding: 0
    }
  }
}))
