import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  header: {
    width: 'calc(100% - 48px)',
    padding: 24
  },

  vendor: {
    width: 'calc(100% - 48px)',
    padding: '24px 24px 80px 24px'
  },
  vendorImage: {
    width: 120,
    height: 120
  },
  countryName: {
    marginLeft: 16,
    '& > *:not(:first-child)': {
      marginTop: 12
    }
  },
  flag: {
    width: 48
  },
  secondaryText: {
    marginLeft: 8
  },

  separator: {
    height: 1,
    marginTop: 24,
    backgroundColor: theme.colors.shadow
  },
  buttonsRow: {
    marginTop: 54,
    width: 'calc(100% - 72px)',
    padding: '0 36px'
  },
  btnContact: {},
  btnFeedback: {
    padding: '14px 24px',
    border: `2px solid ${theme.colors.yellow}`,
    marginLeft: 16
  },
  '@media screen and (max-width: 480px)': {
    header: {
      width: '100%',
      padding: 12
    },
    vendor: {
      width: '100%',
      padding: '24px 0 92px'
    },
    vendorImage: {
      width: 64,
      height: 64
    },
    countryName: {
      marginLeft: 16,
      '& > *:not(:first-child)': {
        marginTop: 12
      }
    },
    flag: {
      width: 32,
      height: 16
    },
    secondaryText: {
      marginLeft: 8
    },

    separator: {
      height: 1,
      marginTop: 24,
      backgroundColor: theme.colors.shadow
    },

    vendorInfo: {
      flex: 1,
      marginTop: 16,

      '&:not(:first-child)': {
        marginTop: 20
      }
    },
    vendorStatuses: {
      '& > *': {
        whiteSpace: 'nowrap'
      },
      '& > *:not(:first-child)': {
        marginLeft: 8
      },
      '&:not(:first-child)': {
        marginTop: 20
      }
    },
    vendorActions: {
      marginTop: 24
    },
    buttonsRow: {
      flexDirection: 'column',
      width: '100%',
      padding: 0
    },
    btnContact: {
      width: '100%'
    },
    btnFeedback: {
      width: '100%',
      padding: '16px 12px',
      marginTop: 16,
      marginLeft: 0,
      border: `2px solid ${theme.colors.yellow}`
    }
  }
}))
