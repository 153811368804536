import { useQuery } from '@apollo/client'

import {
  GetHomeCollections,
  GetHomeCollectionsRequest,
  CollectionsApi,
  HomeCollectionVariables,
  CollectionByIdVariables,
  GetCollectionById,
  GetCollectionByIdRequest
} from './collection.types'

import {
  COLLECTION_BY_ID_QUERY,
  HOME_COLLECTIONS_QUERY
} from './collection.graphql'

// our "constructor"
export const collectionService = (): CollectionsApi => {
  const useHomeCollections = ({
    first = 8
  }: HomeCollectionVariables): GetHomeCollectionsRequest => {
    const variables: HomeCollectionVariables = { first }
    const { data, loading, error } = useQuery<
      GetHomeCollections,
      HomeCollectionVariables
    >(HOME_COLLECTIONS_QUERY, { variables })

    if (error) {
      return { data: null, loading }
    }

    if (!data) {
      return { data: null, loading }
    }

    return { data, loading }
  }

  const useCollectionById = (
    variables: CollectionByIdVariables
  ): GetCollectionByIdRequest => {
    const { data, loading, error, refetch, fetchMore } = useQuery<
      GetCollectionById,
      CollectionByIdVariables
    >(COLLECTION_BY_ID_QUERY, { variables })

    if (error) {
      return { data: null, loading, refetch, fetchMore }
    }

    if (!data) {
      return { data: null, loading, refetch, fetchMore }
    }

    return { data, loading, refetch, fetchMore }
  }

  return {
    useHomeCollections,
    useCollectionById
  }
}
