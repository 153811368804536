import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  item: {
    '&:not(:first-child)': {
      paddingTop: 24,
      borderTop: `1px solid ${theme.colors.shadow}`
    },
    '&:not(:last-child)': {
      paddingBottom: 24
    }
  }
}))
