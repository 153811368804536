import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'
import { MakerDesignerCardStyle } from './maker-designer-card.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    background: theme.colors.white,
    boxShadow: `4px 4px 16px ${theme.colors.shadow}`,
    '&:hover': {
      background: theme.colors.white,
      boxShadow: `4px 4px 16px ${theme.colors.shadow}`
    },
    transition: 'box-shadow 0.25s ease-in-out'
  },
  imgContainer: {
    width: 274
  },
  imgWrapper: {
    filter: 'grayscale(100%)',
    '&:hover': {
      filter: 'none'
    }
  },
  img: {
    objectFit: 'cover',
    height: 180,
    width: 180,
    backgroundColor: theme.colors.active
  },

  placeholderImg: {
    backgroundColor: ({ background }: MakerDesignerCardStyle) => background
  },
  details: {
    height: 52,
    padding: '24px 8px',
    width: 'calc(100% - 16px)'
  },
  '@media screen and (max-width: 1223px)': {
    imgWrapper: {
      filter: 'none'
    }
  }
}))
