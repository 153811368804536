import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  row: {
    padding: '12px 16px',
    width: 'calc(100% - 32px)',
    '& > *:not(:first-child):not(:last-child)': {
      marginLeft: 8
    }
  },
  border: {
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.colors.shadow}`
    }
  },
  img: {
    width: 64,
    height: 64,
    objectFit: 'cover'
  },
  name: {
    display: 'flex',
    flex: 3
  },
  id: {
    display: 'flex',
    flex: 2
  },

  date: {
    display: 'flex',
    flex: 2
  },
  btnContainer: {
    marginLeft: 8,

    width: 216
  },
  '@media screen and (max-width: 480px)': {
    btnContainer: {
      marginLeft: 0,
      marginTop: 18,
      width: '100%'
    }
  },
  '@media screen and (min-width: 481px) and (max-width: 1024px)': {
    id: {
      flex: 1.2
    }
  }
}))
