import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

import { ProductInfoBottomBarStyleProps } from './product-info-botttom-bar.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    width: '100%',
    padding: '22px 16px',
    background: theme.colors.white,
    boxShadow: '-2px -4px 8px rgba(187, 187, 187, 0.16)',
    backdropFilter: 'blur(22px)',
    zIndex: 2,
    position: 'relative'
  },
  button: {
    marginLeft: 24,
    minWidth: 156
  },
  price: {
    minWidth: 156,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  '@media screen and (max-width: 480px)': {
    container: ({ absolute }: ProductInfoBottomBarStyleProps) =>
      absolute
        ? {
            width: 'calc(100% - 32px)',
            position: 'absolute'
          }
        : {
            width: 'calc(100vw - 32px)',
            position: 'fixed'
          },
    button: {
      marginLeft: 0,
      marginTop: 12,
      width: '100%'
    }
  }
}))
