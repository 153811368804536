import React, { FC } from 'react'
import { getPriceWithSymbol } from '../../views'

import { InfoColumn } from '../info-column'
import { ItemLine } from '../item-line'
import { Row } from '../row'

import { useStyle } from './shopping-item-line.styles'
import { ShoppingItemLineProps } from './shopping-item-line.types'

export const ShoppingItemLine: FC<ShoppingItemLineProps> = ({
  className,
  productName,
  imgUrl,
  quantity,
  days,
  items,
  currency,
  noSuggestion,
  description,
  discount,
  productDefaultPrice,
  totalOfferPrice
}) => {
  const classes = useStyle()

  return (
    <ItemLine
      className={className}
      productName={productName}
      imgUrl={imgUrl}
      noSuggestion={noSuggestion}
      description={description}
      discount={discount}
      totalOfferPrice={totalOfferPrice}
      productDefaultPrice={productDefaultPrice}
      currency={currency}
      items={items}
    >
      <Row justifyContent="space-between" className={classes.infoColumns}>
        <InfoColumn
          title="Production Days:"
          titleTx="shopping.cart.production.days"
          value={String(days)}
        />

        <InfoColumn
          title="Item Quantity:"
          titleTx="shopping.cart.units.quantity"
          value={String(quantity)}
        />

        <InfoColumn
          title="Offer Price:"
          titleTx="offer.line.offer.price"
          value={getPriceWithSymbol(totalOfferPrice, currency)}
        />
      </Row>
    </ItemLine>
  )
}
