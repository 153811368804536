import React, { FC, useRef } from 'react'

import { isMobile, isMobileOnly } from 'react-device-detect'
import { Link, useHistory } from 'react-router-dom'
import { useSwipeable } from 'react-swipeable'
import InfiniteScroll from 'react-infinite-scroll-component'

import { Loader, Row } from '../../../components'
import { MainRoutes } from '../../../routes'

import { NewsCard } from '../news-card'

import { useStyle } from './news-list.styles'
import { NewsListViewProps } from './news-list.types'

export const NewsList: FC<NewsListViewProps> = ({
  className = '',
  count,
  data,
  totalCount,
  isWrap,
  onLoadNext,
  onRefresh
}) => {
  const classes = useStyle()
  const history = useHistory()
  const isPage = history.location.pathname === '/latest-news'

  const scrollContainer = useRef<HTMLDivElement>(null)

  const handleOnClickLeft = () => {
    const scrollElement = scrollContainer.current
    if (scrollElement) {
      const scrollWidth = scrollElement.offsetWidth / 1.5
      const leftPosition = scrollElement.scrollLeft - scrollWidth
      scrollElement.scroll({ left: leftPosition, behavior: 'smooth' })
    }
  }

  const handleOnClickRight = () => {
    const scrollElement = scrollContainer.current
    if (scrollElement) {
      const scrollWidth = scrollElement.offsetWidth / 1.5
      const leftPosition = scrollElement.scrollLeft + scrollWidth
      scrollElement.scroll({ left: leftPosition, behavior: 'smooth' })
    }
  }

  const swipeableConfig = {
    delta: 20,
    preventDefaultTouchmoveEvent: false,
    trackTouch: isMobileOnly && !isPage,
    trackMouse: true,
    rotationAngle: 0
  }

  const handlers = useSwipeable({
    onSwipedLeft: handleOnClickRight,
    onSwipedRight: handleOnClickLeft,
    ...swipeableConfig
  })
  const loader = (
    <Row fullWidth>
      <Loader />
    </Row>
  )

  const dataList = (
    <Row fullWidth {...handlers}>
      <Row wrap={isWrap} className={className} ref={scrollContainer}>
        {data.map(({ id, createdAt, title, text, image }) => {
          const nextNewsPage = `${MainRoutes.LATEST_NEWS}/${id}`

          return (
            <Link
              key={`news_${id}`}
              className={classes.newsItem}
              to={nextNewsPage}
            >
              <NewsCard
                id={id}
                createdAt={createdAt}
                title={title}
                image={image}
                text={text}
              />
            </Link>
          )
        })}
      </Row>
    </Row>
  )

  if (!onLoadNext || !count || !totalCount) {
    return dataList
  }

  return (
    <>
      {isMobile && isPage ? (
        <InfiniteScroll
          pullDownToRefresh
          dataLength={data.length}
          next={onLoadNext}
          hasMore={totalCount > count}
          loader={loader}
          refreshFunction={onRefresh}
          pullDownToRefreshThreshold={100}
        >
          {dataList}
        </InfiniteScroll>
      ) : (
        dataList
      )}
    </>
  )
}
