import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../theme'

import { NavLinkStyle } from './nav-link.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  decoration: {
    display: 'flex',
    textDecoration: ({ decoration }: NavLinkStyle) =>
      decoration ? 'underline' : 'none'
  },
  link: ({ to, color }: NavLinkStyle) => ({
    cursor: to ? 'pointer' : 'text',
    color,
    '&:hover': {
      color: to ? theme.colors.hover : color
    }
  })
}))
