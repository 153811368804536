import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import { MakerDesignerCard } from '../../pages'

import { ItemsLine } from '../items-line'

import { useStyle } from './vendors-line.styles'
import { VendorsLineProps } from './vendors-line.types'

export const VendorsLine: FC<VendorsLineProps> = ({
  carousel,
  className = '',
  data,
  title,
  titleTx,
  titlePreset,
  onLoadNext
}) => {
  const classes = useStyle()
  return (
    <ItemsLine
      carousel={carousel}
      className={className}
      itemsLength={data.length}
      title={title}
      titleTx={titleTx}
      titlePreset={titlePreset}
      onLoadNext={onLoadNext}
    >
      {data.map(({ id, avatar, companyName }) => (
        <Link
          key={`categoria_${id}`}
          className={classes.link}
          to={`/vendor/${id}`}
        >
          <MakerDesignerCard id={id} avatar={avatar?.url} name={companyName} />
        </Link>
      ))}
    </ItemsLine>
  )
}
