import React, { FC } from 'react'
import { isMobileOnly } from 'react-device-detect'

import { BannerButtonType } from '../../../services'

import { Button, ButtonPresets } from '../../button'
import { Column } from '../../column'
import { Text, TextPresets } from '../../text'
import { ResponsiveMediaView } from '../../responsive-media-view'

import { BannerCarouselItemProps } from './banner-carousel-item.types'
import { useStyle } from './banner-carousel-item.styles'

export const BannerCarouselItem: FC<BannerCarouselItemProps> = ({
  banner,
  className = '',
  imageClassName = ''
}) => {
  const isBigButton = banner.buttonType === BannerButtonType.BIG_BUTTON
  const classes = useStyle({ isBigButton })

  const buttonPreset: ButtonPresets = isBigButton ? 'primary' : 'button2'
  const buttonTextPreset: TextPresets = isBigButton ? 'h5' : 'maxButton'
  const textPreset = isMobileOnly ? 'h1' : 'mainBanner'

  const Banner = (
    <img
      className={imageClassName}
      height="60vh"
      width="100%"
      src={banner.image}
    />
  )

  const BannerMobile = (
    <img
      className={imageClassName}
      height="75vh"
      width="100%"
      src={banner.mobileImage}
    />
  )
  return (
    <Column
      fullWidth
      fullHeight
      className={`${className} ${classes.container}`}
    >
      <ResponsiveMediaView
        bigScreen={Banner}
        medium={Banner}
        tablet={Banner}
        mobile={BannerMobile}
      />
      <Column className={classes.bannerOverflow}>
        <Text
          className={classes.text}
          color="blue"
          preset="h3"
          text={banner.title || ''}
        />
        <Text
          className={classes.text}
          color="black"
          preset={textPreset}
          text={banner.mainHeading || ''}
        />
        {banner.isButtonActive && banner.buttonLink && banner.buttonText && (
          <a className={classes.anchor} href={banner.buttonLink}>
            <Button
              className={classes.button}
              text={banner.buttonText || ''}
              preset={buttonPreset}
              textColor="white"
              textPreset={buttonTextPreset}
            />
          </a>
        )}
      </Column>
    </Column>
  )
}
