import React, { FC, useMemo } from 'react'

import { MenuSlider } from '../menu-slider'

import { ConstructorSliderProps } from './constructor-slider.types'
import { СonstructorSliderHeader } from './constructor-slider-header'
import { ConstructorRadioBox } from '../constructor-radio-box'
import { ProductOptionType } from '../../services'
import { ConstructorCheckboxBox } from '../constructor-checkbox-box'

export const ConstructorSlider: FC<ConstructorSliderProps> = ({
  className = '',
  title = '',
  data,
  type,
  tx,
  onChangeCheckbox,
  onChangeRadio
}) => {
  const header = <СonstructorSliderHeader optionName={title} tx={tx} />

  const Active = useMemo(() => {
    const activeItem = data.find((item) => item.isDefault)
    if (activeItem) {
      return activeItem.id
    }
    return undefined
  }, [data])

  return (
    <MenuSlider className={className} header={header}>
      {type === ProductOptionType.CHECKBOX ? (
        <ConstructorCheckboxBox
          active={Active}
          data={data}
          onClick={onChangeCheckbox}
          type={type}
        />
      ) : (
        <ConstructorRadioBox
          active={Active}
          data={data}
          onChange={onChangeRadio}
        />
      )}
    </MenuSlider>
  )
}
