import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  backContainer: {
    cursor: 'pointer'
  },
  backTitle: {
    marginLeft: 8
  },

  '@media screen and (max-width: 480px)': {
    backContainer: {
      marginTop: 0
    }
  }
})
