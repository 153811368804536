import React from 'react'

import { Content, Text, Row, Column, Loader, NavLink } from '../../components'
import { useApi } from '../../providers'
import { MainRoutes } from '../../routes'

import { useStyle } from './catalogue.styles'

export const CataloguePage = () => {
  const {
    category: { useCatalogues }
  } = useApi()
  const classes = useStyle()
  const { data, loading } = useCatalogues()

  if (!data && loading) {
    return <Loader />
  }

  return (
    <Content className={classes.cataloguePage} justifyContent="flex-start">
      <Row>
        <Text text="Catalogue" tx="catalogue.title" preset="h1" color="black" />
      </Row>
      {data &&
        data.categories.edges.map((category, index) => (
          <Row className={classes.categoryWrapper} key={category.node.id}>
            {index % 2 !== 0 && (
              <Column className={classes.imageBlock}>
                <img
                  className={classes.img}
                  src={category.node.backgroundImage.url}
                  alt={category.node.backgroundImage.alt}
                />
              </Column>
            )}
            <Column className={classes.menuBlock}>
              <Row className={classes.header}>
                <NavLink
                  preset="h3"
                  text={category.node.name}
                  color="black"
                  to={`${MainRoutes.CATEGORY}/${category.node.id}`}
                />
              </Row>
              <Row className={classes.listWrapper}>
                {category.node.children.edges.map((subcategory) => (
                  <Column
                    className={classes.listGroup}
                    key={subcategory.node.id}
                  >
                    <NavLink
                      preset="h5"
                      color="black"
                      text={subcategory.node.name}
                      to={`${MainRoutes.CATEGORY}/${subcategory.node.id}`}
                    />
                    {subcategory.node.children.edges.map((listGroup) => (
                      <Row className={classes.listItem} key={listGroup.node.id}>
                        <NavLink
                          className={classes.link}
                          preset="body"
                          color="black"
                          text={listGroup.node.name}
                          to={`${MainRoutes.CATEGORY}/${listGroup.node.id}/products`}
                        />
                      </Row>
                    ))}
                  </Column>
                ))}
              </Row>
            </Column>
            {index % 2 === 0 && (
              <Column className={classes.imageBlock}>
                <img
                  className={classes.img}
                  src={category.node.backgroundImage.url}
                  alt={category.node.backgroundImage.alt}
                />
              </Column>
            )}
          </Row>
        ))}
    </Content>
  )
}
