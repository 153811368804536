import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  img: {
    width: 64,
    height: 64,
    objectFit: 'cover'
  },
  name: {
    marginLeft: 8
  },
  idRow: {
    marginTop: 16
  },
  dateRow: {
    marginTop: 8
  }
})
