import React, { FC, useRef, useState } from 'react'
import { useSwipeable } from 'react-swipeable'
import { isMobile } from 'react-device-detect'
import { useHistory } from 'react-router-dom'

import { MainMenuItem } from '../../services'

import { Row } from '../row'
import { Column } from '../column'
import { Text } from '../text'

import { ProductsCategoryFilterProps } from './products-category-filter.types'
import { useStyle } from './products-category-filter.styles'

export const ProductsCategoryFilter: FC<ProductsCategoryFilterProps> = ({
  data,
  defaultValue = '',
  onChange
}) => {
  const history = useHistory()
  const isHomePage: boolean = history.location.pathname === '/'

  const classes = useStyle({ background: !isHomePage })

  const scrollContainer = useRef<HTMLDivElement>(null)
  const [active, changeActive] = useState(defaultValue)

  const titleClassName = (item: MainMenuItem) =>
    item.category.id === active ? classes.selected : classes.notSelected

  const handleOnClickLeft = () => {
    const scrollElement = scrollContainer.current
    if (scrollElement) {
      const scrollWidth = scrollElement.offsetWidth / 1.5
      const leftPosition = scrollElement.scrollLeft - scrollWidth
      scrollElement.scroll({ left: leftPosition, behavior: 'smooth' })
    }
  }

  const handleOnClickRight = () => {
    const scrollElement = scrollContainer.current
    if (scrollElement) {
      const scrollWidth = scrollElement.offsetWidth / 1.5
      const leftPosition = scrollElement.scrollLeft + scrollWidth

      scrollElement.scroll({ left: leftPosition, behavior: 'smooth' })
    }
  }

  const swipeableConfig = {
    delta: 20,
    preventDefaultTouchmoveEvent: false,
    trackTouch: isMobile,
    trackMouse: true,
    rotationAngle: 0
  }

  const handlers = useSwipeable({
    onSwipedLeft: handleOnClickRight,
    onSwipedRight: handleOnClickLeft,
    ...swipeableConfig
  })

  const handleOnCategoryChange = (id: string) => () => {
    const current = active === id

    if (current) {
      if (onChange) {
        onChange('')
      }
      changeActive('')
    } else {
      changeActive(id)
      if (onChange) {
        onChange(id)
      }
    }
  }

  return (
    <Column fullWidth {...handlers}>
      <Row className={classes.filterCategory} ref={scrollContainer}>
        {data.map((item) => (
          <Text
            key={`${item.name}_${item.category.id}`}
            className={titleClassName(item)}
            color="black"
            preset="h5"
            text={item.name}
            onClick={handleOnCategoryChange(item.category.id)}
          />
        ))}
      </Row>
    </Column>
  )
}
