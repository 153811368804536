import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

import { SliderStyleProps } from './slider.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    backgroundColor: ({ active }: SliderStyleProps) =>
      active ? theme.colors.yellow : theme.colors.inactive,
    borderRadius: 24,
    cursor: 'pointer',
    height: 24,
    minWidth: 44
  },
  dot: {
    backgroundColor: theme.colors.white,
    borderRadius: 16,
    height: 16,
    left: ({ active }: SliderStyleProps) => (active ? 24 : 4),
    position: 'absolute',
    top: 4,
    transition: 'left 0.25s ease-in-out',
    width: 16
  }
}))
