import { gql } from '@apollo/client'

import { PAYMENT_ERROR_FRAGMENT } from '../fragments'

export const CREATE_PAYPAL_ORDER_MUTATION = gql`
  ${PAYMENT_ERROR_FRAGMENT}
  mutation CreatePaypalOrder($orderId: ID!, $paypalOrderId: String!) {
    createPaypalOrder(orderId: $orderId, paypalOrderId: $paypalOrderId) {
      paymentErrors {
        ...PaymentErrorFragment
      }
    }
  }
`
