import React, { FC } from 'react'
import { isMobile } from 'react-device-detect'

import { ColorPicker } from '../color-picker'
import { FilterTypeContainer } from '../filter-type-container'
import { MenuSlider } from '../menu-slider'
import { Text } from '../text'

import { useStyle } from './filter-list-color.styles'
import { FilterListColorProps } from './filter-list-color.types'

export const FilterListColor: FC<FilterListColorProps> = ({
  className = '',
  title,
  titleTx,
  ...colorPickerProps
}) => {
  const classes = useStyle()

  const titlePreset = isMobile ? 'h5' : 'h4'
  const titleComponent = <Text text={title} tx={titleTx} preset={titlePreset} />

  if (colorPickerProps.colors.length > 9) {
    return (
      <MenuSlider
        className={`${className} ${classes.slider}`}
        contentClassName={classes.sliderContent}
        titleClassName={classes.sliderTitle}
        header={titleComponent}
      >
        <ColorPicker {...colorPickerProps} />
      </MenuSlider>
    )
  }

  return (
    <FilterTypeContainer className={className} title={title} titleTx={titleTx}>
      <ColorPicker {...colorPickerProps} />
    </FilterTypeContainer>
  )
}
