import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../theme'

import { SearchStyle } from './searchs.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    position: 'fixed',
    height: '100vh',
    top: 0,
    right: 0,
    zIndex: 11,
    boxShadow: ' 0px 4px 8px rgba(0, 0, 0, 0.12)',
    background: theme.colors.white
  },
  inactiveContainer: {
    background: theme.colors.transparent,
    boxShadow: 'none'
  },
  resultsContainer: {
    overflowY: 'auto',
    background: theme.colors.white
  },
  row: {
    backgroundColor: theme.colors.lightBlue,
    padding: ({ mobile }: SearchStyle) => (mobile ? '12px 0' : '16px 0'),
    width: ({ mobile }: SearchStyle) => (mobile ? '100vw' : '466px')
  },
  text: {
    backgroundColor: 'transparent',
    '& > div': {
      paddingTop: 0
    }
  },
  icon: {
    padding: '0 0 0 24px'
  },
  close: {
    padding: '0 24px 0 0'
  },
  primary: {
    background: theme.colors.white,
    marginBottom: 4,
    borderTop: `1px solid ${theme.colors.shadow}`,
    padding: '16px 16px 12px 16px',
    width: ({ mobile }: SearchStyle) => (mobile ? 'calc(100% - 32px)' : '434px')
  },
  item: {
    marginTop: 16,
    width: ({ mobile }: SearchStyle) => mobile && '100%'
  }
}))
