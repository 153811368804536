export enum MainRoutes {
  SHOPPING_CART = '/shopping-cart',
  CONTACT_US = '/contact-us',
  ORDER_PREVIEW = '/order-preview',
  ORDER_PREVIEW_OFFER = '/order-preview-offer',
  DELIVERY_INFO = '/delivery-info',
  DELIVERY_INFO_OFFER = '/delivery-info-offer',
  LATEST_NEWS = '/latest-news',
  ORDER_CONFIRM = '/order-confirm',
  ORDER_CONFIRM_OFFER = '/order-confirm-offer',
  SUBSCRIPTION = '/subscription',
  FEEDBACK = '/feedback/:id',
  VENDOR = '/vendor',
  VENDOR_FEEDBACK = '/vendor-feedback/:id',
  PROCEED_PAYMENT = '/proceed-payment',
  SELECT = '/select-items/:id',
  BECOME_PARTNER = '/become-partner',
  CATEGORY = '/category'
}

export enum AuthRoutes {
  SIGN_IN = '/sign-in',
  SIGN_UP = '/sign-up',
  FORGOT_PASSWORD = '/forgot-password',
  CHANGE_PASSWORD = '/change-password',
  SUCCESS_PASSWORD_RESET = '/success-password-reset'
}
