import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  itemText: {
    flex: 2
  },
  total: {
    flex: 1
  }
})
