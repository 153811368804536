import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    width: '100%',
    padding: 16,
    borderBottom: `1px solid ${theme.colors.shadow}`,
    '&:last-child': {
      borderBottom: 'none'
    }
  },
  img: {
    width: 64,
    height: 64,
    objectFit: 'cover'
  },
  nameText: {
    marginLeft: 8,
    width: '100%'
  },
  observeOptionsBtn: {
    marginTop: 6,
    padding: '8px 6px'
  },

  itemText: {
    width: '100%',
    marginTop: 16
  },
  quantity: {
    width: 148
  }
}))
