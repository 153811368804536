import React, { FC } from 'react'

import { arrayHasElements, Column } from '../../../components'

import { ProductPreviewDetailsBlockSubitem } from '../product-preview-details-block-subitem'
import { ProductPreviewDetailsBlockOption } from '../product-preview-details-block-option'

import { ProductPreviewOptionsProps } from './product-preview-options.types'
import { useStyle } from './product-preview-options.styles'

export const ProductPreviewOptions: FC<ProductPreviewOptionsProps> = ({
  className = '',
  id,
  colors,
  materials,
  options,
  currency
}) => {
  const classes = useStyle()

  return (
    <Column fullWidth className={className} alignItems="flex-start">
      <ProductPreviewDetailsBlockSubitem
        text="Material:"
        tx="product.material.options"
      >
        {materials &&
          materials.map((material) => (
            <ProductPreviewDetailsBlockOption
              isOptionSection
              key={`product_${id}_material_${material.id}`}
              className={classes.optionsRow}
              text={material.title || '-'}
              extraPrice={material.extraPrice}
              currency={currency}
            />
          ))}
      </ProductPreviewDetailsBlockSubitem>
      <ProductPreviewDetailsBlockSubitem
        className={classes.detailsColumn}
        text="Color:"
        tx="product.color.options"
      >
        {colors &&
          colors.map((color) => (
            <ProductPreviewDetailsBlockOption
              isOptionSection
              key={`product_${id}_color_${color.id}`}
              className={classes.optionsRow}
              color={color.color.code}
              text={color.color.name}
              extraPrice={color.extraPrice}
              currency={currency}
            />
          ))}
      </ProductPreviewDetailsBlockSubitem>
      {options &&
        options.map((option) => {
          const isVariants = arrayHasElements(option.variants)

          if (!isVariants) {
            return null
          }

          return (
            <ProductPreviewDetailsBlockSubitem
              key={`product_${id}_option_${option.id}_${option.title}`}
              className={classes.detailsColumn}
              text={option.title}
            >
              {option.variants.map((variant) => (
                <ProductPreviewDetailsBlockOption
                  isOptionSection
                  key={`option_${option.id}_variant_${variant.id}`}
                  className={classes.optionsRow}
                  text={variant.title || '-'}
                  extraPrice={variant.extraPrice}
                  currency={currency}
                />
              ))}
            </ProductPreviewDetailsBlockSubitem>
          )
        })}
    </Column>
  )
}
