import { createUseStyles } from 'react-jss'

import { VendorItemListStyle } from './vendor-item-list.types'

export const useStyle = createUseStyles({
  container: {
    padding: 8
  },
  icon: {
    marginLeft: 8
  },
  text: {
    marginLeft: 8
  },
  img: {
    width: 48,
    height: 48,
    borderRadius: '50%',
    objectFit: 'cover'
  },
  placeholderImg: {
    backgroundColor: ({ background }: VendorItemListStyle) => background
  },
  desctiptionContainer: {
    marginTop: 8
  },
  desctiption: {
    display: 'inline',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginLeft: 4
  }
})
