import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  filterCategory: {
    display: 'flex',
    columnGap: 40,
    backgroundColor: theme.colors.greyFon,
    width: '100%',
    padding: '0px 16px',
    maxWidth: 'unset',
    marginTop: 40,
    overflowX: 'hidden',
    borderTop: `1px solid ${theme.colors.shadow}`,
    borderBottom: `1px solid ${theme.colors.shadow}`,
    '& span': {
      boxSizing: 'border-box',
      cursor: 'pointer'
    }
  },
  text: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    height: 56
  },
  selected: {
    padding: '8px 4px',
    borderBottom: `2px solid ${theme.colors.yellow}`,
    color: theme.colors.black
  },
  notSelected: {
    padding: '6px 4px',
    color: theme.colors.inactive,
    opacity: 0.87,
    borderBottom: '2px solid transparent'
  },
  charactContent: {
    width: '100%',
    padding: '24px 0'
  },
  '@media screen and (max-width: 480px)': {
    filterCategory: {
      padding: '0px 12px',
      columnGap: 12
    }
  }
}))
