import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  innerContainer: {},
  socialIcon: {
    '&:not(:first-child)': {
      marginLeft: 8
    }
  },
  category: {},
  social: {
    marginTop: 24
  },
  subscribeBtn: {
    height: 48,
    marginTop: 12,
    borderBottom: `2px solid ${theme.colors.black}`
  },
  paymentBlock: {
    marginTop: 24
  },
  brand: {
    marginLeft: 4
  },
  paymentImgChat: {
    marginLeft: 84
  }
}))
