import { isMobile } from 'react-device-detect'
import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {
    width: '50%'
  },
  icon: {
    marginLeft: 8
  },
  text: {
    marginLeft: 8
  },
  description: {},
  title: {
    marginLeft: !isMobile && 24
  },
  descriptionContainer: {
    flex: !isMobile && 1,
    '& > span': {
      marginLeft: isMobile && 20
    }
  },
  color: {
    marginLeft: 24
  }
})
