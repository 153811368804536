import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'

import {
  Button,
  Column,
  Dropdown,
  DropdownItemData,
  Loader,
  Row,
  Text,
  TextField,
  TextFieldErrorProps
} from '../../components'
import { useApi, useAuth, useBasket } from '../../providers'
import { MainRoutes } from '../../routes'
import { useValidateDeliveryInfo } from '../../hooks'
import { Address, AddressInput, CountryCode } from '../../services'

import {
  getDefaultStateShippingAddress,
  ValidateUserPhone,
  ValidateUserZipCode
} from '../helper'

import { DEFAULT_USER_STATE } from './defaultUserState'

import { useStyle } from './delivery-info-order-view.styles'

export const DeliveryInfoOrderView = () => {
  const classes = useStyle()
  const history = useHistory()

  const {
    user: defaultUser,
    onAccountAddressCreate,
    onAccountAddressEdit
  } = useAuth()

  const { basket, onCreateBulkDraftOrder } = useBasket()

  const { country } = useApi()
  const [user, changeUser] = useState(defaultUser || DEFAULT_USER_STATE)

  const { data: countriesData } = country.useCountries()
  const { data: countryStatesData, refetch: refetchCountryStates } =
    country.useCountryStatesById({
      filter: {
        country: ''
      }
    })

  const UserCountryCode = useMemo(() => {
    if (user.defaultShippingAddress?.country) {
      const countryCode = user.defaultShippingAddress.country.code
      return countryCode
    }

    return undefined
  }, [user.defaultShippingAddress?.country])

  const isDisabled = useValidateDeliveryInfo(user)

  const Countries = useMemo(() => {
    if (countriesData) {
      return countriesData.countries.edges.map((countryItem) => ({
        ...countryItem.node,
        value: countryItem.node.code
      }))
    }

    return []
  }, [countriesData])

  const UserCountry = useMemo(() => {
    return Countries.find((countryItem) => countryItem.code === UserCountryCode)
  }, [Countries, UserCountryCode])

  useEffect(() => {
    if (UserCountry && UserCountry.id) {
      refetchCountryStates({ filter: { country: UserCountry.id } })
    }
  }, [UserCountry])

  const CountryStates = useMemo(() => {
    if (countryStatesData) {
      return countryStatesData.countryStates.edges.map((countryStateItem) => ({
        ...countryStateItem.node,
        value: countryStateItem.node.id
      }))
    }

    return []
  }, [countryStatesData?.countryStates])

  const UserCountryState = useMemo(() => {
    return CountryStates.find(
      (countryStateItem) =>
        countryStateItem.name === user.defaultShippingAddress?.countryArea
    )
  }, [CountryStates, user.defaultShippingAddress?.countryArea])

  const handleOnChangeCountry = (value: DropdownItemData) => {
    changeUser({
      ...user,
      defaultShippingAddress: {
        ...getDefaultStateShippingAddress,
        ...user.defaultShippingAddress,
        country: {
          country: value.name,
          code: value.value as CountryCode
        },
        countryArea: ''
      }
    })
  }

  const handleOnChangeState = (value: DropdownItemData) => {
    changeUser({
      ...user,
      defaultShippingAddress: {
        ...getDefaultStateShippingAddress,
        ...user.defaultShippingAddress,
        countryArea: String(value.name)
      }
    })
  }

  const handleOnChange =
    (prop: keyof Address) => (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target

      changeUser({
        ...user,
        defaultShippingAddress: {
          ...getDefaultStateShippingAddress,
          ...user.defaultShippingAddress,
          [prop]: value
        }
      })
    }

  const handleOnSubmit = () => {
    const { defaultShippingAddress: address } = user
    if (address) {
      const { id, country: nextCountry } = address
      const nextAddress = {
        ...address,
        country: nextCountry?.code,
        isDefaultShippingAddress: undefined,
        isDefaultBillingAddress: undefined,
        __typename: undefined,
        id: undefined
      } as AddressInput

      if (basket && onCreateBulkDraftOrder) {
        onCreateBulkDraftOrder(nextAddress)
      }
      if (!address) {
        if (onAccountAddressCreate) {
          onAccountAddressCreate(nextAddress)
        }
      } else if (onAccountAddressEdit) {
        onAccountAddressEdit(id, nextAddress)
      }
      history.push(MainRoutes.ORDER_CONFIRM)
    }
  }

  const CountriesIsLoaded = Boolean(Countries.length)
  const CountryStatesIsLoaded = Boolean(CountryStates.length)

  const postalCodeError = useMemo<TextFieldErrorProps>(() => {
    const postalCode = user.defaultShippingAddress?.postalCode
    if (!ValidateUserZipCode(user) && postalCode) {
      return {
        error: 'This code is unknown. Please enter a valid zip code.',
        errorTx: 'error.postal.code'
      }
    }

    return {}
  }, [user.defaultShippingAddress?.postalCode])

  const phoneError = useMemo<TextFieldErrorProps>(() => {
    const phone = user.defaultShippingAddress?.phone
    if (!ValidateUserPhone(user) && phone) {
      return {
        error: 'Please enter a valid phone number.',
        errorTx: 'error.phone.number'
      }
    }

    return {}
  }, [user.defaultShippingAddress?.phone])

  if (!user) {
    return (
      <Column fullWidth fullHeight className={classes.container}>
        <Loader />
      </Column>
    )
  }

  return (
    <>
      <Column className={classes.inputsContainer} alignItems="flex-start">
        {!isMobileOnly && (
          <Text text="Delivery Info" tx="delivery.info" preset="h3" />
        )}

        <Row
          className={classes.infoRow}
          justifyContent="space-between"
          alignItems="flex-start"
          wrap
          fullWidth
        >
          {CountriesIsLoaded && (
            <Row className={classes.dropdawnRow}>
              <Dropdown
                className={classes.dropdown}
                defaultValue={UserCountry}
                label="Country or Region"
                labelTx="delivery.info.country"
                data={Countries}
                preset="body"
                onChange={handleOnChangeCountry}
              />
            </Row>
          )}
          <TextField
            disabled
            className={classes.input}
            label="Country Code"
            labelTx="delivery.info.country.code"
            value={user.defaultShippingAddress?.country?.code}
            preset="border"
            type="text"
          />
          {CountryStatesIsLoaded && (
            <Row className={classes.dropdawnRow}>
              <Dropdown
                className={classes.dropdown}
                defaultValue={UserCountryState}
                label="State"
                labelTx="delivery.info.state"
                data={CountryStates}
                preset="body"
                onChange={handleOnChangeState}
              />
            </Row>
          )}
          <TextField
            className={classes.input}
            defaultValue={user.defaultShippingAddress?.city}
            label="City"
            labelTx="delivery.info.city"
            preset="border"
            type="text"
            maxLength={30}
            onChange={handleOnChange('city')}
          />
          <TextField
            className={classes.input}
            defaultValue={user.defaultShippingAddress?.streetAddress1}
            label="Address Line"
            labelTx="delivery.info.street"
            preset="border"
            type="text"
            maxLength={50}
            onChange={handleOnChange('streetAddress1')}
          />
          <TextField
            withError
            {...postalCodeError}
            className={`${classes.input} ${classes.inputError}`}
            defaultValue={user.defaultShippingAddress?.postalCode}
            label="Zip Code"
            labelTx="delivery.info.zip.code"
            preset="border"
            type="text"
            maxLength={10}
            onChange={handleOnChange('postalCode')}
          />
          <TextField
            className={classes.input}
            label="First Name"
            labelTx="delivery.info.first.name"
            defaultValue={user.defaultShippingAddress?.firstName}
            preset="border"
            type="text"
            maxLength={30}
            onChange={handleOnChange('firstName')}
          />
          <TextField
            className={classes.input}
            label="Last Name"
            labelTx="delivery.info.last.name"
            defaultValue={user.defaultShippingAddress?.lastName}
            preset="border"
            type="text"
            maxLength={30}
            onChange={handleOnChange('lastName')}
          />
          <TextField
            withError
            isHidePlaceholder
            {...phoneError}
            className={`${classes.input} ${classes.inputError}`}
            label="Phone number"
            labelTx="delivery.info.phone"
            placeholder="+country code XXXXXXXXXX"
            placeholderTx="delivery.info.placeholder.phone"
            defaultValue={user.defaultShippingAddress?.phone}
            preset="border"
            type="text"
            maxLength={13}
            onChange={handleOnChange('phone')}
          />
        </Row>
      </Column>
      <Button
        className={classes.button}
        preset="primary"
        text="PROCEED TO PAY"
        textColor="white"
        textPreset="h5"
        tx="delivery.info.button"
        onClick={handleOnSubmit}
        disabled={isDisabled}
      />
    </>
  )
}
