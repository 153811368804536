import { createUseStyles } from 'react-jss'

import { CategoryBreadcrumbsStyle } from './category-breadcrumbs.types'

export const useStyle = createUseStyles({
  breadcrumbs: {
    cursor: ({ active }: CategoryBreadcrumbsStyle) =>
      active ? 'default' : 'pointer',
    '&:hover': {
      color: 'black'
    }
  },
  icon: {
    marginLeft: 8
  }
})
