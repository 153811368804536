import { createUseStyles } from 'react-jss'
import { CollectionsGalleryItemStyles } from './collections-gallery-item.types'

export const useStyle = createUseStyles({
  button: {
    position: 'absolute',
    right: 16,
    bottom: 16
  },
  imageContainer: {
    flex: ({ even }: CollectionsGalleryItemStyles) => (even ? 1 : 2),
    '&:not(:first-child)': {
      marginLeft: 8
    }
  },
  image: {
    display: 'block',
    objectFit: 'cover',
    width: '100%',
    height: '420px'
  },
  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    imageContainer: {
      width: 234,
      height: 234,
      flex: ({ even }: CollectionsGalleryItemStyles) =>
        even ? 'none' : 'none',
      '&:not(:first-child)': {
        marginLeft: 0
      }
    },
    image: {
      height: '100%'
    }
  },
  '@media screen and (max-width: 480px)': {
    imageContainer: {
      flex: 1
    },
    image: {
      width: '298px',
      height: '298px'
    }
  }
})
