import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { isMobile, isMobileOnly } from 'react-device-detect'

import { useApi } from '../../providers'
import {
  Button,
  Column,
  Content,
  FeedbackOrderItem,
  FeedbackProductSlider,
  FeedbackVendorItem,
  getCurrencySymbol,
  GoBackContainer,
  Loader,
  ProductInfoBottomBarMobile,
  ResponsiveMediaView,
  Row,
  Text
} from '../../components'
import { ConfirmationModal } from '../../views'
import { FeedbackCreateInput } from '../../services'

import { getPriceToFixed, limit } from '../helper'

import { useStyle } from './feedback.styles'
import { FeedbackTypeParams } from './feedback.types'

export const FeedbackPage = () => {
  const { id } = useParams<FeedbackTypeParams>()
  const [scrollPosition, setScrollPosition] = useState(0)
  const isAbsolute = scrollPosition !== 0 && scrollPosition === limit()
  const classes = useStyle({ absolute: isAbsolute })
  const history = useHistory()
  const {
    order: { useOrderById },
    feedback: { useCreateFeedback }
  } = useApi()
  const { data: orderData } = useOrderById({ id })
  const { onSubmit } = useCreateFeedback()

  const [openConfirm, changeOpenConfirm] = useState(false)
  const [feedbackData, changeData] = useState<FeedbackCreateInput[]>([])

  const Container = isMobile ? Column : Row
  const h1Preset = isMobileOnly ? 'h1Mobile' : 'h1'
  const h3Preset = isMobileOnly ? 'h3Mobile' : 'h3'
  const justify = isMobileOnly ? 'center' : 'flex-end'

  const handleOnChangeVendorFeedback = (data: FeedbackCreateInput) => {
    let nextFeedbackData = feedbackData.filter(
      (feedback) => feedback.vendor !== data.vendor
    )
    nextFeedbackData = [...nextFeedbackData, data]
    changeData(nextFeedbackData)
  }

  const handleOnChangeProductFeedback = (data: FeedbackCreateInput) => {
    let nextFeedbackData = feedbackData.filter(
      (feedback) => feedback.vendor !== data.vendor
    )
    nextFeedbackData = [...nextFeedbackData, data]
    changeData(nextFeedbackData)
  }

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const CurrentOrder = useMemo(() => {
    if (orderData) {
      return orderData.order
    }

    return undefined
  }, [id, orderData])

  const currencySymbol = CurrentOrder
    ? getCurrencySymbol(CurrentOrder.total.currency)
    : undefined
  const price = `${currencySymbol}${getPriceToFixed(
    CurrentOrder?.total.gross.amount
  )}`
  const companyName = `${CurrentOrder?.vendor.companyName}`
  const vendorAvatar = CurrentOrder?.vendor.avatar?.url
  const totalPrice = `${currencySymbol}${getPriceToFixed(
    CurrentOrder?.totalPrice
  )}`
  const defaultProductPrice = CurrentOrder?.offer?.productTotalPrice

  const countryCode = CurrentOrder?.vendor?.defaultBillingAddress?.country?.code

  const handleGoBack = () => history.goBack()

  const handleOnOpenConfirm = (state: boolean) => () => {
    if (state === false) {
      history.goBack()
    }
    changeOpenConfirm(state)
  }

  const handleOnOpenModal = () => {
    const openModal = handleOnOpenConfirm(true)

    openModal()
  }

  const handleOnSubmit = () => {
    feedbackData.forEach((item) => {
      const newFeedback = { input: item }
      onSubmit(newFeedback)
    })

    handleOnOpenModal()
  }

  const isDisabled = !feedbackData.length

  if (!orderData) {
    return <Loader />
  }

  const FeedbackOrder = (
    <FeedbackOrderItem
      className={classes.smallColumn}
      number={CurrentOrder?.number}
      created={CurrentOrder?.created}
      price={price}
      status={CurrentOrder?.mainStatus}
      totalPrice={totalPrice}
    />
  )

  const Buttons = (
    <Row fullWidth justifyContent={justify} className={classes.submitContainer}>
      <Button
        color="yellow"
        preset="secondaryBorderHigh"
        textColor="yellow"
        textPreset="h5"
        text="CANCEL"
        tx="give.feedback.cancel.btn"
        onClick={handleGoBack}
      />
      <Button
        className={classes.submitButton}
        color="yellow"
        preset="button3"
        textColor="white"
        textPreset="h5"
        text="SEND"
        tx="give.feedback.send.btn"
        onClick={handleOnSubmit}
        disabled={isDisabled}
      />
    </Row>
  )

  return (
    <Content>
      <Column
        fullHeight
        className={classes.container}
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <GoBackContainer
          text="Back to Purchased History"
          textTx="give.feedback.back.to.purchased.history"
          onClick={handleGoBack}
        />
        <Column className={classes.title} fullWidth>
          <Text
            text="Give Feedback"
            tx="give.feedback.header.title"
            preset={h1Preset}
          />
          <Text
            className={classes.title}
            text="Your comments will help other buyers! Please tell us more:"
            tx="give.feedback.secondary.title"
            preset="h5"
            color="inactive"
          />
        </Column>
        <Container
          className={classes.content}
          fullWidth
          alignItems="flex-start"
        >
          <Column fullWidth className={classes.bigColumn}>
            <ResponsiveMediaView
              mobile={FeedbackOrder}
              tablet={FeedbackOrder}
            />
            <FeedbackVendorItem
              className={classes.item}
              companyName={companyName}
              avatar={vendorAvatar}
              countryCode={countryCode}
              vendorId={CurrentOrder?.vendor.id}
              country={CurrentOrder?.vendor.country}
              onChange={handleOnChangeVendorFeedback}
            />
            <Column
              className={`${classes.slider} ${classes.secondaryItem}`}
              fullWidth
            >
              <Row
                className={classes.sliderContent}
                justifyContent="flex-start"
              >
                <Text
                  text="Feedback about the Product"
                  tx="give.feedback.about.product"
                  preset={h3Preset}
                />
              </Row>
              {CurrentOrder?.lines.map((item) => (
                <FeedbackProductSlider
                  key={`item_${id}`}
                  className={classes.item}
                  orderId={CurrentOrder.id}
                  productId={item.product.id}
                  name={item.product.name}
                  color={item.color?.color.code}
                  productImage={item.product.thumbnail2x?.url}
                  quantity={item.quantity}
                  price={item.totalPrice}
                  currency={item.product.pricing.priceRange.stop.gross.currency}
                  onChange={handleOnChangeProductFeedback}
                  defaultPrice={defaultProductPrice}
                />
              ))}
            </Column>
          </Column>
          <ResponsiveMediaView
            bigScreen={FeedbackOrder}
            medium={FeedbackOrder}
          />
        </Container>
      </Column>
      <ResponsiveMediaView
        bigScreen={Buttons}
        medium={Buttons}
        tablet={Buttons}
      />

      <ConfirmationModal
        title="Thank you!"
        txTitle="confirmation.title.text"
        text="Thanks for sharing your thoughts. We appriciate your feedback."
        tx="give.feedback.thanks.modal.message"
        open={openConfirm}
        onClose={handleOnOpenConfirm(false)}
      />
      <ResponsiveMediaView
        mobile={
          <ProductInfoBottomBarMobile
            absolute={isAbsolute}
            className={classes.bottomContainer}
          >
            {Buttons}
          </ProductInfoBottomBarMobile>
        }
      />
    </Content>
  )
}
