import React, { FC } from 'react'

import { concatClassNames } from '../../utils'

import { Row } from '../row'
import { Button } from '../button'
import { Text } from '../text'
import { Column } from '../column'

import { ProductInfoBottomBarProps } from './product-info-botttom-bar.types'
import { useStyle } from './product-info-bottom-bar.styles'

export const ProductInfoBottomBarMobile: FC<ProductInfoBottomBarProps> = ({
  className = '',
  classNameButton = '',
  absolute,
  name,
  price,
  children,
  buttonTitle,
  buttonTitleTx,
  onClick
}) => {
  const classes = useStyle({ absolute })

  return (
    <Column
      fullWidth
      className={concatClassNames(classes.container, className)}
      justifyContent="space-between"
    >
      {children}
      <Row fullWidth justifyContent="space-between">
        {name && <Text text={name} preset="h3Mobile" />}

        {price && (
          <Text
            className={classes.price}
            text={price}
            preset="h1Mobile"
            color="black"
          />
        )}
      </Row>
      {buttonTitle && (
        <Button
          className={`${classes.button} ${classNameButton}`}
          color="yellow"
          preset="primary"
          text={buttonTitle}
          tx={buttonTitleTx}
          textColor="white"
          textPreset="h5"
          onClick={onClick}
        />
      )}
    </Column>
  )
}
