import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    backgroundColor: theme.colors.white,
    padding: '24px 0 56px'
  },
  name: {
    padding: '8px 0'
  },
  image: {
    width: '100%',
    maxHeight: 312,
    objectFit: 'cover'
  },
  column: {},
  title: {
    marginTop: 24
  },
  text: {
    marginTop: 24
  },
  buttonRow: {
    marginTop: 24
  },
  '@media screen and (max-width: 480px)': {
    image: {
      width: 'calc(100% - 32px)',
      maxHeight: 182,
      padding: 16
    },
    column: {
      paddingBottom: 16
    },
    title: {
      marginTop: 16
    },
    text: {
      marginTop: 8,
      textAlign: 'center'
    }
  }
}))
