import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'
import { StyleProps } from './dropdown.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: ({ disabled, custom, open }: StyleProps) => ({
    cursor: disabled ? 'default' : 'pointer',
    minHeight: !custom ? 42 : custom.container,
    border: open
      ? `2px solid ${theme.colors.yellow}`
      : `1px solid ${theme.colors.inactive}`,
    borderRadius: 4,
    padding: open ? '3px 22px' : '4px 23px'
  }),
  label: ({ disabled, value }: StyleProps) => ({
    transformOrigin: '0 0',
    transform: value ? 'scale(1)' : 'scale(1.25) translateY(-2px)',
    transition: 'transform 0.25s ease-in-out',
    color: !disabled && value ? theme.colors.yellow : theme.colors.inactive
  }),
  disabled: {
    color: theme.colors.inactive
  },
  disabledLabel: {
    color: `${theme.colors.greyFon}!important`
  },
  dropdownItems: {
    maxHeight: 500,
    overflowY: 'scroll'
  },
  text: {
    marginTop: 4
  },
  input: {
    width: '100%'
  }
}))
