import { COLLECTION_FRAGMENT } from './collection'
import { FEEDBACK_FRAGMENT } from './feedback'
import { OFFER_FRAGMENT } from './offer'
import { ORDER_FRAGMENT } from './order'
import {
  PRODUCT_PRICING_FRAGMENT,
  BASIC_CARD_PRODUCT_FRAGMENT
} from './product'

export const ADDRESS_FRAGMENT = `
  fragment AddressFragment on Address {
    id
    firstName
    lastName
    companyName
    streetAddress1
    streetAddress2
    city
    cityArea
    postalCode
    country {
      country
      code
    }
    countryArea
    phone
    isDefaultShippingAddress
    isDefaultBillingAddress
  }
`

export const ACCOUNT_ERROR_FRAGMENT = `
  fragment AccountErrorFragment on AccountError {
    field
    message
    code
  }
`

export const VENDOR_USER_SMALL_FRAGMENT = `
fragment VendorUserSmallFragment on User {
  id
  email
  firstName
  lastName
  companyName
  username
  dateJoined
  avatar {
    url
    alt
  }
}
`

export const USER_FRAGMENT = `
  ${FEEDBACK_FRAGMENT}
  ${ADDRESS_FRAGMENT}
  ${BASIC_CARD_PRODUCT_FRAGMENT}
  ${PRODUCT_PRICING_FRAGMENT}
  ${COLLECTION_FRAGMENT}
  ${ORDER_FRAGMENT}
  ${OFFER_FRAGMENT}
  fragment UserFragment on User {
    id
    email
    firstName
    lastName
    sex
    status
    activity
    averageResponseTime
    phone
    city
    companyName
    country
    state
    username
    positiveFeedbackPercentage
    dateJoined
    rating
    conversationId
    unreadMessages
    bestSellingProducts {
      ...BasicCardProductFields
      ...ProductPricingField
    }
    defaultShippingAddress {
      ...AddressFragment
    }
    defaultBillingAddress {
      ...AddressFragment
    }
    feedbackAmount
    feedbacks {
      ...FeedbackFragment
    }
    wishlist(first: 8){
      totalCount
      edges {
        cursor
        node {
          id
          product {
            ...BasicCardProductFields
            ...ProductPricingField
            collection {
              ...CollectionFragment
            }
            category {
              id
              name
            }
          }
        }
      }
    }
    ordersToReturn(first: 10){
      totalCount
      edges {
        cursor
        node {
          ...OrderFragment
        }
      }
    }
    orders(first: 10){
      totalCount
      edges {
        cursor
        node {
          ...OrderFragment
        }
      }
    }
    offers(
      first: 10
      filter : { status: ACCEPTED }
      ){
      totalCount
      edges {
        cursor
        node {
          ...OfferFragment
        }
      }
    }
    avatar {
      url
      alt
    }
  }
`

export const USER_FRAGMENT_FOR_PRODUCT_PREVIEW = `
  ${ADDRESS_FRAGMENT}
  fragment UserFragmentForProductPreview on User {
    id
    email
    firstName
    lastName
    sex
    status
    companyName
    defaultBillingAddress {
      ...AddressFragment
    }
    avatar {
      url
      alt
    }
  }
`

export const USER_PART_FRAGMENT = `
${BASIC_CARD_PRODUCT_FRAGMENT}
${PRODUCT_PRICING_FRAGMENT}
${COLLECTION_FRAGMENT}
fragment UserPartFragment on User {
  id
  email
  firstName
  lastName
  sex
  unreadMessages
  offers(
    first: 100
    filter : { status: NEW }
    ){
    totalCount

  }
  wishlist(first: 8){
    totalCount
    edges {
      cursor
      node {
        id
        product {
          ...BasicCardProductFields
          ...ProductPricingField
          collection {
            ...CollectionFragment
          }
          category {
            id
            name
          }
        }
      }
    }
  }
}
`
