import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {},
  item: {
    marginLeft: 24,
    cursor: 'pointer',
    '& > span:hover': {
      color: theme.colors.yellow
    }
  }
}))
