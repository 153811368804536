import React, { FC, useMemo } from 'react'

import { Column, Row, Text } from '../../../components'
import { getPriceToFixed } from '../../../pages'

import { OfferPriceChatProps } from './offer-price-chat.types'

export const OfferPriceChat: FC<OfferPriceChatProps> = ({
  className,
  totalPrice,
  quantity,
  deliveryPrice,
  price,
  currencySymbol
}) => {
  const getPriceWithCurrency = (currentPrice: number) => {
    return `${currencySymbol}${getPriceToFixed(currentPrice)}`
  }

  const fullPrice = useMemo(() => getPriceWithCurrency(price), [price])
  const fullTotalPrice = useMemo(
    () => getPriceWithCurrency(totalPrice),
    [totalPrice]
  )
  const deliveryOfferPrice = getPriceWithCurrency(deliveryPrice)
  const quantityStingified = String(quantity)

  return (
    <Row fullWidth className={className} justifyContent="space-between">
      <Column alignItems="flex-start" justifyContent="flex-start">
        <Text
          color="inactive"
          preset="body"
          text="Delivery Price: "
          tx="offer.delivery.price"
        />
        <Text color="black" preset="h7" text={deliveryOfferPrice} />
      </Column>
      <Column alignItems="flex-start" justifyContent="flex-start">
        <Text
          color="inactive"
          preset="body"
          text="Item Price"
          tx="offer.item.price"
        />
        <Text color="black" preset="h7" text={fullPrice} />
      </Column>
      <Column alignItems="flex-start" justifyContent="flex-start">
        <Text
          color="inactive"
          preset="body"
          text="Quantity"
          tx="offer.quantity"
        />
        <Text color="black" preset="h7" text={quantityStingified} />
      </Column>
      <Column alignItems="flex-start" justifyContent="flex-start">
        <Text
          color="inactive"
          preset="body"
          text="Total Price"
          tx="offer.totalPrice"
        />
        <Text color="black" preset="h7" text={fullTotalPrice} />
      </Column>
    </Row>
  )
}
