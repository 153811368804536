import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {
    marginTop: 40,
    padding: '0px 16px',
    width: 'calc(100% - 32px)',
    '& > :not(:first-child)': {
      marginLeft: 20
    }
  },
  imageContainer: {
    flex: 1,
    width: '70%'
  },
  actions: {
    width: '30%'
  },
  vendorInfo: {
    marginTop: 40,
    '& > a': {
      marginTop: 4
    },
    '& > div:first-child': {
      marginTop: 4
    }
  },
  policyRow: {
    marginTop: 40,
    '& > a:last-child': {
      marginTop: 16
    }
  },
  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    container: {
      width: '100%',
      paddingBottom: 40
    },
    imageContainer: {
      width: '56%',
      overflowX: 'hidden'
    },
    actions: {
      marginLeft: 20,
      width: '44%'
    },
    policyRow: {
      '& > a:last-child': {
        marginTop: 0
      }
    }
  },
  '@media screen and (max-width: 480px)': {
    container: {
      marginTop: 24,
      padding: 0,
      width: '100%',
      '& > :not(:first-child)': {
        marginLeft: 0
      }
    },
    imageContainer: {
      marginTop: 0,
      width: '100%',
      overflowX: 'hidden'
    },
    actions: {
      width: '100%'
    },
    policyRow: {
      '& > a:last-child': {
        marginTop: 0
      }
    }
  }
})
