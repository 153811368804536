import { createUseStyles } from 'react-jss'

import background from '../../assets/images/delete_account.png'

export const useStyle = createUseStyles({
  modal: {
    marginTop: 0,
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top, center left 50px',
    backgroundImage: `url(${background})`,
    zIndex: 1,
    width: 520,
    height: 263
  },
  title: {
    marginTop: 312
  },
  description: {
    marginTop: 16,
    textAlign: 'center'
  },
  refundTitle: {
    marginTop: 40
  },
  refundPrice: {
    marginTop: 16
  },
  '@media screen and (max-width: 480px)': {
    modal: {
      width: '90%'
    },
    title: {
      marginTop: 194
    }
  }
})
