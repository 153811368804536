import { useQuery } from '@apollo/client'

import {
  ADVERTISING_BANNERS,
  BANNERS,
  MAIN_MENU,
  PROMOTION_BANNERS,
  SECONDARY_MENU
} from './menu.graphql'
import {
  MainMenuShop,
  SecondaryMenu,
  MenuApi,
  MainMenu,
  SecondaryMenuShop,
  HomeBanners,
  GetHomeBanners,
  PagginationBannersVariables,
  PromotionBanners,
  PromotionBannersVariables,
  GetPromotionBanners,
  GetAdvertisingBanners,
  AdvertisingBanners
} from './menu.types'

// our "constructor"
export const menuService = (): MenuApi => {
  const useSecondaryMenu = (): SecondaryMenu => {
    const { data, loading, error } = useQuery<SecondaryMenuShop, any>(
      SECONDARY_MENU
    )

    if (error) {
      return { data: null, loading }
    }

    if (!data) {
      return { data: null, loading }
    }

    return { data, loading }
  }

  const useMainMenu = (): MainMenu => {
    const { data, loading, error, refetch } = useQuery<MainMenuShop, any>(
      MAIN_MENU
    )

    if (error) {
      return { data: null, loading, refetch }
    }

    if (!data) {
      return { data: null, loading, refetch }
    }

    return { data, loading, refetch }
  }

  const useBanners = (
    variables: PagginationBannersVariables
  ): GetHomeBanners => {
    const { data, loading, error } = useQuery<
      HomeBanners,
      PagginationBannersVariables
    >(BANNERS, { variables })

    if (error) {
      return { data: null, loading }
    }

    if (!data) {
      return { data: null, loading }
    }

    return { data, loading }
  }

  const usePromotionBanners = (
    variables: PromotionBannersVariables
  ): GetPromotionBanners => {
    const { data, loading, error } = useQuery<
      PromotionBanners,
      PromotionBannersVariables
    >(PROMOTION_BANNERS, { variables })

    if (error) {
      return { data: null, loading }
    }

    if (!data) {
      return { data: null, loading }
    }

    return { data, loading }
  }

  const useAdvertisingBanners = (
    variables: PromotionBannersVariables
  ): GetAdvertisingBanners => {
    const { data, loading, error } = useQuery<
      AdvertisingBanners,
      PromotionBannersVariables
    >(ADVERTISING_BANNERS, { variables })

    if (error) {
      return { data: null, loading }
    }

    if (!data) {
      return { data: null, loading }
    }

    return { data, loading }
  }

  return {
    useSecondaryMenu,
    useMainMenu,
    useBanners,
    usePromotionBanners,
    useAdvertisingBanners
  }
}
