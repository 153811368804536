import React, { useMemo } from 'react'
import { Redirect } from 'react-router-dom'

import { Column, Banner, ResponsiveMediaView } from '../../components'
import { useApi, useAuth } from '../../providers'
import { BannerKind } from '../../services'
import background from '../../assets/images/background-user-info.png'

import { BasicInfo } from './basic-info'
import { BasicInfoMobile } from './basic-info-mobile'
import { DeliveryInfoView } from './delivery-info'
import { useStyle } from './user-info.styles'

export const UserInfoPage = () => {
  const { user } = useAuth()

  const classes = useStyle()

  const {
    menu: { useBanners }
  } = useApi()

  const { data: banners } = useBanners({
    first: 20,
    filter: {
      isAvailableToday: true,
      isVisible: true,
      kind: [BannerKind.PREMIUM_USER_BANNER]
    }
  })

  const MainBanner = useMemo(() => {
    if (banners) {
      const banner = banners.allBanners.edges[0]

      if (banner) {
        return banner.node
      }
    }

    return undefined
  }, [banners])

  if (!user) {
    return <Redirect to="/" />
  }

  return (
    <Column className={classes.container} alignItems="flex-start">
      <img className={classes.background} src={background} />

      <ResponsiveMediaView
        mobile={<BasicInfoMobile data={user} />}
        tablet={<BasicInfo data={user} />}
        medium={<BasicInfo data={user} />}
        bigScreen={<BasicInfo data={user} />}
      />

      <DeliveryInfoView user={user} />

      {MainBanner && <Banner data={MainBanner} />}
    </Column>
  )
}
