import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  overlay: {
    position: 'fixed',
    backgroundColor: theme.colors.white,
    height: '100%',
    minHeight: '100vh',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 2
  },
  content: {
    height: '100vh',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 56,
    borderBottom: `1px solid ${theme.colors.shadow}`,
    width: '100%'
  },

  svgContainer: {
    display: 'flex',
    margin: '0 12px'
  },
  text: {
    margin: '0 12px'
  },
  icon: {
    cursor: 'pointer',
    '&:hover > path': {
      fill: theme.colors.yellow
    }
  }
}))
