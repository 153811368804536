import { createUseStyles } from 'react-jss'

import background from '../../../assets/images/emptySale.png'

export const useStyle = createUseStyles({
  image: {
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center top 32px',
    backgroundImage: `url(${background})`,
    zIndex: 1,
    width: 330
  },
  subcategory: {
    marginTop: 88
  },
  saleText: {
    marginTop: 312,
    width: 300,
    paddingBottom: 36,
    textAlign: 'center'
  }
})
