import React, { FC, useState } from 'react'
import {
  Column,
  TextField,
  VendorItemList,
  Checkbox,
  QuestionModal
} from '../../components'

import { useStyle } from './choose-vendor.styles'
import { ChooseVendorProps } from './choose-vendor.types'

export const ChooseVendorModal: FC<ChooseVendorProps> = ({
  open,
  data,
  onSearch,
  onSubmit,
  onClose
}) => {
  const [activeIds, changeActiveIds] = useState<string[]>([])
  const classes = useStyle()

  if (!open) {
    return <></>
  }

  const handleOnChange = (id: string) => (state: boolean) => {
    const nextActiveIds = activeIds.filter((currentId) => id !== currentId)

    if (state) {
      changeActiveIds([...nextActiveIds, id])
    } else {
      changeActiveIds(nextActiveIds)
    }
  }

  const isDisabled = activeIds.length === 0

  const handleOnSubmit = () => {
    onSubmit(activeIds)
  }

  return (
    <QuestionModal
      title="Choose Vendor"
      onClose={onClose}
      onSubmit={handleOnSubmit}
      disabled={isDisabled}
    >
      <Column
        fullWidth
        className={classes.column}
        justifyContent="space-between"
      >
        <Column className={classes.form}>
          <TextField
            label="Search"
            labelTx="choose.vendor.text.input"
            preset="border"
            textInputPreset="maxButton"
            onChange={onSearch}
          />
        </Column>
        <Column
          fullWidth
          className={classes.content}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          {data.map(
            ({
              id,
              previewImage,
              background,
              companyName = '',
              displayName = ''
            }) => (
              <Checkbox
                className={classes.checkbox}
                key={`vtmt_vendor_${id}`}
                onChange={handleOnChange(id)}
              >
                <VendorItemList
                  background={background}
                  image={previewImage}
                  description={companyName}
                  text={displayName}
                />
              </Checkbox>
            )
          )}
        </Column>
      </Column>
    </QuestionModal>
  )
}
