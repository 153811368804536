import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    border: `1px solid ${theme.colors.shadow}`
  },
  line: {
    marginTop: 16
  },
  content: {
    borderTop: `1px solid ${theme.colors.shadow}`
  }
}))
