import { OFFER_ITEM_FRAGMENT } from './items'
import { OFFER_MATERIAL_FRAGMENT } from './material'
import { OFFER_COLOR_FRAGMENT } from './color'
import { OPTION_VARIANT_FRAGMENT, OFFER_OPTION_FRAGMENT } from './option'
import {
  PRODUCT_FRAGMENT_FOR_OFFER,
  PRODUCT_PRICING_FRAGMENT_FOR_OFFER
} from './product'

export const OFFER_USER_FRAGMENT = `
  fragment OfferUserFragment on User {
    id
    email
    firstName
    lastName
    companyName
    sex
    status
    username
    avatar {
      url
      alt
    }
  }
`

export const OFFER_FRAGMENT = `
  ${PRODUCT_FRAGMENT_FOR_OFFER}
  ${PRODUCT_PRICING_FRAGMENT_FOR_OFFER}
  ${OFFER_USER_FRAGMENT}
  ${OPTION_VARIANT_FRAGMENT}
  ${OFFER_ITEM_FRAGMENT}
  fragment OfferFragment on Offer {
    id
    conversationId
    number
    description
    noSuggestion
    quantity
    discount
    productTotalPrice
    totalPrice
    totalWeight
    conversationId
    deliveryPrice
    productionDays
    status
    vendor {
      ...OfferUserFragment
    }
    product {
      ...ProductFragmentForOffer
      ...ProductPricingFieldForOffer
    }
    items {
      ...OfferItemFragment
    }
  }
  `

export const OFFER_FRAGMENT_FOR_ORDER = `
  ${PRODUCT_FRAGMENT_FOR_OFFER}
  ${PRODUCT_PRICING_FRAGMENT_FOR_OFFER}
  ${OFFER_ITEM_FRAGMENT}
  ${OFFER_MATERIAL_FRAGMENT}
  ${OFFER_COLOR_FRAGMENT}
  ${OFFER_OPTION_FRAGMENT}
  ${OPTION_VARIANT_FRAGMENT}
  fragment OfferFragmentForOrder on Offer {
    conversationId
    description
    noSuggestion
    discount
    productTotalPrice
    productionDays
    deliveryPrice
    product {
      ...ProductFragmentForOffer
      ...ProductPricingFieldForOffer
    }
    productMaterials {
      ...OfferMaterialFragment
    }
    productColors {
      ...OfferColorFragment
    }
    productOptions {
      ...OfferOptionFragment
    }
    options {
      ...OptionVariantFragment
    }
  }
  `
