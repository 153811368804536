import React, { FC, useState } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import { LightTheme } from '../../../theme'
import { Icon, ICONS } from '../../icon'
import { Column } from '../../column'
import { Row } from '../../row'
import { NavLink } from '../../nav-link'

import { NavCategoryProps } from '../nav-category.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  navLink: {
    marginTop: 12,
    '& > span:hover': {
      color: theme.colors.yellow
    }
  },
  category: {
    marginTop: 14
  },
  svgContainer: {
    width: 'auto'
  },
  icon: {
    cursor: 'pointer',
    width: 24,
    height: 24
  },
  subitem: {
    marginLeft: 28
  }
}))

export const NavCategoryMobile: FC<NavCategoryProps> = ({
  subItemColor: subItemColorOverride,
  item,
  additionalSection
}) => {
  const theme = useTheme<LightTheme>()
  const subItemColor = subItemColorOverride || 'black'
  const [openCategory, changeOpenCategory] = useState(false)
  const iconOpen = openCategory ? ICONS.arrowTop : ICONS.arrowBottom
  const classes = useStyle({})
  const titleColor = openCategory ? 'yellow' : 'black'

  const handleOnClick = () => {
    changeOpenCategory(!openCategory)
  }

  return (
    <Column fullWidth alignItems="flex-start">
      <Row fullWidth justifyContent="space-between">
        <Row>
          <NavLink
            className={classes.category}
            color={titleColor}
            text={item.name}
            preset="h6"
            to={item.url}
          />
        </Row>
        <Row className={classes.svgContainer}>
          <Icon
            className={classes.icon}
            fill={theme.colors.yellow}
            src={iconOpen}
            onClick={handleOnClick}
          />
        </Row>
      </Row>
      {openCategory && (
        <Column alignItems="flex-start" className={classes.subitem}>
          {item.children.map((subItem) => (
            <NavLink
              key={subItem.id}
              className={classes.navLink}
              color={subItemColor}
              text={subItem.name}
              preset="h7"
              to={subItem.url}
            />
          ))}
          {additionalSection}
        </Column>
      )}
    </Column>
  )
}
