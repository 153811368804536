import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'

import { Column, Row, Text } from '../../../components'

import { NewsCardProps } from './news-card.types'
import { useStyle } from './news-card.styles'

export const NewsCard: FC<NewsCardProps> = ({ className, title, image }) => {
  const history = useHistory()
  const isPage = history.location.pathname === '/latest-news'

  const classes = useStyle()
  const doNotShowDescription = isMobileOnly && !isPage
  return (
    <Column className={`${className} ${classes.container}`}>
      <Row className={classes.imgContainer}>
        <Row className={classes.imgLabel}>
          <Text color="black" preset="maxButton" text="READ" tx="news.read" />
        </Row>
        {image && <img className={classes.img} src={image} alt={image} />}
      </Row>

      {!doNotShowDescription && (
        <Column className={classes.details} alignItems="flex-start">
          {title && (
            <Text
              className={classes.text}
              color="black"
              preset="h4"
              text={title}
            />
          )}
        </Column>
      )}
    </Column>
  )
}
