import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useHistory } from 'react-router-dom'

import {
  Column,
  ICONS,
  OfferListItem,
  TextField,
  ResponsiveMediaView,
  getListFromEdgesList,
  Button,
  CabinetPageContainer,
  arrayHasElements,
  Loader,
  Row,
  OfferListItemMobile
} from '../../components'
import { useApi } from '../../providers'
import {
  OfferOrderField,
  OfferStatus,
  OfferUpdateVariables,
  OfferVariables,
  OrderDirection
} from '../../services'
import { EmptyList } from '../../views'

import { CabinetNavigatorPath } from '../cabinet-navigator'

import { useStyle } from './offers.styles'

export const OffersPage = () => {
  const { offer: offerApi } = useApi()
  const history = useHistory()
  const FIRST = 10
  const SEARCH_QUERY_MIN = 3
  const STATUS = [
    OfferStatus.NEW,
    OfferStatus.ACCEPTED,
    OfferStatus.ACCEPTED_BACK_FROM_CART,
    OfferStatus.DECLINED_BY_CUSTOMER,
    OfferStatus.DECLINED_BY_VENDOR
  ]
  const [variables, changeVariables] = useState<OfferVariables>({
    first: FIRST,
    filter: {
      status: STATUS,
      search: ''
    },
    sortBy: { direction: OrderDirection.DESC, field: OfferOrderField.UPDATED }
  })

  const { onSubmit, response: updateResponse } = offerApi.useOfferUpdate()
  const {
    data: response,
    loading,
    refetch,
    fetchMore
  } = offerApi.useOffers(variables)

  const classes = useStyle()

  useEffect(() => {
    refetch(variables)
  }, [history.action])

  const Offers = useMemo(() => {
    const offersData = response?.offers
    if (offersData) {
      return getListFromEdgesList(offersData)
    }

    return []
  }, [response, updateResponse])

  const TotalCount = useMemo(() => {
    if (response) {
      return response.offers.totalCount
    }
    return 0
  }, [response])

  const count = Offers.length || 0

  const handleOnContactVendor = (offerId: string) => () => {
    history.push({
      pathname: `${CabinetNavigatorPath.OFFER_CHAT}/${offerId}`,
      state: 'fromOffersPage'
    })
  }

  const handleOnUpdate = (updateVariables: OfferUpdateVariables) => {
    if (onSubmit) {
      onSubmit(updateVariables)
    }
    if (refetch) {
      const first = count
      refetch({ ...variables, first, after: undefined })
    }
  }

  const handleOnSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = event.target

    const searchValue = inputValue.length >= SEARCH_QUERY_MIN ? inputValue : ''

    const nextVariables = {
      ...variables,
      after: undefined,
      filter: {
        status: STATUS,
        search: searchValue
      }
    }

    changeVariables(nextVariables)

    refetch(nextVariables)
  }

  const handleOnNext = () => {
    if (response && fetchMore) {
      const data = response.offers.edges

      const { cursor } = data[data.length - 1]

      if (cursor) {
        const nextVariables = { ...variables, after: cursor }

        changeVariables(nextVariables)
        fetchMore({
          variables: nextVariables
        })
      }
    }
  }

  const handleOnClickHome = () => {
    history.push('/')
  }

  const searchField = (
    <TextField
      className={classes.search}
      preset="border"
      maxLength={200}
      leftIcon={ICONS.search}
      label="Search"
      labelTx="wishlist.filter.search"
      onChange={handleOnSearch}
    />
  )

  const rightHeaderContent = (
    <ResponsiveMediaView
      bigScreen={searchField}
      medium={searchField}
      tablet={searchField}
    />
  )

  if (loading) {
    return <Loader />
  }

  return (
    <CabinetPageContainer
      label="Offers"
      labelTx="offers.title.text"
      rightHeaderContent={rightHeaderContent}
    >
      {!loading && !arrayHasElements(Offers) && (
        <Column fullWidth>
          <EmptyList
            classNameImg={classes.imageContainer}
            title="Oops! Your Offers Control is empty"
            titleTx="cabinet.route.offers.empty.secondTitle"
            text="Select a product and contact the vendor for offers to appear here."
            tx="cabinet.route.offers.empty.text"
          />
          <Button
            className={classes.emptyButton}
            color="yellow"
            preset="primary"
            textColor="white"
            textPreset="h5"
            text="HOME"
            tx="error.404.button.text"
            onClick={handleOnClickHome}
          />
        </Column>
      )}
      {Offers.map((offer) => (
        <Row fullWidth key={`offer_${offer.id}`}>
          {isMobileOnly ? (
            <OfferListItemMobile
              offer={offer}
              key={`offer_${offer.id}`}
              id={offer.id}
              onChangeStatus={handleOnUpdate}
              onContactVendor={handleOnContactVendor(offer.id)}
            />
          ) : (
            <OfferListItem
              offer={offer}
              key={`offer_${offer.id}`}
              id={offer.id}
              onChangeStatus={handleOnUpdate}
              onContactVendor={handleOnContactVendor(offer.id)}
            />
          )}
        </Row>
      ))}
      {TotalCount > count && (
        <Column fullWidth className={classes.load}>
          <Button
            className={classes.button}
            color="yellow"
            preset="button3"
            textColor="white"
            textPreset="h5"
            text="Load More"
            onClick={handleOnNext}
          />
        </Column>
      )}
    </CabinetPageContainer>
  )
}
