import React, { FC } from 'react'
import { v4 as uuid } from 'uuid'

import {
  getPickedColorCode,
  getPickedColorTitle,
  getPickedMaterial
} from '../../pages'
import { getPriceWithSymbol } from '../../views'

import { Column } from '../column'
import { GeneralItemLine } from '../general-item-line'
import { zeroPad } from '../helpers'
import { VariantLine } from '../variant-line'

import { ItemLineProps } from './item-line.types'

export const ItemLine: FC<ItemLineProps> = ({
  className = '',
  lastColumnClassName = '',
  productName,
  productDefaultPrice,
  imgUrl,
  children,
  items,
  currency,
  isTooltip,
  noSuggestion,
  description,
  discount
}) => {
  return (
    <Column fullWidth className={className}>
      <GeneralItemLine productName={productName} img={imgUrl}>
        {children}
      </GeneralItemLine>

      {items &&
        items.map((item, index) => {
          const {
            id,
            quantity: unitQuantity,
            productColors,
            productMaterials,
            productOptions,
            options,
            totalItemPrice,
            unitIds
          } = item

          const initialIndex = index + 1
          const itemNumber = zeroPad(initialIndex, 2)
          const colorCode = getPickedColorCode(productColors)
          const colorTitle = getPickedColorTitle(productColors)
          const material = getPickedMaterial(productMaterials)

          return (
            <VariantLine
              key={`${uuid()}${id}`}
              lastColumnClassName={lastColumnClassName}
              count={itemNumber}
              productDefaultPrice={productDefaultPrice}
              noSuggestion={noSuggestion}
              currency={currency}
              description={description}
              discount={discount}
              itemVariant={item}
              unitQuantity={unitQuantity}
              unitsIds={unitIds}
              isTooltip={isTooltip}
              totalUnitPrice={getPriceWithSymbol(totalItemPrice, currency)}
              material={material}
              colorCode={colorCode}
              colorTitle={colorTitle}
              offerOptions={options}
              productOptionsFromOffer={productOptions}
            />
          )
        })}
    </Column>
  )
}
