import React, { useMemo } from 'react'

import { ProductFilter, ProductFiltersParseJSON } from '../../services'
import {
  FilterSidebarData,
  getListFromEdgesList,
  parseFilterItems
} from '../../components'
import { useApi } from '../../providers'
import { ProductView, reduceProductsToProductList } from '../../views'

export const SalePage = () => {
  const { product } = useApi()
  const first = 8
  const defaultFilter = {
    isSale: true
  }

  const {
    data: response,
    loading,
    refetch,
    fetchMore
  } = product.useProducts({
    first,
    filter: defaultFilter
  })

  const Data = useMemo(() => {
    if (response) {
      const products = getListFromEdgesList(response.products)
      return reduceProductsToProductList(products)
    }

    return []
  }, [response])

  const TotalCount = useMemo(
    () => response?.products.totalCount || 0,
    [response?.products.totalCount]
  )

  const handleOnLoadNext = (filter: ProductFilter) => {
    if (fetchMore && response) {
      const nextFilter = { ...filter, ...defaultFilter }
      const productEdges = response.products.edges
      const lastIndex = productEdges.length - 1
      fetchMore({
        variables: {
          first,
          filter: nextFilter,
          after: productEdges[lastIndex].cursor
        }
      })
    }
  }

  const handleOnChangeFilter = (value: ProductFilter) => {
    if (refetch) {
      const nextFilter = { ...value, ...defaultFilter }
      refetch({ first, filter: nextFilter })
    }
  }

  const filtersData = response ? response.products.filters : undefined
  const Filters = useMemo(() => {
    if (filtersData) {
      const list = parseFilterItems<ProductFiltersParseJSON>(filtersData)

      if (list) {
        return list
      }
    }

    return {
      colors: [],
      materials: [],
      vendors: [],
      genders: [],
      priceRange: [],
      companyNames: [],
      productTypes: [],
      sizes: [],
      rating: [],
      productionDaysRange: []
    } as FilterSidebarData<ProductFiltersParseJSON>
  }, [filtersData])

  return (
    <ProductView
      withCategories
      loading={loading}
      data={Data}
      title="Sale"
      titleTx="header.sales"
      totalCount={TotalCount}
      filtersData={Filters}
      onLoadNext={handleOnLoadNext}
      onChangeFilter={handleOnChangeFilter}
    />
  )
}
