import { createUseStyles } from 'react-jss'

import { DropdownItemsStyleProps } from './dropdown-items.types'

export const useStyle = createUseStyles({
  container: {
    position: 'relative',
    cursor: ({ disabled }: DropdownItemsStyleProps) =>
      disabled ? 'default' : 'pointer',
    width: '100%',
    background: 'transparent',
    border: 'none',
    padding: 0
  },
  listContainer: {
    position: 'absolute',
    top: 46,
    width: '100%',
    zIndex: 2,
    borderRadius: 4,
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  }
})
