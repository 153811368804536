import React, { useMemo, useState, useEffect } from 'react'

import { useParams } from 'react-router-dom'

import {
  Column,
  getListFromEdgesList,
  ProductCardType,
  ResponsiveMediaView
} from '../../components'
import { useApi } from '../../providers'
import {
  reduceProductEdgesToProductList,
  reduceProductsToProductList
} from '../../views'
import { useWindowDimensions } from '../../hooks'

import { reduceVendorsCategories } from './helpers'
import { VendorPageParams } from './vendor.types'
import { VendorMobileView, VendorView } from './vendor-view'

export const VendorPage = () => {
  const { width } = useWindowDimensions()

  const itemsCount = width >= 1224 ? 10 : 6

  const { id } = useParams<VendorPageParams>()
  const {
    product: { useProducts, useVendorCategories },
    menu: { usePromotionBanners },
    vendor: { useVendorById }
  } = useApi()

  const first = itemsCount
  const itemVaribles = {
    first,
    filter: {
      vendors: [id]
    }
  }
  const categoryItemVariable = {
    first: 40,
    filter: {
      vendor: id
    }
  }
  const { data: vendorData } = useVendorById({ id })
  const {
    data: vendorProducts,
    fetchMore,
    refetch: refetchProducts
  } = useProducts(itemVaribles)
  const { data: categories, refetch: refetchCategories } =
    useVendorCategories(categoryItemVariable)
  const { data: banners } = usePromotionBanners({
    first: 20,
    filter: {
      isVisible: true,
      vendor: id
    }
  })

  useEffect(() => {
    if (refetchProducts) {
      refetchProducts(itemVaribles)
    }

    if (refetchCategories) {
      refetchCategories(categoryItemVariable)
    }
  }, [id])

  const [productsData, changeProductsData] = useState<ProductCardType[]>([])
  const totalCountProducts = vendorProducts?.products.totalCount || 0
  const countProducts = vendorProducts?.products.edges.length || 0

  const Vendor = useMemo(() => {
    if (vendorData) {
      return vendorData.vendor
    }

    return undefined
  }, [vendorData])

  useEffect(() => {
    if (vendorProducts) {
      const nextData = reduceProductEdgesToProductList(
        vendorProducts.products.edges
      )
      changeProductsData([...productsData, ...nextData])
    }
  }, [vendorProducts])

  const PromotionBanner = useMemo(() => {
    if (banners) {
      const now = new Date()
      const bannersValue = getListFromEdgesList(banners.promotionBanners)
        .filter((item) => item.isVisible)
        .filter((item) => {
          const startDate = new Date(item.startDate)
          const endDate =
            new Date(item.endDate).getDate() > startDate.getDate()
              ? new Date(item.endDate)
              : undefined
          if (now.getDate() >= startDate.getDate()) {
            if (endDate) {
              if (now.getDate() < endDate.getDate()) {
                return item
              }
            } else {
              return item
            }
          }
          return false
        })
      return bannersValue
    }

    return []
  }, [banners])

  const Products = useMemo(() => {
    if (Vendor?.bestSellingProducts) {
      return reduceProductsToProductList(Vendor.bestSellingProducts)
    }

    return []
  }, [Vendor?.bestSellingProducts])

  const Categories = useMemo(() => {
    if (categories) {
      return reduceVendorsCategories(categories)
    }

    return []
  }, [categories])

  const AllProducts = useMemo(() => {
    if (vendorProducts) {
      const { edges } = vendorProducts.products
      return reduceProductEdgesToProductList(edges)
    }

    return []
  }, [vendorProducts?.products])

  const averageRating = useMemo(() => {
    if (Vendor?.rating) {
      return Number(Vendor.rating)
    }
    return 0
  }, [Vendor?.rating])

  const positiveFeedbackPercent = Number(
    Vendor?.positiveFeedbackPercentage
  ).toFixed(1)

  const countryCode = Vendor?.defaultBillingAddress?.country?.code

  const positiveFeedback = `${positiveFeedbackPercent}%` || '-'

  const handleOnNext = () => {
    if (vendorProducts) {
      const productEdges = vendorProducts.products.edges
      const nextEdge = productEdges[productEdges.length - 1]

      if (nextEdge && fetchMore) {
        fetchMore({ variables: { first, after: nextEdge.cursor } })
      }
    }
  }

  const VendorMain = (
    <VendorView
      banner={PromotionBanner}
      vendor={Vendor}
      averageRating={averageRating}
      countryCode={countryCode}
      positiveFeedback={positiveFeedback}
      products={Products}
      allProducts={AllProducts}
      categories={Categories}
      totalCount={totalCountProducts}
      count={countProducts}
      onNext={handleOnNext}
    />
  )
  const VendorMobile = (
    <VendorMobileView
      banner={PromotionBanner}
      vendor={Vendor}
      averageRating={averageRating}
      countryCode={countryCode}
      positiveFeedback={positiveFeedback}
      products={Products}
      allProducts={AllProducts}
      categories={Categories}
      totalCount={totalCountProducts}
      count={countProducts}
      onNext={handleOnNext}
    />
  )

  return (
    <Column fullWidth>
      <ResponsiveMediaView
        mobile={VendorMobile}
        tablet={VendorMain}
        medium={VendorMain}
        bigScreen={VendorMain}
      />
    </Column>
  )
}
