import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../theme'
import { MenuSliderStyleProps } from './menu-slider.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  slider: {
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.shadow}`,
    borderRadius: 4
  },
  sliderText: {
    cursor: 'pointer',
    marginLeft: 16
  },
  titleRow: {
    cursor: 'pointer',
    padding: ({ iconControl }: MenuSliderStyleProps) =>
      iconControl ? 16 : '8px 24px',
    width: ({ iconControl }: MenuSliderStyleProps) =>
      iconControl ? 'calc(100% - 32px)' : 'calc(100% - 48px)',
    backgroundColor: ({ mobileOpen }: MenuSliderStyleProps) =>
      mobileOpen ? theme.colors.vendorProfileFon : theme.colors.white
  },

  content: {
    display: 'block',
    height: ({ open, height }: MenuSliderStyleProps) => (open ? height : 0),
    visibility: ({ open }: MenuSliderStyleProps) =>
      open ? 'visible' : 'hidden',
    padding: ({ open }: MenuSliderStyleProps) => (open ? '16px 0px' : 0),
    transition: '0.25s ease-in-out all',
    overflow: 'hidden',
    borderTop: `1px solid ${theme.colors.shadow}`,
    width: 'calc(100% - 32px)'
  },

  icon: {
    height: ({ iconControl }: MenuSliderStyleProps) =>
      iconControl ? '100%' : 32,
    width: 32,
    marginLeft: 24,
    marginTop: ({ iconControl }: MenuSliderStyleProps) => (iconControl ? 12 : 4)
  },
  '@media screen and (max-width: 480px)': {
    titleRow: {
      padding: ({ iconControl }: MenuSliderStyleProps) => !iconControl && 16,
      width: ({ width }: MenuSliderStyleProps) =>
        width ? 'calc(100% - 32px)' : '100%'
    },

    content: {
      padding: ({ open }: MenuSliderStyleProps) => (open ? 0 : 0),
      width: '100%'
    },
    icon: {
      marginLeft: 0,
      marginTop: 0,
      position: ({ iconStyles }: MenuSliderStyleProps) =>
        iconStyles && 'absolute',
      right: ({ iconStyles }: MenuSliderStyleProps) => iconStyles && 10,
      top: ({ iconStyles }: MenuSliderStyleProps) => iconStyles && 30
    }
  }
}))
