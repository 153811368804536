import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    padding: 24
  },
  content: {
    maxWidth: 1124
  },
  title: {
    marginTop: 70
  },
  secondTitle: {
    marginTop: 24
  },
  text: {
    marginTop: 24
  },
  row: {
    border: `1px solid ${theme.colors.shadow}`,
    padding: 16,
    marginTop: 16,
    width: 'calc(100% - 34px)',
    borderRadius: 4,
    cursor: 'pointer'
  },
  opened: {
    border: `1px solid ${theme.colors.yellow}`
  },
  mainRow: {
    flex: 1
  },
  arrow: {
    display: 'flex'
  },
  value: {
    padding: 16
  },
  ordersColumn: {
    top: 0,
    border: `1px solid ${theme.colors.shadow}`,
    borderRadius: 4,
    maxHeight: 300,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 8
    },
    '&::-webkit-scrollbar-thumb': {
      background: `${theme.colors.blue}`,
      height: 120
    }
  },
  list: {
    width: '100%'
  },
  button: {
    marginTop: 24,
    width: 362
  },
  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    content: {
      maxWidth: '100%',
      width: '100%',
      padding: '18px 0 40px'
    },
    title: {
      marginTop: 38
    },
    secondTitle: {
      marginTop: 80
    }
  },
  '@media screen and (max-width: 480px)': {
    container: {
      padding: '24px 0'
    },
    content: {
      maxWidth: '100%',
      width: '100%',
      padding: '18px 0 40px'
    },
    title: {
      marginTop: 0
    },
    secondTitle: {
      marginTop: 80
    }
  }
}))
