import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    width: 'calc(100% - 32px)',
    padding: '16px 0',
    borderBottom: `1px solid ${theme.colors.shadow}`,
    '&:last-child': {
      borderBottom: 'none'
    }
  },
  item: {
    flex: 8
  },
  img: {
    width: 64,
    height: 64,
    objectFit: 'cover'
  },
  nameText: {
    marginLeft: 8
  },
  observeOptionsBtn: {
    marginTop: 6,
    padding: '8px 6px'
  },
  itemText: {
    flex: 4
  },
  itemPrice: {
    flex: 4
  },
  itemLastText: {
    flex: 2.5
  },

  color: {
    marginTop: 8
  },
  quantity: {
    width: 148
  },
  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    container: {
      width: 'calc(100% - 32px)',
      padding: '40px 16px'
    },
    itemPrice: {
      flex: 3
    }
  }
}))
