import React, { FC, useState } from 'react'

import { ChooseOrderModal } from '../../views'

import { Row } from '../row'
import { Button } from '../button'

import { ChooseReturnButtonProps } from './choose-return-button.types'
import { useStyle } from './choose-return-button.styles'

export const ChooseReturnButton: FC<ChooseReturnButtonProps> = ({ orders }) => {
  const classes = useStyle()

  const [openModal, changeOpenModal] = useState<boolean>(false)

  const handleOnChooseOrderClick = () => {
    changeOpenModal(!openModal)
  }

  return (
    <>
      <Row className={classes.buttonRow}>
        <Button
          className={classes.button}
          textColor="white"
          text="CHOOSE ORDER"
          tx="returns.exchange.page.choose.button.name"
          textPreset="h5"
          preset="primary"
          onClick={handleOnChooseOrderClick}
        />
      </Row>
      {openModal && (
        <ChooseOrderModal
          open={openModal}
          onClose={handleOnChooseOrderClick}
          orders={orders}
        />
      )}
    </>
  )
}
