import React, { FC } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { Column, Text, Modal, Button, Row } from '../../components'
import background from '../../assets/images/all_right.png'

import { useStyle } from './confirmation.styles'
import { ConfirmationProps } from './confirmation.types'

export const ConfirmationModal: FC<ConfirmationProps> = ({
  title,
  txTitle,
  text,
  tx,
  buttonText,
  buttonTx,
  open,
  onClose
}) => {
  const classes = useStyle()

  if (!open) {
    return <></>
  }
  const titlePreset = isMobileOnly ? 'h1Mobile' : 'h1'

  return (
    <Modal title="Confirmation" titleTx="confirmation.title" onClose={onClose}>
      <Column
        fullWidth
        className={classes.container}
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <img className={classes.image} src={background} />
        <Column fullWidth className={classes.column}>
          <Text
            className={classes.title}
            preset={titlePreset}
            color="darkBlue"
            text={title}
            tx={txTitle}
          />
          <Text
            className={classes.text}
            preset="body"
            color="inactive"
            text={text}
            tx={tx}
          />
        </Column>
        {buttonText && (
          <Row fullWidth className={classes.buttonRow}>
            <Button
              color="yellow"
              preset="secondaryMedium"
              textColor="white"
              textPreset="maxButton"
              text={buttonText}
              tx={buttonTx}
              onClick={onClose}
            />
          </Row>
        )}
      </Column>
    </Modal>
  )
}
