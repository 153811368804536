import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'
import { ProductsCategoryFilterStyles } from './products-category-filter.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  filterCategory: {
    display: 'flex',
    columnGap: '80px',
    padding: ({ background }: ProductsCategoryFilterStyles) =>
      background && '0 16px',
    backgroundColor: ({ background }: ProductsCategoryFilterStyles) =>
      background && theme.colors.lightBlue,
    width: '100vw',
    maxWidth: 'unset',
    marginTop: ({ background }: ProductsCategoryFilterStyles) =>
      background && 24,
    overflowX: 'hidden',
    '& span': {
      boxSizing: 'border-box',
      cursor: 'pointer'
    }
  },
  selected: {
    textTransform: 'uppercase',
    padding: '14px 0',
    borderBottom: ({ background }: ProductsCategoryFilterStyles) =>
      background && `2px solid ${theme.colors.yellow}`
  },
  notSelected: {
    textTransform: 'uppercase',
    padding: '14px 0',
    color: theme.colors.inactive,
    opacity: 0.87,
    borderBottom: '2px solid transparent'
  },

  '@media screen and (min-width: 481px) and (max-width: 1023px)': {
    filterCategory: {
      columnGap: 60
    }
  },
  '@media screen and (max-width: 480px)': {
    filterCategory: {
      columnGap: 14,
      padding: '0 12px',
      justifyContent: 'space-between',
      borderTop: ({ background }: ProductsCategoryFilterStyles) =>
        background && `1px solid ${theme.colors.shadow}`,
      borderBottom: ({ background }: ProductsCategoryFilterStyles) =>
        background && `1px solid ${theme.colors.shadow}`,
      overflowX: 'auto',
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    },
    selected: {
      padding: '14px 12px'
    },
    notSelected: {
      padding: '14px 12px'
    }
  }
}))
