import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  date: {
    padding: '0px 0px 16px',
    columnGap: 24
  },
  field: {
    marginLeft: 8
  }
})
