import React, { FC } from 'react'

import { Column, Text } from '../../../components'

import { useStyle } from './details-block-subitem.styles'
import { DetailsBlockSubitemProps } from './details-block-subitem.types'

export const DetailsBlockSubitem: FC<DetailsBlockSubitemProps> = ({
  className = '',
  children,
  text,
  tx
}) => {
  const classes = useStyle()
  return (
    <Column
      fullWidth
      className={className}
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Text text={text} tx={tx} preset="h5" />
      <Column fullWidth alignItems="flex-start" className={classes.options}>
        {children}
      </Column>
    </Column>
  )
}
