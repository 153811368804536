import React, { FC } from 'react'

import { getCurrencyByProduct } from '../../helpers'
import { Column } from '../../column'
import { Row } from '../../row'

import { OfferListInfoRow } from '../offers-list-info-row'

import { useStyle } from './offers-list-item-line.styles'
import { OfferListItemLineProps } from './offers-list-item-line.types'

export const OfferListItemLine: FC<OfferListItemLineProps> = ({
  offer,
  className = ''
}) => {
  const classes = useStyle()

  const {
    product,
    quantity,
    discount,
    deliveryPrice,
    productionDays,
    productTotalPrice
  } = offer

  const offerProductionDays = productionDays !== null ? productionDays : 0
  const currency = getCurrencyByProduct(product)

  return (
    <Column fullWidth className={className}>
      <Row
        fullWidth
        className={classes.line}
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Column
          fullWidth
          justifyContent="space-between"
          alignItems="flex-start"
          className={classes.infoRow}
        >
          <OfferListInfoRow
            className={classes.column}
            title="Production Days:"
            titleTx="offer.line.production.days"
            price={offerProductionDays}
          />
          <OfferListInfoRow
            className={classes.column}
            title="Item Quantity:"
            titleTx="offer.line.units.quantity"
            price={quantity}
          />
          <OfferListInfoRow
            className={classes.column}
            title="Offer Price:"
            titleTx="offer.line.offer.price"
            price={productTotalPrice - discount}
            currency={currency}
          />
          <OfferListInfoRow
            className={classes.column}
            title="Delivery Price"
            titleTx="order.line.delivery.price"
            price={deliveryPrice}
            currency={currency}
          />
        </Column>
      </Row>
    </Column>
  )
}
