import moment from 'moment'

import { ProductCardType, ProductType } from '../components'
import { Edge, GetFeaturedProducts, Product, ProductCard } from '../services'

export const getFormatedOrderDate = (date: Date | null) => {
  if (date) {
    return moment(date).format('MM.DD.YYYY')
  }

  return '__.__.____'
}

export const getProductTypeFromProduct = (product: Product): ProductType => ({
  ...product,
  image: product.thumbnail2x?.url,
  minCurrency: product.pricing.priceRange.start.gross.currency,
  maxCurrency: product.pricing.priceRange.stop.gross.currency,
  minPrice: product.pricing.priceRangeUndiscounted.start.gross.amount,
  maxPrice: product.pricing.priceRangeUndiscounted.stop.gross.amount,
  discountMinPrice: product.pricing.priceRange.start.gross.amount,
  discountMaxPrice: product.pricing.priceRange.stop.gross.amount,
  isSale: product.pricing.onSale,
  thumbnail2x: product.thumbnail2x?.url
})

export const getCardProductTypeFromProduct = (
  product: ProductCard
): ProductCardType => ({
  ...product,
  image: product.thumbnail2x?.url,
  minCurrency: product.pricing.priceRange.start.gross.currency,
  maxCurrency: product.pricing.priceRange.stop.gross.currency,
  minPrice: product.pricing.priceRangeUndiscounted.start.gross.amount,
  maxPrice: product.pricing.priceRangeUndiscounted.stop.gross.amount,
  discountMinPrice: product.pricing.priceRange.start.gross.amount,
  discountMaxPrice: product.pricing.priceRange.stop.gross.amount,
  isSale: product.pricing.onSale,
  thumbnail2x: product.thumbnail2x?.url
})

export const getProductTypeFromEdgeProduct = ({
  node: product
}: Edge<Product>): ProductType => getProductTypeFromProduct(product)

export const reduceFeatureProductToProductList = (
  data: GetFeaturedProducts
): ProductType[] =>
  data.shop.homepageCollection.products.edges.map(getProductTypeFromEdgeProduct)

export const reduceProductEdgesToProductList = (
  data: Edge<ProductCard>[]
): ProductCardType[] =>
  data.map(({ node }) => getCardProductTypeFromProduct(node))

export const reduceProductsToProductList = (
  data: ProductCard[]
): ProductCardType[] => data.map(getCardProductTypeFromProduct)

export const reduceRecommendedProductsToProductList = (
  data: GetFeaturedProducts
): ProductType[] =>
  data.shop.homepageCollection.products.edges.map(getProductTypeFromEdgeProduct)

export const capitalizedName = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export const priceFilterValue = (value: string, currency?: string) => {
  const min = value.split(',')[0]
  const max = value.split(',')[1]

  if (currency) {
    return `${currency}${min} - ${currency}${max}`
  }

  return `${min} - ${max}`
}
