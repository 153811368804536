import React, { FC } from 'react'

import { Row, Column, VendorDetailsItem } from '../../../../components'

import { VendorSummaryInfoProps } from './vendor-summary-info.types'
import { useStyle } from './vendor-info.styles'

export const VendorSummaryInfo: FC<VendorSummaryInfoProps> = ({
  activity,
  averageResponseTime,
  positiveFeedback,
  dateJoined
}) => {
  const classes = useStyle()

  return (
    <Row
      className={classes.info}
      fullWidth
      alignItems="flex-start"
      justifyContent="space-evenly"
      wrap
    >
      <Column
        alignItems="flex-start"
        justifyContent="flex-start"
        fullHeight
        className={classes.vendorInfo}
      >
        {/* TODO: use when status change will be implemented */}
        {/* <VendorDetailsItem
          className={classes.vendorStatuses}
          title="Status:"
          titleTx="vendor.info.status"
          value={status || '-'}
        /> */}
        <VendorDetailsItem
          className={classes.vendorStatuses}
          title="Activity on the site:"
          titleTx="vendor.listItem.activity"
          value={activity}
        />
        <VendorDetailsItem
          className={classes.vendorStatuses}
          title="Average response time:"
          titleTx="vendor.listItem.averageResponseTime"
          value={averageResponseTime || '-'}
        />
      </Column>
      <Column
        alignItems="flex-start"
        justifyContent="flex-start"
        fullHeight
        className={classes.vendorInfo}
      >
        <VendorDetailsItem
          className={classes.vendorStatuses}
          title="Positive Feedback:"
          titleTx="vendor.info.feedback"
          value={positiveFeedback}
        />
        <VendorDetailsItem
          className={classes.vendorStatuses}
          title="Ovaflopick data Since:"
          titleTx="vendor.info.dataJoined"
          value={dateJoined || '-'}
        />
      </Column>
    </Row>
  )
}
