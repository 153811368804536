import React, { FC, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useSwipeable } from 'react-swipeable'
import { isMobile } from 'react-device-detect'

import { getPathByCategory } from '../../../utils'

import { Row } from '../../row'
import { Text } from '../../text'
import { Column } from '../../column'
import { CategoryCard } from '../../category-card'

import { CategoryListProps } from '../category-list.types'

import { useStyle } from './category-list-vendor-mobile.styles'

export const CategoryListVendorMobile: FC<CategoryListProps> = ({
  className = '',
  carouselClassName = '',
  children,
  data,
  description,
  descriptionTx,
  title,
  titlePreset,
  titleTx,
  categoryBreadcrumb
}) => {
  const scrollContainer = useRef<HTMLDivElement>(null)

  const classes = useStyle()
  const handleOnClickLeft = () => {
    const scrollElement = scrollContainer.current
    if (scrollElement) {
      const scrollWidth = scrollElement.offsetWidth / 1.5
      const leftPosition = scrollElement.scrollLeft - scrollWidth
      scrollElement.scroll({ left: leftPosition, behavior: 'smooth' })
    }
  }

  const handleOnClickRight = () => {
    const scrollElement = scrollContainer.current
    if (scrollElement) {
      const scrollWidth = scrollElement.offsetWidth / 1.5
      const leftPosition = scrollElement.scrollLeft + scrollWidth
      scrollElement.scroll({ left: leftPosition, behavior: 'smooth' })
    }
  }

  const swipeableConfig = {
    delta: 20,
    preventDefaultTouchmoveEvent: false,
    trackTouch: isMobile,
    trackMouse: true,
    rotationAngle: 0
  }

  const handlers = useSwipeable({
    onSwipedLeft: handleOnClickRight,
    onSwipedRight: handleOnClickLeft,
    ...swipeableConfig
  })
  return (
    <Column fullWidth className={className}>
      <Text color="black" preset={titlePreset} text={title} tx={titleTx} />
      {description && (
        <Text
          className={classes.description}
          color="inactive"
          preset="h7"
          text={description}
          tx={descriptionTx}
        />
      )}
      {children}
      <Column fullWidth {...handlers}>
        <Row
          fullWidth
          justifyContent="flex-start"
          className={`${carouselClassName} ${classes.carouselContainer}`}
          ref={scrollContainer}
        >
          {data.map(
            ({ id, name, backgroundImage, children: categoryChildren }) => {
              const pathname = getPathByCategory(id, categoryChildren.edges)
              const to = {
                pathname,
                state: { categoryBreadcrumb }
              }

              return (
                <Link
                  key={`category${id}`}
                  className={classes.category}
                  to={to}
                >
                  <CategoryCard
                    id={id}
                    name={name}
                    backgroundImage={backgroundImage}
                  />
                </Link>
              )
            }
          )}
        </Row>
      </Column>
    </Column>
  )
}
