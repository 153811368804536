import React, { FC, useState } from 'react'

import { Column } from '../column'
import { ConstructorCheckboxItem } from '../constructor-checkbox-item'
import { useStyle } from './constructor-checkbox-box.styles'
import { ConstructorCheckboxBoxProps } from './constructor-checkbox-box.types'

export const ConstructorCheckboxBox: FC<ConstructorCheckboxBoxProps> = ({
  className = '',
  data,
  type,
  itemClassName = '',
  onClick
}) => {
  const classes = useStyle()
  const [checked, changeChecked] = useState<string[]>([])

  const getChecked = (id: string) => {
    const activeOption = checked.find((item) => item === id)

    return Boolean(activeOption)
  }

  const handleOnChangeChecked = (id: string, state: boolean) => {
    const filteredIds = checked.filter((itemId) => itemId !== id)
    if (state) {
      changeChecked([...filteredIds, id])
      if (onClick) {
        onClick([...filteredIds, id])
      }
    } else {
      changeChecked(filteredIds)
      if (onClick) {
        onClick(filteredIds)
      }
    }
  }

  return (
    <Column fullWidth alignItems="flex-start" className={className}>
      {data &&
        data.map((item) => (
          <ConstructorCheckboxItem
            {...item}
            key={`radioBox_${item.id}`}
            id={item.id}
            type={type}
            className={`${itemClassName} ${classes.item}`}
            checked={getChecked(item.id)}
            onChange={handleOnChangeChecked}
          />
        ))}
    </Column>
  )
}
