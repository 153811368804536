import React, { FC, useMemo, useState } from 'react'

import { OrderUpdateVariables } from '../../../../services'
import { DeclineOrderModal } from '../../../../views'

import { Button } from '../../../button'
import { Row } from '../../../row'
import { Column } from '../../../column'
import {
  getAvaliableToDecline,
  getAvaliableToDelivery,
  getFullName
} from '../../../helpers'

import {
  OrderContactInfo,
  OrderContactInfoProps
} from '../../order-contact-info'

import { OrderSliderButtonsProps } from './order-slider-buttons.types'
import { useStyle } from './order-slider-buttons.styles'

export const OrderSliderButtons: FC<OrderSliderButtonsProps> = ({
  id,
  mainStatus,
  vendor,
  number,
  price,
  paymentStatus,
  onContactVendor,
  onChangeStatus,
  onOpenDelivery
}) => {
  const [openDeclineModal, changeOpenDeclineModal] = useState(false)

  const classes = useStyle()

  const declineIsEnabled = getAvaliableToDecline(mainStatus)

  const VendorFullName = useMemo(() => getFullName(vendor), [vendor])
  const isAvailableToDelivery = useMemo(
    () => getAvaliableToDelivery(mainStatus),
    [mainStatus]
  )

  const handleOnChangeDeclineModal =
    (state: boolean): OrderContactInfoProps['onButtonClick'] =>
    (event) => {
      event.preventDefault()
      event.stopPropagation()

      changeOpenDeclineModal(state)
    }

  const handleOnSubmit = () => {
    const variables: OrderUpdateVariables = {
      id,
      input: {
        isCanceled: true
      }
    }

    if (onChangeStatus) {
      onChangeStatus(variables)
    }

    changeOpenDeclineModal(false)
  }

  return (
    <Column className={classes.container}>
      <Row fullWidth justifyContent="space-between">
        {vendor && (
          <OrderContactInfo
            type="vendor"
            title="Vendor Info:"
            titleTx="order.item.vendorInfo"
            country={vendor.country}
            companyName={vendor.companyName}
            email={vendor.email}
            city={vendor.city}
            fullName={VendorFullName}
            phoneNumber={vendor.phone}
            buttonTitle="Contact Maker"
            buttonTx="order.item.contact.maker"
            onButtonClick={onContactVendor}
          />
        )}
        {declineIsEnabled && (
          <Button
            color="darkBlue"
            className={classes.buttonBorder}
            preset="secondaryBorder"
            textColor="darkBlue"
            textPreset="maxButton"
            text="Decline Order"
            tx="order.item.declineOrder"
            onClick={handleOnChangeDeclineModal(true)}
          />
        )}
      </Row>

      <DeclineOrderModal
        open={openDeclineModal}
        orderNumber={number}
        totalPrice={price}
        paymentStatus={paymentStatus}
        onClose={handleOnChangeDeclineModal(false)}
        onSubmit={handleOnSubmit}
      />
      {isAvailableToDelivery && (
        <Button
          className={classes.deliveryButton}
          preset="secondary"
          color="yellow"
          text="Delivery Tracking"
          textColor="white"
          textPreset="maxButton"
          onClick={onOpenDelivery}
        />
      )}
    </Column>
  )
}
