import React, { FC, useMemo, useState } from 'react'

import { useDefaultValue } from '../../hooks'

import { Column } from '../column'
import { Modal } from '../modal'
import { RadioItem, RadioItemProps } from '../radio-item'

import { useStyle } from './mobile-picker-menu.styles'
import { MobilePickerMenuProps, PickerItem } from './mobile-picker-menu.types'

export const MobilePickerMenu: FC<MobilePickerMenuProps> = ({
  data,
  name,
  open,
  title,
  titleTx,
  value: outerValue,
  defaultValue,
  onClose,
  onChange
}) => {
  const [value, changeValue] = useState(defaultValue)

  const Value = useDefaultValue({ outerValue, value })
  const classes = useStyle()

  const GetActive = useMemo(() => (active: string) => active === Value, [Value])

  const handleOnChange =
    (item: PickerItem): RadioItemProps['onClick'] =>
    () => {
      changeValue(item.id)

      if (onChange) {
        onChange(item)
      }
    }

  if (!open) {
    return <></>
  }

  return (
    <Modal title={title} titleTx={titleTx} onClose={onClose}>
      <Column className={classes.content} alignItems="flex-start">
        {data.map((pickerItem) => (
          <RadioItem
            key={`mobile_picker_itme_${pickerItem.id}`}
            className={classes.radioItem}
            {...pickerItem}
            name={name}
            active={GetActive(pickerItem.id)}
            onClick={handleOnChange(pickerItem)}
          />
        ))}
      </Column>
    </Modal>
  )
}
