import { useMutation, useQuery } from '@apollo/client'

import { Edge } from '../api.types'

import {
  OfferApi,
  GetOffers,
  GetOffersData,
  OfferUpdateVariables,
  GetOfferUpdateResults,
  OfferUpdate,
  OfferCreate,
  GetOfferCreateResults,
  OfferCreateVariables,
  OfferByIdVariables,
  GetOffer,
  GetOfferByIdRequest,
  CreateOrderFromOffer,
  GetCreateOrderFromOfferResults,
  CreateOrderFromOfferVariables,
  OfferVariables,
  OfferStatus,
  Offer,
  CreateOrderFromOfferList,
  GetCreateOrderFromOfferListResults,
  CreateOrderFromOfferListVariables,
  GetOffersByIdData,
  GetOffersById
} from './offer.types'
import {
  OFFERS_QUERY,
  OFFER_UPDATE_MUTATION,
  OFFER_CREATE_MUTATION,
  OFFER_BY_ID,
  CREATE_ORDER_FROM_OFFER_MUTATION,
  CREATE_ORDER_FROM_OFFER_LIST_MUTATION,
  OFFERS_BY_ID
} from './offer.graphql'

export const offerService = (): OfferApi => {
  const useOffers = (variables: OfferVariables): GetOffers => {
    const { data, loading, error, refetch, fetchMore } = useQuery<
      GetOffersData,
      OfferVariables
    >(OFFERS_QUERY, { variables })

    return { data, error, loading, refetch, fetchMore }
  }

  const useOffersById = (variables: OfferVariables): GetOffersById => {
    const { data, loading, error, refetch, fetchMore } = useQuery<
      GetOffersByIdData,
      OfferVariables
    >(OFFERS_BY_ID, { variables, fetchPolicy: 'network-only' })

    return { data, error, loading, refetch, fetchMore }
  }

  const useOfferCreate = (): OfferCreate => {
    const [onOfferCreate, response] = useMutation<
      GetOfferCreateResults,
      OfferCreateVariables
    >(OFFER_CREATE_MUTATION)

    const handleOnSubmit = (variables: OfferCreateVariables) => {
      const options = {
        variables
      }
      onOfferCreate(options)
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useOfferUpdate = (): OfferUpdate => {
    const [onOfferUpdate, response] = useMutation<
      GetOfferUpdateResults,
      OfferUpdateVariables
    >(OFFER_UPDATE_MUTATION)

    const handleOnSubmit = (variables: OfferUpdateVariables) => {
      const options = {
        variables
      }
      onOfferUpdate({
        ...options,
        update(cache, result) {
          const queryResult = cache.readQuery<GetOffersData>({
            query: OFFERS_QUERY
          })
          if (queryResult) {
            const isDeclined = variables.input.status === OfferStatus.DECLINED

            cache.writeQuery<GetOffersData>({
              query: OFFERS_QUERY,
              data: {
                ...queryResult,
                offers: {
                  ...queryResult.offers,
                  totalCount: queryResult.offers.totalCount - 1,
                  edges: queryResult.offers.edges.reduce<Edge<Offer>[]>(
                    (acc, item) => {
                      if (item.node.id === result.data?.offerUpdate.offer.id) {
                        if (isDeclined) {
                          return acc
                        }

                        return [
                          ...acc,
                          {
                            ...item,
                            node: {
                              ...item.node,
                              ...result.data.offerUpdate.offer
                            }
                          }
                        ]
                      }

                      return [...acc, item]
                    },
                    []
                  )
                }
              }
            })
          }
        }
      })
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useOfferById = (variables: OfferByIdVariables): GetOfferByIdRequest => {
    const { data, loading, error, refetch } = useQuery<
      GetOffer,
      OfferByIdVariables
    >(OFFER_BY_ID, { variables })

    if (error) {
      return { data: null, loading, refetch }
    }

    if (!data) {
      return { data: null, loading, refetch }
    }

    return { data, loading, refetch }
  }

  const useCreateOrderFromOffer = (): CreateOrderFromOffer => {
    const [onCreateOrderFromOffer, response] = useMutation<
      GetCreateOrderFromOfferResults,
      CreateOrderFromOfferVariables
    >(CREATE_ORDER_FROM_OFFER_MUTATION)

    const handleOnSubmit = (variables: CreateOrderFromOfferVariables) => {
      const options = {
        variables
      }
      onCreateOrderFromOffer(options)
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useCreateOrderFromOfferList = (): CreateOrderFromOfferList => {
    const [onCreateOrderFromOfferList, response] = useMutation<
      GetCreateOrderFromOfferListResults,
      CreateOrderFromOfferListVariables
    >(CREATE_ORDER_FROM_OFFER_LIST_MUTATION)

    const handleOnSubmit = (variables: CreateOrderFromOfferListVariables) => {
      const options = {
        variables
      }
      onCreateOrderFromOfferList(options)
    }

    return { onSubmit: handleOnSubmit, response }
  }

  return {
    useOffers,
    useOfferUpdate,
    useOfferCreate,
    useOfferById,
    useOffersById,
    useCreateOrderFromOffer,
    useCreateOrderFromOfferList
  }
}
