import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {},
  banner: {},
  carouselContainer: {
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  imgContainer: {
    width: '100%'
  },
  dot: {
    '&:not(:first-child)': {
      marginLeft: 8
    }
  },
  images: {
    overflowX: 'hidden'
  },
  image: {
    width: 'calc(100vw - 24px)',
    height: 300,
    padding: '0 12px',
    display: 'block',
    objectFit: 'cover'
  },
  dotContainer: {
    marginTop: 8
  },
  '@media screen and (min-width:600px)': {
    carouselContainer: {
      width: 544
    },
    image: {
      width: 544,
      height: 488,
      padding: 0
    }
  }
})
