import { createUseStyles } from 'react-jss'
import { StyleProps } from './number-switcher-mobile.types'
import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {},

  minus: {
    width: 24,
    height: 24,
    padding: 4,
    marginRight: 16,
    backgroundColor: theme.colors.white,
    boxShadow: `1px 2px 8px ${theme.colors.shadow}`,
    borderRadius: '50%',
    '& > path': {
      fill: ({ numberValue }: StyleProps) =>
        numberValue ? theme.colors.black : theme.colors.shadow
    }
  },
  plus: {
    width: 24,
    height: 24,
    padding: 4,
    marginLeft: 16,
    backgroundColor: theme.colors.white,
    boxShadow: `1px 2px 8px ${theme.colors.shadow}`,
    borderRadius: '50%'
  }
}))
