import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'
import {
  Column,
  Text,
  ProductList,
  VendorsLine,
  CategoryLine,
  getListFromEdgesList,
  arrayHasElements
} from '../../components'
import { useApi } from '../../providers'
import { reduceProductEdgesToProductList } from '../../views'
import { loadMorePagination } from '../../utils'

import { useStyle } from './search-results.styles'
import { SearchResultsPageParams } from './search-results.types'

const SEARCH_ITEMS_COUNT = 10

export const SearchResultsPage = () => {
  const { value } = useParams<SearchResultsPageParams>()
  const { category, product, vendor } = useApi()

  const defaultVariables = {
    first: SEARCH_ITEMS_COUNT,
    filter: { search: value }
  }

  const { data: products, fetchMore: fetchMoreProducts } =
    product.useProductsGlobalSearch(defaultVariables)
  const { data: categories, fetchMore: fetchMoreCategories } =
    category.useCategoriesGlobalSearch(defaultVariables)
  const { data: vendors, fetchMore: fetchMoreVendors } =
    vendor.useVendorGlobalSearch(defaultVariables)

  useEffect(() => {}, [])

  const classes = useStyle()
  const titlePreset = isMobileOnly ? 'h1Mobile' : 'h1'
  const h2Preset = isMobileOnly ? 'h2Mobile' : 'h2'

  const Products = useMemo(() => {
    if (products && value) {
      return reduceProductEdgesToProductList(
        products.productsGlobalSearch.edges
      )
    }

    return []
  }, [products])

  const Categories = useMemo(
    () => getListFromEdgesList(categories?.categoriesGlobalSearch),
    [categories]
  )

  const Vendors = useMemo(() => {
    if (vendors && value) {
      return getListFromEdgesList(vendors.vendorsGlobalSearch)
    }

    return []
  }, [vendors])

  const hasProducts = arrayHasElements(Products)
  const hasCategories = arrayHasElements(Categories)
  const hasVendors = arrayHasElements(Vendors)

  const handleOnLoadProducts = () => {
    loadMorePagination(
      defaultVariables,
      products?.productsGlobalSearch,
      fetchMoreProducts
    )
  }

  const handleOnLoadCategories = () => {
    loadMorePagination(
      defaultVariables,
      categories?.categoriesGlobalSearch,
      fetchMoreCategories
    )
  }

  const handleOnLoadVendors = () => {
    loadMorePagination(
      defaultVariables,
      vendors?.vendorsGlobalSearch,
      fetchMoreVendors
    )
  }

  return (
    <Column fullWidth className={classes.container}>
      <Text
        className={classes.title}
        color="black"
        text="Search Results"
        preset={titlePreset}
      />

      {hasProducts && (
        <ProductList
          carousel
          className={classes.list}
          data={Products}
          title="Products"
          titleTx="sidebar.products.title"
          titlePreset={h2Preset}
          onLoadNext={handleOnLoadProducts}
        />
      )}

      {hasCategories && (
        <CategoryLine
          carousel
          className={classes.list}
          data={Categories}
          title="Categories"
          titleTx="sidebar.categories.title"
          titlePreset={h2Preset}
          onLoadNext={handleOnLoadCategories}
        />
      )}

      {hasVendors && (
        <VendorsLine
          carousel
          className={classes.list}
          data={Vendors}
          title="Makers / Designers"
          titleTx="sidebar.vendors.title"
          titlePreset={h2Preset}
          onLoadNext={handleOnLoadVendors}
        />
      )}
    </Column>
  )
}
