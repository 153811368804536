import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  loginTabs: {
    display: 'flex',
    width: '100vw',
    backgroundColor: theme.colors.lightBlue,
    marginBottom: 24
  },
  inactive: {
    width: '50%',
    margin: '0 12px',
    '& > span': {
      flexGrow: 1,
      width: '100%',
      textAlign: 'center',
      padding: '1rem'
    }
  },
  active: {
    width: '50%',
    margin: '0 12px',
    borderBottom: '4px solid',
    borderBottomColor: theme.colors.yellow,
    '& > span': {
      flexGrow: 1,
      width: '100%',
      textAlign: 'center',
      padding: '1rem'
    }
  }
}))
