import React, { useState, useMemo, FC } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useLocation } from 'react-router-dom'

import {
  Content,
  Row,
  Column,
  FilterSidebar,
  ProductsCategoryFilter,
  CategoryBreadcrumbs,
  ResponsiveMediaView,
  Text,
  FilterResultsData,
  arrayHasElements,
  ProductFilterKeys
} from '../../components'
import { CoreItem, ProductFilter } from '../../services'
import { useApi } from '../../providers'
import { MainRoutes } from '../../routes'

import { MobileProductsHeader } from '../products/mobile-products-header'
import { TabletProductsHeader } from '../products/tablets-products-header'

import { ProductsView } from '../products'

import { useStyle } from './products-view.styles'
import { ProductViewProps } from './products-view.types'
import { FilterResultRow } from './filter-result-row'
import { CoreItemWithType } from './filter-result-item/filter-result-item.types'

export const ProductView: FC<ProductViewProps> = ({
  id: subcategoryId,
  category,
  withCategories,
  title,
  titleTx,
  loading,
  data,
  totalCount,
  filtersData,
  onChangeFilter,
  onLoadNext,
  onSort
}) => {
  const location = useLocation()
  const { menu } = useApi()
  const categories = menu.useMainMenu()
  const [filters, changeFilters] = useState<Partial<ProductFilter>>({})
  const [filtersResultsData, changeFiltersResultsData] =
    useState<FilterResultsData>(new Map())
  const [isReset, changeIsReset] = useState(false)
  const isShowTitle = location.pathname.includes(`${MainRoutes.CATEGORY}/`)

  const classes = useStyle({ subcategoryId })
  const titlePreset = isMobileOnly ? 'h1Mobile' : 'h1'

  const isShowFilter =
    totalCount >= 0 &&
    totalCount >= data.length &&
    filtersData.companyNames.length > 0

  const Categories = useMemo(() => {
    if (categories && categories.data) {
      return categories.data.shop.navigation.main.items
    }

    return []
  }, [categories])

  const handleOnFilterChange = (value: ProductFilter) => {
    changeIsReset(false)
    changeFilters(value)

    if (onChangeFilter) {
      onChangeFilter(value)
    }
  }

  const handleOnFilterResultsChange = (value: FilterResultsData) => {
    changeFiltersResultsData(value)
  }

  const handleOnResetFilters = () => {
    handleOnFilterChange({})

    changeFiltersResultsData(new Map())
    changeIsReset(true)
  }

  const handleOnRemoveItemFromFilters = (
    type: ProductFilterKeys,
    values?: CoreItem[]
  ) => {
    if (Array.isArray(values)) {
      const filtersByTypeIds = values.map((filterItem) => filterItem.id)

      const nextFilters = {
        ...filters,
        [type]: filtersByTypeIds
      }

      handleOnFilterChange(nextFilters)
    } else {
      const nextFilters = {
        ...filters,
        [type]: values
      }
      handleOnFilterChange(nextFilters)
    }
  }

  const handleOnRemoveItem = (value: CoreItemWithType) => {
    const { id, type } = value
    const filtersByType = filtersResultsData.get(type) || []

    const updatedFiltersResultsByType = filtersByType.filter(
      (filter) => filter.id !== id
    )

    const updatedFilterResultsData = filtersResultsData
    if (arrayHasElements(updatedFiltersResultsByType)) {
      handleOnRemoveItemFromFilters(type, updatedFiltersResultsByType)

      updatedFilterResultsData.set(type, updatedFiltersResultsByType)
    } else {
      handleOnRemoveItemFromFilters(type, undefined)
      updatedFilterResultsData.delete(type)
    }

    changeFiltersResultsData(updatedFilterResultsData)
  }

  const handleOnChangeCategory = (id: string) => {
    if (id) {
      const nextState = {
        ...filters,
        categories: [id]
      }
      handleOnFilterChange(nextState)
    } else {
      const nextState = {
        ...filters,
        categories: undefined
      }
      handleOnFilterChange(nextState)
    }
  }

  const handleOnLoadNext = () => {
    if (onLoadNext) {
      onLoadNext(filters)
    }
  }

  const MobileFilter = (
    <MobileProductsHeader
      count={totalCount}
      isShowTitle={isShowTitle}
      reset={isReset}
      filters={filtersData}
      filtersResults={filtersResultsData}
      onChangeFilters={handleOnFilterChange}
      onChangeFilterItems={handleOnFilterResultsChange}
      onResetFilters={handleOnResetFilters}
      onRemoveItem={handleOnRemoveItem}
      onSort={onSort}
    />
  )

  const TabletFilter = (
    <TabletProductsHeader
      className={classes.tabletHeader}
      count={totalCount}
      isShowTitle={isShowTitle}
      reset={isReset}
      filters={filtersData}
      title={title}
      filtersResults={filtersResultsData}
      onChangeFilters={handleOnFilterChange}
      onChangeFilterItems={handleOnFilterResultsChange}
      onResetFilters={handleOnResetFilters}
      onRemoveItem={handleOnRemoveItem}
      onSort={onSort}
    />
  )

  const Filter = (
    <FilterSidebar
      className={`${classes.hiddenScroll} ${classes.scroll}`}
      filtersResults={filtersResultsData}
      filters={filtersData}
      reset={isReset}
      onChangeFilters={handleOnFilterChange}
      onChangeFilterItems={handleOnFilterResultsChange}
    />
  )

  const FilterResultsRow = filtersResultsData.size > 0 && (
    <FilterResultRow
      data={filtersResultsData}
      onResetFilters={handleOnResetFilters}
      onRemoveItem={handleOnRemoveItem}
    />
  )

  return (
    <Content justifyContent="flex-start">
      {category && (
        <CategoryBreadcrumbs
          active
          className={classes.breadcrumbs}
          category={category}
        />
      )}

      {!isShowTitle && (
        <Text
          className={classes.title}
          preset={titlePreset}
          text={title}
          tx={titleTx}
        />
      )}

      {withCategories && (
        <ProductsCategoryFilter
          data={Categories}
          onChange={handleOnChangeCategory}
        />
      )}
      <ResponsiveMediaView mobile={MobileFilter} tablet={TabletFilter} />
      <ResponsiveMediaView
        bigScreen={FilterResultsRow}
        medium={FilterResultsRow}
      />

      <Row fullWidth justifyContent="flex-start" alignItems="flex-start">
        {isShowFilter && (
          <ResponsiveMediaView medium={Filter} bigScreen={Filter} />
        )}
        <Column
          className={`${classes.productsContainer} ${classes.scroll} ${classes.hiddenScroll}`}
          justifyContent="flex-start"
        >
          <ProductsView
            loading={loading}
            count={totalCount}
            data={data}
            title="Filters"
            titleTx="header.filter"
            onLoadNext={handleOnLoadNext}
            onSort={onSort}
          />
        </Column>
      </Row>
    </Content>
  )
}
