import React from 'react'
import { useHistory } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'
import {
  Button,
  Column,
  Text,
  Content,
  GoBackContainer,
  Row,
  OrderPreviewTable,
  getCurrencyByProduct,
  ProductInfoBottomBar,
  ResponsiveMediaView
} from '../../components'
import { useBasket } from '../../providers'
import { MainRoutes } from '../../routes'
import { EmptyList } from '../../views'

import { getPriceToFixed } from '../helper'

import { useStyle } from './order-preview.styles'
import { getTotalPriceByVendor } from './helpers'

export const OrderPreviewPage = () => {
  const classes = useStyle()
  const history = useHistory()
  const { BasketByVendor, onRemoveBasketByVendor } = useBasket()

  const handleOnClick = () => {
    history.push(MainRoutes.DELIVERY_INFO)
  }

  const handleOnClickHome = () => {
    history.push('/')
  }
  const handleGoBack = () => history.goBack()

  const data = Array.from(BasketByVendor.values())
  const isEmptyList = data.length === 0
  const titlePreset = isMobileOnly ? 'h1Mobile' : 'h1'

  if (isEmptyList) {
    return (
      <Column fullWidth>
        <EmptyList
          title="Oops! No Order"
          titleTx="order.preview.empty.title"
          text="Go find the product you like."
          tx="order.preview.empty.text"
        />
        <Button
          className={classes.emptyButton}
          color="yellow"
          preset="primary"
          textColor="white"
          textPreset="h5"
          text="HOME"
          tx="error.404.button.text"
          onClick={handleOnClickHome}
        />
      </Column>
    )
  }

  return (
    <Column fullWidth>
      <Column className={classes.previewPage}>
        {!isEmptyList && (
          <GoBackContainer
            text="Back to Shopping Cart"
            textTx="order.preview.back.to.shopping.cart"
            onClick={handleGoBack}
          />
        )}
      </Column>
      <Content justifyContent="flex-start">
        <Column className={classes.container}>
          <Text
            className={classes.text}
            text="Order Preview"
            tx="order.preview.title"
            preset={titlePreset}
            color="black"
          />
          <Column fullWidth>
            <Row
              className={classes.subTitle}
              justifyContent="flex-start"
              fullWidth
            >
              <Text
                text="Items from different vendors will be delivered in separate orders."
                tx="order.preview.overview"
                preset="body"
                color="white"
              />
            </Row>

            {data.map(({ id, products, companyName }, index) => {
              const nextIndex = index + 1
              const totalPrice = getTotalPriceByVendor(products)
              const currency = getCurrencyByProduct(products[0]?.product)
              const totalPriceWithCurrency = `${currency}${getPriceToFixed(
                totalPrice
              )}`

              return (
                <Row fullWidth key={`order_preview_${id}`}>
                  <OrderPreviewTable
                    key={`order_preview_${id}`}
                    vendorId={id}
                    index={nextIndex}
                    companyName={companyName}
                    totalPrice={totalPriceWithCurrency}
                    products={products}
                    onRemoveOrder={onRemoveBasketByVendor}
                  />
                </Row>
              )
            })}
          </Column>
        </Column>
        <Row className={classes.buttonRow}>
          <Button
            className={classes.submit}
            preset="primary"
            textColor="white"
            textPreset="h5"
            text="CONTINUE"
            tx="order.preview.confirm.button"
            onClick={handleOnClick}
          />
        </Row>
        <ResponsiveMediaView
          tablet={
            <ProductInfoBottomBar
              className={classes.bottomBar}
              classNameButton={classes.button}
              buttonTitle="CONTINUE"
              buttonTitleTx="order.preview.confirm.button"
              onClick={handleOnClick}
            />
          }
        />
      </Content>
    </Column>
  )
}
