import React, { forwardRef } from 'react'
import { isMobileOnly } from 'react-device-detect'

import { Row } from '../../row'
import { Column } from '../../column'

import { useStyle } from './order-row.styles'
import { OrderRowProps } from './order-row.types'

export const OrderRow = forwardRef<HTMLDivElement, OrderRowProps>(
  ({ children, className = '', ...props }, ref) => {
    const classes = useStyle()
    const Container = isMobileOnly ? Column : Row

    return (
      <Container
        fullWidth
        className={`${className} ${classes.container}`}
        justifyContent="flex-start"
        alignItems="flex-start"
        {...props}
        ref={ref}
      >
        {children}
      </Container>
    )
  }
)
