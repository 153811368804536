import React from 'react'

import {
  Content,
  Text,
  Row,
  Loader,
  Column,
  NavLink
} from '../../../components'
import { useApi } from '../../../providers'
import { MainRoutes } from '../../../routes'

import { CategoryNav } from './catalogue-category-nav'
import { useStyle } from './catalogue-mobile.styles'

export const CatalogueMobilePage = () => {
  const {
    category: { useCatalogues }
  } = useApi()
  const classes = useStyle()
  const { data, loading } = useCatalogues()

  if (!data && loading) {
    return <Loader />
  }

  return (
    <Content className={classes.cataloguePage} justifyContent="flex-start">
      <Row>
        <Text
          text="Catalogue"
          tx="catalogue.title"
          preset="h1Mobile"
          color="black"
        />
      </Row>
      {data &&
        data.categories.edges.map((category) => (
          <Column className={classes.categoryWrapper} key={category.node.id}>
            <Column className={classes.imageBlock}>
              <img
                className={classes.img}
                src={category.node.backgroundImage.url}
                alt={category.node.backgroundImage.alt}
              />
            </Column>

            <Column
              fullWidth
              justifyContent="flex-start"
              alignItems="flex-start"
              className={classes.menuBlock}
            >
              <Row justifyContent="flex-start" className={classes.header}>
                <NavLink
                  preset="h3"
                  text={category.node.name}
                  color="black"
                  to={`${MainRoutes.CATEGORY}/${category.node.id}`}
                />
              </Row>
              <Column alignItems="flex-start" className={classes.listWrapper}>
                {category.node.children.edges.map((subcategory) => (
                  <CategoryNav key={subcategory.node.id} data={subcategory} />
                ))}
              </Column>
            </Column>
          </Column>
        ))}
    </Content>
  )
}
