import React, { FC, useState, useMemo, useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { useParams } from 'react-router-dom'

import {
  CoreItem,
  Currency,
  ProductFilter,
  PriceRangeInput,
  ProductionDaysRangeInput
} from '../../services'
import { ProductsPageParams } from '../../pages'

import { Column } from '../column'
import { Text } from '../text'
import { RangeInput } from '../range-input'
import { FilterTypeContainer } from '../filter-type-container'
import { arrayHasElements } from '../helpers'
import { FilterListByAlphabet } from '../filter-list-by-alphabet'
import { FilterList } from '../filter-list'
import { FilterListColor } from '../filter-list-color'
import { FilterStarReview } from '../filter-star-review'

import { useStyle } from './filter-sidebar.styles'
import { FilterSidebarProps, ProductFilterKeys } from './filter-sidebar.types'

export const FilterSidebar: FC<FilterSidebarProps> = ({
  className = '',
  filters: filtersData,
  filtersResults = new Map<ProductFilterKeys, CoreItem[]>(),
  reset,
  onChangeFilters,
  onChangeFilterItems
}) => {
  const classes = useStyle()
  const minStartPrice = Number(filtersData.priceRange[0]?.name) || 0
  const maxEndPrice = Number(filtersData.priceRange[1]?.name) || 1000
  const minProductionDays =
    Number(filtersData.productionDaysRange[0]?.name) || 1
  const maxroductionDays =
    Number(filtersData.productionDaysRange[1]?.name) || 180
  const { vendorId } = useParams<ProductsPageParams>()
  const vendorIdFilterValue = vendorId ? [vendorId] : []

  const defaultFiltersValue = {
    price: {
      gte: minStartPrice,
      lte: maxEndPrice
    },
    vendors: vendorIdFilterValue
  }

  const [filtersValue, changeFiltersValue] =
    useState<ProductFilter>(defaultFiltersValue)

  const handleOnPriceChange = (price: PriceRangeInput) => {
    if (onChangeFilters) {
      onChangeFilters({ ...filtersValue, price })
    }
  }

  const handleOnProductionDaysChange = (
    productionDays: ProductionDaysRangeInput
  ) => {
    if (onChangeFilters) {
      onChangeFilters({ ...filtersValue, productionDays })
    }
  }

  useEffect(() => {
    changeFiltersValue(defaultFiltersValue)
  }, [reset])

  useEffect(() => {
    const timeout = setTimeout(() => {
      const { price } = filtersValue
      const { productionDays } = filtersValue

      if (price) {
        handleOnPriceChange(price)
      }
      if (productionDays) {
        handleOnProductionDaysChange(productionDays)
      }
    }, 1000)

    return () => clearTimeout(timeout)
  }, [filtersValue.price, filtersValue.productionDays])

  const handleOnFilterChange =
    (prop: ProductFilterKeys) => (value: string[]) => {
      const nextValue = arrayHasElements(value) ? value : undefined
      const nextState = {
        ...filtersValue,
        [prop]: nextValue
      }

      changeFiltersValue(nextState)

      if (onChangeFilters) {
        onChangeFilters(nextState)
      }
    }

  const handleOnFilterItemChange =
    (prop: ProductFilterKeys) => (value: CoreItem[]) => {
      const nextState = filtersResults
      nextState.set(prop, value)

      if (onChangeFilterItems) {
        onChangeFilterItems(nextState)
      }
    }

  const handleOnChangePriceRange =
    (prop: ProductFilterKeys) => (value: number[]) => {
      const nextValue = arrayHasElements(value) ? value : undefined
      const nextState = {
        ...filtersValue,
        [prop]: {
          gte: nextValue && nextValue[0],
          lte: nextValue && nextValue[1]
        }
      }
      changeFiltersValue(nextState)
    }

  const getFilter = (prop: ProductFilterKeys) => {
    const values = filtersResults.get(prop) || []

    return values.map((item) => item.id)
  }

  const Prices = useMemo(() => {
    const values = filtersResults.get('price') || []
    const prices = values[0]?.name

    if (prices) {
      const parsedPrices = prices.split(',')
      return {
        gte: Number(parsedPrices[0]),
        lte: Number(parsedPrices[1])
      }
    }

    return {
      gte: minStartPrice,
      lte: maxEndPrice
    }
  }, [getFilter('price')])

  const ProductionDays = useMemo(() => {
    const values = filtersResults.get('productionDays') || []
    const productionDays = values[0]?.name

    if (productionDays) {
      const parsedProductionDays = productionDays.split(',')
      return {
        gte: Number(parsedProductionDays[0]),
        lte: Number(parsedProductionDays[1])
      }
    }

    return {
      gte: minProductionDays,
      lte: maxroductionDays
    }
  }, [getFilter('productionDays')])

  return (
    <Column
      className={`${className} ${classes.container}`}
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      {!isMobile && (
        <Text color="black" preset="h4" text="FILTERS" tx="header.filter" />
      )}

      {filtersValue.price && (
        <FilterTypeContainer
          className={classes.filterType}
          title="Price"
          titleTx="filter.sidebar.price"
        >
          <RangeInput
            start={minStartPrice}
            end={maxEndPrice}
            currency={Currency.USD}
            valueStart={Prices.gte}
            valueEnd={Prices.lte}
            onChange={handleOnChangePriceRange('price')}
            onChangeItem={handleOnFilterItemChange('price')}
          />
        </FilterTypeContainer>
      )}
      {arrayHasElements(filtersData.productionDaysRange) && (
        <FilterTypeContainer
          className={classes.filterType}
          title="Production days"
          titleTx="filter.sidebar.production.days"
        >
          <RangeInput
            start={minProductionDays}
            end={maxroductionDays}
            valueStart={ProductionDays.gte}
            valueEnd={ProductionDays.lte}
            onChange={handleOnChangePriceRange('productionDays')}
            onChangeItem={handleOnFilterItemChange('productionDays')}
          />
        </FilterTypeContainer>
      )}
      {arrayHasElements(filtersData.rating) && (
        <FilterTypeContainer
          className={classes.filterType}
          title="Customers review"
          titleTx="filter.sidebar.reviews"
        >
          <FilterStarReview
            className={classes.starRow}
            data={filtersData.rating}
            value={getFilter('rating')}
            onChange={handleOnFilterChange('rating')}
            onChangeItem={handleOnFilterItemChange('rating')}
          />
        </FilterTypeContainer>
      )}

      {arrayHasElements(filtersData.colors) && (
        <FilterListColor
          className={classes.filterType}
          title="Color"
          titleTx="filter.sidebar.color"
          colors={filtersData.colors}
          value={getFilter('colors')}
          onChange={handleOnFilterChange('colors')}
          onChangeItem={handleOnFilterItemChange('colors')}
        />
      )}

      {arrayHasElements(filtersData?.vendors) && (
        <FilterListByAlphabet
          className={classes.filterType}
          title="Company"
          titleTx="filter.sidebar.vendor"
          name="companyNames"
          data={filtersData.companyNames}
          value={getFilter('companyNames')}
          onChange={handleOnFilterChange('companyNames')}
          onChangeItem={handleOnFilterItemChange('companyNames')}
        />
      )}
      {/* FIXME: return if it will be decided to turn back gender option */}
      {/* {arrayHasElements(filtersData.genders) && (
        <FilterList
          className={classes.filterType}
          title="Gender"
          titleTx="filter.sidebar.gender"
          name="gender"
          data={filtersData.genders}
          value={getFilter('genders')}
          onChange={handleOnFilterChange('genders')}
          onChangeItem={handleOnFilterItemChange('genders')}
        />
      )} */}
      {arrayHasElements(filtersData.materials) && (
        <FilterList
          className={classes.filterType}
          title="Material"
          titleTx="filter.sidebar.material"
          name="material"
          data={filtersData.materials}
          value={getFilter('materials')}
          onChange={handleOnFilterChange('materials')}
          onChangeItem={handleOnFilterItemChange('materials')}
        />
      )}
    </Column>
  )
}
