import React, { FC } from 'react'

import { Button, Column } from '../../../components'

import { useStyle } from './collections-gallery-item.styles'
import { CollectionGalleryItemProps } from './collections-gallery-item.types'

export const CollectionsGalleryItem: FC<CollectionGalleryItemProps> = ({
  className = '',
  isEven,
  imageUrl,
  alt,
  onClick
}) => {
  const classes = useStyle({ even: isEven })

  return (
    <Column className={`${className} ${classes.imageContainer}`}>
      <img className={classes.image} src={imageUrl} alt={alt} />

      <Button
        className={classes.button}
        color="white"
        preset="button2"
        text="Items in Sale"
        textColor="black"
        textPreset="maxButton"
        tx="collections.title"
        onClick={onClick}
      />
    </Column>
  )
}
