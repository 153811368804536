import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  title: {
    backgroundColor: theme.colors.lightBlue
  },
  rating: {},

  '@media screen and (max-width: 480px) ': {
    title: {
      alignItems: 'flex-start',
      flexDirection: 'column'
    },
    rating: {
      marginTop: 14
    }
  }
}))
