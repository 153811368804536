import React, { FC } from 'react'

import { Column } from '../../column'

import { useStyle } from './promotion-carousel-dot.styles'
import { PromotionCarouselDotProps } from './promotion-carousel-dot.types'

export const PromotionCarouselDot: FC<PromotionCarouselDotProps> = ({
  active,
  className = '',
  onClick
}) => {
  const classes = useStyle({ active })

  return <Column className={`${className} ${classes.dot}`} onClick={onClick} />
}
