import React, { FC } from 'react'

import { Column } from '../column'
import { Icon, ICONS } from '../icon'
import { Row } from '../row'
import { Text } from '../text'

import { useStyle } from './vendor-item-list.styles'
import { VendorItemListProps } from './vendor-item-list.types'

export const VendorItemList: FC<VendorItemListProps> = ({
  className = '',
  background,
  description,
  image,
  text,
  tx,
  onClick
}) => {
  const firstSymbol = text.charAt(0).toUpperCase()
  const classes = useStyle({ background })

  return (
    <Row
      fullWidth
      className={`${className} ${classes.container}`}
      onClick={onClick}
      justifyContent="space-between"
    >
      <Row>
        {image ? (
          <img className={classes.img} src={image} />
        ) : (
          <Row className={`${classes.img} ${classes.placeholderImg}`}>
            <Text color="white" preset="h4" text={firstSymbol} />
          </Row>
        )}
        <Column className={classes.text} alignItems="flex-start">
          <Text color="black" preset="body" text={text} tx={tx} />
          <Row className={classes.desctiptionContainer}>
            <Icon src={ICONS.storeMallDirectory} />
            <Text
              className={classes.desctiption}
              color="inactiveSecondary"
              preset="cardText"
              text={description}
            />
          </Row>
        </Column>
      </Row>
    </Row>
  )
}
