import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    backgroundColor: theme.colors.white,
    width: 'calc(100% - 48px)',
    padding: '16px 24px 16px 24px'
  },
  title: {
    marginTop: 24
  },
  description: {},
  content: {
    width: '70%',
    marginTop: 40,
    alignItems: 'flex-start'
  },
  item: {
    width: '100%',
    borderBottom: `1px solid ${theme.colors.shadow}`
  },
  secondaryItem: {
    marginTop: 40
  },
  submitContainer: {
    background: theme.colors.white,
    width: '100%',
    bottom: 0,
    boxShadow: `4px -2px 8px 0px ${theme.colors.shadow}`,
    marginTop: 16,
    padding: 24,
    position: 'sticky'
  },
  button: {
    height: 54,
    width: 204
  },
  submitButton: {
    width: 204,
    marginLeft: 24
  },
  buttonsRow: {},
  slider: {
    border: `1px solid ${theme.colors.shadow}`,
    borderRadius: 4
  },
  sliderContent: {
    width: 'calc(100% - 32px)',
    padding: 16,
    borderBottom: `1px solid ${theme.colors.shadow}`
  },
  empty: {
    flex: 1.1,
    marginLeft: 24
  },
  feedback: {
    marginTop: 80
  },
  bottomContainer: {},
  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    container: {
      width: '100%',
      padding: '16px 0px'
    },
    submitContainer: {
      padding: ' 24px 16px'
    },
    content: {
      width: '100%',
      alignItems: 'center'
    }
  },
  '@media screen and (max-width: 480px)': {
    container: {
      width: '100%',
      padding: '24px 0px 40px'
    },
    title: {
      textAlign: 'center'
    },
    description: {
      marginTop: 24
    },
    content: {
      width: '100%',
      alignItems: 'center'
    },

    submitContainer: {
      padding: 0
    },
    button: {
      width: 144
    },

    submitButton: {
      width: 116,
      marginLeft: 0
    },
    buttonsRow: {
      justifyContent: 'space-between'
    },
    bottomContainer: {
      bottom: 0
    }
  }
}))
