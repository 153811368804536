import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

import { HeaderOverlayStyle } from './header-overlay.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  overlay: {
    position: 'fixed',
    width: '100%',
    inset: 0,
    height: '100vh',
    backgroundColor: theme.colors.backdrop,
    zIndex: 3
  },
  overlayLeft: {
    height: '100%',
    backgroundColor: theme.colors.white
  },
  navMenuDropdownHeader: {
    poition: 'fixed',
    borderBottom: `1px solid ${theme.colors.shadow}`,
    borderTop: `1px solid ${theme.colors.shadow}`,
    padding: '12px 0 ',
    width: 'calc(100vw - 61px)'
  },
  navMenuDropdown: {
    display: 'flex',
    boxShadowRigth: '2px 0px 8px rgba(0, 0, 0, 0.12)',
    width: 'calc(100vw - 61px)'
  },
  icon: {
    cursor: 'pointer',
    '&:hover > path': {
      fill: theme.colors.yellow
    }
  },
  svgContainer: {
    paddingRight: 8
  },
  text: {
    paddingLeft: 12
  },

  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    navMenuDropdownHeader: {
      width: 314
    },
    navMenuDropdown: {
      width: 314
    },

    overlayLeft: {
      zIndex: 3,
      top: ({ scroll }: HeaderOverlayStyle) => (scroll() ? 0 : 58),
      boxShadow: '2px 0px 8px rgba(0, 0, 0, 0.12)'
    }
  }
}))
