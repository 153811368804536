import { ReactNode } from 'react'

import { TextPresets, TextTranslate } from '../text'

export type ItemsLineProps = {
  carousel?: boolean
  children?: ReactNode
  className?: string
  description?: string
  descriptionTx?: TextTranslate
  header?: ReactNode
  itemsLength: number
  treshold?: number
  title: string
  titlePreset?: TextPresets
  titlePresetMobile?: TextPresets
  titleTx?: TextTranslate
  to?: string
  onLoadNext?: () => void
}

export type ItemsListStyles = {
  carousel?: boolean
  arrowAvaliable?: boolean
  searchResults?: boolean
}

export enum ScrollDirection {
  LEFT = 'left',
  RIGHT = 'right'
}
