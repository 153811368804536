import React, { FC, MouseEventHandler, useState } from 'react'

import { Button } from '../../components'

import { ObserveOptionModal } from '../observe-options'

import { ObserveButtonProps } from './observe-button.types'

export const ObserveButton: FC<ObserveButtonProps> = ({
  className = '',
  defaultOpen = false,
  name = 'Observe Options',
  nameTx = 'order.preview.item.observe.options',
  onClick,
  ...observeModalProps
}) => {
  const [open, changeOpen] = useState(defaultOpen)

  const handleOnOpen =
    (state: boolean): MouseEventHandler<HTMLButtonElement> =>
    (event) => {
      changeOpen(state)

      if (onClick) {
        onClick(event)
      }
    }

  return (
    <>
      <Button
        className={className}
        preset="cards"
        textColor="black"
        textPreset="maxButton"
        text={name}
        tx={nameTx}
        onClick={handleOnOpen(true)}
      />
      <ObserveOptionModal
        {...observeModalProps}
        open={open}
        onClose={handleOnOpen(false)}
      />
    </>
  )
}
