export const AVATAR_FRAGMENT = `
fragment AvatarFragment on Avatar {
  id
  externalId
  createdAt
  previewImage
  username
  subUsername
}
`

export const VTMT_VENDOR_FRAGMENT = `
  fragment VtmtVendorFragment on VtmtVendor {
    id
    email
    displayName
    companyName
    previewImage
    background
  }
`
