import { createUseStyles } from 'react-jss'

import { SignUpStyleProps } from './sign-up.types'

export const useStyle = createUseStyles({
  container: {
    backgroundSize: '100vw',
    backgroundRepeat: 'space',
    backgroundImage: ({ background }: SignUpStyleProps) => `url(${background})`,
    paddingBottom: 24
  }
})
