import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  productAvatar: {
    objectFit: 'cover'
  },
  title: {
    marginLeft: 16
  },
  line: {
    borderTop: `1px solid ${theme.colors.shadow}`,
    width: 'calc(100% - 16px)',
    padding: '16px 8px',
    '&:not(:first-child)': {
      marginTop: 16
    }
  },
  itemNumber: {
    minWidth: 64
  },
  itemTitle: {},
  observeOptionsBtn: {
    padding: '8px 6px'
  },
  column: {
    flex: 1
  },
  itemRow: {
    flex: 1
  },
  infoContainer: {
    flex: 3
  },
  infoRow: {
    flex: 2,
    marginLeft: 16,
    columnGap: 8
  },
  delivery: {
    wordBreak: 'break-word'
  },
  buttonsRow: {
    flex: 1,
    maxWidth: 260,
    marginLeft: 16
  },
  buttonDecline: {
    width: 120,
    padding: '12px 20px'
  },
  buttonContact: {
    width: 120,
    padding: '12px 14px',
    marginLeft: 20
  },
  emptyRow: {
    width: 56
  },
  observeOptionsContainer: {
    marginLeft: 16,
    width: 260
  },
  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    itemRow: {
      flex: 2
    }
  },
  '@media screen and (max-width: 480px)': {
    productAvatar: {
      width: 104
    },
    line: {
      width: '100%'
    },
    infoRow: {
      marginLeft: 0,
      marginTop: 24
    },
    buttonsRow: {
      justifyContent: 'space-between',
      maxWidth: 'none',
      width: '100%',
      marginLeft: 0,
      marginTop: 24,
      columnGap: 16
    },
    buttonDecline: {
      width: '50%',
      maxWidth: '50%'
    },
    buttonContact: {
      width: '50%',
      marginLeft: 0
    }
  }
}))
