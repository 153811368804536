import React, { FC, useState } from 'react'
import { useTheme } from 'react-jss'

import { MainRoutes } from '../../../../../routes'
import { LightTheme } from '../../../../../theme'
import { Icon, ICONS } from '../../../../icon'
import { Column } from '../../../../column'
import { Row } from '../../../../row'
import { NavLink } from '../../../../nav-link'
import { HeaderNavSubMenuProps } from '../../../../header-bottom-bar'

import { HeaderSubMenuMobileItem } from '../submenu-mobile-item'

import { useStyle } from './submenu-mobile.styles'

export const HeaderNavMobileSubMenu: FC<HeaderNavSubMenuProps> = ({
  className = '',
  item
}) => {
  const [openMenu, changeOpenMenu] = useState(false)
  const theme: LightTheme = useTheme()
  const classes = useStyle()
  const iconOpen = openMenu ? ICONS.arrowTop : ICONS.arrowRight
  const titleColor = openMenu ? 'yellow' : 'black'
  const handleOnClick = () => {
    changeOpenMenu(!openMenu)
  }

  return (
    <Column className={`${className} ${classes.item}`} alignItems="flex-start">
      <Row fullWidth justifyContent="space-between">
        <NavLink
          className={classes.item}
          color={titleColor}
          preset="h6"
          text={item.name}
          to={`${MainRoutes.CATEGORY}/${item.category.id}`}
        />
        {item.children.length > 0 && (
          <Row className={classes.svgContainer}>
            <Icon
              src={iconOpen}
              fill={theme.colors[titleColor]}
              onClick={handleOnClick}
            />
          </Row>
        )}
      </Row>
      {openMenu && <HeaderSubMenuMobileItem item={item} />}
    </Column>
  )
}
