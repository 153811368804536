import React, { useState, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'
import _ from 'lodash'

import { useApi, useAuth } from '../../providers'
import {
  Column,
  Content,
  Text,
  getListFromEdgesList,
  GoBackContainer,
  ChooseReturnOrder,
  ChooseReturnButton,
  ResponsiveMediaView
} from '../../components'
import { OrdersVariables } from '../../services'

import { useStyle } from './returns.styles'

const FIRST = 8

export const ReturnPage = () => {
  const { user } = useAuth()
  const { order } = useApi()
  const [variables, changeVariables] = useState<OrdersVariables>({
    first: FIRST
  })
  const {
    data: response,
    refetch,
    fetchMore
  } = order.useOrdersToReturn(variables)

  const history = useHistory()
  const classes = useStyle()

  const titlePreset = isMobileOnly ? 'h1Mobile' : 'h1'

  const isFromItemCard =
    history.location.state !== undefined &&
    history.location.state.prevPath.includes('/product')

  const HasMore = useMemo(() => {
    return Boolean(response?.me?.ordersToReturn.pageInfo.hasNextPage)
  }, [response?.me?.ordersToReturn.pageInfo.hasNextPage])

  const Orders = useMemo(() => {
    const ordersData = response?.me?.ordersToReturn
    if (ordersData) {
      const nextData = _.uniq(getListFromEdgesList(ordersData))

      return nextData
    }

    return []
  }, [response, user])

  const handleOnNext = () => {
    const data = response?.me?.ordersToReturn

    if (data && fetchMore) {
      const { endCursor } = data.pageInfo

      if (endCursor) {
        const nextVariables = { ...variables, after: endCursor }

        changeVariables(nextVariables)
        fetchMore({ variables: { first: FIRST, after: endCursor } })
      }
    }
  }

  const handleOnRefresh = () => {
    if (refetch) {
      refetch({ first: FIRST })
    }
  }

  const handleGoBack = () => history.goBack()

  const ChooseOrder = (
    <ChooseReturnOrder
      orders={Orders}
      hasMore={HasMore}
      onNext={handleOnNext}
      onRefresh={handleOnRefresh}
    />
  )

  return (
    <Content className={classes.container} justifyContent="flex-start">
      {isFromItemCard && (
        <GoBackContainer
          text="Back to Product Page"
          textTx="returns.back.to.product.page"
          onClick={handleGoBack}
        />
      )}
      <Content
        className={classes.content}
        preset="part"
        justifyContent="flex-start"
      >
        <Column justifyContent="flex-start">
          <Text
            className={classes.title}
            text="Return"
            tx="returns.exchange.page.title"
            preset={titlePreset}
          />
          <Text
            className={classes.text}
            text="Looking to return purchased product?"
            tx="returns.exchange.page.second.title"
            preset="h5"
            color="inactive"
          />
          <Text
            className={classes.text}
            preset="body"
            text="If you’re unhappy with your purchase, we will find a solution for you! The majority of our products can be returned within 365 days, and we’ll pay for the return shipping if you are in the US. Returns will be refunded in the original payment or store credit."
            tx="returns.exchange.page.text"
          />
        </Column>
        <ResponsiveMediaView
          bigScreen={ChooseOrder}
          medium={ChooseOrder}
          tablet={ChooseOrder}
          mobile={<ChooseReturnButton orders={Orders} />}
        />
      </Content>
    </Content>
  )
}
