import React, { MouseEventHandler, useEffect, useMemo, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import {
  ICONS,
  Loader,
  ResponsiveMediaView,
  Content,
  ProductBreadcrumbs,
  ProductList,
  FeedbacksList,
  arrayHasElements,
  ProductInfoBottomBar,
  ProductInfoBottomBarMobile,
  getCurrencySymbol
} from '../../components'
import { useApi, useAuth, useSignInModal } from '../../providers'
import { reduceProductsToProductList } from '../../views'
import { OfferStatus } from '../../services'

import { CabinetNavigatorPath } from '../cabinet-navigator'
import { limit } from '../helper'

import { getFeedbackAmount, reduceProductItemToProduct } from './helpers'
import { GeneralProductInfo } from './general-product-info'
import { ProductDetails } from './product-details'
import { ProductDetailsFilter } from './product-details-filter'
import { ProductProps } from './product.types'
import { useStyle } from './product.styles'
import { ButtonsRow } from './buttons-row'

export const ProductPage = () => {
  const [scrollPosition, setScrollPosition] = useState(0)
  const isAbsolute = scrollPosition >= limit() - 400
  const classes = useStyle({ absolute: isAbsolute })
  const {
    product: {
      useProductById,
      useAddProductToWishlist,
      useRemoveProductFromWishlist
    },
    offer: offerApi
  } = useApi()
  const { isLoggedIn, user } = useAuth()
  const { changeOpenModal } = useSignInModal()
  const { id } = useParams<ProductProps>()
  const { data } = useProductById({ id })
  const { onSubmit: onSubmitAddProduct } = useAddProductToWishlist(id)
  const { onSubmit: onSubmitRemoveProduct } = useRemoveProductFromWishlist(id)
  const { response: responseCreateOffer, onSubmit: onSubmitCreateOffer } =
    offerApi.useOfferCreate()

  const history = useHistory()

  const Product = useMemo(() => {
    if (data) {
      return reduceProductItemToProduct(data)
    }

    return null
  }, [JSON.stringify(data?.product)])

  const [isFavorite, changeIsFavorite] = useState(Product?.isFavorite)
  const favoriteIcon = isFavorite ? ICONS.favoriteFilled : ICONS.favorite

  useEffect(() => {
    if (
      typeof isFavorite === 'undefined' &&
      typeof Product?.isFavorite === 'boolean'
    ) {
      changeIsFavorite(Product?.isFavorite)
    }
  }, [Product?.isFavorite, isFavorite])

  const Feedbacks = data?.product.feedbacks

  const IsHiddenFeedbacks = useMemo(() => {
    if (Feedbacks?.length === 0) {
      return null
    }

    return Feedbacks
  }, [JSON.stringify(Feedbacks)])

  const RecommendationProducts = useMemo(() => {
    if (data && data.product.recommendations) {
      return reduceProductsToProductList(
        data.product.recommendations.slice(0, 10)
      )
    }

    return []
  }, [JSON.stringify(data?.product.recommendations)])

  useEffect(() => {
    const offerId = responseCreateOffer.data?.offerCreate.offer.id
    if (offerId) {
      history.push({
        pathname: `${CabinetNavigatorPath.OFFER_CHAT}/${offerId}`,
        state: 'fromProductsPage'
      })
    }
  }, [responseCreateOffer.data?.offerCreate.offer.id])

  const handleOnAddToBasket = () => {
    history.push(`/product-constructor/${id}`)
  }

  const handleOnScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleOnScroll)

    return () => {
      window.removeEventListener('scroll', handleOnScroll)
    }
  }, [])

  if (!data || !Product) {
    return <Loader />
  }

  const { vendor } = Product
  const countryCode = vendor?.defaultBillingAddress?.country?.code
  const averageRating = Feedbacks && getFeedbackAmount(Feedbacks)
  const minCurrencySymbol = getCurrencySymbol(Product?.minCurrency)
  const bottomContainerPrice = `${minCurrencySymbol}${Product?.totalPrice.toFixed(
    2
  )}`
  const isOffer = Product.purchaseSpecifics === 'ONLY_WITH_OFFER'

  const handleOnCreateOffer = () => {
    onSubmitCreateOffer({
      input: {
        customer: user?.id,
        product: id,
        quantity: 1,
        status: OfferStatus.START_OFFER
      }
    })
  }

  const handleOnGetNewOffer: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault()
    event.stopPropagation()

    if (!isLoggedIn) {
      if (changeOpenModal) {
        changeOpenModal(true)
      }
    }

    if (isLoggedIn) {
      handleOnCreateOffer()
    }
  }

  const handleOnContactVendor = () => {
    if (!isLoggedIn) {
      if (changeOpenModal) {
        changeOpenModal(true)
      }
    }

    if (isLoggedIn) {
      history.push(CabinetNavigatorPath.VENDOR_COMMUNICATION)
    }
  }

  const handleOnAddToWishlistClick = () => {
    if (isLoggedIn) {
      changeIsFavorite(!isFavorite)

      if (!isFavorite) {
        onSubmitAddProduct()
      } else {
        onSubmitRemoveProduct()
      }
    }

    if (!isLoggedIn) {
      if (changeOpenModal) {
        changeOpenModal(true)
      }
    }
  }

  return (
    <>
      <Content justifyContent="flex-start">
        <ProductBreadcrumbs
          className={classes.breadcrumbs}
          product={data.product}
        />
        <GeneralProductInfo
          product={Product}
          favouriteIcon={favoriteIcon}
          averageRating={averageRating}
          countryCode={countryCode}
          onAddToWishlistClick={handleOnAddToWishlistClick}
          onAddToBasket={handleOnAddToBasket}
          onGetNewOffer={handleOnGetNewOffer}
          onContactVendor={handleOnContactVendor}
        />
        <ResponsiveMediaView
          bigScreen={<ProductDetails product={Product} id={id} />}
          medium={<ProductDetails product={Product} id={id} />}
          tablet={<ProductDetailsFilter product={Product} />}
          mobile={<ProductDetailsFilter product={Product} />}
        />
        {IsHiddenFeedbacks && (
          <FeedbacksList className={classes.feedback} data={Feedbacks} />
        )}
        {arrayHasElements(RecommendationProducts) && (
          <ProductList
            carousel
            className={classes.productList}
            title="Recommendations for You"
            titlePreset="h1"
            titleTx="recommendation.title"
            data={RecommendationProducts}
          />
        )}
      </Content>
      <ResponsiveMediaView
        tablet={
          <ProductInfoBottomBar
            name={Product.name}
            price={String(bottomContainerPrice)}
            buttonTitle="CONTINUE"
            buttonTitleTx="product.continue.btn"
            onClick={handleOnAddToBasket}
          />
        }
      />

      {scrollPosition > 700 && (
        <ResponsiveMediaView
          mobile={
            <ProductInfoBottomBarMobile
              absolute={isAbsolute}
              className={classes.bottomContainer}
            >
              <ButtonsRow
                classNameOfferButton={classes.button}
                isOffer={isOffer}
                onAddToBasket={handleOnAddToBasket}
                onContactVendor={handleOnContactVendor}
                onGetNewOffer={handleOnGetNewOffer}
              />
            </ProductInfoBottomBarMobile>
          }
        />
      )}
    </>
  )
}
