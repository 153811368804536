import React, { FC } from 'react'

import { Text } from '../../../text'
import { Column } from '../../../column'
import { ColorDot } from '../../../color-dot'
import { Row } from '../../../row'

import { FeedbackSliderHeaderMobileProps } from './feedback-product-slider-header-mobile.types'

import { useStyle } from './feedback-product-slider-header-mobile.styles'

export const FeedbackSliderHeaderMobile: FC<
  FeedbackSliderHeaderMobileProps
> = ({ className = '', name, color, productImage }) => {
  const classes = useStyle()

  return (
    <Column className={`${classes.container} ${className}`} fullWidth>
      <Row fullWidth alignItems="flex-start">
        <Row fullWidth justifyContent="flex-start" alignItems="flex-start">
          <img className={classes.image} src={productImage} />
          <Column className={classes.name} alignItems="flex-start">
            <Text text={name} preset="h5" />
            <ColorDot className={classes.text} preset="default" color={color} />
          </Column>
        </Row>
      </Row>
    </Column>
  )
}
