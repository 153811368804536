import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    maxWidth: 1124,
    padding: '72px 0'
  },
  mail: {
    marginTop: 24,
    '& > span:not(:first-child)': {
      marginLeft: 8
    }
  },
  description: {
    marginTop: 24
  },
  subText: {
    marginTop: 20
  },
  items: {
    marginTop: 20
  },
  border: {
    marginTop: 24,
    borderTop: `1px solid ${theme.colors.shadow}`
  },
  row: {
    marginTop: 24
  },
  under: {
    marginLeft: 20
  },
  button: {
    width: 352,
    marginTop: 30
  },

  '@media screen and (max-width: 480px)': {
    container: {
      width: 'calc(100% - 24px)',
      padding: '40px 12px'
    },
    button: {
      width: 'calc(100% - 48px)'
    },
    items: {
      marginTop: 16,
      flexDirection: 'column'
    }
  },
  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    container: {
      width: 'calc(100% - 32px)',
      padding: '40px 16px'
    }
  }
}))
