import React, { FC } from 'react'

import {
  Column,
  ICONS,
  Icon,
  Row,
  Text,
  CabinetPageContainer
} from '../../components'
import { useStyle } from './empty-list.styles'
import { EmptyProps } from './empty-list.types'

export const EmptyList: FC<EmptyProps> = ({
  headerText,
  headerTx,
  title,
  titleTx,
  text,
  tx,
  classNameImg,
  onRefresh
}) => {
  const classes = useStyle()

  const rightHeaderContent = onRefresh && (
    <Row className={classes.icon}>
      <Icon src={ICONS.refresh} onClick={onRefresh} />
    </Row>
  )

  return (
    <CabinetPageContainer
      label={headerText}
      labelTx={headerTx}
      rightHeaderContent={rightHeaderContent}
    >
      <Column
        className={`${classes.image} ${classNameImg}`}
        alignItems="center"
        justifyContent="center"
      />
      <Column fullWidth className={classes.text}>
        <Text preset="h4" text={title} tx={titleTx} />
        <Text
          className={classes.subText}
          color="inactive"
          preset="body"
          text={text}
          tx={tx}
        />
      </Column>
    </CabinetPageContainer>
  )
}
