import React, { FC } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useHistory } from 'react-router-dom'
import { useTheme } from 'react-jss'

import { Color, LightTheme } from '../../theme'
import { getPathByCategory } from '../../utils'

import { Row } from '../row'
import { Text } from '../text'
import { Icon, ICONS } from '../icon'

import { CategoryBreadcrumbsProps } from './category-breadcrumbs.types'
import { useStyle } from './category-breadcrumbs.styles'

export const CategoryBreadcrumbs: FC<CategoryBreadcrumbsProps> = ({
  active,
  category,
  className = ''
}) => {
  const classes = useStyle({ active })
  const theme = useTheme<LightTheme>()
  const history = useHistory()
  const activeColor: Color = active ? 'black' : 'inactive'
  const textPreset = isMobileOnly ? 'h7' : 'h6'
  const handleOnNavigate = () => {
    const path = getPathByCategory(category.id, category.children.edges)

    history.push(path)
  }

  const handleOnNavigateHome = () => {
    history.push('/')
  }

  const navigate = !active ? handleOnNavigate : undefined

  return (
    <Row className={className} justifyContent="flex-start">
      {category.parent ? (
        <>
          <CategoryBreadcrumbs category={category.parent} />
          <Row className={classes.icon}>
            <Icon fill={theme.colors.inactive} src={ICONS.arrowBreadcrumbs} />
          </Row>
        </>
      ) : (
        <>
          <Text
            className={classes.breadcrumbs}
            color="inactive"
            preset={textPreset}
            text="Home"
            tx="breadcrumbs.home"
            onClick={handleOnNavigateHome}
          />
          <Row className={classes.icon}>
            <Icon fill={theme.colors.inactive} src={ICONS.arrowBreadcrumbs} />
          </Row>
        </>
      )}
      <Text
        className={classes.breadcrumbs}
        color={activeColor}
        preset={textPreset}
        text={category.name}
        onClick={navigate}
      />
    </Row>
  )
}
