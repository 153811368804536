import React, { FC } from 'react'

import { Column, Text } from '../../../components'

import { useStyle } from './category-empty.styles'
import { CategoryEmptyProps } from './category-empty.types'

export const CategoryEmpty: FC<CategoryEmptyProps> = ({ parent }) => {
  const classes = useStyle()

  return (
    <>
      <Text className={classes.subcategory} preset="h1" text={parent.name} />
      <Column
        className={classes.image}
        alignItems="center"
        justifyContent="center"
      >
        <Column>
          <Text
            className={classes.saleText}
            preset="h5"
            text="There are no items at the moment."
            tx="no.items.description"
          />
        </Column>
      </Column>
    </>
  )
}
