import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

import { FeedbackPageStyleProps } from './feedback.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    backgroundColor: theme.colors.white,
    width: 'calc(100% - 48px)',
    padding: '16px 24px 16px 24px'
  },
  title: {
    marginTop: 24
  },
  content: {
    marginTop: 40
  },
  item: {
    width: '100%',
    borderBottom: `1px solid ${theme.colors.shadow}`,
    '&:last-child': {
      borderBottom: 'none'
    }
  },
  secondaryItem: {
    marginTop: 40
  },
  bigColumn: {
    flex: 2
  },
  smallColumn: {
    flex: 1.1,
    marginLeft: 24,
    top: 24,
    position: 'sticky'
  },
  submitContainer: {},
  button: {
    height: 54,
    width: 204
  },
  submitButton: {
    width: 204,
    marginLeft: 24
  },
  slider: {
    border: `1px solid ${theme.colors.shadow}`,
    borderRadius: 4
  },
  sliderContent: {
    width: 'calc(100% - 32px)',
    padding: 16,
    borderBottom: `1px solid ${theme.colors.shadow}`
  },
  bottomContainer: {},
  '@media screen and (min-width: 1224px)': {
    submitContainer: {
      background: theme.colors.white,
      width: '100%',
      boxShadow: `4px -2px 8px 0px ${theme.colors.shadow}`,
      marginTop: 16,
      padding: 24,
      position: 'sticky'
    }
  },

  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    container: {
      width: '100%',
      padding: '24px 0px 44px'
    },
    smallColumn: {
      marginLeft: 0,
      position: 'static'
    },
    item: {
      '&:not(:last-child)': {
        marginTop: 40
      }
    },
    submitContainer: {
      background: theme.colors.white,
      width: '100%',
      boxShadow: `4px -2px 8px 0px ${theme.colors.shadow}`,
      marginTop: 16,
      padding: '24px 16px',
      position: 'sticky'
    }
  },
  '@media screen and (max-width: 480px)': {
    container: {
      width: '100%',
      padding: '24px 0px 30px'
    },
    backContainer: {
      marginTop: 0
    },
    title: {
      textAlign: 'center'
    },
    item: {
      '&:not(:last-child)': {
        marginTop: 24
      }
    },
    bigColumn: {
      width: '100%'
    },
    smallColumn: {
      marginLeft: 0,
      position: 'static'
    },

    button: {
      width: '100%'
    },
    submitButton: {
      width: '100%',
      marginleft: 0
    },
    bottomContainer: {
      bottom: ({ absolute }: FeedbackPageStyleProps) => (absolute ? -480 : 0)
    }
  }
}))
