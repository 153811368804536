import React, { FC, useState } from 'react'
import { isMobile, isMobileOnly } from 'react-device-detect'

import { getFormatedOrderDate } from '../../views'

import { getFullName } from '../helpers'
import { Column } from '../column'
import { Row } from '../row'
import { Text } from '../text'
import { Button } from '../button'
import { RatingStarsComponent } from '../rating-stars'

import { useStyle } from './feedbacks-list.styles'
import { FeedbackListProps } from './feedbacks-list.types'

export const FeedbacksList: FC<FeedbackListProps> = ({
  className = '',
  data
}) => {
  const classes = useStyle()
  const titlePreset = isMobileOnly ? 'h2Mobile' : 'h1'
  const Container = isMobile ? Column : Row
  const ContainerReverse = isMobile ? Row : Column
  const [visible, changeVisible] = useState(3)

  const isShowButton = data && data.length > 3 && visible <= data.length

  const handleOnLoadNext = () => {
    changeVisible((prevState) => prevState + 3)
  }

  return (
    <Column className={className} fullWidth>
      <Column className={classes.container}>
        <Text text="Feedback" tx="feedback.list.title" preset={titlePreset} />
        <Row fullWidth className={classes.reviewsRow}>
          {data && (
            <Text text={data.length.toString()} preset="h7" color="inactive" />
          )}
          <Text
            text="reviews"
            preset="h7"
            color="inactive"
            className={classes.review}
          />
        </Row>
        {data &&
          data.slice(0, visible).map((feedback, key) => {
            const { id, user, rating, comment, answer, createdAt } = feedback

            const date = getFormatedOrderDate(new Date(createdAt))
            const fullName = getFullName(user)

            return (
              <Container
                key={`feedback_${id}_${key}`}
                fullWidth
                alignItems="flex-start"
                justifyContent="flex-start"
                className={classes.feedbackContainer}
              >
                <Column
                  alignItems="flex-start"
                  className={classes.leftContainer}
                >
                  <Row fullWidth justifyContent="flex-start">
                    <Text text={fullName} preset="h4" />
                  </Row>
                  <ContainerReverse
                    fullWidth
                    alignItems="baseLine"
                    justifyContent="space-between"
                  >
                    <Text
                      text={date}
                      preset="h7"
                      color="inactive"
                      className={classes.date}
                    />
                  </ContainerReverse>
                </Column>
                <Column
                  fullWidth
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className={classes.rightContainer}
                >
                  <Column
                    fullWidth
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    className={classes.feedback}
                  >
                    {rating > 0 && (
                      <Row fullWidth justifyContent="flex-start">
                        <RatingStarsComponent rating={rating} edit={false} />
                      </Row>
                    )}
                    {comment && (
                      <Text
                        className={classes.text}
                        text={comment}
                        preset="body"
                      />
                    )}
                  </Column>
                  {answer && (
                    <Row
                      fullWidth
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      className={classes.answer}
                    >
                      <Row>
                        <Text text="Answer:" preset="body" color="inactive" />
                      </Row>
                      <Row>
                        <Text
                          className={classes.answerText}
                          text={answer}
                          preset="body"
                          color="black"
                        />
                      </Row>
                    </Row>
                  )}
                </Column>
              </Container>
            )
          })}
        {isShowButton && (
          <Button
            className={classes.submit}
            preset="primary"
            textColor="white"
            textPreset="h5"
            text="VIEW MORE"
            tx="feedbacks.button.text"
            onClick={handleOnLoadNext}
          />
        )}
      </Column>
    </Column>
  )
}
