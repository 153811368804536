import React, { FC, useRef } from 'react'
import { Link } from 'react-router-dom'

import { getPathByCategory } from '../../utils'

import { Row } from '../row'
import { Text } from '../text'
import { Column } from '../column'
import { CategoryCard } from '../category-card'

import { CategoryListProps } from './category-list.types'
import { useStyle } from './category-list.styles'

export const CategoryList: FC<CategoryListProps> = ({
  className = '',
  carouselClassName = '',
  children,
  data,
  vendorId,
  mini,
  description,
  descriptionTx,
  title,
  titleTx,
  titlePreset,
  categoryBreadcrumb
}) => {
  const scrollContainer = useRef<HTMLDivElement>(null)

  const classes = useStyle()

  return (
    <Column fullWidth className={className}>
      <Text color="black" preset={titlePreset} text={title} tx={titleTx} />
      {description && (
        <Text
          className={classes.description}
          color="inactive"
          preset="h7"
          text={description}
          tx={descriptionTx}
        />
      )}
      {children}
      <Row
        fullWidth
        justifyContent="center"
        className={`${carouselClassName} ${classes.carouselContainer}`}
        ref={scrollContainer}
      >
        <Row wrap className={classes.listRow} justifyContent="flex-start">
          {data.map(
            ({ id, name, backgroundImage, children: categoryChildren }) => {
              const pathname = getPathByCategory(
                id,
                categoryChildren.edges,
                vendorId
              )

              const to = {
                pathname,
                state: { categoryBreadcrumb }
              }

              return (
                <Link
                  key={`category${id}`}
                  className={classes.category}
                  to={to}
                >
                  <CategoryCard
                    id={id}
                    mini={mini}
                    name={name}
                    backgroundImage={backgroundImage}
                  />
                </Link>
              )
            }
          )}
        </Row>
      </Row>
    </Column>
  )
}
