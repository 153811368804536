import React, { FC } from 'react'

import { SOCIAL_MEDIA } from '../../../data'

import { SocialMediaIcon } from '../../social-media-icon'
import { Row } from '../../row'

export type NavSocialBlockProps = {
  className?: string
  classNameIcon?: string
}

export const NavSocialBlock: FC<NavSocialBlockProps> = ({
  className = '',
  classNameIcon = ''
}) => {
  return (
    <Row className={className}>
      {SOCIAL_MEDIA.map((medium) => (
        <SocialMediaIcon
          className={classNameIcon}
          medium={medium}
          key={medium.ariaLabel}
        />
      ))}
    </Row>
  )
}
