import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  describing: {
    padding: '16px 24px',
    width: 'calc(100% - 48px)'
  },
  textRow: {
    marginTop: 4
  }
})
