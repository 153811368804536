import React, { ChangeEvent, FC, useState } from 'react'

import {
  Button,
  Column,
  Row,
  Text,
  TextField,
  NavLink,
  Icon,
  ICONS
} from '../../components'

import { useAuth, useSignInModal } from '../../providers'
import { GetTokenCreateRequest } from '../../services'

import { useStyle } from './sign-in.styles'
import { SignInProps } from './sign-in.types'

const url = `${window.location.href}`
const lastSymbol = url.slice(-1)
const link = lastSymbol === '/' ? url : `${url}/`

const defaultUserState: GetTokenCreateRequest = {
  email: '',
  password: '',
  page: link
}

export const SignInView: FC<SignInProps> = ({
  className = '',
  errorClassName = '',
  modal
}) => {
  const [user, changeUser] = useState<GetTokenCreateRequest>(defaultUserState)
  const { onLoggedInModal, error, onChangeError } = useAuth()
  const { changeOpenModal } = useSignInModal()

  const classes = useStyle({ modal })

  const isDisabled = !user.email || !user.password

  const handleOnChange =
    (prop: keyof GetTokenCreateRequest) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target
      changeUser({
        ...user,
        [prop]: value
      })
    }

  const handleOnClick = () => {
    if (onLoggedInModal) {
      onLoggedInModal(user)
    }
  }

  const handleOnOpenModal = (state: boolean) => () => {
    if (changeOpenModal) {
      changeOpenModal(state)
    }
  }

  const handleOnFocus = () => {
    if (onChangeError) {
      onChangeError()
    }
  }

  return (
    <Column fullWidth className={className}>
      <Text
        color="inactive"
        preset="h7"
        text="Please sign in to continue."
        tx="login.continue"
      />

      <TextField
        defaultValue={user.email}
        className={classes.input}
        label="E-mail"
        labelTx="login.email"
        preset="border"
        type="text"
        onChange={handleOnChange('email')}
        onFocus={handleOnFocus}
      />
      <TextField
        isSecure
        defaultValue={user.password}
        className={classes.input}
        label="Password"
        labelTx="login.password"
        preset="border"
        type="text"
        onChange={handleOnChange('password')}
        onFocus={handleOnFocus}
      />
      <NavLink
        className={classes.forgotPassword}
        color="blue"
        preset="h6"
        text="Forgot Password?"
        to="/forgot-password"
        onClick={handleOnOpenModal(false)}
      />
      {error && (
        <Row
          className={`${classes.error} ${errorClassName} `}
          fullWidth
          justifyContent="flex-start"
        >
          <Icon className={classes.errorIcon} src={ICONS.attention} />
          <Text
            className={classes.errorText}
            text="Incorrect email or password!"
            tx="login.error"
            color="error"
            preset="body"
          />
        </Row>
      )}
      <Button
        className={classes.submit}
        disabled={isDisabled}
        preset="primary"
        text="SIGN IN"
        textColor="white"
        textPreset="h5"
        tx="login.signIn"
        onClick={handleOnClick}
      />
      <Row className={classes.signUpContainer}>
        <Text
          color="black"
          preset="secondTitle"
          text="Don’t have an account?"
          tx="login.noAccount"
        />
        <NavLink
          className={classes.signUp}
          color="blue"
          preset="h6"
          text="Sign Up"
          to="/sign-up"
          tx="login.signUp"
          onClick={handleOnOpenModal(false)}
        />
      </Row>
    </Column>
  )
}
