import React, { FC } from 'react'
import { isMobileOnly } from 'react-device-detect'

import { Row, Text, RatingStarsComponent } from '../../../../components'

import { VendorSummaryHeaderProps } from './vendor-summary-header.types'
import { useStyle } from './vendor-summary-header.styles'

export const VendorSummaryHeader: FC<VendorSummaryHeaderProps> = ({
  className = '',
  rating
}) => {
  const companySummaryPreset = isMobileOnly ? 'h3Mobile' : 'h3'

  const classes = useStyle()

  return (
    <Row
      fullWidth
      justifyContent="space-between"
      className={`${className} ${classes.title}`}
    >
      <Text
        text="Company Summary"
        preset={companySummaryPreset}
        color="black"
      />
      <RatingStarsComponent
        className={classes.rating}
        rating={rating}
        edit={false}
      />
    </Row>
  )
}
