import React, { FC, useState } from 'react'

import { Column, Row, NavLink, Icon, ICONS } from '../../../../components'
import { MainRoutes } from '../../../../routes'

import { useStyle } from './catalogue-category-nav.styles'
import { CategoryNavProps } from './catalogue-category-nav.types'

export const CategoryNav: FC<CategoryNavProps> = ({ data }) => {
  const classes = useStyle()

  const [openCategory, changeOpenCategory] = useState(false)
  const iconOpen = openCategory ? ICONS.arrowTop : ICONS.arrowBottom
  const handleOnClick = () => {
    changeOpenCategory(!openCategory)
  }

  return (
    <Column
      fullWidth
      alignItems="flex-start"
      className={classes.listGroup}
      key={data.node.id}
    >
      <Row fullWidth justifyContent="space-between">
        <NavLink
          preset="h5"
          color="black"
          text={data.node.name}
          to={`${MainRoutes.CATEGORY}/${data.node.id}`}
        />
        {data.node.children.edges.length > 0 && (
          <Row className={classes.svgContainer}>
            <Icon
              className={classes.icon}
              fill="black"
              src={iconOpen}
              onClick={handleOnClick}
            />
          </Row>
        )}
      </Row>
      {openCategory && data && (
        <Column alignItems="flex-start">
          {data.node.children.edges.map((listGroup) => (
            <Column
              alignItems="flex-start"
              className={classes.listItem}
              key={listGroup.node.id}
            >
              <NavLink
                key={listGroup.node.id}
                preset="body"
                color="black"
                text={listGroup.node.name}
                to={`${MainRoutes.CATEGORY}/${listGroup.node.id}/products`}
              />
            </Column>
          ))}
        </Column>
      )}
    </Column>
  )
}
