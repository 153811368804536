import React, { useState, useEffect } from 'react'
import { useTheme } from 'react-jss'
import { useHistory } from 'react-router-dom'

import { BasketIcon } from '../../../../views'
import { useAuth, useSignInModal } from '../../../../providers'
import { CabinetNavigatorPath, CabinetNavigator } from '../../../../pages'
import { LightTheme } from '../../../../theme'

import { NavLink } from '../../../nav-link'
import { Icon, ICONS } from '../../../icon'
import { HeaderOverlay } from '../../../header-overlay'
import { Row } from '../../../row'
import { Text } from '../../../text'
import { SearchComponent } from '../../../searchs'

import { HeaderNavMobileMenu } from '../../header-mobile'

import { useStyle } from './header-tablet-bottom-bar.styles'

export const HeaderTabletBottomBar = () => {
  const theme: LightTheme = useTheme()
  const classes = useStyle()
  const [open, openSearch] = useState(false)
  const [openNavMenu, changeOpenNavMenu] = useState(false)
  const [openProfileMenu, changeOpenProfileMenu] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0)
  const history = useHistory()

  const { isLoggedIn, user } = useAuth()
  const { changeOpenModal } = useSignInModal()

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  const handleOnClickNavMenu = (state: boolean) => () => {
    changeOpenNavMenu(state)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    changeOpenNavMenu(false)
    changeOpenProfileMenu(false)
  }, [history.location.pathname])

  const handleOnNavigate = (path: string) => () => {
    history.push(path)
  }
  const handleOnClick = (state: boolean) => () => {
    openSearch(state)
  }

  const handleOnOpenWishList = () => {
    const navigate = handleOnNavigate(CabinetNavigatorPath.WISH_LIST)

    if (!isLoggedIn) {
      if (changeOpenModal) {
        changeOpenModal(true)
      }
    }

    if (isLoggedIn) {
      navigate()
    }
  }

  const handleOnClickProfileMenu = (state: boolean) => () => {
    changeOpenProfileMenu(state)
    if (!isLoggedIn) {
      history.push('/sign-in')
    }
  }

  const headerProfile = (
    <Text
      className={classes.text}
      color="black"
      preset="h5"
      text={`${user?.firstName} ${user?.lastName}`}
    />
  )
  const headerNav = (
    <NavLink
      color="black"
      preset="h5"
      text="OVAFLOPICK"
      to="/"
      className={classes.text}
    />
  )

  return (
    <Row className={classes.container}>
      <Row fullWidth justifyContent="space-between">
        <Row justifyContent="flex-start" className={classes.flex}>
          <Icon src={ICONS.menu} onClick={handleOnClickNavMenu(true)} />
          <Row
            className={classes.user}
            onClick={handleOnClickProfileMenu(true)}
          >
            <Icon src={ICONS.person} />
            {isLoggedIn ? (
              <Text
                color="black"
                preset="h6"
                text={`${user?.firstName} ${user?.lastName}`}
              />
            ) : (
              <Text color="black" preset="h6" text="MYOVAFLOPICK" />
            )}
          </Row>
        </Row>

        <Row justifyContent="center" className={classes.flex}>
          <NavLink color="black" preset="h4" text="OVAFLOPICK" to="/" />
        </Row>

        <Row justifyContent="flex-end" className={classes.flex}>
          <Icon
            className={classes.icon}
            src={ICONS.search}
            onClick={handleOnClick(true)}
          />
          {open && (
            <SearchComponent open={open} onClose={handleOnClick(false)} />
          )}
          <Icon
            className={classes.icon}
            fill={theme.colors.black}
            src={ICONS.favorite}
            onClick={handleOnOpenWishList}
          />
          <BasketIcon className={classes.icon} />
        </Row>
      </Row>
      {openNavMenu && (
        <HeaderOverlay
          scrollPosition={scrollPosition}
          header={headerNav}
          onClose={handleOnClickNavMenu(false)}
        >
          <HeaderNavMobileMenu />
        </HeaderOverlay>
      )}

      {openProfileMenu && isLoggedIn && (
        <HeaderOverlay
          scrollPosition={scrollPosition}
          header={headerProfile}
          onClose={handleOnClickProfileMenu(false)}
        >
          <CabinetNavigator />
        </HeaderOverlay>
      )}
    </Row>
  )
}
