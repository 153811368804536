import { useQuery, useMutation } from '@apollo/client'

import {
  PRODUCTS_QUERY,
  PREVIOSLY_SEEN_PRODUCTS_QUERY,
  RECOMMENDATION_PRODUCTS,
  TOP_PRODUCTS_QUERY,
  WISHLIST_ADD_PRODUCT,
  FEATURED_PRODUCTS_QUERY,
  PRODUCT_BY_CATEGORY_ID,
  PRODUCT_BY_ID,
  WISHLIST,
  WISHLIST_REMOVE_PRODUCT,
  VENDOR_CATEGORIES,
  NEW_ARRIVALS_PRODUCTS,
  TOP_PRODUCTS_QUERY_GLOBAL_SEARCH,
  YOU_MAY_LIKE,
  PRODUCTS_QUERY_GLOBAL_SEARCH,
  PRODUCT_BY_COLLECTION_ID,
  ADVERTISING_PRODUCTS_QUERY,
  PRODUCT_PREVIEW_BY_ID
} from './product.graphql'
import {
  WishlistVariables,
  GetProductsData,
  GetPrevioslyProductsRequest,
  GetPrevioslyProductsVariables,
  GetPrevioslySeenProducts,
  GetRecommendationProducts,
  GetRecommendationProductsRequest,
  GetNewArrivalsProducts,
  GetNewArrivalsProductsRequest,
  GetTopProducts,
  GetTopProductsRequest,
  GetTopProductsVariables,
  ProductsVariables,
  GetAddProductWishlistRequest,
  GetRemoveProductWishlistRequest,
  GetFeaturedProducts,
  GetFeaturedProductsRequest,
  GetProduct,
  GetProductByIdRequest,
  GetProductsByCategoryId,
  GetProductsByCategoryIdRequest,
  GetWishlistAddResults,
  GetWishlistRemoveResults,
  GetWishlistRequest,
  GetWishlistResults,
  ProductApi,
  ProductByIdVariables,
  ProductsByCategoryIdVariables,
  GetProducts,
  GetVendorCategories,
  VendorCategoriesVariables,
  GetVendorCategoriesRequest,
  GetYouMayLikeProductsRequest,
  GetYouMayLikeProducts,
  ProductsByCollectionIdVariables,
  GetProductsByCollectionIdRequest,
  GetProductsByCollectionId,
  GetAdvertisingProductsVariables,
  GetAdvertisingProductsRequest,
  GetAdvertisingProducts,
  GetProductsGlobalSearch,
  GetProductsGlobalSearchData
} from './product.types'

// our "constructor"
export const productService = (): ProductApi => {
  const useProducts = (variables: ProductsVariables): GetProducts => {
    const { data, loading, error, refetch, fetchMore } = useQuery<
      GetProductsData,
      ProductsVariables
    >(PRODUCTS_QUERY, {
      variables,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first'
    })

    if (error) {
      return { data: null, loading, refetch, fetchMore }
    }

    if (!data) {
      return { data: null, loading, refetch, fetchMore }
    }

    return { data, loading, refetch, fetchMore }
  }
  const useProductsGlobalSearch = (
    variables: ProductsVariables
  ): GetProductsGlobalSearch => {
    const { data, loading, refetch, fetchMore, error } = useQuery<
      GetProductsGlobalSearchData,
      ProductsVariables
    >(PRODUCTS_QUERY_GLOBAL_SEARCH, {
      variables,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first'
    })
    if (error) {
      return { data: null, loading, refetch, fetchMore }
    }

    if (!data) {
      return { data: null, loading, refetch, fetchMore }
    }

    return { data, loading, refetch, fetchMore }
  }

  const useFeaturedProducts = (
    variables: ProductsVariables
  ): GetFeaturedProductsRequest => {
    const { data, loading, error, refetch, fetchMore } = useQuery<
      GetFeaturedProducts,
      ProductsVariables
    >(FEATURED_PRODUCTS_QUERY, {
      variables,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first'
    })

    return { data, loading, refetch, fetchMore, error }
  }

  const useTopProducts = (
    variables: GetTopProductsVariables
  ): GetTopProductsRequest => {
    const { data, loading, error, refetch, fetchMore } = useQuery<
      GetTopProducts,
      GetTopProductsVariables
    >(TOP_PRODUCTS_QUERY, {
      variables,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first'
    })

    if (error) {
      return { data: null, loading, refetch, fetchMore }
    }

    if (!data) {
      return { data: null, loading, refetch, fetchMore }
    }

    return { data, loading, refetch, fetchMore }
  }

  const useTopProductsGlobalSearch = (
    variables: GetTopProductsVariables
  ): GetTopProductsRequest => {
    const { data, loading, error, refetch, fetchMore } = useQuery<
      GetTopProducts,
      GetTopProductsVariables
    >(TOP_PRODUCTS_QUERY_GLOBAL_SEARCH, {
      variables,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first'
    })

    if (error) {
      return { data: null, loading, refetch, fetchMore }
    }

    if (!data) {
      return { data: null, loading, refetch, fetchMore }
    }

    return { data, loading, refetch, fetchMore }
  }

  const usePrevioslySeenProducts = (
    variables: GetPrevioslyProductsVariables
  ): GetPrevioslyProductsRequest => {
    const { data, loading, error, refetch } = useQuery<
      GetPrevioslySeenProducts,
      GetPrevioslyProductsVariables
    >(PREVIOSLY_SEEN_PRODUCTS_QUERY, {
      variables,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first'
    })

    if (error) {
      return { data: null, loading, refetch }
    }

    if (!data) {
      return { data: null, loading, refetch }
    }

    return { data, loading, refetch }
  }

  const useProductsByCategoryId = (
    variables: ProductsByCategoryIdVariables
  ): GetProductsByCategoryIdRequest => {
    const { data, loading, error, refetch, fetchMore } = useQuery<
      GetProductsByCategoryId,
      ProductsByCategoryIdVariables
    >(PRODUCT_BY_CATEGORY_ID, {
      variables,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first'
    })

    if (error) {
      return { data: null, loading, refetch, fetchMore }
    }

    if (!data) {
      return { data: null, loading, refetch, fetchMore }
    }

    return { data, loading, refetch, fetchMore }
  }

  const useProductsByCollectionId = (
    variables: ProductsByCollectionIdVariables
  ): GetProductsByCollectionIdRequest => {
    const { data, loading, error, refetch, fetchMore } = useQuery<
      GetProductsByCollectionId,
      ProductsByCollectionIdVariables
    >(PRODUCT_BY_COLLECTION_ID, {
      variables,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first'
    })

    if (error) {
      return { data: null, loading, refetch, fetchMore }
    }

    if (!data) {
      return { data: null, loading, refetch, fetchMore }
    }

    return { data, loading, refetch, fetchMore }
  }
  const useYouMayLike = (
    variables: ProductsVariables
  ): GetYouMayLikeProductsRequest => {
    const { data, loading, error, refetch, fetchMore } = useQuery<
      GetYouMayLikeProducts,
      ProductsVariables
    >(YOU_MAY_LIKE, { variables, fetchPolicy: 'network-only' })

    return { data, loading, refetch, error, fetchMore }
  }

  const useRecommendationProducts = (
    variables: ProductsVariables
  ): GetRecommendationProductsRequest => {
    const { data, loading, error, refetch, fetchMore } = useQuery<
      GetRecommendationProducts,
      ProductsVariables
    >(RECOMMENDATION_PRODUCTS, { variables, fetchPolicy: 'network-only' })

    return { data, loading, refetch, error, fetchMore }
  }

  const useNewArrivalsProducts = (
    variables: ProductsVariables
  ): GetNewArrivalsProductsRequest => {
    const { data, loading, error, refetch, fetchMore } = useQuery<
      GetNewArrivalsProducts,
      ProductsVariables
    >(NEW_ARRIVALS_PRODUCTS, {
      variables,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first'
    })

    if (error) {
      return { data: null, loading, refetch, fetchMore }
    }

    if (!data) {
      return { data: null, loading, refetch, fetchMore }
    }

    return { data, loading, refetch, fetchMore }
  }

  const useVendorCategories = (
    variables: VendorCategoriesVariables
  ): GetVendorCategoriesRequest => {
    const { data, loading, error, refetch } = useQuery<
      GetVendorCategories,
      VendorCategoriesVariables
    >(VENDOR_CATEGORIES, { variables, fetchPolicy: 'network-only' })

    return { data, loading, refetch, error }
  }

  const useProductById = (
    variables: ProductByIdVariables
  ): GetProductByIdRequest => {
    const { data, loading, error } = useQuery<GetProduct, ProductByIdVariables>(
      PRODUCT_BY_ID,
      { variables, fetchPolicy: 'network-only' }
    )

    if (error) {
      return { data: null, loading }
    }

    if (!data) {
      return { data: null, loading }
    }

    return { data, loading }
  }

  const useProductPreviewById = (
    variables: ProductByIdVariables
  ): GetProductByIdRequest => {
    const { data, loading, error } = useQuery<GetProduct, ProductByIdVariables>(
      PRODUCT_PREVIEW_BY_ID,
      { variables, fetchPolicy: 'network-only' }
    )

    if (error) {
      return { data: null, loading }
    }

    if (!data) {
      return { data: null, loading }
    }

    return { data, loading }
  }

  const useAddProductToWishlist = (
    id: string
  ): GetAddProductWishlistRequest => {
    const [AddProductToWishlistSubmit, response] = useMutation<
      GetWishlistAddResults,
      { productId: string }
    >(WISHLIST_ADD_PRODUCT)

    const handleOnSubmit = () => {
      if (id) {
        const options = {
          variables: {
            productId: id
          }
        }

        AddProductToWishlistSubmit(options)
      }
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useRemoveProductFromWishlist = (
    id: string
  ): GetRemoveProductWishlistRequest => {
    const [AddProductToWishlistSubmit, response] = useMutation<
      GetWishlistRemoveResults,
      { productId: string }
    >(WISHLIST_REMOVE_PRODUCT)

    const handleOnSubmit = () => {
      if (id) {
        const options = {
          variables: {
            productId: id
          }
        }

        AddProductToWishlistSubmit(options)
      }
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useWishlist = (variables: WishlistVariables): GetWishlistRequest => {
    const { data, loading, error, refetch, fetchMore } = useQuery<
      GetWishlistResults,
      WishlistVariables
    >(WISHLIST, {
      variables,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first'
    })

    return { data, error, loading, refetch, fetchMore }
  }

  const useAdvertisingProducts = (
    variables: GetAdvertisingProductsVariables
  ): GetAdvertisingProductsRequest => {
    const { data, loading, error, refetch, fetchMore } = useQuery<
      GetAdvertisingProducts,
      GetAdvertisingProductsVariables
    >(ADVERTISING_PRODUCTS_QUERY, {
      variables,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first'
    })

    if (error) {
      return { data: null, loading, refetch, fetchMore }
    }

    if (!data) {
      return { data: null, loading, refetch, fetchMore }
    }

    return { data, loading, refetch, fetchMore }
  }

  return {
    useNewArrivalsProducts,
    useProducts,
    useFeaturedProducts,
    useRecommendationProducts,
    useTopProducts,
    usePrevioslySeenProducts,
    useProductsByCategoryId,
    useTopProductsGlobalSearch,
    useProductById,
    useAddProductToWishlist,
    useRemoveProductFromWishlist,
    useWishlist,
    useVendorCategories,
    useYouMayLike,
    useProductsGlobalSearch,
    useProductsByCollectionId,
    useAdvertisingProducts,
    useProductPreviewById
  }
}
