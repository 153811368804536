import React from 'react'
import { useParams } from 'react-router-dom'

import { CategoryPageParams } from '../category'
import { ProductsViewPage } from './products-view'

export const ProductsPage = () => {
  const { id } = useParams<CategoryPageParams>()

  return <ProductsViewPage key={`category_products_${id}`} />
}
