import { getCurrencySymbol } from '../../components'
import {
  Currency,
  OrderLineCreateInput,
  ProductOptions,
  ProductWithOptions
} from '../../services'

export const getOrderLinesFromBasketProducts = (
  products: ProductWithOptions[]
): OrderLineCreateInput[] => {
  return products.map((product) => {
    const { id, count, orderColor, orderMaterial, orderOptions } = product

    const pickedSingleOptions = orderOptions.map((option) => {
      const variantIdArray = option.variants
        ? option.variants?.map((variant) => variant.id)
        : []
      return {
        optionId: option.id,
        variantId: variantIdArray
      }
    })

    const pickedOptions = [...pickedSingleOptions]

    return {
      productId: id,
      quantity: count,
      colorId: orderColor.id,
      materialId: orderMaterial.id,
      options: pickedOptions
    }
  })
}

export const getProductWithOptionPrice = (product: ProductWithOptions) => {
  const optionsPrice = product.orderOptions.reduce((acc, optionItem) => {
    const extraPrice =
      optionItem.variants?.reduce(
        (price, item) => price + item.extraPrice,
        0
      ) || 0
    return acc + extraPrice
  }, 0)

  const fullPrice =
    product.defaultPrice +
    product.orderColor.extraPrice +
    product.orderMaterial.extraPrice +
    optionsPrice

  return fullPrice
}

export const getProductWithOptionPriceByQuantity = (
  product: ProductWithOptions,
  quantity: number
) => {
  const fullAmountPrice = getProductWithOptionPrice(product) * quantity
  return fullAmountPrice
}

export const getProductPriceFromBasket = (
  product: ProductWithOptions,
  quantity: number,
  currency?: Currency
) => {
  const currencySymbol = getCurrencySymbol(currency)

  return `${currencySymbol}${getProductWithOptionPriceByQuantity(
    product,
    quantity
  ).toFixed(2)}`
}

export const getProductOptionVariantIds = (
  product: ProductWithOptions | ProductOptions
) => {
  const productOption = product.orderOptions.map((item) => {
    const optionVariant = item.variants?.map((variant) => variant.id)

    return optionVariant
  })

  if (productOption.length) {
    const productOptionVariantIds = productOption.reduce((acc, item) => {
      if (acc && item) {
        return [...acc, ...item]
      }

      return undefined
    })

    const productOptionVariantIdsSort = productOptionVariantIds?.sort()

    return productOptionVariantIdsSort
  }

  return undefined
}
