import React, { FC, useState } from 'react'

import { AttentionOfferModal, AttentionOfferModalProps } from '../../views'

import { Column } from '../column'
import { ResponsiveMediaView } from '../responsive-media-view'
import { ShoppingItemLine, ShoppingItemLineMobile } from '../shopping-item-line'

import { ShoppingListItemHeader } from './shopping-list-item-header'
import { useStyle } from './shopping-list-item.styles'
import { ShoppingListItemProps } from './shopping-list-item.types'

export const ShoppingListItem: FC<ShoppingListItemProps> = ({
  className,
  id,
  product,
  quantity,
  vendor,
  productTotalPrice,
  discount,
  productionDays,
  items,
  index,
  description,
  noSuggestion,
  currency,
  onRemove,
  onChangeStatus,
  onContactVendorOffer
}) => {
  const classes = useStyle()

  const [openRemoveOffer, changeOpenRemoveOffer] = useState(false)

  const { companyName } = vendor

  const totalOfferPrice = productTotalPrice - discount
  const days = productionDays === null ? 0 : productionDays

  const handleOnChangeRemoveOfferModal =
    (state: boolean): AttentionOfferModalProps['onClose'] =>
    (event) => {
      event.preventDefault()
      event.stopPropagation()

      changeOpenRemoveOffer(state)
    }

  const handleOnOpenRemoveOfferModal = () => {
    changeOpenRemoveOffer(true)
  }
  const handleOnRemove = () => {
    if (onRemove) {
      onRemove(id)
    }
    if (onChangeStatus) {
      onChangeStatus(id)
    }
  }

  const handleOnContactVendorOffer = () => {
    if (onContactVendorOffer) {
      onContactVendorOffer(id)
    }
  }

  const ListItemLine = (
    <ShoppingItemLine
      className={classes.listContainer}
      productDefaultPrice={product.defaultPrice}
      discount={discount}
      description={description}
      noSuggestion={noSuggestion}
      productName={product.name}
      imgUrl={product.thumbnail2x?.url}
      days={days}
      quantity={quantity}
      totalOfferPrice={totalOfferPrice}
      items={items}
      currency={currency}
    />
  )

  const ListItemLineMobile = (
    <ShoppingItemLineMobile
      className={classes.listContainer}
      productDefaultPrice={product.defaultPrice}
      discount={discount}
      description={description}
      noSuggestion={noSuggestion}
      productName={product.name}
      imgUrl={product.thumbnail2x?.url}
      days={days}
      quantity={quantity}
      totalOfferPrice={totalOfferPrice}
      items={items}
      currency={currency}
      onContactVendor={handleOnContactVendorOffer}
    />
  )

  return (
    <Column fullWidth className={`${className} ${classes.container}`}>
      <ShoppingListItemHeader
        index={index}
        companyName={companyName}
        onOpenRemoveOfferModal={handleOnOpenRemoveOfferModal}
        onContactVendorOffer={handleOnContactVendorOffer}
      />
      <ResponsiveMediaView
        bigScreen={ListItemLine}
        medium={ListItemLine}
        tablet={ListItemLine}
        mobile={ListItemLineMobile}
      />
      <AttentionOfferModal
        open={openRemoveOffer}
        description="This Offer will back to Offer list with the status Accepted."
        descriptionTx="delete.from.cart.description"
        onClose={handleOnChangeRemoveOfferModal(false)}
        onSubmit={handleOnRemove}
      />
    </Column>
  )
}
