import { gql } from '@apollo/client'

import {
  ACCOUNT_ERROR_FRAGMENT,
  USER_FRAGMENT,
  USER_PART_FRAGMENT
} from '../fragments'

export const REGISTER_USER = gql`
  ${ACCOUNT_ERROR_FRAGMENT}
  ${USER_FRAGMENT}
  mutation RegisterAccount(
    $password: String!
    $email: String!
    $sex: Int!
    $role: Int!
    $redirectUrl: String!
    $firstName: String
  ) {
    accountRegister(
      input: {
        password: $password
        email: $email
        firstName: $firstName
        sex: $sex
        role: $role
        redirectUrl: $redirectUrl
      }
    ) {
      accountErrors {
        ...AccountErrorFragment
      }
      jwtToken
      refreshToken
      csrfToken
      talkjsSignature
      user {
        ...UserFragment
      }
    }
  }
`

export const ME_QUERY = gql`
  ${USER_FRAGMENT}
  query Me {
    me {
      ...UserFragment
    }
  }
`

export const PART_ME_QUERY = gql`
  ${USER_PART_FRAGMENT}
  query Me {
    me {
      ...UserPartFragment
    }
  }
`

export const TOKEN_AUTH = gql`
  ${ACCOUNT_ERROR_FRAGMENT}
  ${USER_FRAGMENT}
  mutation TokenAuth($email: String!, $password: String!, $page: String) {
    tokenCreate(email: $email, password: $password, page: $page) {
      accountErrors {
        ...AccountErrorFragment
      }
      user {
        ...UserFragment
      }
      token
      talkjsSignature
      refreshToken
      csrfToken
    }
  }
`

export const TOKEN_REFRESH = gql`
  ${ACCOUNT_ERROR_FRAGMENT}
  ${USER_FRAGMENT}
  mutation TokenRefresh($token: String!) {
    tokenRefresh(refreshToken: $token) {
      accountErrors {
        ...AccountErrorFragment
      }
      user {
        ...UserFragment
      }
      token
      talkjsSignature
    }
  }
`

export const REQUEST_PASSWORD_RESET = gql`
  ${ACCOUNT_ERROR_FRAGMENT}
  mutation RequestPasswordReset($email: String!) {
    requestPasswordReset(email: $email) {
      accountErrors {
        ...AccountErrorFragment
      }
    }
  }
`

export const SET_PASSWORD = gql`
  ${ACCOUNT_ERROR_FRAGMENT}
  mutation SetPassword($email: String!, $token: String!, $password: String!) {
    setPassword(email: $email, token: $token, password: $password) {
      accountErrors {
        ...AccountErrorFragment
      }
      token
      refreshToken
    }
  }
`

export const CHANGE_PASSWORD = gql`
  ${USER_FRAGMENT}
  mutation PasswordChange($newPassword: String!, $oldPassword: String!) {
    passwordChange(newPassword: $newPassword, oldPassword: $oldPassword) {
      accountErrors {
        field
        message
        code
      }
      user {
        ...UserFragment
      }
    }
  }
`

export const ACCOUNT_ADDRESS_CREATE = gql`
  ${ACCOUNT_ERROR_FRAGMENT}
  ${USER_FRAGMENT}
  mutation AccountAddressCreate($input: AddressInput!, $type: AddressTypeEnum) {
    accountAddressCreate(input: $input, type: $type) {
      address {
        ...AddressFragment
      }
      user {
        ...UserFragment
      }
      accountErrors {
        ...AccountErrorFragment
      }
    }
  }
`

export const ACCOUNT_PROFILE_UPDATE = gql`
  ${ACCOUNT_ERROR_FRAGMENT}
  ${USER_FRAGMENT}
  mutation AccountProfileUpdate($input: AccountInput!) {
    accountUpdate(input: $input) {
      user {
        ...UserFragment
      }
      accountErrors {
        ...AccountErrorFragment
      }
    }
  }
`

export const ACCOUNT_ADDRESS_EDIT = gql`
  ${ACCOUNT_ERROR_FRAGMENT}
  ${USER_FRAGMENT}
  mutation accountAddressUpdate($input: AddressInput!, $id: ID!) {
    accountAddressUpdate(input: $input, id: $id) {
      address {
        ...AddressFragment
      }
      user {
        ...UserFragment
      }
      accountErrors {
        ...AccountErrorFragment
      }
    }
  }
`

export const SET_DEFAULT_ADDRESS = gql`
  ${ACCOUNT_ERROR_FRAGMENT}
  ${USER_FRAGMENT}
  mutation AccountSetDefaultAddress($id: ID!, $type: AddressTypeEnum!) {
    accountSetDefaultAddress(id: $id, type: $type) {
      user {
        ...UserFragment
      }
      accountErrors {
        ...AccountErrorFragment
      }
    }
  }
`

export const FEEDBACK_CREATE = gql`
  ${ACCOUNT_ERROR_FRAGMENT}
  mutation FeedbackCreate($email: String!, $message: String!, $name: String!) {
    feedback(email: $email, message: $message, name: $name) {
      accountErrors {
        ...AccountErrorFragment
      }
    }
  }
`
export const ACCOUNT_DELETE = gql`
  ${ACCOUNT_ERROR_FRAGMENT}
  ${USER_FRAGMENT}
  mutation accountDelete($token: String!) {
    accountDelete(token: $token) {
      accountErrors {
        ...AccountErrorFragment
      }
      user {
        ...UserFragment
      }
    }
  }
`

export const IS_USER_EXIST = gql`
  mutation IsUserExists($email: String!) {
    isUserExists(email: $email) {
      isUserExists
    }
  }
`
