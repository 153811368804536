import React, { FC } from 'react'

import { Column } from '../column'
import { Row } from '../row'
import { Icon, ICONS } from '../icon'

import { HeaderOverlayProps } from './header-overlay.types'
import { useStyle } from './header-overlay.styles'

export const HeaderOverlay: FC<HeaderOverlayProps> = ({
  scrollPosition,
  header,
  children,
  onClose
}) => {
  const isScrollPosition = () => {
    if (scrollPosition) {
      return scrollPosition > 10
    }
    return false
  }
  const classes = useStyle({ scroll: isScrollPosition })

  return (
    <Column
      justifyContent="flex-start"
      alignItems="flex-start"
      className={classes.overlay}
    >
      <Column justifyContent="flex-start" className={classes.overlayLeft}>
        <Column alignItems="flex-start" className={classes.navMenuDropdown}>
          <Row
            justifyContent="space-between"
            fullWidth
            className={classes.navMenuDropdownHeader}
          >
            {header}
            <Row className={classes.svgContainer}>
              <Icon
                className={classes.icon}
                src={ICONS.close}
                onClick={onClose}
              />
            </Row>
          </Row>
          {children}
        </Column>
      </Column>
    </Column>
  )
}
