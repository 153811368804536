import React, { FC } from 'react'

import { Text } from '../../text'

import { DropdownItemProps } from './dropdown-item.types'
import { useStyle } from './dropdown-item.styles'

export const DropdownItem: FC<DropdownItemProps> = ({
  disabled = false,
  isScrollable = false,
  item,
  onClick
}) => {
  const classes = useStyle({ isScrollable })
  const { name, nameTx } = item
  const disabledClass = disabled && classes.disabled
  const disabledTextPreset = disabled ? 'h6' : 'body'

  const handleOnClick = () => {
    onClick(item)
  }

  return (
    <button
      className={`${disabledClass} ${classes.container}`}
      onClick={handleOnClick}
    >
      <Text
        className={classes.text}
        text={name}
        tx={nameTx}
        preset={disabledTextPreset}
        color="black"
      />
    </button>
  )
}
