import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    width: 16,
    height: 16,
    padding: 0,
    marginLeft: 8,
    border: 'none',
    background: theme.colors.white
  }
}))
