import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    maxWidth: 568,
    zIndex: 1
  },
  details: {
    padding: '16px',
    width: 'calc(100% - 32px)'
  },
  row: {
    borderBottom: `1px solid ${theme.colors.shadow}`,
    padding: '16px 0px'
  }
}))
