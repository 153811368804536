import { createUseStyles } from 'react-jss'
import { isMobile } from 'react-device-detect'
import { LightTheme } from '../../theme'

import background from '../../assets/images/background-pattern.png'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    backgroundSize: '100vw',
    backgroundRepeat: 'space',
    backgroundImage: `url(${background})`,
    paddingBottom: 24,
    zIndex: 1
  },
  forgotForm: {
    marginTop: isMobile ? 24 : 96,
    width: 536
  },
  forgotSecondaryText: {
    marginTop: 8,
    textAlign: 'center'
  },
  email: {},
  input: {
    marginTop: 56
  },
  submit: {
    marginTop: 123
  },
  logIn: {
    marginTop: 235
  },
  signUp: {
    marginLeft: 4
  },
  signUpContainer: {
    marginTop: 24
  },
  error: {
    position: 'absolute',
    backgroundColor: theme.colors.errorBackground,
    padding: '8px 0',
    marginTop: 80,
    borderRadius: 4
  },
  errorIcon: {
    marginLeft: 19
  },
  errorText: {
    marginLeft: 16
  }
}))
