import React, { FC } from 'react'
import { useTheme } from 'react-jss'
import { isMobileOnly } from 'react-device-detect'

import { getColorByMainStatus, getMessageByMainStatus } from '../../pages'
import { LightTheme } from '../../theme'

import { Column } from '../column'
import { Row } from '../row'
import { Text } from '../text'
import { ColorDot } from '../color-dot'

import { useStyle } from './feedback-order-item.styles'
import { FeedbackOrderItemProps } from './feedback-order-item.types'

export const FeedbackOrderItem: FC<FeedbackOrderItemProps> = ({
  number = '',
  status,
  totalPrice,
  created,
  className = ''
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle()
  const Container = isMobileOnly ? Column : Row
  return (
    <Column
      className={`${classes.container} ${className}`}
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      <Column
        className={classes.content}
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <Row alignItems="flex-start">
          <Text text="#" tx="give.feedback.order.item.number" preset="h4" />
          <Text className={classes.number} text={number} preset="h4" />
        </Row>
        {status && (
          <Row className={classes.status}>
            <ColorDot
              preset="big"
              color={theme.colors[getColorByMainStatus(status)]}
            />
            <Text
              {...getMessageByMainStatus(status)}
              className={classes.statusText}
              preset="h7"
            />
          </Row>
        )}
      </Column>
      <Container className={classes.content} justifyContent="flex-start">
        <Row className={classes.mobileRow}>
          <Text
            text="Date of creation:"
            tx="give.feedback.order.item.date.of.creation"
            preset="body"
            color="inactive"
          />
          {created && (
            <Text className={classes.bottomText} text={created} preset="h7" />
          )}
        </Row>
        <Row className={classes.mobileRow}>
          <Text
            className={classes.priceText}
            text="Order Price:"
            tx="give.feedback.order.item.order.price"
            preset="body"
            color="inactive"
          />
          <Text className={classes.bottomText} text={totalPrice} preset="h7" />
        </Row>
      </Container>
    </Column>
  )
}
