import React from 'react'

import {
  Column,
  LoginOverlay,
  LoginTabs,
  ResponsiveMediaView,
  Text
} from '../../components'
import { SignInView } from '../../views'

import { useStyle } from './login.styles'

export const LoginPage = () => {
  const classes = useStyle()

  const LoginView = (
    <Column fullWidth className={classes.container}>
      <Column className={classes.loginForm}>
        <Text preset="h1" text="Welcome back!" tx="login.welcome" />
        <SignInView errorClassName={classes.error} />
      </Column>
    </Column>
  )

  const LoginViewMobile = (
    <LoginOverlay>
      <LoginTabs activeLogin />
      <SignInView
        className={classes.loginForm}
        errorClassName={classes.error}
      />
    </LoginOverlay>
  )

  return (
    <ResponsiveMediaView
      bigScreen={LoginView}
      medium={LoginView}
      tablet={LoginView}
      mobile={LoginViewMobile}
    />
  )
}
