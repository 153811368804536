import { MouseEvent } from 'react'

import { Color } from '../../theme'

export enum TypeLink {
  PHONE = 'tel:',
  EMAIL = 'mailto:',
  FAX = 'fax:'
}

export type OuterLinkProps = {
  color?: Color
  className?: string
  linkClassName?: string
  link: string
  typeLink?: TypeLink
  text?: string
  iconSrc?: string
  onCopyLink?: (
    link: string,
    event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => void
  copyLink?: boolean
}

export type OuterLinkStyleProps = {
  copyLink?: boolean
}
