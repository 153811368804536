export enum FooterAdditionalLinks {
  PRIVACY_POLICY = '/privacy-policy',
  TERMS_OF_CONDITIONS = '/terms',
  SITE_MAP = '/site-map',
  COOKIE_POLICY = '/cookie-policy',
  CATALOGUE = '/catalogue',
  RETURNS = '/returns',
  TOP_CATEGORIES = '/top-categories',
  PAYMENT_DELIVERY = '/payment-delivery'
}
