import React, { forwardRef } from 'react'
import { useHistory } from 'react-router-dom'

import { useAuth, useBasket, useToastify } from '../../providers'

import { Button } from '../button'
import { Column } from '../column'
import { Content } from '../content'
import { Icon, ICONS } from '../icon'
import { Row } from '../row'
import { Text } from '../text'
import { HeaderBottomBar } from '../header-bottom-bar'

import { useStyle } from './header.styles'

export const Header = forwardRef<HTMLDivElement>((_, ref) => {
  const { isLoggedIn, onLogout } = useAuth()
  const { onClearBasket } = useBasket()
  const { open } = useToastify()

  const handleOnClick = (state: boolean) => () => {
    if (state) {
      open({
        text: 'You are now logged out',
        tx: 'header.logout.plate'
      })
    }
  }

  const handleOnLogout = () => {
    const openToastify = handleOnClick(true)
    if (onLogout && onClearBasket) {
      onClearBasket()
      onLogout()
      openToastify()
    }
  }

  const history = useHistory()

  const classes = useStyle()

  const handleOnNavigate = (path: string) => () => {
    history.push(path)
  }

  return (
    <Column fullWidth ref={ref}>
      <Row className={classes.container}>
        <Content>
          <Row fullWidth justifyContent="space-between">
            <Row>
              <Text
                color="white"
                preset="secondTitle"
                text="Welcome to our store. Call"
                tx="header.welcome"
              />
              <Text
                className={classes.text}
                color="yellow"
                preset="secondTitle"
                text="+78 999 443"
              />
              <Text
                className={classes.text}
                color="white"
                preset="secondTitle"
                text="for support"
                tx="header.support"
              />
            </Row>
            <Row
              className={classes.rightContainer}
              justifyContent="space-between"
            >
              <Row className={classes.button}>
                <Icon src={ICONS.uk} />
                <Text
                  className={classes.flagTitle}
                  color="white"
                  preset="secondTitle"
                  text="ENGLISH"
                />
              </Row>
              <Row className={classes.button}>
                <Text color="white" preset="secondTitle" text="USD" />
              </Row>
              <Row>
                {isLoggedIn ? (
                  <Button
                    className={classes.button}
                    color="yellow"
                    preset="borderButton"
                    text="LOGOUT"
                    textColor="yellow"
                    textPreset="secondTitle"
                    tx="header.logout"
                    onClick={handleOnLogout}
                  />
                ) : (
                  <Row>
                    <Button
                      className={classes.button}
                      color="yellow"
                      preset="borderButton"
                      text="LOGIN"
                      textColor="yellow"
                      textPreset="secondTitle"
                      tx="header.login"
                      onClick={handleOnNavigate('/sign-in')}
                    />
                    <Text
                      className={classes.auth}
                      color="white"
                      preset="secondTitle"
                      text="Sign Up"
                      tx="header.signUp"
                      onClick={handleOnNavigate('/sign-up')}
                    />
                  </Row>
                )}
              </Row>
            </Row>
          </Row>
        </Content>
      </Row>
      <HeaderBottomBar />
      <Column fullWidth id="submenu-header" />
    </Column>
  )
})
