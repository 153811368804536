import * as React from 'react'
import { useHistory } from 'react-router-dom'

import { Content } from '../../content'
import { Row } from '../../row'

import { NavMobile } from './nav-mobile'
import { useStyle } from './footer-mobile.styles'

export const FooterMobile = React.forwardRef<HTMLDivElement>((_, ref) => {
  const classes = useStyle()
  const history = useHistory()
  const isChat = history.location.pathname.includes('/vendor-communication')

  return (
    <Row fullWidth className={classes.footer} id="footer" ref={ref}>
      <Content>{!isChat && <NavMobile />}</Content>
    </Row>
  )
})
