import React, { FC } from 'react'

import {
  Button,
  CategoryListVendorMobile,
  Column,
  Content,
  ProductList,
  Text,
  arrayHasElements,
  PromotionBannerCarousel
} from '../../../components'
import { ProductsDataView } from '../../../views'

import { VendorSummary } from '../vendor-summary'

import { useStyle } from './vendor-view.styles'
import { VendorViewProps } from './vendor-view.types'

export const VendorMobileView: FC<VendorViewProps> = ({
  banner,
  vendor,
  averageRating,
  countryCode,
  positiveFeedback,
  products,
  allProducts,
  categories,
  totalCount,
  count,
  onNext
}) => {
  const classes = useStyle()

  return (
    <Column fullWidth>
      <PromotionBannerCarousel data={banner} timeout={4000} />
      <Content justifyContent="flex-start">
        <Column className={classes.basicInfo} fullWidth>
          <VendorSummary
            data={vendor}
            averageRating={averageRating}
            countryCode={countryCode}
            positiveFeedback={positiveFeedback}
          />

          <ProductList
            mini
            className={classes.productList}
            data={products.slice(0, 4)}
            title="Best Selling Products"
            titlePreset="h1Mobile"
            titleTx="bestSellingProducts.title"
          >
            <Text
              className={classes.description}
              color="inactive"
              preset="h7"
              text="A new collection shot in the warmth of soft evening light. Discover elegant silhouettes, refined details."
              tx="bestSellingProducts.description"
            />
          </ProductList>
        </Column>
        {arrayHasElements(categories) && (
          <CategoryListVendorMobile
            className={classes.categories}
            description="A new collection shot in the warmth of soft evening light. Discover elegant silhouettes, refined details."
            data={categories}
            title="Items by Category"
            titlePreset="h1Mobile"
            titleTx="header.itemsByCategory"
          />
        )}
        {Boolean(totalCount) && (
          <Column fullWidth className={classes.allItemsContainer}>
            <Text
              className={classes.allItemsTitle}
              text="All Items"
              preset="h1Mobile"
            />
            <ProductsDataView className={classes.allItems} data={allProducts} />
            {totalCount > count && (
              <Column fullWidth className={classes.load}>
                <Button
                  color="yellow"
                  preset="primary"
                  textColor="white"
                  textPreset="h5"
                  text="View More"
                  tx="vendor.button.text"
                  onClick={onNext}
                />
              </Column>
            )}
          </Column>
        )}
      </Content>
    </Column>
  )
}
