import { createUseStyles } from 'react-jss'
import { isMobile } from 'react-device-detect'
import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  mainContainer: {
    padding: isMobile ? 12 : 24,
    paddingTop: 0,
    width: isMobile ? 'calc(100% - 24px)' : 'calc(100% - 48px)',
    '& > :not(:last-child)': {
      marginLeft: 0
    }
  },
  container: {
    marginTop: 40
  },
  options: {
    flex: 2.2
  },
  table: {
    marginTop: 24
  },
  productInfo: {
    alignItems: 'flex-start'
  },
  breadcrumbs: {
    marginTop: 16
  },
  buttonContainer: {
    width: 'calc(100% - 24px)',
    padding: '0px 12px 24px 12px',
    background: theme.colors.white,
    position: 'fixed',
    bottom: 0,
    zIndex: 4
  },
  button: {
    marginTop: 24,
    width: 156
  },
  '@media screen and (max-width:480px)': {
    container: {
      marginTop: 24
    },
    mainContainer: {
      padding: '0 12px',
      width: 'calc(100% - 24px)'
    }
  },
  '@media screen and (min-width:481px) and  (max-width:1223px)': {
    mainContainer: {
      padding: '0 16px 40px 16px',
      width: 'calc(100% - 32px)'
    }
  },
  '@media screen and (max-width:1223px)': {
    container: {
      flexDirection: 'column'
    },
    options: {
      flex: 2.2,
      width: '100%'
    }
  }
}))
