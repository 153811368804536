import * as React from 'react'

export const Basket = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      fill="#666"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 8.5c0-1.11-.89-2-2-2h-4v-2c0-1.11-.89-2-2-2h-4c-1.11 0-2 .89-2 2v2H4c-1.11 0-1.99.89-1.99 2L2 19.5c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2v-11zm-8-2v-2h-4v2h4zm-10 2v11h16v-11H4z"
        fill="inherit"
        fillOpacity={0.87}
      />
    </svg>
  )
}
