import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyle } from './text.styles'
import { TextProps } from './text.types'
import { presetComponents } from './text.presets'

export const Text: FC<TextProps> = ({
  className = '',
  description,
  text = '',
  tx,
  values,
  preset = 'body',
  innerHtml,
  color = 'black',
  ...props
}) => {
  const classes = useStyle({ color, text })
  const Component = presetComponents[preset]

  if (!tx) {
    return (
      <Component className={`${className} ${classes[preset]}`} {...props}>
        {text}
      </Component>
    )
  }

  return (
    <Component className={`${className} ${classes[preset]}`} {...props}>
      <FormattedMessage
        id={tx || text}
        description={description}
        defaultMessage={text}
        values={values}
      />
    </Component>
  )
}
