import React from 'react'
import { isMobileOnly } from 'react-device-detect'

import { Content, Text, Column } from '../../components'
import { useStyle } from './privacy-policy.styles'

export const PrivacyPolicyPage = () => {
  const classes = useStyle()
  const titlePreset = isMobileOnly ? 'h1Mobile' : 'h1'
  const subTitlePreset = isMobileOnly ? 'h2Mobile' : 'h2'
  const textPreset = isMobileOnly ? 'h7' : 'h5'

  return (
    <Content className={classes.container} justifyContent="flex-start">
      <Text text="Privacy Policy" preset={titlePreset} tx="page.privacy" />

      <Text
        className={classes.name}
        color="inactive"
        preset={textPreset}
        text="Effective on October 1, 2020"
      />
      <Column className={classes.privacyCont} alignItems="flex-start">
        <Text
          preset="body"
          text="This Privacy Policy applies when you use our Services (described below). We offer our users choices about the data we collect, use and share as described in this Privacy Policy. You acknowledge that your continued use of our Services after we publish or send a notice about our changes to this Privacy Policy means that the collection, use and sharing of your personal data is subject to the updated Privacy Policy, as of its effective date."
        />
        <Text
          className={classes.data}
          preset={subTitlePreset}
          text="1. Data We Collect"
        />
        <Text
          preset="h5"
          className={classes.dataName}
          text="1.1 Data You Provide To Us"
        />
        <Text
          preset="body"
          text="To create an account you need to provide data including your name, email address and/or mobile number, and a password. If you register for a premium Service, you will need to provide payment (e.g., credit card) and billing information."
        />
        <Text
          className={classes.others}
          preset="h5"
          text="1.2 Data From Others"
        />
        <Text
          className={classes.textName}
          preset="body"
          text="You and others may post content that includes information about you (as part of articles, posts, comments, videos) on our Services."
        />
      </Column>
    </Content>
  )
}
