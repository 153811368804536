import React, { FC } from 'react'

import { Row } from '../../row'
import { Text } from '../../text'

import { useStyle } from './offer-list-item-header.styles'
import { OfferListItemHeaderProps } from './offer-list-item-header.types'

export const OfferListItemHeader: FC<OfferListItemHeaderProps> = ({
  offerImage,
  productName
}) => {
  const classes = useStyle()

  return (
    <Row fullWidth justifyContent="space-between">
      <Row justifyContent="flex-start">
        <Row
          fullWidth
          className={classes.statusRow}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <img
            className={classes.productAvatar}
            height="64px"
            width="64px"
            src={offerImage}
          />
          <Text className={classes.text} text={productName} preset="h5" />
        </Row>
      </Row>
    </Row>
  )
}
