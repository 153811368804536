import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    maxwidth: 512,
    maxHeight: 512,
    padding: '4px 0',
    zIndex: 1
  },
  details: {
    maxHeight: 432,
    overflowY: 'auto'
  },
  detailsHeader: {
    padding: 24,
    width: 'calc(100% - 48px)'
  },
  detailsHeaderItem: {
    marginTop: 16
  },
  text: {
    marginLeft: 24
  },
  header: {
    backgroundColor: theme.colors.greyFon,
    padding: '8px 24px',
    width: 'calc(100% - 48px)'
  },
  headerText: {
    width: 232
  },
  infoContainer: {
    padding: 24,
    width: 'calc(100% - 48px)'
  },
  totalPrice: {
    padding: '16px 24px',
    width: 'calc(100% - 48px)',
    marginTop: 16,
    background: theme.colors.lightBlue
  },
  discount: {
    marginTop: 16
  },
  idsContainer: {
    padding: '16px 24px'
  },
  ids: {
    marginTop: 4,
    columnGap: 4,
    rowGap: 4
  },
  idsItem: {
    backgroundColor: theme.colors.greyFon,
    border: `1px solid ${theme.colors.shadow}`,
    borderRadius: 4,
    padding: 8
  }
}))
