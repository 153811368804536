import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  results: {
    marginLeft: 4
  },
  count: {
    lineHeight: '18px'
  },
  filter: {
    position: 'relative',
    display: 'flex',
    backgroundColor: theme.colors.white,
    padding: '16px 0',
    width: '100%'
  },
  title: {
    marginTop: 24
  },
  filterOverlay: {
    position: 'fixed',
    backgroundColor: theme.colors.white,
    height: '100%',
    minHeight: '100vh',
    top: 0,
    left: 0,
    zIndex: 2
  },
  filterHeader: {
    display: 'flex',
    alignItems: 'center',
    height: 57,
    borderBottom: `1px solid ${theme.colors.shadow}`,
    width: '100%'
  },
  filterSvgContainer: {
    display: 'flex',
    margin: '0 12px'
  },
  filterText: {
    padding: '16px 12px'
  },
  icon: {
    cursor: 'pointer',
    '&:hover > path': {
      fill: theme.colors.yellow
    }
  },
  infoBottom: {
    width: 'calc(100% - 24px)',
    padding: '24px 12px',
    background: theme.colors.white,

    boxShadow: '4px 8px 24px rgba(0, 0, 0, 0.16)',
    position: 'fixed',
    bottom: 0,
    zIndex: 24
  }
}))
