import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../theme'

import { Column } from '../column'
import { NavLink } from '../nav-link'

import { NavCategoryProps } from './nav-category.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    width: 190
  },
  navLink: {
    marginTop: 16,
    '& > span:hover': {
      color: theme.colors.yellow
    }
  }
}))

export const NavCategory: FC<NavCategoryProps> = ({
  className = '',
  categoryColor: categoryColorOverride,
  subItemColor: subItemColorOverride,
  item,
  additionalSection
}) => {
  const classes = useStyle()
  const categoryColor = categoryColorOverride || 'black'
  const subItemColor = subItemColorOverride || 'inactive'

  return (
    <Column
      alignItems="flex-start"
      justifyContent="flex-start"
      className={`${className} ${classes.container}`}
    >
      <NavLink
        color={categoryColor}
        text={item.name}
        preset="body"
        to={item.url}
      />
      <Column alignItems="flex-start" justifyContent="flex-start">
        {item.children.map((subItem) => (
          <NavLink
            key={subItem.id}
            className={classes.navLink}
            color={subItemColor}
            text={subItem.name}
            preset="secondTitle"
            to={subItem.url}
          />
        ))}
        {additionalSection}
      </Column>
    </Column>
  )
}
