import React, { ChangeEvent, useMemo, useState } from 'react'
import {
  arrayHasElements,
  AvatarItem,
  Button,
  CabinetPageContainer,
  Column,
  getListFromEdgesList,
  Icon,
  ICONS,
  Loader,
  ResponsiveMediaView,
  Row,
  Text,
  OuterLink
} from '../../components'
import { useApi } from '../../providers'
import { Avatar, VtmtVendor } from '../../services'
import {
  ChooseVendorModal,
  ConfirmationModal,
  EmptyList,
  SendAvatarModal
} from '../../views'

import googlePlayIcon from '../../components/icon/icons/google_play.svg'
import appStoreIcon from '../../components/icon/icons/app_store.svg'

import { useStyle } from './vtmt-avatarts.styles'

const STORES_LINKS = {
  appStore: {
    ariaLabel: 'appStore',
    href: 'https://apps.apple.com/ua/app/ovtmt/id1545586316?l=ru',
    icon: appStoreIcon
  },
  googlePlay: {
    ariaLabel: 'googlePlay',
    href: 'https://play.google.com/store/apps/details?id=com.ovafloinc.com&hl=ru',
    icon: googlePlayIcon
  }
}

export const VTMTAvatarsPage = () => {
  const first = 8
  const vendorFirst = 100

  const { avatar } = useApi()
  const { data, loading, refetch } = avatar.useAvatars({ first })
  const { data: vendors, refetch: refetchVendors } = avatar.useVtmtVendors({
    first: vendorFirst
  })
  const { onSubmit: onShareAvatar } = avatar.useShareAvatar()
  const classes = useStyle()
  const [openChooseVendor, changeOpenChooseVendor] = useState(false)
  const [openSendAvatar, changeOpenSendAvatar] = useState(false)
  const [openConfirm, changeOpenConfirm] = useState(false)
  const [currentAvatar, changeCurrentAvatar] = useState<Avatar>()
  const [activeIds, changeActiveIds] = useState<string[]>([])

  const Avatars = useMemo(() => getListFromEdgesList(data?.avatars), [data])
  const Vendors = useMemo(
    () => getListFromEdgesList(vendors?.vtmtVendors),
    [vendors]
  )

  const ActiveVendors = useMemo(() => {
    return activeIds.reduce<VtmtVendor[]>((acc, id) => {
      const activeItem = Vendors.find((item) => item.id === id)

      if (activeItem) {
        return [...acc, activeItem]
      }

      return acc
    }, [])
  }, [activeIds, Vendors])

  const handleOnToRefreshClick = () => {
    if (refetch) {
      refetch({ first })
    }
  }

  const handleOnLoadMore = () => {
    if (refetch && data) {
      const { edges } = data.avatars
      const { cursor = '' } = edges[edges.length - 1]

      refetch({ first, after: cursor })
    } else if (refetch) {
      refetch({ first })
    }
  }

  const handleClearVendorState = () => {
    if (refetchVendors) {
      refetchVendors({ first: vendorFirst, filter: undefined })
    }
  }

  const handleOnSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    if (refetchVendors) {
      refetchVendors({ first: vendorFirst, filter: { search: value } })
    }
  }

  const handleOnOpenChooseVendor = (state: boolean) => () => {
    if (state) {
      changeActiveIds([])
    } else {
      handleClearVendorState()
    }

    changeOpenChooseVendor(state)
  }

  const handleOnOpenSendAvatar = (state: boolean) => () => {
    changeOpenSendAvatar(state)
    handleClearVendorState()
  }

  const handleOnOpenConfirm = (state: boolean) => () => {
    changeOpenConfirm(state)
  }

  const handleOnToShareClick = (id: string) => () => {
    const openModal = handleOnOpenChooseVendor(true)

    const nextAvatar = Avatars.find((item) => item.id === id)
    if (nextAvatar) {
      changeCurrentAvatar(nextAvatar)

      openModal()
    }
  }

  const handleOnSendAvatar = (nextIds: string[]) => {
    changeOpenChooseVendor(false)
    changeOpenSendAvatar(true)
    changeActiveIds(nextIds)
  }

  const handleOnSubmit = () => {
    if (currentAvatar?.id) {
      onShareAvatar({
        avatarId: currentAvatar?.id || '',
        vendorEmails: activeIds
      })
    }
    changeActiveIds([])
  }

  const handleOnRemoveActive = (id: string) => {
    changeActiveIds(activeIds.filter((currentId) => currentId !== id))
  }

  const handleOnSuccess = () => {
    const closeModalSendAvatar = handleOnOpenSendAvatar(false)
    const openModalConfirm = handleOnOpenConfirm(true)

    handleOnSubmit()
    closeModalSendAvatar()
    openModalConfirm()
  }

  if (loading) {
    return <Loader />
  }

  if (!arrayHasElements(Avatars)) {
    return (
      <Column fullWidth>
        <EmptyList
          headerText="VTMT/Avatars"
          headerTx="emptyAvatars.title.text"
          title="Oops! Your Avatars list is empty"
          titleTx="emptyAvatars.second.title"
          text="Create your avatar in the mobile application OVAFLOPICK and it will automatically appear in this list."
          tx="emptyAvatars.text.about.problem"
          onRefresh={handleOnToRefreshClick}
        />
        <Row className={classes.rows}>
          <OuterLink
            className={classes.icons}
            iconSrc={STORES_LINKS.appStore.icon}
            link={STORES_LINKS.appStore.href}
          />
          <OuterLink
            className={classes.play}
            iconSrc={STORES_LINKS.googlePlay.icon}
            link={STORES_LINKS.googlePlay.href}
          />
        </Row>
      </Column>
    )
  }

  const TitleRow = (
    <Row className={classes.th} justifyContent="flex-start">
      <Text
        className={`${classes.nameTh} ${classes.name} `}
        text="Name"
        tx="vtmtAvatars.name"
        preset="body"
      />
      <Text
        className={classes.id}
        text="ID"
        tx="vtmtAvatars.id"
        preset="body"
      />
      <Text
        className={classes.date}
        text="Date"
        tx="vtmtAvatars.date"
        preset="body"
      />
      <Row className={classes.btnContainer}></Row>
    </Row>
  )
  const totalCount = data?.avatars.totalCount || 0
  const count = Avatars.length

  const rightHeaderContent = (
    <Row className={classes.icon}>
      <Icon src={ICONS.refresh} onClick={handleOnToRefreshClick} />
    </Row>
  )

  return (
    <CabinetPageContainer
      label="VTMT/Avatars"
      labelTx="emptyAvatars.title.text"
      rightHeaderContent={rightHeaderContent}
    >
      <Column
        className={classes.avatars}
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <ResponsiveMediaView
          bigScreen={TitleRow}
          medium={TitleRow}
          tablet={TitleRow}
        />

        {Avatars.map((avatarItem) => (
          <AvatarItem
            key={`avatar_item_${avatarItem.id}`}
            {...avatarItem}
            createdAt={avatarItem.createdAt}
            onToShareClick={handleOnToShareClick(avatarItem.id)}
          />
        ))}
      </Column>
      {totalCount > count && (
        <Column fullWidth className={classes.load}>
          <Button
            className={classes.button}
            color="yellow"
            preset="button3"
            textColor="white"
            textPreset="h5"
            text="Load More"
            tx="vtmtAvatars.loadMore"
            onClick={handleOnLoadMore}
          />
        </Column>
      )}
      {openChooseVendor && (
        <ChooseVendorModal
          open={openChooseVendor}
          data={Vendors}
          onSearch={handleOnSearch}
          onClose={handleOnOpenChooseVendor(false)}
          onSubmit={handleOnSendAvatar}
        />
      )}
      {openSendAvatar && (
        <SendAvatarModal
          avatar={currentAvatar}
          open={openSendAvatar}
          data={ActiveVendors}
          onClose={handleOnOpenSendAvatar(false)}
          onSubmit={handleOnSuccess}
          onRemove={handleOnRemoveActive}
        />
      )}
      {openConfirm && (
        <ConfirmationModal
          title="All right!"
          txTitle="confirmation.second.title.text"
          text="Your avatar has been successfully sent to selected Vendors."
          tx="confirmation.text"
          open={openConfirm}
          onClose={handleOnOpenConfirm(false)}
        />
      )}
    </CabinetPageContainer>
  )
}
