import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    height: 48,
    width: 'calc(100% - 48px)',
    padding: '6px 24px',
    border: '1px solid rgba(102, 102, 102, 0.87)',
    borderRadius: 4
  },
  label: {
    transformOrigin: '0 0',
    transform: 'scale(0.9)',
    color: theme.colors.yellow
  },
  caretDown: {
    transform: 'rotate(0deg)',
    cursor: 'pointer'
  },
  caretUp: {
    transform: 'rotate(180deg) translateY(2px)',
    cursor: 'pointer'
  }
}))
