import React, { FC } from 'react'

import { QuestionModal, Text } from '../../components'
import { useStyle } from './delete-offer-modal.styles'

import { DeleteOfferModalProps } from './delete-offer-modal.types'

export const DeleteOfferModal: FC<DeleteOfferModalProps> = ({
  open,
  description,
  descriptionTx,
  onClose,
  onSubmit
}) => {
  const classes = useStyle()

  if (!open) {
    return <></>
  }

  return (
    <QuestionModal
      className={classes.modal}
      title="Delete Offer"
      titleTx="deleteOffer.modal.title"
      successText="Delete"
      successTx="deleteOffer.modal.success"
      cancelText="Back"
      cancelTx="deleteOffer.modal.cancel"
      onClose={onClose}
      onSubmit={onSubmit}
    >
      <Text
        className={classes.title}
        color="darkBlue"
        preset="h4"
        text="Are you sure?"
        tx="declineOrder.modal.secondaryTitle"
      />
      <Text
        className={classes.description}
        color="inactive"
        preset="body"
        text={description}
        tx={descriptionTx}
      />
    </QuestionModal>
  )
}
