import { createUseStyles } from 'react-jss'

import background from '../../assets/images/error.png'
import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    minHeight: 850,
    justifyContent: 'flex-start',
    backgroundSize: '80%',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${background})`
  },
  firstColumn: {
    flex: 1.5,
    marginTop: 174,
    marginLeft: 370
  },
  errorNumber: {
    marginTop: 8
  },
  secondColumn: {
    flex: 1
  },
  title: {},
  page: {},
  textError: {
    marginTop: 32,
    maxWidth: 664
  },
  button: {
    cursor: 'pointer',
    marginTop: 32,
    width: 364,
    padding: 16,
    backgroundColor: theme.colors.yellow
  },
  row: {},
  '@media screen and (max-width: 480px)': {
    container: {
      backgroundSize: '100%',
      height: '100%'
    },
    firstColumn: {
      marginTop: 40,
      marginLeft: 0
    },
    row: {
      flexDirection: 'column',
      alignItems: 'center'
    },
    button: {
      marginTop: 24,
      padding: 12,
      width: 'calc(100% - 24px)'
    },
    secondColumn: {
      width: 'calc(100% - 24px)',
      flex: 1,
      marginTop: 100
    }
  },
  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    container: {
      width: 'calc(100% - 64px)',
      padding: '0px 32px 32px 32px',
      backgroundSize: '100%',
      height: '100%'
    },

    row: {
      flexDirection: 'column'
    },
    errorNumber: {
      fontSize: 48,
      lineHeight: 1.3,
      marginTop: 24
    },
    firstColumn: {
      marginTop: 140,
      marginLeft: 258
    },
    textError: {
      maxWidth: '100%'
    },
    secondColumn: {
      marginTop: 174
    },
    page: {
      marginTop: 24
    }
  }
}))
