import React from 'react'
import { useHistory } from 'react-router-dom'

import { useApi } from '../../providers'
import paymentImg from '../../assets/images/payment-icons.png'

import { Row } from '../row'
import { Column } from '../column'
import { Button } from '../button'
import { Text } from '../text'
import { Content } from '../content'
import { Loader } from '../loader'

import { NavCategory } from './nav-category'
import { NavSocialBlock } from './nav-social'
import { useStyle } from './nav.styles'

export const Nav = () => {
  const { menu } = useApi()
  const history = useHistory()
  const { data, loading } = menu.useSecondaryMenu()
  const classes = useStyle()

  if (!data || loading) {
    return <Loader />
  }

  const categories = data.shop.navigation.secondary.items

  const handleOnNavigate = (path: string) => () => {
    history.push(path)
  }

  return (
    <Content>
      <Row
        wrap
        fullWidth
        className={classes.innerContainer}
        alignItems="flex-start"
        justifyContent="space-between"
      >
        {categories.map((item, index) => {
          const additionalSection = index === 0 && (
            <NavSocialBlock
              className={classes.social}
              classNameIcon={classes.socialIcon}
            />
          )
          return (
            <NavCategory
              className={classes.category}
              additionalSection={additionalSection}
              key={item.id}
              item={item}
            />
          )
        })}
        <Column
          className={classes.category}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Text
            color="black"
            preset="body"
            text="Newsletter"
            tx="footer.newsletter"
          />
          <Row
            className={classes.subscribeBtn}
            fullWidth
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Button
              text="SUBSCRIBE"
              tx="footer.subscribe"
              textColor="black"
              textPreset="maxButton"
              preset="button5"
              onClick={handleOnNavigate('/subscription')}
            />
          </Row>
          <img
            className={classes.paymentBlock}
            src={paymentImg}
            alt="Available for pay"
          />
          <Row className={classes.paymentBlock}>
            <Text
              color="shadow"
              preset="secondTitle"
              text="Copyright by"
              tx="footer.copyright"
            />
            <Text
              color="yellow"
              className={classes.brand}
              preset="secondTitle"
              text="Ovaflopick"
            />
          </Row>
        </Column>
      </Row>
    </Content>
  )
}
