export type Image = {
  id: string
  url: string
  alt: string
}

export type CarouselProps = {
  carousel?: boolean
  className?: string
  data: Image[]
  mini?: boolean
  onClick: (image: Image) => void
}

export type CarouselStyles = {
  carousel?: boolean
  active?: boolean
}

export enum ScrollDirectionVertical {
  TOP = 'top',
  BOTTOM = 'bottom'
}
