import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../../theme'
import { Row } from '../../../components'
import { MainRoutes } from '../../../routes'

import { MakerDesignerCard } from '../maker-designer-card'

import { useStyle } from './maker-designer-list.styles'
import { MakerDesignerListProps } from './maker-designer-list.types'

export const MakerDesignerList: FC<MakerDesignerListProps> = ({ data }) => {
  const theme = useTheme<LightTheme>()
  const classes = useStyle()

  return (
    <Row wrap>
      {data.map(({ id, companyName, avatar }, index) => {
        const nextVendorPage = `${MainRoutes.VENDOR}/${id}`
        const isAvatar = avatar && avatar.url
        const background = theme.colors.active

        return (
          <Link
            key={`vendor_${id}_${index}`}
            className={classes.vendor}
            to={nextVendorPage}
          >
            <MakerDesignerCard
              background={background}
              id={id}
              name={`${companyName}`}
              avatar={isAvatar}
            />
          </Link>
        )
      })}
    </Row>
  )
}
