import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'

import { Product, Row } from '../../components'

import { EmptyProducts } from './empty-products'
import { useStyle } from './products-data.styles'
import { ProductsDataViewProps } from './products-data.types'

export const ProductsDataView: FC<ProductsDataViewProps> = ({
  categoryBreadcrumb,
  className = '',
  count,
  mini,
  data
}) => {
  const emptyPage = count === 0

  const classes = useStyle({ mobile: isMobileOnly })
  const justifyContent = isMobileOnly ? 'center' : 'flex-start'
  const history = useHistory()
  const isWishlist = history.location.pathname.includes('/wish-list')

  if (emptyPage && !isWishlist) {
    return (
      <EmptyProducts
        className={classes.empty}
        title="There are no items at the moment."
        titleTx="no.items.description"
      />
    )
  }

  return (
    <Row
      fullWidth
      wrap
      className={className}
      justifyContent={justifyContent}
      alignItems="flex-start"
    >
      {data.map(
        ({
          id,
          colors,
          image,
          minCurrency,
          maxCurrency,
          maxPrice,
          minPrice,
          totalPrice,
          name,
          isFavorite,
          productionDaysUpTo,
          brandName,
          countryOfOrigin,
          materialComposition,
          isSale,
          discountMinPrice,
          discountMaxPrice,
          options
        }) => (
          <Product
            key={`product_${id}`}
            id={id}
            mini={mini}
            colors={colors}
            className={classes.product}
            image={image}
            isFavorite={isFavorite}
            minCurrency={minCurrency}
            maxCurrency={maxCurrency}
            maxPrice={maxPrice}
            minPrice={minPrice}
            totalPrice={totalPrice}
            name={name}
            isSale={isSale}
            productionDaysUpTo={productionDaysUpTo}
            brandName={brandName}
            countryOfOrigin={countryOfOrigin}
            materialComposition={materialComposition}
            discountMinPrice={discountMinPrice}
            discountMaxPrice={discountMaxPrice}
            categoryBreadcrumb={categoryBreadcrumb}
            options={options}
          />
        )
      )}
    </Row>
  )
}
