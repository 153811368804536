import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {},
  details: {
    marginTop: 16,
    width: '100%'
  },
  statusRow: {
    marginLeft: 8
  },

  status: {
    marginTop: 4
  },
  productionText: {
    marginTop: 8
  },
  statusColumn: {
    marginLeft: 24
  },
  button: {
    marginLeft: 24,
    height: 16,
    width: 104,
    padding: '12px 8px'
  },
  buttonBorder: {
    marginLeft: 24,
    height: 16,
    width: 108,
    padding: '10px 6px'
  }
})
