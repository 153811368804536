import { OfferStatus } from '../../services'
import { Color } from '../../theme'

export const getColorByOfferStatus = (status: OfferStatus): Color => {
  switch (status) {
    case OfferStatus.NEW:
      return 'yellow'
    case OfferStatus.DRAFT:
      return 'inactiveSecondary'
    case OfferStatus.ACCEPTED:
      return 'active'
    case OfferStatus.ACCEPTED_BACK_FROM_CART:
      return 'active'
    case OfferStatus.START_OFFER:
      return 'inactiveSecondary'

    default:
      return 'error'
  }
}
