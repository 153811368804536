import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    background: `${theme.colors.shadow}88`,
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100vh',
    width: '100vw',
    zIndex: 100
  },
  modal: {
    backgroundColor: theme.colors.white,
    borderRadius: 4,
    position: 'relative',
    height: 804,
    minWidth: 660
  },
  title: {
    padding: 16,
    width: 'calc(100% - 32px)',
    border: `1px solid ${theme.colors.shadow}`
  },
  bottomContainer: {
    alignSelf: 'stretch',
    padding: 24,
    width: 'calc(100% - 48px)'
  },
  illustration: {
    opacity: 0.7
  }
}))
