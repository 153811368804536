import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'
import { CheckboxStyleProps } from './checkbox.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    height: 24,
    width: 24,
    cursor: 'pointer'
  },
  innerContainer: {
    background: theme.colors.white,
    border: ({ active }: CheckboxStyleProps) =>
      active ? 'none' : `2px solid ${theme.colors.black}`,
    borderRadius: ({ round }: CheckboxStyleProps) => (round ? '50%' : '4px'),

    height: 14,
    width: 14
  }
}))
