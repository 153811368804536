import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  productInfo: {
    '& > *:not(:first-child)': {
      marginTop: 12
    }
  },
  titleContainer: {
    minHeight: 48
  },
  title: {
    width: 'calc(100% - 64px)',
    textAlign: 'left'
  },
  favorite: {
    background: theme.colors.white,
    borderRadius: 40,
    boxShadow: `1px 2px 8px ${theme.colors.shadow}`,
    height: 40,
    position: 'absolute',
    right: 0,
    top: 6,
    width: 40,
    cursor: 'pointer'
  },
  stars: {
    marginTop: 24,
    maxHeight: 24,
    '& > div > label > svg': {
      width: 24
    }
  },
  price: {
    marginLeft: 4
  }
}))
