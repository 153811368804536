import React, { FC } from 'react'
import { isMobileOnly } from 'react-device-detect'

import { Column, Row } from '../../../components'
import { getVendorInfo } from '../../../pages'

import { ProductPreviewGallery } from '../product-preview-gallery'
import { ProductPreviewBasicProductInfo } from '../product-preview-basic-product-info'
import { ProductPreviewVendorInfo } from '../product-preview-vendor-info'
import { ProductPreviewButtonsRow } from '../product-preview-buttons-row'
import { ProductPreviewDetails } from '../product-preview-details'

import { ProductPreviewGeneralInfoProps } from './product-preview-general-info.types'
import { useStyle } from './product-preview-general-info.styles'

export const ProductPreviewGeneralInfo: FC<ProductPreviewGeneralInfoProps> = ({
  product,
  countryCode,
  favouriteIcon,
  averageRating,
  onAddToWishlistClick,
  onGetNewOffer,
  onContactVendor
}) => {
  const classes = useStyle()
  const Container = isMobileOnly ? Column : Row

  const {
    images = [],
    minCurrency,
    totalPrice,
    name,
    vendor,
    isSale,
    discountMaxPrice,
    deliveryPrice
  } = product

  const { id: vendorId, companyName } = getVendorInfo(vendor)

  const productDeliveryPrice = deliveryPrice !== null ? deliveryPrice?.price : 0

  return (
    <Container
      fullWidth
      className={classes.container}
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      <Row className={classes.imageContainer}>
        <ProductPreviewGallery images={images} />
      </Row>
      <Column
        fullWidth
        className={classes.infoContainer}
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <ProductPreviewBasicProductInfo
          favouriteIcon={favouriteIcon}
          name={name}
          isSale={isSale}
          currency={minCurrency}
          deliveryPrice={productDeliveryPrice}
          totalPrice={totalPrice}
          averageRating={averageRating}
          discountMaxPrice={discountMaxPrice}
          onAddToWishlistClick={onAddToWishlistClick}
        />
        <ProductPreviewVendorInfo
          id={vendorId}
          className={classes.vendorInfo}
          name={companyName}
          countryCode={countryCode}
        />
        <ProductPreviewButtonsRow
          onContactVendor={onContactVendor}
          onGetNewOffer={onGetNewOffer}
        />
        <Column fullWidth className={classes.sliderContainer}>
          <ProductPreviewDetails product={product} id={product.id} />
        </Column>
      </Column>
    </Container>
  )
}
