import React, { FC } from 'react'

import { Button, Column, Modal, Row, Text } from '../../components'

import { useStyle } from './attention-offer-modal.styles'
import { AttentionOfferModalProps } from './attention-offer-modal.types'

export const AttentionOfferModal: FC<AttentionOfferModalProps> = ({
  open,
  title = 'Remove Offer',
  titleTx = 'remove.offer.modal.title',
  description,
  descriptionTx,
  onClose,
  onSubmit
}) => {
  const classes = useStyle()

  if (!open) {
    return <></>
  }

  return (
    <Modal title={title} titleTx={titleTx} onClose={onClose}>
      <Column fullWidth className={classes.content} justifyContent="flex-start">
        <Text color="darkBlue" text="Attention!" preset="h4" />
        <Text preset="body" text={description} tx={descriptionTx} />
      </Column>
      <Row className={classes.bottom} fullWidth justifyContent="flex-end">
        <Button
          color="yellow"
          preset="secondaryPopup"
          textColor="white"
          textPreset="maxButton"
          text="OK"
          tx="remove.offer.success"
          onClick={onSubmit}
        />
      </Row>
    </Modal>
  )
}
