import React, { useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'

import { useApi } from '../../providers'
import {
  Button,
  Column,
  Text,
  Content,
  GoBackContainer,
  OrderPreviewOfferTable,
  Row,
  OrderPreviewOfferTableMobile,
  ResponsiveMediaView
} from '../../components'

import { MainRoutes } from '../../routes'
import { EmptyList } from '../../views'
import { OfferStatus, OfferUpdateVariables } from '../../services'

import { useStyle } from './order-preview-offer.styles'
import {
  getPickedColorCode,
  getPickedColorTitle,
  getPickedMaterial
} from './helpers'

export type OrderPreviewOfferPageParams = {
  offerId: string
}

export const OrderPreviewOfferPage = () => {
  const { offerId: id } = useParams<OrderPreviewOfferPageParams>()
  const { offer: offerApi } = useApi()
  const classes = useStyle()
  const history = useHistory()
  const { data } = offerApi.useOfferById({ id })
  const { onSubmit } = offerApi.useOfferUpdate()

  const Offer = useMemo(() => {
    if (data) {
      return data.offer
    }
    return undefined
  }, [data])

  const Product = useMemo(() => {
    if (data) {
      return data.offer.product
    }
    return undefined
  }, [data])

  const colorName = Offer && getPickedColorTitle(Offer.productColors)
  const colorCode = Offer && getPickedColorCode(Offer.productColors)

  const handleOnClick = () => {
    history.push(`${MainRoutes.DELIVERY_INFO_OFFER}/${id}`)
  }

  const handleOnClickHome = () => {
    history.push('/')
  }
  const handleGoBack = () => history.goBack()

  const handleOnRemoveOffer = () => {
    const variables: OfferUpdateVariables = {
      id,
      input: {
        status: OfferStatus.DECLINED
      }
    }

    if (onSubmit) {
      onSubmit(variables)
    }
  }

  const isEmptyList =
    Offer === undefined || Offer.status === OfferStatus.DECLINED
  const titlePreset = isMobileOnly ? 'h1Mobile' : 'h1'

  const OrderPreviewOffer = Offer && (
    <OrderPreviewOfferTable
      number={Offer.number}
      companyName={Offer.vendor.companyName}
      product={Product}
      material={getPickedMaterial(Offer.productMaterials)}
      colorName={colorName}
      colorCode={colorCode}
      options={Offer.productOptions}
      offerOptions={Offer.options}
      productTotalPrice={Offer.productTotalPrice}
      totalPrice={Offer.totalPrice}
      id={id}
      offerQuantity={Offer.quantity}
      onRemoveOffer={handleOnRemoveOffer}
    />
  )

  const OrderPreviewOfferMobile = Offer && (
    <OrderPreviewOfferTableMobile
      number={Offer.number}
      companyName={Offer.vendor.companyName}
      product={Product}
      material={getPickedMaterial(Offer.productMaterials)}
      colorName={colorName}
      colorCode={colorCode}
      options={Offer.productOptions}
      offerOptions={Offer.options}
      productTotalPrice={Offer.productTotalPrice}
      totalPrice={Offer.totalPrice}
      id={id}
      offerQuantity={Offer.quantity}
      onRemoveOffer={handleOnRemoveOffer}
    />
  )

  return (
    <Column fullWidth>
      <Column className={classes.previewPage}>
        {!isEmptyList && (
          <GoBackContainer
            text="Back to Offers"
            textTx="order.preview.back.to.offers"
            onClick={handleGoBack}
          />
        )}
      </Column>
      <Content justifyContent="flex-start">
        <Column className={classes.container}>
          <Text
            className={classes.text}
            text="Order Preview"
            tx="order.preview.title"
            preset={titlePreset}
            color="black"
          />
          {Offer && !isEmptyList && (
            <>
              <ResponsiveMediaView
                bigScreen={OrderPreviewOffer}
                medium={OrderPreviewOffer}
                tablet={OrderPreviewOffer}
                mobile={OrderPreviewOfferMobile}
              />

              <Row className={classes.buttonRow}>
                <Button
                  className={classes.submit}
                  preset="primary"
                  textColor="white"
                  textPreset="h5"
                  text="CONTINUE"
                  tx="order.preview.confirm.button"
                  onClick={handleOnClick}
                />
              </Row>
            </>
          )}

          {isEmptyList && (
            <Column fullWidth>
              <EmptyList
                title="Oops! No Order"
                titleTx="order.preview.empty.title"
                text="Go find the product you like."
                tx="order.preview.empty.text"
              />
              <Button
                className={classes.emptyButton}
                color="yellow"
                preset="primary"
                textColor="white"
                textPreset="h5"
                text="HOME"
                tx="error.404.button.text"
                onClick={handleOnClickHome}
              />
            </Column>
          )}
        </Column>
      </Content>
    </Column>
  )
}
