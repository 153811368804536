import React, { FC } from 'react'

import {
  Column,
  Row,
  Text,
  VendorItemList,
  Icon,
  ICONS,
  QuestionModal
} from '../../components'

import { useStyle } from './send-avatar.styles'
import { SendAvatarProps } from './send-avatar.types'

export const SendAvatarModal: FC<SendAvatarProps> = ({
  open,
  avatar,
  data,
  onClose,
  onSubmit,
  onRemove
}) => {
  const classes = useStyle()

  const handleOnClick = () => {
    onSubmit()
  }

  const handleOnRemove = (id: string) => () => {
    if (onRemove) {
      onRemove(id)
    }
  }

  if (!open) {
    return <></>
  }

  return (
    <QuestionModal
      title="Send Avatar"
      successText="Send"
      successTx="choose.vendor.button.secondary.text"
      onSubmit={handleOnClick}
      onClose={onClose}
    >
      <Column>
        <Row>
          <img className={classes.img} src={avatar?.previewImage} />
          <Text className={classes.title} preset="h5" text={avatar?.username} />
        </Row>
        <Row>
          <Text
            className={classes.text}
            preset="body"
            text="Are you sure you want to send this avatar to the following vendors?"
          />
        </Row>
      </Column>
      <Row wrap fullWidth justifyContent="center">
        {data.map(
          ({
            id,
            previewImage,
            background,
            displayName = '',
            companyName = ''
          }) => (
            <Row
              key={`send_avatar_${id}`}
              className={classes.itemContainer}
              justifyContent="flex-start"
              onClick={handleOnRemove(id)}
            >
              <VendorItemList
                className={classes.item}
                background={background}
                image={previewImage}
                text={displayName}
                description={companyName}
              />
              <Row className={classes.icon}>
                <Icon height={32} width={32} src={ICONS.close} />
              </Row>
            </Row>
          )
        )}
      </Row>
    </QuestionModal>
  )
}
