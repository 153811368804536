export const BACKGROUND_IMAGE_FRAGMENT = `
fragment BackgroundImageFragment on Image {
  url
  alt
}
`

export const CATEGORY_FRAGMENT = `
${BACKGROUND_IMAGE_FRAGMENT}
fragment CategoryFragment on Category {
  id
  name
  description
  level
  slug
  backgroundImage {
    ...BackgroundImageFragment
  },
  products(first: 1) {
    edges {
      node {
        id
        name
      }
    }
  }
}
`
