import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../theme'

import background from '../../assets/images/empty-avatars.png'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    backgroundColor: theme.colors.white,
    padding: '24px 0 20px'
  },
  image: {
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top, center left 50px',
    backgroundImage: `url(${background})`,
    zIndex: 1,
    width: '100%',
    height: 300
  },
  title: {
    padding: '8px 0'
  },
  text: {
    width: '60%',
    marginLeft: 2,
    marginTop: 276
  },
  subText: {
    marginTop: 16
  },
  '@media screen and (max-width: 1440px)': {
    image: {
      height: 500
    }
  },
  '@media screen and (max-width: 1024px)': {
    image: {
      backgroundSize: '80%',
      backgroundPosition: 'top, center left 50px',
      height: 400
    },
    avatarsEmptyMessage: {
      paddingRight: 50
    }
  }
}))
