import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'

import { Button, Column, NavLink, Row, Text } from '../../components'

import { SuccessPasswordResetProps } from './success-password-reset.types'
import { useStyle } from './success-password-reset.style'

export const SuccesPasswordResetPage: FC<SuccessPasswordResetProps> = ({
  className = '',
  ...props
}) => {
  const classes = useStyle()
  const history = useHistory()

  const handleOnNavigate = (path: string) => () => {
    history.push(path)
  }

  return (
    <Column
      {...props}
      className={`${className} ${classes.container}`}
      fullWidth
    >
      <Column className={classes.changeForm}>
        <Text
          preset="h1"
          text="Successful password reset!"
          tx="password.reset.success.title"
        />
        <Text
          className={classes.changeSecondaryText}
          color="inactive"
          preset="h7"
          text="You can now use your new password to log in to your account!"
          tx="password.reset.success.text"
        />
        <Button
          className={classes.logIn}
          text="LOG IN"
          tx="password.reset.login"
          preset="primary"
          textColor="white"
          textPreset="h5"
          onClick={handleOnNavigate('/sign-in')}
        />
        <Row className={classes.signUpContainer}>
          <Text
            color="black"
            preset="secondTitle"
            text="Don’t have an account?"
          />
          <NavLink
            className={classes.signUp}
            color="blue"
            preset="h6"
            text="Sign Up"
            to="/sign-up"
          />
        </Row>
      </Column>
    </Column>
  )
}
