import React, { FC, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js'
import { CabinetNavigatorPath } from '../../pages'
import {
  Content,
  Loader,
  Row,
  Column,
  NavLink,
  Text,
  Icon,
  ICONS
} from '../../components'
import { useApi, useToastify } from '../../providers'
import { Currency } from '../../services'

import {
  CreateOrderActions,
  CreateOrderBody,
  Layout,
  OnApproveActions,
  OnApproveData,
  PaypalButtonsStyle,
  PaypalButtonsViewProps,
  ShippingPreference,
  UserAction
} from './paypal-buttons.types'
import { useStyle } from './paypal-buttons.styles'

const style: PaypalButtonsStyle = { layout: Layout.VERTICAL }

export const PaypalButtonsView: FC<PaypalButtonsViewProps> = ({
  orderNumber,
  orderId,
  amount = '1',
  currency = Currency.USD
}) => {
  const { payment } = useApi()
  const { onSubmit } = payment.useCreatePaypalOrder()
  const { open } = useToastify()
  const [{ options, isPending }, dispatch] = usePayPalScriptReducer()
  const classes = useStyle()
  const [paypalOrderId, changePaypalOrderId] = useState('')
  const [success, changeSuccess] = useState(false)
  const [isError, changeIsError] = useState(false)
  const [loading, changeLoading] = useState(false)

  const Container = isMobile ? Column : Row
  const toastifyOptions = () => ({
    values: {
      orderNumber
    },
    timeout: 4000
  })

  const order: CreateOrderBody = {
    purchase_units: [
      {
        amount: {
          currency_code: currency,
          value: amount
        },
        custom_id: orderId
      }
    ],
    application_context: {
      brand_name: 'Ovaflopick',
      shipping_preference: ShippingPreference.NO_SHIPPING,
      user_action: UserAction.CONTINUE
    }
  }

  useEffect(() => {
    dispatch({
      type: 'resetOptions',
      value: {
        ...options,
        currency
      }
    })
  }, [currency])

  const handleOnCreateOrder = (
    _: Record<string, unknown>,
    actions: CreateOrderActions
  ) => {
    return actions.order.create(order).then((nextPaypalOrderId) => {
      // Your code here after create the order
      changePaypalOrderId(nextPaypalOrderId)
      return nextPaypalOrderId
    })
  }

  const handleOnApprove = (data: OnApproveData, actions: OnApproveActions) => {
    if (actions.order) {
      return actions.order.capture().then(() => {
        if (paypalOrderId === data.orderID) {
          open({
            text: `Order #${orderNumber} was success paid`,
            tx: 'paypal.success',
            ...toastifyOptions
          })
        } else {
          open({
            text: `Order #${orderNumber} was success paid, but have problem. Send request to support.`,
            tx: 'paypal.successWithError',
            values: {
              orderNumber
            },
            timeout: 4000
          })
        }
        onSubmit({ paypalOrderId: data.orderID, orderId })
        changeSuccess(true)
        changeLoading(true)
      })
    }

    return new Promise(() => {})
  }

  const handleOnError = (error: Record<string, unknown>) => {
    if (error) {
      open({
        text: `Order #${orderNumber} wasn't paid. Something went wrong`,
        tx: 'paypal.error',
        ...toastifyOptions
      })
      changeIsError(true)
    }
    // eslint-disable-next-line no-console
    console.error(error)
  }

  if (success) {
    return (
      <Column className={classes.container}>
        <Column alignItems="center">
          <Container alignItems="center">
            <Icon src={ICONS.paymentSuccess} />
            <Text
              className={classes.bottomText}
              text="Thanks for your order! Your payment is complete."
              tx="order.confirm.payment.success"
              preset="h5"
            />
          </Container>
          <Container className={classes.track}>
            <Text
              className={classes.title}
              text="Track the progress of your orders in your personal account:"
              tx="order.confirm.link.text"
              preset="h6"
              color="inactive"
            />
            <NavLink
              className={classes.link}
              color="blue"
              preset="h4"
              text="Delivery & Orders Control"
              tx="order.confirm.link.order.control"
              to={CabinetNavigatorPath.ORDERS_CONTROL}
            />
          </Container>
        </Column>
      </Column>
    )
  }

  if (success) {
    return (
      <Column className={classes.container}>
        <Column alignItems="center">
          <Row alignItems="center">
            <Icon src={ICONS.paymentSuccess} />
            <Text
              className={classes.bottomText}
              text="Your payment is complete. Your balance has been topped up."
              tx="add.funds.payment.success"
              preset="h6"
            />
          </Row>
        </Column>
      </Column>
    )
  }

  if (isError) {
    return (
      <Column className={classes.container}>
        <Row className={classes.error}>
          <Icon src={ICONS.paymentError} />
          <Text
            className={classes.errorText}
            text="Sorry, we weren’t able to complete your payment at this time. Please try again
          later."
            tx="add.funds.payment.error"
            preset="h6"
          />
        </Row>
      </Column>
    )
  }

  if (loading) {
    return <Loader />
  }

  return (
    <Content className={classes.container}>
      {isPending && <Loader />}
      <Row fullWidth>
        <PayPalButtons
          className={classes.paypal}
          style={style}
          disabled={false}
          forceReRender={[amount, currency, style]}
          createOrder={handleOnCreateOrder}
          // @ts-ignore
          onApprove={handleOnApprove}
          onError={handleOnError}
        />
      </Row>
    </Content>
  )
}
