import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  order: {
    marginTop: 16
  },
  load: {
    padding: '16px 0'
  },
  button: {
    width: 537
  },
  emptyButton: {
    cursor: 'pointer',
    marginTop: 24,
    width: 312,
    padding: 16
  },
  '@media screen and (max-width: 480px)': {
    order: {
      marginTop: 24
    },
    load: {
      padding: 0,
      marginTop: 16
    },
    button: {
      width: '100%',
      padding: '12px 0'
    },
    emptyButton: {
      cursor: 'pointer',
      marginTop: 24,
      mibWidth: 'none',
      width: 'calc(100% - 24px)',
      padding: 16
    }
  }
})
