import { createUseStyles } from 'react-jss'

import { OuterLinkStyleProps } from './outer-link.types'

export const useStyle = createUseStyles({
  container: {
    borderRadius: 32,
    padding: ({ copyLink }: OuterLinkStyleProps) => copyLink && 16,
    width: ({ copyLink }: OuterLinkStyleProps) =>
      copyLink ? 'calc(100% - 32px)' : '100%'
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '90%'
  },
  text: {
    textOverflow: 'ellipsis'
  }
})
