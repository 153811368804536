import React, { FC, useMemo } from 'react'

import { Button, Row } from '../../../components'

import { FilterResultItem } from '../filter-result-item'

import { useStyle } from './filter-result-row.styles'
import { FilterResultRowProps } from './filter-result-row.types'

export const FilterResultRow: FC<FilterResultRowProps> = ({
  data,
  onResetFilters,
  onRemoveItem
}) => {
  const classes = useStyle()

  const filterResults = useMemo(
    () => Array.from(data.entries()),
    [data.entries()]
  )

  const filterResultsData = useMemo(() => {
    const filterResultsWithType = filterResults.map((entry) => {
      return entry[1].map((coreItem) => ({
        ...coreItem,
        type: entry[0]
      }))
    })
    return filterResultsWithType.flat()
  }, [filterResults])

  return (
    <Row fullWidth className={classes.row} justifyContent="space-between">
      <Row className={classes.resultsRow} wrap justifyContent="flex-start">
        {filterResultsData.map((item) => (
          <FilterResultItem
            key={`result_${item.id}`}
            id={item.id}
            type={item.type}
            name={item.name}
            onClick={onRemoveItem}
          />
        ))}
      </Row>
      <Row justifyContent="flex-end">
        <Button
          className={classes.reset}
          preset="button2"
          text="RESET"
          textPreset="maxButton"
          textColor="white"
          onClick={onResetFilters}
        />
      </Row>
    </Row>
  )
}
