import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    background: theme.colors.white,
    borderBottom: `1px solid ${theme.colors.shadow}`,
    padding: '12px 24px',
    width: 'calc(100% - 48px)'
  },
  icon: {
    marginLeft: 16,
    cursor: 'pointer',
    '&:hover > path': {
      fill: theme.colors.yellow
    }
  },
  user: {
    cursor: 'pointer',
    marginLeft: 12,
    textDecoration: 'none'
  }
}))
