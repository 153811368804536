import React, { FC } from 'react'
import { isMobileOnly } from 'react-device-detect'

import { Column } from '../column'
import { Row } from '../row'
import { Text } from '../text'

import { useStyle } from './category-card.styles'
import { CategoryCardProps } from './category-card.types'

export const CategoryCard: FC<CategoryCardProps> = ({
  backgroundImage,
  mini,
  className,
  name
}) => {
  const classes = useStyle({ mini })
  const textPreset = isMobileOnly ? 'h5' : 'h4'
  return (
    <Column className={`${className} ${classes.container}`}>
      <Row className={classes.imgContainer}>
        <Row className={classes.imgLabel}>
          <Text
            color="black"
            preset="maxButton"
            text="LOOK"
            tx="category.look"
          />
        </Row>
        {backgroundImage && (
          <img
            className={classes.img}
            src={backgroundImage.url}
            alt={backgroundImage.alt}
          />
        )}
      </Row>
      <Column className={classes.details}>
        {name && <Text color="black" preset={textPreset} text={name} />}
      </Column>
    </Column>
  )
}
