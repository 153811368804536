import { FetchMore, ListVariablesWithFilter, PaginationList } from '../services'

export type PaginationListData<T> = { [key: string]: PaginationList<T> }
export type FetchMoreFunc<TOptions extends ListVariablesWithFilter<any>> =
  FetchMore<any, TOptions>

export const loadMorePagination = <
  T,
  TOptions extends ListVariablesWithFilter<any>
>(
  variables: TOptions,
  data?: PaginationList<T>,
  fetchMore?: FetchMoreFunc<TOptions>
) => {
  if (data?.pageInfo.hasNextPage) {
    const { edges } = data
    const { cursor } = edges[edges.length - 1]
    const first = edges.length

    if (fetchMore) {
      fetchMore({
        variables: {
          ...variables,
          first,
          after: cursor
        }
      })
    }
  }
}
