import React, { FC } from 'react'

import { Text } from '../text'
import { Row } from '../row'
import { Icon } from '../icon'

import { InfoRowProps } from './info-row.types'
import { useStyle } from './info-row.styles'

export const InfoRow: FC<InfoRowProps> = ({
  className = '',
  children,
  title,
  titleTx,
  img,
  value,
  valueTx,
  preset = 'h7'
}) => {
  const classes = useStyle()

  return (
    <Row
      alignItems="center"
      justifyContent="flex-start"
      className={`${className} ${classes.container}`}
    >
      <Text
        className={classes.title}
        color="inactive"
        preset="body"
        text={title}
        tx={titleTx}
      />
      {value && (
        <Row>
          <Text color="black" preset={preset} text={value} tx={valueTx} />
          {children}
        </Row>
      )}
      {value && img && (
        <Row>
          <Icon src={img} />
          <Text color="black" preset="h7" text={value} tx={valueTx} />
        </Row>
      )}
    </Row>
  )
}
