import { useQuery } from '@apollo/client'

import { ADMINS_QUERY } from './admin.graphql'
import { AdminApi, Admins, AdminsData, AdminsVariables } from './admin.types'

export const adminService = (): AdminApi => {
  const useAdmins = (): Admins => {
    const { data, loading, error, refetch } = useQuery<
      AdminsData,
      AdminsVariables
    >(ADMINS_QUERY)

    return { data, error, loading, refetch }
  }

  return { useAdmins }
}
