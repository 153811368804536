import React, { useMemo } from 'react'
import { isMobileOnly } from 'react-device-detect'

import { useApi } from '../../providers'
import { BannerKind } from '../../services'

import {
  arrayHasElements,
  BannerCarousel,
  Column,
  getListFromEdgesList,
  Loader,
  PromotionBannerCarousel,
  SecondaryBannerView
} from '../../components'
import {
  CollectionsGallery,
  FeatureProducts,
  LatestNewsView,
  TopProducts
} from '../../views'

export const HomePage = () => {
  const firstNews = isMobileOnly ? 4 : 6

  const { menu, news: newsApi } = useApi()
  const { data: news } = newsApi.useLatestNewsList({ first: firstNews })
  const { data } = menu.useBanners({
    // TODO: temporary changed first for testing reasons, request of customer ovm-1771, should be 20
    first: 100,
    filter: {
      isAvailableToday: true,
      isVisible: true,
      kind: [
        BannerKind.PROMOTION_BANNER,
        BannerKind.TOP_PRODUCT_BANNER,
        BannerKind.PROMOTED_STORE_BANNER
      ]
    }
  })
  const { data: advertisingBanners } = menu.useAdvertisingBanners({
    // TODO: temporary changed first for testing reasons, request of customer ovm-1771, should be 10
    first: 100
  })

  const News = useMemo(
    () => getListFromEdgesList(news?.latestNewsList).slice(0, firstNews),
    [news]
  )

  const MainBanner = useMemo(() => {
    if (data) {
      const now = new Date()
      const banners = getListFromEdgesList(data.allBanners)
        .filter(
          (item) => item.kind === BannerKind.PROMOTION_BANNER && item.isVisible
        )
        .filter((item) => {
          const startDate = new Date(item.startDate)
          const endDate =
            new Date(item.endDate).getDate() > startDate.getDate()
              ? new Date(item.endDate)
              : undefined
          if (now.getDate() >= startDate.getDate()) {
            if (endDate) {
              if (now.getDate() < endDate.getDate()) {
                return item
              }
            } else {
              return item
            }
          }
          return false
        })
      return banners
    }

    return []
  }, [data])

  const AdvertisingBanners = useMemo(() => {
    if (advertisingBanners) {
      const bannersValue = getListFromEdgesList(
        advertisingBanners.advertisingBanners
      )

      return bannersValue
    }

    return []
  }, [advertisingBanners])

  const TopBanner = useMemo(() => {
    if (data) {
      const topBanner = data.allBanners.edges.find(
        (item) => item.node.kind === BannerKind.TOP_PRODUCT_BANNER
      )

      return topBanner?.node
    }

    return undefined
  }, [data])

  const SecondaryBanner = useMemo(() => {
    if (data) {
      const banner = data.allBanners.edges
        .map((item) => item.node)
        .find((item) => item.kind === BannerKind.PROMOTED_STORE_BANNER)

      return banner
    }

    return ''
  }, [data])

  if (!data) {
    return <Loader />
  }

  return (
    <Column fullWidth>
      {arrayHasElements(AdvertisingBanners) ? (
        <PromotionBannerCarousel data={AdvertisingBanners} timeout={4000} />
      ) : (
        <BannerCarousel data={MainBanner} timeout={4000} />
      )}
      <FeatureProducts />
      <CollectionsGallery />
      <TopProducts banner={TopBanner} />
      {SecondaryBanner && <SecondaryBannerView data={SecondaryBanner} />}
      <LatestNewsView isWrap={false} data={News} />
    </Column>
  )
}
