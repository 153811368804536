import React, { forwardRef, useRef, useState } from 'react'
import { useSwipeable } from 'react-swipeable'

import { Row, Column, CarouselDot } from '../../../components'

import { GalleryMobileProps } from './gallery-mobile.types'
import { useStyle } from './gallery-mobile.styles'

export const GalleryMobile = forwardRef<HTMLDivElement, GalleryMobileProps>(
  ({ className = '', images }, ref) => {
    const [currentIndex, changeCurrentIndex] = useState(0)
    const scrollContainer = useRef<HTMLDivElement>(null)

    const classes = useStyle()

    const handleOnClickIndex = (index: number) => () => {
      const scrollElement = scrollContainer.current

      if (scrollElement && index >= 0 && index < images.length) {
        const leftPosition = scrollElement.offsetWidth * index
        changeCurrentIndex(index)
        scrollElement.scroll({ left: leftPosition, behavior: 'smooth' })
      }
    }

    const handleOnClickLeft = () => {
      const scroll = handleOnClickIndex(currentIndex - 1)

      scroll()
    }

    const handleOnClickRight = () => {
      const scroll = handleOnClickIndex(currentIndex + 1)

      scroll()
    }

    const swipeableConfig = {
      delta: 20,
      preventDefaultTouchmoveEvent: false,
      trackTouch: true,
      trackMouse: true,
      rotationAngle: 0
    }

    const handlers = useSwipeable({
      onSwipedLeft: handleOnClickRight,
      onSwipedRight: handleOnClickLeft,
      ...swipeableConfig
    })

    return (
      <Row {...handlers} fullWidth className={className}>
        <Column className={classes.images} ref={ref}>
          <Row
            fullWidth
            justifyContent="space-between"
            ref={scrollContainer}
            className={classes.carouselContainer}
          >
            {images.map((image) => {
              return (
                <Column
                  fullWidth
                  className={classes.imgContainer}
                  key={`${image.id}`}
                >
                  <img
                    key={`image_${image.id}`}
                    className={classes.image}
                    src={image.url}
                  />
                </Column>
              )
            })}
          </Row>
          <Row className={classes.dotContainer}>
            {images.map((image, index) => {
              const active = currentIndex === index

              return (
                <CarouselDot
                  key={`dot_${image.id}`}
                  active={active}
                  className={classes.dot}
                  onClick={handleOnClickIndex(index)}
                />
              )
            })}
          </Row>
        </Column>
      </Row>
    )
  }
)
