import React, { FC } from 'react'
import { isTablet } from 'react-device-detect'

import { Row } from '../../row'
import { Text } from '../../text'
import { OrderRow } from '../../order-slider'

import { useStyle } from './order-info-row.styles'
import { OrderInfoRowProps } from './order-info-row.types'

export const OrderInfoRow: FC<OrderInfoRowProps> = ({
  createdDate,
  finishedDate,
  companyName,
  country
}) => {
  const classes = useStyle()

  return (
    <OrderRow className={classes.date}>
      {isTablet && (
        <>
          <Row alignItems="flex-start">
            <Text
              color="inactive"
              preset="body"
              text="Vendor"
              tx="order.item.product.vendor"
            />
            <Text
              className={classes.field}
              color="black"
              preset="h7"
              text={companyName}
            />
          </Row>
          <Row alignItems="flex-start">
            <Text
              // className={classes.text}
              color="inactive"
              preset="body"
              text="Country"
              tx="order.item.product.country"
            />
            <Text
              className={classes.field}
              color="black"
              preset="h7"
              text={country}
            />
          </Row>
        </>
      )}
      <Row alignItems="flex-start">
        <Text
          color="inactive"
          preset="body"
          text="Start date of production"
          tx="order.item.product.startDateProd"
        />
        <Text
          className={classes.field}
          color="black"
          preset="h7"
          text={createdDate}
        />
      </Row>
      <Row alignItems="flex-start">
        <Text
          color="inactive"
          preset="body"
          text="End date of profuction"
          tx="order.item.product.endDateProd"
        />
        <Text
          className={classes.field}
          color="black"
          preset="h7"
          text={finishedDate}
        />
      </Row>
    </OrderRow>
  )
}
