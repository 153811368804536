import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    marginTop: 12
  },

  item: {
    marginLeft: 12,
    width: 'calc(100% - 18px)',
    '&:not(:last-child)': {
      marginBottom: 25
    },
    '& > span': {
      color: theme.colors.black
    }
  },
  rightContainer: {
    background: theme.colors.darkBlue,
    width: 'calc(100vw - 61px)',
    height: 48,
    position: 'fixed',
    bottom: 0
  },
  button: {
    cursor: 'pointer',
    marginLeft: 12
  },
  flagTitle: {
    marginLeft: 8
  },
  arrowRight: {
    fill: 'white',
    width: 24,
    height: 24
  },
  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    rightContainer: {
      width: 314
    }
  }
}))
