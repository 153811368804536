import React from 'react'

import { useApi } from '../../../providers'

import { NavLink } from '../../nav-link'
import { Row } from '../../row'

import { HeaderAdditionalLinks } from '../header-additional-links'

import { HeaderNavSubMenu } from './submenu'
import { useStyle } from './nav-menu.styles'

export const HeaderNavMenu = () => {
  const { menu } = useApi()
  const { data } = menu.useMainMenu()
  const classes = useStyle()

  if (!data || !HeaderAdditionalLinks) {
    return <></>
  }

  return (
    <Row justifyContent="start" className={classes.container}>
      {data.shop.navigation.main.items.map((item) => (
        <HeaderNavSubMenu key={item.id} className={classes.item} item={item} />
      ))}
      <NavLink
        className={classes.item}
        color="inactive"
        text="New Arrivals"
        preset="h6"
        to={HeaderAdditionalLinks.NEW_ARRIVALS}
        tx="header.newArrivals"
      />
      <NavLink
        className={classes.item}
        color="inactive"
        text="Top Products"
        preset="h6"
        to={HeaderAdditionalLinks.TOP_PRODUCTS}
        tx="header.topProducts"
      />
      <NavLink
        className={classes.item}
        color="inactive"
        text="Maker/Designer"
        preset="h6"
        to={HeaderAdditionalLinks.MAKERS_DESIGNERS}
        tx="header.maker"
      />
    </Row>
  )
}
