import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

import { ProductStyles } from './product.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    transition: 'box-shadow 0.25s ease-in-out',
    '&:hover': {
      background: theme.colors.white,
      boxShadow: `4px 4px 16px ${theme.colors.shadow}`
    },
    '&:hover > div > div > div': {
      opacity: 1,
      transition: 'opacity 0.25s ease-in-out'
    },
    '&:hover > div > div': {
      visibility: 'visible',
      zIndex: 3
    },
    '&:hover > div > div > img': {
      transform: 'scale(1.3)'
    },
    maxWidth: ({ mini }: ProductStyles) => (mini ? 198 : 296)
  },
  imageContainer: {
    overflow: 'hidden'
  },
  image: ({ mini }: ProductStyles) => ({
    display: 'block',
    height: mini ? 256 : 410,
    width: mini ? 198 : 296,
    objectFit: 'cover',
    overflow: 'hidden',
    transition: 'transform 0.25s ease-in-out'
  }),
  title: {
    minHeight: 42,
    marginTop: 12
  },
  price: {
    marginTop: 8
  },
  discount: {
    textDecoration: 'line-through'
  },
  discountPrice: {
    marginLeft: 16
  },
  dash: {
    padding: '0 6px'
  },
  colors: {
    marginTop: 8
  },
  color: {
    '&:not(:first-child)': {
      marginLeft: 8
    }
  },
  details: {
    padding: 12,
    width: 'calc(100% - 24px)'
  },
  favorite: {
    background: theme.colors.white,
    borderRadius: 40,
    bottom: -20,
    boxShadow: `1px 2px 8px ${theme.colors.shadow}`,
    height: 40,
    position: 'absolute',
    right: 16,
    width: 40,
    zIndex: 1
  },
  hoverContainer: {
    visibility: 'hidden',
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0
  },
  hoverDetails: {
    width: 'calc(100% - 24px)',
    opacity: 1,
    '& > div:not(:first-child)': {
      marginTop: 8
    }
  },
  link: {
    display: 'flex',
    textDecoration: 'none'
  },
  salePlate: {
    backgroundColor: theme.colors.yellow,
    height: 32,
    position: 'absolute',
    top: 0,
    left: 16,
    zIndex: 1
  }
}))
