import React, { ChangeEvent, FC, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'

import { FeedbackCreateInput } from '../../services/feedback'
import { getPriceToFixed } from '../../pages'

import { Column } from '../column'
import { MenuSlider } from '../menu-slider'
import { Text } from '../text'
import { Row } from '../row'
import { TextArea } from '../text-area'
import { RatingStarsComponent } from '../rating-stars'
import { getCurrencySymbol } from '../helpers'
import { ResponsiveMediaView } from '../responsive-media-view'

import { FeedbackProductSliderProps } from './feedback-product-slider.types'
import {
  FeedbackSliderHeader,
  FeedbackSliderHeaderMobile
} from './feedback-product-slider-header'

import { useStyle } from './feedback-product-slider.styles'

const DEFAULT_FEEDBACK_STATE: FeedbackCreateInput = {
  product: '',
  rating: 0,
  comment: ''
}

export const FeedbackProductSlider: FC<FeedbackProductSliderProps> = ({
  className = '',
  productId,
  name,
  color,
  productImage,
  quantity,
  price,
  currency,
  onChange,
  orderId,
  defaultPrice
}) => {
  const classes = useStyle()
  const symbolCurrency = getCurrencySymbol(currency)
  const Container = isMobileOnly ? Row : Column
  const align = isMobileOnly ? 'center' : 'flex-start'
  const justify = isMobileOnly ? 'space-between' : 'center'

  const mobileDefaultPrice = `${symbolCurrency}${getPriceToFixed(defaultPrice)}`

  const [feedbackData, changeData] = useState<FeedbackCreateInput>(
    DEFAULT_FEEDBACK_STATE
  )

  const handleOnChange =
    (prop: keyof FeedbackCreateInput) =>
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = event.target
      const nextFeedbackData = {
        ...feedbackData,
        [prop]: value
      }
      changeData(nextFeedbackData)
      if (onChange && productId && orderId && prop === 'comment' && value) {
        onChange(nextFeedbackData)
      }
    }

  feedbackData.order = orderId
  feedbackData.product = productId

  const handleOnRating = (nextValue: number) => {
    const nextFeedbackData = {
      ...feedbackData,
      rating: nextValue
    }
    changeData(nextFeedbackData)
    if (onChange && productId && orderId && nextValue) {
      onChange(nextFeedbackData)
    }
  }

  const headerDesktop = (
    <FeedbackSliderHeader
      name={name}
      color={color}
      productImage={productImage}
      quantity={quantity}
      price={price}
      currency={currency}
      defaultPrice={defaultPrice}
    />
  )
  const headerMobile = (
    <FeedbackSliderHeaderMobile
      name={name}
      color={color}
      productImage={productImage}
    />
  )

  const header = (
    <ResponsiveMediaView
      bigScreen={headerDesktop}
      medium={headerDesktop}
      tablet={headerDesktop}
      mobile={headerMobile}
    />
  )

  const mobileContent = (
    <>
      <Row fullWidth justifyContent="space-between">
        <Text
          text="Price for unit:"
          tx="give.feedback.about.product.price.for.unit"
          preset="body"
          color="inactive"
        />
        <Text text={mobileDefaultPrice} preset="h7" />
      </Row>
      <Row
        fullWidth
        justifyContent="space-between"
        className={classes.quantityRow}
      >
        <Text
          text="Quantity:"
          tx="order.item.product.quantity"
          preset="body"
          color="inactive"
        />
        <Text text={String(quantity)} preset="h7" />
      </Row>
    </>
  )

  return (
    <Column className={className} fullWidth>
      <MenuSlider className={classes.slider} header={header}>
        <Column fullWidth alignItems="flex-start">
          <ResponsiveMediaView mobile={mobileContent} />

          <Container
            fullWidth
            alignItems={align}
            justifyContent={justify}
            className={classes.ratingRow}
          >
            <Text
              text="Overal Rating"
              tx="give.feedback.overal.rating"
              preset="h7"
            />

            <Row className={classes.rate}>
              <RatingStarsComponent
                defaultRating={feedbackData.rating}
                onChange={handleOnRating}
              />
            </Row>
          </Container>

          <TextArea
            className={classes.textArea}
            label="Comments"
            labelTx="give.feedback.comments"
            preset="border"
            textAreaInputPreset="body"
            maxLength={500}
            onChange={handleOnChange('comment')}
          />
        </Column>
      </MenuSlider>
    </Column>
  )
}
