import React, { FC } from 'react'

import { Column } from '../column'
import {
  OrderConfirmItemLine,
  OrderConfirmItemLineMobile
} from '../order-confirm-item-line'
import { ResponsiveMediaView } from '../responsive-media-view'

import { useStyle } from './order-confirm-line.styles'
import { OrderConfirmLineProps } from './order-confirm-line.types'

export const OrderConfirmLine: FC<OrderConfirmLineProps> = ({
  product,
  quantity,
  items,
  currency,
  noSuggestion,
  description,
  discount,
  deliveryPrice,
  totalOrderPrice
}) => {
  const classes = useStyle()

  const orderDeliveryPrice = deliveryPrice || 0

  const totalPriceWithoutDelivery = totalOrderPrice - orderDeliveryPrice || 0

  const Info = (
    <OrderConfirmItemLine
      className={classes.listContainer}
      productName={product.name}
      imgUrl={product?.thumbnail2x.url}
      noSuggestion={noSuggestion}
      description={description}
      discount={discount}
      deliveryPrice={orderDeliveryPrice}
      quantity={quantity}
      productDefaultPrice={product.defaultPrice}
      totalOfferPrice={totalPriceWithoutDelivery}
      totalOrderPrice={totalOrderPrice}
      items={items}
      currency={currency}
      isTooltip={true}
    />
  )

  const InfoMobile = (
    <OrderConfirmItemLineMobile
      className={classes.listContainer}
      productName={product.name}
      imgUrl={product?.thumbnail2x.url}
      noSuggestion={noSuggestion}
      description={description}
      discount={discount}
      deliveryPrice={orderDeliveryPrice}
      quantity={quantity}
      productDefaultPrice={product.defaultPrice}
      totalOfferPrice={totalPriceWithoutDelivery}
      totalOrderPrice={totalOrderPrice}
      items={items}
      currency={currency}
      isTooltip={true}
    />
  )

  return (
    <Column className={classes.items} fullWidth>
      <ResponsiveMediaView
        bigScreen={Info}
        medium={Info}
        tablet={Info}
        mobile={InfoMobile}
      />
    </Column>
  )
}
