import { createUseStyles } from 'react-jss'

import { BreadcrumbsStyle } from './breadcrumbs.types'

export const useStyle = createUseStyles({
  breadcrumbs: {
    cursor: ({ active }: BreadcrumbsStyle) => (active ? 'default' : 'pointer'),
    '&:hover': {
      color: 'black'
    }
  },
  icon: {
    marginLeft: 8
  }
})
