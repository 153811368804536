import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { ColorDot, Row, Text } from '../../../components'
import { LightTheme } from '../../../theme'

import { useStyle } from './features-details-block-option.styles'
import { FeaturesDetailsBlockOptionProps } from './features-details-block-option.types'

export const FeaturesDetailsBlockOption: FC<
  FeaturesDetailsBlockOptionProps
> = ({ className = '', label, labelTx, text, tx }) => {
  const theme = useTheme<LightTheme>()
  const classes = useStyle()

  return (
    <Row
      fullWidth
      alignItems="flex-start"
      justifyContent="flex-start"
      className={className}
    >
      <ColorDot
        className={classes.dot}
        color={theme.colors.yellow}
        preset="mini"
      />
      <Row
        wrap
        fullWidth
        className={classes.featuresRow}
        justifyContent="flex-start"
      >
        {label && <Text color="inactive" text={label} tx={labelTx} />}
        <Text className={classes.text} text={text} tx={tx} />
      </Row>
    </Row>
  )
}
