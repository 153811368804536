import React, { FC } from 'react'
import { isMobile } from 'react-device-detect'
import { Column } from '../column'
import { Text } from '../text'

import { useStyle } from './filter-type-container.styles'
import { FilterTypeContainerProps } from './filter-type-container.types'

export const FilterTypeContainer: FC<FilterTypeContainerProps> = ({
  className = '',
  children,
  title,
  titleTx
}) => {
  const classes = useStyle()
  const titlePreset = isMobile ? 'h5' : 'h4'

  return (
    <Column
      fullWidth
      alignItems="flex-start"
      className={`${className} ${classes.container}`}
      justifyContent="flex-start"
    >
      {title && <Text text={title} tx={titleTx} preset={titlePreset} />}
      <Column
        fullWidth
        className={classes.list}
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        {children}
      </Column>
    </Column>
  )
}
