import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'

import { concatClassNames } from '../../utils'
import { MainRoutes } from '../../routes'

import { Row } from '../row'
import { Button } from '../button'
import { Text } from '../text'

import { ProductInfoBottomBarProps } from './product-info-botttom-bar.types'
import { useStyle } from './product-info-bottom-bar.styles'

export const ProductInfoBottomBar: FC<ProductInfoBottomBarProps> = ({
  className = '',
  classNameButton = '',
  name,
  price,
  absolute,
  children,
  buttonTitle,
  buttonTitleTx,
  onClick
}) => {
  const classes = useStyle({ absolute })
  const history = useHistory()
  const isOrderPreview = history.location.pathname.includes(
    MainRoutes.ORDER_PREVIEW
  )

  const isFullWidth = isOrderPreview && 'fullWidth'
  return (
    <Row
      fullWidth
      className={concatClassNames(classes.container, className)}
      justifyContent="space-between"
    >
      {children}
      {name && <Text text={name} preset="h3Mobile" />}

      <Row fullWidth={isFullWidth} justifyContent="flex-end">
        {price && (
          <Text
            className={classes.price}
            text={price}
            preset="h4"
            color="inactive"
          />
        )}
        {buttonTitle && (
          <Button
            className={`${classes.button} ${classNameButton}`}
            color="yellow"
            preset="primary"
            text={buttonTitle}
            tx={buttonTitleTx}
            textColor="white"
            textPreset="h5"
            onClick={onClick}
          />
        )}
      </Row>
    </Row>
  )
}
