import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  success: {
    marginLeft: 24
  },
  confirm: {
    marginLeft: 24
  },
  details: {
    alignSelf: 'stretch',
    flex: 1,
    padding: 16,
    width: 'calc(100% - 32px)',
    maxHeight: 'calc(100vh - 300px)',
    overflow: 'auto'
  },
  bottom: {
    marginTop: 24,
    borderTop: `1px solid ${theme.colors.shadow}`,
    padding: 24,
    width: 'calc(100% - 48px)'
  },
  '@media screen and (max-width: 480px)': {
    success: {
      width: '100%',
      marginLeft: 0
    },
    confirm: {
      width: '100%',
      padding: '10px 8px',
      marginLeft: 0
    },
    bottom: {
      padding: '24px 16px',
      width: 'calc(100% - 32px)'
    }
  }
}))
