import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  item: {
    cursor: 'pointer',
    '& > span:hover': {
      color: theme.colors.yellow
    }
  },
  svgContainer: {
    width: 24,
    height: 24
  }
}))
