import React, { FC, useEffect, useRef } from 'react'

import { useChat } from '../../providers'

import { useStyle } from './inbox.styles'
import { InboxProps } from './inbox.types'

export const Inbox: FC<InboxProps> = ({ className = '' }) => {
  const { onMountConversation, onDestroyInboxes } = useChat()
  const classes = useStyle()
  const talkjsContainer = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (onMountConversation) {
      onMountConversation(talkjsContainer)
    }

    return () => {
      if (onDestroyInboxes) {
        onDestroyInboxes()
      }
    }
  }, [])

  return (
    <div
      ref={talkjsContainer}
      className={`${className} ${classes.container}`}
    ></div>
  )
}
