import { isFunction } from 'lodash'
import React, {
  ChangeEvent,
  FocusEvent,
  forwardRef,
  MouseEvent,
  useLayoutEffect,
  useRef,
  useState
} from 'react'
import { useIntl } from 'react-intl'
import { useTheme } from 'react-jss'
import { useDefaultValue } from '../../hooks'

import { Color, LightTheme } from '../../theme'
import { concatClassNames } from '../../utils'

import { Column } from '../column'
import { Icon, ICONS } from '../icon'
import { Row } from '../row'
import { Text } from '../text'

import { useStyle } from './text-field.styles'
import { TextFieldProps } from './text-field.types'

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      className = '',
      defaultValue,
      inputClassName = '',
      isSecure,
      label,
      isHidePlaceholder,
      labelTx,
      leftIcon,
      leftIconColor = 'yellow',
      placeholder: placeholderValue,
      placeholderTx,
      autoFocus = false,
      placeholderColor,
      preset = 'main',
      rightElement,
      textInputPreset,
      value: outerValue,
      onChange,
      onRightElementClick,
      withError,
      error,
      errorTx,
      onFocus,
      onBlur,
      ...props
    },
    ref
  ) => {
    const intl = useIntl()
    const inputRef = useRef<HTMLInputElement>(null)
    const [value, changeValue] = useState(defaultValue || '')
    const [focus, changeFocus] = useState(false)
    const [visible, changeVisible] = useState(!isSecure)
    const Value = useDefaultValue({ outerValue, value })
    const theme = useTheme<LightTheme>()
    const classes = useStyle({
      error,
      focus,
      leftIcon,
      password: isSecure && !visible,
      preset,
      textInputPreset,
      placeholderColor,
      value: Value
    })

    const activeLabelColor: Color =
      !focus && !Value ? 'inactive' : leftIconColor
    const type = isSecure && !visible ? 'password' : 'text'
    const secureIcon = visible ? ICONS.removeRedEye : ICONS.visibilityOff
    const placeholder = placeholderTx
      ? intl.formatMessage({
          id: placeholderTx,
          defaultMessage: placeholderValue
        })
      : placeholderValue

    useLayoutEffect(() => {
      if (ref) {
        if (isFunction(ref)) {
          ref(inputRef.current)
        } else {
          // eslint-disable-next-line no-param-reassign
          ref.current = inputRef.current
        }
      }
    }, [inputRef])

    const handleOnVisible = (
      event: MouseEvent<SVGElement, globalThis.MouseEvent>
    ) => {
      changeVisible(!visible)
      event.preventDefault()
      event.stopPropagation()
    }

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { value: nextValue } = event.target
      changeValue(nextValue)

      if (onChange) {
        onChange(event)
      }
    }

    const handleOnFocus =
      (state: boolean) => (event: FocusEvent<HTMLInputElement>) => {
        changeFocus(state)

        if (state) {
          if (onFocus) {
            onFocus(event)
          }
        } else if (onBlur) {
          onBlur(event)
        }
      }

    const handleOnClickContainer = () => {
      if (inputRef.current) {
        inputRef.current.focus()
      }
    }

    return (
      <Column fullWidth className={className}>
        <Row
          className={classes.container}
          justifyContent="space-between"
          onClick={handleOnClickContainer}
        >
          {leftIcon && (
            <Icon
              className={classes.leftIcon}
              fill={theme.colors[activeLabelColor]}
              src={leftIcon}
              height={24}
              width={24}
            />
          )}
          <Column
            fullWidth
            className={classes.inputContainer}
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            {label && (
              <Text
                className={classes.label}
                color={activeLabelColor}
                preset="subscriptionText"
                text={label}
                tx={labelTx}
              />
            )}
            {isHidePlaceholder ? (
              <input
                {...props}
                className={concatClassNames(
                  inputClassName,
                  classes.input,
                  classes.inputLabel
                )}
                placeholder={placeholder}
                autoFocus={autoFocus}
                value={Value}
                type={type}
                ref={inputRef}
                onFocus={handleOnFocus(true)}
                onBlur={handleOnFocus(false)}
                onChange={handleOnChange}
              />
            ) : (
              <input
                {...props}
                className={`${inputClassName} ${classes.input}`}
                placeholder={placeholder}
                autoFocus={autoFocus}
                value={Value}
                type={type}
                ref={inputRef}
                onFocus={handleOnFocus(true)}
                onBlur={handleOnFocus(false)}
                onChange={handleOnChange}
              />
            )}
          </Column>
          {isSecure && value && (
            <Icon
              className={classes.icon}
              color="black"
              src={secureIcon}
              onClick={handleOnVisible}
            />
          )}
          {rightElement && (
            <Row className={classes.icon} onClick={onRightElementClick}>
              {rightElement}
            </Row>
          )}
        </Row>
        {withError && (
          <Column fullWidth>
            <Text
              className={classes.errorText}
              text={error}
              tx={errorTx}
              color="error"
              preset="secondTitle"
            />
          </Column>
        )}
      </Column>
    )
  }
)
