import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  button: {
    width: 536,
    marginTop: 16
  },
  row: {
    width: 1092
  },
  containerPage: {
    borderBottom: `1px solid ${theme.colors.shadow}`
  },
  containerContent: {
    marginTop: 0
  },
  input: {
    maxWidth: 536,
    marginTop: 16,
    color: theme.colors.inactive
  },
  inputText: {
    color: theme.colors.inactive
  },
  radio: {
    width: 536,
    marginTop: 16
  },
  link: {
    marginTop: 40,
    padding: 24,
    '&:hover': {
      textDecoration: 'underline',
      color: theme.colors.blue
    }
  },
  item: {
    marginLeft: 60
  },
  errorIcon: {
    marginLeft: 19
  },
  col: {
    borderBottom: `1px solid ${theme.colors.shadow}`,
    padding: '24px 0 40px'
  },
  border: {
    borderTop: `1px solid ${theme.colors.shadow}`,
    borderBottom: `1px solid ${theme.colors.shadow}`,
    marginTop: 40,
    padding: '24px 0 40px'
  },
  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    row: {
      width: '100%',
      columnGap: 20
    },
    input: {
      width: 'calc(50% - 10px)'
    },
    radio: {
      width: 'calc(50% - 10px)'
    },
    button: {
      width: 'calc(50% - 10px)',
      padding: '16px 0'
    },
    col: {
      width: '100%'
    }
  },
  '@media screen and (max-width: 480px)': {
    button: {
      width: '100%',
      marginTop: 24
    },
    row: {
      width: '100%'
    },
    input: {
      marginTop: 0,
      '&:first-child': {
        marginTop: 16
      },
      '&:not(:first-child)': {
        marginTop: 24
      }
    },
    radio: {
      width: '100%'
    },
    link: {
      padding: 0
    },
    border: {
      width: '100%'
    }
  },
  errorRow: {
    position: 'absolute',
    marginTop: 94,
    borderRadius: 4
  },
  errorText: {
    marginLeft: 16,
    height: 14
  }
}))
