import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'
import { RatingStarsStyles } from './rating-stars.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    backgroundColor: theme.colors.white
  },
  icon: {
    '&:first-child': {
      marginRight: ({ bigStar }: RatingStarsStyles) => (bigStar ? 16 : 8),
      width: ({ bigStar }: RatingStarsStyles) => !bigStar && 20,
      height: ({ bigStar }: RatingStarsStyles) => !bigStar && 20
    }
  },
  '@media screen and (max-width: 480px)': {
    icon: {
      '&:first-child': {
        marginRight: 0,
        width: 24,
        height: 24
      }
    }
  }
}))
