import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    marginTop: 42
  },
  description: {
    width: 380,
    marginTop: 8,
    textAlign: 'center'
  },
  item: {
    '& > *': {
      backgroundColor: theme.colors.shadow
    }
  },
  load: {
    padding: 16,
    width: 'calc(100% - 32px)',
    '& > *': {
      width: 'auto',
      padding: '16px 214px'
    }
  },
  vendors: {
    width: 'calc(100% - 40px)',
    padding: '24px 40px'
  },
  vendor: {
    textDecoration: 'none',
    padding: 8
  },

  '@media screen and (max-width: 480px)': {
    container: {
      marginTop: 0,
      width: 'calc(100% - 24px)',
      padding: '24px 12px'
    },
    description: {
      width: '100%',
      marginTop: 24
    },
    load: {
      padding: 0,
      width: '100%',

      '& > *': {
        width: '100%',
        padding: '16px 0'
      }
    },
    vendors: {
      width: '100%',
      padding: '24px 0'
    },
    vendor: {
      textDecoration: 'none',
      padding: 0,
      '&': {
        marginLeft: 0
      }
    }
  }
}))
