import { User } from '../../services'

export const DEFAULT_USER_STATE: User = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  jwtToken: '',
  sex: 0,
  defaultShippingAddress: null,
  defaultBillingAddress: null,
  status: '',
  activity: '',
  averageResponseTime: '',
  city: '',
  ordersAmount: 0,
  sales: 0,
  companyName: '',
  country: '',
  state: '',
  username: '',
  avatar: {
    url: '',
    __typename: 'Image'
  },
  wishlist: {
    totalCount: 0,
    edges: [],
    filters: '{}',
    pageInfo: {
      hasNextPage: false,
      hasPreviousPage: false,
      startCursor: '',
      endCursor: ''
    }
  },
  orders: {
    totalCount: 0,
    edges: [],
    filters: '{}',
    pageInfo: {
      hasNextPage: false,
      hasPreviousPage: false,
      startCursor: '',
      endCursor: ''
    }
  },
  offers: {
    totalCount: 0,
    edges: [],
    filters: '{}',
    pageInfo: {
      hasNextPage: false,
      hasPreviousPage: false,
      startCursor: '',
      endCursor: ''
    }
  },
  unreadMessages: 0,
  feedbacks: [],
  feedbackAmount: 0
}
