import React, { FC } from 'react'

import { OfferStatus } from '../../../services'

import { Button } from '../../button'
import { Column } from '../../column'
import { Row } from '../../row'

import { useStyle } from './offers-list-button-row.styles'
import { OfferListButtonRowProps } from './offers-list-button-row.types'

export const OfferListButtonRow: FC<OfferListButtonRowProps> = ({
  offer,
  className = '',
  isDeclined,
  onOpenDeclineModal,
  onOpenDeleteModal,
  onContactVendor
}) => {
  const classes = useStyle()

  const { status } = offer

  const isDeletedByCustomer = status === OfferStatus.DELETED_BY_CUSTOMER

  const handleOnOpenDeclineModal = () => {
    if (onOpenDeclineModal) {
      onOpenDeclineModal()
    }
  }

  const handleOnOpenDeleteModal = () => {
    if (onOpenDeleteModal) {
      onOpenDeleteModal()
    }
  }

  return (
    <Column fullWidth className={className}>
      <Row className={classes.buttonsRow} justifyContent="flex-end">
        {isDeclined ? (
          <Button
            className={classes.buttonDecline}
            color="darkBlue"
            textColor="darkBlue"
            textPreset="maxButton"
            preset="secondaryBorderMini"
            text="Delete Offer"
            tx="offers.delete.offer"
            onClick={handleOnOpenDeleteModal}
          />
        ) : (
          <Button
            disabled={isDeletedByCustomer}
            className={classes.buttonDecline}
            color="darkBlue"
            textColor="darkBlue"
            textPreset="maxButton"
            preset="secondaryBorderMini"
            text="Decline Offer"
            tx="offers.decline.offer"
            onClick={handleOnOpenDeclineModal}
          />
        )}
        <Button
          className={classes.buttonContact}
          textColor="yellow"
          textPreset="maxButton"
          preset="borderButton"
          text="Contact Maker"
          tx="offers.contact.maker"
          onClick={onContactVendor}
        />
      </Row>
    </Column>
  )
}
