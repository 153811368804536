import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    backgroundColor: theme.colors.vendorProfileFon,
    border: `1px solid ${theme.colors.shadow}`,
    width: '100%',
    borderRadius: 4,
    marginTop: 16
  },
  containerSlider: {
    backgroundColor: theme.colors.vendorProfileFon,
    borderBottom: 'none',
    borderRadius: 0,
    width: 'calc(100% - 32px)',
    padding: '0 16px'
  },
  contentSlider: {
    borderTop: 'none'
  },
  titleSlider: {
    backgroundColor: theme.colors.vendorProfileFon
  },
  mainHeader: {}
}))
