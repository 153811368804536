import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  summary: {
    border: `1px solid ${theme.colors.shadow}`,
    borderRadius: 4,
    flex: 1,
    marginLeft: 20
  },
  summaryBlock: {
    padding: 16,
    width: 'calc(100% - 32px)',
    '&:not(:first-child)': {
      borderTop: `1px solid ${theme.colors.shadow}`
    }
  },
  summryItem: {
    marginTop: 16
  },
  button: {
    marginTop: 24
  },
  color: {
    width: '21px !important',
    height: '21px !important',
    border: `1px solid ${theme.colors.greyFon}`,
    borderRadius: '50% !important'
  },
  colorTitle: {
    marginLeft: 6
  },
  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    button: {
      width: 536
    }
  },
  '@media screen and (max-width: 1223px)': {
    summary: {
      marginLeft: 0,
      width: '100%',
      marginTop: 24
    }
  }
}))
