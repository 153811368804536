import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useTheme } from 'react-jss'
import { useHistory, useParams } from 'react-router-dom'

import {
  Content,
  Icon,
  ICONS,
  OrderItemConfirm,
  Row,
  Text
} from '../../components'
import { useApi } from '../../providers'
import { LightTheme } from '../../theme'

import { CabinetNavigatorPath } from '../cabinet-navigator'

import { useStyle } from './proceed-payment.styles'
import { ProceedPaymentParams } from './proceed-payment.types'

export const ProceedPaymentPage = () => {
  const { id } = useParams<ProceedPaymentParams>()
  const history = useHistory()
  const theme = useTheme<LightTheme>()
  const classes = useStyle()

  const { order } = useApi()
  const { data } = order.useOrderById({ id })

  const titlePreset = isMobileOnly ? 'h1Mobile' : 'h1'

  const handleOnBack = () => {
    history.push(CabinetNavigatorPath.ORDERS_CONTROL)
  }

  return (
    <Content className={classes.container} justifyContent="flex-start">
      <Row
        fullWidth
        className={classes.backContainer}
        justifyContent="flex-start"
      >
        <Icon
          height={24}
          width={24}
          fill={theme.colors.inactive}
          src={ICONS.leftArrow}
        />
        <Text
          className={classes.backTitle}
          text="Back to Order Control"
          tx="order.proceed.back"
          preset="h6"
          color="black"
          onClick={handleOnBack}
        />
      </Row>
      <Text
        className={classes.title}
        text="Proceed with Payment"
        tx="order.proceed.title"
        preset={titlePreset}
        color="black"
      />
      <Text
        className={classes.description}
        text="Please carefully check the order(s) information below and choose way to pay."
        tx="order.proceed.text"
        preset="h5"
        color="inactive"
      />

      {data?.order && (
        <OrderItemConfirm
          key={`orderConfirm_${data.order.id}`}
          {...data.order}
        />
      )}
    </Content>
  )
}
