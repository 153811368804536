import React, { FC, MouseEvent } from 'react'
import { isMobileOnly } from 'react-device-detect'

import {
  Column,
  Row,
  Text,
  getCurrencySymbol,
  Checkbox,
  ResponsiveMediaView,
  ItemInfoRow,
  ItemInfoRowMobile
} from '../../../components'
import { ObserveButton } from '../../../views'

import { getPriceToFixed } from '../../helper'

import { useStyle } from './select-table-items.styles'
import { SelectTableItemsProps } from './select-table-items.types'

export const SelectTableItems: FC<SelectTableItemsProps> = ({
  item,
  offerOptions,
  onReturnItem,
  currency,
  checked
}) => {
  const classes = useStyle()

  const handleReturnItem =
    (nextId: string) =>
    (
      state: boolean,
      event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
    ) => {
      event.preventDefault()
      event.stopPropagation()

      if (onReturnItem) {
        onReturnItem(nextId, state)
      }
    }

  const currencySymbol = getCurrencySymbol(currency)
  const defaultPrice = item
    ? `${currencySymbol}${getPriceToFixed(item?.totalPrice / item?.quantity)}`
    : `${currencySymbol}0`
  const totalPrice = `${currencySymbol}${getPriceToFixed(item?.totalPrice)}`
  const Container = isMobileOnly ? Column : Row

  const InfoRow = (
    <ItemInfoRow
      pricePerItem={defaultPrice}
      price={totalPrice}
      quantity={`${item?.quantity}`}
    />
  )

  const InfoRowMobile = (
    <ItemInfoRowMobile
      className={classes.infoRow}
      price={totalPrice}
      pricePerItem={defaultPrice}
      quantity={`${item?.quantity}`}
    />
  )
  return (
    <Container className={classes.container}>
      <Row fullWidth className={classes.item}>
        {item && (
          <Row className={classes.checkbox} justifyContent="flex-start">
            <Checkbox
              checked={checked}
              onChange={handleReturnItem(item.product.id)}
            />
          </Row>
        )}
        <Row
          className={classes.item}
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <img className={classes.img} src={item?.product.thumbnail.url} />
          <Column className={classes.nameText} alignItems="flex-start">
            <Text text={item?.product.name} preset="h5" />
            <ObserveButton
              className={classes.observeOptionsBtn}
              colorCode={item?.color?.color?.code ?? ''}
              colorTitle={item?.color?.color?.name ?? ''}
              material={item?.material?.title ?? ''}
              offerOptions={offerOptions}
              nameTx="order.preview.item.observe.options"
            />
          </Column>
        </Row>
        <ResponsiveMediaView
          bigScreen={InfoRow}
          medium={InfoRow}
          tablet={InfoRow}
        />
      </Row>
      <ResponsiveMediaView mobile={InfoRowMobile} />
    </Container>
  )
}
