import React from 'react'

import {
  Column,
  LoginOverlay,
  LoginTabs,
  ResponsiveMediaView
} from '../../components'
import { SignUpView } from '../../views'
import background from '../../assets/images/background-pattern.png'

import { useStyle } from './sign-up.styles'

export const SignUpPage = () => {
  const classes = useStyle({ background })

  const SignUp = (
    <Column fullWidth className={classes.container}>
      <SignUpView />
    </Column>
  )

  const SignUpMobile = (
    <LoginOverlay>
      <LoginTabs activeSignUp />
      <SignUpView />
    </LoginOverlay>
  )

  return (
    <ResponsiveMediaView
      bigScreen={SignUp}
      medium={SignUp}
      tablet={SignUp}
      mobile={SignUpMobile}
    />
  )
}
