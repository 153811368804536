import React, { FC } from 'react'
import { useTheme } from 'react-jss'
import { Link } from 'react-router-dom'

import { LightTheme } from '../../theme'

import { Text } from '../text'

import { useStyle } from './nav-link.styles'
import { NavLinkProps } from './nav-link.types'

export const NavLink: FC<NavLinkProps> = ({
  className = '',
  decoration,
  outerLink,
  text,
  color,
  to,
  ...props
}) => {
  const theme: LightTheme = useTheme()
  const themeColor = color && theme.colors[color]
  const classes = useStyle({ color: themeColor, decoration, to })

  if (!to) {
    return <Text className={className} text={text} color={color} {...props} />
  }

  if (outerLink) {
    return (
      <a
        className={`${className} ${classes.decoration}`}
        href={to}
        rel="noreferrer"
        target="_blank"
        {...props}
      >
        <Text className={classes.link} text={text} color={color} {...props} />
      </a>
    )
  }

  return (
    <Link className={`${className} ${classes.decoration}`} to={to}>
      <Text className={classes.link} text={text} color={color} {...props} />
    </Link>
  )
}
