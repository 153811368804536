import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  slider: {
    border: 'none',
    borderBottom: `1px solid ${theme.colors.shadow}`
  },
  sliderContent: {
    border: 'none',
    maxWidth: '100%'
  },
  sliderTitle: {
    width: '100%',
    padding: '16px 0'
  },
  '@media screen and (max-width: 480px)': {
    sliderContent: {
      width: '100%'
    }
  }
}))
