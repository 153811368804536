import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  FilterSidebarData,
  getListFromEdgesList,
  parseFilterItems
} from '../../components'
import { useApi } from '../../providers'
import { ProductView, reduceProductsToProductList } from '../../views'
import {
  ProductFilter,
  ProductFiltersParseJSON,
  ProductOrderSort,
  ProductsByCategoryIdVariables
} from '../../services'

import { CategoryPageParams, ProductsPageParams } from '../category'

export const ProductsVendorViewPage = () => {
  const { id } = useParams<CategoryPageParams>()
  const { vendorId } = useParams<ProductsPageParams>()
  const {
    product: { useProductsByCategoryId },
    category: { useCategoryById }
  } = useApi()
  const first = 8

  const [productVariables, changeProductVariables] =
    useState<ProductsByCategoryIdVariables>({
      id,
      first,
      filter: {
        vendors: [vendorId]
      }
    })

  const {
    data: response,
    loading,
    refetch,
    fetchMore
  } = useProductsByCategoryId(productVariables)

  const { data: categoryData } = useCategoryById(id)

  const Data = useMemo(() => {
    if (response) {
      const products = getListFromEdgesList(response.category.products)
      return reduceProductsToProductList(products)
    }

    return []
  }, [response])

  const ParentCategory = useMemo(() => {
    if (categoryData) {
      return categoryData.category
    }

    return undefined
  }, [id, categoryData])

  const TotalCount = useMemo(
    () => response?.category.products.totalCount || 0,
    [response?.category.products.totalCount]
  )

  const Name = useMemo(
    () => response?.category.name || '',
    [response?.category.name]
  )

  const handleOnSort = (sortBy?: ProductOrderSort) => {
    refetch({ ...productVariables, first, sortBy })
    changeProductVariables({ ...productVariables, first, sortBy })
  }

  const handleOnLoadNext = (filter: ProductFilter) => {
    if (fetchMore && response) {
      const productEdges = response.category.products.edges
      const lastIndex = productEdges.length - 1
      fetchMore({
        variables: {
          id,
          first,
          filter,
          after: productEdges[lastIndex].cursor
        }
      })
    }
  }

  const handleOnChangeFilter = (value: ProductFilter) => {
    if (refetch) {
      refetch({
        id,
        first,
        filter: value
      })
    }
  }

  const filtersData = response ? response.category.products.filters : undefined
  const Filters = useMemo(() => {
    if (filtersData) {
      const list = parseFilterItems<ProductFiltersParseJSON>(filtersData)

      if (list) {
        return list
      }
    }

    return {
      colors: [],
      materials: [],
      vendors: [],
      genders: [],
      priceRange: [],
      companyNames: [],
      productTypes: [],
      sizes: [],
      rating: [],
      productionDaysRange: []
    } as FilterSidebarData<ProductFiltersParseJSON>
  }, [filtersData])

  return (
    <ProductView
      id={id}
      loading={loading}
      category={ParentCategory}
      data={Data}
      title={Name}
      totalCount={TotalCount}
      filtersData={Filters}
      onLoadNext={handleOnLoadNext}
      onChangeFilter={handleOnChangeFilter}
      onSort={handleOnSort}
    />
  )
}
