import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  text: {
    maxWidth: 502,
    marginTop: 6
  },
  column: {
    marginLeft: 22
  },
  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    text: {
      maxWidth: 424
    }
  }
})
