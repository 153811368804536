import React, { FC } from 'react'

import { Row, Text, Column, NumberSwitcher } from '../../../components'

import { ProductInfoProps } from './product-info.types'
import { useStyle } from './product-info.styles'

export const ProductInfo: FC<ProductInfoProps> = ({
  className = '',
  image,
  title,
  titlePreset,
  salePriceByQuantity,
  priceByQuantity,
  isSale,
  onChange
}) => {
  const classes = useStyle()

  return (
    <Row fullWidth className={`${className} ${classes.productInfo}`}>
      <Row className={classes.imgContainer}>
        <img className={classes.img} src={image} />
      </Row>
      <Column className={classes.optionsName} fullWidth alignItems="flex-start">
        <Text className={classes.table} text={title} preset={titlePreset} />
        <Row
          className={classes.switcherBlock}
          fullWidth
          justifyContent="flex-start"
        >
          <NumberSwitcher
            className={classes.switcher}
            label={'Quantity'}
            preset="body"
            onChange={onChange}
          />
          {isSale ? (
            <Text
              className={classes.price}
              text={salePriceByQuantity}
              preset="h4"
              color="inactive"
            />
          ) : (
            <Text
              className={classes.price}
              text={priceByQuantity}
              preset="h4"
              color="inactive"
            />
          )}
        </Row>
        <Text
          className={classes.priceHint}
          text="Option price apply per one item."
          tx="product.otion.price.hint"
          preset="body"
          color="inactive"
        />
      </Column>
    </Row>
  )
}
