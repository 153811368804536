import React, { FC, useEffect, useMemo } from 'react'
import { isMobileOnly } from 'react-device-detect'

import {
  Content,
  ProductList,
  Loader,
  Row,
  Text,
  HeaderAdditionalLinks,
  arrayHasElements
} from '../../components'
import { useWindowDimensions } from '../../hooks'
import { useApi } from '../../providers'
import { Banner, OrderDirection, ProductOrderField } from '../../services'

import { reduceProductEdgesToProductList } from '../helpers'

import { useStyle } from './top-products.styles'

export type TopProductsProps = {
  banner?: Banner
}

export const TopProducts: FC<TopProductsProps> = ({ banner }) => {
  const { width } = useWindowDimensions()
  const { product } = useApi()
  const classes = useStyle()
  // TODO: temporary changed first for testing reasons, request of customer ovm-1771, should be 20
  const first = 100

  const h1Preset = isMobileOnly ? 'h1Mobile' : 'h1'

  const imgWidth = 170
  const gap = 16
  const isCarousel =
    imgWidth * first + gap * (first - 1) > width ||
    imgWidth * first + gap * (first - 1) > 1720

  const { data, refetch } = product.useTopProducts({
    first
  })

  const variables = {
    first,
    sortBy: {
      direction: OrderDirection.DESC,
      field: ProductOrderField.ADVERTISING_CREATED
    }
  }

  const { data: advertisingProducts, refetch: advertisingProductsRefetch } =
    product.useAdvertisingProducts(variables)

  useEffect(() => {
    refetch({ first })
    advertisingProductsRefetch(variables)
  }, [])

  const Products = useMemo(() => {
    if (data) {
      return reduceProductEdgesToProductList(data.topProducts.edges)
    }

    return []
  }, [data])

  const AdvertisingProducts = useMemo(() => {
    if (advertisingProducts) {
      return reduceProductEdgesToProductList(
        advertisingProducts?.advertisingProducts.edges
      )
    }

    return []
  }, [advertisingProducts])

  const isAdvertisingProducts = arrayHasElements(AdvertisingProducts)
    ? AdvertisingProducts
    : Products

  if (!data && !advertisingProducts) {
    return <Loader />
  }

  return (
    <Content justifyContent="flex-start">
      <Row fullWidth className={classes.container} alignItems="flex-start">
        {banner && (
          <Row className={classes.imageContainer}>
            <img className={classes.image} src={banner.image} />
          </Row>
        )}
        {isCarousel ? (
          <ProductList
            mini
            carousel
            titlePreset={h1Preset}
            className={classes.productList}
            data={isAdvertisingProducts}
            title="Top Products"
            titleTx="topProducts.title"
            to={HeaderAdditionalLinks.TOP_PRODUCTS}
          >
            <Text
              className={classes.description}
              color="inactive"
              preset="h7"
              text="A new collection shot in the warmth of soft evening light. Discover elegant silhouettes, refined details."
              tx="topProducts.description"
            />
          </ProductList>
        ) : (
          <ProductList
            mini
            titlePreset={h1Preset}
            className={classes.productList}
            data={isAdvertisingProducts}
            title="Top Products"
            titleTx="topProducts.title"
            to={HeaderAdditionalLinks.TOP_PRODUCTS}
          >
            <Text
              className={classes.description}
              color="inactive"
              preset="h7"
              text="A new collection shot in the warmth of soft evening light. Discover elegant silhouettes, refined details."
              tx="topProducts.description"
            />
          </ProductList>
        )}
      </Row>
    </Content>
  )
}
