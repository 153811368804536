import React, { FC, MouseEvent, useMemo, useState } from 'react'

import { CoreItem } from '../../services'

import { ColorDot } from '../color-dot'
import { Row } from '../row'

import { useStyle } from './color-picker.styles'
import { ColorPickerProps } from './color-picker.types'

export const ColorPicker: FC<ColorPickerProps> = ({
  colors,
  value,
  defaultValue = [],
  onChange,
  onChangeItem
}) => {
  const [activeIds, changeActiveIds] = useState(defaultValue)
  const classes = useStyle()

  const MainValue = useMemo(() => value || activeIds, [value, activeIds])

  const activeClassName = (id: string) =>
    MainValue.includes(id) ? classes.colorSelected : classes.color

  const handleOnChange = (
    nextValue: string[],
    event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    changeActiveIds(nextValue)

    if (onChange) {
      onChange(nextValue, event)
    }

    if (onChangeItem) {
      const nextValueItems = colors.filter((item) => {
        const isActiveColor = nextValue.includes(item.id)

        return isActiveColor
      })
      onChangeItem(nextValueItems, event)
    }
  }

  const handleOnClick =
    (color: CoreItem) =>
    (event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
      const currentActive = MainValue.includes(color.id)

      if (currentActive) {
        const nextValue = MainValue.filter((colorId) => colorId !== color.id)

        handleOnChange(nextValue, event)
      } else {
        const nextValue = [...MainValue, color.id]

        handleOnChange(nextValue, event)
      }
    }

  return (
    <Row
      wrap
      fullWidth
      alignItems="center"
      justifyContent="flex-start"
      className={classes.container}
    >
      {colors.map((item: CoreItem) => (
        <ColorDot
          key={item.id}
          className={activeClassName(item.id)}
          color={item.name}
          preset="productColor"
          onClick={handleOnClick(item)}
        />
      ))}
    </Row>
  )
}
