import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  results: {
    marginLeft: 4
  },
  count: {
    lineHeight: '18px'
  },
  sort: {
    marginLeft: 48,
    minWidth: 316
  },
  sortText: {
    minWidth: 64
  },
  sortDropdown: {
    width: 'calc(100% - 16px)',
    borderRadius: 4,
    scrollbarWidth: 'none',
    border: '1px solid black',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  }
})
