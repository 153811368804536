import React, { FC, useState, useEffect } from 'react'
import ReactDOM from 'react-dom'

import { usePortal } from '../../../../hooks'
import { MainRoutes } from '../../../../routes'

import { NavLink } from '../../../nav-link'
import { Row } from '../../../row'

import { useStyle } from './submenu-item.styles'
import { HeaderNavSubMenuItemProps } from './submenu-item.types'

export const HeaderSubMenuItem: FC<HeaderNavSubMenuItemProps> = ({
  item,
  onMouseOver,
  onMouseOut
}) => {
  const [open, changeOpen] = useState(false)
  const classes = useStyle()

  useEffect(() => {
    if (!open) {
      const timer = setTimeout(() => {
        if (onMouseOut) {
          onMouseOut()
        }
      }, 2000)

      return () => clearTimeout(timer)
    }

    return () => {}
  }, [open])

  const target = usePortal('submenu-header')

  const handleOnMouseOver = (state: boolean) => () => {
    changeOpen(state)

    if (state) {
      if (onMouseOver) {
        onMouseOver()
      }
    } else if (onMouseOut) {
      onMouseOut()
    }
  }

  return ReactDOM.createPortal(
    <Row
      className={classes.container}
      justifyContent="center"
      onMouseOver={handleOnMouseOver(true)}
      onMouseLeave={handleOnMouseOver(false)}
    >
      {item.children.map((child) => (
        <Row key={child.id}>
          <NavLink
            className={classes.child}
            color="inactive"
            text={child.name}
            preset="h6"
            to={`${MainRoutes.CATEGORY}/${child.category.id}`}
          />
        </Row>
      ))}
    </Row>,
    target
  )
}
