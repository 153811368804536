import React, { FC } from 'react'

import { ConstructorSlider } from '../../../components'

import { OptionSliderProps } from './option-slider.types'

export const OptionSlider: FC<OptionSliderProps> = ({
  className = '',
  option,
  onChange
}) => {
  const handlOnCheck = (ids: string[]) => {
    const nextVariants = option.variants.filter((item) => {
      const activeVariant = ids.find((itemId) => item.id === itemId)

      return Boolean(activeVariant)
    })

    if (onChange) {
      onChange({
        ...option,
        variants: nextVariants
      })
    }
  }

  const handleOnChange = (id: string) => {
    const active = option.variants.find(
      (variantOption) => variantOption.id === id
    )

    if (active && onChange) {
      onChange({
        ...option,
        variants: [active]
      })
    }
  }

  return (
    <ConstructorSlider
      key={`option_${option.title}`}
      className={className}
      title={option.title}
      data={option.variants}
      type={option.type}
      onChangeCheckbox={handlOnCheck}
      onChangeRadio={handleOnChange}
    />
  )
}
