import React, { FC, MouseEventHandler, useState } from 'react'

import { Icon, ICONS } from '../../components'

import { UnitsListModal } from '../units-list-modal'

import { useStyle } from './tooltip-button.styles'
import { TooltipButtonProps } from './tooltip.types'

export const TooltipButton: FC<TooltipButtonProps> = ({
  defaultOpen = false,
  onClick,
  ...unitsModalProps
}) => {
  const classes = useStyle()
  const [open, changeOpen] = useState(defaultOpen)

  const handleOnOpen =
    (state: boolean): MouseEventHandler<HTMLButtonElement> =>
    (event) => {
      changeOpen(state)

      if (onClick) {
        onClick(event)
      }
    }

  return (
    <>
      <button className={classes.container} onClick={handleOnOpen(true)}>
        <Icon src={ICONS.info} />
      </button>
      <UnitsListModal
        {...unitsModalProps}
        open={open}
        onClose={handleOnOpen(false)}
      />
    </>
  )
}
