import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  rangeStyle: {
    marginBottom: 20,
    '& .rc-slider-track': {
      backgroundColor: theme.colors.yellow
    },
    '& .rc-slider-handle': {
      backgroundColor: theme.colors.yellow,
      border: 'none'
    },
    '& .rc-slider-handle-dragging': {
      boxShadow: theme.colors.yellow,
      border: 'none'
    }
  },
  rangeInput: {
    columnGap: 10
  }
}))
