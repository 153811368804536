export enum CabinetNavigatorPath {
  USER_INFO = '/cabinet/profile',
  WISH_LIST = '/cabinet/wish-list',
  PURCHASE_HISTORY = '/cabinet/purchase-history',
  VENDOR_COMMUNICATION = '/cabinet/vendor-communication',
  ORDERS_CONTROL = '/cabinet/orders-control',
  PROFILE_SETTINGS = '/cabinet/profile-settings',
  VTMT_AVATAR = '/cabinet/vtmt',
  OFFERS = '/offers',
  OFFER = '/offer',
  OFFER_CHAT = '/offer-chat',
  ORDER_CHAT = '/order-chat'
}
