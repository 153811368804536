import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  filters: {
    backgroundColor: theme.colors.white,
    minWidth: 246,
    padding: 24
  },
  content: {
    width: '100%'
  }
}))
