import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment'
import { isMobileOnly } from 'react-device-detect'

import {
  Column,
  Text,
  Row,
  Content,
  Loader,
  Breadcrumbs
} from '../../components'
import { useApi } from '../../providers'

import { useStyle } from './news.styles'
import { NewsPageParams } from './news.types'

export const NewsPage = () => {
  const { id } = useParams<NewsPageParams>()
  const { news } = useApi()
  const { data } = news.useLatestNewsById({ id })
  const classes = useStyle()
  const history = useHistory()

  const Container = isMobileOnly ? Column : Row
  if (!data) {
    return <Loader />
  }
  const { title, text, image, createdAt } = data.latestNews
  const titlePrest = isMobileOnly ? 'h1Mobile' : 'h1'
  const formatedCreatedAt = moment(createdAt).format('MMMM D, YYYY')

  const handleOnNavigate = () => {
    history.goBack()
  }

  return (
    <Content className={classes.container}>
      <Breadcrumbs
        title="Latest News"
        titleTx="breadcrumbs.latest.news"
        subTitle={title}
        onNavigate={handleOnNavigate}
      />
      <Content className={classes.content}>
        <Column
          className={classes.newsContainer}
          justifyContent="flex-start"
          fullWidth
        >
          <Container className={classes.briefInfo} fullWidth>
            <img className={classes.image} src={image} alt={image} />
            <Column
              className={classes.details}
              alignItems="flext-start"
              fullWidth
            >
              <Text text={title} preset={titlePrest} />
              <Text
                color="inactive"
                text={formatedCreatedAt}
                preset="h5"
                className={classes.createdAt}
              />
            </Column>
          </Container>
          <Container className={classes.text} fullWidth>
            <Text text={text} preset="body" />
          </Container>
        </Column>
      </Content>
    </Content>
  )
}
