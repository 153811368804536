import React, { FC } from 'react'
import { isMobileOnly } from 'react-device-detect'

import {
  Button,
  Column,
  getListFromEdgesList,
  HintRow,
  Row
} from '../../../components'
import { concatClassNames } from '../../../utils'

import { ButtonsRowProps } from './buttons-row.types'
import { useStyle } from './buttons-row.styles'
import { useApi } from '../../../providers'
import { getInstruction } from '../../helper'

export const ButtonsRow: FC<ButtonsRowProps> = ({
  classNameOfferButton = '',
  isOffer,
  onContactVendor,
  onAddToBasket,
  onGetNewOffer
}) => {
  const { instruction: instructionApi } = useApi()
  const first = 100
  const classes = useStyle()
  const Container = isMobileOnly ? Column : Row

  const { data } = instructionApi.useInstructions({ first })

  const instructionsList = getListFromEdgesList(data?.instructions)

  const communication = getInstruction(instructionsList, 'communication')

  return (
    <Row fullWidth>
      {!isOffer ? (
        <Container fullWidth className={classes.btnRow}>
          <Button
            className={classes.addToCartBtn}
            color="yellow"
            preset="primary"
            text="ADD TO CART"
            tx="product.add.to.cart.button"
            textColor="white"
            textPreset="h5"
            onClick={onAddToBasket}
          />
          <Button
            className={classes.contactBtn}
            color="yellow"
            preset="secondaryBorder"
            text="CONTACT MAKER"
            tx="maker.contactTo"
            textColor="yellow"
            textPreset="h5"
            onClick={onContactVendor}
          />
        </Container>
      ) : (
        <Column fullWidth className={classes.btnRowOffer}>
          <Button
            className={concatClassNames(
              classNameOfferButton,
              classes.offerContactBtn
            )}
            color="yellow"
            preset="primary"
            text="START CHAT WITH MAKER"
            tx="vendor.start.chat.with.maker"
            textColor="white"
            textPreset="h5"
            onClick={onGetNewOffer}
          />
          <HintRow text={communication} top={96}>
            <Button
              className={classes.communication}
              color="yellow"
              preset="secondaryBorder"
              text="GO TO COMMUNICATION"
              tx="vendor.go.to.comminucation"
              textColor="yellow"
              textPreset="h5"
              onClick={onContactVendor}
            />
          </HintRow>
        </Column>
      )}
    </Row>
  )
}
