import React, { useState, useEffect, forwardRef } from 'react'
import { useHistory } from 'react-router-dom'

import { BasketIcon } from '../../../views'
import { CabinetNavigator } from '../../../pages'
import { useAuth } from '../../../providers'

import { Text } from '../../text'
import { Row } from '../../row'
import { Column } from '../../column'
import { NavLink } from '../../nav-link'
import { Icon, ICONS } from '../../icon'
import { SearchComponent } from '../../searchs'
import { HeaderOverlay } from '../../header-overlay'

import { useStyle } from './header-mobile.styles'
import { HeaderNavMobileMenu } from './nav-menu-mobile'

export const HeaderMobile = forwardRef<HTMLDivElement, any>((_, ref) => {
  const { isLoggedIn, user } = useAuth()
  const history = useHistory()
  const [openNavMenu, changeOpenNavMenu] = useState(false)
  const [openProfileMenu, changeOpenProfileMenu] = useState(false)
  const [open, openSearch] = useState(false)
  const classes = useStyle()

  const handleOnClickNavMenu = (state: boolean) => () => {
    changeOpenNavMenu(state)
  }

  useEffect(() => {
    changeOpenNavMenu(false)
    changeOpenProfileMenu(false)
  }, [history.location.pathname])

  const handleOnClickProfileMenu = (state: boolean) => () => {
    changeOpenProfileMenu(state)
    if (!isLoggedIn) {
      history.push('/sign-in')
    }
  }

  const handleOnClick = (state: boolean) => () => {
    openSearch(state)
  }

  const headerProfile = (
    <Text
      className={classes.text}
      color="black"
      preset="h5"
      text={`${user?.firstName} ${user?.lastName}`}
    />
  )
  const headerNav = (
    <NavLink
      color="black"
      preset="h5"
      text="OVAFLOPICK"
      to="/"
      className={classes.text}
    />
  )

  return (
    <Row className={classes.container} ref={ref}>
      <Column fullWidth alignItems="space-between">
        <Row justifyContent="space-between">
          <Row justifyContent="space-between" className={classes.iconsDuo}>
            <Icon
              className={classes.icon}
              src={ICONS.menu}
              onClick={handleOnClickNavMenu(true)}
            />
            <Icon
              className={classes.icon}
              src={ICONS.person}
              onClick={handleOnClickProfileMenu(true)}
            />
          </Row>
          <Row className={classes.nav}>
            <NavLink color="black" preset="h5" text="OVAFLOPICK" to="/" />
          </Row>
          <Row justifyContent="space-between" className={classes.iconsDuo}>
            <BasketIcon className={classes.icon} />
            <Icon
              className={classes.icon}
              src={ICONS.search}
              onClick={handleOnClick(true)}
            />
            {open && (
              <SearchComponent open={open} onClose={handleOnClick(false)} />
            )}
          </Row>
        </Row>
        {openNavMenu && (
          <HeaderOverlay
            header={headerNav}
            onClose={handleOnClickNavMenu(false)}
          >
            <HeaderNavMobileMenu />
          </HeaderOverlay>
        )}

        {openProfileMenu && isLoggedIn && (
          <HeaderOverlay
            header={headerProfile}
            onClose={handleOnClickProfileMenu(false)}
          >
            <CabinetNavigator />
          </HeaderOverlay>
        )}
      </Column>
    </Row>
  )
})
