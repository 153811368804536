import { ICONS } from '../components'

export const SOCIAL_MEDIA = [
  {
    ariaLabel: 'facebook',
    href: 'https://www.facebook.com/',
    path: ICONS?.facebook
  },
  {
    ariaLabel: 'instagram',
    href: 'https://www.instagram.com/',
    path: ICONS?.instagram
  },
  {
    ariaLabel: 'twitter',
    href: 'https://twitter.com/',
    path: ICONS?.twitter
  },
  {
    ariaLabel: 'linkedin',
    href: 'https://www.linkedin.com/',
    path: ICONS?.linkedin
  }
]
