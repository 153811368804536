import React, { useEffect, useMemo } from 'react'
import { isMobileOnly } from 'react-device-detect'

import { reduceProductEdgesToProductList } from '../helpers'
import { useApi } from '../../providers'
import { arrayHasElements, Loader, ProductList } from '../../components'

import { useStyle } from './previosly-seen.styles'

export const PrevioslySeen = () => {
  const { product } = useApi()
  const first = 10
  const { data } = product.usePrevioslySeenProducts({
    first
  })
  const { data: topProductsData, refetch } = product.useTopProducts({
    first
  })

  const classes = useStyle()
  const titlePreset = isMobileOnly ? 'h2Mobile' : 'h1'

  useEffect(() => {
    refetch({ first })
  }, [])

  const Products = useMemo(() => {
    if (data) {
      return reduceProductEdgesToProductList(data.previouslySeenProducts.edges)
    }

    return []
  }, [data])

  const TopProducts = useMemo(() => {
    if (topProductsData) {
      return reduceProductEdgesToProductList(topProductsData.topProducts.edges)
    }

    return []
  }, [topProductsData])

  if (!data) {
    return <Loader />
  }

  if (!arrayHasElements(Products)) {
    return (
      <ProductList
        carousel
        className={classes.productList}
        titlePreset={titlePreset}
        title="TOP Products"
        titleTx="topProducts.title"
        data={TopProducts}
      />
    )
  }

  return (
    <ProductList
      carousel
      className={classes.productList}
      titlePreset={titlePreset}
      title="Previously seen items"
      titleTx="previoslySeen.title"
      data={Products}
    />
  )
}
