import React, { FC } from 'react'

import { Link } from 'react-router-dom'

import { Text } from '../text'

import { useStyle } from './item-card-link.styles'
import { ItemCardLinkProps } from './item-card-link.types'

export const ItemCardLink: FC<ItemCardLinkProps> = ({
  className = '',
  text,
  textTx,
  to
}) => {
  const classes = useStyle()

  return (
    <Link to={to} className={className}>
      <Text
        className={classes.policy}
        color="blue"
        preset="h6"
        text={text}
        tx={textTx}
      />
    </Link>
  )
}
