import React from 'react'
import { useParams } from 'react-router-dom'

import { CategoryPageParams } from '../category'

import { ProductsVendorViewPage } from './products-vendor-view'

export const ProductsVendorPage = () => {
  const { id } = useParams<CategoryPageParams>()

  return <ProductsVendorViewPage key={`category_products_${id}`} />
}
