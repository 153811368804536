import React, { FC } from 'react'
import { getFormatedOrderDate } from '../../../../pages'

import { Row } from '../../../row'
import { Column } from '../../../column'
import { Text } from '../../../text'
import { InfoRow } from '../../../info-row'

import { OrderStaging } from '../../order-staging'

import { useStyle } from './order-slider-header-open-mobile.styles'
import { OrderSliderHeaderOpenMobileProps } from './order-slider-header-open-mobile.types'

export const OrderSliderHeaderOpenMobile: FC<
  OrderSliderHeaderOpenMobileProps
> = ({
  number,
  created,
  price,
  mainStatus,
  startDate,
  finishDate,
  vendor,

  remainingTimeProductionStarted
}) => {
  const classes = useStyle()
  const orderIdText = `# ${number}`
  const countryCode = vendor?.defaultBillingAddress?.country?.code

  return (
    <Column fullWidth className={classes.container}>
      <Row wrap fullWidth justifyContent="space-between">
        <Row wrap justifyContent="flex-start">
          <Text className={classes.row} preset="h4" text={orderIdText} />
        </Row>
      </Row>

      <OrderStaging
        className={classes.staging}
        mainStatus={mainStatus}
        remainingTimeProductionStarted={remainingTimeProductionStarted}
      />
      <InfoRow
        className={classes.row}
        title="Date:"
        titleTx="order.item.date.title"
        value={created}
      />
      <InfoRow
        className={classes.row}
        title="Total Price:"
        titleTx="order.item.price.title"
        value={price}
      />
      <InfoRow
        className={classes.row}
        title="Vendor:"
        titleTx="order.item.vendor.title"
        value={vendor?.companyName}
      />
      {vendor && (
        <InfoRow
          className={classes.row}
          title="Country:"
          titleTx="order.item.vendor.country"
          value={countryCode}
        />
      )}

      <InfoRow
        className={classes.row}
        title="Start Date:"
        titleTx="order.item.startDate"
        value={getFormatedOrderDate(startDate)}
      />
      <InfoRow
        className={classes.row}
        title="Finish Date:"
        titleTx="order.item.finishDate"
        value={getFormatedOrderDate(finishDate)}
      />
    </Column>
  )
}
