import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    backgroundColor: theme.colors.white,
    padding: '24px 0'
  },
  button: {
    marginTop: 24,
    width: 264
  },
  buy: {
    marginLeft: 20,
    width: 160
  },
  youMayLikeContainer: {
    marginTop: 40,
    padding: '40px 0',
    borderTop: `1px solid ${theme.colors.shadow}`,
    maxWidth: '100%'
  },
  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    container: {
      width: '100%',
      padding: '24px 0px 40px 0px'
    }
  },
  '@media screen and (max-width: 480px)': {
    container: {
      width: '100%',
      padding: '24px 0 40px'
    }
  }
}))
