import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  detailsRow: {
    flex: 6
  },
  details: {
    flex: 2,
    marginLeft: 8,
    overflow: 'hidden'
  },
  statusColumn: {
    flex: 2,
    marginLeft: 24,
    minWidth: 168
  },
  statusRow: {
    flex: 2.4
  },
  status: {
    marginTop: 4,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  productionText: {
    marginTop: 8
  },
  buttonsRow: {
    flex: 2
  },
  button: {
    marginLeft: 24,
    width: 120
  },
  buttonBorder: {
    width: 120,
    padding: '12px 6px'
  }
})
