import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Talk from 'talkjs'

import {
  OrderSlider,
  Button,
  Column,
  Loader,
  arrayHasElements,
  getListFromEdgesList,
  OrderSliderMobile,
  ResponsiveMediaView,
  ButtonProps,
  OrderSliderProps,
  CabinetPageContainer
} from '../../components'
import { useApi, useChat } from '../../providers'
import { MainRoutes } from '../../routes'
import {
  Order,
  OrderMainStatus,
  OrdersVariables,
  OrderUpdateVariables
} from '../../services'
import { EmptyList } from '../../views'

import { CabinetNavigatorPath } from '../cabinet-navigator'

import { useStyle } from './order-control.styles'

const FIRST = 10
const STATUS = [
  OrderMainStatus.NOT_CONFIRMED,
  OrderMainStatus.CONFIRMED,
  OrderMainStatus.PRODUCTION_STARTED,
  OrderMainStatus.PRODUCTION_FINISHED,
  OrderMainStatus.PRODUCT_SHIPPED,
  OrderMainStatus.PAID,
  OrderMainStatus.REFUNDED,
  OrderMainStatus.PARTLY_REFUNDED
]

export const OrderControlPage = () => {
  const history = useHistory()
  const { order: orderApi } = useApi()
  const { createVendor, onCreateConversation, onSendOrderMessage } = useChat()
  const [variables, changeVariables] = useState<OrdersVariables>({
    first: FIRST,
    filter: {
      mainStatus: STATUS
    }
  })
  const {
    data: response,
    loading,
    refetch,
    fetchMore
  } = orderApi.useOrders(variables)
  const { onSubmit } = orderApi.useOrderUpdate()

  const classes = useStyle()

  useEffect(() => {
    if (history.action === 'PUSH') {
      refetch(variables)
    }
  }, [])

  const handleOnClickHome = () => {
    history.push('/')
  }

  const TotalCount = useMemo(() => {
    if (response) {
      return response.orders.totalCount
    }

    return 0
  }, [response])

  const Orders = useMemo(() => {
    const ordersData = response?.orders
    if (ordersData) {
      return getListFromEdgesList(ordersData)
    }

    return []
  }, [response])

  const count = Orders.length || 0

  const handleOnNext = () => {
    if (response && fetchMore) {
      const data = response.orders.edges
      const { cursor } = data[data.length - 1]

      if (cursor) {
        const nextVariables = { ...variables, after: cursor }

        changeVariables(nextVariables)
        fetchMore({
          variables: nextVariables
        })
      }
    }
  }

  const handleOnUpdate = (updateVariables: OrderUpdateVariables) => {
    onSubmit(updateVariables)
    refetch(variables)
  }

  const handleOnPayment =
    (id: string): ButtonProps['onClick'] =>
    (event) => {
      event.preventDefault()
      event.stopPropagation()

      history.push(`${MainRoutes.PROCEED_PAYMENT}/${id}`)
    }

  const handleOnSendWelcomeMessage = (
    order: Order,
    conversation?: Talk.ConversationBuilder
  ) => {
    if (conversation && onSendOrderMessage) {
      onSendOrderMessage(conversation, order)
    }
  }

  const handleOnContactVendor =
    (order: Order): OrderSliderProps['onContactVendor'] =>
    (event) => {
      event.preventDefault()
      event.stopPropagation()

      if (order.offer) {
        history.push(
          `${CabinetNavigatorPath.ORDER_CHAT}/${order.id}${CabinetNavigatorPath.OFFER}/${order.offer.id}`
        )
      } else {
        const chatUser: Talk.User = createVendor(order.vendor)

        if (onCreateConversation) {
          const conversation = onCreateConversation(chatUser)
          handleOnSendWelcomeMessage(order, conversation)
        }

        history.push(CabinetNavigatorPath.VENDOR_COMMUNICATION)
      }
    }

  const handleOnOpenDeliveryLink = (link: string) => () => {
    if (link) {
      window.open(link, '_blank')
    }
  }

  if (loading && !arrayHasElements(Orders)) {
    return <Loader />
  }

  const OrderSliderDesktopAndTablet = Orders.map((order) => {
    return (
      <OrderSlider
        key={`order_${order.id}`}
        className={classes.order}
        order={order}
        onPayment={handleOnPayment(order.id)}
        onContactVendor={handleOnContactVendor(order)}
        onChange={handleOnUpdate}
        onOpenDelivery={handleOnOpenDeliveryLink(order.deliveryTrackingLink)}
      />
    )
  })

  const OrderMobileSlider = Orders.map((order) => {
    return (
      <OrderSliderMobile
        key={`order_${order.id}`}
        className={classes.order}
        order={order}
        onPayment={handleOnPayment(order.id)}
        onContactVendor={handleOnContactVendor(order)}
        onChange={handleOnUpdate}
        onOpenDelivery={handleOnOpenDeliveryLink(order.deliveryTrackingLink)}
      />
    )
  })

  return (
    <CabinetPageContainer
      label="Orders Control"
      labelTx="cabinet.route.ordersControl.title"
    >
      {!arrayHasElements(Orders) && (
        <Column fullWidth>
          <EmptyList
            title="Oops! Your Orders Control is empty"
            titleTx="order.preview.empty.list.title"
            text="Your generated orders will appear here after payment"
            tx="order.preview.empty.list.text"
          />
          <Button
            className={classes.emptyButton}
            color="yellow"
            preset="primary"
            textColor="white"
            textPreset="h5"
            text="HOME"
            tx="error.404.button.text"
            onClick={handleOnClickHome}
          />
        </Column>
      )}

      <ResponsiveMediaView
        bigScreen={OrderSliderDesktopAndTablet}
        medium={OrderSliderDesktopAndTablet}
        tablet={OrderSliderDesktopAndTablet}
        mobile={OrderMobileSlider}
      />

      {loading && <Loader />}
      {TotalCount > count && (
        <Column fullWidth className={classes.load}>
          <Button
            className={classes.button}
            color="yellow"
            preset="button3"
            textColor="white"
            textPreset="h5"
            text="Load More"
            onClick={handleOnNext}
          />
        </Column>
      )}
    </CabinetPageContainer>
  )
}
