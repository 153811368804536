import React, { FC } from 'react'
import { isMobileOnly } from 'react-device-detect'

import { Currency } from '../../../services'
import {
  Column,
  Row,
  Text,
  Checkbox,
  ItemHeader,
  ResponsiveMediaView
} from '../../../components'

import { SelectItemTableProps } from './select-item-table.types'
import { SelectTableItems } from '../select-table-items'
import { useStyle } from './select-item-table.styles'

export const SelectItemTable: FC<SelectItemTableProps> = ({
  data,
  lines,
  onReturnAll,
  checkedIds,
  returnAllState,
  onReturnItem
}) => {
  const classes = useStyle()
  const Container = isMobileOnly ? Column : Row
  const h3Toh4Preset = isMobileOnly ? 'h5' : 'h4'
  const getActive = (id: string) => {
    return Boolean(checkedIds.find((currentId) => currentId === id))
  }

  const Header = (
    <ItemHeader
      className={classes.header}
      classNameItem={classes.firstItem}
      classNamePrice={classes.item}
      classNameQuantity={classes.item}
      classNameTotal={classes.total}
    />
  )

  return (
    <Column fullWidth className={classes.container}>
      <Container className={classes.infoContainer} justifyContent="flex-start">
        <Row fullWidth alignItems="center" justifyContent="flex-start">
          <Row className={classes.checkbox} justifyContent="flex-start">
            <Checkbox checked={returnAllState} onChange={onReturnAll} />
          </Row>
          <Row fullWidth justifyContent="flex-start">
            <Text text={`# ${data?.number}`} preset={h3Toh4Preset} />
          </Row>
        </Row>
      </Container>
      <ResponsiveMediaView bigScreen={Header} medium={Header} tablet={Header} />
      {lines?.map((item, index) => (
        <SelectTableItems
          key={index}
          item={item}
          offerOptions={data?.offer?.options}
          currency={data?.total.currency ?? Currency.USD}
          onReturnItem={onReturnItem}
          checked={getActive(item.product.id)}
        />
      ))}
    </Column>
  )
}
