import React, { FC } from 'react'

import { Column, Row, Text } from '../../../components'

import { useStyle } from './details-block.styles'
import { DetailsBlockProps } from './details-block.types'

export const DetailsBlock: FC<DetailsBlockProps> = ({
  className = '',
  children,
  title,
  titleTx
}) => {
  const classes = useStyle()

  return (
    <Column
      alignItems="flex-start"
      className={`${className} ${classes.container}`}
    >
      <Text
        text={title}
        tx={titleTx}
        preset="h3"
        color="black"
        className={classes.text}
      />
      <Row className={classes.separator} fullWidth />
      <Column className={classes.content} alignItems="flex-start">
        <Column className={classes.productInfo} alignItems="flex-start">
          {children}
        </Column>
      </Column>
    </Column>
  )
}
