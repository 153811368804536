import { isMobile } from 'react-device-detect'
import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  productInfo: {},
  imgContainer: {},
  img: {
    width: 200,
    height: 200,
    objectFit: 'cover'
  },
  optionsName: {
    marginLeft: 16
  },
  switcherBlock: {
    marginTop: 24
  },
  switcher: {
    width: 180
  },
  price: {
    marginLeft: 24
  },
  table: {
    marginTop: 24
  },

  priceHint: {
    marginTop: 16
  },
  '@media screen and (max-width: 480px)': {
    productInfo: {
      flexDirection: 'column'
    },
    imgContainer: {
      width: isMobile && '100%'
    },
    img: {
      width: '100%',
      height: 299,
      objectFit: 'cover'
    },
    optionsName: {
      marginLeft: 0
    },
    switcherBlock: {
      marginTop: 24,
      justifyContent: 'space-between'
    },
    switcher: {
      width: '50%'
    },
    price: {
      marginLeft: 0,
      display: 'flex',
      justifyContent: 'center',
      width: '50%'
    }
  }
})
