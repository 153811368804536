import React, { FC } from 'react'

import { Text } from '../../text'
import { Row } from '../../row'
import { Column } from '../../column'

import { useStyle } from './avatar-info-mobile.styles'
import { AvatarInfoMobileProps } from './avatar-info-mobile.types'

export const AvatarInfoMobile: FC<AvatarInfoMobileProps> = ({
  id,
  img,
  name,
  date
}) => {
  const classes = useStyle()

  return (
    <Column fullWidth>
      <Row fullWidth justifyContent="flex-start">
        <img className={classes.img} src={img} alt={name} />
        <Text className={classes.name} text={name} preset="h5" />
      </Row>
      <Row fullWidth justifyContent="space-between" className={classes.idRow}>
        <Text text="ID" tx="vtmtAvatars.id" preset="body" />
        <Text text={id} preset="h7" />
      </Row>
      <Row fullWidth justifyContent="space-between" className={classes.dateRow}>
        <Text text="Date" tx="vtmtAvatars.date" preset="body" />
        <Text text={date} preset="h7" />
      </Row>
    </Column>
  )
}
