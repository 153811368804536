import React, { FC } from 'react'

import { Row } from '../row'
import { Text } from '../text'

import { useStyle } from './returns-item.styles'
import { ReturnsItemProps } from './returns-item.types'

export const ReturnsItem: FC<ReturnsItemProps> = ({
  className = '',
  active,
  order,
  onClick
}) => {
  const classes = useStyle({ active })

  return (
    <Row
      className={`${classes.ordersRow} ${className}`}
      key={order.id}
      onClick={onClick}
    >
      <Row className={classes.mainRow} justifyContent="flex-start">
        <Row className={classes.one}>
          <Text text={`# ${order.number}`} preset="h4" />
        </Row>
        <Row className={classes.two}>
          <Text
            text="Date: "
            preset="body"
            tx="returns.exchange.page.date"
            className={classes.twoDate}
          />
          <Text className={classes.textItem} text={order.created} preset="h7" />
        </Row>
        <Row className={classes.three}>
          <Text
            text="Company:"
            tx="returns.exchange.page.vendor"
            preset="body"
            color="inactive"
            className={classes.threeVendor}
          />
          <Text
            className={classes.textItem}
            text={order.vendor.companyName}
            preset="h7"
          />
        </Row>
      </Row>
      <Row className={classes.orderImagesOrders} justifyContent="flex-end">
        {order.lines
          .filter((line) => line.product !== null)
          .map(({ product }, index) => (
            <img
              key={`product_item_${product.id}_${index}`}
              className={classes.image}
              src={product.thumbnail.url}
              alt={product.thumbnail.alt}
            />
          ))}
      </Row>
    </Row>
  )
}
