import { gql } from '@apollo/client'

import {
  COLLECTION_FRAGMENT,
  BASIC_PRODUCT_FRAGMENT,
  PRODUCT_PRICING_FRAGMENT
} from '../fragments'

export const HOME_COLLECTIONS_QUERY = gql`
  ${COLLECTION_FRAGMENT}
  query Collections($first: Int!) {
    collections(first: $first) {
      edges {
        node {
          ...CollectionFragment
        }
      }
    }
  }
`

export const COLLECTION_BY_ID_QUERY = gql`
  ${BASIC_PRODUCT_FRAGMENT}
  ${PRODUCT_PRICING_FRAGMENT}
  ${COLLECTION_FRAGMENT}
  query CollectionById(
    $id: ID!
    $after: String
    $first: Int!
    $filter: ProductFilterInput
  ) {
    collection(id: $id) {
      id
      name
      description
      products(first: $first, after: $after, filter: $filter) {
        totalCount
        filters
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          cursor
          node {
            ...BasicProductFields
            ...ProductPricingField
            collection {
              ...CollectionFragment
            }
            category {
              id
              name
            }
          }
        }
      }
    }
  }
`
