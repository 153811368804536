import { useMemo } from 'react'
import { ValidateUserPhone, ValidateUserZipCode } from '../pages'

import { User } from '../services'

export const useValidateDeliveryInfo = (user: User) => {
  const ValidateUser = useMemo(() => {
    if (user.defaultShippingAddress) {
      const {
        country,
        streetAddress1,
        city,
        countryArea,
        firstName,
        lastName,
        phone,
        postalCode
      } = user.defaultShippingAddress

      return (
        !country?.country ||
        !streetAddress1 ||
        !city ||
        !countryArea ||
        !firstName ||
        !lastName ||
        !phone ||
        !postalCode ||
        !ValidateUserPhone(user) ||
        !ValidateUserZipCode(user)
      )
    }

    return false
  }, [user.defaultShippingAddress])

  return ValidateUser
}
