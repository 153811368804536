import { createUseStyles } from 'react-jss'

import { ShoppingCartStyleProps } from './shopping-cart.types'

export const useStyle = createUseStyles({
  content: {},
  breadcrumbs: {
    marginTop: 24
  },
  title: {
    marginTop: 24
  },
  countTitle: {
    marginTop: 24
  },
  itemContainer: {
    marginTop: 40
  },
  items: {
    flex: 2
  },
  info: {
    flex: 1
  },
  bottomContainer: {},
  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    content: {
      padding: '24px 0px 44px'
    },
    bottomContainer: {
      marginTop: 24
    }
  },
  '@media screen and (max-width: 480px) ': {
    content: {
      padding: '24px 0'
    },
    items: {
      width: '100%'
    },
    title: {
      marginTop: 0
    },
    countTitle: {
      marginTop: 24
    },
    bottomContainer: {
      bottom: ({ absolute }: ShoppingCartStyleProps) => (absolute ? -440 : 0)
    }
  }
})
