import React, { FC } from 'react'

import { Row, Text } from '../../../components'

import { getPriceWithSymbol } from '../helpers'

import { useStyle } from './observe-options-price.styles'
import { ObserveOptionPriceProps } from './observe-options-price.types'

export const ObserveOptionPrice: FC<ObserveOptionPriceProps> = ({
  className = '',
  preset,
  title,
  titleTx,
  price,
  currency
}) => {
  const classes = useStyle()
  const text =
    currency && price ? getPriceWithSymbol(price, currency) : String(price)

  return (
    <Row fullWidth justifyContent="space-between" className={className}>
      <Text color="inactive" text={title} tx={titleTx} preset="body" />
      {price && <Text className={classes.text} text={text} preset={preset} />}
    </Row>
  )
}
