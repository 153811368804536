import { ApolloError, MutationResult } from '@apollo/client'

import { Order, OrderDirection, OrderError } from '../order'
import { AddressInput, User } from '../auth'
import {
  CountableConnectionT,
  Product,
  ProductColor,
  ProductCreateColorInput,
  ProductCreateMaterialInput,
  ProductCreateOptionInput,
  ProductMaterial,
  ProductOption,
  ProductOptionVariant
} from '../product'
import { Edge, FetchMore, ListVariables, PaginationList } from '../api.types'

export enum OfferStatus {
  NEW = 'NEW',
  DRAFT = 'DRAFT',
  ACCEPTED = 'ACCEPTED',
  ACCEPTED_BACK_FROM_CART = 'ACCEPTED_BACK_FROM_CART',
  DELETED_BY_CUSTOMER = 'DELETED_BY_CUSTOMER',
  DELETED_BY_VENDOR = 'DELETED_BY_VENDOR',
  DECLINED_BY_CUSTOMER = 'DECLINED_BY_CUSTOMER',
  DECLINED_BY_VENDOR = 'DECLINED_BY_VENDOR',
  DECLINED = 'DECLINED',
  START_OFFER = 'START_OFFER'
}

export type OfferFilterInput = {
  status?: OfferStatus[]
  noSuggestion?: boolean
  search?: string
  ids?: string[]
}

export enum ProductErrorCode {
  ALREADY_EXISTS = 'ALREADY_EXISTS',
  ATTRIBUTE_ALREADY_ASSIGNED = 'ATTRIBUTE_ALREADY_ASSIGNED',
  ATTRIBUTE_CANNOT_BE_ASSIGNED = 'ATTRIBUTE_CANNOT_BE_ASSIGNED',
  ATTRIBUTE_VARIANTS_DISABLED = 'ATTRIBUTE_VARIANTS_DISABLED',
  DUPLICATED_INPUT_ITEM = 'DUPLICATED_INPUT_ITEM',
  GRAPHQL_ERROR = 'GRAPHQL_ERROR',
  INVALID = 'INVALID',
  NOT_PRODUCTS_IMAGE = 'NOT_PRODUCTS_IMAGE',
  NOT_PRODUCTS_VARIANT = 'NOT_PRODUCTS_VARIANT',
  NOT_FOUND = 'NOT_FOUND',
  REQUIRED = 'REQUIRED',
  SUBSCRIPTION_REQUIRED = 'SUBSCRIPTION_REQUIRED',
  UNIQUE = 'UNIQUE',
  VARIANT_NO_DIGITAL_CONTENT = 'VARIANT_NO_DIGITAL_CONTENT'
}

export type OfferError = {
  field: String
  message: String
  code: ProductErrorCode
}
export interface OfferColor extends ProductColor {}

export interface OfferMaterial extends ProductMaterial {}

export enum OfferOptionType {
  RADIOBUTTON = 'RADIOBUTTON',
  CHECKBOX = 'CHECKBOX'
}
export interface OfferOption extends ProductOption {}

export type OfferItem = {
  id: string
  quantity: number
  totalItemPrice: number
  productMaterials: OfferMaterial[]
  productColors: OfferColor[]
  productOptions: OfferOption[]
  options: ProductOptionVariant[]
  unitIds: string[]
}

export type Offer = {
  id: string
  number: number
  description: string
  noSuggestion: boolean
  quantity: number
  discount: number
  productTotalPrice: number
  totalPrice: number
  totalWeight: number
  vendor: User
  customer?: User
  product: Product
  status: OfferStatus
  conversationId: string
  productMaterials: OfferMaterial[]
  productColors: OfferColor[]
  productOptions: OfferOption[]
  options: ProductOptionVariant[]
  deliveryPrice: number
  productionDays: number
  items: OfferItem[]
}

export type OfferCreateInput = {
  customer?: string
  product?: string
  quantity?: number
  status?: string
  totalPrice?: string
  productMaterials?: ProductCreateMaterialInput[]
  productColors?: ProductCreateColorInput[]
  productOptions?: ProductCreateOptionInput[]
}
export enum OfferOrderField {
  NUMBER = 'NUMBER',
  ITEM_NAME = 'ITEM_NAME',
  CUSTOMER_NAME = 'CUSTOMER_NAME',
  TOTAL_PRICE = 'TOTAL_PRICE',
  STATUS = 'STATUS',
  QUANTITY = 'QUANTITY',
  UPDATED = 'UPDATED'
}

export type OfferOrder = {
  direction?: OrderDirection
  field?: OfferOrderField
}

export interface OfferVariables extends ListVariables {
  filter?: OfferFilterInput
  sortBy?: OfferOrder
}

export type CreateOrderFromOfferVariables = {
  offerId: string
  shippingAddress: AddressInput
}

export type CreateOrderFromOfferListVariables = {
  offerIds: string[]
  shippingAddress: AddressInput
}

export type OfferCreateVariables = {
  input: OfferCreateInput
}

export interface OfferUpdateVariables extends OfferCreateVariables {
  id: string
}

export type GetOfferUpdateResults = {
  offerUpdate: {
    offer: Offer
    offerErrors: OfferError[]
  }
}
export type GetOfferCreateResults = {
  offerCreate: {
    offer: Offer
    offerErrors: OfferError[]
  }
}

export type GetCreateOrderFromOfferResults = {
  createOrderFromOffer: {
    order: Order
    orderErrors: OrderError[]
  }
}
export type GetCreateOrderFromOfferListResults = {
  createOrderFromOfferList: {
    orders: Order[]
    orderErrors: OrderError[]
  }
}

export type OfferCountableEdge = Edge<Offer>

export type OfferCountableConnection = CountableConnectionT<OfferCountableEdge>

export type GetOffersData = {
  offers: PaginationList<Offer>
}

export type OfferCreate = {
  onSubmit: (variables: OfferCreateVariables) => void
  response: MutationResult<GetOfferCreateResults>
}

export type CreateOrderFromOffer = {
  onSubmit: (variables: CreateOrderFromOfferVariables) => void
  response: MutationResult<GetCreateOrderFromOfferResults>
}

export type CreateOrderFromOfferList = {
  onSubmit: (variables: CreateOrderFromOfferListVariables) => void
  response: MutationResult<GetCreateOrderFromOfferListResults>
}

export type OfferUpdate = {
  onSubmit: (variables: OfferUpdateVariables) => void
  response: MutationResult<GetOfferUpdateResults>
}

export type GetOfferData = {
  offer: Offer
}

export type GetOffersByIdData = {
  useOffersById: PaginationList<Offer>
}

export type GetOffers = {
  data?: GetOffersData
  error?: ApolloError
  loading: boolean
  refetch: (variables: OfferVariables) => void
  fetchMore?: FetchMore<GetOffersData, OfferVariables>
}

export type GetOffersById = {
  data?: GetOffersByIdData
  error?: ApolloError
  loading: boolean
  refetch: (variables: OfferVariables) => void
  fetchMore?: FetchMore<GetOffersByIdData, OfferVariables>
}

export type OfferByIdVariables = {
  id: string
}
export type OffersByIdVariables = {
  offerIds: string[]
}

export type GetOffer = {
  offer: Offer
}

export type GetOfferByIdRequest = {
  data: GetOffer | null
  loading: boolean
  refetch?: (variables: OfferByIdVariables) => void
}

export type OfferApi = {
  useOffers: (variables: OfferVariables) => GetOffers
  useOffersById: (variables: OfferVariables) => GetOffersById
  useOfferUpdate: () => OfferUpdate
  useOfferCreate: () => OfferCreate
  useCreateOrderFromOffer: () => CreateOrderFromOffer
  useCreateOrderFromOfferList: () => CreateOrderFromOfferList
  useOfferById: (variables: OfferByIdVariables) => GetOfferByIdRequest
}
